import { useTranslation } from "react-i18next"
import KeyValue from "../Display/KeyValue"
import { Grid } from "@mui/material"
import NumericFieldValue from "../entity/NumericFieldValue"
import CurrencyFieldValue from "../entity/CurrencyFieldValue"

const AggregateResult = ({ items }) => {
    const { t } = useTranslation("vbms")

    return <>{items.length > 0 && (
        <Grid container>
            {items.map((item) =>
                <Grid item xs={12} sm={4} xl={3}>
                    <KeyValue key={item.id} label={t(item.label)} variant="inline">
                        {(!item.type || item.type !== "currency") && <NumericFieldValue value={item.value} />}
                        {item.type && item.type === "currency" && <CurrencyFieldValue value={item.value} />}
                    </KeyValue>
                </Grid>
            )}
        </Grid>
    )}</>
}

export default AggregateResult
