import { useTranslation } from 'react-i18next';
import LoadingButton from '../../../../components/form/LoadingButton';
import { useCallback, useState } from 'react';
import { Box, Stack } from '@mui/material';
import CreateInvoiceSummary from './CreateInvoiceSummary';

const CreateInvoice = (props) => {
    const {
        selection,
        onConfirm
    } = props

    const { t } = useTranslation()

    const [loading, setLoading] = useState(false)

    const handleOnButtonClick = useCallback(() => {
        setLoading(true)
        onConfirm().then(() => {
            setLoading(false)
        }).catch((error) => {
            setLoading(false)
        })
    }, [onConfirm])

    return (
        <Stack spacing={2}>
            <CreateInvoiceSummary
                selection={selection}
                t={t}
            />
            <Box display="flex" justifyContent="center">
                <LoadingButton
                    loading={loading}
                    variant="contained"
                    color="primary"
                    onClick={handleOnButtonClick}
                >
                    {t("billing_client_create_invoice_widget.steps.invoice.button")}
                </LoadingButton>
            </Box>
        </Stack>
    )
}

export default CreateInvoice