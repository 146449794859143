import { billingTypeMapping, createExportField, createPaymentMethodMapping } from "../../../components/helpers/fieldHelper"
import _get from "lodash/get"
import _isDate from "lodash/isDate"
import _set from "lodash/set"
import { format as formatDate } from "date-fns"
import constants from "../../../components/entity/constants"
import { CUSTOMER_TYPE_IDS, getPax, processDate } from "./utils"
import { pushFields, dateObject } from "../../../lib/utils"

export const getTableExport = (apiClient, t, user, filterConfig) => {

    const columns = [
        createExportField("id", "Id"),
        createExportField("locator", t("bookings.fields.locator")),
        // Insert: parent_locator ('Localizador Padre')
        createExportField(
            "order.voucher_id",
            t("bookings.fields.order.voucher_id")
        ),
        createExportField(
            "experience.name",
            t("bookings.fields.experience")
        ),
        createExportField(
            "product.name",
            t("bookings.fields.product")
        ),
        createExportField(
            "order.created",
            t("bookings.fields.order.created"),
            null,
            (booking) => dateObject(booking.order.created)
        ),
        createExportField(
            "order.created",
            t("bookings.fields.order.created_year"),
            null,
            (booking) => parseInt(processDate(booking.order.created, "y"))
        ),
        createExportField(
            "order.created",
            t("bookings.fields.order.created_month"),
            null,
            (booking) => parseInt(processDate(booking.order.created, "M"))
        ),
        createExportField(
            "order.created",
            t("bookings.fields.order.created_day"),
            null,
            (booking) => parseInt(processDate(booking.order.created, "d"))
        ),
        createExportField(
            "order.created",
            t("bookings.fields.order.created_hour"),
            null,
            (booking) => parseInt(processDate(booking.order.created, "H"))
        ),
        createExportField(
            "order.created",
            t("bookings.fields.order.created_minute"),
            null,
            (booking) => parseInt(processDate(booking.order.created, "m"))
        ),
        createExportField(
            "booking_date",
            t("bookings.fields.booking_date"),
            null,
            (booking) => dateObject(booking.booking_date)
        ),
        createExportField(
            "booking_date",
            t("bookings.fields.booking_date_year"),
            null,
            (booking) => parseInt(processDate(booking.booking_date, "y"))
        ),
        createExportField(
            "booking_date",
            t("bookings.fields.booking_date_month"),
            null,
            (booking) => parseInt(processDate(booking.booking_date, "M"))
        ),
        createExportField(
            "booking_date",
            t("bookings.fields.booking_date_day"),
            null,
            (booking) => parseInt(processDate(booking.booking_date, "d"))
        ),
        createExportField(
            "booking_date",
            t("bookings.fields.booking_date_hour"),
            null,
            (booking) => parseInt(processDate(booking.booking_date, "H"))
        ),
        createExportField(
            "booking_date",
            t("bookings.fields.booking_date_minute"),
            null,
            (booking) => parseInt(processDate(booking.booking_date, "m"))
        ),
        createExportField(
            "validation_date",
            t("bookings.fields.validation_date"),
            null,
            (booking) => {
                if (booking.no_show) {
                    return t("bookings.fields.no_show")
                } else if (booking.cancel_state) {
                    return t("bookings.filter.cancelled")
                } else {
                    return booking?.validation_date ? dateObject(booking.validation_date) : null
                }
            }
        ),
        createExportField(
            "validation_date",
            t("bookings.fields.validation_date_year"),
            null,
            (booking) => booking?.validation_date ? parseInt(processDate(booking.validation_date, "y")) : null
        ),
        createExportField(
            "validation_date",
            t("bookings.fields.validation_date_month"),
            null,
            (booking) => booking?.validation_date ? parseInt(processDate(booking.validation_date, "M")) : null
        ),
        createExportField(
            "validation_date",
            t("bookings.fields.validation_date_day"),
            null,
            (booking) => booking?.validation_date ? parseInt(processDate(booking.validation_date, "d")) : null
        ),
        createExportField(
            "validation_date",
            t("bookings.fields.validation_date_hour"),
            null,
            (booking) => booking?.validation_date ? parseInt(processDate(booking.validation_date, "H")) : null
        ),
        createExportField(
            "validation_date",
            t("bookings.fields.validation_date_minute"),
            null,
            (booking) => booking?.validation_date ? parseInt(processDate(booking.validation_date, "m")) : null
        ),
        createExportField(
            "no_show",
            t("bookings.fields.no_show"),
            null,
            (booking) => booking.no_show ? t("common.yes") : t("common.no")
        ),
        createExportField(
            "cancel_state",
            t("bookings.filter.cancelled"),
            null,
            (booking) => booking.cancel_state ? t("common.yes") : t("common.no")
        ),
        createExportField(
            "payment_method",
            t("bookings.fields.order.payment_method"),
            createPaymentMethodMapping(t)
        ),
        createExportField(
            "order.manager_collaborator",
            t("bookings.fields.order.manager_collaborator.title")
        ),
        createExportField(
            "order.collaborator.name",
            t("bookings.fields.order.collaborator.title")
        ),
        createExportField(
            "order.office",
            t("bookings.filter.office")
        ),
        createExportField(
            "order.salesman.first_name",
            t("bookings.filter.salesman")
        ),
        createExportField(
            "order.customer.first_name",
            t("bookings.fields.order.customer.first_name"),
            null
        ),
        createExportField(
            "order.customer.last_name",
            t("bookings.fields.order.customer.last_name")
        ),
        createExportField(
            "order.customer.email",
            t("bookings.fields.order.customer.email")
        ),
        createExportField(
            "order.customer.phone",
            t("bookings.fields.order.customer.phone"),
            null,
            (booking) => booking.order.customer.phone || booking.order.customer.phone_other
        ),
        createExportField(
            "order.language",
            t("common.language")
        ),
        createExportField(
            "product.supplier.name",
            t("bookings.fields.supplier")
        ),
        createExportField(
            "order.main_site.name",
            t("bookings.fields.order.site.title")
        ),
        createExportField(
            "pax_total",
            t("bookings.fields.pax_total"),
            null,
            (booking) => getPax(booking.product_rates)
        ),
        createExportField(
            "pax_adults",
            t("bookings.fields.pax_adult"),
            null,
            (booking) => getPax(booking.product_rates, CUSTOMER_TYPE_IDS.ADULTS)
        ),
        createExportField(
            "pax_children",
            t("bookings.fields.pax_children"),
            null,
            (booking) => getPax(booking.product_rates, CUSTOMER_TYPE_IDS.CHILDREN)
        ),
        createExportField(
            "pax_residents",
            t("bookings.fields.pax_residents"),
            null,
            (booking) => getPax(booking.product_rates, CUSTOMER_TYPE_IDS.RESIDENTS)
        ),
        createExportField(
            "pax_not_residents",
            t("bookings.fields.pax_not_residents"),
            null,
            (booking) => getPax(booking.product_rates, CUSTOMER_TYPE_IDS.NOT_RESIDENTS)
        ),
        // Insert: Optionals? ('Opcionales')
        createExportField(
            "amount.main_currency.total_amount.value",
            t("bookings.fields.total_amount")
        ),
        createExportField(
            "amount.total_amount_pvp",
            t("bookings.fields.product_rates.pvp"),
            null,
            (booking) => parseFloat((booking.amount.total_amount_pvp / booking.order.currency_exchange_rate).toFixed(2))
        ),
        createExportField(
            "amount.total_amount_net",
            t("bookings.fields.product_rates.neto"),
            null,
            (booking) => parseFloat(((booking.order.hasOwnProperty("collaborator") ? booking.amount.total_amount_net : booking.amount.total_amount_pvp) / booking.order.currency_exchange_rate).toFixed(2))
        ),
        createExportField(
            "amount.total_amount_net",
            t("bookings.fields.total_amount_margin"),
            null,
            (booking) => parseFloat(((booking.order.hasOwnProperty("collaborator") ? (booking.amount.total_amount_pvp - booking.amount.total_amount_net) : 0) / booking.order.currency_exchange_rate).toFixed(2))
        ),
        createExportField(
            "amount.main_currency.total_base.value",
            t("bookings.fields.product_rates.pvd")
        ),
        createExportField(
            "amount.main_currency.total_tax.value",
            t("bookings.fields.total_tax")
        ),
        createExportField(
            "order.tpvid",
            t("bookings.fields.order.transaction_id")
        ),
        // Insert: disfrutare_seller? ('Vendedor Disfrutare')
        createExportField(
            "order.billing_type",
            t("bookings.fields.order.billing_method"),
            billingTypeMapping(t)
        ),
        createExportField(
            "order.id",
            t("bookings.fields.order.id")
        )
    ]

    const conditionalColumns = [
        {
            field: createExportField(
                "notes",
                t("bookings.fields.order.customer.notes")
            ),
            condition: !user.isIntermediary()
        }
    ]
    pushFields(columns, conditionalColumns)

    return (filter, progressHandler) => {

        let collaboratorFilter = [];
            if (_get(filter, "collaborator_id", null)) {
                _get(filter, "collaborator_id", null).forEach(col => {collaboratorFilter.push(col.id)});
            }

        const params = {
            ...filter,
            activity_id: _get(filter, "activity_id[0].id", null),
            collaborator_id: collaboratorFilter
        }

        filterConfig
            .filter(field => field.type === constants.DATE_TYPE)
            .forEach(field => {
                const date = _get(params, field.id)
                if (_isDate(date)) {
                    _set(params, field.id, formatDate(date, "yyyy-MM-dd"))
                }
            })

        filterConfig
            .filter(field => (field.type === constants.STRING_TYPE ||
                field.type === constants.TEXT_TYPE))
            .forEach(field => {
                if (_get(params, field.id) !== '') {
                    const param = _get(params, field.id)
                    _set(params, field.id, param.trim())
                }
            })

        apiClient.booking.exportBookings(
            columns,
            params,
            progressHandler
        )
    }
}
