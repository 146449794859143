import { useTranslation } from "react-i18next"
import { useCallback, useEffect, useState } from "react"
import { Box, Button, Grid, Paper, Stack } from "@mui/material"
import Loading from "../../../Display/Loading"
import RatesSelector from "../BookingWidget/RatesSelector"
import BookingSummary from "../BookingWidget/BookingSummary"
import PaymentGatewaysSelector from "../../PaymentGatewaysSelector/PaymentGatewaysSelector"
import useSnackBars from "../../../Snackbar/snack-bar.context"
import _isEmpty from "lodash/isEmpty"
import _get from "lodash/get"
import { useNavigate } from "react-router-dom"

const initSelection = (selection) => ({
    ...selection,
    rates: [],
    isReady: false,
    cart: null,
    paymentGateway: null,
})

/**
 * Returns the total quantity of tickets selected
 *  
 * @param {*} rates 
 * @returns 
 */
const getRatesQty = (rates) => rates.reduce((acc, rate) => acc + rate.qty, 0)

/**
 * Check if cart is ready to be confirmed.
 * 
 * @param {*} selection 
 * @returns 
 */
const checkIsCartReady = (selection) =>
    !_isEmpty(_get(selection, "cart.line_items")) && (_get(selection, "cart.total_amount") === 0 || !_isEmpty(_get(selection, "paymentGateway")))

const OneClickBookingWidget = (props) => {
    const {
        initialSelection,
        availabilityFetcher,
        ratesFetcher,
        paymentGatewaysFetcher,
        onBookingConfirm,
        onCartConfirm,
    } = props

    const { t, i18n } = useTranslation("vbms")
    const [waiting, setWaiting] = useState(false)
    const [selection, setSelection] = useState(initSelection(initialSelection))
    const [paymentGateways, setPaymentGateways] = useState([])
    const [error, setError] = useState(null)
    const { addAlert } = useSnackBars()
    const navigate = useNavigate()

    useEffect(() => {
        // check availability for the date and set default session
        if (selection.session !== null) {
            return
        }

        setWaiting(true)
        availabilityFetcher(selection.product.id, selection.date, selection.product.min_pax)
            .then((availability) => {
                let session = null

                // check availability for selected date
                if (!_isEmpty(availability)) {
                    const availableSessions = availability[0].sessions
                        .filter((session) => session.available > 0 || session.available === -1)
                        .sort((a, b) => a.available > b.available)
                    if (!_isEmpty(availableSessions)) {
                        session = availableSessions[0].session
                    }
                }

                setWaiting(false)

                if (session === null) {
                    addAlert(t("booking_widget.errors.availability"), "error")
                    return
                }

                setSelection((selection) => {
                    return {
                        ...selection,
                        session: { session: session }
                    }
                })
            })
    }, [selection.product, selection.date, selection.session, availabilityFetcher])

    useEffect(() => {
        const bookings = _get(selection, "cart.bookings", [])
        if (bookings.length > 0) {
            navigate("/orders/bookings/" + bookings[0].id)
            return
        }

        // check if cart is ready to be confirmed        
        if (checkIsCartReady(selection)) {
            onCartConfirmHandler()
        }
    }, [selection])

    useEffect(() => {
        if (error) {
            setWaiting(false)
            addAlert(error.message, "error")
            resetSelection()
        }
    }, [error])

    // updates selection with the rates selected
    const onRatesSelectionHandler = useCallback((rates, isReady) =>
        setSelection((selection) => {
            return {
                ...selection,
                rates: rates,
                isReady: isReady,
            }
        }), [])

    // add booking to cart and finish
    const onBookingConfirmHandler = useCallback(() => {
        setWaiting(true)
        onBookingConfirm(selection)
            .then((cart) => {

                // load available payment gateways
                paymentGatewaysFetcher().then((paymentGateways) => {

                    let selectionData = {
                        cart: cart,
                        client: null,
                        participants: null,
                        payment_gateway: null,
                    }

                    const paymentGateway = paymentGateways.length === 1 ? paymentGateways[0] : null
                    setSelection((selection) => {
                        return {
                            ...selection,
                            cart: cart,
                            paymentGateway: paymentGateway,
                        }
                    })

                    if (paymentGateways.length > 1) {
                        setPaymentGateways(paymentGateways)
                        return
                    }
                })
            })
            .catch((error) => setError(error))
            .finally(() => setWaiting(false))
    }, [selection])

    const onCartConfirmHandler = () => {
        setWaiting(true)

        const confirmValues = {
            client: {},
            participants: [],

            // payment gateway could be null for free products
            payment_gateway_id: _get(selection, "paymentGateway.id"),
        }

        // initialize client with empty values
        Object.entries(selection.cart.client.form).forEach(([key, value]) => {
            confirmValues.client[key] = null
        })

        onCartConfirm(selection.cart, confirmValues)
            .then((cart) => {
                setSelection((prev) => ({
                    ...prev,
                    cart: cart,
                }))
            })
            .catch((error) => setError(error))
            .finally(() => setWaiting(false))
    }

    // reset selection 
    const resetSelection = () => {
        setError(null)
        setPaymentGateways([])
        setSelection((selection) => {
            return {
                ...selection,
                cart: null,
                paymentGateways: null,
            }
        })
    }

    return (
        <Paper elevation={0}>
            <Loading open={waiting} />
            {selection.session !== null && (
                <Grid
                    container
                    spacing={2}
                    direction="row"
                >
                    <Grid item xs={12} md={6}>
                        <RatesSelector
                            product={selection.product.id}
                            date={selection.date}
                            ratesFetcher={ratesFetcher}
                            selected={selection.rates}
                            max={selection.product.max_pax || Number.MAX_SAFE_INTEGER}
                            disabled={selection.cart !== null}
                            onChange={onRatesSelectionHandler}
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <BookingSummary selection={selection} />
                    </Grid>
                    <Grid item xs={12}>
                        {paymentGateways.length > 1 ? (
                            <Stack
                                spacing={2}
                                direction="column"
                                alignItems="center"
                            >
                                <PaymentGatewaysSelector
                                    paymentGateways={paymentGateways}
                                    selection={selection.paymentGateway}
                                    onChange={(value) => setSelection((selection) => ({ ...selection, paymentGateway: value }))}
                                />
                                <Stack spacing={2} direction="row">
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        onClick={() => onBookingConfirmHandler()}
                                        disabled={!selection.cart || !selection.paymentGateway}
                                    >
                                        {t("booking_widget.summary.pay")}
                                    </Button>
                                    <Button
                                        variant="outlined"
                                        color="primary"
                                        onClick={() => resetSelection()}
                                    >
                                        {t("common.cancel")}
                                    </Button>
                                </Stack>
                            </Stack>
                        ) : (
                            <Box display="flex" justifyContent="center">
                                <Button
                                    variant="contained"
                                    color="primary"
                                    onClick={() => onBookingConfirmHandler()}
                                    disabled={!selection.isReady}
                                >
                                    {t("booking_widget.summary.confirm")}
                                </Button>
                            </Box>

                        )}
                    </Grid>
                </Grid >
            )}
        </Paper >
    )
}

export default OneClickBookingWidget