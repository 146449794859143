import { useTranslation } from "react-i18next"
import constants from "../../../../components/entity/constants"
import { useVolcanoApiClient } from "../../../../context/VolcanoApiClientProvider"
import {
    createEditableListFormField,
    createCallbackField, createFormFieldset, createFormField, createField, createRangeDatesField,
} from "../../../../components/helpers/fieldHelper"
import AsyncEntityFormPage from "../../../../layout/templates/AsyncContentPage/AsyncEntityFormPage"
import {useNavigate, useParams} from "react-router-dom"
import availableRoutes from "../../../../routes/availableRoutes"


const RouteConfigurationPage = () => {
    const { apiClient } = useVolcanoApiClient()
    const { t } = useTranslation("vbms")
    const navigate = useNavigate()
    const { entityId } = useParams()

    const getItemTime = (value) => {
        let date = new Date();
        let [hours, minutes, seconds] = value.split(':').map(Number);

        date.setHours(hours || 0);
        date.setMinutes(minutes || 0);
        date.setSeconds(seconds || 0);

        return date
    }

    const getItemFormated = (item) => {
        return {
            date_from: new Date(item.date_from),
            date_to: new Date(item.date_to),
            config: {
                start_time: getItemTime(item.config.start_time),
                weekdays: item.config.weekdays,
            }
        }
    }

    const fetcher = () => {
        return apiClient.activityManager.route
            .getRoute(entityId, [])
            .then((route) => {
                const configuration = route.configuration.map((item) => {
                  return getItemFormated(item)
                })
                return {
                    ...route,
                    configuration: configuration
                }
            })
    }

    const handleClose = () => {
        navigate(
            availableRoutes.route_view.path.replace(":entityId", entityId)
        )
    }

    const onConfirm = (values) => {
        const configuration = values.configuration.map((item) => {
            return {
                config: {
                    weekdays: item.config.weekdays,
                    start_time: item.config.start_time.toISOString().split("T")[1].slice(0, 5),
                },
                date_from: item.date_from.toISOString().split("T")[0],
                date_to: item.date_to.toISOString().split("T")[0]
            }
        })

        const routeUpdate = {
            configuration: configuration,
        }

        return apiClient.activityManager.route
            .updateConfiguration(entityId, routeUpdate)
            .then(() => {
                navigate(
                    availableRoutes.route_view.path.replace(":entityId", entityId)
                )
            })
    }

    const formContent = [
        createFormFieldset(t("routes.form.fieldset_configurations"), [
            createEditableListFormField(
                "configuration",
                null,
                "",
                t("routes.actions.set_pickup_points.modal_title"),
                null,
                false,
                true,
                true,
                null,
                [
                        createFormField(
                            createRangeDatesField(
                                "date_from",
                                t("routes.fields.date_from"),
                                "date_to",
                                true
                            ),
                            true,
                            null
                        ),
                        createFormField(
                            createRangeDatesField(
                                "date_to",
                                t("routes.fields.date_to"),
                                "date_from",
                                false
                            ),
                            true,
                            null
                        ),
                        createFormField(
                            createField(
                                "config.start_time",
                                t("routes.fields.start_time"),
                                constants.TIME_TYPE
                            ),
                            true,
                            null
                        ),
                        createFormField(
                            createField(
                                "config.weekdays",
                                t("routes.fields.weekdays"),
                                constants.WEEKDAYS_TYPE
                            ),
                            true,
                            ""
                        )
                ]
            ),
        ])
    ];

    return (
        <AsyncEntityFormPage
            fetcher={fetcher}
            title={createCallbackField(
                "name",
                t("routes.fields.name"),
                constants.STRING_TYPE,
                (value) => {
                    return t("routes.view.title", { name: value })
                }
            )}
            variant="extended"
            onSave={onConfirm}
            onClose={handleClose}
            formContent={formContent}
        />
    );
};

export default RouteConfigurationPage
