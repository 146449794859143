import { useTranslation } from "react-i18next"
import AsyncEntityForm from "../../../../../components/form/AsyncEntityForm"
import { createFormFieldset, createField } from '../../../../../components/helpers/fieldHelper';
import constants from '../../../../../components/entity/constants';

const BookingSendVoucher = ({ selection, onAction, onCancel }) => {
    const { t } = useTranslation("vbms")

    const fetcher = () => Promise.resolve(selection)

    const formContent = [
        createFormFieldset(null, [
            createField(
                "order.customer.email",
                t("bookings.fields.send_voucher.email"),
                constants.EMAIL_TYPE
            ),
        ])
    ]

    const onConfirm = (result) => {
        onAction({
            ...result,
            locator: selection.locator
        })

    }

    return (
        <AsyncEntityForm
            fetcher={fetcher}
            onSave={onConfirm}
            formContent={formContent}
        />
    )
}

export default BookingSendVoucher