import { useEffect, useState } from "react"
import { DateCalendar, PickersDay } from "@mui/x-date-pickers"
import {
    format as formatDate,
    parseISO,
    startOfMonth,
} from "date-fns"
import { Box, CircularProgress } from "@mui/material"
import PersonIcon from '@mui/icons-material/Person'
import _get from "lodash/get"

const fetchData = async (setter, callback, args) => {
    try {
        const result = await callback(...args)
        setter(result)
    } catch (error) { }
}

/**
 * Returns the available days from the availability array
 * @param {array} availability
 * @param {number} minQuantity
 * @returns {object}
 */
const getAvailableDays = (availability, minQuantity) => {
    return availability
        .filter((day) =>
            day.sessions.reduce((max, session) => Math.max(max, session.available === -1 ? Number.MAX_SAFE_INTEGER : session.available), 0) >= minQuantity
        )
        .map((day) => ({
            date: parseISO(day.date),
            sessions: day.sessions,
        }))
        .reduce((days, day) => ({ ...days, [formatDate(day.date, "yyyy-MM-dd")]: day }), {})
}

/**
 * Returns true if the date is available 
 * @param {Date} date 
 * @param {object} availableDays 
 * @returns 
 */
const isDateAvailable = (date, availableDays) => {
    /*    const today = startOfToday()
        if (date < today) {
            return false
        }*/

    return availableDays.hasOwnProperty(formatDate(date, "yyyy-MM-dd"))
}

const PickersDayWithAvailability = ({ day, available, ...other }) => {
    return (
        <Box position="relative">
            <PickersDay
                sx={{
                    fontSize: "0.8rem",
                    borderRadius: 0,
                    paddingBottom: "0.5rem",
                }}
                day={day}
                {...other}
            />
            <Box
                sx={{
                    color: other.selected ? "white" : "text.primary",
                    fontSize: "0.65rem",
                    position: "absolute",
                    zIndex: 1,
                    bottom: 0,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "flex-end",
                    flexFlow: "row wrap",
                    width: "100%",
                }}
            >
                {available > 0 && (<>
                    <PersonIcon sx={{
                        fontSize: "0.65rem",
                        marginBottom: "2px",
                    }}
                    />
                    {available}
                </>)}
            </Box>
        </Box>
    )
}

const AvailabilityDay = ({ day, selectedDay, availability, ...other }) => {
    const dayStr = formatDate(day, "yyyy-MM-dd")
    let available = null
    if (availability.hasOwnProperty(dayStr) &&
        availability[dayStr].sessions.length === 1 &&
        availability[dayStr].sessions[0].session === "day_wide") {
        available = availability[dayStr].sessions[0].available
    }

    return <PickersDayWithAvailability day={day} available={available} {...other} />
}

const AvailabilityCalendar = ({ selected, availabilityFetcher, languagesWeekdays, minQuantity, onSelection }) => {
    const [availability, setAvailability] = useState([])
    const [isFetching, setIsFetching] = useState(false)

    minQuantity = minQuantity || 1

    const updateAvailability = (availability) => {
        const availableDays = getAvailableDays(availability, minQuantity)
        setAvailability(availableDays)
        setIsFetching(false)
    }

    useEffect(() => {
        setIsFetching(true)
        fetchData(updateAvailability, availabilityFetcher, [])
    }, [])

    const onMonthChangeHandler = (date) => {
        setIsFetching(true)
        fetchData(updateAvailability, availabilityFetcher, [startOfMonth(date)])
    }

    const onDateSelectionHandler = (date) => onSelection(_get(availability, formatDate(date, "yyyy-MM-dd")))

    return (
        <DateCalendar
            minDate={startOfMonth(AvailabilityDay)}
            loading={isFetching}
            renderLoading={() => <CircularProgress />}
            shouldDisableDate={(date) => !isDateAvailable(date, availability)}
            slots={{
                day: AvailabilityDay,
            }}
            slotProps={{
                day: {
                    availability,
                },
            }}
            onMonthChange={onMonthChangeHandler}
            onChange={onDateSelectionHandler}
            sx={{ width: { xs: 'auto', md: '320px'}}}
        />
    )
}

export default AvailabilityCalendar
