import constants from "../../../components/entity/constants";
import { createField, createLinkField, notificationTemplateTypesMapping } from '../../../components/helpers/fieldHelper';

export const getTableColumns = (t, user) => ([
    createLinkField(
        createField(
            "name",
            t("notification_templates.fields.name"),
            constants.TEXT_TYPE
        ),
        {
            route: "notification_templates",
            field: "id",
        }
    ),
    createField(
        "system_template",
        t("notification_templates.fields.system_template"),
        constants.BOOLEAN_TYPE
    ),
    createField(
        "notification_template_type",
        t("notification_templates.fields.type"),
        constants.STRING_TYPE,
        notificationTemplateTypesMapping(t)
 
    ),
    createField(
        "created",
        t("notification_templates.fields.created"),
        constants.DATETIME_TYPE
    )
])