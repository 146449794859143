import constants from '../../../../components/entity/constants';
import { createField, createDataSetFacet, createFacetField, createFacetsContainer, createObjectStatusField, createCallbackField } from '../../../../components/helpers/fieldHelper';
import _isEmpty from 'lodash/isEmpty';

export const getHeaderContent = (t, user) => [
    createDataSetFacet(t("facility_book_lines.view.facet_information"), [
        createField(
            "id",
            "Id",
            constants.STRING_TYPE
        ),
        createCallbackField(
            "open_time",
            t("facility_book_lines.fields.open_time"),
            constants.STRING_TYPE,
            (value) => {
                if (!_isEmpty(value)) {
                    let [hours, minutes] = value.split(":")
                    return `${hours}:${minutes}`
                }
            }

        ),
        createCallbackField(
            "close_time",
            t("facility_book_lines.fields.close_time"),
            constants.STRING_TYPE,
            (value) => {
                if (!_isEmpty(value)) {
                    let [hours, minutes] = value.split(":")
                    return `${hours}:${minutes}`
                }
            }
        ),

        createField(
            "responsable_user.name",
            t("facility_book_lines.fields.responsable_user"),
            constants.STRING_TYPE
        ),
    ]),
    createFacetsContainer([
        createFacetField("object_status", {
            size: "small",
            field: createObjectStatusField(
                createField(
                    "blocked",
                    t("facility_book_lines.fields.blocked"),
                    constants.BOOLEAN_TYPE
                ),
                null,
                (value) => (value ? "negative" : "positive")
            ),
        }),
        createFacetField("object_status", {
            size: "small",
            field: createObjectStatusField(
                createField(
                    "facility_book_state.name",
                    t("facility_book_lines.fields.facility_book_state"),
                    constants.STRING_TYPE
                ),
                null,
                (value) => {
                    switch (value) {
                        case "medio":
                            return "critical";
                        case "inactiva":
                            return "negative";
                        default:
                            return "neutral";
                    }
                }
            ),
        }),
    ]),
    createDataSetFacet(t("facility_book_lines.view.facet_comments"), [
        createField(
            "comments",
            t("facility_book_lines.fields.comments"),
            constants.STRING_TYPE
        )
    ])
]