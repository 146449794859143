import availableRoutes from '../../../routes/availableRoutes'
import userPermissions from "../../../lib/user-permissions";

export const getActions = (t, navigate, user) => [
    {
        title: t("settings_roles.add"),
        onExecute: (user, progressHandler, onSuccess, onError) => {
            navigate(availableRoutes.settings_roles_add.path)
        },
        condition: () => user.hasPermission(userPermissions.PERM_ROL_ADMINISTER)
    },
]