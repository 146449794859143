import constants from "../../../components/entity/constants"
import startOfMonth from "date-fns/startOfMonth"
import { createField, createFormField, createFormFieldOption, createSelectFormField, createAsyncSelectFormField } from '../../../components/helpers/fieldHelper';
import { buildOptions } from "../../../lib/form-options-fetchers";
import { getIntermediariesClientsFilter } from "../../../lib/collection-filters";
import _has from "lodash/has"
import _get from "lodash/get"
import _isEmpty from "lodash/isEmpty"

export const getTableFilter = (apiClient, t, user, cache) => {
    const type = _has(user, "crm_intermediary") ? "recipient" : "issuer"

    return [
        createField(
            "title",
            t("invoices.fields.title"),
            constants.TEXT_TYPE
        ),
        createField(
            "booking_locator",
            t("bookings.fields.locator"),
            constants.TEXT_TYPE
        ),
        createSelectFormField(
            "state",
            t("invoices.fields.state"),
            [
                createFormFieldOption("", t("table.all")),
                createFormFieldOption("issued", t(`invoices.constants.state.${type}.issued`)),
                createFormFieldOption("payment_pending", t(`invoices.constants.state.${type}.payment_pending`)),
                createFormFieldOption("paid", t(`invoices.constants.state.${type}.paid`)),
                createFormFieldOption("refunded", t(`invoices.constants.state.${type}.refunded`)),
            ]
        ),
        createFormField(
            createField(
                "date_from",
                t("invoices.filter.date_from"),
                constants.DATE_TYPE
            ),
            true,
            startOfMonth(new Date())
        ),
        createFormField(
            createField(
                "date_to",
                t("invoices.filter.date_to"),
                constants.DATE_TYPE
            ),
            true,
            new Date()
        ),
        getIntermediariesClientsFilter(apiClient, t, user, 'collaborator_id'),
        createField(
            "code",
            t("invoices.filter.code"),
            constants.TEXT_TYPE
        ),
        createAsyncSelectFormField(
            "currency",
            t("bookings.filter.currencies"),
            () => {
                const enterpriseId = parseInt(_get(user, "corporate_account.enterprise.id", "-1"))
                let result = [
                    createFormFieldOption("", t("table.all")),
                    createFormFieldOption("main", t("currencies.fields.main")),
                    createFormFieldOption("others", t("currencies.fields.others"))
                ]

                return cache.get("enterprises", []).then((enterprises) => {

                    if (_isEmpty(enterprises)) {
                        return result
                    }

                    const enterprise = enterprises.find((enterprise) => enterprise.id === enterpriseId)
                    if (enterprise.currencies) {
                        result = [...result, ...buildOptions(enterprise.currencies.available, t, "id", "id", false)]
                    }

                    return result
                })
            },
            false,
            () => user.corporate_account.enterprise.id === 1 // Filter available only for Volcano
        ),
    ]
}