import { useEntityContext } from "../entity/context/entity/entity.context"
import BaseActionsGroup from "./BaseActionsGroup";

const EntityActionsGroup = ({ actions, variant, actionsNumberMobile, actionsNumberDesktop }) => {
    return (
        <BaseActionsGroup
            selection={useEntityContext()}
            actions={actions}
            variant={variant}
            actionsNumberMobile={actionsNumberMobile}
            actionsNumberDesktop={actionsNumberDesktop}
        />
    )
}

export default EntityActionsGroup