import { createExportField } from "../../../components/helpers/fieldHelper";

export const getTableExport = (apiClient, t) => {
    return (filter, progressHandler) => {
        apiClient.billingInformation.exportBillingInformations(
            [
                createExportField("id", "Id"),
                createExportField("vat_number", t("billing_clients.fields.vat_number")),
                createExportField("name", t("billing_clients.fields.name")),
                createExportField("enterprise.name", t("billing_clients.fields.enterprise")),
            ],
            filter,
            progressHandler
        )
    }
} 