import { Box, Collapse, Typography } from "@mui/material"
import { useTranslation } from "react-i18next"
import SelectionActionsGroup from "../../action/SelectionActionsGroup";
import { useDataTableContext } from "../../entity/context/collection/collection.context";

const DataTableToolbar = (props) => {
    const { t } = useTranslation("vbms")
    const [table] = useDataTableContext()

    const { selected, actions } = props
    const numSelected = selected ? selected.length : 0

    // check if actions are available for selected items
    const filteredActions = actions && numSelected > 0 ?
        actions.filter((action) => action.condition ? action.condition(table.result.actions, selected) : true)
        : []

    return (
        <Collapse in={numSelected > 0 && filteredActions.length > 0}>
            <Box
                sx={{
                    pl: { sm: 2 },
                    pr: 1,
                    pt: 1,
                    pb: 1,
                }}
                display="flex"
                justifyContent="space-between"
                alignItems="center"
            >
                <Box>
                    {numSelected > 0 && (
                        <Typography
                            color="inherit"
                            variant="body1"
                            component="div"
                        >
                            {t("table.selectedWithCount", { count: numSelected })}
                        </Typography>
                    )}
                </Box>
                <Box>
                    {filteredActions && (
                        <SelectionActionsGroup actions={filteredActions} variant="outlined" selection={selected} />
                    )}
                </Box>
            </Box>
        </Collapse>
    )
}

export default DataTableToolbar