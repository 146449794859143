import { useTranslation } from "react-i18next"
import { useVolcanoApiClient } from "../../../context/VolcanoApiClientProvider"
import CollectionPage from "../../../layout/templates/CollectionPage/CollectionPage"
import { useVolcanoAuth } from '../../../context/VolcanoAuthContext'
import { useNavigate } from 'react-router-dom'
import { getTableFilter } from './NotificationTemplatesCollection.filter'
import { getActions } from "./NotificationTemplatesCollection.actions"
import { getTableColumns } from './NotificationTemplatesCollection.columns'
import { useCache } from "../../../context/cache.context"
import _get from "lodash/get"
import _isArray from "lodash/isArray"
import availableRoutes from "../../../routes/availableRoutes"

const NotificationTemplatesCollectionPage = () => {
    const { apiClient, siteConfig } = useVolcanoApiClient()
    const { t } = useTranslation("vbms")
    const volcanoAuth = useVolcanoAuth()
    const navigate = useNavigate()
    const cache = useCache()

    const filterConfig = getTableFilter(apiClient, siteConfig, t, volcanoAuth.user, cache)

    const actions = [
        {
            id:"add_template",
            scope: "table",
            title: t("notification_templates.actions.add_title"),
            onExecute: (user, progressHandler, onSuccess, onError) => {
                navigate(availableRoutes.notification_templates_add.path)
            },
            condition: () => volcanoAuth.user.hasPermission('administer-any-notification-template')
        },
    ]

    const table = {
        columns: getTableColumns(t, volcanoAuth.user),
        actions: getActions(t, navigate, volcanoAuth.user, apiClient),
        fetcher: (params) => {

            let productsFilter = [];
            if (_get(params, "product_id", null)) {
                if (_isArray(_get(params, "product_id", null))) {
                    _get(params, "product_id", null).forEach(prd => {
                        productsFilter.push(prd.id)
                    });
                } else {
                    productsFilter.push(_get(params, "product_id", null));
                }
            }

            const queryFilter = {
                ...params,
                product_id: productsFilter
            }

            return apiClient.notifications.getNotificationTemplates(queryFilter)
        }
    };

    return ( 
        <CollectionPage
            title={t("notification_templates.title")}
            subtitle={t("notification_templates.subtitle")}
            actions={actions}
            table={table}
            filterConfig={filterConfig}
        />
    )
}

export default NotificationTemplatesCollectionPage;
