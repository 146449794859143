import { useTranslation } from "react-i18next";
import constants from "../../../../../../components/entity/constants";
import {
    createField
} from "../../../../../../components/helpers/fieldHelper";
import ArrayDataTable from "../../../../../../components/Datatable/ArrayDataTable";

const ActivityConfigProducts = (props) => {

    const { t } = useTranslation("vbms")

    const columns = [
        createField(
            "experience.name",
            t("activity_configs.fields.experience"),
            constants.STRING_TYPE
        ),
        createField(
            "name",
            t("activity_configs.fields.product"),
            constants.STRING_TYPE
        ),
        createField(
            "with_resources",
            t("activity_configs.fields.resources"),
            constants.BOOLEAN_TYPE
        ),
        createField(
            "allow_multiple_activities",
            t("activity_configs.fields.allow_multiple_activities"),
            constants.BOOLEAN_TYPE
        ),
        createField(
            "ignore_sessions",
            t("activity_configs.fields.ignore_sessions"),
            constants.BOOLEAN_TYPE
        ),

    ]

    return (
        <ArrayDataTable
            data={props.data ?? []}
            columns={columns}
        />
    )

}

export default ActivityConfigProducts
