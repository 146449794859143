import { downloadFile, getSuggestedForCollaboratorFilename, openInNewTab } from "../../../lib/utils"

export const getShareActions = (user, apiClient, entityId, t) => [
    {
        id: "link",
        title: t("common.actions.share.options.link"),
        onExecute: (liquidation, progressHandler, onSuccess, onError) => {
            navigator.clipboard.writeText(window.location.href)
            onSuccess(t("common.actions.share.confirm"))
        },
    },
    {
        id: "link_pdf",
        title: t("common.actions.share.options.open_pdf"),
        onExecute: (liquidation, progressHandler, onSuccess, onError) => {
            const data = {
                resource: "liquidations",
                operation: "liquidation_pdf",
                id: liquidation.id,
                params: {
                    title: liquidation.title
                }
            }
            return apiClient.download.createLink(data)
                .then((link) => {
                    openInNewTab(link.url);
                })
                .catch((error) => {
                    onError(t("common.actions.share.error_open"))
                })
        },
    }
    ,
    {   
        id: "download_pdf",
        title: t("common.download"),
        showLoading: true,
        onExecute: (liquidation, progressHandler, onSuccess, onError) => {
            const params = {
                title: liquidation.title
            }
            return apiClient.liquidation
                .getLiquidationPdf(liquidation.id, params)
                .then(pdf => {
                    downloadFile(pdf, getSuggestedForCollaboratorFilename(liquidation.title, liquidation.collaborator) + ".pdf")
                })
                .finally(() => true)
        },
    }
]