import { memo, useEffect, useState } from "react"
import { KeyboardArrowDown, KeyboardArrowUp } from "@mui/icons-material"
import { Box, Collapse, Grid, IconButton } from "@mui/material"
import _isArray from "lodash/isArray"

const pinBaseStyle = {
    content: '""',
    position: "absolute",
    width: "4rem",
    top: "50 %",
    height: "0.0625rem",
}

const CollapseContainerBase = ({ disableCollapse, open, onToogle, children }) => {
    const content = _isArray(children) ? children : [children]

    return (
        <Box>
            <Collapse in={open}>
                <Grid
                    item
                    xs={12}
                    container
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="flex-start"
                    spacing={3}
                    pt={2}
                    pb={2}
                >
                    {content.map((item, index) => (
                        <Grid key={index} item xs={12} md="auto" width="100%">
                            {item}
                        </Grid>
                    ))}
                </Grid>
            </Collapse>
            {!disableCollapse && (<Box
                m={-1}
                display="flex"
                justifyContent="center"
                alignItems="center"
            >
                <IconButton
                    size="small"
                    onClick={onToogle}
                    sx={{
                        //border: 1,
                        borderRadius: "30%",
                        borderColor: "common.border",
                        boxShadow: "0 0 0.125rem 0 rgb(85 107 130 / 16%), 0 0.125rem 0.25rem 0 rgb(85 107 130 / 16%)",
                        padding: 0,
                        "&::before": {
                            ...pinBaseStyle,
                            backgroundImage: "linear-gradient(to left, #d9d9d9, transparent)",
                            right: "100%",
                        },
                        "&::after": {
                            ...pinBaseStyle,
                            backgroundImage: "linear-gradient(to right, #d9d9d9, transparent)",
                            left: "100%",
                        },
                    }}>
                    {open ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
                </IconButton>
            </Box>)}
        </Box>
    )
}

const checkProps = (prevProps, nextProps) => prevProps.open === nextProps.open

const MemoizedCollapseContainer = memo(CollapseContainerBase, checkProps)

const CollapseContainer = ({ disableCollapse, collapsed, onChange, noMemoized, children }) => {
    const [open, setOpen] = useState(true)

    useEffect(() => {
        if (disableCollapse) {
            return
        }

        setOpen(!collapsed)
    }, [disableCollapse, collapsed])

    useEffect(() => {
        onChange && onChange(open)
    }, [open, onChange])

    const onToogleHandler = () => {
        setOpen(!open)
    }

    if (noMemoized) {
        return <CollapseContainerBase disableCollapse={disableCollapse} open={open} onToogle={onToogleHandler}>{children}</CollapseContainerBase>
    }

    return <MemoizedCollapseContainer disableCollapse={disableCollapse} open={open} onToogle={onToogleHandler}>{children}</MemoizedCollapseContainer>
}

export default CollapseContainer