import { useTranslation } from 'react-i18next';
import constants from '../../../../../components/entity/constants';
import { createCallbackField, createField } from '../../../../../components/helpers/fieldHelper';
import ArrayDataTable from '../../../../../components/Datatable/ArrayDataTable';
import DisfrutareLink from '../../../../../components/Volcano/Disfrutare/DisfrutareLink';

const SiteFeaturedProducts = (props) => {
    const { t } = useTranslation("vbms")

    const columns = [
        createCallbackField(
            "name",
            t("products.fields.name"),
            constants.STRING_TYPE,
            (value, entity) => <DisfrutareLink href={"/admin/products/view/" + entity.id}>{entity.name}</DisfrutareLink>
        ),
        createField(
            "active",
            t("products.fields.active"),
            constants.BOOLEAN_TYPE
        ),
    ]

    return (
        <ArrayDataTable
            data={props.data}
            columns={columns}
        />
    )
}

export default SiteFeaturedProducts