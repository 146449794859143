import availableRoutes from "../../../../routes/availableRoutes";
import _has from "lodash/has";
import PickupPointForm from "../PickupPointForm";

export const getActions = (apiClient, t, user, navigate, entityId) => {


    return [
        {
            id: "edit",
            title: t("common.edit"),
            reloadOnSuccess: true,
            showLoading: true,
            withDialog: {
                title: t("pickup_points.actions.edit.modal_title"),
                content: PickupPointForm
            },
            onExecute: (item, progressHandler, onSuccess, onError) => {
                return apiClient.activityManager.pickupPoint.editPickupPoint(entityId, item)
                    .then((result) => {
                        onSuccess(
                            t("pickup_points.actions.edit.confirm", {
                                name: result.name,
                            })
                        )

                        return { ...result, id: entityId }
                    })
                    .catch((error) => {
                        onError(
                            t("pickup_points.actions.edit.error", {
                                name: item.name,
                            })
                        )
                    })
            },
            condition: (item) => _has(item.getActions(), "edit")
        },
        {
            id: "delete",
            title: t("common.delete"),
            reloadOnSuccess: true,
            showLoading: true,
            confirmDialog: {
                title: t("pickup_points.actions.delete.modal_title"),
                content: (item) =>
                    t("pickup_points.actions.delete.modal_content", {
                        name: item.name,
                    }),
            },
            onExecute: (item, progressHandler, onSuccess, onError) => {
                return apiClient.activityManager.pickupPoint.deletePickupPoint(item.id)
                    .then(() => {
                        onSuccess(
                            t("pickup_points.actions.delete.confirm", {
                                name: item.name,
                            })
                        )
                        navigate(availableRoutes.pickup_points.path)
                    })
                    .catch((error) => {
                        onError(
                            t("pickup_points.actions.delete.error", {
                                name: item.name,
                            })
                        )
                    })
            },
            condition: (item) => _has(item.getActions(), "delete")
        }
    ]
}