import { useTranslation } from "react-i18next"
import { useSearchParams } from "react-router-dom"
import { useVolcanoApiClient } from "../../../context/VolcanoApiClientProvider"
import Loading from "../../../components/Display/Loading"
import { AsyncContextProvider } from "../../../context/async.context"
import { Alert, AlertTitle, Box, Paper } from "@mui/material"
import KeyValue from "../../../components/Display/KeyValue"
import { useCallback, useEffect, useState } from "react"

const PaymentTransactionResultLoading = ({ open, title, messagesKey }) => {
    const { t } = useTranslation("vbms")

    title = title ? t(title) : t(`payment_transactions.${messagesKey}.loading`)

    return (
        <Loading open={open} title={title} />
    )
}

const PaymentTransactionResultError = ({ error, title, subtitle, severity, messagesKey }) => {
    const { t } = useTranslation("vbms")

    title = title ? t(title) : t(`payment_transactions.${messagesKey}.fail.title`)
    subtitle = subtitle ? t(subtitle) : t(`payment_transactions.${messagesKey}.fail.subtitle`)
    severity = severity ? severity : "error"

    return (
        <Paper elevation={6} sx={{ margin: 3 }}>
            <Alert severity={severity}>
                <AlertTitle>{t(`payment_transactions.${messagesKey}.fail.title`)}</AlertTitle>
                <Box>{subtitle}</Box>
                {error && <KeyValue label={t(`payment_transactions.${messagesKey}.fail.message_label`)} labelVariant="body2">{error.message}</KeyValue>}
            </Alert>
        </Paper>
    )
}

const PaymentTransactionResultBasePage = ({ transationType, transactionResultFetcher }) => {
    const [searchParams, setSearchParams] = useSearchParams()
    const { apiClient } = useVolcanoApiClient()
    const [state, setState] = useState({ status: null, attempts: 0 })
    const messagesKey = transationType === "booking" ? "result" : "token_result"

    const transactionStatusFetcher = useCallback(() => {
        return apiClient.paymentTransaction.getTransaction(searchParams.get("vte_transaction_id"))
    }, [apiClient.paymentTransaction, searchParams])

    const queryPaymentStatus = useCallback(() => {
        transactionStatusFetcher().then(paymentTransaction => {
            setState({
                status: paymentTransaction.status,
                attempts: state.attempts + 1
            })
        }).catch(() => {
            setState({
                ...state,
                attempts: state.attempts + 1
            })
        })

        return
    }, [state, transactionStatusFetcher])

    useEffect(() => {
        if (state.attempts < 7) {
            // wait until the status is pending until max attempts
            if (!state.status || state.status === "pending") {
                if (state.attempts === 0) {
                    queryPaymentStatus()
                    return
                }

                setTimeout(() => queryPaymentStatus(), 30000)
            }
        } else {
            if (state.status !== "pending_warning") {
                setState({
                    ...state,
                    status: "pending_warning"
                })
            }
        }
    }, [state, queryPaymentStatus])

    return (
        <>
            {(!state.status || state.status === "pending") && <PaymentTransactionResultLoading
                open={!state.status || state.status === "pending"}
                title={`payment_transactions.${messagesKey}.loading_status`}
                messagesKey={messagesKey}
            />}
            {state.status && <>
                {!["pending", "pending_warning"].includes(state.status) && (
                    <AsyncContextProvider
                        fetcher={transactionResultFetcher}
                        loadComponent={(open) => (
                            <PaymentTransactionResultLoading
                                open={open}
                                messagesKey={messagesKey}
                            />
                        )}
                        errorComponent={(error) => <PaymentTransactionResultError error={error} messagesKey={messagesKey} />}
                    />
                )}
                {state.status === "pending_warning" && <PaymentTransactionResultError
                    messagesKey={messagesKey}
                    severity="warning"
                    title={`payment_transactions.${messagesKey}.fail.title_pending`}
                    subtitle={`payment_transactions.${messagesKey}.fail.subtitle_pending`}
                />}
            </>}
        </>
    )
}

export default PaymentTransactionResultBasePage