import { format as formatDate } from 'date-fns';
import { useParams } from 'react-router-dom'
import { useTranslation } from "react-i18next"
import { useVolcanoApiClient } from "../../../../context/VolcanoApiClientProvider"
import { useNavigate } from 'react-router-dom'
import { useVolcanoAuth } from '../../../../context/VolcanoAuthContext'
import { createCallbackField } from '../../../../components/helpers/fieldHelper'
import constants from '../../../../components/entity/constants'
import AsyncEntityPage from "../../../../layout/templates/AsyncContentPage/AsyncEntityPage"
import { getHeaderContent } from './FacilityBookLinePage.header'
import { getTabNavigationItems } from './FacilityBookLinePage.tabs'
import { getActions } from './FacilityBookLinePage.actions'


const FacilityBookLinePage = () => {
    const { t } = useTranslation()
    const { entityId } = useParams()
    const { apiClient } = useVolcanoApiClient()
    const { user } = useVolcanoAuth()
    const navigate = useNavigate()

    const fetcher = () => {
        return apiClient.venue.facilityBookLine.getFacilityBookLine(entityId).then((item) => {
            item.comments = item.comments?.replaceAll("<br />", "\n").replaceAll("\n\n", "\n").replaceAll("&nbsp;", " ");
            return item
        })
    }

    return (
        <AsyncEntityPage
            fetcher={fetcher}
            title={createCallbackField(
                "date",
                t("facility_book_lines.view.title"),
                constants.STRING_TYPE,
                (entity, values) => t("facility_book_lines.view.title", {
                    facility_book_name: values.facility_book.name,
                    date: formatDate(new Date(values.date), "dd/MM/yyyy")
                })
            )}
            headerContent={getHeaderContent(t, user)}
            tabNavigationItems={getTabNavigationItems(t)}
            actions={getActions(apiClient, t, {}, navigate, entityId)}
        />
    );
}

export default FacilityBookLinePage;