import { Box, Stack } from "@mui/material"
import Text from "../../Display/Text";
import HtmlFieldValue from "../../entity/HtmlFieldValue";
import _isEmpty from "lodash/isEmpty"

const LanguagesWeekdays = ({rules}) => {

    if (_isEmpty(rules)) {
        return null
    }

    return (
        <Stack spacing={2}>
            {rules.map((rule) => 
                <Box key={rule.title}>
                    <Text variant="h6">
                        <HtmlFieldValue value={rule.title}/>
                    </Text>
                    <Stack direction="row" spacing={1}>
                        {rule.language.map((language) => {
                        const icon = language === "en" ? "gb" : language
                        return (
                            <span key={language} className={`fi fi-${icon}`}></span>
                        )})}
                    </Stack>
                </Box>
            )}
        </Stack>
    )
}

export default LanguagesWeekdays