import { Edit, Delete } from "@mui/icons-material"
import DiscountCodeAddProduct from "./components/actions/DiscountCodeProducts/DiscountCodeAddProduct"
import availableRoutes from "../../../routes/availableRoutes"

export const getActions = (apiClient, t, user, itemProps, navigate, entityId = null) => {
    const { scope, icon, reloadOnSuccess, showLoading } = { ...itemProps }
    return [
        {
            id: "add_product",
            title: t("discount_codes.actions.add_product.button"),
            withDialog: {
                title: t("discount_codes.actions.add_product.modal_title"),
                content: DiscountCodeAddProduct
            },
            reloadOnSuccess: reloadOnSuccess,
            showLoading: showLoading,
            onExecute: (data, progressHandler, onSuccess, onError) => {
                return apiClient.catalog.discountCode.addProduct(entityId, data.products)
                    .then((data) => {
                        navigate(availableRoutes.discount_codes_view.path.replace(":entityId", entityId))
                        onSuccess(
                            t("discount_codes.actions.add_product.confirm")
                        )
                    })
                    .catch((error) => {
                        onError(
                            t("discount_codes.actions.add_product.error")
                        )
                    })
            },
            condition: (discountCode) => discountCode.hasAction("edit")
        },
        {
            id: "change_state_active",
            title: t("discount_codes.actions.change_state_active.button"),
            confirmDialog: {
                title: t("discount_codes.actions.change_state_active.modal_title"),
                content: (discountCode) =>
                    t("discount_codes.actions.change_state_active.modal_content", {
                        name: discountCode.name
                    }),
            },
            reloadOnSuccess: reloadOnSuccess,
            showLoading: true,
            onExecute: (result, progressHandler, onSuccess, onError) => {
                let discountCode = { ...result, active: true }

                return apiClient.catalog.discountCode.editDiscountCode(entityId, discountCode)
                    .then((discountCode) => {
                        onSuccess(
                            t("discount_codes.actions.change_state_active.confirm", {
                                name: discountCode.name,
                            }))
                        navigate(`${availableRoutes.discount_codes.path}/${entityId}`)
                    })
                    .catch((error) => {
                        onError(
                            t("discount_codes.actions.change_state_active.error", {
                                name: discountCode.name,
                            }))
                    })
            },
            condition: (discountCode) => discountCode.hasAction("edit") && !discountCode.active
        },
        {
            id: "change_state_inactive",
            title: t("discount_codes.actions.change_state_inactive.button"),
            confirmDialog: {
                title: t("discount_codes.actions.change_state_inactive.modal_title"),
                content: (discountCode) =>
                    t("discount_codes.actions.change_state_inactive.modal_content", {
                        name: discountCode.name
                    })
            },
            reloadOnSuccess: reloadOnSuccess,
            showLoading: true,
            onExecute: (result, progressHandler, onSuccess, onError) => {
                let discountCode = { ...result, active: false }

                return apiClient.catalog.discountCode.editDiscountCode(entityId, discountCode)
                    .then((discountCode) => {
                        onSuccess(
                            t("discount_codes.actions.change_state_inactive.confirm", {
                                name: discountCode.name
                            }), 
                        )
                        navigate(`${availableRoutes.discount_codes.path}/${entityId}`)
                    })
                    .catch((error) => {
                        onError(
                            t("discount_codes.actions.change_state_inactive.error", {
                                name: discountCode.name,
                            }))
                    })
            },
            condition: (discountCode) => discountCode.hasAction("edit") && discountCode.active
        },
        {
            id: "edit",
            title: t("common.edit"),
            icon: icon && Edit,
            reloadOnSuccess: reloadOnSuccess,
            showLoading: showLoading,
            onExecute: (discountCode) => {
                return Promise.resolve(discountCode)
                    .then((discountCode) => {
                        navigate(availableRoutes.discount_codes_edit.path.replace(":entityId", entityId ?? discountCode.id))
                    })
            },
            condition: (discountCode) => discountCode.hasAction("edit")
        },
        {
            id: "delete",
            title: t("common.delete"),
            scope: scope,
            icon: icon && Delete,
            reloadOnSuccess: reloadOnSuccess,
            showLoading: showLoading,
            confirmDialog: {
                title: t("discount_codes.actions.delete.modal_title"),
                content: (discountCode) =>
                    t("discount_codes.actions.delete.modal_content", {
                        name: discountCode.name,
                    }),
            },
            onExecute: (discountCode, progressHandler, onSuccess, onError) => {
                return apiClient.catalog.discountCode.deleteDiscountCode(discountCode.id)
                    .then((result) => {
                        onSuccess(
                            t("discount_codes.actions.delete.confirm", {
                                name: discountCode.name,
                            })
                        )
                        navigate(availableRoutes.discount_codes.path)
                    })
                    .catch((error) => {
                        onError(
                            t("discount_codes.actions.delete.error", {
                                name: discountCode.name,
                            })
                        )

                        throw error
                    })
            },
            condition: (discountCode) => discountCode.hasAction("delete")
        },
    ]
}