import { Box, Drawer, Link, useMediaQuery, useTheme } from "@mui/material"
import LogoSection from "../LogoSection";
import SidebarMenu from "./SidebarMenu";
import { Link as RouterLink } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Text from "../../../components/Display/Text";
import { useVolcanoApiClient } from "../../../context/VolcanoApiClientProvider";
import { useVolcanoAuth } from "../../../context/VolcanoAuthContext"

const drawerWidth = 200

const Sidebar = ({ drawerOpen, drawerToogle, window }) => {
    const { t } = useTranslation("vbms")
    const theme = useTheme()
    const matchUpMd = useMediaQuery(theme.breakpoints.up('md'))
    const { siteConfig } = useVolcanoApiClient()

    const authContext = useVolcanoAuth()

    const drawer = (
        <Box sx={{
            position: "absolute",
            top: 0,
            width: "100%",
            height: "98vh",
            display: "flex",
            flexDirection: "column",
        }}
        >
            <Box
                sx={{
                    display: {
                        xs: 'block',
                        md: 'none',
                        [theme.breakpoints.down('md')]: {
                            minHeight: '56px'
                        }
                    }, '& a img': {
                        maxHeight: "50px",
                        minWidth: "50px",
                        height: "50px",
                        width: "auto",
                    }
                }}>
                <Box>
                    <LogoSection />
                </Box>
            </Box>
            <Box sx={{
                px: 2,
                // calc height - logo height - footer height
                maxHeight: {
                    xs: `calc(100% - 64px - 100px)`,
                    md: `calc(100% - 50px - 100px)`,
                },
                overflowY: "auto",
            }} >
                <SidebarMenu isMobile={!matchUpMd} onItemSelected={drawerToogle} />
            </Box>
            <Box
                sx={{
                    position: 'absolute',
                    left: 0,
                    bottom: {
                        xs: '20px',
                        md: '70px',
                    },
                    width: '100%',
                    height: '70px',
                    borderTop: '1px solid',
                    borderTopColor: theme.palette.common.container.border,
                }}>
                {siteConfig.manuals && 
                    authContext.user.hasPermission("manuals-access") &&  
                    <Box sx={{ p: 2, textAlign: "center" }}>
                        <Link component={RouterLink} to="/manuals">{t("app.manuals")}</Link>
                    </Box>
                }
                <Box sx={{ p: 2, textAlign: "center" }}>
                    <Link component={RouterLink} to="/terms-and-conditions">{t("app.terms_and_conditions")}</Link>
                    <Text>Ver. {siteConfig.version}</Text>
                </Box>
            </Box>
        </Box>
    )

    const container = window !== undefined ? () => window.document.body : undefined;

    return (
        <Box component="nav" sx={{ flexShrink: { md: 0 }, width: matchUpMd ? drawerWidth : 'auto' }}>
            <Drawer
                container={container}
                variant={matchUpMd ? 'persistent' : 'temporary'}
                anchor="left"
                open={drawerOpen}
                onClose={drawerToogle}
                ModalProps={{ keepMounted: true }}
                color="inherit"
                sx={{
                    '& .MuiDrawer-paper': {
                        width: drawerWidth,
                        borderRightColor: theme.palette.common.container.border,
                        [theme.breakpoints.up('md')]: {
                            top: '65px'
                        }
                    }
                }}
            >
                {drawer}
            </Drawer>
        </Box>
    )
}

export default Sidebar