import { Box, Container, Link, Stack, Typography } from "@mui/material"
import { useTranslation } from "react-i18next"
import { Link as RouterLink } from "react-router-dom"

const ErrorPage = ({ code, message, description, hideLink = false }) => {
    const { t } = useTranslation("vbms")

    return (
        <Box
            sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: {
                    xs: "calc(100vh - 57px)",
                    sm: "calc(100vh - 65px)",
                },
            }}
        >
            <Container maxWidth="md">
                <Stack spacing={2} direction="column" justifyContent="center">
                    <Typography variant="h1" align="center">{code}</Typography>
                    <Typography variant="h2" align="center">{message}</Typography>
                    <Typography variant="body1" align="center">{description}</Typography>
                    {!hideLink && <Link component={RouterLink} to="/" variant="body1" align="center">{t("common.errors.link")}</Link>}
                </Stack>
            </Container>
        </Box >
    )
}

export default ErrorPage