import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import { Box, Button, Card, CardContent } from "@mui/material";
import DataContainer from "../../Display/DataContainer";
import HtmlFieldValue from "../../entity/HtmlFieldValue";
import Title from "../../Display/Title";
import { useDialog } from "../../Dialog/dialog.context";
import { useVolcanoApiClient } from "../../../context/VolcanoApiClientProvider";
import SimpleLoading from "../../Display/SimpleLoading";


const PreviewElement = ({ selection }) => {

    const { t } = useTranslation("vbms");
    const { handleClose } = useDialog()
    const { apiClient } = useVolcanoApiClient()

    const [element, setElement] = useState([])
    const [loading, setLoading] = useState(true)

    useEffect(() => {
        setLoading(true)
        apiClient.notifications.getPreview(selection.notification_id, selection.id)
            .then((result) => {
                setElement(result.preview)
                setLoading(false)
            })
    }, [])

    return (
        <><SimpleLoading loading={loading} />
        {!loading && element ? (
            <Box>{element.sms && (
                <DataContainer
                    title={t('notification_templates.constants.sms')}
                    titleVariant={''}
                    titleLevel={'h4'}
                    >
                    <Box key={'box_sms'} mt={1}>
                        <Card
                            key={'card_sms'}
                            variant="outlined"
                            sx={{
                                marginBottom: 2,
                            }}
                        >
                            <CardContent>
                                <HtmlFieldValue value={element.sms.body}/>
                            </CardContent>
                        </Card>                                    
                    </Box>
                </DataContainer>
            )}{element.email && (
                <DataContainer
                    title={t('notification_templates.constants.email')}
                    titleVariant={''}
                    titleLevel={'h4'}
                    >
                    <Box key={'box_mail'} mt={1}>
                        <Card
                            key={'card_mail'}
                            variant="outlined"
                            sx={{
                                marginBottom: 2,
                            }}
                        >
                            <CardContent>
                                <Title level='h4'>{element.email.subject}</Title>
                                <HtmlFieldValue value={element.email.body}/>
                            </CardContent>
                        </Card>                                    
                    </Box>
                </DataContainer>
            )}
                <Button
                    color="primary"
                    variant="contained"
                    type="submit"
                    onClick={() => handleClose()}>
                    {t("common.close")}
                </Button>
            </Box>
            
        ) : (
            <></>
        )}
        </>
    )
}

export default PreviewElement;