import { createContext, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useTranslation } from "react-i18next"
import VolcanoApi from "@volcanoteide/volcanoteide-api-client"
import Loading from "../components/Display/Loading";

const getApiKey = (hostname) => {
    // Just for local development testing
    if (process.env.REACT_APP_API_SITE_KEY) {
        return process.env.REACT_APP_API_SITE_KEY
    }

    // Staging/Prod
    switch (hostname) {
        case 'admin.volcanoteide.com':
        case 'admin.dev.volcanoteide.com':
        case 'admin.stg.volcanoteide.com':
        case 'admin.staging.volcanoteide.com':
            return 'admin_volcano'
        case 'partners.volcanoteide.com':
        case 'partners.dev.volcanoteide.com':
        case 'partners.stg.volcanoteide.com':
        case 'partners.staging.volcanoteide.com':
            return 'partners_volcano'
        case 'admin.caminobarrancodemasca.com':
        case 'admin.dev.caminobarrancodemasca.com':
        case 'admin.stg.caminobarrancodemasca.com':
        case 'admin.pre.caminobarrancodemasca.com':
            return 'admin_caminomasca'
        case 'agencias.caminobarrancodemasca.com':
        case 'partners.caminobarrancodemasca.com':
        case 'agencias.dev.caminobarrancodemasca.com':
        case 'partners.dev.caminobarrancodemasca.com':
        case 'agencias.stg.caminobarrancodemasca.com':
        case 'partners.stg.caminobarrancodemasca.com':
        case 'agencias.pre.caminobarrancodemasca.com':
        case 'partners.pre.caminobarrancodemasca.com':
            return 'partners_caminomasca'
        default:
            return 'admin_volcano'
    }
}

const Context = createContext()

export const VolcanoAPIClientProvider = ({ children }) => {
    const { t, i18n } = useTranslation()
    const [siteConfig, setSiteConfig] = useState(null)

    const apiClient = useMemo(() => new VolcanoApi({
        with_default_params: true, // filters user enterprise
        protocol: process.env.REACT_APP_API_PROTOCOL,
        host: process.env.REACT_APP_API_HOST,
        port: process.env.REACT_APP_API_PORT,
        timeout: process.env.REACT_APP_API_TIMEOUT,
        site_key: getApiKey(window.location.hostname),
        locale: i18n.language,
        bearer_interceptor: () => {
            return localStorage.jwt ? localStorage.jwt : false;
        },
    }), [i18n.language])

    const loadSiteConfig = useCallback(() => {
        if ((!localStorage.config) || (JSON.parse(localStorage.getItem("config")).version !== process.env.REACT_APP_API_SITE_CONFIG_VERSION)) {
            apiClient.vbms.getConfig()
                .then((result) => {
                    localStorage.config = JSON.stringify(result.config)
                    setSiteConfig(result.config)
                })
                .catch((error) => {
                    if (error.message === "Expired token") {
                        localStorage.clear()
                        i18n.changeLanguage(i18n.language)
                        window.location.reload()
                    }
                })
            return
        }

        setSiteConfig(JSON.parse(localStorage.getItem("config")))
    }, [apiClient])

    const reloadSiteConfig = useCallback(() => {
        localStorage.removeItem("config")
        loadSiteConfig()
    }, [loadSiteConfig])

    useEffect(() => {
        loadSiteConfig()
    }, [loadSiteConfig])

    const contextValue = useMemo(() => {
        return {
            apiClient,
            siteConfig,
            reloadSiteConfig
        }
    }, [
        apiClient,
        siteConfig,
        reloadSiteConfig
    ])

    return (
        <Context.Provider value={contextValue}>
            {siteConfig && children}
            {!siteConfig && <Loading open={!siteConfig} title={t('app.loading_config')} />}
        </Context.Provider >
    )
}

export const useVolcanoApiClient = () => useContext(Context)