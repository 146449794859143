import startOfMonth from "date-fns/startOfMonth"
import constants from "../../../components/entity/constants"
import { createBooleanFormField, createField, createFormField, createFormFieldOption, createSelectFormField, createSuggestionTypeMapping } from "../../../components/helpers/fieldHelper"

export const getTableFilter = (t, apiClient, user, cache) => ([
    createSelectFormField(
        "suggestion_type",
        t("suggestions.fields.suggestion_type"),
        [createFormFieldOption("", t("table.all"))].concat(
            Object.entries(createSuggestionTypeMapping(t))
                .map(([key, value]) => createFormFieldOption(key, value))
        )
    ),
    createBooleanFormField(
        t,
        "managed",
        t("suggestions.fields.managed"),
        null,
        true
    ),
    createFormField(
        createField(
            "creation_date_from",
            t("suggestions.filter.creation_date_from"),
            constants.DATE_TYPE
        ),
        false,
        startOfMonth(new Date())
    )
])