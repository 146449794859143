import constants from "../../../components/entity/constants"
import { createField } from "../../../components/helpers/fieldHelper"

export const getTableFilter = (t, apiClient, user, cache) => ([
    createField(
        "vat_number",
        t("billing_clients.fields.vat_number"),
        constants.STRING_TYPE
    ),
    createField(
        "name",
        t("billing_clients.fields.name"),
        constants.STRING_TYPE
    ),
])