import { memo } from "react"
import CollectionDataTable from "../Datatable/CollectionDataTable"

const checkMemo = (oldProps, newProps) => {
    return true
}

const ValueSelectorTable = memo(({ table, multipleSelection, selected, onSelectionChange }) => {

    if (table.filterConfig) {
        table.filterConfig.ignoreSearchParams = true
    }

    return (
        <CollectionDataTable
            {...table}
            hideShare={true}
            selectionMode={multipleSelection ? "multiple" : "single"}
            selected={selected}
            onSelectionChange={onSelectionChange}
        />
    )
}, checkMemo)

export default ValueSelectorTable