import ChangePassword from './components/actions/ChangePassword'

export const getActions = (apiClient, entityId, t, navigate) => [
    {
        id: "change_state_enable",
        title: t("settings_system_users.actions.change_state_enable.button"),
        confirmDialog: {
            title: t("settings_system_users.actions.change_state_enable.modal_title"),
            content: (selection) =>
                t("settings_system_users.actions.change_state_enable.modal_content", {
                    username: selection.username,
                }),
        },
        reloadOnSuccess: true,
        onExecute: (user, progressHandler, onSuccess, onError) => {
            return apiClient.user.setUserActive(user.id, true)
                .then((user) => {
                    onSuccess(
                        t("settings_system_users.actions.change_state_enable.confirm", {
                            name: user.name,
                        })
                    )

                    return user
                })
                .catch((error) => {
                    onError(
                        t("settings_system_users.actions.change_state_enable.error", {
                            name: user.name,
                        })
                    )

                    throw error
                })
        },
        condition: (user) => user.hasAction("change_state") && !user.active
    },
    {
        id: "change_state_disable",
        title: t("settings_system_users.actions.change_state_disable.button"),
        confirmDialog: {
            title: t("settings_system_users.actions.change_state_disable.modal_title"),
            content: (selection) =>
                t("settings_system_users.actions.change_state_disable.modal_content", {
                    username: selection.username,
                }),
        },
        reloadOnSuccess: true,
        onExecute: (user, progressHandler, onSuccess, onError) => {
            return apiClient.user.setUserActive(user.id, false)
                .then((user) => {
                    onSuccess(
                        t("settings_system_users.actions.change_state_disable.confirm", {
                            name: user.name,
                        })
                    )

                    return user
                })
                .catch((error) => {
                    onError(
                        t("settings_system_users.actions.change_state_disable.error", {
                            name: user.name,
                        })
                    )
                    throw error
                })
        },
        condition: (user) => user.hasAction("change_state") && user.active
    },
    {
        id: "edit",
        title: t("common.edit"),
        onExecute: (user, progressHandler, onSuccess, onError) => {
            navigate('/settings/users/' + user.id + '/edit')
        },
        condition: (user) => user.hasAction("edit")
    },
    {
        id: "change_password",
        title: t("settings_system_users.actions.change_password.button"),
        withDialog: {
            title: t("settings_system_users.actions.change_password.modal_title"),
            content: ChangePassword,
        },
        onExecute: (user, progressHandler, onSuccess, onError) => {
            apiClient.user.appUserChangePassword(entityId, user)
                .then((user) => {
                    onSuccess(
                        t("settings_system_users.actions.change_password.confirm", {
                            name: user.name,
                        })
                    )
                })
                .catch((error) => {
                    onError(
                        t("settings_system_users.actions.change_password.error", {
                            name: user.name,
                        })
                    )
                })
        },
        condition: (user) => user.hasAction("reset_password")
    },
    {
        id: "reset_password",
        title: t("settings_system_users.actions.reset_password.button"),
        onExecute: (user, progressHandler, onSuccess, onError) => {
            apiClient.resetPasswordRequest(user.email, user.getCorporateAccount().id)
                .then(() => {
                    onSuccess(
                        t("settings_system_users.actions.reset_password.confirm", {
                            email: user.email,
                        })
                    )
                })
                .catch((error) => {
                    onError(
                        t("settings_system_users.actions.reset_password.error", {
                            email: user.email,
                        })
                    )
                })
        },
        condition: (user) => user.hasAction("reset_password")
    },
]
