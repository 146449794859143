import { Box, Typography } from "@mui/material"
import { useTranslation } from "react-i18next"

const SuggestionContent = ({ entity }) => {

    const { t } = useTranslation("vbms");

    if (!entity) return null

    return (
        <Box>
            <Typography sx={{ pb: 1 }} variant="h6" gutterBottom>
                {t("suggestions.fields.content")}:
            </Typography>
            <div
                dangerouslySetInnerHTML={{ __html: entity.content }}
            />
        </Box>

    )
}

export default SuggestionContent