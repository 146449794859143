import { downloadFile, openInNewTab } from "../../../lib/utils"
import _isEmpty from "lodash/isEmpty"

export const getShareActions = (user, apiClient, entityId, t) => [
    {
        id: "link",
        title: t("common.actions.share.options.link"),
        onExecute: (booking, progressHandler, onSuccess, onError) => {
            navigator.clipboard.writeText(window.location.href)
            onSuccess(t("common.actions.share.confirm"))
        },
    },
    {
        id: "link_pdf",
        title: t("common.actions.share.options.link_pdf"),
        onExecute: (booking, progressHandler, onSuccess, onError) => {
            const data = {
                resource: "bookings",
                operation: "booking_pdf",
                id: booking.id,
            }
            return apiClient.download.createLink(data)
                .then((link) => {
                    navigator.clipboard.writeText(link.url);
                    onSuccess(t("common.actions.share.confirm"))
                })
                .catch((error) => {
                    onError(t("common.actions.share.error"))
                })
        },
        condition: (entity) => entity.state === 'valid' || entity.state === 'refund_requested'
    },
    {
        id: "download_pdf",
        title: t("bookings.actions.download_voucher"),
        onExecute: (booking, progressHandler, onSuccess, onError) => {
            return apiClient.booking
                .getBookingPdf(booking.id, booking.order.customer.sid)
                .then((pdf) =>
                    downloadFile(pdf, "booking_" + booking.locator + ".pdf")
                )
        },
        condition: (entity) => entity.state === 'valid' || entity.state === 'refund_requested'
    },
    {
        id: "link_gift_pdf",
        title: t("common.actions.share.options.open_gift_pdf"),
        onExecute: (booking, progressHandler, onSuccess, onError) => {
            const data = {
                resource: "bookings",
                operation: "booking_gift_pdf",
                id: booking.id,
            }
            return apiClient.download.createLink(data)
                .then((link) => {
                    openInNewTab(link.url);
                })
        },
        condition: (entity) => (entity.state === 'valid' || entity.state === 'refund_requested') && !_isEmpty(entity.order.gift)
    }
]