import { useCallback } from "react"
import { useVolcanoAuth } from "../../../context/VolcanoAuthContext"
import { useVolcanoApiClient } from "../../../context/VolcanoApiClientProvider"
import { useTranslation } from "react-i18next"
import { createCallbackField, createField } from '../../../components/helpers/fieldHelper'
import { getHeaderContent } from "./EnterprisePage.header"
import { getTabNavigationItems } from "./EnterprisePage.tabs"
import { getActions } from "./EnterprisePage.actions"
import AsyncEntityPage from "../../../layout/templates/AsyncContentPage/AsyncEntityPage"
import constants from '../../../components/entity/constants'
import { useNavigate } from "react-router-dom"


const EnterprisePage = () => {
    const volcanoAuth = useVolcanoAuth()
    const navigate = useNavigate()
    const { apiClient } = useVolcanoApiClient()
    const { t } = useTranslation("vbms")
    const entityId = useCallback(() => volcanoAuth.user.corporate_account.enterprise.id)

    const fetcher = () => apiClient.enterprise.getEnterprise(entityId)

    return (
        <AsyncEntityPage
            fetcher={fetcher}
            title={createField(
                "name",
                t("enterprise.fields.name"),
                constants.STRING_TYPE,
            )}
            subtitle={createCallbackField(
                "",
                "",
                constants.STRING_TYPE,
                () => t("enterprise.subtitle")
            )}
            headerContent={getHeaderContent(t, volcanoAuth.user)}
            actions={getActions(apiClient, t, volcanoAuth.user, {}, navigate, entityId)}
            tabNavigationItems={getTabNavigationItems(t)}
        />
    );
}

export default EnterprisePage;