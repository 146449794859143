import { useTranslation } from "react-i18next"
import { useNavigate, useSearchParams } from "react-router-dom"
import { useVolcanoApiClient } from "../../../context/VolcanoApiClientProvider"
import availableRoutes from "../../../routes/availableRoutes"
import useSnackBars from "../../../components/Snackbar/snack-bar.context"
import PaymentTransactionResultBasePage from "./PaymentTransactionBaseResultPage"

const PaymentTransactionResultPage = () => {
    const { t } = useTranslation("vbms")
    const [searchParams, setSearchParams] = useSearchParams()
    const { apiClient } = useVolcanoApiClient()
    const { addAlert } = useSnackBars()
    const navigate = useNavigate()

    const transactionResultFetcher = () => {
        return apiClient.paymentTransaction.processTransactionResult(Object.fromEntries(searchParams))
            .then(bookings => {
                addAlert(t("payment_transactions.result.success.message"), "success")
                navigate(
                    availableRoutes.bookings_view.path.replace(":entityId", bookings[0].id),
                    { replace: true }
                )
            })
    }

    return (
        <PaymentTransactionResultBasePage
            transationType="booking"
            transactionResultFetcher={transactionResultFetcher}            
        />
    )
}

export default PaymentTransactionResultPage