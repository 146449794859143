import { forwardRef } from "react";
import ReCAPTCHA from "react-google-recaptcha";

const GoogleRecaptcha = forwardRef((props, ref) => {

    const GOOGLE_RECAPTCHA_API_KEY = process.env.REACT_APP_GOOGLE_RECAPTCHA_API_KEY

    return (
        <ReCAPTCHA
            ref={ref}
            sitekey={GOOGLE_RECAPTCHA_API_KEY}
            size="invisible"
        />
    )
})

export default GoogleRecaptcha