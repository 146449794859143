import { useTranslation } from "react-i18next"
import constants from "../../../components/entity/constants"
import { useVolcanoApiClient } from "../../../context/VolcanoApiClientProvider"
import { useNavigate, useParams } from "react-router-dom"
import { createCallbackField } from "../../../components/helpers/fieldHelper"
import AsyncEntityPage from "../../../layout/templates/AsyncContentPage/AsyncEntityPage"
import { getActions } from './RolePage.actions';
import { getHeaderContent } from './RolePage.header';
import { getTabNavigationItems } from "./RolePage.tabs"
import { useVolcanoAuth } from "../../../context/VolcanoAuthContext"

const RolePage = (props) => {
    const { t } = useTranslation();
    const { entityId } = useParams()
    const { apiClient } = useVolcanoApiClient()
    const volcanoAuth = useVolcanoAuth()
    const navigate = useNavigate()

    const fetcher = () => apiClient.role.getRole(entityId)

    return (
        <AsyncEntityPage
            fetcher={fetcher}
            title={createCallbackField(
                "name",
                t("settings_roles.fields.name"),
                constants.STRING_TYPE,
                (value) => {
                    return t("settings_roles.view.title", { name: value });
                }
            )}
            headerContent={getHeaderContent(t, volcanoAuth.user)}
            actions={getActions(apiClient, entityId, t, navigate)}
            tabNavigationItems={getTabNavigationItems(t)}
        />
    );
};

export default RolePage;