import { Box } from "@mui/material"
import { Field } from "formik"
import { CheckboxWithLabel } from "formik-mui"
import { useTranslation } from "react-i18next"
import { validate } from "./utils"

const CheckBoxFormField = ({ field, value }) => {
    const { t } = useTranslation("vbms")

    return (
        <Box>
            <Field
                component={CheckboxWithLabel}
                name={field.id}
                label={field.label}
                required={field.required}
                validate={(value) => validate(field, t, value)}
                InputProps={{ size: "small" }}
                InputLabelProps={{ shrink: true }}
            />
        </Box>
    )
}

export default CheckBoxFormField
