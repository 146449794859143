import { Box, Stack } from "@mui/material"
import constants from "../../../components/entity/constants"
import { createCallbackField, createField, createIconField, createLinkField, createCurrencyField } from '../../../components/helpers/fieldHelper';
import DateTimeFieldValue from "../../../components/entity/DateTimeFieldValue"
import StringFieldValue from "../../../components/entity/StringFieldValue"
import VisibilityIcon from "@mui/icons-material/Visibility"

export const getTableColumns = (t, user) => ([
    createIconField(
        "id",
        VisibilityIcon,
        t("common.actions.view.button"),
        {
            route: "refunds",
            field: "id",
        },
        true
    ),
    createCallbackField(
        "booking.order",
        t("refunds.fields.transaction_id"),
        constants.STRING_TYPE,
        (value) => <Box>{value.tpvid === null ? (value.pp_transaction_id === null ? "Error" : value.pp_transaction_id) : value.tpvid}</Box>
    ),
    createField(
        "type.name",
        t("refunds.fields.type_name"),
        constants.STRING_TYPE
    ),
    createField(
        "state.name",
        t("refunds.fields.state_name"),
        constants.STRING_TYPE
    ),
    createLinkField(
        createField(
            "booking.locator",
            t("refunds.fields.booking_locator"),
            constants.TEXT_TYPE
        ),
        {
            route: "bookings",
            field: "booking.id",
        }
    ),
    createField(
        "booking.booking_date",
        t("bookings.fields.booking_date"),
        constants.DATETIME_TYPE
    ),
    createCallbackField(
        "created",
        t("refunds.fields.created"),
        constants.STRING_TYPE,
        (value, entity) =>
            <Stack>
                <Box><DateTimeFieldValue value={value} /></Box>
                <Box><StringFieldValue value={entity.created_user.name.trim()} /></Box>
            </Stack>,
        null,
        null,
        "created"
    ),
    createCallbackField(
        "last_action",
        t("refunds.fields.modified"),
        constants.STRING_TYPE,
        (value) =>
            <Stack>
                <Box><DateTimeFieldValue value={value.modified} /></Box>
                {value.modified_user && <Box><StringFieldValue value={value.modified_user.name.trim()} /></Box >}
            </Stack>,
        null,
        null,
        "modified"
    ),
    createCallbackField(
        "booking.product",
        t("bookings.fields.product"),
        constants.STRING_TYPE,
        (value) => <Box>{value.experience.name}<br />{value.name}</Box>
    ),
    createCallbackField(
        "booking.order.collaborator",
        t("bookings.fields.order.collaborator.title"),
        constants.BOOLEAN_TYPE,
        (value, entity) => {
            return (entity.booking.order?.collaborator && entity.booking.order.billing_type === 1)
        }
    ),
    createField(
        "booking.order.payment_mode.name",
        t("refunds.fields.order_payment_mode"),
        constants.STRING_TYPE
    ),
    createCurrencyField(
        createField(
            "cost",
            t("refunds.fields.cost"),
            constants.CURRENCY_TYPE
        ),
        "currency_code",
        "main_currency_code",
        "booking.order.currency_exchange_rate"
    ),
    createCurrencyField(
        createField(
            "amount",
            t("refunds.fields.amount"),
            constants.CURRENCY_TYPE
        ),
        "currency_code",
        "main_currency_code",
        "exchange_rate"
    ),
])