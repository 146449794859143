import { createTabBarItem } from "../../../../components/helpers/fieldHelper";
import PickupPoint from "./components/tabs/PickupPoint";
import {DEFAULT_LIMIT} from "../../../../lib/form-options-fetchers";


export const getTabNavigationItems = (user, apiClient, t) => [
    createTabBarItem(
        t("pickup_points.view.navbar.lodgings"),
        ({ entity, data }) => (
            <PickupPoint
                data={data.getItems()}
                entity={entity}
            />
        ),
        (pickupPoint) => {
            const params = {
                limit: DEFAULT_LIMIT,
                offset: 0,
            }
            return apiClient.activityManager.pickupPoint.getLodgings(pickupPoint.id, params)
        }
    ),
];
