import AssignmentIndIcon from '@mui/icons-material/AssignmentInd';
import CheckIcon from '@mui/icons-material/Check';
import ClearIcon from '@mui/icons-material/Clear';
import AddCommentIcon from '@mui/icons-material/AddComment';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import CommentForm from '../../../components/Volcano/Comments/CommentForm';
import RefundAccept from './components/actions/RefundAccept';
import RefundReject from './components/actions/RefundReject';

export const getActions = (apiClient, t, user, itemProps = {}) => {
    const { scope, hideIcons } = { ...itemProps }

    return [
        {
            id: "assing",
            scope: scope,
            icon: hideIcons ? null : AssignmentIndIcon,
            title: t("refunds.actions.assign.button"),
            reloadOnSuccess: true,
            showLoading: true,
            onExecute: (refund, progressHandler, onSuccess, onError) => {
                return apiClient.refund.assign(refund.id)
                    .then((result) => {
                        onSuccess(
                            t("refunds.actions.assign.confirm", { locator: refund.booking.locator })
                        )

                        return result
                    })
                    .catch((error) => {
                        onError(
                            t("refunds.actions.assign.error", { locator: refund.booking.locator })
                        )

                        throw error
                    })
            },
            condition: (refund) => refund.hasAction("assign_refund"),
        },
        {
            id: "accept",
            scope: scope,
            icon: hideIcons ? null : CheckIcon,
            title: t("refunds.actions.accept.button"),
            withDialog: {
                title: (refund) => t((refund.state.id === 3) ? "refunds.actions.accept.modal_title_manual" : "refunds.actions.accept.modal_title", {
                    locator: refund.booking.locator
                }),
                content: RefundAccept,
            },
            reloadOnSuccess: true,
            showLoading: true,
            onExecute: (refund, progressHandler, onSuccess, onError) => {
                return apiClient.refund.accept(refund.id, refund.data)
                    .then((result) => {
                        onSuccess(
                            t("refunds.actions.accept.confirm", { locator: refund.booking.locator })
                        )

                        return result
                    })
                    .catch((error) => {
                        onError(
                            t("refunds.actions.accept.error", { locator: refund.booking.locator })
                        )

                        throw error
                    })
            },
            condition: (refund) => refund.hasAction("accept_refund"),
        },
        {
            id: "reject",
            scope: scope,
            icon: hideIcons ? null : ClearIcon,
            title: t("refunds.actions.reject.button"),
            withDialog: {
                title: (refund) => t("refunds.actions.reject.modal_title", { locator: refund.booking.locator }),
                content: RefundReject,
            },
            reloadOnSuccess: true,
            showLoading: true,
            onExecute: (refund, progressHandler, onSuccess, onError) => {
                return apiClient.refund.reject(refund.id, refund.data.notify_client)
                    .then((result) => {
                        onSuccess(
                            t("refunds.actions.reject.confirm", { locator: refund.booking.locator })
                        )

                        return result
                    })
                    .catch((error) => {
                        onError(
                            t("refunds.actions.reject.error", { locator: refund.booking.locator })
                        )

                        throw error
                    })
            },
            condition: (refund) => refund.hasAction("reject_refund"),
        },
        {
            id: "add_comment",
            scope: scope,
            icon: hideIcons ? null : AddCommentIcon,
            title: t("common.actions.add_comment.button"),
            withDialog: {
                title: t("common.actions.add_comment.modal_title"),
                content: CommentForm,
            },
            reloadOnSuccess: true,
            showLoading: true,
            onExecute: (refund, progressHandler, onSuccess, onError) => {
                return apiClient.refund.addComment(refund.id, refund.comment)
                    .then((refund) => {
                        onSuccess(
                            t("common.actions.add_comment.confirm")
                        )

                        return { ...refund, id: refund.id }
                    })
                    .catch((error) => {
                        onError(
                            t("common.actions.add_comment.error")
                        )

                        throw error
                    })
            },
            condition: (refund) => refund.hasAction("add_comment"),
        },
        {
            id: "refundCost",
            scope: scope,
            icon: hideIcons ? null : AddCircleIcon,
            title: t("refunds.actions.refund_cost.button"),
            reloadOnSuccess: true,
            showLoading: true,
            onExecute: (refund, progressHandler, onSuccess, onError) => {
                return apiClient.refund.refundCost(refund.id)
                    .then((refund) => {
                        onSuccess(
                            t("refunds.actions.refund_cost.confirm", { locator: refund.booking.locator })
                        )

                        return refund
                    })
                    .catch((error) => {
                        onError(
                            t("refunds.actions.refund_cost.error", { locator: refund.booking.locator })
                        )

                        throw error
                    })
            },
            condition: (refund) => refund.hasAction("refund_cost"),
        },
    ]
}