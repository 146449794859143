import { useRef, useState } from 'react'
import { useTranslation } from "react-i18next"
import { Box, FormHelperText } from '@mui/material'
import { Field } from 'formik'

import { CKEditor } from '@ckeditor/ckeditor5-react'
import { 
    Autoformat,
    BlockQuote,
    Bold, 
    ClassicEditor, 
    Essentials, 
    Heading,
    Indent,
    Italic, 
    Link,
    List,
    MediaEmbed,
    Paragraph,
    PasteFromOffice,
    SourceEditing,
    Table,
    TableToolbar,
    TextTransformation
} from 'ckeditor5'

import 'ckeditor5/ckeditor5.css'
//import 'ckeditor5-premium-features/ckeditor5-premium-features.css'

const validate = (t, required, value) => {
    if (!required) {
        return null
    }

    return null
}


const HtmlFormField = ({field, value}) => {
    const { t, i18n } = useTranslation("vbms")

    const [editorContent, setEditorContent] = useState(value);

    const editorRef = useRef(null);

    const handleReady = (editor, setError, setTouched) => {
        editorRef.current = editor;
        // Obtén la instancia del plugin SourceEditing
        const sourceEditingPlugin = editor.plugins.get('SourceEditing');

        // Agrega un listener para detectar cuando el modo de edición de código cambia
        sourceEditingPlugin.on('change:isSourceEditingMode', (evt, propertyName, newValue) => {
            // Aquí puedes realizar otras acciones cuando el modo cambia            
            if (newValue) {
                setError(field.id, t('common.form.alert.html_field.source_editing'))
                setTouched(field.id, true, false)
            } else {
                setError(field.id, null)
                setTouched(field.id, true, false)
            }
        });
    };

    return (
        <Field
            name={field.id}
            label={field.label}
            required={field.required}
            validate={(value) => validate(t, value, field.required)}
            >
            {({
                field,
                form: { values, setFieldValue, setFieldTouched, setFieldError },
                meta,
            }) => (
                <>
                {meta.error && meta.touched && <FormHelperText error={true}>{meta.error}</FormHelperText>}
                <Box>
                    <CKEditor
                        id={ field.id }
                        editor={ ClassicEditor }
                        data={ editorContent }
                        config={{
                            plugins: [
                                Autoformat,
                                BlockQuote,
                                Bold, 
                                Essentials, 
                                Heading,
                                Indent,
                                Italic, 
                                Link,
                                List,
                                MediaEmbed,
                                Paragraph,
                                PasteFromOffice,
                                SourceEditing,
                                Table,
                                TableToolbar,
                                TextTransformation                                
                            ],
                            toolbar: {
                                items: [ 'undo', 'redo', '|',  
                                    'sourceEditing', '|', 
                                    'heading', '|', 
                                    'bold', 'italic', '|', 
                                    'link', 'insertTable', 'blockQuote', '|', 
                                    'bulletedList', 'numberedList', 'outdent', 'indent'
                                ],
                            },
                            table: {
                                contentToolbar: [
                                    'tableColumn',
                                    'tableRow',
                                    'mergeTableCells'
                                ]
                            },
                            language: i18n.language,                            
                        }}

                        onChange={ ( event, editor ) => {
                            setFieldValue(field.name, editor.getData(), true)
                            setFieldTouched(field.name, true, false)
                        } }

                        onReady={(editor) => handleReady(editor, setFieldError, setFieldTouched)}
                
                    />
                </Box>
                </>            
            )}
            </Field>        

    )
  
}

export default HtmlFormField
