import { useTranslation } from "react-i18next"
import constants from "../../../../components/entity/constants"
import { useVolcanoApiClient } from "../../../../context/VolcanoApiClientProvider"
import {
    createEditableListFormField,
    createAsyncSelectFormField,
    createCallbackField, createFormFieldset, createFormField, createField,
} from "../../../../components/helpers/fieldHelper"
import AsyncEntityFormPage from "../../../../layout/templates/AsyncContentPage/AsyncEntityFormPage"
import {useNavigate, useParams} from "react-router-dom"
import availableRoutes from "../../../../routes/availableRoutes"
import { DEFAULT_LIMIT } from "../../../../lib/form-options-fetchers"
import {useCache} from "../../../../context/cache.context"
import ValueSelectorDialog from "../../../../components/ValueSelectorDialog/ValueSelectorDialog"
import {getPickupPointsFilter} from "../../libs/collection-filters";


const PickupConfigPickupPointsPage = () => {
    const { apiClient } = useVolcanoApiClient()
    const cache = useCache()
    const { t } = useTranslation("vbms")
    const navigate = useNavigate()
    const { pickupConfigId } = useParams()


    const fetcher = () => {
        return apiClient.activityManager.pickupConfig
            .getPickupConfig(pickupConfigId, [])
            .then((pickupConfig) => {
                return apiClient.activityManager.pickupConfig
                    .getPickupConfigPickupPoints(pickupConfigId, [])
                    .then((pickupPoints) => {
                        const pickupPointsConvert = pickupPoints.getItems().map((item) => {
                            return {
                                id: item.id,
                                name: item.name,
                                relative_minutes: item.pickup_config_info.relative_minutes
                            }
                        })

                       return  {
                            ...pickupConfig,
                            pickup_points: pickupPointsConvert
                        }
                    })
            })
    }

    const handleClose = () => {
        navigate(
            availableRoutes.pickup_configs_view.path.replace(":entityId", pickupConfigId)
        )
    }

    const onConfirm = (values) => {
        const pickupPoints = values.pickup_points.map((item) => {
            return {
                id: item.id,
                relative_minutes: item.relative_minutes
            }
        })

        const pickupConfigUpdate = {
            pickup_points: pickupPoints,
            final_point: {
                id: values.final_point.id
            }
        }

        return apiClient.activityManager.pickupConfig
            .editPickupConfigPickupPoints(pickupConfigId, pickupConfigUpdate)
            .then(() => {
                navigate(
                    availableRoutes.pickup_configs_view.path.replace(":entityId", pickupConfigId)
                )
            })
    }
    const pickupPointsFilterField = getPickupPointsFilter(apiClient, t, true, cache)

    const formContent = [
        createFormFieldset(t("pickup_configs.form.fieldset_pickup_points"), [
            createEditableListFormField(
                "pickup_points",
                "",
                "name",
                t("pickup_configs.actions.set_pickup_points.modal_title"),
                ({ onConfirm, onCancel }) => <ValueSelectorDialog
                    table={pickupPointsFilterField.table}
                    selected={pickupPointsFilterField.selected}
                    valueFormatter={pickupPointsFilterField.valueFormatter}
                    multipleSelection={true}
                    onConfirm={onConfirm}
                    onCancel={onCancel}
                />,
                true,
                true,
                true,
                null,
                [
                  createFormField(
                      createField(
                          "relative_minutes",
                          t("pickup_points.fields.relative_minutes"),
                          constants.STRING_TYPE
                      ),
                      true,
                      ""
                  )
                ]
            ),
        ]),
        createFormFieldset(t("pickup_configs.form.fieldset_final_pickup_point"), [
            createAsyncSelectFormField(
                "final_point.id",
                "",
                () => {
                    const params = {
                        limit: DEFAULT_LIMIT,
                        sort: "name"
                    }

                    return apiClient.activityManager.pickupPoint.getPickupPoints(params)
                        .then((result) => {
                            return [].concat(
                                result.getItems()
                                    .map((item) => ({ value: item.id, label: item.name }))
                                    .sort((a, b) => a.label.localeCompare(b.label))
                            )
                        })
                },
                false
            ),
        ]),
    ];

    return (
        <AsyncEntityFormPage
            fetcher={fetcher}
            title={createCallbackField(
                "name",
                t("pickup_configs.fields.name"),
                constants.STRING_TYPE,
                (value) => {
                    return t("pickup_configs.view.title", { name: value })
                }
            )}
            onSave={onConfirm}
            onClose={handleClose}
            formContent={formContent}
        />
    );
};

export default PickupConfigPickupPointsPage
