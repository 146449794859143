import { useTranslation } from "react-i18next"
import { useVolcanoApiClient } from "../../../context/VolcanoApiClientProvider"
import CollectionPage from "../../../layout/templates/CollectionPage/CollectionPage"
import { useCache } from "../../../context/cache.context"
import { getTableGroupBy } from "./BookingsCollectionGrouped.groupby"
import { getTableColumns } from "./BookingsCollectionGrouped.columns"
import { getTableFilter } from "./BookingsCollectionGrouped.filter"
import _isDate from "lodash/isDate"
import _get from "lodash/get"
import _set from "lodash/set"
import { format as formatDate } from "date-fns"
import { useVolcanoAuth } from "../../../context/VolcanoAuthContext"
import { getTableExport } from "./BookingsCollectionGrouped.export"

const BookingsCollectionGroupedPage = () => {
    const { apiClient } = useVolcanoApiClient()
    const { i18n, t } = useTranslation("vbms")
    const cache = useCache()
    const volcanoAuth = useVolcanoAuth()

    const filterConfig = getTableFilter(apiClient, t, volcanoAuth.user, cache)

    const table = {
        selectionMode: "none",
        groupBy: getTableGroupBy(t, volcanoAuth.user, i18n),
        columns: getTableColumns(t, volcanoAuth.user, i18n),
        fetcher: (params) => {
            // booking date is required
            let date = _get(params, "booking_date", new Date())
            if (date === "") {
                date = new Date()
            }

            if (_isDate(date)) {
                _set(params, "booking_date", formatDate(date, "yyyy-MM-dd"))
            }

            const queryFilter = {
                limit: 500,
                valid: 1,
                cancelled: 0,
                booking_date_from: params.booking_date,
                booking_date_to: params.booking_date,
                experience_name: _get(params, "experience_name", null),
                experience_id: _get(params, "experience_id[0].id", null),
                activity_id: _get(params, "activity_id[0].id", null),
            }

            // do not query API if activity or experience is not selected
            if (!queryFilter.experience_id && !queryFilter.activity_id) {
                queryFilter.experience_id = -1
            }

            return apiClient.booking.getBookings(queryFilter)
        }
    }

    return (
        <CollectionPage
            title={t("bookings.title")}
            subtitle={t("bookings.subtitle")}
            filterConfig={filterConfig}
            table={table}
            onExport={getTableExport(apiClient, t, volcanoAuth.user, filterConfig)}
        />
    )
}

export default BookingsCollectionGroupedPage
