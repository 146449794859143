import { useTranslation } from "react-i18next"
import { useVolcanoApiClient } from "../../../context/VolcanoApiClientProvider"
import CollectionPage from "../../../layout/templates/CollectionPage/CollectionPage"
import { useVolcanoAuth } from '../../../context/VolcanoAuthContext'
import { useNavigate } from 'react-router-dom'
import { getTableFilter } from './SitesCollection.filter'
import { getActions } from "./SitesCollection.actions"
import { getTableColumns } from './SitesCollection.columns'
import { getTableExport } from './SitesCollection.export'
import { useCache } from "../../../context/cache.context"
import { getTableActions } from '../../Sites/SitesCollection/SitesCollection.actions';

const SitesCollectionPage = () => {
    const { apiClient } = useVolcanoApiClient()
    const { t } = useTranslation("vbms")
    const volcanoAuth = useVolcanoAuth()
    const navigate = useNavigate()
    const cache = useCache()

    const table = {
        highlight: (site) => site.is_admin ? null : "background.info.main",
        columns: getTableColumns(t, volcanoAuth.user),
        actions: getTableActions(apiClient, t, volcanoAuth.user),
        fetcher: (params) => {
            const queryFilter = {
                ...params,
                sort: 'name'
            }

            return apiClient.content.site.getSites(queryFilter)
        },
        onExport: getTableExport(apiClient, t)
    };

    return (
        <CollectionPage
            title={t("sites.title")}
            subtitle={t("sites.subtitle")}
            actions={getActions(t, navigate, volcanoAuth.user)}
            filterConfig={getTableFilter(t, apiClient, volcanoAuth.user, cache)}
            table={table}
        />
    )
}

export default SitesCollectionPage;
