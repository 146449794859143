import {
    createAsyncSelectFormField,
    createFormField,
    createRangeDatesField
} from "../../../../components/helpers/fieldHelper";
import {activityConfigsFetcher, activityStatesFetcher} from "../../libs/form-options-fetchers";
import {addWeeks, startOfToday} from "date-fns";

export const getTableFilter = (t, apiClient, user, cache) => ([
    createAsyncSelectFormField(
        "activity_config_id",
        t("activities.fields.activity_config"),
        () => activityConfigsFetcher(cache, apiClient, t, {},true),
        false
    ),
    createFormField(
        createRangeDatesField(
            "date_from",
            t("activities.fields.date_from"),
            "date_to",
            true
        ),
        true,
        startOfToday()
    ),
    createFormField(
        createRangeDatesField(
            "date_to",
            t("activities.fields.date_to"),
            "date_from",
            false
        ),
        true,
        addWeeks(startOfToday(), 1)
    ),
    createAsyncSelectFormField(
        "state",
        t("activities.fields.state"),
        () => activityStatesFetcher(cache, apiClient, t, true),
        false
    ),
])
