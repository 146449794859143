import { Box, Button, Collapse, List, ListItem, ListItemButton, ListItemIcon, ListItemText, styled } from "@mui/material"
import { useTranslation } from "react-i18next"
import { Link, NavLink } from "react-router-dom"
import { useVolcanoAuth } from "../../../context/VolcanoAuthContext"
import availableRoutes from "../../../routes/availableRoutes"
import { useEffect, useState } from "react"
import _get from "lodash/get"
import _isFunction from "lodash/isFunction"
import { useMenu } from "../../../context/menu.context"

const MenuNav = styled(List)({
    '& .MuiListItem-root': {
        paddingLeft: 0,
        paddingRight: 4,
    },
    '& .MuiListItemButton-root': {
        paddingLeft: 0,
        paddingRight: 4,
    },
    '& .MuiListItemIcon-root': {
        minWidth: 0,
        marginRight: 4,
    },
    '& .MuiSvgIcon-root': {
        fontSize: 20,
    },
    '& .MuiCollapse-root': {
        paddingLeft: 24,
    }
})

const processMenuEntry = (t, user, checkRoute, entry, disfrutareAccess) => {
    // check the user has access to this menu entry
    if (!entry.items) {
        if (checkRoute(entry.key)) {
            entry.label = t("menu." + entry.text)
            if (_get(entry, "type") === "disfrutare" && disfrutareAccess) {
                entry.link = disfrutareAccess.replace('[URL]', availableRoutes[entry.key].path)
                entry.target = "_blank"
            } else {
                entry.link = availableRoutes[entry.key].path
                if (user && _isFunction(entry.path)) {
                    const path = entry.path(user)
                    Object.entries(path).forEach((param) => {
                        entry.link = entry.link.replace(':' + param[0], param[1])
                    })
                }
                entry.target = "_self"
            }

            return entry
        }
    } else {
        entry.label = t("menu." + entry.text)
        return entry
    }

    return false;
}

const reduceMenuEntries = (t, user, checkRoute, entries, disfrutareAccess) => {
    return entries.reduce((acc, entry) => {
        const menuEntry = processMenuEntry(t, user, checkRoute, entry, disfrutareAccess)
        if (menuEntry) {
            if (menuEntry.items) {
                // process submenu
                // only show this item if at least one submenu entry is available
                menuEntry.items = reduceMenuEntries(t, user, checkRoute, entry.items, disfrutareAccess)
                if (menuEntry.items.length > 0) {
                    acc.push(menuEntry)
                }
            } else {
                acc.push(menuEntry)
            }
        }

        return acc
    }, [])
}

const MenuItem = ({ item, isMobile, onItemSelected, onGroupClick }) => {
    if (item.items && item.items.length > 0) {
        return (
            <ListItem>
                {item.icon && (
                    <ListItemIcon
                        onClick={onGroupClick}
                        sx={{ cursor: "pointer" }}
                    >
                        {item.icon}
                    </ListItemIcon>
                )}
                <ListItemText
                    onClick={onGroupClick}
                    primary={item.label}
                    primaryTypographyProps={{
                        sx: {
                            cursor: "pointer",
                            fontWeight: "bold",
                            textTransform: "uppercase",
                            fontSize: "0.7rem",
                        },
                    }}
                />
            </ListItem>
        )
    }

    return (
        <ListItemButton
            key={item.key}
            component={item.link && NavLink}
            to={item.link}
            target={item.target}
            onClick={() => item.link && isMobile && onItemSelected()}
        >
            {item.icon && <ListItemIcon>{item.icon}</ListItemIcon>}
            <ListItemText primary={item.label} />
        </ListItemButton>
    )
}

const MenuItems = ({ items, isMobile, onItemSelected, open, setOpen }) => {
    return (
        <>
            {items.map((item) =>
                <Box
                    key={item.key}
                >
                    {item.items && item.items.length > 0 && (
                        <>
                            <MenuItem
                                item={item}
                                isMobile={isMobile}
                                onItemSelected={onItemSelected}
                                onGroupClick={() => item.items && setOpen((prevState) => ({ ...prevState, [item.key]: !prevState[item.key] }))}
                            />
                            {item.items && item.items.length > 0 && (
                                <Collapse in={open[item.key]} timeout="auto" unmountOnExit>
                                    <List
                                        component="div"
                                        disablePadding
                                    >
                                        <MenuItems
                                            items={item.items}
                                            isMobile={isMobile}
                                            onItemSelected={onItemSelected}
                                            open={open}
                                            setOpen={setOpen}
                                        />
                                    </List>
                                </Collapse>
                            )}
                        </>
                    )}
                    {!item.items && (
                        <MenuItem
                            item={item}
                            isMobile={isMobile}
                            onItemSelected={onItemSelected}
                        />
                    )}
                </Box>
            )}
        </>
    )
}

const initialState = (items) => items.reduce((acc, item) => {
    if (item.items && item.items.length > 0) {
        acc[item.key] = _get(item, "open", true)
        acc = { ...acc, ...initialState(item.items) }
    }

    return acc
}, {})

const MenuItemsWrapper = ({ items, isMobile, onItemSelected }) => {
    const [open, setOpen] = useState({})

    useEffect(() => {
        setOpen(initialState(items))
    }, [items])

    return (
        <MenuItems
            items={items}
            isMobile={isMobile}
            onItemSelected={onItemSelected}
            open={open}
            setOpen={setOpen}
        />
    )
}

const SidebarMenu = ({ isMobile, onItemSelected }) => {
    const { t } = useTranslation("vbms")
    const volcanoAuth = useVolcanoAuth()
    const { mainMenu } = useMenu()
    const [items, setItems] = useState([])

    useEffect(() => {
        const checkRoute = (route) => {
            return volcanoAuth.user.allowedRoutes.includes(route)
        }

        const disfrutareAccess = volcanoAuth.user.hasPermission("disfrutare-access") ? volcanoAuth.user.settings.disfrutare.url : false

        const items = mainMenu.reduce((acc, entry) => {
            const menuEntry = processMenuEntry(t, volcanoAuth.user, checkRoute, entry, disfrutareAccess)
            if (menuEntry) {
                if (menuEntry.items) {
                    // process submenu
                    // only show this item if at least one submenu entry is available
                    menuEntry.items = reduceMenuEntries(t, volcanoAuth.user, checkRoute, entry.items, disfrutareAccess)
                    if (menuEntry.items.length > 0) {
                        acc.push(menuEntry)
                    }
                } else {
                    acc.push(menuEntry)
                }
            }

            return acc;
        }, [])

        setItems(items)
    }, [volcanoAuth.user, mainMenu])

    return (
        <>
            {volcanoAuth.user.allowedRoutes.includes("bookings_add") && (
                <Box mt={2}>
                    <Button
                        variant="contained"
                        color="primary"
                        component={Link} to={availableRoutes.bookings_add.path}
                    >
                        {t("menu.bookings_add")}
                    </Button>
                </Box>
            )}
            {false && volcanoAuth.user.allowedRoutes.includes("bookings_validate") && (
                <Box mt={2}>
                    <Button
                        variant="contained"
                        color="primary"
                        component={Link} to={availableRoutes.bookings_validate.path}
                    >
                        {t("menu.bookings_validate")}
                    </Button>
                </Box>
            )}
            {items.length > 0 && (
                <MenuNav>
                    <MenuItemsWrapper items={items} isMobile={isMobile} onItemSelected={onItemSelected} />
                </MenuNav>
            )}
        </>

    )

}

export default SidebarMenu