import { useTranslation } from "react-i18next"
import constants from "../../../components/entity/constants"
import { useVolcanoApiClient } from "../../../context/VolcanoApiClientProvider"
import { useParams, useNavigate } from 'react-router-dom'
import AsyncEntityPage from "../../../layout/templates/AsyncContentPage/AsyncEntityPage"
import { createCallbackField } from "../../../components/helpers/fieldHelper"
import { useVolcanoAuth } from '../../../context/VolcanoAuthContext'
import { getActions } from "./BookingPage.actions"
import { getHeaderContent } from "./BookingPage.header"
import { getTabNavigationItems } from './BookingPage.tabs'
import _has from "lodash/has"
import DisfrutareLink from "../../../components/Volcano/Disfrutare/DisfrutareLink"
import { getShareActions } from "./BookingPage.shareActions"

const BookingPage = (props) => {
    const { t } = useTranslation()
    const { entityId } = useParams()
    const { apiClient } = useVolcanoApiClient()
    const volcanoAuth = useVolcanoAuth()
    const navigate = useNavigate()

    const fetcher = () => {
        return apiClient.booking.getBooking(entityId, { view_mode: 'full', with_activity: 1 }).then((booking) => {
            if (false && _has(booking, "order.collaborator")) {
                return apiClient.booking.getRelatedBookings(entityId, { view_mode: 'full' }).then((result) => {
                    booking.related_bookings = result.getItems()
                    return booking
                })
            } else {
                return booking
            }
        })
    }

    return (
        <AsyncEntityPage
            fetcher={fetcher}
            title={createCallbackField(
                "locator",
                t("bookings.fields.locator"),
                constants.STRING_TYPE,
                (value, entity) => t("bookings.view.title", { locator: value }) + (entity.order.voucher_id !== null ? ` - (${entity.order.voucher_id})` : "")
            )}
            subtitle={createCallbackField(
                "product",
                t("bookings.fields.product"),
                constants.STRING_TYPE,
                (value, entity) => <><DisfrutareLink href={"/admin/experiences/view/" + entity.experience.id}>{entity.experience.name}</DisfrutareLink> / <DisfrutareLink href={"/admin/products/view/" + value.id}>{value.name}</DisfrutareLink></>
            )}
            headerContent={getHeaderContent(t, volcanoAuth.user)}
            actions={getActions(volcanoAuth.user, apiClient, entityId, t, navigate)}
            shareActions={getShareActions(volcanoAuth.user, apiClient, entityId, t)}
            tabNavigationItems={getTabNavigationItems(volcanoAuth.user, apiClient, t)}
        />
    )
}

export default BookingPage
