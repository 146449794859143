import { useTranslation } from "react-i18next"
import { useVolcanoApiClient } from "../../context/VolcanoApiClientProvider"
import { useNavigate, useParams } from "react-router-dom"
import { billingClientFetcher } from "./BillingClients.functions"
import BasePage from "../../layout/templates/BasePage"
import { useState, useEffect, useCallback } from "react"
import { filterAvailableActions } from "../../components/action/utils"
import Loading from "../../components/Display/Loading"
import BillingClientCreateInvoiceWidget from "./components/BillingClientCreateInvoiceWidget/BillingClientCreateInvoiceWidget"
import availableRoutes from '../../routes/availableRoutes';

const BillingClientCreateInvoiceFormPage = (props) => {
    const { t } = useTranslation()
    const { entityId } = useParams()
    const { apiClient } = useVolcanoApiClient()
    const navigate = useNavigate()

    const [isReady, setIsReady] = useState(false)
    const [entity, setEntity] = useState()

    const actions = [
        {
            id: "cancel",
            title: t("common.cancel"),
            onExecute: () => {
                navigate(-1)
            },
        },
    ]

    const handleOnInvoiceGenerated = useCallback((invoice) => {
        navigate(availableRoutes.billing_invoices_view.path.replace(":entityId", invoice.id))
    }, [navigate])

    useEffect(() => {
        if (!entityId) {
            setIsReady(true)
            return
        }

        billingClientFetcher(apiClient, entityId).then((billingClient) => {
            setEntity(billingClient)
            setIsReady(true)
        })
    }, [apiClient, entityId])

    return (
        <>
            <Loading open={!isReady} />
            {isReady && <BasePage
                title={t("billing_clients.actions.create_invoice.form_title")}
                subtitle={entity && `${entity.name} (${entity.vat_number})`}
                actions={filterAvailableActions(actions, entity)}
            >
                <BillingClientCreateInvoiceWidget
                    defaultSelection={{ billingClient: entity }}
                    onInvoiceGenerated={handleOnInvoiceGenerated}
                />
            </BasePage>}
        </>
    )
}

export default BillingClientCreateInvoiceFormPage