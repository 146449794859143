import constants from "../../../components/entity/constants"
import { createField, createLinkField, createCallbackField, discountCodesTypeMapping } from '../../../components/helpers/fieldHelper'

export const getTableColumns = (t) => ([
    createLinkField(
        createField(
            "name",
            t("discount_codes.fields.name"),
            constants.STRING_TYPE
        ),
        {
            route: "discount_codes",
            field: "id",
        }
    ),
    createField(
        "site",
        t("discount_codes.fields.site"),
        constants.STRING_TYPE,
    ),
    createField(
        "type",
        t("discount_codes.fields.type.name"),
        constants.STRING_TYPE,
        discountCodesTypeMapping(t)
    ),
    createField(
        "value",
        t("discount_codes.fields.value"),
        constants.NUMERIC_TYPE
    ),
    createField(
        "usages",
        t("discount_codes.fields.usages"),
        constants.NUMERIC_TYPE
    ),
    createCallbackField(
        "use_limit",
        t("discount_codes.fields.use_limit"),
        constants.STRING_TYPE,
        (value) => value || t("discount_codes.constants.unlimited")
    ),
    createField(
        "valid_from",
        t("discount_codes.fields.valid_from"),
        constants.DATE_TYPE
    ),
    createField(
        "valid_to",
        t("discount_codes.fields.valid_to"),
        constants.DATE_TYPE
    ),
    createField(
        "active",
        t("common.states.active"),
        constants.BOOLEAN_TYPE
    )
])