import { useTranslation } from "react-i18next"
import { Link } from "@mui/material"
import DataSet from '../Display/DataSet';
import StringFieldValue from "./StringFieldValue"

/**
 * Display pickup info (venue and time).
 * @param {Object} pickupPoint
 */
const PickupFieldValue = ({ value }) => {
    const { t } = useTranslation("vbms")

    if (!value) {
        return null
    }

    const data = [
        {
            key: "name",
            name: t("bookings.fields.pickup.name"),
            value: <StringFieldValue value={value.name} />,
        },
        {
            key: "pickup_time",
            name: t("bookings.fields.pickup.time"),
            value: <StringFieldValue value={value.pickup_time} />,
        },
        {
            key: "",
            name: "",
            value: <Link Link
                target="_blank"
                href={`https://www.google.es/maps?z=10&q=loc:${value.coordinates.lat},${value.coordinates.lng}`}
            >
                {t("bookings.fields.pickup.map_link")}
            </Link>
        }
    ]

    return (
        <DataSet variant="inline" titleVariant="label" data={data} />
    )

}

export default PickupFieldValue