import constants from '../../../components/entity/constants'
import { createDataSetFacet, createFacetField, createFacetsContainer, createField, createLiquidationStateMapping, createObjectStatusField } from '../../../components/helpers/fieldHelper'

export const getHeaderContent = (t) => [
    createDataSetFacet(t("liquidations.view.facet_information"), [
        createField("id", "Id", constants.STRING_TYPE),
        createField(
            "created",
            t("liquidations.fields.created"),
            constants.DATETIME_TYPE
        ),
        createField(
            "collaborator.enterprise.name",
            t("common.enterprise"),
            constants.STRING_TYPE
        ),
    ]),
    createDataSetFacet(
        t("liquidations.view.facet_intermediary"),
        [
            createField("collaborator.id", "Id", constants.STRING_TYPE),
            createField(
                "collaborator.nombre",
                t("liquidations.fields.intermediary"),
                constants.STRING_TYPE
            ),
        ],
    ),
    createFacetsContainer([
        createFacetField("object_status", {
            size: "large",
            field: createObjectStatusField(
                createField(
                    "status",
                    t("liquidations.fields.status"),
                    constants.STRING_TYPE,
                    createLiquidationStateMapping(t)
                ),
                null,
                (value) => {
                    switch (value) {
                        case "created":
                            return "info"
                        case "invoice_pending":
                            return "negative"
                        case "invoice_paid":
                            return "positive"
                        default:
                            return "neutral"
                    }
                }
            ),
        }),
    ]),
    createFacetsContainer([
        createFacetField("object_status", {
            size: "large",
            field: createObjectStatusField(
                createField(
                    "total_price",
                    t("liquidations.fields.total_amount"),
                    constants.CURRENCY_TYPE
                ),
                null,
                (value) => "info"
            ),
        }),
        createFacetField("object_status", {
            size: "large",
            field: createObjectStatusField(
                createField(
                    "total_tax",
                    t("invoices.fields.total_tax"),
                    constants.CURRENCY_TYPE
                ),
                null,
                (value) => "info"
            ),
        }),
    ]),
]