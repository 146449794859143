import { Field } from "formik";
import { ToggleButton, ToggleButtonGroup, Box } from "@mui/material";
import { useTranslation } from "react-i18next";

const WeekdaysFormField = ({ field, form }) => {
    const { t } = useTranslation("vbms");

    const days = ['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday'];

    const handleDaysChange = (event, newDays) => {
        const selectedDays = days.map((day) => (newDays.includes(day) ? '1' : '0'));
        form.setFieldValue(field.name, selectedDays.join(""));
    };

    const formatDays = (value) => value ? value.split('').map(Number) : [];

    const selectedValues = formatDays(field.value);

    return (
        <Box>
            <ToggleButtonGroup
                value={days.filter((_, index) => selectedValues[index] === 1)}
                onChange={handleDaysChange}
                size="small"
                exclusive={false}
            >
                {days.map((day, index) => (
                    <ToggleButton
                        key={`${day}-${index}`}
                        value={day}
                        selected={selectedValues[index] === 1}
                        color={selectedValues[index] === 1 ? "primary" : "standard"}
                    >
                        {t(`common.fields.week_days_short.${day}`)}
                    </ToggleButton>
                ))}
            </ToggleButtonGroup>
        </Box>
    );
};

const FormikWeekdaysFormField = (props) => (
    <Field name={props.field.name}>
        {({ field, form }) => <WeekdaysFormField field={field} form={form} />}
    </Field>
);

export default FormikWeekdaysFormField;
