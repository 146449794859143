import constants from "../../../components/entity/constants";
import { createAsyncSelectFormField, createField, notificationStatesMapping, notificationTemplateTypesMapping } from '../../../components/helpers/fieldHelper';
import { notificationSchemaKeyItemsFetcher } from "../../../lib/form-options-fetchers";


export const getTableFilter = (apiClient, siteConfig, t, user, cache) => ([
    createField(
        "template_name",
        t("notifications.fields.name"),
        constants.TEXT_TYPE
    ),
    createAsyncSelectFormField(
        "type",
        t("notifications.fields.type"),
        () => notificationSchemaKeyItemsFetcher("notification_template_type", cache, apiClient, t, notificationTemplateTypesMapping, true),
        false
    ),
    createAsyncSelectFormField(
        "state",
        t("notifications.fields.state"),
        () => notificationSchemaKeyItemsFetcher("notification_states", cache, apiClient, t, notificationStatesMapping, true),
        false
    ),
    createField(
        "date_from",
        t("notifications.filter.date_from"),
        constants.DATE_TYPE
    ),
    createField(
        "date_to",
        t("notifications.filter.date_to"),
        constants.DATE_TYPE
    )
])