import { Box, Stack } from '@mui/material';
import DateTimeFieldValue from '../../../../../components/entity/DateTimeFieldValue';
import KeyValue from '../../../../../components/Display/KeyValue';
import Text from '../../../../../components/Display/Text';
import CurrencyFieldValue from '../../../../../components/entity/CurrencyFieldValue';
import { useTranslation } from 'react-i18next';
import _isEmpty from "lodash/isEmpty"
import DataContainer from '../../../../../components/Display/DataContainer';

const ProductChangeDetail = ({ old_product, old_booking_date, new_product, new_booking_date, t }) => {
    return (
        <Stack>
            <KeyValue label={t("refunds.fields.data.old_product")} variant="inline">
                <Text>{old_product.experience_name} / {old_product.name}</Text>
            </KeyValue>
            {!_isEmpty("old_booking_date") && (old_booking_date !== new_booking_date) && <KeyValue
                label={t("refunds.fields.data.old_booking_date")}
                variant="inline">
                <DateTimeFieldValue value={old_booking_date} format="datetime" />
            </KeyValue>}
            <KeyValue label={t("refunds.fields.data.new_product")} variant="inline">
                <Text>{new_product.experience_name} / {new_product.name}</Text>
            </KeyValue>
            {!_isEmpty("new_booking_date") && (old_booking_date !== new_booking_date) && <KeyValue
                label={t("refunds.fields.data.new_booking_date")}
                variant="inline">
                <DateTimeFieldValue value={new_booking_date} format="datetime" />
            </KeyValue>}
        </Stack>
    )
}

const RatesChangeDetail = ({ old_rates, new_rates, currency, main_currency, exchange_rate, t }) => {
    return (
        <Stack>
            <KeyValue label={t("refunds.fields.data.old_rates")}>
                <Stack>{
                    old_rates.map((rate) => <Text>
                        {rate.name.replace(",", ", ")} x {rate.qty}: {<CurrencyFieldValue value={{ value: rate.pvp, currency: currency, main_currency: main_currency, exchange_rate: exchange_rate }} />} ({rate.tax_percentage}% {t("common.fields.tax_type")})
                    </Text>)
                }</Stack>
            </KeyValue>
            <Stack sx={{ marginTop: 2 }}>
                <KeyValue label={t("refunds.fields.data.new_rates")}>
                    <Stack>{
                        new_rates.map((rate) => <Text>
                            {rate.name.replace(",", ", ")} x {rate.qty}: {<CurrencyFieldValue value={{ value: rate.pvp, currency: currency, main_currency: main_currency, exchange_rate: exchange_rate }} />} ({rate.tax_percentage}% {t("common.fields.tax_type")})
                        </Text>)
                    }</Stack>
                </KeyValue>
            </Stack>
        </Stack>
    )
}

const RefundDetail = ({ refundData, currency, mainCurrency, exchangeRate }) => {
    const { t } = useTranslation("vbms")

    return (
        <Box sx={{ marginY: 2 }}>
            <DataContainer title={t("refunds.view.facet_change_detail")}>
                {refundData.hasOwnProperty("old_product") && <ProductChangeDetail
                    old_product={refundData.old_product}
                    old_booking_date={refundData.old_booking_date}
                    new_product={refundData.new_product}
                    new_booking_date={refundData.new_booking_date}
                    t={t}
                />}
                {refundData.hasOwnProperty("old_rates") && <RatesChangeDetail
                    old_rates={refundData.old_rates}
                    new_rates={refundData.new_rates}
                    currency={currency}
                    main_currency={mainCurrency}
                    exchange_rate={exchangeRate}
                    t={t}
                />}
            </DataContainer>
        </Box>
    )
}

export default RefundDetail