import { useCallback, useState } from "react"
import { useTranslation } from "react-i18next"
import { Alert, Autocomplete, Grid, TextField } from "@mui/material"
import _isArray from "lodash/isArray"
import _isEmpty from "lodash/isEmpty"
import _get from "lodash/get"
import PickupFieldValue from "../../entity/PickupFieldValue"

const UNKNOWN_OPTION = "UNK"

const BASE_PICKUP_SELECTION = {
    lodgin_id: null,
    pickup_point_id: null,
    observations: "",
}

const getSelectedElement = (t, collection, id) => {
    if (!_isArray(collection)) {
        return null
    }

    if (id === UNKNOWN_OPTION) {
        return {
            id: UNKNOWN_OPTION,
            name: t("booking_widget.steps.client.pickup.option_unknown")
        }

    }

    return collection.find((element) => element.id === id) ?? null
}

/**
 * Autocomplete field for pickup selection steps.
 * 
 * @param {string} id field id
 * @param {string} label field label
 * @param {Array} options field options
 * @param {function} onChange field onChange
 */
const SelectField = ({ id, label, options, onChange, defaultValue, helperText, error }) => {
    const { t } = useTranslation("vbms")

    if (!_isArray(options) || _isEmpty(options)) {
        return null
    }

    // prepend unknown option to options
    var availableOptions = options.slice(0)
    availableOptions.unshift({
        id: UNKNOWN_OPTION,
        name: t("booking_widget.steps.client.pickup.option_unknown")
    })

    return (
        <>
            <Autocomplete
                id={id}
                size="small"
                options={availableOptions}
                getOptionLabel={(option) => _get(option, "name", "")}
                isOptionEqualToValue={(option, value) => option.id === _get(value, "id", "")}
                value={defaultValue}
                onChange={(event, value) => onChange(value)}
                renderInput={(params) => <TextField {...params} label={label} InputLabelProps={{ shrink: true }} helperText={helperText} error={error} />}
            />
        </>
    )
}

const ActivityPickupSelector = ({ pickupData, error, onChange, defaultValue }) => {
    const { t } = useTranslation("vbms")
    const [selection, setSelection] = useState(defaultValue || BASE_PICKUP_SELECTION)

    const onChangeHandler = useCallback((selection) => {
        setSelection(selection)

        onChange({
            lodgin_id: selection.lodgin_id === UNKNOWN_OPTION ? null : selection.lodgin_id,
            pickup_point_id: selection.pickup_point_id === UNKNOWN_OPTION ? null : selection.pickup_point_id,
            observations: selection.observations ? selection.observations.trim() : null,
        })
    }, [setSelection, onChange])

    const setLodgin = useCallback((selected) => {
        onChangeHandler({
            ...BASE_PICKUP_SELECTION,
            lodgin_id: _get(selected, "id", null),
            pickup_point_id: _get(selected, "pickup_point_id", null),
        })
    }, [onChangeHandler])

    const setPickupPoint = useCallback((selected) => {
        onChangeHandler({
            ...BASE_PICKUP_SELECTION,
            lodgin_id: UNKNOWN_OPTION,
            pickup_point_id: _get(selected, "id", null),
        })
    }, [onChangeHandler])

    const setObservations = useCallback((selection, observations) => {
        onChangeHandler({
            ...selection,
            observations: observations,
        })
    }, [onChangeHandler])

    if (!pickupData) {
        return null
    }

    return (
        <Grid container spacing={2}>
            <Grid item xs={12} lg={6}>
                <SelectField
                    id="pickup.lodgin_id"
                    label={t("booking_widget.steps.client.pickup.lodgin")}
                    helperText={t("booking_widget.steps.client.pickup.lodgin_helper")}
                    options={pickupData.lodgins}
                    defaultValue={getSelectedElement(t, pickupData.lodgins, _get(selection, "lodgin_id"))}
                    onChange={setLodgin}
                />
            </Grid>
            {_get(selection, "lodgin_id") === UNKNOWN_OPTION && (
                <Grid item xs={12} lg={6}>
                    <SelectField
                        id="pickup.pickup_point_id"
                        label={t("booking_widget.steps.client.pickup.pickup_point")}
                        helperText={t("booking_widget.steps.client.pickup.pickup_point_helper")}
                        options={pickupData.pickup_points}
                        defaultValue={getSelectedElement(t, pickupData.pickup_points, _get(selection, "pickup_point_id"))}
                        onChange={setPickupPoint}
                    />
                </Grid>
            )}
            {_get(selection, "lodgin_id") === UNKNOWN_OPTION && (
                <Grid item xs={12}>
                    <TextField
                        id="pickup.observations"
                        label={t("booking_widget.steps.client.pickup.observations")}
                        helperText={t("booking_widget.steps.client.pickup.observations_helper")}
                        value={selection.observations}
                        onChange={(event) => setObservations(selection, event.target.value)}
                        variant="outlined"
                        size="small"
                        fullWidth
                        multiline
                        rows={4}
                        InputLabelProps={{
                            shrink: true
                        }}
                    />
                </Grid>
            )}
            {error && (
                <Grid item xs={12}>
                    <Alert severity="error">{_get(pickupData, "contact_info")}</Alert>
                </Grid>
            )}
            {selection.pickup_point_id && selection.pickup_point_id !== UNKNOWN_OPTION && (
                <Grid item xs={12}>
                    <PickupFieldValue
                        value={pickupData.pickup_points.find((pickupPoint) =>
                            pickupPoint.id === _get(selection, "pickup_point_id")
                        )}
                    />
                </Grid>
            )}
        </Grid>
    )
}

export default ActivityPickupSelector