import availableRoutes from '../../../routes/availableRoutes';
import { getActions as getRowActions } from "../InvoicePage/InvoicePage.actions"

export const getActions = (t, navigate, user) => [
    {
        title: t("billing_clients.actions.create_invoice.button"),
        onExecute: (user, progressHandler, onSuccess, onError) => {
            navigate(availableRoutes.billing_clients_create_invoice.path)
        },
        condition: () => user.hasPermission("create-invoice")
    },
]

export const getTableActions = (apiClient, t, user) => getRowActions(apiClient, t, "row", user.crm_intermediary)