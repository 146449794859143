import _has from 'lodash/has'

export const getActions = (apiClient, entityId, t, navigate) => [
    {
        id: "change_state_enable",
        title: t("intermediaries.offices.actions.change_state_active.button"),
        confirmDialog: {
            title: t("intermediaries.offices.actions.change_state_active.modal_title"),
            content: (selection) =>
                t("intermediaries.offices.actions.change_state_active.modal_content", {
                    name: selection.name,
                }),
        },
        reloadOnSuccess: true,
        showLoading: true,
        onExecute: (office, progressHandler, onSuccess, onError) => {
            return apiClient.intermediary.setIntermediaryOfficeState(office.id, true)
                .then((office) => {
                    onSuccess(
                        t("intermediaries.offices.actions.change_state_active.confirm", {
                            name: office.name,
                        })
                    )

                    return office
                })
                .catch((error) => {
                    onError(
                        t("intermediaries.offices.actions.change_state_active.error", {
                            name: office.name,
                        })
                    )

                    throw error
                })
        },
        condition: (office) => _has(office.getActions(), "set_active") && office.state !== "active"
    },
    {
        id: "change_state_disable",
        title: t("intermediaries.offices.actions.change_state_inactive.button"),
        confirmDialog: {
            title: t("intermediaries.offices.actions.change_state_inactive.modal_title"),
            content: (selection) =>
                t("intermediaries.offices.actions.change_state_inactive.modal_content", {
                    name: selection.name,
                }),
        },
        reloadOnSuccess: true,
        showLoading: true,
        onExecute: (office, progressHandler, onSuccess, onError) => {
            return apiClient.intermediary.setIntermediaryOfficeState(office.id, false)
                .then((office) => {
                    onSuccess(
                        t("intermediaries.offices.actions.change_state_inactive.confirm", {
                            name: office.name,
                        })
                    )

                    return office
                })
                .catch((error) => {
                    onError(
                        t("intermediaries.offices.actions.change_state_inactive.error", {
                            name: office.name,
                        })
                    )
                    throw error
                })
        },
        condition: (office) => _has(office.getActions(), "set_active") && office.state === "active"
    },
    {
        id: "edit",
        title: t("common.edit"),
        onExecute: (office, progressHandler, onSuccess, onError) => {
            navigate('/settings/offices/' + office.id + '/edit')
        },
        condition: (office) => _has(office.getActions(), "edit")
    }
]