import { useTranslation } from "react-i18next"
import constants from "../../../components/entity/constants"
import { useVolcanoApiClient } from "../../../context/VolcanoApiClientProvider"
import { useParams, useNavigate } from 'react-router-dom'
import AsyncEntityPage from "../../../layout/templates/AsyncContentPage/AsyncEntityPage"
import { createCallbackField } from "../../../components/helpers/fieldHelper"
import { useVolcanoAuth } from '../../../context/VolcanoAuthContext'
import { getActions } from "./DiscountCodesPage.actions"
import { getHeaderContent } from "./DiscountCodesPage.header"
import { getTabNavigationItems } from "./DiscountCodePage.tabs"

const DiscountCodesPage = () => {
    const { t } = useTranslation()
    const { entityId } = useParams()
    const { apiClient } = useVolcanoApiClient()
    const volcanoAuth = useVolcanoAuth()
    const navigate = useNavigate()

    const fetcher = () => {
        return apiClient.catalog.discountCode.getDiscountCode(entityId)
    }

    return (
        <AsyncEntityPage
            fetcher={fetcher}
            title={createCallbackField(
                "name",
                t("discount_codes.fields.name"),
                constants.STRING_TYPE,
                (value) => t("discount_codes.view.title", { name: value })
            )}
            headerContent={getHeaderContent(t, volcanoAuth.user)}
            actions={getActions(apiClient, t, volcanoAuth.user, {}, navigate, entityId)}
            tabNavigationItems={getTabNavigationItems(t)}
        />
    )
}

export default DiscountCodesPage