import { isFunction } from "lodash"
import { createContext, useContext, useEffect, useMemo, useState } from "react"
import useAsync from "../../hooks/useAsync"

const stub = () => {
    throw new Error('You forgot to wrap your component in <AsyncTabNavigationProvider></AsyncTabNavigationProvider>.')
}

const defaultContext = {
    activeTab: null,
    setActiveTab: stub,
    tab: null,
    tabData: [],
    tabAsyncError: null,
    tabAsyncStatus: null,
}

const Context = createContext(defaultContext)

const AsyncTabNavigationProvider = ({ tabs, data, children }) => {
    const [activeTab, setActiveTab] = useState(tabs ? 0 : null)
    const [tabData, setTabData] = useState([])
    const { refresh, status, value, error } = useAsync(tabs ? (tabs[0].fetcher || null) : null, true)

    useEffect(() => {
        if (tabs && isFunction(tabs[activeTab].fetcher) && !tabData[activeTab]) {
            refresh(tabs[activeTab].fetcher, data)
        }
    }, [activeTab])

    useEffect(() => {
        setTabData((prevData) => {
            const newTabData = [...prevData]
            newTabData[activeTab] = value
            return newTabData
        })
    }, [value])

    const contextValue = useMemo(() => {
        return {
            activeTab: activeTab,
            setActiveTab: setActiveTab,
            tab: activeTab !== null ? tabs[activeTab] : null,
            tabData: tabData,
            tabAsyncError: error,
            tabAsyncStatus: status
        }
    }, [
        activeTab,
        tabData,
        error,
        status
    ])

    return (
        <Context.Provider value={contextValue}>
            {children}
        </Context.Provider>
    )

}

const useAsyncTabNavigation = () => useContext(Context)

export {
    useAsyncTabNavigation,
    AsyncTabNavigationProvider
}