import { useTranslation } from "react-i18next"
import { useVolcanoApiClient } from "../../../../context/VolcanoApiClientProvider"
import { useVolcanoAuth } from "../../../../context/VolcanoAuthContext"
import { useCache } from "../../../../context/cache.context"
import CollectionPage from "../../../../layout/templates/CollectionPage/CollectionPage"
import { getTableColumns } from "./FacilityBookLinesCollection.columns"
import { getTableFilter } from "./FacilityBookLinesCollection.filter"
import constants from "../../../../components/entity/constants"
import _isDate from "lodash/isDate"
import _get from "lodash/get"
import _set from "lodash/set"
import { format as formatDate } from "date-fns"

const FacilityBookLinesCollectionPage = () => {
    const { t } = useTranslation("vbms")
    const cache = useCache()
    const volcanoAuth = useVolcanoAuth()
    const { apiClient } = useVolcanoApiClient()

    const filterConfig = getTableFilter(apiClient, t, volcanoAuth.user, cache)

    const table = {
        columns: getTableColumns(t),

        fetcher: (params) => {
            filterConfig
                .filter(field => field.type === constants.DATE_TYPE)
                .forEach(field => {
                    const date = _get(params, field.id)
                    if (_isDate(date)) {
                        _set(params, field.id, formatDate(date, "yyyy-MM-dd"))
                    }
                })

            return apiClient.venue.facilityBookLine.getFacilityBookLines(null, params)
        },
    }
    return (
        <CollectionPage
            title={t("facility_book_lines.title")}
            subtitle={t("facility_book_lines.subtitle")}
            filterConfig={filterConfig}
            table={table}
        />
    )
}

export default FacilityBookLinesCollectionPage