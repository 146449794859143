import { createExportField } from "../../../components/helpers/fieldHelper"

export const getTableExport = (apiClient, t) => {
    return (filter, progressHandler) => {
        apiClient.refund.exportRefunds(
            [
                createExportField("id", "Id"),
                createExportField(
                    "booking.order",
                    t("refunds.fields.transaction_id"),
                    null,
                    (order) => order.tpvid === null ? (order.pp_transaction_id === null ? "Error" : order.pp_transaction_id) : order.tpvid
                ),
                createExportField("type.name", t("refunds.fields.type_name")),
                createExportField("booking.locator", t("refunds.fields.booking_locator")),
                createExportField("booking.booking_date", t("bookings.fields.booking_date")),
                createExportField("created", t("refunds.fields.created"),),
                createExportField("last_action.modified", t("refunds.fields.modified"),),
                createExportField(
                    "booking.product.experience.name",
                    t("bookings.fields.experience")
                ),
                createExportField("booking.product.name", t("bookings.fields.product")),
                createExportField("booking.order.payment_mode.name", t("refunds.fields.order_payment_mode")),
                createExportField("main_currency.cost.value", t("refunds.fields.cost")),
                createExportField("main_currency.amount.value", t("refunds.fields.amount")),
            ],
            filter,
            progressHandler
        )
    }
} 