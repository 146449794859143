import { useTranslation } from "react-i18next";
import { useState } from "react";
import { Box, Button, Card, CardContent } from "@mui/material";
import DataContainer from "../../Display/DataContainer";
import Title from "../../Display/Title";
import { useDialog } from "../../Dialog/dialog.context";
import StringFieldValue from "../../entity/StringFieldValue";
import _isArray from "lodash/isArray"


const HistoricalElement = ({ selection }) => {

    const { t } = useTranslation("vbms");
    const { handleClose } = useDialog()

    const [element, setElement] = useState(selection.selection)

    return (
        <>{element && element.data ? (
            <Box>{element.data.map((field) => {
                const fieldName = field.field
                const previousValue = field.old ?
                    (field.old.name ? field.old.name : _isArray(field.old) ? field.old.map((field) => field.name).join(', ') : field.old)
                    : "-"
                const newValue = field.new ?
                    (field.new.name ? field.new.name : _isArray(field.new) ? field.new.map((field) => field.name).join(', ') : field.new)
                    : "-"

                return (
                    <DataContainer
                        title={t("intermediaries.fields."+fieldName)}
                        titleVariant={''}
                        titleLevel={'h5'}
                        >
                        <Box key={'box_'+fieldName} mt={1}>
                            <Card
                                key={'card_'+fieldName}
                                variant="outlined"
                                sx={{
                                    marginBottom: 2,
                                }}
                            >
                                <CardContent>
                                    <Title level='h6'>{t("intermediaries.view.previous_value")}</Title>
                                    <StringFieldValue value={previousValue}/>
                                    <Title level='h6'>{t("intermediaries.view.new_value")}</Title>
                                    <StringFieldValue value={newValue}/>
                                </CardContent>
                            </Card>                                    
                        </Box>
                    </DataContainer>
                )
            })}
                <Button
                    color="primary"
                    variant="contained"
                    type="submit"
                    onClick={() => handleClose()}>
                    {t("common.close")}
                </Button>
            </Box>
        ) : (
            <></>
        )}
        </>
    )
}

export default HistoricalElement;