import { createContext, useCallback, useContext, useEffect, useMemo, useState } from "react";
import { Alert, Snackbar } from '@mui/material';

const Context = createContext();

const AUTO_DISMISS = 4000;

/*const useStyles = makeStyles((theme) => ({
  anchorOriginTopCenter: {
    top: 54
  }
}));
*/

export function SnackBarProvider({ children }) {
  const [alert, setAlert] = useState();
  const [open, setOpen] = useState(false);

  useEffect(() => {
    if (alert) {
      setOpen(true)
    } else {
      setOpen(false)
    }
  }, [alert])

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return
    }

    setAlert(null)
  }

  const addAlert = useCallback(
    (content, severity) =>
      setAlert({ message: content, severity: severity || "info" }),
    []
  )

  const value = useMemo(() => ({ addAlert }), [addAlert])

  return (
    <Context.Provider value={value}>
      {children}
      <Snackbar
        open={open}
        onClose={handleClose}
        autoHideDuration={AUTO_DISMISS}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        {alert && <Alert onClose={handleClose} severity={alert.severity}>{alert.message}</Alert>}
      </Snackbar>
    </Context.Provider>
  )
}

const useSnackBars = () => useContext(Context)

export default useSnackBars
