import { Typography, Stack, useMediaQuery } from "@mui/material";
import { styled, useTheme } from "@mui/material/styles";
import { useTranslation } from "react-i18next";

const Container = styled(Stack, {
    shouldForwardProp: (prop) => prop !== "desktop" && prop !== "open",
})(({ desktop, open, theme }) => ({
    display: open ? "none" : "inherit",
    margin: desktop ? "1rem" : "2rem",
    backgroundColor: theme.palette.common.white,
    marginTop: "1rem",
    borderRadius: "0.5rem",
    padding: "1rem",
}));

export const RulesSelectorContainer = (props) => {
    const theme = useTheme()
    const matchUpMd = useMediaQuery(theme.breakpoints.down('md'))
    const {t} = useTranslation("vbms")

    return (
        <Container container {...props} desktop={matchUpMd}>
            <Typography variant="h3" marginBottom={3}>{props.config.title || t("components.rules_selector.default_title")}</Typography>
            {props.children}
        </Container>
    )
}

export const RulesDivider = () => {
    return (
        <Stack justifyContent={'center'} alignItems={'center'}>
            <Typography variant="h5">-</Typography>
        </Stack>
    )
}