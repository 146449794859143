import { useTranslation } from "react-i18next"
import AsyncEntityForm from "../../../../../components/form/AsyncEntityForm"
import constants from "../../../../../components/entity/constants"
import { createFormFieldset, createField, createTextAreaFormField, createLanguagesMapping, createSelectFormField, languageList } from '../../../../../components/helpers/fieldHelper'

const BookingClientEdit = ({ selection, onAction, onCancel }) => {

    const { t } = useTranslation("vbms")

    const fetcher = () => Promise.resolve(selection)

    const formContent = [
        createFormFieldset(null, [
            createField(
                "order.customer.first_name",
                t("bookings.fields.order.customer.first_name"),
                constants.STRING_TYPE
            ),
            createField(
                "order.customer.last_name",
                t("bookings.fields.order.customer.last_name"),
                constants.STRING_TYPE
            ),
            createField(
                "order.customer.email",
                t("bookings.fields.order.customer.email"),
                constants.STRING_TYPE
            ),
            createField(
                "order.customer.phone",
                t("bookings.fields.order.customer.phone"),
                constants.PHONE_TYPE
            ), createTextAreaFormField(
                "order.customer.comments",
                t("bookings.fields.order.customer.observations"),
                4
            ), createSelectFormField(
                "order.language",
                t("bookings.fields.order.language"),
                Object.entries(createLanguagesMapping(t))
                    .map(([key, value]) => (
                        {
                            value: key,
                            label: value
                        }
                    ))
            ),
        ])
    ]

    const onConfirm = (result) => {

        onAction({
            locator: selection.locator,
            order_id: selection.order.id,
            customer_id: selection.order.customer.id,
            customer: {
                name: result.order.customer.first_name?.trim(),
                surename: result.order.customer.last_name?.trim(),
                email: result.order.customer.email,
                language_code3: result.order.language,
                comments: result.order.customer.comments?.trim(),
                phone: result.order.customer.phone
            }
        })
    }

    return (
        <AsyncEntityForm
            fetcher={fetcher}
            onSave={onConfirm}
            formContent={formContent}
        />
    )
}

export default BookingClientEdit