import { createTabBarItem } from "../../../../components/helpers/fieldHelper";
import ActivityConfigConfiguration from "./components/tabs/ActivityConfigConfiguration";
import ActivityConfigProducts from "./components/tabs/ActivityConfigProducts";


export const getTabNavigationItems = (user, apiClient, t) => [
    createTabBarItem(
        t("routes.view.navbar.configuration"),
        ({ entity }) => (
            <ActivityConfigConfiguration
                data={entity.availability_rules}
                entity={entity}
            />
        ),
        null,
        (entity) => entity.availability_rules
    ),
    createTabBarItem(
        t("routes.view.navbar.products"),
        ({ entity }) => (
            <ActivityConfigProducts
                data={entity.products}
                entity={entity}
            />
        ),
        null,
        (entity) => entity.products
    )
];
