import constants from "../../../components/entity/constants";
import { createCallbackField, createField, createInvoiceStateMapping, createLinkField } from "../../../components/helpers/fieldHelper";
import _has from 'lodash/has';
import { Link as RouterLink } from "react-router-dom"
import { Link } from "@mui/material"

export const getTableColumns = (t, volcanoAuth) => ([
    createLinkField(
        createField(
            "title",
            t("invoices.fields.title"),
            constants.STRING_TYPE
        ),
        {
            route: "billing_invoices",
            field: "id",
        }
    ),
    createCallbackField(
        "",
        t("invoices.fields.recipient.entity"),
        constants.STRING_TYPE,
        (_, invoice) => {
            if (_has(invoice, "collaborator")) {
                return <Link component={RouterLink} to={"/settings/intermediaries/" + invoice.collaborator.crm_intermediary_id}>{invoice.collaborator.fact_razon_social || invoice.collaborator.name}</Link>
            } else if (_has(invoice, "billing_client")) {
                return invoice.billing_client.name
            }
            return ""
        },
            (invoice) => !_has(volcanoAuth.user, "crm_intermediary")
        ),
    createField(
        "amount.total_amount",
        t("invoices.fields.total_amount"),
        constants.CURRENCY_TYPE
    ),
    createField(
        "state",
        t("invoices.fields.state"),
        constants.STRING_TYPE,
        createInvoiceStateMapping(t, _has(volcanoAuth.user, "crm_intermediary") ? "recipient" : "issuer")
    ),
    createField(
        "created",
        t("invoices.fields.created"),
        constants.DATETIME_TYPE
    ),
])