import { useTranslation } from "react-i18next"
import { useVolcanoApiClient } from "../../../../context/VolcanoApiClientProvider"
import CollectionPage from "../../../../layout/templates/CollectionPage/CollectionPage"
import { useVolcanoAuth } from "../../../../context/VolcanoAuthContext"
import { useNavigate } from "react-router-dom"
import { getActions, getTableActions } from "./InboxBookingsCollection.actions"
import { getTableColumns } from "./InboxBookingsCollection.columns"
import CallToActionIcon from "@mui/icons-material/CallToAction"
import ContactEmergencyIcon from "@mui/icons-material/ContactEmergency"
import BookmarkAddedIcon from "@mui/icons-material/BookmarkAdded"
import { useDialog } from "../../../../components/Dialog/dialog.context"
import {useState} from "react";

const InboxBookingsCollection = () => {
    const { apiClient } = useVolcanoApiClient()
    const { t } = useTranslation("vbms")
    const volcanoAuth = useVolcanoAuth()
    const navigate = useNavigate()
    const { showDialog, handleClose } = useDialog();

    const refreshNumber = 100
    const [refreshTrigger, setRefreshTrigger] = useState(0)

    const conditionMarkInUse = (entity) => entity.locked_by === false
    const conditionMarkNotInUse = (entity) => entity.locked_by !== false

    const doChangeStateManaged = (value) => {
        const body = {
            id: value,
            managed: true
        };

        return apiClient.booking.changeManagedState(value, body)
    }

    const doRefreshTrigger = () => {
        refreshTrigger === 0 ? setRefreshTrigger(1) : setRefreshTrigger(0)
    }

    const confirmDialog = (title, message, onConfirm) => {
        const actions = [
            {
                id: "confirm",
                title: t("common.accept"),
                onExecute: () => {
                    handleClose()
                    onConfirm().finally(() => {
                        doRefreshTrigger()
                    })
                }
            },
            {
                id: "cancel",
                title: t("common.cancel"),
                onExecute: () => handleClose()
            }
        ]

        showDialog(title, message, actions)
    }

    const changeStateManaged = (value, entity) => {
        confirmDialog(
            t("inbox.actions.change_state_managed.title"),
            t("inbox.actions.change_state_managed.message", {
                name: entity.locator,
            }),
            () => doChangeStateManaged(value)
        )
    }

    const markInUse = (value, entity) => {
        confirmDialog(
            t("inbox.actions.mark_in_use.title"),
            t("inbox.actions.mark_in_use.message", {
                name: entity.locator,
            }),
            () => apiClient.booking.lockBooking(value)
        )
    }

    const markNotInUse = (value, entity) => {
        confirmDialog(
            t("inbox.actions.mark_not_in_use.title"),
            t("inbox.actions.mark_not_in_use.message", {
                name: entity.locator,
            }),
            () => apiClient.booking.unlockBooking(value)
        )
    }

    const actions = [
        {
            id: "mark_in_use",
            title: t("inbox.actions.mark_in_use.title"),
            scope: "block_action",
            onExecute: markInUse,
            condition: conditionMarkInUse,
            icon: <CallToActionIcon />
        },
        {
            id: "mark_not_in_use",
            title: t("inbox.actions.mark_not_in_use.title"),
            scope: "block_action",
            onExecute: markNotInUse,
            condition: conditionMarkNotInUse,
            icon: <ContactEmergencyIcon />
        },
        {
            id: "chance_state_managed",
            title: t("inbox.actions.chance_state_managed.title"),
            scope: "managed_action",
            onExecute: changeStateManaged,
            condition: () => true,
            icon: <BookmarkAddedIcon />
        }
    ]

    const table = {
        columns: getTableColumns(t, volcanoAuth.user, actions),
        actions: getTableActions(apiClient, t, volcanoAuth.user),
        fetcher: (params) => {
            const queryFilter = {
                ...params,
                state: "valid",
                with_activity: 1,
                activity_managed: 0,
                sort: "booking_date",
                sort_direction: "asc",
                in_inbox: 1
            }
            return apiClient.booking.getBookings(queryFilter)
        },
        refresh: refreshNumber,
        refreshTrigger: refreshTrigger
    }

    return (
        <CollectionPage
            title={t("inbox.title")}
            subtitle={t("inbox.subtitle")}
            actions={getActions(apiClient, t, navigate, volcanoAuth.user, confirmDialog)}
            table={table}
        />
    )
}

export default InboxBookingsCollection
