import { useTranslation } from "react-i18next";
import {getTableGroupBy} from "../../../../../Bookings/BookingsCollectionGrouped/BookingsCollectionGrouped.groupby";
import {getTableColumns} from "../../../../../Bookings/BookingsCollection/BookingsCollection.columns";
import {useVolcanoApiClient} from "../../../../../../context/VolcanoApiClientProvider";
import {useVolcanoAuth} from "../../../../../../context/VolcanoAuthContext";
import { format as formatDate } from "date-fns"
import CollectionDataTable from "../../../../../../components/Datatable/CollectionDataTable";

const ActivityBookings = ({ activity }) => {
    const { apiClient } = useVolcanoApiClient()
    const {i18n, t } = useTranslation("vbms")
    const volcanoAuth = useVolcanoAuth()

    const activityConfigId = activity.id
    let date = new Date(activity.date)
    date = formatDate(date, "yyyy-MM-dd")
    const columns = getTableColumns(t, volcanoAuth.user, i18n)
    const groupBy = getTableGroupBy(t, volcanoAuth.user, i18n)

    return (
        <CollectionDataTable
            fetcher={() => {
                const queryFilter = {
                    limit: 500,
                    valid: 1,
                    cancelled: 0,
                    booking_date_from: date,
                    booking_date_to: date,
                    activity_id: activityConfigId,
                }

                return apiClient.booking.getBookings(queryFilter)
            }}
            columns={columns}
            groupBy={groupBy}
        />
    )

}

export default ActivityBookings;
