import _get from "lodash/get"
import _has from "lodash/has"
import _isFunction from "lodash/isFunction"
import _isObject from "lodash/isObject"
import _isArray from "lodash/isArray"
import { Link as RouterLink } from "react-router-dom"
import { useVolcanoAuth } from "../../context/VolcanoAuthContext"
import { useFilterContext } from "./context/filter/filter.context"
import { useDataTableContext } from "./context/collection/collection.context"
import availableRoutes from "../../routes/availableRoutes"
import constants from "./constants"
import { Link } from "@mui/material"
import DisfrutareLink from "../Volcano/Disfrutare/DisfrutareLink"
import { getRouteParams } from "../../lib/utils"

export const getFieldValue = (field, entity) => {
    let result = null

    if (_has(field, "facet")) {
        return entity
    }

    const fieldId = _get(field, "id")
    if (fieldId) {
        result = _get(entity, fieldId)
    }

    if (_isFunction(_get(field, "callback"))) {
        result = field.callback(result, entity)
    }

    if (field.type === 'select' && field.multiple && !result) {
        result = []
    }

    return result
}

const getFieldComponent = (field, entity) => {
    const {
        type,
        displayField,
        mapping,
        callback,
        formatCallback
    } = field

    let value = getFieldValue(field, entity)
    if (_isObject(mapping)) {
        if (type === 'array' && value && _isArray(value)) {
            let result = [];
            value.forEach(element => {
                result.push(_get(field.mapping, element))
            });
            value = result;
        } else {
            value = _get(field.mapping, value)
        }
//        value = _get(field.mapping, value)
    }

    const FieldValueComponent = _get(constants.FIELD_TYPE_COMPONENT_MAPPING, type)

    if (FieldValueComponent) {
        return (
            <FieldValueComponent
                field={field}
                value={value}
                displayField={displayField}
                mapping={mapping}
                callback={callback}
                formatCallback={formatCallback}
                format={type}
            />
        )
    } else {
        return <>{value}</>
    }
}

const LinkEntityField = ({field, entity}) => {
    const authContext = useVolcanoAuth()
    const filterContext = useFilterContext()
    const dataTableContext = useDataTableContext()

    if (authContext.user.allowedRoutes.includes(field.link.route) || _get(field, "link.isDisfrutare", false) && authContext.user.hasPermission("disfrutare-access")) {
        if (_get(field, "link.isDisfrutare", false)) {
            return <DisfrutareLink to={field.link.href} />
        }

        let url = availableRoutes[field.link.route].path

        if (field.link.field) {
            url += "/" + _get(entity, field.link.field)

            if (filterContext) {
                const [filterConfig, filter] = filterContext
                const [table] = dataTableContext

                const params = {
                    page: table.page,
                    rowsPerPage: table.rowsPerPage,
                    filter: structuredClone(filter)
                }

                url += `?q=${getRouteParams(params, filterConfig, table.orderBy, table.order)}`
            }
        }

        if (field.type === "download") {
            url += "/download"
        }

        return <Link component={RouterLink} to={url}>{getFieldValue(field, entity)}</Link>
    }
}

/**
 * Entity field value transformator the component take an object like
 *
 *  {
 *    id:     name from JSON response structure where the data is
 *    label:  string as lable which user will see.
 *    type:   field type, it can be one of following constants defined at assets/constants.js
 *      TEXT_TYPE,
 *      STRING_TYPE,
 *      BOOLEAN_TYPE
 *      CURRENCY_TYPE
 *      CURRENCY_SYMBOL
 *      ARRAY_TYPE
 *      CALLBACK_TYPE
 *      DOWNLOAD_TYPE
 *    align:    constant with the alignment. One of the following:
 *      CENTER_ALIGN,
 *      RIGHT_ALIGN,
 *      LEFT_ALIGN,
 *    link: {
 *      route:  route name for the link.
 *      field:  name of variable which should be replaced in URL
 *    }
 *  },
 *
 *
 * @param {object} field
 * @param {object} entity
 */
const EntityField = ({ field, entity }) => {

    if (field.link) {
        return <LinkEntityField field={field} entity={entity}/>
    }

    return getFieldComponent(field, entity)
}

export default EntityField
