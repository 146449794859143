import { Box, Grid, IconButton, useMediaQuery, useTheme } from "@mui/material"
import EntityActionsGroup from "../../components/action/EntityActionsGroup"
import CollapseContainer from "../../components/CollapseContent/CollapseContainer"
import Title from "../../components/Display/Title"
import useScrollPosition from "../../components/hooks/useScrollPosition"
import { useAsyncTabNavigation } from "../../components/TabNavigation/context/async-tabnavigation.context"
import AsyncTabContent from "../../components/TabNavigation/AsyncTabContent"
import AsyncTabNavigation from "../../components/TabNavigation/AsyncTabNavigation"
import { useLocation } from "react-router-dom"
import { useEffect, useState } from "react"
import ShareActionsGroup from "../../components/action/ShareActionsGroup"
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import _isFunction from "lodash/isFunction"

const PageHeader = ({ title, subtitle, actions, shareActions, noMemoized, goBackHandler, children }) => {
    const { isInitialPosition } = useScrollPosition()
    const theme = useTheme()
    const matchDownMd = useMediaQuery(theme.breakpoints.down('md'))
    const [hasScrolled, setHasScrolled] = useState(false)
    const [isCollapsed, setIsCollapsed] = useState(matchDownMd)
    const location = useLocation()
    const params = new URLSearchParams(location.search);

    useEffect(() => {
        if (!isInitialPosition && !hasScrolled) {
            setHasScrolled(true)
        }
    }, [isInitialPosition, hasScrolled, setHasScrolled])

    return (
        <>
            <Box
                display="flex"
                pb={1}
            >
                {_isFunction(goBackHandler) && params.has("q") && (
                    <IconButton onClick={goBackHandler}>
                        <KeyboardBackspaceIcon />
                    </IconButton>
                )}
                <Box
                    display="flex"
                    alignItems="flex-start"
                    justifyContent="center"
                    flexDirection="column"
                    flexGrow={1}
                >
                    <Title level={!isCollapsed ? "h2" : "h4"}>{title}</Title>
                    <Title level={!isCollapsed ? "subtitle" : "body1"}>{subtitle}</Title>
                </Box>
                <Box className="base-actions-group" display="flex" alignItems="center">
                    <ShareActionsGroup actions={shareActions} />
                </Box>
                <EntityActionsGroup actions={actions} />
            </Box>
            {children && (
                <CollapseContainer
                    collapsed={hasScrolled || matchDownMd}
                    onChange={(open) => setIsCollapsed(!open)}
                    noMemoized={noMemoized}
                >
                    {children}
                </CollapseContainer>
            )}
        </>
    )
}

const BasePage = (props) => {
    const theme = useTheme()
    const matchUpMd = useMediaQuery(theme.breakpoints.up('md'))
    const { activeTab, setActiveTab } = useAsyncTabNavigation()
    const actions = props.actions || []
    const shareActions = props.shareActions || []

    const stickyHeaderConfig = matchUpMd ? {
        position: "sticky",
        top: "65px",
        zIndex: 2
    } : {}

    console.log("BASE PAGE")

    return (
        <Grid container>
            <Grid item xs={12} sx={{
                bgcolor: "common.white",
                padding: 2,
                paddingLeft: 4,
                paddingBlockEnd: props.tabNavigationItems ? 0 : 2,
                boxShadow: `inset 0 -0.0625rem ${theme.palette.common.container.border}, 0 0.125rem 0.25rem 0 rgb(85 107 30 / 8%)`,
                ...stickyHeaderConfig
            }}> 
                <PageHeader
                    title={props.title}
                    subtitle={props.subtitle}
                    actions={actions}
                    shareActions={shareActions}
                    goBackHandler={props.goBackHandler}
                    noMemoized={props.noHeaderMemoized || false}
                >
                    {props.headerContent}
                </PageHeader>
                {activeTab !== null && (
                    <Grid item xs={12}>
                        <AsyncTabNavigation
                            onTabSelected={setActiveTab}
                            tabs={props.tabNavigationItems}
                        />
                    </Grid>
                )}
            </Grid>
            <Grid item xs={12} sx={{ padding: { xs: 2, md: 4 }, paddingTop: 2 }}>
                <Box sx={{ bgcolor: "common.white", padding: 2, borderRadius: 2 }}>
                    {activeTab !== null ? <AsyncTabContent /> : props.children}
                </Box>
            </Grid>
        </Grid >
    );
};

export default BasePage;