import DateSelector from "../../../../../components/Volcano/DateSelector/DateSelector";
import { useCallback, useState } from 'react';
import { useVolcanoApiClient } from '../../../../../context/VolcanoApiClientProvider';
import { format as formatDate } from "date-fns"
import { Box, Button, Stack } from "@mui/material"
import { useTranslation } from 'react-i18next';
import { useDialog } from "../../../../../components/Dialog/dialog.context";
import _isEmpty from "lodash/isEmpty"

const BookingDateChange = ({ selection, onAction, onCancel }) => {

    const { t } = useTranslation("vbms")
    const { apiClient } = useVolcanoApiClient()

    const { handleClose } = useDialog()
    const [newDate, setNewDate] = useState({})

    const getRatesQty = () => selection.product_rates.reduce((acc, rate) => acc + rate.qty, 0)

    const availabilityFetcher = useCallback((productId, date) => {
        setNewDate({})
        const params = (selection.order.collaborator == null) ? {} : { collaborator_id: selection.order.collaborator.id }
        return apiClient.catalog.product
            .getProductAvailability(productId, date, true, params)
            .then((result) => {
                return result;
            });
    }, [apiClient.catalog.product, setNewDate]);

    const onSelection = useCallback((selection) => {

        const date = formatDate(selection.date, 'yyyy-MM-dd')
        const session = selection.session.session === 'day_wide' ? '00:00:00' : selection.session.session

        setNewDate({
            date: date,
            session: session
        })
    }, [setNewDate])


    const onConfirm = () => {
        onAction({
            ...newDate,
            locator: selection.locator
        }).finally(() => {
            onClose()
        })
    }

    const onClose = () => {
        handleClose()
    }

    return (
        <>
            <DateSelector
                product={selection.product.id}
                selected={selection.date}
                minQuantity={getRatesQty()}
                availabilityFetcher={availabilityFetcher}
                onSelection={onSelection}
            />
            <Box
                display="flex"
                justifyContent="flex-end"
                alignItems="center" mt={2}
            >
                <Stack direction="row" spacing={1}>
                    {!_isEmpty(newDate) &&
                        <Button
                            color="primary"
                            variant="contained"
                            type="submit"
                            onClick={() => onConfirm()}>
                            {t("common.accept")}
                        </Button>}
                    <Button
                        color="primary"
                        variant="contained"
                        type="submit"
                        onClick={() => onClose()}>
                        {t("common.cancel")}
                    </Button >
                </Stack>
            </Box>
        </>
    )
}

export default BookingDateChange