import constants from "../../../../components/entity/constants"
import { createField, createLinkField, createCallbackField } from '../../../../components/helpers/fieldHelper'

export const getTableColumns = (t, user) => ([

    createLinkField(
        createField(
            "name",
            t("venues.fields.name"),
            constants.TEXT_TYPE,
        ),
        {
            route: "venues",
            field: "id",
        }
    ),
    
    createField(
        "key",
        t("venues.fields.key"),
        constants.STRING_TYPE
    ),
    createCallbackField(
        "sites",
        t("venues.fields.sites"),
        constants.STRING_TYPE,
        (values) => values.map(v => v.name).join(',')
    ),
    createField(
        "created",
        t("common.fields.created"),
        constants.DATETIME_TYPE
    )
])