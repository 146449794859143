import { useEffect, useState, useMemo, Fragment, useCallback } from "react"
import { Box, TableCell, TableRow, Stack, Grid, IconButton, Collapse, TextField } from "@mui/material"
import { useTranslation } from "react-i18next"
import Text from "../../../../components/Display/Text"
import _get from "lodash/get"
import _has from "lodash/has"
import _isEmpty from "lodash/isEmpty"
import DataWrapper from "../../../../components/Display/DataWrapper"
import BookingRatesTable from "./BookingRatesTable"
import { getRateAgeRestriction, getRateQtyRestriction, getRatesNotInBooking, isMaxQtyAttempted, buildResult } from './BookingRates.functions';
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown"
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp"
import { Add, Delete, Undo } from "@mui/icons-material"
import { validateParticipants } from "../../../../lib/booking-management"
import BookingRatesBasicData from './BookingRatesBasicData';

const mapRate = (rate, unitPriceNet, newParticipants = null, newAmount = null) => {
    let qty, participants, rateData
    if (_has(rate, "rate_id")) { // booking rates
        participants = newParticipants !== null ? newParticipants : rate.participants
        qty = participants.filter(
            (participant) => !participant.deleted
        ).length

        rateData = {
            id: rate.id,
            pvp: newAmount !== null && !unitPriceNet ? newAmount : rate.pvp,
            neto: newAmount !== null && unitPriceNet ? newAmount : rate.neto,
            unit_price: newAmount !== null ? newAmount : (unitPriceNet ? rate.neto : rate.pvp),
        }
    } else { // product rates
        participants = newParticipants !== null ? newParticipants : (_has(rate, "participants") ? rate.participants : [])
        qty = participants.filter(
            (participant) => !participant.deleted
        ).length

        rateData = {
            rate_id: parseInt(rate.id),
            pvp: parseFloat(rate.pvp),
            neto: parseFloat(rate.neto),
        }
    }

    return {
        ...rateData,
        qty: qty,
        participants: participants,
        customer_types: rate.customer_types.map((ct) => ct.id),
    }
}

const ParticipantFieldCell = ({ id, label, value, disabled, error, onChange }) => {
    return (
        <Grid item xs={12} md={3} lg>
            <TextField
                id={id}
                label={label}
                name={id}
                value={value}
                InputProps={{ size: "small" }}
                fullWidth
                disabled={disabled}
                onChange={onChange}
                error={!_isEmpty(error)}
                helperText={_isEmpty(error) ? "" : error}
                InputLabelProps={{ shrink: true }}
            />
        </Grid >
    )
}

const BookingParticipantsRow = ({ participant, minQtyAttempted, maxQtyAttempted, allowEditParticipantsData, allowEditQty, t, onChange }) => {
    const [deleted, setDeleted] = useState(false)
    const [data, setData] = useState(participant)

    const disableFields = deleted || (maxQtyAttempted && !allowEditParticipantsData)

    const handleOnInputChange = (event) => {
        const newData = {
            ...data,
            [event.target.name]: event.target.value
        }
        setData(newData)
        handleOnChange(newData)
    }

    const handleOnChange = (values) => {
        onChange({
            ...values,
            changed: true
        })
    }

    const handleOnChangeState = useCallback((del) => {
        setDeleted(del)

        onChange({
            ...data,
            deleted: del,
            changed: !del
        })
    }, [data, onChange])

    const renderIcons = useCallback((deleted) => {
        let undoDisabled = true
        let deleteDisabled = true

        if (deleted) {
            if (!maxQtyAttempted) {
                undoDisabled = false
            }
        } else if (!(deleted || minQtyAttempted)) {
            deleteDisabled = !allowEditQty
        }

        return (
            <Fragment>
                <IconButton aria-label="undo" color="primary" onClick={() => handleOnChangeState(false)} disabled={undoDisabled} sx={{ paddingX: "6px" }}>
                    <Undo />
                </IconButton>
                <IconButton aria-label="delete" color="primary" onClick={() => handleOnChangeState(true)} disabled={deleteDisabled} sx={{ paddingX: "6px" }}>
                    <Delete />
                </IconButton>
            </Fragment>
        )

    }, [minQtyAttempted, maxQtyAttempted, allowEditQty, handleOnChangeState])

    return (
        <Grid item xs={6} lg={12}>
            <Grid container spacing={2} justifyContent="flex-end">
                <ParticipantFieldCell
                    id="first_name"
                    label={t("bookings.fields.product_rates.participant.first_name")}
                    value={data.first_name}
                    error={!_get(participant, "deleted", false) && _has(participant, "errors.first_name") ? participant.errors.first_name : ""}
                    disabled={disableFields}
                    onChange={handleOnInputChange}
                />
                <ParticipantFieldCell
                    id="last_name"
                    label={t("bookings.fields.product_rates.participant.last_name")}
                    value={data.last_name}
                    error={!_get(participant, "deleted", false) && _has(participant, "errors.last_name") ? participant.errors.last_name : ""}
                    disabled={disableFields}
                    onChange={handleOnInputChange}
                />
                <ParticipantFieldCell
                    id="id_card"
                    label={t("bookings.fields.product_rates.participant.id_card")}
                    value={data.id_card}
                    error={!_get(participant, "deleted", false) && _has(participant, "errors.id_card") ? participant.errors.id_card : ""}
                    disabled={disableFields}
                    onChange={handleOnInputChange}
                />
                <Grid item xs={12} md={3} lg="auto" sx={{ textAlign: { xs: "center", lg: "right" } }}>
                    {renderIcons(deleted)}
                </Grid>
            </Grid>
        </Grid>
    )
}

const BookingParticipantsTable = ({ participants, minQtyAttempted, maxQtyAttempted, allowEditParticipantsData, allowEditQty, onChange }) => {
    const { t } = useTranslation("vbms")

    return (
        <Grid container spacing={2} sx={{ paddingTop: 1 }}>
            {participants && participants.map((participant) => (
                <BookingParticipantsRow
                    key={_has(participant, "id") ? participant.id : participant.row_id}
                    participant={participant}
                    minQtyAttempted={minQtyAttempted}
                    maxQtyAttempted={maxQtyAttempted}
                    allowEditParticipantsData={allowEditParticipantsData}
                    allowEditQty={allowEditQty}
                    t={t}
                    onChange={onChange}
                />
            ))}
        </Grid >
    )
}

const BookingRateRow = (props) => {
    const {
        index,
        rate,
        maxQtyAttempted,
        allowEditParticipantsData,
        allowEditQty,
        allowEditAmount,
        hideNetPrice,
        hideBasePrice,
        hideRefundCost,
        disableExpandIcon,
        currency,
        mainCurrency,
        exchangeRate,
        costExchangeRate,
        onChange
    } = props

    const { t, i18n } = useTranslation("vbms")
    const [value, setValue] = useState(mapRate(rate, !hideNetPrice))
    const [open, setOpen] = useState(rate.participants && rate.participants.length > 0)

    const ageRestriction = getRateAgeRestriction(rate)
    const qtyRestriction = getRateQtyRestriction(rate)

    const getParticipantQty = useCallback((participants) => {
        if (participants) {
            return participants
                .filter((participant) => !_get(participant, "deleted", false))
                .length
        } else {
            return (rate.participants ? rate.participants : [])
                .filter((participant) => !_get(participant, "deleted", false))
                .length
        }
    }, [rate.participants])

    const getMinRateQtyAttempted = useCallback((participants) => {
        return qtyRestriction.min !== 0 &&
            getParticipantQty(participants) - qtyRestriction.min <= 0
    }, [getParticipantQty, qtyRestriction.min])

    const getMaxRateQtyAttempted = useCallback((participants) => {
        return maxQtyAttempted ||
            (qtyRestriction.max !== 0 && (qtyRestriction.max - getParticipantQty(participants) === 0))
    }, [getParticipantQty, maxQtyAttempted, qtyRestriction.max])

    const handleOnAmountChange = (newAmount) => {
        if (newAmount !== value.unit_price) {
            const mRate = mapRate(rate, !hideNetPrice, null, newAmount)

            setValue(mRate)
            onChange(mRate)
        }
    }

    const handleOnParticipantsChange = useCallback((participants) => {
        const mRate = mapRate(rate, !hideNetPrice, participants, value.unit_price)

        setValue(mRate)
        onChange(mRate)
    }, [rate, onChange, hideNetPrice])

    const handleOnParticipantChange = useCallback((newParticipantValues) => {
        const valuesList = _get(value, "participants", [])

        if (_has(newParticipantValues, "id")) { // booking rate participant
            const index = valuesList.findIndex((participant) => newParticipantValues.id === parseInt(participant.id))

            valuesList[index] = newParticipantValues
            handleOnParticipantsChange(valuesList)
        } else { // product rate participant
            const index = valuesList.findIndex((participant) => newParticipantValues.row_id === participant.row_id)

            if (index >= 0) {
                if (_get(newParticipantValues, "deleted", false) &&
                    _isEmpty(newParticipantValues.first_name) && _isEmpty(newParticipantValues.last_name) && _isEmpty(newParticipantValues.id_card)) {
                    valuesList.splice(index, 1)
                } else {
                    valuesList[index] = newParticipantValues
                }

                handleOnParticipantsChange(valuesList)
            } else {
                valuesList.push(newParticipantValues)
                handleOnParticipantsChange(valuesList)
            }
        }
    }, [value.participants, handleOnParticipantsChange])

    const handleOnAddParticipant = () => {

        const rowId = !_isEmpty(value.participants) && _has(value.participants[value.participants.length - 1], "row_id") ?
            parseInt(value.participants[value.participants.length - 1].row_id.split("_")[1]) + 1 :
            0

        const tableId = _has(value, "rate_id") ? value.rate_id : rate.id
        handleOnParticipantChange({
            row_id: tableId + "_" + rowId,
            first_name: "",
            last_name: "",
            id_card: ""
        })
        setOpen(true)
    }

    return (
        <Fragment key={rate.id}>
            <TableRow sx={{ backgroundColor: index % 2 === 0 ? "inherited" : "action.hover" }}>
                <TableCell align="center" sx={{ borderBottom: "unset" }} size="small">
                    <IconButton
                        aria-label="expand row"
                        size="small"
                        onClick={() => setOpen(!open)}
                        disabled={disableExpandIcon}
                    >
                        {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                    </IconButton>
                </TableCell>
                <BookingRatesBasicData
                    rate={rate}
                    allowEditAmount={allowEditAmount}
                    hideBasePrice={hideBasePrice}
                    hideNetPrice={hideNetPrice}
                    hideRefundCost={hideRefundCost}
                    i18n={i18n}
                    unitPrice={value.unit_price}
                    t={t}
                    currency={currency}
                    mainCurrency={mainCurrency}
                    exchangeRate={exchangeRate}
                    costExchangeRate={costExchangeRate}
                    onChange={handleOnAmountChange}
                />
                <TableCell align="center">
                    <Stack
                        direction="column"
                        justifyContent="center"
                        alignItems="center"
                    >
                        <Box>
                            <Text>{value.qty}</Text>
                        </Box>
                        <Box>
                            {_get(ageRestriction, "min", -1) >= 0 && (
                                <Text variant="body2">
                                    {t("booking_widget.steps.rates.age_restriction", {
                                        from: ageRestriction.min,
                                        to: ageRestriction.max,
                                    })}
                                </Text>
                            )}
                        </Box>
                    </Stack>
                </TableCell>
                <TableCell align="center">
                    <IconButton
                        aria-label="add"
                        color="primary"
                        sx={{ padding: 0 }}
                        onClick={() => handleOnAddParticipant()}
                        disabled={getMaxRateQtyAttempted(value.participants) || !allowEditQty} >
                        <Add />
                    </IconButton>
                </TableCell>
            </TableRow>
            <TableRow sx={{ backgroundColor: index % 2 === 0 ? "inherited" : "action.hover" }}>
                <TableCell />
                <TableCell sx={{ padding: 0 }} colSpan={4 + (!hideNetPrice ? 1 : 0) + (!hideBasePrice ? 1 : 0)}>
                    <Collapse in={open} timeout="auto" unmountOnExit>
                        <Box sx={{ marginLeft: 2 }}>
                            <BookingParticipantsTable
                                participants={value.participants}
                                minQtyAttempted={getMinRateQtyAttempted(value.participants)}
                                maxQtyAttempted={getMaxRateQtyAttempted(value.participants)}
                                allowEditParticipantsData={allowEditParticipantsData}
                                allowEditQty={allowEditQty}
                                onChange={handleOnParticipantChange}
                            />
                        </Box>
                    </Collapse>
                </TableCell>
            </TableRow>
        </Fragment>
    )
}

const BookingRatesParticipants = (props) => {
    const {
        rates,
        selection,
        max,
        isRetailPrice,
        hideProductRates,
        hideRefundCost,
        allowEditParticipantsData,
        allowEditQty,
        allowEditAmount,
        currency,
        mainCurrency,
        exchangeRate,
        costExchangeRate,
        onChange
    } = props

    const { t } = useTranslation("vbms")

    const finalRates = useMemo(() => {
        return selection.length > 0 ? selection.map((rate) => mapRate(rate, !isRetailPrice)) : []
    }, [selection, isRetailPrice])

    const productRates = getRatesNotInBooking(rates, selection)

    const productRatesHasParticipants = () => {
        return productRates.filter(
            (rate) => finalRates
                .map((finalRate) => {
                    return parseInt(finalRate.rate_id)
                })
                .includes(parseInt(rate.id))
        ).length > 0
    }

    const getProductRatesWithParticipants = useCallback(() => {
        return productRates.map((rate) => {
            const index = finalRates.findIndex(
                (finalRate) => parseInt(finalRate.rate_id) === parseInt(rate.id)
            )

            if (index >= 0) {
                return {
                    ...rate,
                    participants: finalRates[index].participants
                }
            } else {
                return rate
            }
        })
    }, [productRates, finalRates])

    const validateRates = useCallback(() => {
        const pValidation = validateParticipants(finalRates, "rates", "id_card", true, t)
        if (_has(pValidation, "rates")) {
            finalRates.forEach((_, index) => {
                pValidation.rates.forEach((validationRate) => {
                    validationRate.participants.forEach((validationParticipant) => {
                        let pIndex
                        if (_has(validationParticipant, "id")) {
                            pIndex = finalRates[index].participants.findIndex(
                                (participant) => _has(participant, "id") && participant.id === validationParticipant.id
                            )
                        } else if (_has(validationParticipant, "row_id")) {
                            pIndex = finalRates[index].participants.findIndex(
                                (participant) => _has(participant, "row_id") && participant.row_id === validationParticipant.row_id
                            )
                        }

                        if (pIndex >= 0) {
                            finalRates[index].participants[pIndex] = {
                                ...finalRates[index].participants[pIndex],
                                valid: false,
                                errors: validationParticipant
                            }
                        }
                    })
                })
            })
        } else {
            finalRates.forEach((_, index) => {
                finalRates[index].participants.forEach((_, pIndex) => {
                    finalRates[index].participants[pIndex] = {
                        ...finalRates[index].participants[pIndex],
                        valid: true,
                        errors: []
                    }
                })
            })
        }
    }, [finalRates, t])

    const handleOnChange = useCallback((newRateValues) => {
        let index
        let changed = false

        if (_has(newRateValues, "id")) { // booking rate
            index = finalRates.findIndex(
                (rate) => parseInt(newRateValues.id) === parseInt(rate.id)
            )

            finalRates[index] = {
                ...finalRates[index],
                qty: newRateValues.qty,
                participants: newRateValues.participants,
                pvp: newRateValues.pvp,
                neto: newRateValues.neto,
                unit_price: newRateValues.unit_price,
            }

            changed = true
        } else { // product rate
            index = finalRates.findIndex(
                (rate) => parseInt(newRateValues.rate_id) === parseInt(rate.rate_id)
            )

            if (index >= 0) {
                finalRates[index].qty = newRateValues.qty
                finalRates[index].participants = newRateValues.participants

                if (newRateValues.qty === 0) {
                    finalRates.splice(index, 1)
                }

                changed = true
            } else if (index < 0) {
                finalRates.push(newRateValues)

                changed = true
            }
        }

        validateRates()

        if (changed) {
            onChange(buildResult(selection, finalRates, true, allowEditAmount))
        }
    }, [finalRates, selection, onChange, validateRates, allowEditAmount])

    useEffect(() => {
        buildResult(selection, finalRates, true, allowEditAmount)
    }, [selection, finalRates, allowEditAmount])

    return (
        <Grid container spacing={2}>
            <Grid item xs={12} lg={hideProductRates || productRates.length === 0 ? 12 : 6}>
                <DataWrapper
                    title={t("bookings.rates_edit_form.booking_rates.title")}
                    subtitle={t("bookings.rates_edit_form.booking_rates.subtitle")}
                    subtitleVariant="text"
                >
                    <Grid container spacing={2} sx={{ marginTop: 1 }}>
                        <BookingRatesTable
                            rates={selection}
                            withParticipants={true}
                            maxQtyAttempted={isMaxQtyAttempted(max, finalRates)}
                            allowEditParticipantsData={allowEditParticipantsData}
                            allowEditQty={allowEditQty}
                            allowEditAmount={allowEditAmount}
                            hideNetPrice={isRetailPrice}
                            hideBasePrice={true}
                            hideRefundCost={hideRefundCost}
                            disableExpandIcon={true}
                            RateRowComponent={BookingRateRow}
                            t={t}
                            currency={currency}
                            mainCurrency={mainCurrency}
                            exchangeRate={exchangeRate}
                            costExchangeRate={costExchangeRate}
                            onChange={handleOnChange}
                        />
                    </Grid>
                </DataWrapper>
            </Grid>
            {
                !hideProductRates && productRates.length > 0 && <Grid item xs={12} lg={6}>
                    <DataWrapper
                        title={t("bookings.rates_edit_form.product_rates.title")}
                        subtitle={t("bookings.rates_edit_form.product_rates.subtitle")}
                        subtitleVariant="text"
                    >
                        <Grid container spacing={2} sx={{ marginTop: 1 }}>
                            <BookingRatesTable
                                rates={getProductRatesWithParticipants()}
                                withParticipants={true}
                                maxQtyAttempted={isMaxQtyAttempted(max, finalRates)}
                                allowEditParticipantsData={true}
                                allowEditQty={allowEditQty}
                                allowEditAmount={false}
                                hideNetPrice={isRetailPrice}
                                hideBasePrice={true}
                                hideRefundCost={true}
                                disableExpandIcon={productRatesHasParticipants() || !isMaxQtyAttempted(max, finalRates)}
                                RateRowComponent={BookingRateRow}
                                t={t}
                                currency={currency}
                                mainCurrency={mainCurrency}
                                exchangeRate={exchangeRate}
                                costExchangeRate={costExchangeRate}
                                onChange={handleOnChange}
                            />
                        </Grid>
                    </DataWrapper>
                </Grid>}
        </Grid>
    )
}

export default BookingRatesParticipants