import { createExportField } from "../../../components/helpers/fieldHelper"
import _get from "lodash/get"
import _isDate from "lodash/isDate"
import { format as formatDate } from "date-fns"
import { CUSTOMER_TYPE_IDS, getPax, processDate } from "../BookingsCollection/utils"
import { pushFields } from "../../../lib/utils"

export const getTableExport = (apiClient, t, user, filterConfig) => {

    const columns = [
        createExportField(
            "experience.name",
            t("bookings.fields.experience")
        ),
        createExportField(
            "product.name",
            t("bookings.fields.product")
        ),
        createExportField("locator", t("bookings.fields.locator")),
        createExportField(
            "order.voucher_id",
            t("bookings.fields.order.voucher_id")
        ),
        createExportField(
            "product.supplier.name",
            t("bookings.fields.supplier")
        ),
        createExportField(
            "order.customer.full_name",
            t("bookings.fields.order.customer.first_name"),
            null
        ),
        createExportField(
            "pax_adults",
            t("bookings.fields.pax_adult"),
            null,
            (booking) => getPax(booking.product_rates, CUSTOMER_TYPE_IDS.ADULTS)
        ),
        createExportField(
            "pax_children",
            t("bookings.fields.pax_children"),
            null,
            (booking) => getPax(booking.product_rates, CUSTOMER_TYPE_IDS.CHILDREN)
        ),
        createExportField(
            "pax_total",
            t("bookings.fields.pax_total"),
            null,
            (booking) => getPax(booking.product_rates)
        ),
        createExportField(
            "order.language",
            t("common.language")
        ),
        createExportField(
            "order.lodgin.name",
            t("bookings.fields.pickup.lodgin")
        ),
        createExportField(
            "order.salesman.first_name",
            t("bookings.filter.salesman")
        ),
        createExportField(
            "order.collaborator.name",
            t("bookings.fields.order.collaborator.title")
        ),
        createExportField(
            "order.customer.phone",
            t("common.contact_details.phone") + " Cl.",
            null,
            (booking) => booking.order.customer.phone || booking.order.customer.phone_other
        ),
        createExportField(
            "order.salesman.phone",
            t("common.contact_details.phone") + " Vn.",
        ),
        createExportField(
            "confirmed",
            t("bookings.fields.confirmed"),
            null,
            (booking) => booking.confirmed ? t("common.yes") : t("common.no")
        ),
        createExportField(
            "order.pickup.name",
            t("bookings.fields.pickup.name")
        ),
        createExportField(
            "order.pickup.pickup_time",
            t("bookings.fields.pickup.time")
        ),
        createExportField(
            "booking_date",
            t("bookings.fields.booking_date_hour"),
            null,
            (booking) => processDate(booking.booking_date, "HH:mm")
        ),
    ]

    const conditionalColumns = [
        {
            field: createExportField(
                "notes",
                t("bookings.fields.order.customer.notes")
            ),
            condition: !user.isIntermediary()
        }
    ]
    pushFields(columns, conditionalColumns)

    return (filter, progressHandler) => {
        // booking date is required
        let date = _get(filter, "booking_date", new Date())
        if (date === "") {
            date = new Date()
        }

        if (_isDate(date)) {
            date = formatDate(date, "yyyy-MM-dd")
        }

        const params = {
            valid: 1,
            cancelled: 0,
            booking_date_from: date,
            booking_date_to: date,
            experience_name: _get(filter, "experience_name", null),
            experience_id: _get(filter, "experience_id[0].id", null),
            activity_id: _get(filter, "activity_id[0].id", null),
            sort: "product_name",
            sort_direction: "asc",
        }

        apiClient.booking.exportBookings(
            columns,
            params,
            progressHandler
        )
    }
}
