import { useState, useCallback } from "react"
import { useNavigate } from "react-router-dom"
import { useVolcanoApiClient } from "../../context/VolcanoApiClientProvider"
import { useTranslation } from "react-i18next"
import SuggestionForm from "./SuggestionForm"
import SuggestionMessage from "./SuggestionMessage"
import useSnackBars from "../Snackbar/snack-bar.context"
import _every from 'lodash/every'

const SUGGESTION_CARD_STATE_INITIAL = 0;
const SUGGESTION_CARD_STATE_SUCCESS = 1;

const SuggestionCard = ({ title, image }) => {

    const { t } = useTranslation("vbms")
    const navigate = useNavigate()
    const { apiClient } = useVolcanoApiClient()
    const [loading, setLoading] = useState(false)
    const [state, setState] = useState(SUGGESTION_CARD_STATE_INITIAL)
    const { addAlert } = useSnackBars()

    const suggestionsFetcher = useCallback((entity) => {
        setLoading(true)
        return apiClient.suggestion
            .addSuggestion(entity)
            .then((result) => {
                setState(SUGGESTION_CARD_STATE_SUCCESS)
                return result
            })
            .catch((error) => {
                addAlert(`Error [${error?.type}]: ${error?.message}`, "error")
            })
            .finally(() => setLoading(false))
    }, [apiClient, addAlert])

    const onSubmitHandler = (values) => {
        if (!_every(values)) {
            addAlert(t("suggestions.actions.send.incomplete"), "error")
            return
        }
        setLoading(true)
        const result = suggestionsFetcher(values)

        return result
    }

    const setSuggestionInitialHandler = () => {
        setState(SUGGESTION_CARD_STATE_INITIAL)
    }

    const renderState = (state) => {
        switch (state) {
            case SUGGESTION_CARD_STATE_INITIAL:
                return (
                    <SuggestionForm
                        title={title}
                        loading={loading}
                        onSubmit={onSubmitHandler}
                    />
                )
            case SUGGESTION_CARD_STATE_SUCCESS:
                return (
                    <SuggestionMessage
                        label={t("suggestions.actions.send.confirm")}
                        setLoginScreen={() => navigate("/login")}
                        setSuggestionInitial={setSuggestionInitialHandler}
                    />
                )
            default:
                return null
        }
    }

    return (
        <>
            {renderState(state)}
        </>
    )

}

export default SuggestionCard