import availableRoutes from "../../../../routes/availableRoutes";
import _has from "lodash/has";
import ZoneForm from "../ZoneForm";

export const getActions = (apiClient, t, user, navigate, entityId = null) => {


    return [
        {
            id: "edit",
            title: t("common.edit"),
            reloadOnSuccess: true,
            showLoading: true,
            withDialog: {
                title: t("enterprise.actions.add_currency.modal_title"),
                content: ZoneForm
            },
            onExecute: (zone, progressHandler, onSuccess, onError) => {
                return apiClient.activityManager.zone.editZone(entityId, zone)
                    .then((result) => {
                        onSuccess(
                            t("zones.actions.edit.confirm", {
                                name: zone.name,
                            })
                        )

                        return { ...result, id: entityId }
                    })
                    .catch((error) => {
                        onError(
                            t("zones.actions.edit.error", {
                                name: zone.name,
                            })
                        )
                    })
            },
            condition: (zone) => _has(zone.getActions(), "edit")
        },
        {
            id: "delete",
            title: t("common.delete"),
            reloadOnSuccess: true,
            showLoading: true,
            confirmDialog: {
                title: t("zones.actions.delete.modal_title"),
                content: (zone) =>
                    t("zones.actions.delete.modal_content", {
                        name: zone.name,
                    }),
            },
            onExecute: (zone, progressHandler, onSuccess, onError) => {
                return  apiClient.activityManager.zone.deleteZone(zone.id)
                    .then(() => {
                        onSuccess(
                            t("zones.actions.delete.confirm", {
                                name: zone.name,
                            })
                        )
                        navigate(availableRoutes.zones.path)
                    })
                    .catch((error) => {
                        onError(
                            t("zones.actions.delete.error", {
                                name: zone.name,
                            })
                        )
                    })
            },
            condition: (zone) => _has(zone.getActions(), "delete")
        }
    ]
}