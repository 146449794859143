import { Box } from "@mui/material"
import { Field } from "formik"
import { TextField } from "formik-mui"
import { useTranslation } from "react-i18next"
import { validate } from "./utils"

const CurrencyFormField = ({ field, value }) => {
    const { t } = useTranslation("vbms")

    return (
        <Box>
            <Field
                component={TextField}
                name={field.id}
                label={field.label}
                required={field.required}
                validate={(value) => validate(field, t, value)}
                InputProps={{
                    size: "small",
                    inputMode: 'numeric'
                }}
                InputLabelProps={{
                    shrink: true,
                }}
            />
        </Box>
    )
}

export default CurrencyFormField
