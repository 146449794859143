import { Avatar, Box, Chip, ClickAwayListener, Grow, MenuItem, MenuList, Paper, Popper, useMediaQuery, useTheme } from "@mui/material";
import { useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useVolcanoAuth } from "../../../context/VolcanoAuthContext";

const ProfileSection = () => {
    const { t } = useTranslation("vbms")
    const [opened, setOpened] = useState(false)
    const anchorRef = useRef(null)
    const volcanoAuth = useVolcanoAuth()
    const theme = useTheme()
    const matchUpMd = useMediaQuery(theme.breakpoints.up('md'))
    const navigate = useNavigate()

    const handleToggle = () => {
        setOpened(!opened)
    }

    const handleClose = (event) => {
        if (anchorRef.current && anchorRef.current.contains(event.target)) {
            return
        }

        setOpened(false)
    }

    const handleListKeyDown = (event) => {
        if (event.key === "Tab") {
            event.preventDefault();
            setOpened(false);
        }
    }

    const handleProfile = () => {
        navigate('/settings/users/' + volcanoAuth.user.id)
        setOpened(false)
    }

    const handleLogout = () => {
        volcanoAuth.logout()
        setOpened(false);
    }

    const renderLabel = (title, subtitle) => {
        if (matchUpMd) {
            return (
                <>
                    <Box sx={{ textOverflow: "ellipsis", "whiteSpace": "nowrap", overflow: "hidden" }}>{title}</Box>
                    <Box sx={{ textOverflow: "ellipsis", "whiteSpace": "nowrap", overflow: "hidden", fontSize: "0.6rem" }}>{subtitle}</Box>
                </>
            )
        }

        return null
    }

    return (
        <>
            <Chip
                avatar={<Avatar>{volcanoAuth.user.name.replace('-', '').match(/\b(\w)/g).slice(0, 2).join('')}</Avatar>}
                label={renderLabel(volcanoAuth.user.name, volcanoAuth.user.crm_intermediary ? volcanoAuth.user.crm_intermediary.name : volcanoAuth.user.corporate_account.enterprise.name)}
                onClick={handleToggle}
                ref={anchorRef}
                aria-controls={opened ? "menu-list-grow" : undefined}
                aria-haspopup="true"
                sx={{
                    maxWidth: 150,
                    [theme.breakpoints.down('md')]: {
                        '& .MuiChip-label': {
                            display: 'none',
                        },
                        '& .MuiChip-avatar': {
                            marginRight: "6px",
                        }
                    }
                }}
            />
            <Popper
                placement="bottom-end"
                open={opened}
                anchorEl={anchorRef.current}
                role={undefined}
                transition
                disablePortal
            >
                {({ TransitionProps, placement }) => (
                    <Grow
                        {...TransitionProps}
                        style={{
                            transformOrigin:
                                placement === "bottom" ? "center top" : "center bottom",
                        }}
                    >
                        <Paper>
                            <ClickAwayListener onClickAway={handleClose}>
                                <MenuList
                                    autoFocusItem={opened}
                                    id="menu-list-grow"
                                    onKeyDown={handleListKeyDown}
                                >
                                    <MenuItem onClick={handleProfile}>{t("header.profile.account")}</MenuItem>
                                    <MenuItem onClick={handleLogout}>{t("header.profile.logout")}</MenuItem>
                                </MenuList>
                            </ClickAwayListener>
                        </Paper>
                    </Grow>
                )}
            </Popper>
        </>
    )
}

export default ProfileSection;