import { useTranslation } from "react-i18next"
import { useVolcanoApiClient } from '../../../../../context/VolcanoApiClientProvider'
import { useCache } from '../../../../../context/cache.context'
import { useCallback, useEffect, useState } from "react"
import { Box, Button, Stack, FormControl, InputLabel, MenuItem, Select, Table, TableContainer, TableBody, TableCell, TableRow } from '@mui/material'
import { useDialog } from '../../../../../components/Dialog/dialog.context'
import Text from '../../../../../components/Display/Text'
import Counter from '../../../../../components/Counter/Counter'
import { getRateName } from '../../../BookingRatesFormPage/components/BookingRates.functions'
import DataWrapper from '../../../../../components/Display/DataWrapper';
import { arraysEqual } from "../../../../../lib/utils"

const filterBookingRates = (bookingRates) => {
    return bookingRates.filter((bookingRate) => bookingRate.qty > 0)
}

const ManagerCollaboratorSelect = ({ intermediaries, managerCollaborator, t, onManagerCollaboratorChange }) => {

    const handleOnManagerCollaboratorChange = (value, text) => {
        if (value !== managerCollaborator.id) {
            onManagerCollaboratorChange({ id: value, name: text })
        }
    }

    return (
        <>
            {intermediaries && <FormControl className="selectField" required={true} fullWidth>
                <InputLabel id="manager_collaborator_id_label" htmlFor="manager_collaborator_id" shrink>
                    {t("bookings.fields.assign_pax_manager_collaborator.manager_collaborator")}
                </InputLabel>
                <Select
                    labelId="manager_collaborator_id_label"
                    id="manager_collaborator_id"
                    value={managerCollaborator.id}
                    label={t("bookings.fields.assign_pax_manager_collaborator.manager_collaborator")}
                    displayEmpty
                    size="small"
                    notched={true}
                    onChange={(e, index) => handleOnManagerCollaboratorChange(e.target.value, index.props.children)}
                >
                    {intermediaries.map((option, index) => {
                        return <MenuItem key={index} value={option.id}>{option.name}</MenuItem>
                    })}
                </Select>
            </FormControl>}
        </>
    )
}

const BookingRatesTable = ({ bookingRates, t, onRatesChange }) => {

    let finalRates = []

    const handleOnRatesChange = (id, value) => {
        const rate = { id: id, qty: value }
        const index = finalRates.findIndex(x => x.id === id)

        if (index >= 0) {
            if (finalRates[index].qty !== value) {
                finalRates[index] = rate
                onRatesChange(finalRates)
            }
        } else {
            finalRates.push(rate)
            onRatesChange(finalRates)
        }
    }

    return (
        <DataWrapper title={t("bookings.fields.assign_pax_manager_collaborator.booking_rates")}>
            <TableContainer component={Box} sx={{ paddingLeft: 2 }}>
                <Table
                    size="small"
                    sx={{
                        '& td, & th': { border: 0 },
                        '& td:last-child, & th:last-child': { pr: 0 },
                        '& td:first-of-type, & th:first-of-type': { pl: 0 },
                    }}
                >
                    <TableBody>
                        {bookingRates.map((bookingRate, index) => (
                            <TableRow key={index}>
                                <TableCell align="left"><Text>{getRateName(bookingRate)}</Text></TableCell>
                                <TableCell align="center">
                                    <Stack
                                        direction="column"
                                        justifyContent="center"
                                        alignItems="center"
                                    >
                                        <Box>
                                            <Counter
                                                defaultValue={0}
                                                min={0}
                                                max={bookingRate.qty}
                                                onChange={(value) => handleOnRatesChange(bookingRate.id, value)}
                                            />
                                        </Box>
                                    </Stack>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </DataWrapper>
    )
}

const FormButtons = ({ showConfirm, t, onConfirm, onClose }) => {
    return (
        <Box
            display="flex"
            justifyContent="flex-end"
            alignItems="center" mt={2}
        >
            <Stack direction="row" spacing={1}>
                {showConfirm &&
                    <Button
                        color="primary"
                        variant="contained"
                        type="submit"
                        onClick={() => onConfirm()}>
                        {t("common.accept")}
                    </Button>}
                <Button
                    color="primary"
                    variant="contained"
                    type="submit"
                    onClick={() => onClose()}>
                    {t("common.cancel")}
                </Button >
            </Stack>
        </Box>
    )
}

const BookingAssignPaxManagerCollaborator = ({ selection, onAction, onCancel }) => {
    const { apiClient } = useVolcanoApiClient()
    const { handleClose } = useDialog()
    const cache = useCache()
    const { t } = useTranslation("vbms")

    const [intermediaries, setIntermediaries] = useState(null)
    const [data, setData] = useState({ manager_collaborator: { id: '', name: '' }, booking_rates: [] })

    const intermediariesFetcher = useCallback(() => {
        return cache.get(
            "manager_collaborators",
            null,
            () => apiClient.collaborator.getCollaborators({ "collaborator_id": selection.order.collaborator.id }).then((res) => res.getItems())
        )
    }, [cache, apiClient.collaborator, selection.order.collaborator.id])

    const handleOnManagerCollaboratorChange = (value) => {
        setData({
            ...data,
            manager_collaborator: value
        })
    }

    const handleOnRatesChange = (value) => {
        if (!arraysEqual(data.booking_rates, value)) {
            setData({
                ...data,
                booking_rates: value
            })
        }
    }

    const onConfirm = () => {
        onAction({
            locator: selection.locator,
            order_id: selection.order.id,
            manager_collaborator: data.manager_collaborator,
            booking_rates: filterBookingRates(data.booking_rates),
        }).finally(() => {
            onClose()
        })
    }

    const onClose = () => {
        handleClose()
    }

    useEffect(() => {
        intermediariesFetcher().then((res) => {
            setIntermediaries(res)
        })

    }, [intermediariesFetcher])

    return (
        <Stack spacing={2} sx={{ marginTop: 1 }}>
            <ManagerCollaboratorSelect
                intermediaries={intermediaries}
                managerCollaborator={data.manager_collaborator}
                t={t}
                onManagerCollaboratorChange={handleOnManagerCollaboratorChange}
            />
            <BookingRatesTable
                bookingRates={selection.product_rates}
                t={t}
                onRatesChange={handleOnRatesChange}
            />
            <FormButtons
                data={data}
                t={t}
                showConfirm={data.manager_collaborator.id !== '' && filterBookingRates(data.booking_rates).length > 0}
                onConfirm={onConfirm}
                onClose={onClose}
            />
        </Stack>
    )
}

export default BookingAssignPaxManagerCollaborator