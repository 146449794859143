import CollectionPage from "../../../../layout/templates/CollectionPage/CollectionPage"
import { useTranslation } from "react-i18next"
import { useNavigate } from "react-router-dom"
import { useVolcanoApiClient } from "../../../../context/VolcanoApiClientProvider"
import { useVolcanoAuth } from '../../../../context/VolcanoAuthContext'
import { has, isEmpty } from "lodash"
import { getTableFilter } from './OfficesCollection.filter'
import { getActions } from "./OfficesCollection.actions"
import { getTableColumns } from './OfficesCollection.columns'
import { useCache } from '../../../../context/cache.context'
import _get from "lodash/get"

const OfficesCollection = () => {
    const { apiClient } = useVolcanoApiClient()
    const { t } = useTranslation("vbms")
    const navigate = useNavigate()
    const volcanoAuth = useVolcanoAuth()
    const cache = useCache()

    const filterConfig = getTableFilter(apiClient, t, volcanoAuth.user, cache)

    const table = {
        columns: getTableColumns(t, has(volcanoAuth.user, "crm_intermediary_id")),
        fetcher: (params) => {
            if (params && has(params, "state") && !isEmpty(params.state)) {
                params.state = params.state === "1" ? "active" : "inactive"
            }
            
            const queryFilter = {
                ...params,
                crm_intermediary_id: _get(params, "intermediary_id[0].entity.crm_intermediary_id", null)
            }
            
            return  apiClient.intermediary.getIntermediaryOffices(null, queryFilter)
        }
    }

    return (
        <CollectionPage
            title={t("intermediaries.offices.title")}
            subtitle={t("intermediaries.offices.subtitle")}
            actions={getActions(t, navigate, volcanoAuth.user)}
            table={table}
            filterConfig={filterConfig}
        />
    )
}

export default OfficesCollection