import {Field} from "formik"
import {DatePicker} from "@mui/x-date-pickers/DatePicker"
import {useTranslation} from "react-i18next"
import _get from "lodash/get"
import {FormHelperText} from "@mui/material";
import {isDate} from "lodash";

const RangeDatesFormField = ({ field, form , name, label, required, referenceField, isMinField}) => {

    const { i18n } = useTranslation("vbms")
    const language = i18n.language

    const handleChange = (value) => {
        form.setFieldValue(name, value || null)
        form.setFieldTouched(name, true, false)
    }

    const getReferenceFieldId = () => {
        const fieldParts = name.split('.')
        if (fieldParts.length === 1) {
            return referenceField
        }
        fieldParts.pop()
        const parentPath = fieldParts.join('.')

        return `${parentPath}.${referenceField}`
    }

    const getRefValue = () => {
        const reference = getReferenceFieldId()
        return _get(form.values, reference)
    }

    const getDateValue = () => {
        if (typeof field.value === "object") {
            return field.value
        }

        return null
    }

    const getMinDate = () => {
        if (!isMinField) {
            return getRefValue()
        }
        return null
    }

    const getMaxDate = () => {
        if (isMinField) {
            return getRefValue()
        }
        return null
    }


    const getDateFormatString = (locale) => {
        const formatObj = new Intl.DateTimeFormat(locale).formatToParts(new Date())

        return formatObj
            .map((obj) => {
                switch (obj.type) {
                    case "day":
                        return "dd"
                    case "month":
                        return "MM"
                    case "year":
                        return "yyyy"
                    default:
                        return obj.value
                }
            })
            .join("")
    }

    return (
        <DatePicker
            openTo="day"
            views={["year", "month", "day"]}
            format={getDateFormatString(language)}
            label={label}
            value={getDateValue()}
            onChange={handleChange}
            minDate={getMinDate()}
            maxDate={getMaxDate()}
            slotProps={{
                textField: {
                    size: 'small',
                    className: 'dateField',
                    InputLabelProps: { shrink: true },
                    fullWidth: true,
                    required: required
                }
            }}
        />
    )
}

const validate = (t, value, required) => {
    if (required && !value) {
        return t("common.form.validation.required")
    }

    if (value && (!isDate(value) || isNaN(value))) {
        return t("common.form.validation.invalid_date")
    }

    return null
}

const FormikRangeDatesFormField = ({field}) => {

    const { t } = useTranslation("vbms")
    const mainField = field

    return (
        <Field
            name={field.id}
            label={field.label}
            required={field.required}
            referenceField={field.referenceField}
            isMinField={field.isMinField}
            validate={(value) => validate(t, value, field.required)}
        >
            {({ field, form, meta }) => (
                <>
                    <RangeDatesFormField
                        field={field}
                        form={form}
                        name={mainField.id}
                        label={mainField.label}
                        required={mainField.required}
                        referenceField={mainField.referenceField}
                        isMinField={mainField.isMinField}
                    />

                    {meta.error && meta.touched && <FormHelperText error={true}>{meta.error}</FormHelperText>}
                </>
            )}
        </Field>
    )
}

export default FormikRangeDatesFormField
