import constants from '../../../components/entity/constants'
import { createDataSetFacet, createField, createCallbackField, createFacetsContainer, createFacetField, createObjectStatusField, discountCodesTypeMapping } from '../../../components/helpers/fieldHelper'

export const getHeaderContent = (t, user) => [
    createDataSetFacet(t("discount_codes.view.facet_information"), [
        createField("id", "Id", constants.STRING_TYPE),
        createField(
            "value",
            t("discount_codes.fields.value"),
            constants.NUMERIC_TYPE
        ),
        createField(
            "type",
            t("discount_codes.fields.type.name"),
            constants.STRING_TYPE,
            discountCodesTypeMapping(t)
        ),
        createField(
            "site",
            t("discount_codes.fields.site"),
            constants.STRING_TYPE,
        ),
    ]),
    createDataSetFacet(t("discount_codes.view.facet_information_validity"), [
        createField(
            "valid_from",
            t("discount_codes.fields.valid_from"),
            constants.DATE_TYPE
        ),
        createField(
            "valid_to",
            t("discount_codes.fields.valid_to"),
            constants.DATE_TYPE
        ),
    ]),
    createDataSetFacet(t("discount_codes.view.facet_information_usage"), [
        createField(
            "usages",
            t("discount_codes.fields.usages"),
            constants.NUMERIC_TYPE
        ),
        createCallbackField(
            "use_limit",
            t("discount_codes.fields.use_limit"),
            constants.STRING_TYPE,
            (value) => value || t("discount_codes.constants.unlimited")
        ),
    ]),
    createFacetsContainer([
        createFacetField("object_status", {
            size: "small",
            field: createObjectStatusField(
                createCallbackField(
                    "usages_left",
                    t("discount_codes.fields.usages_left"),
                    constants.STRING_TYPE,
                    (value, entity) => {
                        if (isNaN(entity.use_limit)) {
                            return t("discount_codes.constants.unlimited")
                        }
                        
                        return entity.use_limit - entity.usages
                    }
                )
            ),
            condition: (entity) => entity.valid
        }),
    ]),
    createFacetsContainer([
        createFacetField("object_status", {
            size: "small",
            field: createObjectStatusField(
                createField(
                    "active",
                    t("common.states.active"),
                    constants.BOOLEAN_TYPE
                ),
                null,
                (value) => value ? "positive" : "negative"
            ),
        }),
    ]),
]