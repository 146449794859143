import { useTranslation } from "react-i18next";
import constants from "../../../../../../components/entity/constants";
import {
    createField
} from "../../../../../../components/helpers/fieldHelper";
import ArrayDataTable from "../../../../../../components/Datatable/ArrayDataTable";

const RouteProducts = (props) => {

    const { t } = useTranslation("vbms")

    const columns = [
        createField(
            "experience.name",
            t("routes.fields.experience"),
            constants.STRING_TYPE
        ),
        createField(
            "name",
            t("routes.fields.product"),
            constants.STRING_TYPE
        ),
    ]

    return (
        <ArrayDataTable
            data={props.data ?? []}
            columns={columns}
        />
    )

}

export default RouteProducts
