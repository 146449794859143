import { createTabBarItem } from '../../../components/helpers/fieldHelper';
import SiteFeaturedProducts from './components/tabs/SiteFeaturedProducts';


export const getTabNavigationItems = (user, apiClient, t) => [
    createTabBarItem(
        t("sites.view.navbar.featured_products"),
        ({ entity, data }) => (
            <SiteFeaturedProducts
                data={data.getItems()}
                entity={entity}
            />
        ),
        (site) => apiClient.content.site.getFeaturedProducts(site.id).catch((err) => { }),
    ),
];