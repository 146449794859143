import availableRoutes from "../../../../../../routes/availableRoutes"
import { Delete } from "@mui/icons-material"

export const getTableActions = (apiClient, t, navigate, entityId) => {
    return [
        {
            id: "delete",
            title: t("common.delete"),
            scope: 'row',
            icon: Delete,
            reloadOnSuccess: true,
            showLoading: true,
            confirmDialog: {
                title: t("discount_codes.actions.delete_product.modal_title"),
                content: (discountCode) =>
                    t("discount_codes.actions.delete_product.modal_content", {
                        name: discountCode.name,
                    }),
            },
            onExecute: (product, progressHandler, onSuccess, onError) => {
                return apiClient.catalog.discountCode.deleteProduct(entityId, product.id)
                    .then((result) => {
                        navigate(availableRoutes.discount_codes_view.path.replace(":entityId", entityId))
                        onSuccess(
                            t("discount_codes.actions.delete_product.confirm")
                        )
                    })
                    .catch((error) => {
                        onError(
                            t("discount_codes.actions.delete_product.error")
                        )

                        throw error
                    })
            },
        }
    ]
}