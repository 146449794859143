import constants from "../../../components/entity/constants";
import { createAsyncSelectFormField, createBooleanFormField, createField } from '../../../components/helpers/fieldHelper';
import { getIntermediariesClientsFilter } from '../../../lib/collection-filters';
import { optionsFetcher, rolesFetcher } from '../../../lib/form-options-fetchers';
import userPermissions from '../../../lib/user-permissions';

export const getTableFilter = (apiClient, t, user, cache) => ([
    createField(
        "username",
        t("settings_system_users.fields.username"),
        constants.TEXT_TYPE
    ),
    createField(
        "app_user_all",
        t("settings_system_users.fields.user"),
        constants.TEXT_TYPE
    ),
    createBooleanFormField(
        t,
        "active",
        t("settings_system_users.fields.active"),
        null,
        true,
        user.isIntermediary() ? "" : "1"
    ),
    createBooleanFormField(
        t,
        "is_admin",
        t("settings_system_users.fields.internal"),
        () => !user.isIntermediary()
    ),
    createAsyncSelectFormField(
        "role_id",
        t("settings_system_users.fields.role"),
        () => rolesFetcher(cache, apiClient, t, null, true),
        false,
        () => user.hasPermission('administer-roles')
    ),
    getIntermediariesClientsFilter(apiClient, t, user, 'crm_intermediary_id'),
    createAsyncSelectFormField(
        "crm_intermediary_office_id",
        t("settings_system_users.fields.office"),
        () => optionsFetcher(cache.get("offices", []), t),
        false,
        () => user.hasPermission(userPermissions.PERM_CRM_INTERMEDIARY_OFFICE_VIEW)
    ),
])
