import { useTranslation } from "react-i18next"
import AsyncEntityForm from "../../../../../components/form/AsyncEntityForm"
import { createFormFieldset, createField } from '../../../../../components/helpers/fieldHelper'
import constants from '../../../../../components/entity/constants'
import { DialogContentText } from "@mui/material"

const BookingSetNotes = ({ selection, onAction, onCancel }) => {
    const { t } = useTranslation("vbms")

    const fetcher = () => Promise.resolve(selection)

    const formContent = [
        createFormFieldset(null, [
            createField(
                "notes",
                t("bookings.fields.order.customer.notes"),
                constants.TEXT_TYPE
            ),
        ])
    ]

    const onConfirm = (result) => {
        onAction({
            notes: result.notes.trim()
        })
    }

    return (
        <>
            <DialogContentText>{t("bookings.actions.set_notes.modal_content", { "locator": selection.locator})}</DialogContentText>
            <AsyncEntityForm
                fetcher={fetcher}
                onSave={onConfirm}
                formContent={formContent}
            />
        </>
    )
}

export default BookingSetNotes