import { useCallback, useEffect, useState } from "react"
import FormikEntityForm from "../../../form/FormikEntityForm"
import { useTranslation } from "react-i18next"
import { createDependentFormField, createFormFieldset, createSelectFormField } from "../../../helpers/fieldHelper"
import constants from "../../../entity/constants"
import _get from "lodash/get"
import _has from "lodash/has"
import { Box, Card, CardContent, Fab, Grid, IconButton, InputAdornment, Stack, TextField, Typography } from "@mui/material"
import CameraInput from "./CameraInput"
import { Search, SettingsOutlined } from "@mui/icons-material"

const ValidationConfigForm = ({ config, activitiesFetcher, onConfirm }) => {
    const { t, i18n } = useTranslation("vbms")
    const [activities, setActivities] = useState(null)
    const [selection, setSelection] = useState(config)

    useEffect(() => {
        if (activities !== null) {
            return
        }

        activitiesFetcher(selection.activity_config_id).then((collection) => {
            const activities = collection.getItems().filter((activity) => _has(activity, "access_control_venues"))

            if (activities.length === 1) {
                const newSelection = {
                    ...config,
                    activity_id: activities[0].id,
                }

                if (activities[0].access_control_venues.length === 1) {
                    newSelection.access_control_venue_id = activities[0].access_control_venues[0].id

                    if (activities[0].access_control_venues[0].access_control_points.length === 1) {
                        newSelection.access_control_point_id = activities[0].access_control_venues[0].access_control_points[0].id
                        newSelection.access_control_point_name = activities[0].access_control_venues[0].access_control_points[0].name
                    }
                }

                setSelection(newSelection)
            }

            setActivities(activities)
        })
    }, [activities, activitiesFetcher])

    const formContent = [
        createFormFieldset(t("validation_widget.config.title"), [
            createSelectFormField(
                "activity_id",
                t("validation_widget.config.fields.activity"),
                !activities ? [] : activities.map((activity) => {
                    const config = {
                        year: "numeric",
                        month: "2-digit",
                        day: "2-digit",
                    }

                    return {
                        label: activity.activity_configuration.name + " (" + Intl.DateTimeFormat(i18n.language, config).format(new Date(activity.date)) + ")",
                        value: activity.id,
                    }
                })
            ),
            createDependentFormField(
                "access_control_venue_id",
                t("validation_widget.config.fields.access_control_venue"),
                constants.SELECT_TYPE,
                (values) => {
                    const activityId = _get(values, "activity_id", null)

                    const activity = activities.find((activity) => activity.id === activityId)
                    const accessControlPoints = !activity ? [] : _get(activity, "access_control_venues", [])

                    if (accessControlPoints.length === 0) {
                        return []
                    }

                    return accessControlPoints.map((item) => ({ value: item.id, label: item.name }))
                }
            ),
            createDependentFormField(
                "access_control_point_id",
                t("validation_widget.config.fields.access_control_point"),
                constants.SELECT_TYPE,
                (values) => {
                    const activityId = _get(values, "activity_id", null)
                    const venueId = _get(values, "access_control_venue_id", null)

                    const activity = activities.find((activity) => activity.id === activityId)
                    if (!activity) {
                        return []
                    }

                    const venue = activity.access_control_venues.find((venue) => venue.id === venueId)
                    if (!venue) {
                        return []
                    }

                    const accessControlPoints = _get(venue, "access_control_points", [])

                    if (accessControlPoints.length === 0) {
                        return []
                    }

                    return accessControlPoints.map((item) => ({ value: item.id, label: item.name }))
                }
            )
        ]),
    ]

    const onSaveHandler = (values) => {
        const activityId = _get(values, "activity_id", null)
        const activity = activities.find((activity) => activity.id === activityId)
        if (!activity) {
            onConfirm(values)
        }

        const venueId = _get(values, "access_control_venue_id", null)
        const venue = activity.access_control_venues.find((venue) => venue.id === venueId)
        if (!venue) {
            onConfirm(values)
        }

        const accessControlPointId = _get(values, "access_control_point_id", null)
        const accessControlPoint = venue.access_control_points.find((accessControlPoint) => accessControlPoint.id === accessControlPointId)
        if (!accessControlPoint) {
            onConfirm(values)
        }

        onConfirm({
            ...values,
            access_control_point_name: accessControlPoint.name,
        })
    }

    if (!activities) {
        return null
    }

    return <FormikEntityForm
        entity={selection}
        onSave={onSaveHandler}
        formContent={formContent}
        variant="default"
    />
}

const ValidationTextInput = ({ defaultValue, onChange }) => {
    const [value, setValue] = useState("")

    useEffect(() => {
        setValue(defaultValue)
    }, [defaultValue, setValue])

    return (
        <Box>
            <TextField
                id="locator"
                fullWidth
                value={value}
                onChange={(e) => setValue(e.target.value)}
                onKeyDown={(ev) => {
                    if (ev.key === "Enter") {
                        onChange(value)
                        ev.preventDefault()
                    }
                }}
                InputProps={{
                    endAdornment: <InputAdornment position="start">
                        <IconButton
                            edge="end"
                            onClick={() => onChange(value)}
                        >
                            <Search />
                        </IconButton>
                    </InputAdornment>,
                }}
                variant="standard"
            />
        </Box>
    )
}

const ValidationInput = ({ onChange }) => {
    const [value, setValue] = useState("")

    useEffect(() => {
        if (!value) {
            return
        }

        onChange(value)
    }, [value, onChange])


    const onScanSuccess = useCallback((value) => {
        setValue(prevState => {
            if (prevState === value) {
                return prevState
            }

            return value
        })
    }, [setValue])

    return (
        <Stack direction="column" spacing={2}>
            <CameraInput onScanSuccess={onScanSuccess} />
            <ValidationTextInput defaultValue={value} onChange={onScanSuccess} />
        </Stack>
    )
}

const ValidationResultBox = ({ locator, title, message }) => {

    return (
        <Box bgcolor="white" sx={{ margin: 2, borderRadius: 2 }}>
            <Card variant="outlined">
                <CardContent sx={{ textAlign: "center" }}>
                    <Typography variant="h1" component="div">{title}</Typography>
                    {locator && <Typography variant="h4" component="div" sx={{ mt: 2 }} color="text.secondary">{locator}</Typography>}
                    <Typography variant="h4" component="div" sx={{ mt: 2 }} color="text.secondary">{message}</Typography>
                </CardContent>
            </Card>
        </Box>
    )
}

const ValidationResult = ({ result }) => {
    const { t } = useTranslation("vbms")

    if (!result) {
        return null
    }

    const data = {
        title: result.success ? t("validation_widget.access_control.result.success.title") : t("validation_widget.access_control.result.error.title"),
        locator: _get(result.data, "locator", null),
        message: t(result.message_key, result.data),
    }

    return (
        <Box
            sx={{
                height: "100%",
                display: "flex",
                flexWrap: "wrap",
                alignContent: "center",
                alignItems: "center",
                justifyContent: "center",
                bgcolor: result ? (result.success ? "success.main" : "error.main") : "grey.200"
            }}
        >
            {result && (
                <ValidationResultBox {...data} />
            )}
        </Box>
    )
}

const ValidationCheck = ({ config, onValidate, onSettingsClick }) => {
    const [result, setResult] = useState(null)

    const onInputChange = useCallback((locator) => {
        onValidate(locator, config).then((result) => setResult(result))
    }, [onValidate, setResult])

    return (
        <Grid
            container
            direction="row"
            justifyContent="space-between"
            alignItems="stretch"
            sx={{ height: "100vh", spacing: { xs: 0, md: 2 } }}
        >
            <Grid
                item
                xs={12}
                md={6}
                sx={{ height: { xs: "50%", md: "100%" } }}
            >
                <Box sx={{ position: "relative", height: "100%" }}>
                    <ValidationInput onChange={onInputChange} />
                    <Stack
                        direction="row"
                        alignItems="center"
                        spacing={2}
                        sx={{
                            position: "absolute",
                            top: 8,
                            right: 8,
                            zIndex: 1,
                        }}
                    >
                        <Box>
                            <Typography
                                variant="body1"
                                component="div"
                                sx={{
                                    paddingInline: 1,
                                    color: "text.secondary",
                                    backgroundColor: "rgba(255, 255, 255, 0.6)",
                                }}
                            >
                                {config.access_control_point_name}
                            </Typography>
                        </Box>
                        <Fab
                            aria-label="settings"
                            size="small"
                            sx={{
                                bgcolor: "info.light"
                            }}
                            onClick={onSettingsClick}
                        >
                            <SettingsOutlined />
                        </Fab>
                    </Stack>
                </Box>
            </Grid>
            <Grid
                item
                xs={12}
                md={6}
                sx={{
                    height: {
                        xs: "50%",
                        md: "100%"
                    }
                }}
            ><ValidationResult result={result} /></Grid>
        </Grid >
    )
}

const ValidationWidget = ({ defaultConfig, activitiesFetcher, onValidate }) => {
    const [config, setConfig] = useState(localStorage.validation_widget ? JSON.parse(localStorage.getItem("validation_widget")) : defaultConfig)
    const [showConfig, setShowConfig] = useState(false)

    useEffect(() => {
        if (!config.activity_id) {
            setShowConfig(true)
        } else {
            setShowConfig(false)
        }

        localStorage.setItem("validation_widget", JSON.stringify(config))
    }, [config, setShowConfig])

    const onConfigurationUpdateHandler = (config) => {
        setConfig({
            ...defaultConfig,
            ...config
        })
    }

    return (
        <>
            {showConfig && (
                <ValidationConfigForm
                    config={config}
                    activitiesFetcher={activitiesFetcher}
                    onConfirm={onConfigurationUpdateHandler}
                />
            )}
            {!showConfig && config.activity_id !== null && (
                <ValidationCheck
                    config={config}
                    onValidate={onValidate}
                    onSettingsClick={() => setShowConfig(true)}
                />
            )}
        </>
    )
}

export default ValidationWidget