import { useVolcanoApiClient } from "../../../../../../context/VolcanoApiClientProvider"
import { createField } from "../../../../../../components/helpers/fieldHelper"
import { useTranslation } from "react-i18next"
import { getTableActions } from "./EnterpriseCurrencies.actions"
import { useNavigate } from "react-router-dom"
import CollectionDataTable from "../../../../../../components/Datatable/CollectionDataTable"
import constants from "../../../../../../components/entity/constants"
import { useVolcanoAuth } from "../../../../../../context/VolcanoAuthContext"

const EnterpriseCurrencies = ({entity}) => {
    const { t } = useTranslation("vbms")
    const { apiClient } = useVolcanoApiClient()
    const volcanoAuth = useVolcanoAuth()
    const navigate = useNavigate()

    const fetcher = () => apiClient.enterprise.getCurrencies(entity.id)

    const columns = [
        createField(
            "id",
            "Id",
            constants.STRING_TYPE
        ),
        createField(
            "name",
            t("enterprise.fields.currencies.name"),
            constants.STRING_TYPE
        ),
        createField(
            "code",
            t("enterprise.fields.currencies.code"),
            constants.STRING_TYPE
        ),
        createField(
            "main_currency",
            t("enterprise.fields.currencies.is_main"),
            constants.BOOLEAN_TYPE
        ),
        createField(
            "exchange_rate.mode",
            t("enterprise.fields.currencies.exchange_rate.mode"),
            constants.STRING_TYPE,
            {
                fixed: t("enterprise.fields.currencies.exchange_rate.mode_options.fixed"),
                dynamic: t("enterprise.fields.currencies.exchange_rate.mode_options.dynamic"),
            }
        ),
        createField(
            "exchange_rate.rate",
            t("enterprise.fields.currencies.exchange_rate.rate"),
            constants.NUMERIC_TYPE
        ),
        createField(
            "exchange_rate.correction_factor",
            t("enterprise.fields.currencies.exchange_rate.correction_factor"),
            constants.PERCENTAGE_TYPE
        ),
        createField(
            "exchange_rate.modified",
            t("enterprise.fields.currencies.exchange_rate.modified"),
            constants.DATETIME_TYPE
        ),
    ]

    if (!entity) {
        return null
    }

    return (
        <CollectionDataTable
            fetcher={fetcher}
            columns={columns}
            actions={getTableActions(apiClient, t, navigate, volcanoAuth.user, entity.id)}
        />
    )
}

export default EnterpriseCurrencies