import { Table, TableContainer } from '@mui/material'
import DataTableHead from './DataTableHead'
import GroupedDataTableBody from './GroupedDataTableBody'

const GroupedDataTableContainer = (props) => {
    const {
        groupBy,
        columns,
        table,
        actions,
        highlight,
    } = props

    return (
        <TableContainer>
            <Table size="small">
                <DataTableHead
                    selectionMode="none"
                    columns={groupBy.columns}
                    withActions={false}
                    rowCount={table.result.rows.length}
                    grouped={true}
                />
                <GroupedDataTableBody
                    groupBy={groupBy}
                    columns={columns}
                    rows={table.result.rows}
                    actions={actions}
                    highlight={highlight}
                />
            </Table>
        </TableContainer>
    )
}

export default GroupedDataTableContainer