import { useFormikContext } from "formik";
import { useEffect, useState } from 'react';
import { objectsEqual } from "../../lib/utils";

const FormObserver = ({ onChange }) => {
    const { values } = useFormikContext()

    const [data, setData] = useState({})

    useEffect(() => {
        if (!objectsEqual(data, values)) {
            setData(values)
        }
    }, [values, data]);


    useEffect(() => {
        onChange(data)
    }, [data, onChange]);

    return null
};

export default FormObserver