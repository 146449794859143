import { useTranslation } from "react-i18next"
import { useVolcanoApiClient } from "../../../context/VolcanoApiClientProvider"
import CollectionDataTable from "../../Datatable/CollectionDataTable"
import { createField, createLinkField } from "../../helpers/fieldHelper"
import constants from "../../entity/constants"

const UsersTable = ({ defaultParams }) => {
    const { apiClient } = useVolcanoApiClient()
    const { t } = useTranslation("vbms")

    const columns = [
        createLinkField(
            createField(
                "name",
                t("intermediaries.offices.fields.name"),
                constants.TEXT_TYPE
            ),
            {
                route: "intermediary_offices",
                field: "id",
            }
        ),
        createField(
            "contact.contact_details.email",
            t("common.fields.contact_details.email"),
            constants.EMAIL_TYPE
        ),
        createField(
            "active",
            t("settings_system_users.fields.active"),
            constants.BOOLEAN_TYPE
        ),
    ]

    return (
        <CollectionDataTable
            fetcher={(params) => {
                params = {
                    ...defaultParams
                    , ...params
                }
                return apiClient.intermediary.getIntermediaryOffices(null, params)
            }}
            columns={columns}
        />
    )
}

export default UsersTable