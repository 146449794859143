import { Typography } from "@mui/material"

const Label = ({ variant, children }) => {
  return (
    <Typography
      component="span"
      variant={variant || "body1"}
      sx={{ color: "common.label" }}
    >
      {children}
    </Typography>
  )
}

export default Label
