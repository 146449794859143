import {
    INITIALIZE,
    SET_DIRTY,
    SET_ROWS_PER_PAGE,
    SET_ORDER,
    SET_PAGE,
    SET_RESULT,
} from "./collection.action"

const dataTableReducer = (state, action) => {
    switch (action.type) {
        case INITIALIZE:
            return {
                ...state,
                rowsPerPage: action.rowsPerPage,
                page: action.page,
                orderBy: action.orderBy,
                order: action.order,
                filter: action.filter,
            }
        case SET_DIRTY:
            return {
                ...state,
                dirty: action.dirty,
            }
        case SET_ROWS_PER_PAGE:
            return {
                ...state,
                rowsPerPage: action.rowsPerPage,
                page: 0,
            }
        case SET_PAGE:
            return {
                ...state,
                page: action.page,
            }
        case SET_ORDER:
            return {
                ...state,
                orderBy: action.orderBy,
                order: action.order,
            }
        case SET_RESULT:
            return {
                ...state,
                result: action.result,
                dirty: false,
            }
        default:
            return state
    }
}

export default dataTableReducer