import { TreeView } from '@mui/x-tree-view';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import _isArray from 'lodash/isArray';
import { useState } from 'react';
import { buildTreeItems, getNodesToExpand } from '../../lib/treeview';

const TreeViewFieldValue = ({ value, expandMode }) => {
    const [expanded, setExpanded] = useState(getNodesToExpand(value, expandMode).map((value) => value.toString()))

    if (!_isArray(value) || value.length === 0) {
        return
    }

    const handleToggle = (e, nodeIds) => {
        setExpanded(nodeIds);
    };

    return (
        <TreeView
            aria-label="multi-select"
            defaultCollapseIcon={<ExpandMoreIcon />}
            defaultExpandIcon={<ChevronRightIcon />}
            expanded={expanded}
            onNodeToggle={handleToggle}
        >
            {buildTreeItems(value)}
        </TreeView>
    )
}

export default TreeViewFieldValue;