import { Field } from "formik"
import { TextField } from "formik-mui"
import { Stack } from '@mui/material';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

const PasswordFormField = ({ field, value }) => {
    const { t } = useTranslation()
    const [data, setData] = useState({ pwd: '', pwd_repeat: '' })

    const handleInputKeyUp = (event) => {
        if (event.target.name === field.id) {
            setData({ ...data, pwd: event.target.value })
        } else {
            setData({ ...data, pwd_repeat: event.target.value })
        }
    }

    const validatePassword = () => {
        const passwordRegex = new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])")
        let error = ""

        if (field.useStrength) {
            if (data.pwd.length < 8) {
                error = t("common.passwordStrengthSizeError")
            } else if (!passwordRegex.test(data.pwd)) {
                error = t("common.passwordStrengthFormatError")
            }
        }

        return error;
    };

    const validateConfirmPassword = () => {
        let error = ""

        if (data.pwd && data.pwd_repeat) {
            if (data.pwd !== data.pwd_repeat) {
                error = t("common.passwordRepeatError")
            }
        }

        return error;
    };

    return (
        <Stack spacing={2}>
            <Field
                component={TextField}
                name={field.id}
                label={field.label}
                required={field.required}
                InputProps={{ size: "small" }}
                InputLabelProps={{
                    shrink: true,
                }}
                type="password"
                onKeyUp={handleInputKeyUp}
                validate={validatePassword}
            />
            <Field
                component={TextField}
                name={field.repeatId}
                label={field.repeatLabel}
                required={field.required}
                InputProps={{ size: "small" }}
                InputLabelProps={{
                    shrink: true,
                }}
                type="password"
                onKeyUp={handleInputKeyUp}
                validate={validateConfirmPassword}
            />
        </Stack>
    )
}

export default PasswordFormField
