export const INITIALISE = 'INITIALISE'
export const RENEW_TOKEN = 'RENEW_TOKEN'
export const LOGOUT = 'LOGOUT'
export const RESET_PASSWORD_REQUEST = 'RESET_PASSWORD_REQUEST'
export const RESET_PASSWORD = 'RESET_PASSWORD'
export const CLEAR_REDIRECT = 'CLEAR_REDIRECT'
export const ERROR = 'ERROR'

const isEmpty = (value) => {
    return (
        value === undefined ||
        value === null ||
        (typeof value === 'object' && Object.keys(value).length === 0) ||
        (typeof value === 'string' && value.trim().length === 0)
    )
}

export const reducer = (state, action) => {
    switch (action.type) {
        case INITIALISE:
        case RENEW_TOKEN:
            return {
                ...state,
                isAuthenticated: !isEmpty(action.user),
                isLoading: false,
                error: null,
                user: action.user,
                redirect: action.redirect,
            }
        case RESET_PASSWORD_REQUEST:
        case RESET_PASSWORD:
            return {
                ...state,
                isLoading: false,
                error: null,
            }
        case LOGOUT:
            return {
                ...state,
                isLoading: false,
                error: null,
                isAuthenticated: false,
                user: null,
            };
        case CLEAR_REDIRECT:
            return {
                ...state,
                redirect: null
            }
        case ERROR:
            return {
                ...state,
                isLoading: false,
                error: action.error,
            }
        // todo: token refresh    
        default:
            return state
    }
}