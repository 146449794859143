import CollectionPage from "../../../layout/templates/CollectionPage/CollectionPage"
import { useTranslation } from "react-i18next"
import { useNavigate } from "react-router-dom"
import { useVolcanoApiClient } from "../../../context/VolcanoApiClientProvider"
import { useVolcanoAuth } from '../../../context/VolcanoAuthContext'
import { has } from "lodash"
import { getTableFilter } from './IntermediariesCollection.filter'
import { getActions } from "./IntermediariesCollection.actions"
import { getTableColumns } from './IntermediariesCollection.columns'
import { getTableExport } from "./IntermediariesCollection.export"
import { useCache } from '../../../context/cache.context'
import constants from "../../../components/entity/constants"
import _isDate from "lodash/isDate"
import _get from "lodash/get"
import _set from "lodash/set"
import { format as formatDate } from "date-fns"


const IntermediariesCollection = () => {
    const { apiClient } = useVolcanoApiClient()
    const { t } = useTranslation("vbms")
    const navigate = useNavigate()
    const volcanoAuth = useVolcanoAuth()
    const cache = useCache()

    const filterConfig = getTableFilter(apiClient, t, volcanoAuth.user, cache)

    const table = {
        columns: getTableColumns(t, has(volcanoAuth.user, "crm_intermediary_id"), volcanoAuth),
        fetcher: (params) => {
            filterConfig
                .filter(field => field.type === constants.DATE_TYPE)
                .forEach(field => {
                    const date = _get(params, field.id)
                    if (_isDate(date)) {
                        _set(params, field.id, formatDate(date, "yyyy-MM-dd"))
                    }
                })
            return apiClient.intermediary.getIntermediaries(params)
        }
    }

    return (
        <CollectionPage
            title={t("intermediaries.title")}
            subtitle={t("intermediaries.subtitle")}
            actions={getActions(t, navigate, volcanoAuth.user)}
            table={table}
            filterConfig={filterConfig}
            onExport={getTableExport(apiClient, t, volcanoAuth.user, filterConfig)}
        />
    )
}

export default IntermediariesCollection