import CreditCardIcon from '@mui/icons-material/CreditCard';
import SendToMobileIcon from '@mui/icons-material/SendToMobile';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import ToggleButtonGroupField from '../../form/ToggleButtonGroupField';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { createPaymentMethodMapping } from '../../helpers/fieldHelper';

const getPaymentGatewayIcon = (paymentMethod) => {
    switch (paymentMethod) {
        case "credit":
            return <AccountBalanceIcon />
        case "redsys":
        case "oneclick":
            return <CreditCardIcon />
        case "paygold":
            return <SendToMobileIcon />
        default:
            return null
    }
}

const getOptions = (paymentGateways, t) => {
    return paymentGateways.map((paymentGateway) => {
        return {
            value: paymentGateway.id,
            label: createPaymentMethodMapping(t)[paymentGateway.payment_method],
            icon: getPaymentGatewayIcon(paymentGateway.payment_method)
        }
    })
}

const PaymentGatewaysSelector = (props) => {
    const { paymentGateways, selection, onChange } = props
    const [value, setValue] = useState(selection ? selection.id : null)
    const { t } = useTranslation("vbms")

    const handleOnChange = (value) => {
        setValue(value)
        onChange(paymentGateways.find((item) => { return item.id === value }))
    }

    const field = {
        id: "payment_gateway",
        options: getOptions(paymentGateways, t)
    }

    return (
        <ToggleButtonGroupField field={field} value={value} onChange={handleOnChange} />
    )
}

export default PaymentGatewaysSelector