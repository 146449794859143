import { useTranslation } from 'react-i18next';
import constants from '../../../../../components/entity/constants';
import { createField, createInvoiceStateMapping } from '../../../../../components/helpers/fieldHelper';
import ArrayDataTable from '../../../../../components/Datatable/ArrayDataTable';

const InvoiceHistory = (props) => {
    const { t } = useTranslation("vbms")

    const columns = [
        createField(
            "created",
            t("common.fields.comments.created"),
            constants.DATETIME_TYPE
        ),
        /*createField(
            "user.name",
            t("common.fields.comments.username"),
            constants.STRING_TYPE
        ),*/
        createField(
            "state",
            t("invoices.fields.state"),
            constants.STRING_TYPE,
            createInvoiceStateMapping(t, "issuer")
        ),
        createField(
            "comment",
            t("common.fields.comments.description"),
            constants.STRING_TYPE
        )
    ]

    return (
        <ArrayDataTable
            data={props.data}
            columns={columns}
        />
    )
}

export default InvoiceHistory