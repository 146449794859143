import { Box } from "@mui/material"
import { Html5Qrcode, Html5QrcodeScanType, Html5QrcodeScanner, Html5QrcodeSupportedFormats } from "html5-qrcode"
import { useEffect } from "react"

const QR_SCANNER_HTML_ID = "html5qr-code-wrapper"

const QR_SCANNER_DEFAULT_CONFIG = {
    fps: 10,
    //qrbox: { width: 250, height: 250 },
    supportedScanTypes: [Html5QrcodeScanType.SCAN_TYPE_CAMERA],
    formatsToSupport: [Html5QrcodeSupportedFormats.QR_CODE]
}

const createConfig = () => {
    return QR_SCANNER_DEFAULT_CONFIG
}

const CameraInput = ({ onScanSuccess }) => {

    useEffect(() => {
        Html5Qrcode.getCameras().then(devices => {
            if (devices && devices.length) {
                const cameraId = devices[0].id

                const config = createConfig()
                const qrScanner = new Html5Qrcode(QR_SCANNER_HTML_ID, false)

                qrScanner.start(
                    cameraId,
                    config,
                    onScanSuccess
                ).catch(error => {
                    console.error("Failed to start Html5QrCode.", error)
                })

                return () => {
                    qrScanner.clear().catch(error => {
                        console.error("Failed to clear Html5QrCode.", error)
                    })
                }
            }
        }).catch(error => {
            console.error("Failed to initialize Html5QrCode.", error)
        })

    }, [onScanSuccess])

    return <Box
        id={QR_SCANNER_HTML_ID}
        sx={{ minHeight: "40px", minWidth: "100%" }}
    />
}

export default CameraInput