import { Box, CircularProgress } from "@mui/material"
import { useAsyncTabNavigation } from "./context/async-tabnavigation.context"

const Loading = () => (
    <Box sx={{ display: 'flex', justifyContent: 'center' }}>
        <CircularProgress />
    </Box>
)

const AsyncTabContent = () => {
    const { activeTab, tab, tabData, tabAsyncStatus } = useAsyncTabNavigation()

    if (!tab.isAsync) {
        return <tab.content {...tab.props} />
    }

    return (
        <>
            {tabAsyncStatus === "pending" && <Loading />}
            {tabAsyncStatus === "error" && "Error"}
            {(tabAsyncStatus === "success" && tabData[activeTab]) && <tab.content data={tabData[activeTab]} {...tab.props} />}
        </>
    )
}

export default AsyncTabContent