import BaseActionsGroup from "./BaseActionsGroup";

const SelectionActionsGroup = ({ selection, actions, variant, actionsNumberMobile, actionsNumberDesktop }) => {
    return (
        <BaseActionsGroup
            selection={selection}
            actions={actions}
            variant={variant}
            actionsNumberMobile={actionsNumberMobile}
            actionsNumberDesktop={actionsNumberDesktop}
        />
    )
}

export default SelectionActionsGroup