import { useCallback, useEffect, useState } from "react";
import { useVolcanoApiClient } from "../../../../context/VolcanoApiClientProvider";
import _isEmpty from "lodash/isEmpty";
import DataWrapper from "../../../../components/Display/DataWrapper";
import {Button, CircularProgress, FormControl, Grid, InputLabel, MenuItem, Select, TextField} from "@mui/material";
import LoadingButton from "../../../../components/form/LoadingButton";
import { Box } from "@mui/system";
import ClientSelectorDialog from './ClientSelectorDialog';

export const SearchForm = (props) => {
  const {
    user,
    countriesFetcher,
    billingClientsFetcher,
    collaboratorsFetcher,
    t,
    onSearchClick,
    onBillingClientsLoaded,
    onCollaboratorLoaded,
    onClientLoaded,
    onCreateRequest
  } = props;

  const [countries, setCountries] = useState([]);
  const [data, setData] = useState({ vat_number: "", country_id: "ES", enterprise_id: user.corporate_account.enterprise.id });
  const [loading, setLoading] = useState(false);
  const [noData, setNoData] = useState(false);
  const { apiClient } = useVolcanoApiClient();

  const handleInputChange = (event) => {
    setData({
      ...data,
      [event.target.name]: event.target.value
    });
  };

  const handleOnSearchButtonClick = useCallback(() => {
    if (_isEmpty(data.vat_number) || _isEmpty(data.country_id)) {
      return;
    }

    onSearchClick();
    setLoading(true);
    setNoData(false);
    billingClientsFetcher(data.vat_number, data.enterprise_id, data.country_id).then((result) => {
      const billingClients = result.getItems();

      if (_isEmpty(billingClients)) {
        collaboratorsFetcher(data.vat_number, data.enterprise_id, data.country_id).then((result) => {
          const collaborators = result.getItems();

          if (_isEmpty(collaborators)) {
            setNoData(true);
            setLoading(false);
            return;
          }

          onCollaboratorLoaded(collaborators[0]);
          setLoading(false);
        });
        return;
      }

      onBillingClientsLoaded(billingClients);
      setLoading(false);
    });
  }, [billingClientsFetcher, collaboratorsFetcher, onSearchClick, onBillingClientsLoaded, onCollaboratorLoaded, data.vat_number, data.country_id, data.enterprise_id]);

  useEffect(() => {
    countriesFetcher().then((items) => setCountries(items));
  }, [countriesFetcher]);

  return (
    <DataWrapper title={t("billing_client_create_invoice_widget.steps.initial.title")}>
      <Grid container spacing={2} sx={{ marginTop: 1 }}>
        <Grid item>
          <TextField
            id="vat_number"
            name="vat_number"
            label={t("billing_clients.fields.vat_number")}
            fullWidth
            value={data.vat_number}
            onChange={handleInputChange}
            size="small"
            InputLabelProps={{ shrink: true }}
            autoFocus
          />
        </Grid>
        <Grid item>
          {countries.length > 0 ? (
            <FormControl fullWidth>
              <InputLabel variant="outlined" htmlFor="country_id" shrink>{t("common.fields.contact_details.country")}</InputLabel>
              <Select
                id="country_id"
                name="country_id"
                label={t("common.fields.contact_details.country")}
                value={data.country_id}
                onChange={handleInputChange}
                displayEmpty
                size="small"
                notched={true}
              >
                {countries.map((option) => (
                  <MenuItem key={option.value} value={option.value}>{option.label}</MenuItem>
                ))}
              </Select>
            </FormControl>
          ) : (
            <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
              <CircularProgress size={24}/>
            </div>
          )}
        </Grid>
        <Grid item>
          <ClientSelectorDialog apiClient={apiClient} t={t} onClientLoaded={onClientLoaded}/>
        </Grid>
        <Grid item>
          <LoadingButton
            loading={loading}
            variant="contained"
            color="primary"
            onClick={handleOnSearchButtonClick}
          >
            {t("common.find")}
          </LoadingButton>
        </Grid>
      </Grid>
      {noData && (
        <Grid container spacing={2} sx={{ marginTop: 1, textAlign: "center" }}>
          <Grid item>
            <Box sx={{ color: "info.main", paddingTop: 1 }}>{t("table.no_data")}</Box>
          </Grid>
          <Grid item>
            <Button
              variant="contained"
              color="primary"
              onClick={() => onCreateRequest(data)}
            >
              {t("common.create")}
            </Button>
          </Grid>
        </Grid>
      )}
    </DataWrapper>
  );
};
