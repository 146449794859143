import { useVolcanoTheme } from '../../context/VolcanoThemeProvider';
import PublicLayout from './PublicLayout';

const PublicLayoutWithLogo = () => {
    const volcanoTheme = useVolcanoTheme()

    return (
        <PublicLayout image={volcanoTheme.logo} />
    )
}

export default PublicLayoutWithLogo