import constants from '../../../../components/entity/constants'
import { createCallbackField, createDataSetFacet, createFacetField, createField, createObjectStatusField } from '../../../../components/helpers/fieldHelper'

export const getHeaderContent = (t) => [
    createDataSetFacet(t("intermediaries.offices.view.facet_information"), [
        createField("id", "Id", constants.STRING_TYPE),
        createField(
            "name",
            t("intermediaries.offices.fields.name"),
            constants.STRING_TYPE
        ),
        createField(
            "created",
            t("common.fields.created"),
            constants.DATETIME_TYPE
        ),
        createField(
            "intermediary.name",
            t("intermediaries.offices.fields.intermediary"),
            constants.STRING_TYPE
        ),
    ]),
    createDataSetFacet(
        t("intermediaries.offices.view.facet_contact"),
        [
            createField("contact.contact_details", null, constants.ADDRESS_TYPE),
        ],
    ),
    createFacetField("object_status", {
        size: "large",
        field: createObjectStatusField(
            createCallbackField(
                "state",
                t("intermediaries.offices.fields.active"),
                constants.BOOLEAN_TYPE,
                (value) => value === "active"
            ),
            null,
            (value) => value ? "positive" : "negative"
        ),
    }),
]