import { Box, Grid, Typography } from "@mui/material"
import { processFormAvailableFields } from "./utils"

const FormFieldset = ({ title, entity, fields }) => {

    const availableFields = processFormAvailableFields(entity, fields)

    return (
        <Box className="FormFieldset-root" >
            <Typography variant="h3" marginBottom={2}>{title}</Typography>
            <Grid container spacing={2}>
                {availableFields.map((field, index) => {
                    return <Grid key={index} item xs={12}>{field.value}</Grid>
                })}
            </Grid>
        </Box>
    )
}

export default FormFieldset