import { Button, IconButton } from "@mui/material"

const Action = ({
    action,
    onAction,
    color = "primary",
    variant = "contained",
    disabled = false
}) => {

    const handleAction = () => {
        onAction(action)
    }

    if (action.icon) {
        return (
            <IconButton
                aria-label={action.title}
                title={action.title}
                size="small"
                color={color}
                variant={variant}
                onClick={handleAction}>
                <action.icon />
            </IconButton>
        )
    }

    return (
        <Button
            size="small"
            color={color}
            variant={variant}
            onClick={handleAction}
            disabled={disabled}
        >
            {action.title}
        </Button>
    )
}

export default Action