import { useTranslation } from "react-i18next"
import BillingClientForm from "../BillingClientForm";
import DataWrapper from '../../../../components/Display/DataWrapper';
import { Button, Stack } from '@mui/material';
import {useCallback, useEffect, useState} from "react";
import _isEmpty from "lodash/isEmpty"
import _get from "lodash/get"
import { Box } from '@mui/system';

const ClientForm = (props) => {

    const { selection, enterprisesFetcher, onConfirm, onError } = props

    const { t } = useTranslation()

    const [billingClient, setBillingClient] = useState(selection)

    const onSaveHandler = useCallback(() => {
        const emptyAccount = _isEmpty(billingClient.accountancy_config.account_id)
        const data = {
            ...billingClient,
            id: selection.id,
            is_collaborator: _get(selection, "is_collaborator", false),
            collaborator_id: _get(selection, "collaborator_id", null)
        }

        if (emptyAccount) {
            delete data.accountancy_config
        } else {
            const reg = /^(?:\d{11})$/

            if (!reg.exec(billingClient.accountancy_config.account_id)) {
                onError(t("billing_clients.form.account_id_error"))
                return
            }
        }

        onConfirm({ billingClient: data })
    }, [selection, billingClient, t, onConfirm, onError])

    useEffect(() => {
        if (selection?.is_collaborator) {
            onSaveHandler();
        }
    }, [selection, onSaveHandler]);

    return (
        <Stack spacing={2}>
            <DataWrapper title={selection?.is_collaborator ?
                t("billing_client_create_invoice_widget.steps.client_edit.collaborator_title") :
                t("billing_client_create_invoice_widget.steps.client_edit.title")}>
                <BillingClientForm
                    entity={billingClient}
                    enterprisesFetcher={enterprisesFetcher}
                    onChange={(values) => setBillingClient(values)}
                />
            </DataWrapper>
            <Box display="flex" justifyContent="center">
                <Button
                    variant="contained"
                    color="primary"
                    onClick={onSaveHandler}
                >
                    {t("common.continue")}
                </Button>
            </Box>
        </Stack>
    )
}

export default ClientForm
