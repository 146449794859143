import React from 'react'
import { useTranslation } from 'react-i18next';
import { Fade, Typography, CardContent, CardActions, Button, Box, Stack } from '@mui/material'
import CheckIcon from '@mui/icons-material/Check';

const styles = {
    box: {
        paddingTop: '0.75em',
        paddingBottom: 0,
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        textAlign: 'center',
        gap: '1em'
    },
    content: {
        paddingBottom: 0,
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        gap: '0.5em'
    },
    icon: {
        color: 'success.main',
        fontSize: '5em'
    },
    typography: {
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        fontWeight: 'Medium',
        textTransform: 'none',
        fontSize: '18px'
    }
}
const SuggestionMessage = ({ setLoginScreen, setSuggestionInitial }) => {

    const { t } = useTranslation("vbms")

    return (
        <Fade in={true}>
            <Box sx={styles.box}>
                <CardContent>
                    <Stack direction="row" justifyContent="center" alignItems="center" gap={1} mt={1}>
                        <CheckIcon />
                        <Typography variant="subtitle1" component="h2" align="center">
                            {t("suggestions.actions.send.confirm")}
                        </Typography>
                    </Stack>
                </CardContent>
                <CardActions sx={{ justifyContent: "space-between", width: "100%" }}>
                    <Button
                        variant="text"
                        size="small"
                        onClick={setLoginScreen}
                    >

                        {t("common.back")}
                    </Button>
                    <Button
                        variant="contained"
                        size="small"
                        onClick={setSuggestionInitial}
                    >
                        {t("suggestions.actions.send.button_variant")}
                    </Button>
                </CardActions>
            </Box>
        </Fade >
    )
}

export default SuggestionMessage