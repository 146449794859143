import { useTranslation } from "react-i18next"
import { useVolcanoApiClient } from "../../../context/VolcanoApiClientProvider"
import CollectionPage from "../../../layout/templates/CollectionPage/CollectionPage"
import { useVolcanoAuth } from '../../../context/VolcanoAuthContext'
import { getTableFilter } from './NotificationsCollection.filter'
import { getTableColumns } from './NotificationsCollection.columns'
import { useCache } from "../../../context/cache.context"

const NotificationsCollectionPage = () => {
    const { apiClient, siteConfig } = useVolcanoApiClient()
    const { t } = useTranslation("vbms")
    const volcanoAuth = useVolcanoAuth()
    const cache = useCache()

    const filterConfig = getTableFilter(apiClient, siteConfig, t, volcanoAuth.user, cache)

    const table = {
        columns: getTableColumns(t, volcanoAuth.user),
        fetcher: (params) => {

            const queryFilter = {
                ...params
            }

            return apiClient.notifications.getNotifications(queryFilter)
        }
    };

    return (
        <CollectionPage
            title={t("notifications.title")}
            subtitle={t("notifications.subtitle")}
            table={table}
            filterConfig={filterConfig}
        />
    )
}

export default NotificationsCollectionPage;
