import constants from "../../../../components/entity/constants"
import {
    createCallbackField,
    createField,
    createLinkField
} from "../../../../components/helpers/fieldHelper"

export const getTableColumns = (t) => ([
    createLinkField(
        createField(
            "name",
            t("lodgings.fields.name"),
            constants.STRING_TYPE
        ),
        {
            route: "lodgings",
            field: "id",
        }
    ),
    createField(
        "zone.name",
        t("lodgings.fields.zone"),
        constants.STRING_TYPE
    ),
    createCallbackField(
        "area_components",
        t("lodgings.fields.locality"),
        constants.STRING_TYPE,
        (value) => {
            const areaComponent = value.find(component => component.cod === "LO");
            if (areaComponent) {
                return areaComponent.name;
            }

            return "";
        }
    ),
    createField(
        "created",
        t("lodgings.fields.created"),
        constants.DATETIME_TYPE
    ),
    createField(
        "modified",
        t("lodgings.fields.modified"),
        constants.DATETIME_TYPE
    ),
])
