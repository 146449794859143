import { useCallback, useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { Stack, Box, Button, Grid } from '@mui/material';
import _get from "lodash/get"
import _isEmpty from "lodash/isEmpty"
import _isFunction from "lodash/isFunction"
import _isArray from "lodash/isArray"
import "/node_modules/flag-icons/css/flag-icons.min.css"
import ItemSelector from "../../ItemsSelector/ItemsSelector"
import DataWrapper from "../../Display/DataWrapper"
import { orderItemsByKey } from "../../../lib/utils"
import { ValueSelectorInput } from '../../form/ValuseSelectorDialogFormField';
import constants from '../../entity/constants';
import { createField, createDialogFormField, createFormFieldOption, createSelectFormField, createCommonStateMapping, createFormField } from '../../helpers/fieldHelper';

const SimpleProductSelector = ({
    productsFetcher,
    featuredProductsFetcher,
    selected,
    onSelection,
}) => {
    const { t } = useTranslation("vbms")
    const [featuredProducts, setFeaturedProducts] = useState(null)
    const [selection, setSelection] = useState(selected)

    const configField = createDialogFormField(
        "product_id",
        t("booking_widget.steps.product.field_product"),
        false,
        {
            filterConfig: [
                createField(
                    "name",
                    t("products.fields.name"),
                    constants.STRING_TYPE
                ),
                createField(
                    "sku",
                    t("products.fields.sku"),
                    constants.STRING_TYPE
                ),
                createField(
                    "experience_name",
                    t("products.fields.experience"),
                    constants.STRING_TYPE
                ),
                createFormField(
                    createSelectFormField(
                        "state",
                        t("products.fields.state"),
                        [createFormFieldOption("all", t("table.all"))].concat(
                            Object.entries(createCommonStateMapping(t))
                                .map(([key, value]) => createFormFieldOption(key, value))
                        )
                    ),
                    false,
                    "all"
                )
            ],
            columns: [
                createField(
                    "name",
                    t("products.fields.name"),
                    constants.STRING_TYPE
                ),
                createField(
                    "sku",
                    t("products.fields.sku"),
                    constants.STRING_TYPE
                ),
                createField(
                    "experience.name",
                    t("products.fields.experience"),
                    constants.STRING_TYPE
                ),
                createField(
                    "state",
                    t("products.fields.state"),
                    constants.STRING_TYPE,
                    createCommonStateMapping(t)
                ),
            ],
            fetcher: (params) => productsFetcher({ ...params }),
        },
        (value, language) => {
            return {
                id: value.id,
                label: value.name,
                entity: value,
            }
        }
    )

    const handleProductSelected = useCallback((_, value) => {
        if (!value) {
            setSelection(null)
            return
        }

        setSelection(_isArray(value) ? value[0].entity : value.entity)
    }, [])

    useEffect(() => {
        if (featuredProductsFetcher && !featuredProducts) {
            featuredProductsFetcher().then((result) => {
                setFeaturedProducts(orderItemsByKey([...new Map(result.map(product => [product.id, product])).values()], "name"))
            })
        }
    }, [featuredProducts, featuredProductsFetcher])

    useEffect(() => {
        if (selection && onSelection && _isFunction(onSelection)) {
            onSelection({
                category: { id: null },
                experience: selection.experience,
                product: selection,
            })
        }
    }, [selection, onSelection])

    const field = {
        id: configField.id,
        name: configField.id,
        labelMaxLen: 20,
        value: selection ? [{
            id: selection.id,
            label: selection.name,
            entity: selection
        }] : null,
        required: true
    }

    return (
        <Stack spacing={2}>
            {!_isEmpty(featuredProducts) && (
                <ItemSelector
                    title={t("booking_widget.steps.product.card_feature_products_title")}
                    items={featuredProducts.map((product) => ({
                        title: product.experience.name,
                        subtitle: product.name,
                        value: product
                    }))}
                    selected={_get(selection, "product.id")}
                    onItemSelected={setSelection}
                />
            )}
            <DataWrapper title={t("booking_widget.steps.product.card_product_title")}>
                <Grid container spacing={2} m={0}>
                    <Grid item xs={12} sm={4} key={configField.id}>
                        <ValueSelectorInput
                            field={field}
                            label={configField.label}
                            multiple={false}
                            table={configField.table}
                            valueFormatter={configField.valueFormatter}
                            setFieldValue={handleProductSelected}
                        />
                    </Grid>
                </Grid>
            </DataWrapper>
        </Stack>
    )
}

export default SimpleProductSelector
