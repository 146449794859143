import { useTranslation } from 'react-i18next';
import constants from '../../entity/constants';
import { createField, createLinkField, createInvoiceStateMapping } from '../../helpers/fieldHelper';
import ArrayDataTable from '../../Datatable/ArrayDataTable';
import { Download } from '@mui/icons-material';
import _has from 'lodash/has';
import { useVolcanoAuth } from '../../../context/VolcanoAuthContext';

const InvoicesTable = (props) => {
    const { t } = useTranslation("vbms")
    const volcanoAuth = useVolcanoAuth()

    const columns = [
        createLinkField(
            createField(
                "title",
                t("invoices.fields.title"),
                constants.STRING_TYPE
            ),
            {
                route: "billing_invoices",
                field: "id",
            }
        ),
        createField(
            "created",
            t("invoices.fields.created"),
            constants.DATETIME_TYPE
        ), 
        createField(
            "state",
            t("invoices.fields.state"),
            constants.STRING_TYPE,
            createInvoiceStateMapping(t, _has(volcanoAuth.user, "crm_intermediary") ? "recipient" : "issuer")
        ),
        createField(
            "amount.total_amount",
            t("invoices.fields.total_amount"),
            constants.CURRENCY_TYPE
        ),
    ]

    const actions = [
        {
            id: "download_invoice",
            title: t("common.download"),
            scope: 'row',
            icon: Download,
            onExecute: (invoice, progressHandler, onSuccess, onError) => {
                props.onDownload(invoice)
            }
        }
    ]

    return (
        <ArrayDataTable
            data={props.data}
            columns={columns}
            actions={actions}
        />
    )
}

export default InvoicesTable