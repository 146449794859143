import { useTranslation } from "react-i18next"
import constants from "../../entity/constants"
import { createField } from "../../helpers/fieldHelper"
import ArrayDataTable from "../../Datatable/ArrayDataTable"
import _isEmpty from "lodash/isEmpty"

const CommentsTable = (props) => {
    const { t } = useTranslation("vbms")

    const processComments = () => {
        return props.data.filter((comment) => {
            return !_isEmpty(comment.comments)
        })
    }

    const columns = [
        createField(
            "created",
            t("common.fields.comments.created"),
            constants.DATETIME_TYPE
        ),
        createField(
            "user.name",
            t("common.fields.comments.username"),
            constants.STRING_TYPE
        ),
        createField(
            "comments",
            t("common.fields.comments.description"),
            constants.STRING_TYPE
        )
    ]

    return (
        <ArrayDataTable
            data={processComments()}
            columns={columns}
        />
    )
}

export default CommentsTable