import { useTranslation } from "react-i18next"
import constants from "../../../components/entity/constants"
import { useVolcanoApiClient } from "../../../context/VolcanoApiClientProvider"
import { useParams } from 'react-router-dom'
import AsyncEntityPage from "../../../layout/templates/AsyncContentPage/AsyncEntityPage"
import { createCallbackField } from "../../../components/helpers/fieldHelper"
import { useVolcanoAuth } from '../../../context/VolcanoAuthContext'
import { getActions } from "./RefundPage.actions"
import { getHeaderContent } from "./RefundPage.header"
import { getTabNavigationItems } from "./RefundPage.tabs"

const RefundPage = (props) => {
    const { t } = useTranslation()
    const { entityId } = useParams()
    const { apiClient } = useVolcanoApiClient()
    const volcanoAuth = useVolcanoAuth()

    const fetcher = () => apiClient.refund.getRefund(entityId, { view_mode: 'full' })

    return (
        <AsyncEntityPage
            fetcher={fetcher}
            title={createCallbackField(
                "booking.order",
                t("refunds.fields.transaction_id"),
                constants.STRING_TYPE,
                (value) => t("refunds.view.title", {
                    transaction_id: value.tpvid === null ? (value.pp_transaction_id === null ? "Error" : value.pp_transaction_id) : value.tpvid
                })
            )}
            headerContent={getHeaderContent(t, volcanoAuth.user)}
            actions={getActions(apiClient, t, volcanoAuth.user, { hideIcons: true })}
            tabNavigationItems={getTabNavigationItems(volcanoAuth.user, apiClient, t)}
        />
    )
}

export default RefundPage