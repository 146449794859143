import { useTranslation } from "react-i18next";
import constants from "../../../../../../components/entity/constants";
import {
    createField, createLinkField
} from "../../../../../../components/helpers/fieldHelper";
import ArrayDataTable from "../../../../../../components/Datatable/ArrayDataTable";

const RoutePickupConfigs = (props) => {

    const { t } = useTranslation("vbms")

    const columns = [
        createLinkField(
            createField(
                "name",
                t("routes.fields.pickup_config"),
                constants.STRING_TYPE
            ),
            {
                route: "pickup_configs",
                field: "id",
            }
        ),
    ]

    return (
        <ArrayDataTable
            data={props.data ?? []}
            columns={columns}
        />
    )

}

export default RoutePickupConfigs
