import { Card, CardActionArea, CardContent, Grid, Typography, Box, CardMedia, Stack } from '@mui/material';
import _isEmpty from 'lodash/isEmpty';
import DataWrapper from '../Display/DataWrapper';
import { useTranslation } from 'react-i18next';
import _get from 'lodash/get';
import _has from 'lodash/has';
import fallbackImg from '../../assets/images/logo/fallback1.png';
import { createLanguagesMapping } from '../helpers/fieldHelper';

const ItemSelectorMedia = ({ item }) => {
    const { t } = useTranslation("vbms")

    if (_isEmpty(item) || _isEmpty(item.value) || (_isEmpty(item.value.languages) && _isEmpty(item.image))) {
        return null
    }
    const withImage = _get(item, "image", null) !== null
    const withLanguages = _has(item.value, "languages")
    const languagesMapping = createLanguagesMapping(t, _get(item, "value.languages", []), true)

    return (
        <Box
            sx={{
                position: "relative",
                minHeight: "30px",
            }}
        >
            {withImage && (
                <CardMedia
                    component="img"
                    image={(item.image.includes("img/cache")) ? item.image : fallbackImg}
                    alt={item.title}
                />
            )}
            {withLanguages && (
                <Box
                    sx={{
                        position: "absolute",
                        bottom: 0,
                        width: "100%",
                        backgroundColor: "rgba(0, 0, 0, 0.4)",
                        padding: 1
                    }}

                >
                    <Stack
                        direction="row"
                        justifyContent="center"
                        alignItems="center"
                        spacing={1}
                    >
                        {item.value.languages.map((language) => {
                            const icon = language === "en" ? "gb" : language

                            return <span key={language} title={languagesMapping[language]} className={`fi fi-${icon}`}></span>
                        })}
                    </Stack>
                </Box>
            )}
        </Box>
    )
}

const ItemSelector = ({ title, items, selected, onItemSelected }) => {
    return (
        <DataWrapper
            title={title}
            subtitle={selected && !_isEmpty(items) && items.find((item) => item.value.id === selected).title}
            onDelete={() => onItemSelected(null)}
        >
            {!selected && (
                <Grid container spacing={2}>
                    {items && items.map((item) => {
                        return (
                            <Grid key={item.value.id} item xs={12} sm={6} lg={4}>
                                <Card variant="outlined">
                                    <CardActionArea onClick={() => onItemSelected(item.value)}>
                                        <ItemSelectorMedia item={item} />
                                        <CardContent sx={{
                                            minHeight: "50px",
                                            justifyContent: "center",
                                            alignItems: "center",
                                            display: "flex",
                                        }}>
                                            <Stack sx={{ textAlign: "center" }}>
                                                <Typography>{item.title}</Typography>
                                                {item.subtitle && <Typography>{item.subtitle}</Typography>}
                                            </Stack>
                                        </CardContent>
                                    </CardActionArea>
                                </Card>
                            </Grid>
                        );
                    })}
                </Grid>
            )}
        </DataWrapper>
    )
}

export default ItemSelector