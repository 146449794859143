import _has from 'lodash/has'
import availableRoutes from '../../../routes/availableRoutes';

export const getActions = (apiClient, entityId, t, navigate, user) => [
    {
        id: "edit",
        title: t("common.edit"),
        onExecute: (billingClient, progressHandler, onSuccess, onError) => {
            navigate('/billing-clients/' + billingClient.id + '/edit')
        },
        condition: (billingClient) => _has(billingClient.getActions(), "edit")
    },
    {
        id: "create_invoice",
        title: t("billing_clients.actions.create_invoice.button"),
        onExecute: (user, progressHandler, onSuccess, onError) => {
            navigate(availableRoutes.billing_clients_create_client_invoice.path.replace(":entityId", entityId))
        },
        condition: () => user.hasPermission("create-invoice")
    },
    {
        id: "delete",
        title: t("common.delete"),
        confirmDialog: {
            title: t("billing_clients.actions.delete.modal_title"),
            content: (selection) =>
                t("billing_clients.actions.delete.modal_content", {
                    vat_number: selection.vat_number,
                }),
        },
        showLoading: true,
        onExecute: (billingClient, progressHandler, onSuccess, onError) => {
            return apiClient.billingInformation.deleteBillingInformation(billingClient.id, true)
                .then((result) => {
                    onSuccess(
                        t("billing_clients.actions.delete.confirm", {
                            vat_number: billingClient.vat_number,
                        })
                    )

                    navigate('/billing-clients')
                })
                .catch((error) => {
                    onError(
                        t("billing_clients.actions.delete.error", {
                            vat_number: billingClient.vat_number,
                        })
                    )

                    throw error
                })
        },
        condition: (billingClient) => _has(billingClient.getActions(), "delete")
    },
]