import { createExportField } from "../../../components/helpers/fieldHelper";
import _get from "lodash/get"
import _isDate from "lodash/isDate"
import _set from "lodash/set"
import { format as formatDate } from "date-fns"
import constants from "../../../components/entity/constants"
import _has from 'lodash/has';

export const getTableExport = (apiClient, t, filterConfig) => {
    return (filter, progressHandler) => {

        const params = {
            ...filter,
            collaborator_id: _get(filter, "collaborator_id[0].id", null)
        }

        filterConfig
            .filter(field => field.defaultValue)
            .forEach(field => {
                if (_get(params, field.id, '') === '') {
                    _set(params, field.id, field.defaultValue)
                }
            })

        filterConfig
            .filter(field => field.type === constants.DATE_TYPE)
            .forEach(field => {
                const date = _get(params, field.id)
                if (_isDate(date)) {
                    _set(params, field.id, formatDate(date, "yyyy-MM-dd"))
                }
            })

        apiClient.invoice.exportInvoices(
            [
                createExportField(
                    "title",
                    t("invoices.fields.title")
                ),
                createExportField(
                    "",
                    t("invoices.fields.recipient.entity"),
                    null,
                    (invoice) => processCustomer(invoice)
                ),
                createExportField(
                    "created",
                    t("invoices.fields.created")
                ),
                createExportField(
                    "amount.total_tax",
                    t("invoices.fields.line_items.total_tax")
                ),
                createExportField(
                    "amount.total_amount",
                    t("invoices.fields.line_items.total_price")
                )
            ],
            params,
            progressHandler
        )
    }
} 

const processCustomer = (invoice) => {
    if (_has(invoice, "collaborator")) return invoice.collaborator.name
    if (_has(invoice, "billing_client")) return invoice.billing_client.name

    return ""
}