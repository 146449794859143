import { useTranslation } from "react-i18next"
import Constants from "./constants"
import { getCurrencyLocale } from '../../lib/utils';

const CurrencyFieldValue = ({ value }) => {
  const { i18n } = useTranslation("vbms")
  const locale = getCurrencyLocale(i18n.language)

  if (value === null) {
    return null
  }

  var result = null
  var mainCurrencyResult = null

  if (typeof value === "object") {
    result = Intl.NumberFormat(locale, {
      style: Constants.CURRENCY_TYPE,
      currency: value.currency,
    }).format(value.value)

    if (value.main_currency !== null && value.main_currency !== value.currency) {
      mainCurrencyResult = Intl.NumberFormat(locale, {
        style: Constants.CURRENCY_TYPE,
        currency: value.main_currency,
      }).format(value.value / value.exchange_rate)
    }

  } else {
    result = Intl.NumberFormat(locale, {
      style: Constants.CURRENCY_TYPE,
      currency: Constants.DEFAULT_CURRENCY,
    }).format(value)
  }

  if (mainCurrencyResult !== null) {
    return (
      <>
        {result} ({mainCurrencyResult})
      </>
    )
  }

  return <>{result}</>
}

export default CurrencyFieldValue
