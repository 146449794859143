import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import i18n from './config/i18n';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';

i18n.changeLanguage("es");

const root = ReactDOM.createRoot(document.getElementById('root'));
/*root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);*/

root.render(
  <App />
);
