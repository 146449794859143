import { useNavigate } from "react-router"
import { useVolcanoApiClient } from "../../../../context/VolcanoApiClientProvider"
import { useVolcanoAuth } from "../../../../context/VolcanoAuthContext"
import { useCache } from "../../../../context/cache.context"
import useSnackBars from "../../../Snackbar/snack-bar.context"
import OneClickBookingWidget from "./OneClickBookingWidget"
import { useCallback } from "react"
import { format as formatDate } from "date-fns"

// initialize ocb product configuration
const initOCBProduct = (product) => {
    const result = {
        product: {
            id: product.id,
            name: product.name,
            min_pax: product.qty_restrictions.min,
            max_pax: product.qty_restrictions.max,
            experience: {
                id: product.experience.id,
                name: product.experience.name,
            }
        },
        date: new Date(),
        session: null,
        step: 5,
    }

    return result
}

// process rate name
const getRateName = (rate) =>
    rate.customer_types
        .sort((ct1, ct2) => ct1.group_id === 5 ? -1 : 1)
        .reduce((names, ct) => {
            names.push(ct.name)
            return names
        }, [])
        .join(", ")

/**
* Process payment data and redirects to the required url.
*
* @param {object} paymentData
*/
const processPaymentRedirection = (paymentData) => {
    if (paymentData.type === "GET") {
        window.location.replace(paymentData.payment_url)
    } else if (paymentData.type === "POST") {
        const form = document.createElement("form")
        form.id = "paymentForm"
        form.name = "paymentForm"
        form.method = "post"
        form.action = paymentData.payment_url

        // create form fields
        Object.entries(paymentData.data).forEach(
            ([fieldName, fieldValue]) => {
                const input = document.createElement("input")
                input.type = "hidden"
                input.name = fieldName
                input.value = fieldValue
                form.appendChild(input)
            }
        )

        document.body.appendChild(form)

        form.submit()
    }
}

const VolcanoOneClickBookingWidget = () => {
    const { apiClient } = useVolcanoApiClient()
    const volcanoAuth = useVolcanoAuth()
    const cache = useCache()
    const navigate = useNavigate()

    const selection = initOCBProduct(volcanoAuth.user.settings.ocb_products[0])

    const availabilityFetcher = useCallback((productId, date, qty) => {
        return apiClient.catalog.product
            .getProductAvailability(productId, date, false, { qty: qty })
            .then((result) => {
                return result;
            });
    }, [apiClient]);

    const ratesFetcher = useCallback((productId, date) => {
        return cache.get(
            "ocb_rates",
            [],
            () => apiClient.catalog.product
                .getProductRatesForDate(productId, date)
                .then((result) => result.map((rate) => ({
                    ...rate,
                    name: getRateName(rate)
                })))
        )
    }, [apiClient])

    const paymentGatewaysFetcher = useCallback(() => {
        return apiClient.cart.getPaymentGateways()
    }, [apiClient])

    const bookingConfirmHandler = useCallback((selection) => {
        apiClient.cart.resetLocalCart()

        const bookingHour = selection.session.session === 'day_wide' ? '00:00:00' : selection.session.session

        const booking = {
            type: "booking_date",
            product_id: selection.product.id,
            booking_date: formatDate(selection.date, "yyyy-MM-dd") + " " + bookingHour,
            rates: selection.rates
                .map((rate) => {
                    return {
                        rate_id: rate.id,
                        qty: rate.qty,
                    }
                }),
        }

        return apiClient.cart
            .addBooking(booking)
            .then((cart) => {
                return cart
            })
    }, [apiClient])

    const cartConfirmHandler = (cart, values) => {
        return apiClient.cart
            .confirmCart({
                id: cart.id,
                ...values
            })
            .then((cart) => {
                switch (cart.state) {
                    case "completed":
                        // load bookings related with the cart
                        return apiClient.booking.getBookings({ order_id: cart.order_id }).then((result) => {
                            cart.bookings = result.getItems()
                            return cart
                        })
                    case "confirmed":
                        // process payment data and redirects to the required url
                        processPaymentRedirection(cart.payment_data)
                        break
                    default:
                        //todo: handle other states
                        break
                }

                return cart
            })
    }

    return (
        <OneClickBookingWidget
            initialSelection={selection}
            availabilityFetcher={availabilityFetcher}
            ratesFetcher={ratesFetcher}
            paymentGatewaysFetcher={paymentGatewaysFetcher}
            onBookingConfirm={bookingConfirmHandler}
            onCartConfirm={cartConfirmHandler}
        />
    )
}

export default VolcanoOneClickBookingWidget