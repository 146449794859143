import { Button, CircularProgress } from "@mui/material"

/**
 * Button with loading spinner
 */
const LoadingButton = (props) => {
    const { loading, children, ...rest } = props

    return (
        <Button {...rest} disabled={loading}>
            <>
                {children}
                {loading && <CircularProgress
                    size={16}
                    sx={{
                        color: "inherit",
                        marginLeft: 1,
                    }}
                />}
            </>
        </Button>
    )
}

export default LoadingButton