import { useTranslation } from "react-i18next"
import { useVolcanoApiClient } from "../../../../context/VolcanoApiClientProvider"
import CollectionPage from "../../../../layout/templates/CollectionPage/CollectionPage"
import { useVolcanoAuth } from "../../../../context/VolcanoAuthContext"
import { useNavigate } from "react-router-dom"
import { getTableFilter } from "../components/Activities.filter"
import { getActions, getTableActions} from "./ActivitiesCollection.actions"
import {getTableColumns} from "./ActivitiesCollection.columns"
import {getTableExport} from "./ActivitiesCollection.export"
import {useCache} from "../../../../context/cache.context"
import {format as formatDate} from "date-fns";


const ActivitiesCollectionPage = () => {

    const { apiClient } = useVolcanoApiClient()
    const { t } = useTranslation("vbms")
    const volcanoAuth = useVolcanoAuth()
    const navigate = useNavigate()
    const cache = useCache()
    const filterConfig = getTableFilter(t, apiClient, volcanoAuth.user, cache)

    const table = {
        columns: getTableColumns(t, volcanoAuth.user),
        actions: getTableActions(apiClient, t, volcanoAuth.user),
        fetcher: (params) => {
            const queryFilter = {
                ...params,
                date_from: params.date_from ? formatDate(params.date_from, "yyyy-MM-dd") : null,
                date_to: params.date_to ? formatDate(params.date_to, "yyyy-MM-dd") : null,
                sort: "date"
            }
            return apiClient.activityManager.activity.getActivities(queryFilter)
        },
        onExport: getTableExport(apiClient, t)
    };

    return (
        <CollectionPage
            title={t("activities.title")}
            subtitle={t("activities.subtitle")}
            actions={getActions(apiClient, t, navigate, volcanoAuth.user)}
            filterConfig={filterConfig}
            table={table}
        />
    )
}

export default ActivitiesCollectionPage;
