import { useTranslation } from "react-i18next"
import _isNumber from "lodash/isNumber"
import _isInteger from "lodash/isInteger"
import { getCurrencyLocale } from '../../lib/utils';

const NumericFieldValue = ({ value, isPercent }) => {
  const { i18n } = useTranslation("vbms")

  let result = ""
  if (_isNumber(value)) {
    let config = {}
    if (isPercent) {
      config.style = "percent"
      config.maximumFractionDigits = 2
      config.minimumFractionDigits = 2
    } else if (_isInteger(value)) {
      config.maximumFractionDigits = 0
      config.minimumFractionDigits = 0
    } else {
      config.maximumFractionDigits = 2
      config.minimumFractionDigits = 0
    }
    result = Intl.NumberFormat(getCurrencyLocale(i18n.language), config).format(value)
  }

  return <>{result}</>
}

export default NumericFieldValue
