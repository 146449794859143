import React, { useRef, useState } from "react"
import { Container, Draggable } from "react-smooth-dnd"
import List from "@mui/material/List"
import ListItem from "@mui/material/ListItem"
import ListItemText from "@mui/material/ListItemText"
import ListItemAvatar from "@mui/material/ListItemAvatar"
import DragHandleIcon from "@mui/icons-material/DragHandle"
import DeleteIcon from "@mui/icons-material/Delete"
import { Box, IconButton, Dialog, DialogTitle, DialogContent, Grid, Stack, ListItemButton } from '@mui/material'
import { Field, FieldArray, useFormikContext } from 'formik'
import AddIcon from '@mui/icons-material/Add'
import _get from 'lodash/get'
import { initArrayFormValues, processFormAvailableFields } from "./utils"

const EditableListItem = ({ field, value, index, onDelete }) => {

    const fieldNamePrefix = `${field.id}.${index}`

    const availableFields = processFormAvailableFields(value, field?.fields, fieldNamePrefix)

    const listItemGridSize = availableFields.length === 0 || !value.label ? 12 : 6

    return (
        <ListItem
            disablePadding
            className={field.allowSort ? 'drag-handle' : ''}
            alignItems="flex-start"
            sx={{
                cursor: field.allowSort ? 'pointer' : 'default',
                '& .MuiListItemSecondaryAction-root': {
                    top: "20px"
                }
            }}
            secondaryAction={field.allowDelete && (
                <IconButton
                    aria-label="delete"
                    color="primary"
                    edge="end"
                    sx={{ padding: 0, align: "right" }}
                    onClick={onDelete}
                >
                    <DeleteIcon />
                </IconButton>
            )}
        >
            <Field name={`${fieldNamePrefix}.id`} type="hidden" />
            <ListItemButton
                alignItems="flex-start"
                disableRipple
            >
                {field.allowSort && <ListItemAvatar sx={{ marginTop: 0.5 }}>
                    <DragHandleIcon />
                </ListItemAvatar>}
                <Grid spacing={1} container>
                    {value.label && (
                        <Grid item xs={12} md={listItemGridSize}>
                            <ListItemText primary={value.label} />
                        </Grid>
                    )}
                    {availableFields.length > 0 && (
                        <Grid item xs={12} md={listItemGridSize}>
                            <Stack direction="row" display="flex" spacing={1}>
                                {availableFields.map((field, index) => {
                                    return <React.Fragment key={field.id || index}>{field.value}</React.Fragment>
                                })}
                            </Stack>
                        </Grid>
                    )}
                </Grid>
            </ListItemButton>
        </ListItem>
    )
}

const EditableListFormField = ({ field, value }) => {
    const { values } = useFormikContext();
    const configField = field
    const [openAddDialog, setOpenAddDialog] = useState(false)
    const dialogRef = useRef(null)

    const closeAddDialog = () => {
        if (dialogRef.current) {
            dialogRef.current.focus();
        }

        setOpenAddDialog(false)
    }

    const Content = configField.addContent || null

    const createEmptyItem = () => {
        const item = {};
        if (configField.fields && configField.fields.length > 0) {
            configField.fields.forEach((subField) => {
                item[subField.id] = '';
            });
        }
        return item;
    };

    return (
        <FieldArray name={configField.id}>
            {({ move, push, remove }) => {
                const addElement = () => {
                    const newItem = createEmptyItem();
                    push(newItem);
                };
                return (
                <>
                    {Content && <Dialog
                        open={openAddDialog}
                        fullWidth={true}
                        maxWidth="lg"
                        onClose={closeAddDialog}
                        ref={dialogRef}
                    >
                        <DialogTitle>{configField.addContentTitle}</DialogTitle>
                        <DialogContent>
                            <Content
                                {...configField.addContent.props}
                                onConfirm={(data, progressHandler, onSuccessHandler, onErrorHandler) => {
                                    closeAddDialog()
                                    // insert each selected item
                                    initArrayFormValues(data.map((item) => item.entity), configField.itemLabel, configField.fields).forEach((item) => push(item))
                                }}
                                onCancel={closeAddDialog}
                            />
                        </DialogContent>
                    </Dialog>}
                    <Box>
                        {Content && <Box display="flex" justifyContent="flex-end" mr={0.5}>
                            <IconButton
                                aria-label="add"
                                color="primary"
                                sx={{ padding: 0, align: "right" }}
                                onClick={() => setOpenAddDialog(true)}
                            >
                                <AddIcon />
                            </IconButton>
                        </Box>}
                        {!Content && <Box display="flex" justifyContent="flex-end" mr={0.5}>
                            <IconButton
                                aria-label="add"
                                color="primary"
                                sx={{ padding: 0, align: "right" }}
                                onClick={() => addElement()}
                            >
                                <AddIcon />
                            </IconButton>
                        </Box>}

                        <List>
                            <Container
                                dragHandleSelector=".drag-handle"
                                lockAxis="y"
                                onDrop={({ removedIndex, addedIndex }) => {
                                    move(removedIndex, addedIndex)
                                }}
                            >
                                {_get(values, configField.id, []).map((item, index) => (
                                    <Draggable key={index}>
                                        <EditableListItem
                                            field={configField}
                                            value={item}
                                            index={index}
                                            onDelete={() => remove(index)}
                                        />
                                    </Draggable>
                                ))}
                            </Container>
                        </List>
                        {
                            //meta.error && meta.touched && <FormHelperText error={true}>{meta.error}</FormHelperText>
                        }
                    </Box>
                </>
            )}
            }
        </FieldArray>

    );
};

export default EditableListFormField
