
import { useVolcanoApiClient } from "../../context/VolcanoApiClientProvider";
import {useTranslation} from 'react-i18next';
import ItemSelector from '../../components/ItemsSelector/ItemsSelector';
import BasePage from '../../layout/templates/BasePage';

const ManualContent = () => {

    const { t } = useTranslation("vbms");
    const { siteConfig } = useVolcanoApiClient()

    const onSelection = (value) =>  window.open('./assets/files/' + value.filename, '_blank')

    return(
        <ItemSelector
            title={t('app.manuals')}
            items={siteConfig.manuals.map((manual) => ({
                title: manual.name,
                subtitle: '',
                value: {
                    id:manual.name,
                    filename: manual.filename
                }
            }))}
            onItemSelected={onSelection}
        />
    )
}

const ManualContentPage = () => {

    const { t } = useTranslation("vbms");

    return (
        <BasePage title={t('app.manuals')}>
            <ManualContent/>
        </BasePage>
    )
}

export default ManualContentPage