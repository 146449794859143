import { useTranslation } from "react-i18next"
import { useVolcanoApiClient } from "../../context/VolcanoApiClientProvider"
import { useNavigate, useParams } from "react-router-dom"
import { useCache } from "../../context/cache.context";
import { billingClientFetcher, parseBillingClientToApi } from './BillingClients.functions';
import { enterprisesFetcher as extEnterprisesFetcher } from '../../lib/form-options-fetchers';
import BasePage from '../../layout/templates/BasePage';
import Loading from '../../components/Display/Loading';
import { useState, useEffect, useCallback } from 'react';
import BillingClientForm from './components/BillingClientForm';
import useSnackBars from '../../components/Snackbar/snack-bar.context';
import _isEmpty from "lodash/isEmpty"

const BillingClientFormPage = (props) => {
    const { t } = useTranslation()
    const { entityId } = useParams()
    const { apiClient } = useVolcanoApiClient()
    const navigate = useNavigate()
    const cache = useCache()
    const { addAlert } = useSnackBars()

    const [isReady, setIsReady] = useState(false)
    const [entity, setEntity] = useState({})

    const actions = [
        {
            id: "save",
            title: t("common.save"),
            onExecute: () => {
                onSaveHandler()
            },
        },
        {
            id: "cancel",
            title: t("common.cancel"),
            onExecute: () => {
                navigate(-1)
            },
        },
    ]

    const enterprisesFetcher = useCallback(() => {
        return extEnterprisesFetcher(cache, apiClient, t, false)
    }, [cache, apiClient, t])

    const onSaveHandler = () => {
        const reg = /^(?:\d{11})$/
        const billingClient = parseBillingClientToApi(entity)

        if (!_isEmpty(billingClient.accountancy_config) && !reg.exec(billingClient.accountancy_config.account_id)) {
            addAlert(t("billing_clients.form.account_id_error"), "error")
            return
        }

        if (!entityId) {
            apiClient.billingInformation.createBillingInformation(billingClient, 'application')
                .then((billingClient) => {
                    addAlert(t("billing_clients.actions.edit.confirm", { vat_number: billingClient.vat_number }), "success")
                    navigate('/billing-clients')
                })
                .catch((error) => {
                    addAlert(error.message, "error")
                })
        } else {
            apiClient.billingInformation.editBillingInformation(entityId, billingClient, 'application')
                .then((billingClient) => {
                    addAlert(t("billing_clients.actions.edit.confirm", { vat_number: billingClient.vat_number }), "success")
                    navigate('/billing-clients/' + entityId)
                })
                .catch((error) => {
                    addAlert(error.message, "error")
                })
        }
    }

    useEffect(() => {
        if (!entityId) {
            setIsReady(true)
            return
        }

        billingClientFetcher(apiClient, entityId).then((billingClient) => {
            setEntity(billingClient)
            setIsReady(true)
        })
    }, [apiClient, entityId])

    return (
        <>
            <Loading open={!isReady} />
            {isReady && <BasePage
                title={entityId ?
                    t("billing_clients.form.edit_title", { vat_number: entity.vat_number }) :
                    t("billing_clients.form.add_title")
                }
                actions={actions}
            >
                <BillingClientForm
                    entity={entity}
                    enterprisesFetcher={enterprisesFetcher}
                    onChange={(values) => setEntity(values)}
                />
            </BasePage>}
        </>
    )
}

export default BillingClientFormPage