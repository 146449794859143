import { has } from "lodash";
import BookingsTable from "../../../../../components/Volcano/Bookings/BookingsTable";

const UserSales = ({ entity }) => {

    if (!entity) {
        return null;
    }

    var defaultParams = {}
    if (has(entity, "salesman")) {
        defaultParams.salesman_id = entity.salesman.id
    } else {
        // todo: filtrar por reservas creadas por el usuario
    }

    return (
        <BookingsTable defaultParams={defaultParams} />
    )
}

export default UserSales