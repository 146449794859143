import { Field } from "formik"
import _capitalize from "lodash/capitalize"
import _isEmpty from 'lodash/isEmpty'
import _isFunction from 'lodash/isFunction'
import _get from 'lodash/get'
import _set from 'lodash/set'
import { useTranslation } from "react-i18next"
import { Box, Checkbox, FormControl, FormControlLabel, FormHelperText, Grid, InputLabel, MenuItem, Select, TextField } from "@mui/material"
import { useCallback, useEffect, useState } from "react"
import { useCache } from "../../context/cache.context"
import SimpleLoading from "../Display/SimpleLoading"
import { t } from "i18next"

const BASE_ADDRESS_VALUE = {
    address_type: { id: "" },
    address: "",
    postal_code: "",
    locality: "",
    state: "",
    country: { id: "" },
    phone: "",
    email: "",
    phone_alternative: "",
    fax: "",
}

const FORM_FIELDS = [
    [
        {
            id: "address_type.id",
            schema_key: "address_type",
            label: "common.fields.contact_details.address_type",
            type: "select",
            required: true,
        },
        {
            id: "address",
            label: "common.fields.contact_details.address",
            type: "text",
            required: true,
        },
    ],
    [
        {
            id: "locality",
            label: "common.fields.contact_details.locality",
            type: "text",
        },
        {
            id: "state",
            label: "common.fields.contact_details.state",
            type: "text",
            required: true,
        },
    ],
    [

        {
            id: "postal_code",
            label: "common.fields.contact_details.postal_code",
            type: "text",
            required: true,
        },
        {
            id: "country.id",
            schema_key: "country",
            label: "common.fields.contact_details.country",
            type: "select",
            required: true,
        },
    ],
    [
        {
            id: "phone",
            label: "common.fields.contact_details.phone",
            type: "text",
        },
        {
            id: "phone_alternative",
            label: "common.fields.contact_details.phone_alternative",
            type: "text",
        },
    ],
    [
        {
            id: "email",
            label: "common.fields.contact_details.email",
            type: "text",
        },
    ]
]

const FORM_OPTIONAL_FIELDS = [
    [
        {
            id: "fax",
            label: "common.fields.contact_details.fax",
            type: "text",
        },
    ],
]

const validate = (t, required, value) => {
    if (!required) {
        return null
    }

    if (!value) {
        return t("common.form.validation.required_address")
    }

    const notEmptyFields = ["address_type.id", "country.id", "address"]
    if (notEmptyFields.filter((field) => _isEmpty(_get(value, field))).length > 0) {
        return t("common.form.validation.required_address")
    }
    return null
}

const getOptions = (options) => {
    return Object.entries(options).map(([key, value]) => {
        return { value: key, label: _capitalize(value) }
    })
}

const getOptionalFields = ({ required, value, schema, updateValue, optionalFields }) => {
    if (optionalFields) {
        return (
            FORM_OPTIONAL_FIELDS.map((row, index) =>
                <FormRow
                    key={index}
                    fields={row.map((field) => ({ ...field, required: field.required && required }))}
                    value={value}
                    schema={schema}
                    mode={"full"}
                    onChange={updateValue}
                />
            )
        )
    }
}

const FormField = ({ field, value, schema, onChange }) => {
    const { t } = useTranslation("vbms")

    if (field.type === "select") {
        return (
            <FormControl required={field.required} fullWidth>
                <InputLabel id={field.id} htmlFor={field.id} shrink>{t(field.label)}</InputLabel>
                <Select
                    labelId={field.id}
                    id={field.id}
                    value={value}
                    label={t(field.label)}
                    onChange={(e) => onChange(e.target.value)}
                    displayEmpty
                    size="small"
                    notched={true}
                >
                    {getOptions(schema[field.schema_key].options).map((option, index) => {
                        return <MenuItem key={index} value={option.value}>{option.label}</MenuItem>
                    })}
                </Select>
            </FormControl>
        )
    } else if (field.type === "text") {
        return (
            <TextField
                id={field.id}
                label={t(field.label)}
                value={value}
                required={field.required}
                onChange={(e) => onChange(e.target.value)}
                variant="outlined"
                size="small"
                InputLabelProps={{
                    shrink: true
                }}
            />
        )
    }

    return null
}

const FormRow = ({ fields, value, schema, mode, onChange }) => {
    const fullWidth = fields.length === 1

    return (
        <Grid item container spacing={2}>
            {fields.map((field, index) => (
                <Grid item xs={12} md={fullWidth ? 12 : (mode === "compact" ? (index === 0 ? 4 : 8) : 6)} key={index}>
                    <FormField
                        field={field}
                        value={_get(value, field.id, "")}
                        schema={schema}
                        onChange={(value) => onChange(field.id, value)}
                    />
                </Grid>
            ))}
        </Grid>
    )
}

const AddressForm = ({ defaultValue, required, onChange, optionalFields }) => {
    const [value, setValue] = useState(defaultValue || BASE_ADDRESS_VALUE)
    const [schema, setSchema] = useState(false)
    const cache = useCache()

    useEffect(() => {
        cache.get("contact_schema").then((schema) => {
            setSchema(schema)
        })
    }, [cache, setSchema])

    useEffect(() => {
        onChange(value)
    }, [value])

    const updateValue = useCallback((field, value) => {
        setValue((prevValue) => {
            const newValue = {
                ...prevValue,
            }
            _set(newValue, field, value)

            return newValue
        })
    }, [setValue])

    return (
        <Box>
            <SimpleLoading loading={!schema} />
            {schema && <Grid spacing={2} container>
                {FORM_FIELDS.map((row, index) =>
                    <FormRow
                        key={index}
                        fields={row.map((field) => ({ ...field, required: field.required && required }))}
                        value={value}
                        schema={schema}
                        mode={index % 2 === 0 ? "compact" : "full"}
                        onChange={updateValue}
                    />
                )}
                {getOptionalFields({ required, value, schema, updateValue, optionalFields })}
            </Grid>}
        </Box>
    )
}

const AddressFormField = ({ field, value }) => {
    const { t } = useTranslation("vbms")

    const configField = field

    return (
        <Field
            id={field.id}
            name={field.id}
            validate={(value) => validate(t, field.required, value)}
        >
            {({
                field,
                form: { values, setFieldValue, setFieldTouched },
                meta,
            }) => {

                return (
                    <>
                        <AddressForm
                            defaultValue={meta.initialValue}
                            required={configField.required}
                            onChange={(value) => {
                                setFieldValue(field.name, value, true)
                                setFieldTouched(field.name, true, false)
                            }}
                            optionalFields={configField.optionalFields}
                        />
                        {meta.error && meta.touched && <FormHelperText error={true}>{meta.error}</FormHelperText>}
                    </>
                )
            }}
        </Field>
    )
}

export default AddressFormField