import { useContext } from "react";
import { Stack, Typography } from "@mui/material"
import { useTheme } from "@emotion/react";
import { DatePicker } from "@mui/x-date-pickers";
import { parseISO, format } from "date-fns";
import { RulesDivider } from "../RulesSelector";
import { RulesContext } from "./RulesSelector";
import { t } from "i18next";

const INTERVALS = {
    FROM: 'date_from',
    TO: 'date_to'
}

function getDateFormatString(locale) {
    const formatObj = new Intl.DateTimeFormat(locale).formatToParts(new Date())
    return formatObj
        .map((obj) => {
            switch (obj.type) {
                case "day":
                    return "dd"
                case "month":
                    return "MM"
                case "year":
                    return "yyyy"
                default:
                    return obj.value
            }
        })
        .join("")
}


export const RulesSelectorDates = ({ label = "Label", showLabel, showDivider, editable = false, index, language }) => {
    const theme = useTheme()

    const {config, setConfig} = useContext(RulesContext)
    const {date_from: dateFrom, date_to: dateTo} = config[index]

    const formatDate = (date) => {
        return format(date, "yyyy-MM-dd")
    }

    const onChange = (value, interval) => {
        let newConfig = [...config]

        newConfig[index] = {...newConfig[index], [interval]: formatDate(value)}
        setConfig(newConfig)
    }

    const renderLabel = () => {
        if (showLabel || (!showLabel && index === 0)) {
            return (
                <Typography
                    variant="h5"
                    borderBottom={!showLabel && `1px solid ${theme.palette.common.container.border}`}
                    marginBottom={!showLabel && 2}
                    paddingBottom={!showLabel && 2}
                    sx={{ fontWeight: 'bold' }}
                >
                    {label}
                </Typography>
            )
        }
        return null
    }

    return (
        <Stack gap={2}>
            {renderLabel()}
            <Stack direction={{ xs: 'column', sm: 'row' }} spacing={{ xs: 2, sm: 1 }} divider={showDivider && <RulesDivider />}>
                <DatePicker
                    format={getDateFormatString(language)}
                    label={t("components.rules_selector.range_from")}
                    value={parseISO(dateFrom)}
                    slotProps={{ textField: { size: 'small', InputLabelProps: { shrink: true }, fullWidth: true } }}
                    readOnly={!editable}
                    disableOpenPicker={!editable}
                    onChange={(value) => onChange(value, INTERVALS.FROM)}
                />
                <DatePicker
                    format={getDateFormatString(language)}
                    label={t("components.rules_selector.range_to")}
                    value={parseISO(dateTo)}
                    slotProps={{ textField: { size: 'small', InputLabelProps: { shrink: true }, fullWidth: true } }}
                    readOnly={!editable}
                    disableOpenPicker={!editable}
                    onChange={(value) => onChange(value, INTERVALS.TO)}
                />
            </Stack>
        </Stack>
    )
}

export default RulesSelectorDates