import BasePage from "../BasePage"
import { EntityContextProvider } from "../../../components/entity/context/entity/entity.context"
import EntityField from "../../../components/entity/EntityField"
import EntityForm from "../../../components/form/EntityForm"
import { Formik } from "formik"
import { useTranslation } from "react-i18next"
import { useNavigate } from "react-router-dom"
import { initFormValues } from "../../../components/form/utils"

const EntityFormPage = (props) => {
    const { entity, title, subtitle, formContent, onSave, variant } = props
    const { t } = useTranslation("vbms")
    const navigate = useNavigate()

    const initialValues = initFormValues(entity, formContent)

    return (
        <EntityContextProvider entity={entity}>
            <Formik initialValues={initialValues} onSubmit={async (values) => onSave(values)}>
                {({ submitForm, isSubmitting }) => {
                    const actions = [
                        {
                            id: "save",
                            title: t("common.save"),
                            onExecute: () => {
                                submitForm()
                            },
                        },
                        {
                            id: "cancel",
                            title: t("common.cancel"),
                            onExecute: () => {
                                navigate(-1)
                            },
                        },
                    ]

                    return (
                        <BasePage
                            title={<EntityField field={title} entity={entity} />}
                            subtitle={subtitle && <EntityField field={subtitle} entity={entity} />}
                            actions={actions}
                        >
                            <EntityForm entity={entity} formContent={formContent} variant={variant}/>
                        </BasePage>
                    )
                }}
            </Formik>
        </EntityContextProvider>
    )
}

export default EntityFormPage
