import { Box, Tab, Tabs } from "@mui/material";
import { useState } from "react";

const TabNavigation = ({ tabs, onTabSelected }) => {
    const [activeTab, setActiveTab] = useState(0);

    const handleChange = (event, newActiveTab) => {
        setActiveTab(newActiveTab);
        onTabSelected(newActiveTab);
    };

    return (
        <Box flexGrow={1}>
            <Tabs value={activeTab} onChange={handleChange} textColor="primary">
                {tabs.map((tab, index) => (
                    <Tab
                        disableRipple
                        key={index}
                        label={tab}
                        id={`section-tab-${index}`}
                        aria-controls={`tabpanel-${index}`}
                        sx={{
                            fontSize: "0.875rem"
                        }}
                    />
                ))}
            </Tabs>
        </Box>
    )
}

export default TabNavigation