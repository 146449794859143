import constants from "../../../../components/entity/constants"
import {
    createDataSetFacet,
    createField
} from "../../../../components/helpers/fieldHelper";

export const getHeaderContent = (t) => [
    createDataSetFacet(t("routes.view.facet_information"), [
        createField("id", "Id", constants.STRING_TYPE),
        createField(
            "name",
            t("routes.fields.name"),
            constants.STRING_TYPE
        ),

    ]),
];
