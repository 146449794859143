import constants from '../../../components/entity/constants'
import { createDataSetFacet, createFacetField, createFacetsContainer, createField, createCallbackField, createObjectStatusField } from '../../../components/helpers/fieldHelper'

export const getHeaderContent = (t) => [
    createDataSetFacet(t("suggestions.view.facet_information"), [
        createField(
            "id",
            "Id",
            constants.STRING_TYPE
        ),
        createField(
            "created",
            t("common.fields.created"),
            constants.DATETIME_TYPE
        ),
        createField(
            "modified_user.name",
            t("suggestions.fields.modified_user"),
            constants.STRING_TYPE,
            null,
            (values) => values.managed
        ),
        createField(
            "modified",
            t("suggestions.fields.modified"),
            constants.DATETIME_TYPE,
            null,
            (values) => values.managed
        ),
    ]),
    createFacetsContainer([
        createFacetField("object_status", {
            size: "large",
            field: createObjectStatusField(
                createCallbackField(
                    "managed",
                    t("suggestions.view.facet_managed"),
                    constants.STRING_TYPE,
                    (value) => value ? t("suggestions.constants.managed") : t("suggestions.constants.not_managed")
                ),
                null,
                (value) => value === t("suggestions.constants.managed") ? "positive" : "negative"
            ),
        }),
    ]),
]
