import _has from 'lodash/has'
import ChangeState from "./components/actions/ChangeState"
import availableRoutes from '../../../routes/availableRoutes';

/**
* Process payment data and redirects to the required url.
*
* @param {object} paymentData
*/
const processPaymentRedirection = (paymentData) => {
    if (paymentData.type === "GET") {
        window.location.replace(paymentData.payment_url)
    } else if (paymentData.type === "POST") {
        const form = document.createElement("form")
        form.id = "paymentForm"
        form.name = "paymentForm"
        form.method = "post"
        form.action = paymentData.payment_url

        // create form fields
        Object.entries(paymentData.data).forEach(
            ([fieldName, fieldValue]) => {
                const input = document.createElement("input")
                input.type = "hidden"
                input.name = fieldName
                input.value = fieldValue
                form.appendChild(input)
            }
        )

        document.body.appendChild(form)

        form.submit()
    }
}

export const getActions = (apiClient, entityId, t, navigate, user) => [
    {
        id: "add_payment",
        title: t("intermediaries.actions.add_payment.button"),
        confirmDialog: {
            title: t("intermediaries.actions.add_payment.modal_title"),
            content: () =>t("intermediaries.actions.add_payment.modal_content"),
        },
        showLoading: true,
        onExecute: (result, progressHandler, onSuccess, onError) => {
            return apiClient.intermediary
                .requestPaymentToken(entityId)
                .then((paymentData) => {
                    processPaymentRedirection(paymentData)
                })
                .catch((error) => {
                    onError(
                        t("intermediaries.actions.add_payment.error", {
                            name: entityId,
                        })
                    )

                    throw error
                })
        },
        condition: (intermediary) => intermediary.hasAction("add_payment")
    },
    {
        id: "delete_payment",
        title: t("intermediaries.actions.delete_payment.button"),
        confirmDialog: {
            title: t("intermediaries.actions.delete_payment.modal_title"),
            content: () =>t("intermediaries.actions.delete_payment.modal_content"),
        },
        showLoading: true,
        reloadOnSuccess: true,
        onExecute: (result, progressHandler, onSuccess, onError) => {
            return apiClient.intermediary
                .deletePaymentToken(entityId)
                .then((intermediary) => {
                    onSuccess(t("intermediaries.actions.delete_payment.confirm"))

                    return intermediary
                })
                .catch((error) => {
                    onError(
                        t("intermediaries.actions.delete_payment.error", {
                            name: entityId,
                        })
                    )

                    throw error
                })
        },
        condition: (intermediary) => intermediary.hasAction("delete_payment")
    },    
    {
        id: "change_state",
        title: t("intermediaries.actions.change_state.button"),
        withDialog: {
            title: t("intermediaries.actions.change_state.modal_title"),
            content: ChangeState
        },
        reloadOnSuccess: true,
        showLoading: true,
        onExecute: (result, progressHandler, onSuccess, onError) => {
            return apiClient.intermediary
                .setIntermediaryState(entityId, result.data.state)
                .then((intermediary) => {
                    onSuccess(
                        t("intermediaries.actions.change_state.confirm", {
                            name: entityId,
                        })
                    )

                    return intermediary
                })
                .catch((error) => {
                    onError(
                        t("intermediaries.actions.change_state.error", {
                            name: entityId,
                        })
                    )

                    throw error
                })
        },
        condition: (intermediary) => _has(intermediary.getActions(), "change_state")
    },
    {
        id: "edit_products",
        title: t("intermediaries.actions.edit_products.button"),
        onExecute: (intermediary, progressHandler, onSuccess, onError) => {
            const baseUrl = user.settings.disfrutare.base_url
            const loginUrl = user.settings.disfrutare.login_url
            const url = (baseUrl + loginUrl).replace("[TOKEN]", localStorage.getItem("jwt"))
            
            window.open(url.replace("[URL]", availableRoutes.disfrutare_colaborator_product_rates.path.replace(":entityId", intermediary.collaborator_id)))
        },
        condition: (intermediary) => _has(intermediary.getActions(), "edit") && _has(user, "settings.disfrutare.base_url")
    },
    {
        id: "edit",
        title: t("common.edit"),
        onExecute: (intermediary, progressHandler, onSuccess, onError) => {
            navigate(availableRoutes.intermediaries_intermediary_edit.path.replace(":entityId", intermediary.id))
        },
        condition: (intermediary) => _has(intermediary.getActions(), "edit")
    }
]