import { useTranslation } from "react-i18next"
import { useVolcanoAuth } from "../../../../../context/VolcanoAuthContext"
import { useVolcanoApiClient } from "../../../../../context/VolcanoApiClientProvider"
import { createField, createFormField, createFormFieldset } from "../../../../../components/helpers/fieldHelper"
import AsyncEntityForm from "../../../../../components/form/AsyncEntityForm"
import constants from "../../../../../components/entity/constants"
import { Box } from "@mui/material"

const EnterpriseEditCurrency = ({ selection, onAction, onCancel }) => {
    const { t } = useTranslation("vbms")

    const fetcher = () => Promise.resolve({
        rate: selection.exchange_rate.mode === "fixed" ? selection.exchange_rate.rate : null,
        correction_factor: selection.exchange_rate.correction_factor * 100,
    })

    const formContent = [
        createFormFieldset(null, [
            {
                ...createFormField(
                    createField(
                        "rate",
                        t("enterprise.fields.currencies.exchange_rate.rate"),
                        constants.NUMERIC_TYPE
                    ),
                    false
                ),
                max_width: "100%"
            },
            {
                ...createFormField(
                    createField(
                        "correction_factor",
                        t("enterprise.fields.currencies.exchange_rate.correction_factor"),
                        constants.NUMERIC_TYPE
                    ),
                    true,
                    0.0
                ),
                max_width: "100%"
            }
        ])
    ]

    const onConfirm = (result) => {
        onAction({
            id: selection.id,
            name: selection.name,
            exchange_rate: {
                rate: result.rate ? parseFloat(result.rate) : null,
                correction_factor: parseFloat(result.correction_factor) / 100,
            }
        })
    }

    return (
        <Box>
            <Box sx={{ mb: 2 }}>
                {t("enterprise.actions.edit_currency.modal_content")}
            </Box>
            <AsyncEntityForm
                fetcher={fetcher}
                onSave={onConfirm}
                formContent={formContent}
            />
        </Box>
    )
}

export default EnterpriseEditCurrency