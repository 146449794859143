import IntermediarySalesTable from "../../../../../components/Volcano/Intermediaries/IntermediarySalesTable";
import { Box, Button } from "@mui/material";
import { openInNewTab, getRouteParams } from "../../../../../lib/utils";
import _set from "lodash/set"
import { useTranslation } from "react-i18next";

const IntermediarySalesButton = ({ defaultParams, entity} ) => {
    const { t } = useTranslation("vbms")

    return (
        <Box
            mb = {1}>
                <Button
                        size="small"
                        color="primary"
                        variant="contained"
                        onClick={() => {
                            //_set(defaultParams, "collaborator_id", [{'id':entity.collaborator_id, 'label':entity.name, 'entity': entity}])
                            _set(defaultParams, "collaborator_id", [{'id':entity.collaborator_id, 'label':entity.name}])
                            _set(defaultParams, "payment_method_id", [])

                            const params = {
                                page: 0,
                                rowsPerPage: 20,
                                filter: defaultParams
                            }

                            let url = new URL(window.location.href);
                            url.pathname = "/orders/bookings";
                            url.searchParams.set("q", getRouteParams(params))
                            openInNewTab(url.toString())
                        }}
                    >
                    {t("intermediaries.actions.view_bookings.button")}
                </Button>
            </Box>
    )

}

const IntermediarySales = ({ entity }) => {
    return (
        <Box>
            <IntermediarySalesButton defaultParams={{ collaborator_id: entity.collaborator_id }} entity={ entity } />
            <IntermediarySalesTable defaultParams={{ collaborator_id: entity.collaborator_id }} />
        </Box>
    )
}

export default IntermediarySales