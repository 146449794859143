import constants from "../../../components/entity/constants"
import { createField, createLinkField, createLiquidationStateMapping } from '../../../components/helpers/fieldHelper'

export const getTableColumns = (t, user) => ([
    createLinkField(
        createField(
            "title",
            t("liquidations.fields.title"),
            constants.STRING_TYPE
        ),
        {
            route: "liquidations",
            field: "id",
        }
    ),
    createLinkField(
        createField(
            "collaborator.fact_razon_social",
            t("liquidations.fields.intermediary"),
            constants.STRING_TYPE,
            null,
            (user) => true //user.isIntermediary()
        ),
        {
            route: "intermediaries",
            field: "collaborator.crm_intermediary_id",
        }
            
    ),
    createField(
        "status",
        t("liquidations.fields.status"),
        constants.STRING_TYPE,
        createLiquidationStateMapping(t)
    ),
    createField(
        "created",
        t("liquidations.fields.created"),
        constants.DATETIME_TYPE,
    ),
    createField(
        "total_price",
        t("liquidations.fields.total_amount"),
        constants.CURRENCY_TYPE
    )
])