import constants from "../../entity/constants"
import { createBookingStateMapping, createField, createLinkField } from "../../helpers/fieldHelper"

const BOOKING_FIELD_ID = "id"
const BOOKING_FIELD_LOCATOR = "locator"
const BOOKING_FIELD_REMOTE_ID = "remote_id"
const BOOKING_FIELD_EXPERIENCE = "experience"
const BOOKING_FIELD_PRODUCT = "product"
const BOOKING_FIELD_BOOKING_DATE = "booking_date"
const BOOKING_FIELD_CREATION_DATE = "creation_date"
const BOOKING_FIELD_VALIDATION_DATE = "validation_date"
const BOOKING_FIELD_STATE = "state"
const BOOKING_FIELD_TOTAL_AMOUNT = "total_amount"
const BOOKING_FIELD_TOTAL_QTY = "total_qty"

const processBookingFields = (t, fields) => {
    return fields.map(field => {
        switch (field) {
            case BOOKING_FIELD_ID:
                return createField(
                    "id",
                    "ID",
                    constants.STRING_TYPE
                )
            case BOOKING_FIELD_LOCATOR:
                return createLinkField(
                    createField(
                        "locator",
                        t("bookings.fields.locator"),
                        constants.TEXT_TYPE
                    ),
                    {
                        route: "bookings",
                        field: "id",
                    }
                )
            case BOOKING_FIELD_REMOTE_ID:
                return createField(
                    "order.remote_id",
                    "Remote id",
                    constants.STRING_TYPE
                )
            case BOOKING_FIELD_EXPERIENCE:
                return createField(
                    "product.experience.name",
                    "Experience",
                    constants.STRING_TYPE
                )
            case BOOKING_FIELD_PRODUCT:
                return createField(
                    "product.name",
                    "Product",
                    constants.STRING_TYPE
                )
            case BOOKING_FIELD_BOOKING_DATE:
                return createField(
                    "booking_date",
                    "Booking date",
                    constants.DATETIME_TYPE
                )
            case BOOKING_FIELD_CREATION_DATE:
                return createField(
                    "order.created",
                    "Creation date",
                    constants.DATETIME_TYPE
                )
            case BOOKING_FIELD_VALIDATION_DATE:
                return createField(
                    "validation_date",
                    "Validation date",
                    constants.DATETIME_TYPE
                )
            case BOOKING_FIELD_STATE:
                return createField(
                    "state",
                    t("bookings.fields.state"),
                    constants.STRING_TYPE,
                    createBookingStateMapping(t)
                )
            case BOOKING_FIELD_TOTAL_AMOUNT:
                return createField(
                    "amount.total_amount",
                    t("bookings.fields.total_amount"),
                    constants.CURRENCY_TYPE
                )
            default:
                return []
        }
    })
}

export {
    BOOKING_FIELD_ID,
    BOOKING_FIELD_LOCATOR,
    BOOKING_FIELD_REMOTE_ID,
    BOOKING_FIELD_EXPERIENCE,
    BOOKING_FIELD_PRODUCT,
    BOOKING_FIELD_BOOKING_DATE,
    BOOKING_FIELD_CREATION_DATE,
    BOOKING_FIELD_VALIDATION_DATE,
    BOOKING_FIELD_STATE,
    BOOKING_FIELD_TOTAL_AMOUNT,
    BOOKING_FIELD_TOTAL_QTY,
    processBookingFields
}