import { useTranslation } from "react-i18next"
import { useVolcanoApiClient } from "../../../context/VolcanoApiClientProvider"
import { useVolcanoAuth } from "../../../context/VolcanoAuthContext"
import CollectionPage from "../../../layout/templates/CollectionPage/CollectionPage"
import { Download, Visibility } from "@mui/icons-material"
import _padStart from "lodash/padStart"
import { getTableColumns } from "./LiquidationsCollection.columns"
import { getTableFilter } from "./LiquidationsCollection.filter"
import { downloadFile, openInNewTab, getSuggestedForCollaboratorFilename } from "../../../lib/utils"
import { getActions } from "./LiquidationsCollection.actions"
import { useCache } from "../../../context/cache.context"
import _get from 'lodash/get';


const LiquidationsCollectionPage = () => {
    const { t } = useTranslation("vbms")
    const { apiClient } = useVolcanoApiClient()
    const volcanoAuth = useVolcanoAuth()
    const cache = useCache()

    const actions = [
        {
            id: "open_liquidation",
            title: t("common.open"),
            scope: 'row',
            icon: Visibility,
            onExecute: (liquidation, progressHandler, onSuccess, onError) => {
                const data = {
                    resource: "liquidations",
                    operation: "liquidation_pdf",
                    id: liquidation.id,
                    title: getSuggestedForCollaboratorFilename(liquidation.title, liquidation.collaborator, true)
                }
                return apiClient.download.createLink(data)
                    .then((link) => {
                        openInNewTab(link.url);
                    })
                    .catch((error) => {
                        onError(t("common.actions.share.error_open"))
                    })
            }
        },
        {
            id: "download_liquidation",
            title: t("common.download"),
            scope: 'row',
            icon: Download,
            onExecute: (liquidation, progressHandler, onSuccess, onError) => {
                apiClient.liquidation.getLiquidationPdf(liquidation.id)
                    .then((pdf) => {
                        downloadFile(pdf, getSuggestedForCollaboratorFilename(liquidation.title, liquidation.collaborator, true) + ".pdf")
                    })
                    .catch((error) => {
                        onError(t("common.actions.share.error_download"))
                    })
            }
        }
    ]

    const filterConfig = getTableFilter(apiClient, t, volcanoAuth.user, cache)

    //todo: add generate action if user has permission

    const table = {
        columns: getTableColumns(t, volcanoAuth.user),
        actions: actions,
        fetcher: (params) => {

            const queryFilter = {
                ...params,
                collaborator_id: _get(params, "collaborator_id[0].id", null)
            }

            return apiClient.liquidation
                .getLiquidations(queryFilter)
                .then((result) => {
                    result.items = result.getItems().map((liquidation) => {
                        liquidation.title = `${liquidation.year}${_padStart(liquidation.number, 5, 0)}`
                        return liquidation
                    })

                    return result
                })
        }
    }

    return (
        <CollectionPage
            title={t("liquidations.title")}
            subtitle={t("liquidations.subtitle")}
            actions={getActions(apiClient, t, volcanoAuth.user)}
            table={table}
            filterConfig={filterConfig}
        />
    )
}

export default LiquidationsCollectionPage
