import {useTranslation} from "react-i18next";
import {Box} from "@mui/system";
import {ToggleButton, ToggleButtonGroup} from "@mui/material";

const WeekdaysFieldValue = ({ value }) => {
    const { t } = useTranslation("vbms")
    const formatDays = (values) => {
        return values?.split('').map(Number)
    }
    const days = ['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday']
    const selectedValues  = formatDays(value)
    return (
        <Box>
            <ToggleButtonGroup
                value={days}
                size="small"
                exclusive>
                {days.map((day, index) => {
                    return (
                        <ToggleButton
                            key={`${day}-${index}`}
                            value={day}
                            selected={selectedValues[index] === 1}
                            readOnly={true}
                            color={selectedValues[index] === 1 ? 'primary' : 'standard'}
                        >
                            {t(`common.fields.week_days_short.${day}`)}
                        </ToggleButton>
                    )
                })}
            </ToggleButtonGroup>
        </Box>
    );
}

export default WeekdaysFieldValue;
