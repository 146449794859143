import { createContext, useContext, useEffect, useMemo } from "react"
import useAsync from "../components/hooks/useAsync"

const initialState = {
    data: null,
    error: null,
    status: "idle",
}

const Context = createContext(initialState)

const AsyncContextProvider = ({ fetcher, loadComponent, errorComponent, children }) => {
    const { execute, status, value, error } = useAsync(fetcher, false)
    const Loading = loadComponent
    const ErrorComponent = errorComponent

    useEffect(() => {
        execute()
    }, [execute])

    const contextValue = useMemo(() => {
        return {
            data: value,
            error: error,
            status: status,
            fetchContent: execute,
        }
    }, [
        value,
        error,
        status,
        execute,
    ])

    return (
        <Context.Provider value={contextValue}>
            {loadComponent && (status === "pending") && <Loading open={status === "pending"} />}
            {status === "success" ? children : null}
            {errorComponent && (status === "error") && <ErrorComponent error={error} />}
        </Context.Provider>
    )
}

const useAsyncContent = () => useContext(Context)

export {
    Context as AsyncContext,
    AsyncContextProvider,
    useAsyncContent
}