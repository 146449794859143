import { createTheme, responsiveFontSizes } from "@mui/material";


const palette = {
    common: {
        black: "#000",
        white: "#fff",
        border: "#758ca4",
        text: "#1d2d3e",
        label: "#556b82",
        table: {
            headerBackground: "#eff1f2",
            border: "#e5e5e5",

        },
        container: {
            border: "#d9d9d9",
        }
    },
    background: {
        default: "#f5f6f7",
        error: {
            main: "#ffeaf4",
        },
        warning: {
            main: "#fff8d6",
        },
        info: {
            main: "#e1f4ff",
        },
        success: {
            main: "#f5fae5",
        },
    },
    primary: {
        main: "#0070f2",
    },
    secondary: {
        main: "#d27700",
    },
    error: {
        main: "#aa0808",
    },
    warning: {
        main: "#e76500",
    },
    info: {
        main: "#788FA6",
    },
    success: {
        main: "#256f3A",
    },
    text: {
        primary: "#1d2d3e",
    },
    contrastThreshold: 4.5,
}

const typography = {
    fontFamily: ["Muli", "Roboto", "Helvetica Neue", "Arial2", "sans-serif"].join(","),
    htmlFontSize: 18,
    fontSize: 14,
    h1: {
        fontSize: "3rem"
    },
    h2: {
        fontSize: "2rem"
    },
    h3: {
        fontSize: "1.5rem"
    },
    h4: {
        fontSize: "1.25rem"
    },
    h5: {
        fontSize: "1rem"
    },
    h6: {
        fontSize: "0.875rem"
    },
    body1: {
        fontSize: "0.875rem"
    },
    body2: {
        fontSize: "0.775rem"
    },
    subtitle1: {
        fontSize: "1rem",
        color: palette.common.label
    }
}

const theme = createTheme({
    palette: palette,
    typography: typography,
    components: {
        MuiLink: {
            styleOverrides: {
                root: {
                    textDecoration: "none"
                }
            }
        },
        MuiButton: {
            styleOverrides: {
                sizeSmall: {
                    minWidth: "auto",
                    paddingLeft: "0.4375rem",
                    paddingRight: "0.4375rem",
                    fontSize: typography.body1.fontSize,
                    fontWeight: "500",
                    textTransform: "none",
                }
            }
        },
        MuiTabs: {
            styleOverrides: {
                indicator: ({ ownerState, theme }) => ({
                    backgroundColor: theme.palette.primary.main,
                    height: 3
                }),
            }
        },
        MuiTab: {
            styleOverrides: {
                root: {
                    textTransform: "none",
                    margin: "0 0.5rem",
                    padding: "0 0.25rem",
                    minWidth: 0,

                },
                textColorPrimary: ({ ownerState, theme }) => ({
                    color: theme.palette.text.primary,
                }),
            }
        },
        MuiTableCell: {
            styleOverrides: {
                root: {
                    fontSize: typography.body1.fontSize
                }
            }
        },
        MuiTablePagination: {
            styleOverrides: {
                root: {
                    fontSize: typography.body1.fontSize
                },
                selectLabel: {
                    fontSize: typography.body1.fontSize
                },
                displayedRows: {
                    fontSize: typography.body1.fontSize
                },
            }
        },
        MuiOutlinedInput: {
            styleOverrides: {
                notchedOutline: {
                    fontSize: typography.body1.fontSize
                }
            }
        },
        MuiFormHelperText: {
            styleOverrides: {
                root: ({ ownerState, theme }) => ({
                    fontWeight: ownerState.error ? "600" : "400",
                }),
            }
        },
    }
})

export default responsiveFontSizes(theme)