import { useTranslation } from "react-i18next"
import DataSet from '../Display/DataSet'
import StringFieldValue from "./StringFieldValue"
import _get from "lodash/get"
import _isEmpty from "lodash/isEmpty"
import constants from "./constants"

/**
 * Display contact details info.
 * @param {Object} value
 */
const ContactDetailsFieldValue = ({ value }) => {
    const { t } = useTranslation("vbms")

    if (!value) {
        return null
    }

    const fields = [
        {
            id: "address_type.name",
            key: "address_type",
            type: constants.STRING_TYPE,
        },
        {
            id: "address",
            key: "address",
            type: constants.STRING_TYPE,
        },
        {
            id: "locality",
            key: "locality",
            type: constants.STRING_TYPE,
        },
        {
            id: "postal_code",
            key: "postal_code",
            type: constants.STRING_TYPE,
        },
        {
            id: "state",
            key: "state",
            type: constants.STRING_TYPE,
        },
        {
            id: "country.name",
            key: "country",
            type: constants.STRING_TYPE,
        },
        {
            id: "email",
            key: "email",
            type: constants.EMAIL_TYPE,
        },
        {
            id: "phone",
            key: "phone",
            type: constants.PHONE_TYPE,
        },
        {
            id: "phone_alternative",
            key: "phone_alternative",
            type: constants.PHONE_TYPE,
        },
        {
            id: "fax",
            key: "fax",
            type: constants.STRING_TYPE,
        },
        {
            id: "copy_address_from",
            key: "copy_address_from",
            type: constants.CHECKBOX_TYPE,
        }
    ]

    const data = []

    fields
        .filter(field => !_isEmpty(_get(value, field.id)))
        .forEach(field => {
            let format = null
            switch (field.type) {
                case constants.EMAIL_TYPE:
                    format = "email"
                    break
                case constants.PHONE_TYPE:
                    format = "phone"
                    break
                default:
                    format = "default"
            }

            data.push({
                key: field.id,
                name: t(`common.fields.contact_details.${field.key}`),
                value: <StringFieldValue value={_get(value, field.id)} format={format} />,
            })
        })

    return (
        <DataSet variant="inline" titleVariant="label" data={data} />
    )

}

export default ContactDetailsFieldValue