import TabNavigation from "./TabNavigation"

const AsyncTabNavigation = ({ tabs, onTabSelected }) => {
    console.log("ASYNCTAB")
    return (
        <TabNavigation
            onTabSelected={onTabSelected}
            tabs={tabs.map((tab) => tab.name)}
        />
    )
}

export default AsyncTabNavigation