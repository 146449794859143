import _get from 'lodash/get'

export const parseBillingClientFromApi = (billingClient) => {
    billingClient.contact_details = {
        address_type: billingClient.address_type,
        address: billingClient.address,
        locality: billingClient.locality,
        state: billingClient.state,
        postal_code: billingClient.postal_code,
        country: billingClient.country,
        email: billingClient.email,
        phone: billingClient.phone,
    }

    delete billingClient.address_type
    delete billingClient.address
    delete billingClient.locality
    delete billingClient.state
    delete billingClient.postal_code
    delete billingClient.country
    delete billingClient.email
    delete billingClient.phone

    return billingClient
}

export const parseBillingClientToApi = (billingClient) => {
    // clone billingClient
    const data = {
        ...billingClient,
        road_type: {
            id: billingClient.contact_details.address_type.id
        },
        address: billingClient.contact_details.address,
        locality: billingClient.contact_details.locality,
        state: billingClient.contact_details.state,
        postal_code: billingClient.contact_details.postal_code,
        country: {
            id: billingClient.contact_details.country.id,
        },
        email: billingClient.contact_details.email,
        phone: billingClient.contact_details.phone,
        enterprise_id: parseInt(billingClient.enterprise.id),
        allow_repeated: Boolean(parseInt(billingClient.allow_repeated))
    }

    if (_get(billingClient, "accountancy_config.account_id", "") === "") {
        delete data.accountancy_config
    }

    if (billingClient.is_collaborator) {
        data.collaborator_id = billingClient.collaborator_id

        delete data.id
    }

    delete data.contact_details
    delete data.enterprise

    return data
}

export const billingClientsFetcher = (apiClient, vatNumber, enterpriseId, countryId) => {
    return apiClient.billingInformation.getBillingInformations({
        limit: 1000,
        vat_number: vatNumber,
        enterprise_id: enterpriseId,
        country: countryId
    })
}

export const billingClientFetcher = (apiClient, billingClientId) => {
    return apiClient.billingInformation
        .getBillingInformation(billingClientId)
        .then((billingClient) => parseBillingClientFromApi(billingClient))
}