import { useTranslation } from "react-i18next"
import { useVolcanoAuth } from "../../../../context/VolcanoAuthContext"
import { Box, Grid, Paper } from '@mui/material';
import ProcessSteps from '../../../../components/ProcessSteps/ProcessSteps';
import Loading from '../../../../components/Display/Loading';
import { useCallback, useState } from 'react';
import ClientSelector from "./ClientSelector"
import { billingClientsFetcher as extBillingClientsFetcher, parseBillingClientToApi } from '../../BillingClients.functions';
import useSnackBars from '../../../../components/Snackbar/snack-bar.context';
import BookingsSelector from "./BookingsSelector";
import CreateInvoice from "./CreateInvoice";
import { useVolcanoApiClient } from '../../../../context/VolcanoApiClientProvider';
import { contactSchemaKeyItemsFetcher, enterprisesFetcher as extEnterprisesFetcher } from '../../../../lib/form-options-fetchers';
import { useCache } from '../../../../context/cache.context';
import ClientForm from "./ClientForm";

/** Available selection steps */
const STEP_INITIAL = 1
const STEP_CLIENT_EDIT = 2
const STEP_BOOKINGS = 3
const STEP_INVOICE = 4

const BASE_SELECTION = {
    step: STEP_INITIAL,
    billingClient: null,
    clientEdited: false,
    bookings: []
}

const DEFAULT_STEPS = [
    STEP_INITIAL,
    STEP_CLIENT_EDIT,
    STEP_BOOKINGS,
    STEP_INVOICE
]

/**
 * Returns the step title based on the step number
 *  
 * @param {*} step 
 * @param {*} selection 
 * @returns 
 */
const stepGetTitle = (step, selection) => {
    switch (step) {
        case STEP_INITIAL:
            return "billing_client_create_invoice_widget.steps.initial.title"
        case STEP_CLIENT_EDIT:
            return selection.billingClient && selection.billingClient.is_collaborator ?
                "billing_client_create_invoice_widget.steps.client_edit.collaborator_title" :
                "billing_client_create_invoice_widget.steps.client_edit.title"
        case STEP_BOOKINGS:
            return "billing_client_create_invoice_widget.steps.bookings.title"
        case STEP_INVOICE:
            return "billing_client_create_invoice_widget.steps.invoice.title"
        default:
            return null
    }
}

/**
 * Returns the initial selection object
 *  
 * @param {*} selection 
 * @returns 
 */
const initSelection = (selection) => {
    let result = {
        ...BASE_SELECTION,
        ...selection,
        step: checkStep(selection)
    }

    return result
}

/**
 * Returns the step to be displayed based on the current selection
 *  
 * @param {*} selection 
 * @returns 
 */
const checkStep = (selection) => {
    if (!selection.billingClient) {
        return STEP_INITIAL
    }

    if (!selection.clientEdited) {
        return STEP_CLIENT_EDIT
    }

    if (!selection.bookings || selection.bookings.length === 0) {
        return STEP_BOOKINGS
    }

    return STEP_INVOICE
}

const getStepLoadingMessage = (step) => {
    switch (step) {
        case STEP_INITIAL:
            return "billing_client_create_invoice_widget.steps.initial.loading"
        case STEP_CLIENT_EDIT:
            return "billing_client_create_invoice_widget.steps.client_edit.loading"
        default:
            return null
    }
}

/**
 * Returns the available steps configuration based on the current selection
 *  
 * @param {*} selection 
 * @returns 
 */
const getSteps = (defaultSelection, t, locale, selection) => {
    const availableSteps = DEFAULT_STEPS

    return availableSteps.map((step, index) => {
        let result = {
            id: step,
            content: {
                title: t(stepGetTitle(step, selection)),
                text: null,
                info: null,
            },
        }

        switch (step) {
            case STEP_CLIENT_EDIT:
                if (selection.billingClient) {
                    result.content.text = `${selection.billingClient.name} (${selection.billingClient.vat_number})`
                }
                break
            default:
                break
        }

        return result
    })
}

const ProcessStep = (props) => {
    const { step, t, selection } = props

    const buildStepComponent = () => {
        switch (step) {
            case STEP_INITIAL:
                return (
                    <ClientSelector
                        t={t}
                        countriesFetcher={props.countriesFetcher}
                        enterprisesFetcher={props.enterprisesFetcher}
                        billingClientsFetcher={props.billingClientsFetcher}
                        collaboratorsFetcher={props.collaboratorsFetcher}
                        user={props.user}
                        onSelection={props.onSelection}
                    />
                )
            case STEP_CLIENT_EDIT:
                return (
                    <ClientForm
                        selection={selection.billingClient}
                        enterprisesFetcher={props.enterprisesFetcher}
                        onConfirm={props.onConfirm}
                        onError={props.onError}
                    />
                )
            case STEP_BOOKINGS:
                return (
                    <BookingsSelector
                        selection={selection.bookings}
                        bookingFetcher={props.bookingFetcher}
                        onSelection={props.onSelection}
                        onError={props.onError}
                    />
                )
            case STEP_INVOICE:
                return (
                    <CreateInvoice
                        selection={props.selection}
                        onConfirm={props.onConfirm}
                    />
                )
            default:
                return null
        }
    }

    return <Box>{buildStepComponent()}</Box>
}

const BillingClientCreateInvoiceWidget = (props) => {
    const {
        defaultSelection,
        onInvoiceGenerated
    } = props

    const { t, i18n } = useTranslation()
    const { apiClient } = useVolcanoApiClient()
    const cache = useCache()
    const volcanoAuth = useVolcanoAuth()

    const [waiting, setWaiting] = useState(false)
    const [selection, setSelection] = useState(initSelection(defaultSelection || {}))
    const { addAlert } = useSnackBars()

    const countriesFetcher = useCallback(() => {
        return contactSchemaKeyItemsFetcher("country", cache, apiClient, t, false)
    }, [cache, apiClient, t])

    const enterprisesFetcher = useCallback(() => {
        return extEnterprisesFetcher(cache, apiClient, t, false)
    }, [cache, apiClient, t])

    const billingClientsFetcher = useCallback((vatNumber, enterpriseId, countryId) => {
        return extBillingClientsFetcher(apiClient, vatNumber, enterpriseId, countryId)
    }, [apiClient])

    const collaboratorsFetcher = useCallback((vatNumber, enterpriseId, countryId) => {
        return apiClient.collaborator
            .getCollaborators({
                vat_number: vatNumber,
                country_id: countryId,
                enterprise_id: enterpriseId,
                state: "active"
            })
    }, [apiClient])

    const bookingFetcher = useCallback((locator) => {
        return apiClient.booking.getBookings({ locator: locator }).then((result) => {
            const bookings = result.getItems()

            if (bookings.length === 0) {
                throw new Error("not_found")
            }

            let bookingResults = []

            bookings.forEach(booking => {
                if (apiClient.booking.checkBookingAction(booking.id, 'create_customer_invoice').then((result) => result.allowed)){
                    bookingResults.push({
                        id: booking.id,
                        locator: booking.locator
    
                    })
                }
            })

            return bookingResults
        })
    }, [apiClient])

    const handleOnError = (error) => {
        addAlert(error, "error")
    }

    const handleOnClientSelection = (stepSelection) => {
        const newSelection = {
            ...selection,
            billingClient: stepSelection.billingClient,
            clientEdited: false
        }

        setSelection({
            ...newSelection,
            step: checkStep(newSelection),
        })
    }

    const handleOnClientEditConfirm = (stepSelection) => {
        const newSelection = {
            ...selection,
            billingClient: stepSelection.billingClient,
            clientEdited: true
        }

        setSelection({
            ...newSelection,
            step: checkStep(newSelection),
        })
    }

    const handleOnBookingsSelection = (stepSelection) => {
        const newSelection = {
            ...selection,
            bookings: stepSelection.bookings
        }

        setSelection({
            ...newSelection,
            step: checkStep(newSelection),
        })
    }

    const handleOnCreateInvoiceConfirm = useCallback(() => {
        return apiClient.invoice
            .createBookingsInvoice(selection.bookings, parseBillingClientToApi(selection.billingClient))
            .then((invoice) => {
                onInvoiceGenerated(invoice)
                addAlert(t("billing_client_create_invoice_widget.steps.invoice.success", { title: invoice.title }), "success")
            })
            .catch(function (error) {
                addAlert(error.message, "error")
                throw error
            })
    }, [apiClient, selection, t, addAlert, onInvoiceGenerated])

    // returns the props for the current step
    const getProcessStepProps = (step) => {
        switch (step) {
            case STEP_INITIAL:
                return {
                    step: step,
                    selection: selection,
                    countriesFetcher: countriesFetcher,
                    enterprisesFetcher: enterprisesFetcher,
                    billingClientsFetcher: billingClientsFetcher,
                    collaboratorsFetcher: collaboratorsFetcher,
                    user: volcanoAuth.user,
                    onSelection: handleOnClientSelection,
                }
            case STEP_CLIENT_EDIT:
                return {
                    step: step,
                    selection: selection,
                    enterprisesFetcher: enterprisesFetcher,
                    onConfirm: handleOnClientEditConfirm,
                    onError: handleOnError
                }
            case STEP_BOOKINGS:
                return {
                    step: step,
                    selection: selection,
                    bookingFetcher: bookingFetcher,
                    onSelection: handleOnBookingsSelection,
                    onError: handleOnError
                }
            case STEP_INVOICE:
                return {
                    step: step,
                    selection: selection,
                    onConfirm: handleOnCreateInvoiceConfirm
                }
            default:
                return null
        }
    }

    const updateSelectionForStep = (step) => {
        let newSelection = {}

        switch (step) {
            case STEP_INITIAL:
                newSelection = {
                    billingClient: null,
                    clientEdited: false
                }
                break
            case STEP_CLIENT_EDIT:
                newSelection = {
                    clientEdited: false
                }
                break
            default:
                break
        }

        setSelection((selection) => ({
            ...selection,
            ...newSelection,
            step: step
        }))
    }

    return (
        <Paper elevation={0}>
            <Loading open={waiting} title={t(getStepLoadingMessage(selection.step))} />
            <Grid
                container
                spacing={2}
                direction="row"
                justify="flex-start"
                alignItems="flex-start"
            >
                <Grid item sm={2}>
                    <ProcessSteps
                        activeStep={selection.step}
                        steps={getSteps(defaultSelection, t, i18n.language, selection)}
                        onStepSelected={(step) => updateSelectionForStep(step)}
                    />
                </Grid>
                <Grid item xs={12} md={10}>
                    <ProcessStep {...getProcessStepProps(selection.step)} />
                </Grid>
            </Grid>
        </Paper>
    )
}

export default BillingClientCreateInvoiceWidget