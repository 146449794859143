import { Grid, useMediaQuery, useTheme } from "@mui/material"
import DataContainer from "./DataContainer"
import KeyValue from "./KeyValue"
import Text from "./Text"

const DataSet = (props) => {
    const theme = useTheme()
    const matchUpSm = useMediaQuery(theme.breakpoints.up('sm'))

    const variant = (matchUpSm && props.variant) || "default"
    const data = props.data || []

    return (
        <DataContainer
            title={props.title}
            titleVariant={props.titleVariant}
            titleLevel={props.titleLevel}
        >
            <Grid
                container
                direction={matchUpSm && (props.direction || "column") === "row" ? "row" : "column"}
                columnSpacing={2}
                rowSpacing={0}
            >
                {data.map((item, index) => (
                    <Grid
                        item
                        key={index}
                    //sx={{ marginBottom: index < data.length - 1 ? 0 : 0.5 }}
                    >
                        {(item.name && item.value && (
                            <KeyValue label={item.name} variant={variant}>
                                <Text>{item.value}</Text>
                            </KeyValue>)) || (<Text>{item.value}</Text>)}
                    </Grid>
                ))}
            </Grid>
        </DataContainer>
    );
};

export default DataSet;
