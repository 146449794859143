import { useTranslation } from "react-i18next"
import BasePage from "../../layout/templates/BasePage"
import VolcanoValidationWidget from "../../components/Volcano/Widgets/ValidationWidget/VolcanoValidationWidget"
import { useMediaQuery, useTheme } from "@mui/material"

const ValidationPage = (props) => {
    const theme = useTheme()
    const matchDownMd = useMediaQuery(theme.breakpoints.down('md'))
    const { t } = useTranslation("vbms")

    if (matchDownMd) {
        return <VolcanoValidationWidget />
    }

    return (
        <BasePage
            title={t("validation_widget.title")}
        >
            <VolcanoValidationWidget />
        </BasePage>
    )
}

export default ValidationPage