import { createContext, useCallback, useContext, useEffect, useMemo, useState } from "react"
import { useVolcanoAuth } from "./VolcanoAuthContext"

const stub = () => {
    throw new Error('You forgot to wrap your component in <MenuProvider></MenuProvider>.')
}

const initialState = {
    module: null,
    modules: [],
    set: stub
}
const Context = createContext(initialState)

const processDefaultModule = (user) => {
    if (!user || user.allowedModules?.length === 0) {
        return null
    }

    if (user.allowedModules.length === 1) {
        return user.allowedModules[0]
    }

    const activeModule = localStorage.getItem("activeModule")
    if (activeModule && user.allowedModules.includes(activeModule)) {
        return activeModule
    }

    // check if user has access to bookins module
    if (user.allowedModules.includes("bookings")) {
        return "bookings"
    }

    // returns first module that user has permissions
    return user.allowedModules[0]
}

const processModulesMenu = (allowedModules, menu, active) => {

    if (!allowedModules) {
        return []
    }

    return menu
        .filter(item => allowedModules.includes(item.key))
        .map(item => ({
            ...item,
            active: item.key === active
        }))
}

const MenuProvider = ({ menu, children }) => {
    const authContext = useVolcanoAuth()
    const [module, setModule] = useState(null)

    const setActiveModule = useCallback(value => {
        localStorage.setItem("activeModule", value)
        setModule(value)
    }, [setModule])

    const getModules = useCallback(() => {
        return !authContext.user ? [] : processModulesMenu(authContext.user.allowedModules, menu, module)
    }, [authContext.user, menu, module])

    useEffect(() => {
        if (authContext.user) {
            setActiveModule(processDefaultModule(authContext.user))
        }
    }, [authContext.user, setActiveModule])

    const contextValue = useMemo(() => ({
        module,
        modules: getModules(),
        mainMenu: getModules().find(item => item.key === module)?.items || [],
        setActiveModule
    }), [module, getModules, setActiveModule])

    return (
        <Context.Provider value={contextValue}>
            {children}
        </Context.Provider>
    )
}

const useMenu = () => useContext(Context)

export {
    MenuProvider,
    useMenu
}