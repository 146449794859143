import ItemSelector from "../../../../components/ItemsSelector/ItemsSelector";

export const BillingClientsList = ({ billingClients, title, onSelection }) => {
  return (
    <ItemSelector
      title={title}
      items={billingClients.map((billingClient) => ({
        title: billingClient.name,
        subtitle: billingClient.address,
        value: billingClient
      }))}
      onItemSelected={onSelection}
    />
  )
}
