import { useTranslation } from "react-i18next"
import { Field } from "formik"
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import { FormHelperText } from "@mui/material" 
import { isDate } from "lodash";

function getDateFormatString(locale) {
  const formatObj = new Intl.DateTimeFormat(locale).formatToParts(new Date());

  return formatObj
    .map((obj) => {
      switch (obj.type) {
        case "day":
          return "dd";
        case "month":
          return "MM";
        case "year":
          return "yyyy";
        default:
          return obj.value;
      }
    })
    .join("");
}

const DatePickerField = ({ field, form, ...props }) => {
  const { i18n } = useTranslation();
  const currentError = form.errors[field.name];

  return (
    <DatePicker
      openTo="day"
      views={["year", "month", "day"]}
      format={getDateFormatString(i18n.language)}
      value={field.value || null}
      helperText={currentError}
      error={Boolean(currentError)}
      onError={(error) => {
        if (error !== currentError) {
          form.setFieldError(field.name, error);
        }
      }}
      slotProps={{
        textField: {
          size: "small",
          className: "dateField",
          InputLabelProps: { shrink: true },
          required: props.required
        }
      }}
      onChange={(date) => {
        form.setFieldValue(
          field.name,
          date
        )
      }
      }
      {...props}
    />
  )
}


const validate = (t, value, required) => {
  if (required && (!isDate(value) || isNaN(value))) {
    return t("common.form.validation.invalid_date")
  }
  return null
}

const DateFormField = ({ field, value }) => {
  const { t, i18n } = useTranslation("vbms")
  const mainField = field
  return (
    <Field
      name={field.id}
      label={field.label}
      required={field.required}
      validate={(value) => validate(t, value, field.required)}
    >
    {({
        field,
        form,
        meta,
    }) => (
        <>
          <DatePickerField 
            field={field}
            form={form}
            name={mainField.id}
            label={mainField.label}
            required={mainField.required}
          />

        {meta.error && meta.touched && <FormHelperText error={true}>{meta.error}</FormHelperText>}
        </>            
      )}
    </Field>
  )
}

export default DateFormField
