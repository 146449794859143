import { useTranslation } from 'react-i18next';
import SuggestionCard from '../../../components/SuggestionCard/SuggestionCard';

const SuggestionAddPage = () => {
    const { t } = useTranslation("vbms")

    return (
        <SuggestionCard title={t("suggestions.form.title")} />
    )
}

export default SuggestionAddPage