import { useTranslation } from "react-i18next"
import AsyncEntityForm from "../../../../../components/form/AsyncEntityForm"
import { createFormFieldset, createField } from '../../../../../components/helpers/fieldHelper'
import constants from '../../../../../components/entity/constants'
import { DialogContentText } from "@mui/material"
import { useState } from "react"
import { Alert } from "@mui/material"

const BookingSetVoucher = ({ selection, onAction, onCancel }) => {
    const { t } = useTranslation("vbms")
    const fetcher = () => Promise.resolve(selection)

    const formContent = [
        createFormFieldset(null, [
            createField(
                "order.voucher_id",
                t("bookings.fields.order.voucher"),
                constants.TEXT_TYPE
            ),
        ])
    ]

    const onConfirm = (result) => {
        onAction({
            voucher_id: result.order.voucher_id
        })
    }

    return (
        <>
            <DialogContentText>{t("bookings.actions.set_voucher.modal_content", { "locator": selection.locator})}</DialogContentText>
            <AsyncEntityForm
                fetcher={fetcher}
                onSave={onConfirm}
                formContent={formContent}
            />
        </>
    )
}

export default BookingSetVoucher
