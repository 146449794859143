import { TreeItem } from '@mui/x-tree-view';
import _isArray from 'lodash/isArray';

export const buildTreeItems = (value) => {
    return value.map((item) => {
        if (_isArray(item.value)) {
            return (
                <TreeItem key={item.id} nodeId={item.id.toString()} label={item.name}>
                    {buildTreeItems(item.value)}
                </TreeItem>
            )
        }

        return (
            <TreeItem key={item.id} nodeId={`p_${item.id.toString()}`} label={item.name} value={item.id} />
        )
    })
}

export const getNodesToExpand = (value, expandMode) => {
    const expand = []

    if (!_isArray(value) || value.length === 0 || expandMode === 'collapse_all') {
        return expand
    }

    if (expandMode === 'expand_all') {
        value.forEach((item) => {
            expand.push(item.id)
            if (_isArray(item.value)) {
                expand.push(...getNodesToExpand(item.value, expandMode))
            }
        })
        return expand
    }

    return expand
}

export const getSelectedNodesToExpand = (permissions, selectedNodes) => {
    const expand = []

    if (selectedNodes) {
        permissions.forEach((module) => {
            module.value.forEach((submodule) => {
                submodule.value.forEach((permission) => {
                    if (selectedNodes.filter((value) => value.id === permission.id).length > 0) {
                        if (!expand.includes(submodule.id)) {
                            expand.push(submodule.id)
                        }
                        if (!expand.includes(module.id)) {
                            expand.push(module.id)
                        }
                        return expand
                    }
                })
                return expand
            })
            return expand
        })
    
    }

    return expand
}