import { useTranslation } from 'react-i18next'
import constants from '../../../../../components/entity/constants'
import { createField, createCallbackField, createIconField, createCurrencyField } from '../../../../../components/helpers/fieldHelper';
import ArrayDataTable from '../../../../../components/Datatable/ArrayDataTable'
import _isEmpty from 'lodash/isEmpty'
import _get from 'lodash/get'
import _has from 'lodash/has'
import { useVolcanoApiClient } from '../../../../../context/VolcanoApiClientProvider'
import { useAsyncContent } from '../../../../../context/async.context'
import CancelIcon from '@mui/icons-material/Cancel'
import VisibilityIcon from '@mui/icons-material/Visibility';
import { useVolcanoAuth } from '../../../../../context/VolcanoAuthContext';

const BookingRefunds = (props) => {

    const { fetchContent } = useAsyncContent()

    const { t } = useTranslation("vbms")

    const { apiClient } = useVolcanoApiClient()
    const volcanoAuth = useVolcanoAuth()

    const columns = [
        createIconField(
            "id",
            VisibilityIcon,
            t("common.actions.view.button"),
            {
                route: "refunds",
                field: "id",
            },
            true,
            () => volcanoAuth.user.hasPermission('administer-any-booking-refunds'),
        ),
        createCallbackField(
            "booking.order",
            t("refunds.fields.transaction_id"),
            constants.STRING_TYPE,
            (value) => {
                if (_isEmpty(_get(value, "main_payment_transaction.remote.remote_id"))) {
                    if (_isEmpty(_get(value, "tpvid"))) {
                        return "Error"
                    } else {
                        return value.tpvid
                    }
                }

                return value.main_payment_transaction.remote.remote_id
            }
        ),
        createField(
            "type.name",
            t("refunds.fields.type_name"),
            constants.STRING_TYPE
        ),
        createField(
            "state.name",
            t("refunds.fields.state_name"),
            constants.STRING_TYPE
        ),
        createField(
            "created",
            t("refunds.fields.created"),
            constants.DATETIME_TYPE
        ),
        createField(
            "last_action.modified",
            t("refunds.fields.modified"),
            constants.DATETIME_TYPE
        ),
        createCurrencyField(
            createField(
                "cost",
                t("refunds.fields.cost"),
                constants.CURRENCY_TYPE
            ),
            "currency_code",
            "main_currency_code",
            "exchange_rate"
        ),
        createCurrencyField(
            createField(
                "amount",
                t("refunds.fields.amount"),
                constants.CURRENCY_TYPE
            ),
            "currency_code",
            "main_currency_code",
            "exchange_rate"
        )
    ]

    const actions = [
        {
            id: "cancel_refund",
            title: t("bookings.actions.cancel_refund.button"),
            scope: 'row',
            showLoading: true,
            icon: CancelIcon,
            onExecute: (refund, progressHandler, onSuccess, onError) => {
                return apiClient.refund
                    .cancelRefund(refund.id)
                    .then(() => {
                        return fetchContent()
                    })
            },
            condition: (refund) => _has(refund.getActions(), "cancel_refund")
        }
    ]

    return (
        <ArrayDataTable
            data={props.data}
            columns={columns}
            actions={actions}
        />
    )
}

export default BookingRefunds