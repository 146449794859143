import { Box, Card, CardActions, CardContent, Checkbox, alpha } from "@mui/material"
import _isArray from "lodash/isArray"
import _isFunction from "lodash/isFunction"
import _get from "lodash/get"
import BaseActionsGroup from "../../action/BaseActionsGroup"
import { filterAvailableActions } from "../../action/utils"
import DatasetEntityFacet from '../../entity/DatasetEntityFacet';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next'
import { useNavigate } from "react-router-dom"

const CardRowActions = ({ row, actions, columns }) => {
    const navigate = useNavigate()

    const processRowActions = useCallback((row) => {
        const availableActions = filterAvailableActions(actions, row)

        if (columns) {
            availableActions.unshift(
                ...columns.filter((column) => {
                    return column.isAction
                }).map((column) => {
                    column.scoope = "row"

                    return column
                })
            )
        }

        return availableActions
    }, [actions, columns])

    const availableActions = processRowActions(row)

    return (
        <>
            {availableActions && availableActions.length > 0 && (
                <CardActions sx={{ justifyContent: "flex-end", marginBottom: -6 }}>
                    <BaseActionsGroup selection={row} actions={availableActions} actionsNumberMobile={2} navigate={navigate} />
                </CardActions>
            )}
        </>
    )
}

const CardListBody = (props) => {
    const {
        withSelection,
        columns,
        actions,
        rows,
        selected,
        onRowSelect,
        highlight,
        numSelected,
        onSelectAllClick
    } = props

    const { t } = useTranslation("vbms")

    const rowCount = rows.length

    const isSelected = (id) => selected.indexOf(id) !== -1

    const filterFields = (columns, entity) => {
        if (!_isArray(columns)) {
            return []
        }

        return columns.filter((column) => {
            const value = _get(entity, column.id, null)

            return !column.isAction && value !== null
        })
    }

    return (
        <>
            {withSelection && (
                <Box
                    display="flex"
                    justifyContent="flex-start"
                    alignItems="center"
                    sx={{ paddingLeft: 2 }}
                >
                    <Box>
                        <Checkbox
                            indeterminate={numSelected > 0 && numSelected < rowCount}
                            checked={rowCount > 0 && numSelected === rowCount}
                            onChange={onSelectAllClick}
                            inputProps={{ "aria-label": t("table.select_all") }}
                        />
                    </Box>
                    <Box>{t("table.select_all")}</Box>
                </Box>
            )}
            {rows.length === 0 && (
                <Box sx={{ color: "info.main" }}>
                    {t("table.no_data")}
                </Box>
            )}
            {rows.map((row, index) => {
                const isItemSelected = isSelected(row.id)
                const labelId = `enhanced-table-checkbox-${row.id}`

                const columnIds = {}

                const rowBgColor = _isFunction(highlight) ? highlight(row) : null

                return (
                    <Card
                        key={row.id + "-" + columnIds[row.id]++}
                        variant="outlined"
                        sx={{
                            marginBottom: 2,
                            ...isItemSelected && {
                                backgroundColor: (theme) =>
                                    alpha(theme.palette.primary.main, theme.palette.action.activatedOpacity),
                            },
                        }}
                    >
                        <CardRowActions row={row} actions={actions} columns={columns} />
                        <CardContent
                            // role="checkbox"
                            // aria-checked={isItemSelected}
                            // tabIndex={-1}
                            // selected={isItemSelected}
                            sx={{
                                bgcolor: rowBgColor,
                                marginBottom: -2,
                            }}
                        >
                            <Box
                                display="flex"
                                justifyContent="flex-start"
                                alignItems="flex-start"
                            >
                                <Box>
                                    {withSelection && (
                                        <Checkbox
                                            checked={isItemSelected}
                                            inputProps={{ "aria-labelledby": labelId }}
                                            onClick={(event) => onRowSelect(event, row.id)}
                                        />
                                    )}
                                </Box>
                                <Box>
                                    <DatasetEntityFacet
                                        key={index}
                                        fields={filterFields(columns, row)}
                                        entity={row}
                                        variant="default"
                                    />
                                </Box>
                            </Box>
                        </CardContent>
                    </Card >
                )
            })}
        </>
    )
}

export default CardListBody