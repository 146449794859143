import { useTranslation } from 'react-i18next';
import constants from '../../../../../components/entity/constants';
import { createField, createCallbackField } from '../../../../../components/helpers/fieldHelper';
import ArrayDataTable from '../../../../../components/Datatable/ArrayDataTable';
import { Link } from "@mui/material"
import HtmlFieldValue from '../../../../../components/entity/HtmlFieldValue';

const BookingHistory = (props) => {
    const { t } = useTranslation("vbms")

    const columns = [
        createField(
            "order_log_entry_type.id",
            t("bookings.fields.history.id"),
            constants.STRING_TYPE
        ),
        createField(
            "order_log_entry_type.name",
            t("bookings.fields.history.type_name"),
            constants.STRING_TYPE
        ),
        createField(
            "created",
            t("bookings.fields.history.created"),
            constants.DATETIME_TYPE
        ),
        createCallbackField(
            "description",
            t("bookings.fields.history.description"),
            constants.STRING_TYPE,
            (value, entity) => {
                if (entity.order_log_entry_type.id === 'related_booking_created') {
                    return <div>{t("bookings.fields.history.related_booking")} <Link href={"/orders/bookings/" + entity.data.booking_id}>{entity.data.booking_locator}</Link></div>
                } else {
                    return <HtmlFieldValue value={value}/>
                }
            }
        ),
        createField(
            "user.name",
            t("bookings.fields.history.username"),
            constants.STRING_TYPE,
            null,
            () => !props.hideUserColumn
        ),
    ]

    return (
        <ArrayDataTable
            data={props.data}
            columns={columns}
        />
    )
}

export default BookingHistory