import _isArray from "lodash/isArray"
import _isFunction from "lodash/isFunction"
import BasePage from "../BasePage"
import FacetsContainer from "../../../components/Display/FacetsContainer"
import { EntityContextProvider } from "../../../components/entity/context/entity/entity.context"
import DatasetEntityFacet from "../../../components/entity/DatasetEntityFacet"
import EntityField from "../../../components/entity/EntityField"
import ObjectStatusFacet from "../../../components/entity/ObjectStatusFacet"
import { filterAvailableActions } from "../../../components/action/utils"

const EntityPage = (props) => {
    const { entity, title, subtitle, actions, shareActions, headerContent, tabNavigationItems, goBackHandler } = props

    if (!entity) {
        // todo: skeleton
        return null
    }

    const processContents = (contents) => {
        if (!_isArray(contents)) {
            return null
        }

        return contents
            .filter((content) =>
                _isFunction(content.options.condition) ? content.options.condition(entity) : true
            )
            .map((content, index) => {
                switch (content.facet) {
                    case "container":
                        return (
                            <FacetsContainer key={index}>
                                {processContents(content.options.fields)}
                            </FacetsContainer>
                        )
                    case "dataset":
                        return (
                            <DatasetEntityFacet
                                key={index}
                                title={content.options.title}
                                fields={content.options.fields}
                                entity={entity}
                            />
                        )
                    case "object_status":
                        return (
                            <ObjectStatusFacet
                                key={index}
                                size={content.options.size}
                                field={content.options.field}
                                entity={entity}
                            />
                        );
                    default:
                        return null
                }
            })
    }

    return (
        <EntityContextProvider entity={entity}>
            <BasePage
                title={<EntityField field={title} entity={entity} />}
                subtitle={subtitle && <EntityField field={subtitle} entity={entity} />}
                actions={filterAvailableActions(actions, entity)}
                shareActions={filterAvailableActions(shareActions, entity)}
                headerContent={processContents(headerContent)}
                tabNavigationItems={tabNavigationItems}
                goBackHandler={goBackHandler}
            >
                {props.children}
            </BasePage>
        </EntityContextProvider>
    )
}

export default EntityPage
