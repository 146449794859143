import availableRoutes from "../../../routes/availableRoutes"
import { getActions as getRowActions } from "../TagPage/TagPage.actions"

export const getActions = (t, navigate, user) => [
    {
        title: t("common.create"),
        onExecute: () => {
            navigate(availableRoutes.tags_add.path)
        },
        condition: () => user.hasAnyPermission([
            'access-any-enterprise',
            'administer-tags-system',
            'administer-tags'
        ])
    },
]


export const getTableActions = (...props) => getRowActions(...props)