import { useTranslation } from "react-i18next"
import { useVolcanoApiClient } from "../../../context/VolcanoApiClientProvider"
import { useVolcanoAuth } from "../../../context/VolcanoAuthContext"
import CollectionPage from "../../../layout/templates/CollectionPage/CollectionPage"
import { getTableColumns } from './InvoicesCollection.columns'
import { getTableFilter } from './InvoicesCollection.filter'
import { getTableExport } from './InvoicesCollection.export'
import { getActions, getTableActions } from "./InvoicesCollection.actions"
import { useNavigate } from 'react-router-dom';
import { useCache } from '../../../context/cache.context';
import _get from 'lodash/get';
import constants from '../../../components/entity/constants';
import _isDate from 'lodash/isDate';
import _set from 'lodash/set';
import { format as formatDate } from 'date-fns';

const InvoicesCollectionPage = () => {
    const { apiClient } = useVolcanoApiClient()
    const { t } = useTranslation("vbms")
    const volcanoAuth = useVolcanoAuth()
    const navigate = useNavigate()
    const cache = useCache()

    const filterConfig = getTableFilter(apiClient, t, volcanoAuth.user, cache)

    const table = {
        actions: getTableActions(apiClient, t, volcanoAuth.user),
        highlight: (invoice) => {
            switch (invoice.state) {
                case "unpaid": return "background.error.main"
                case "issued": return "background.error.main"
                default: return null
            }
        },
        columns: getTableColumns(t, volcanoAuth),
        fetcher: (params) => {
            filterConfig
                .filter(field => field.type === constants.DATE_TYPE)
                .forEach(field => {
                    const date = _get(params, field.id)
                    if (_isDate(date)) {
                        _set(params, field.id, formatDate(date, "yyyy-MM-dd"))
                    }
                })

            let queryFilter = {
                ...params
            }
            if (Array.isArray(params.collaborator_id)) {

                const business = [];
                const persons = [];
                params.collaborator_id.forEach(collaborator => {
                    if (collaborator.entity.type === 'business') {
                        business.push(collaborator.id);
                    } else {
                        persons.push(collaborator.id);
                    }
                });

                queryFilter = {
                    ...params,
                    billing_client_id: persons,
                    crm_intermediary_id: business
                }

                delete queryFilter.collaborator_id;
            }

            return apiClient.invoice.getInvoices(queryFilter);
        },
        onExport: getTableExport(apiClient, t),
    };

    return (
        <CollectionPage
            title={t("invoices.title")}
            subtitle={t("invoices.subtitle")}
            actions={getActions(t, navigate, volcanoAuth.user)}
            table={table}
            filterConfig={filterConfig}
            onExport={getTableExport(apiClient, t, filterConfig)}
        />
    );
};

export default InvoicesCollectionPage;
