
import { Formik } from 'formik'
import { EntityContextProvider } from '../entity/context/entity/entity.context'
import EntityForm from './EntityForm'
import { initFormValues } from './utils'
import { useTranslation } from 'react-i18next'
import { Box, DialogActions } from '@mui/material'
import { useDialog } from '../Dialog/dialog.context'
import _isFunction from 'lodash/isFunction'
import FormObserver from './FormObserver'
import EntityActionsGroup from '../action/EntityActionsGroup'

const FormButtons = ({ actions }) => {
    return (
        <DialogActions>
            <Box
                display="flex"
                pt={2}
                pb={1}
            >
                <EntityActionsGroup actions={actions} />
            </Box>
        </DialogActions>
    )
}

const FormikEntityForm = ({ entity, formContent, acceptButtonTitle, variant, onSave, onChange, onClose }) => {
    const { t } = useTranslation("vbms")
    const { handleClose } = useDialog()

    const initialValues = initFormValues(entity, formContent)

    return (
        <EntityContextProvider entity={entity}>
            <Formik initialValues={initialValues} onSubmit={async (values) => onSave(values)}>
                {({ isValid, submitForm, isSubmitting }) => {
                    const actions = [
                        {
                            id: "save",
                            title: acceptButtonTitle ? acceptButtonTitle : t("common.save"),
                            onExecute: () => {
                                if (isValid) {
                                    handleClose()
                                    return submitForm()
                                }
                            },
                        },
                        {
                            id: "cancel",
                            title: t("common.cancel"),
                            onExecute: () => {
                                if (onClose) {
                                    onClose()
                                } else {
                                    handleClose()
                                }
                            },
                        }
                    ]

                    return (
                        <Box>
                            {_isFunction(onChange) && <FormObserver onChange={onChange} />}
                            <EntityForm
                                entity={entity}
                                formContent={formContent}
                                variant={variant ? variant : "dialog"}
                            />
                            {_isFunction(onSave) && <FormButtons actions={actions} />}
                        </Box>
                    )
                }}
            </Formik>
        </EntityContextProvider>
    )
}

export default FormikEntityForm