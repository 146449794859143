import { Stack } from "@mui/material";
import { useEffect, useState } from "react";
import { t } from "i18next";
import SimpleLoading from "../../../../../components/Display/SimpleLoading";
import { SelectedSessionsProduct } from "./NotificationRecipients/NotificationRecipients.functions";


const NotificationSessions = ({ products }) => {

    const [selectedSessions, setSelectedSessions] = useState([])

    const [loading, setLoading] = useState(true)

    useEffect(() => {
        setSelectedSessions(products.sort((a, b) => a.name.localeCompare(b.name)))
        setLoading(false)
    }, [products])

    return (
        <><SimpleLoading loading={loading} />
        <Stack spacing={2}>
            {(!loading && selectedSessions.map((productSelectedSession) => {
                return (
                    <SelectedSessionsProduct
                        key={'csp_'+productSelectedSession.id}
                        product={productSelectedSession}
                        t={t}
                    />
                )
            }))}
        </Stack>
        </>
    )
}
 
export default NotificationSessions;