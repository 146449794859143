import { useTranslation } from "react-i18next";
import { getRecipientsPrepareActions } from "./NotificationRecipients.functions";
import { createBooleanFormField, createCallbackField, createField, createIconField, createLanguagesMapping, createLinkField, createSelectFormField, languagesCode2 } from "../../../../../../components/helpers/fieldHelper";
import constants from "../../../../../../components/entity/constants";
import { Box } from "@mui/material";
import { useVolcanoApiClient } from "../../../../../../context/VolcanoApiClientProvider";
import { useNavigate } from "react-router-dom";
import { useVolcanoAuth } from "../../../../../../context/VolcanoAuthContext";
import VisibilityIcon from '@mui/icons-material/Visibility';
import CollectionDataTable from "../../../../../../components/Datatable/CollectionDataTable";
import _get from "lodash/get"
import _isArray from "lodash/isArray"
import { getProductsFilter } from "../../../../../../lib/collection-filters";
import { useState } from "react";

const getTableFilter = (apiClient, siteConfig, t, user) => ([
    createField(
        "locator",
        t("notifications.filter.locator"),
        constants.TEXT_TYPE
    ),
    getProductsFilter(apiClient, t, user, true, { state: "active", site_id:  siteConfig.site_id}),
    createField(
        "name",
        t("notifications.fields.booking.name"),
        constants.TEXT_TYPE
    ),
    createField(
        "email",
        t("notifications.fields.email"),
        constants.TEXT_TYPE
    ),
    createField(
        "phone",
        t("notifications.fields.phone"),
        constants.TEXT_TYPE
    ),
    createBooleanFormField(
        t,
        "no_send",
        t("notifications.fields.send")
    ),
    createSelectFormField(
        "languages",
        t("notification_templates.fields.recipients.language"),
        Object.entries(createLanguagesMapping(t, languagesCode2(), true))
            .map(([key, value]) => (
                {
                    value: key,
                    label: value
                }
            )),
    ),
])

const getTableColumns = (t) => ([
    createLinkField(
        createField(
            "bar_code",
            t("notifications.fields.booking.locator"),
            constants.TEXT_TYPE
        ),
        {
            route: "bookings",
            field: "recipient_data_id",
        }
    ),
    createField(
        "product",
        t("bookings.fields.product"),
        constants.STRING_TYPE
    ),
    createField(
        "experience",
        t("bookings.fields.experience"),
        constants.STRING_TYPE
    ),
    createField(
        "name",
        t("notifications.fields.booking.name"),
        constants.STRING_TYPE
    ),
    createField(
        "full_reservation_date",
        t("bookings.fields.booking_date"),
        constants.STRING_TYPE
    ),
    createField(
        "email",
        t("notifications.fields.email"),
        constants.STRING_TYPE
    ),
    createField(
        "phone",
        t("notifications.fields.phone"),
        constants.STRING_TYPE
    ),
    createField(
        "language",
        t("notifications.fields.language"),
        constants.STRING_TYPE,
        createLanguagesMapping(t),
    ),
    createCallbackField(
        "send",
        t("notifications.fields.send"),
        constants.STRING_TYPE,
        (value) => <Box>{(value) ? t("common.yes") : t("common.no")}</Box>,
    ),
    createIconField(
        "id",
        VisibilityIcon,
        t("common.actions.preview.button"),
        {
        },
        true
    ),
])

const NotificationRecipientsPrepareBookings = ({ defaultParams, details }) => {
    const { t } = useTranslation("vbms");
    const { apiClient, siteConfig } = useVolcanoApiClient()
    const navigate = useNavigate()
    const volcanoAuth = useVolcanoAuth()

    const entityId = useState(defaultParams.notification_id)

    const actions = getRecipientsPrepareActions(entityId, details, 'booking', t, apiClient, volcanoAuth.user, navigate)

    const filterConfig = getTableFilter(apiClient, siteConfig, t, volcanoAuth.user)


    return (
        <CollectionDataTable
            fetcher={(query) => {

                let productsFilter = [];
                if (_get(query, "product_id", null)) {
                    if (_isArray(_get(query, "product_id", null))) {
                        _get(query, "product_id", null).forEach(prd => {
                            productsFilter.push(prd.id)
                        });
                    } else {
                        productsFilter.push(_get(query, "product_id", null));
                    }
                }

                const params = {
                    ...defaultParams,
                    ...query,
                    product_id: productsFilter
                }

                return apiClient.notifications.getRecipients(defaultParams.notification_id, params)
            }}
            selectionMode="multiple"
            columns={getTableColumns(t)}
            actions={actions}
            filterConfig={filterConfig}
            highlight= {(entity) => entity.send ? null : "background.warning.main"}
        />
    )

}

export default NotificationRecipientsPrepareBookings