import startOfMonth from "date-fns/startOfMonth"
import sub from "date-fns/sub"
import constants from "../../../components/entity/constants"
import { createField, createFormField, createFormFieldOption, createLiquidationStateMapping, createSelectFormField } from "../../../components/helpers/fieldHelper"
import { getIntermediariesClientsFilter } from '../../../lib/collection-filters'

export const getTableFilter = (apiClient, t, user, cache) => ([
    createField(
        "year",
        t("liquidations.filter.year"),
        constants.NUMERIC_TYPE
    ),
    createField(
        "number",
        t("liquidations.filter.number"),
        constants.NUMERIC_TYPE
    ),
    createField(
        "locator",
        t("liquidations.filter.locator"),
        constants.STRING_TYPE
    ),
    createSelectFormField(
        "status",
        t("liquidations.filter.status"),
        [createFormFieldOption("", t("table.all"))].concat(
            Object.entries(createLiquidationStateMapping(t))
                .map(([key, value]) => createFormFieldOption(key, value))
        )
    ),
    getIntermediariesClientsFilter(apiClient, t, user, 'collaborator_id'),
    createFormField(
        createField(
            "creation_date_from",
            t("liquidations.filter.liquidation_date_from"),
            constants.DATE_TYPE
        ),
        false,
        startOfMonth(sub(new Date(), { months: 1 }))
    ),
    createField(
        "creation_date_to",
        t("liquidations.filter.liquidation_date_to"),
        constants.DATE_TYPE
    )
])