import { createContext, useContext } from "react"
import Loading from "../../../Display/Loading"

const Context = createContext()

const EntityContextProvider = ({ entity, children }) => {
  return (
    <Context.Provider value={entity}>
      {!entity ? <Loading open={!entity} /> : children}
    </Context.Provider>
  )
}

const useEntityContext = () => useContext(Context)

export {
  useEntityContext,
  EntityContextProvider,
}