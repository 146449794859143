import { Box, CircularProgress, Typography } from "@mui/material"

const CircularProgressWithLabel = (props) => {
  return (
    <Box position="relative" display="inline-flex">
      <CircularProgress variant="determinate" {...props} />
      <Box
        top={0}
        left={0}
        bottom={0}
        right={0}
        position="absolute"
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        <Typography
          variant="caption"
          component="div"
          className="progress"
        >{`${Math.round(props.value)}%`}</Typography>
      </Box>
    </Box>
  )
}

const LoadingInternal = ({ title, progress }) => {
  return (
    <Box>
      <Box
        justifyContent="center"
        alignItems="center"
        display="flex"
      >
        {progress >= 0 ?
          <CircularProgressWithLabel color="inherit" value={progress} /> :
          <CircularProgress color="inherit" />
        }
      </Box>
      <Box mt={2}>
        {title && <Typography variant="h6" component="h2" className="title">{title}</Typography>}
      </Box>
    </Box>
  )
}

export default LoadingInternal
