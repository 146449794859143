import { createTabBarItem } from '../../../components/helpers/fieldHelper';
import TagExperiences from './components/tabs/TagExperiences';
import TagProducts from './components/tabs/TagProducts';

export const getTabNavigationItems = (t) => [
    createTabBarItem(
        t("tags.view.navbar.experiences"),
        TagExperiences,
    ),
    createTabBarItem(
        t("tags.view.navbar.products"),
        TagProducts,
    ),
];