import constants from "../../../../components/entity/constants";
import {
    createDataSetFacet,
    createField
} from "../../../../components/helpers/fieldHelper";


export const getHeaderContent = (t) => [
    createDataSetFacet(t("pickup_configs.view.facet_information"), [
        createField("id", "Id", constants.STRING_TYPE),
        createField(
            "name",
            t("pickup_configs.fields.name"),
            constants.STRING_TYPE
        ),
        createField(
            "description",
            t("pickup_configs.fields.description"),
            constants.STRING_TYPE
        ),
    ]),
];
