import { useTranslation } from "react-i18next"
import { createFormFieldOptions } from "../helpers/fieldHelper"
import SelectFormField from "./SelectFormField"

const BooleanFormField = ({ field, value, isFilter }) => {
    const { t } = useTranslation("vbms")

    if (isFilter) {
        field = createFormFieldOptions(field, [
            ["", t("table.all")],
            [true, t("common.yes")],
            [false, t("common.no")]
        ])
    } else {
        if (field.emptyValue) {
            field = createFormFieldOptions(field, [
                ["", "( - )"],
                [true, t("common.yes")],
                [false, t("common.no")]
            ])
        } else {
            field = createFormFieldOptions(field, [
                [true, t("common.yes")],
                [false, t("common.no")]
            ])
        }
    }

    return (
        <SelectFormField field={field} value={value} />
    )
}

export default BooleanFormField