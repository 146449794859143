import constants from "../../../components/entity/constants"
import { createField, createCallbackField, createLinkField } from '../../../components/helpers/fieldHelper'

export const getTableColumns = (t) => ([

    createLinkField(
        createField(
            "slug",
            t("tags.fields.slug"),
            constants.TEXT_TYPE
        ),
        {
            route: "tags",
            field: "id",
        }
    ),
    createCallbackField(
        "name",
        t("tags.fields.name"),
        constants.STRING_TYPE,
        (value) => value ?? t("tags.constants.no_name")
    ),
    createCallbackField(
        "site",
        t("tags.fields.site"),
        constants.STRING_TYPE,
        (value) => value.name
    ),
    createField(
        "visible",
        t("tags.fields.visible"),
        constants.BOOLEAN_TYPE
    ),
    createField(
        "protected",
        t("tags.fields.protected"),
        constants.BOOLEAN_TYPE
    ),
    createField(
        "created",
        t("common.fields.created"),
        constants.DATETIME_TYPE
    ),
    createCallbackField(
        "sort_order",
        t("tags.fields.sort_order"),
        constants.NUMERIC_TYPE,
        (value) => parseInt(value) || 0
    )
])