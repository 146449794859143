import constants from '../../../components/entity/constants';
import { createDataSetFacet, createFacetField, createField, createObjectStatusField } from '../../../components/helpers/fieldHelper';

export const getHeaderContent = (t, user) => [
    createDataSetFacet(t("settings_roles.view.facet_information"), [
        createField("id", "Id", constants.STRING_TYPE),
        createField(
            "name",
            t("settings_roles.fields.name"),
            constants.STRING_TYPE
        ),
        createField(
            "slug",
            t("settings_roles.fields.slug"),
            constants.STRING_TYPE
        ),
        createField(
            "created",
            t("settings_roles.fields.created"),
            constants.DATETIME_TYPE
        ),
    ]),
    createFacetField("object_status", {
        size: "large",
        field: createObjectStatusField(
            createField(
                "is_system",
                t("settings_roles.fields.is_system"),
                constants.BOOLEAN_TYPE
            ),
            null,
            (value) => {
                switch (value) {
                    case false:
                        return "negative";
                    case true:
                        return "positive";
                    default:
                        return "neutral";
                }
            }
        ),
    }),
    createFacetField("object_status", {
        size: "large",
        field: createObjectStatusField(
            createField(
                "corporate_account_available",
                t("settings_roles.fields.corporate_account_available"),
                constants.BOOLEAN_TYPE
            ),
            null,
            (value) => {
                switch (value) {
                    case false:
                        return "negative";
                    case true:
                        return "positive";
                    default:
                        return "neutral";
                }
            }
        ),
    }),
];