import { Navigate, Outlet } from "react-router-dom"

const ProtectedRoute = ({ component: Component, isAllowed, redirectPath = '/login', children }) => {
    if (!isAllowed) {
        return <Navigate to={redirectPath} replace />
    }

    return (<Component>{children ? children : <Outlet />}</Component>)
}

export const ProtectedPublicRoute = ({ component: Component, isAllowed, redirectPath = '/login' }) => {
    if (!isAllowed) {
        return <Navigate to={redirectPath} replace />
    }
    return <Component />
}

export default ProtectedRoute