import { createTabBarItem } from '../../../../components/helpers/fieldHelper';
import OfficeUsers from './components/tabs/OfficeUsers'
import OfficeSales from './components/tabs/OfficeSales'

export const getTabNavigationItems = (t) => [
    createTabBarItem(
        t("intermediaries.offices.view.navbar.users"),
        OfficeUsers,
    ),
    createTabBarItem(
        t("intermediaries.offices.view.navbar.sales"),
        OfficeSales
    ),
];