import { Link } from "@mui/material"

const StringFieldValue = ({ value, format }) => {
  format = format || "default"

  switch (format) {
    case "email":
      return <Link href={"mailto:" + value} target="_top">{value}</Link>
    case "phone":
      return <Link href={"tel:" + value} target="_top">{value}</Link>
    default:
      return <>{value}</>
  }
}

export default StringFieldValue
