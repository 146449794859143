import { useTranslation } from "react-i18next"
import constants from "../../../components/entity/constants"
import { useVolcanoApiClient } from "../../../context/VolcanoApiClientProvider"
import { useNavigate, useParams } from "react-router-dom"
import { createCallbackField } from "../../../components/helpers/fieldHelper"
import AsyncEntityPage from "../../../layout/templates/AsyncContentPage/AsyncEntityPage"
import { getActions } from './NotificationTemplatePage.actions';
import { getHeaderContent } from './NotificationTemplatePage.header';
import { getTabNavigationItems } from "./NotificationTemplatePage.tabs"
import { useVolcanoAuth } from "../../../context/VolcanoAuthContext"
import useSnackBars from "../../../components/Snackbar/snack-bar.context"
import { useEffect, useState } from "react"


const NotificationTemplatePage = (props) => {
    const { t } = useTranslation();
    const { entityId } = useParams()
    const { apiClient } = useVolcanoApiClient()
    const volcanoAuth = useVolcanoAuth()
    const navigate = useNavigate()
    const { addAlert } = useSnackBars()

    const fetcher = () => apiClient.notifications.getNotificationTemplate(entityId)

    const [legend, setLegend] = useState(false)

    useEffect(() => {
        apiClient.notifications.getLegend().then((legend) => {
            setLegend(legend)
        })
    }, [setLegend, apiClient.notifications])

    return (
        <AsyncEntityPage 
            fetcher={fetcher}
            title={createCallbackField(
                "name",
                t("notification_templates.view.title"),
                constants.STRING_TYPE,
                (value) => {
                    return t("notification_templates.view.title", { name: value });
                }
            )}
            headerContent={getHeaderContent(t, volcanoAuth.user)}
            actions={getActions(volcanoAuth.user, apiClient, entityId, t, navigate, addAlert)}
            tabNavigationItems={getTabNavigationItems(legend, t, apiClient, volcanoAuth, navigate)}
        />
    );
};

export default NotificationTemplatePage;