import constants from "../../../../components/entity/constants";
import { createCallbackField, createField, createLinkField } from "../../../../components/helpers/fieldHelper";

export const getTableColumns = (t, intermediaryUser) => ([
    createLinkField(
        createField(
            "name",
            t("intermediaries.offices.fields.name"),
            constants.TEXT_TYPE
        ),
        {
            route: "intermediary_offices",
            field: "id",
        }
    ),
    createLinkField(
        createField(
            "intermediary.name",
            t("intermediaries.offices.fields.intermediary"),
            constants.TEXT_TYPE,
            null,
            () => !intermediaryUser
        ),
        {
            route: "intermediaries",
            field: "intermediary.id",
        }
    ),
    createCallbackField(
        "state",
        t("intermediaries.offices.fields.active"),
        constants.BOOLEAN_TYPE,
        (state) => state === "active"
    ),
    createField(
        "contact.contact_details.email",
        t("common.fields.contact_details.email"),
        constants.EMAIL_TYPE
    ),
    createField(
        "contact.contact_details.phone",
        t("common.fields.contact_details.phone"),
        constants.PHONE_TYPE
    ),
    createField(
        "created",
        t("common.fields.created"),
        constants.DATETIME_TYPE
    ),
])