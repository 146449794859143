import { Grid } from "@mui/material"
import { Form } from "formik"
import _isArray from "lodash/isArray"
import _isFunction from "lodash/isFunction"
import FormFieldset from "./FormFieldset"

const EntityForm = ({ entity, formContent, variant, actionsOnSubmit }) => {
    variant = variant || "default"

    const processForm = (contents) => {
        if (!_isArray(contents)) {
            return null;
        }

        return contents
            .filter((content) =>
                _isFunction(content.options.condition)
                    ? content.options.condition(entity)
                    : true
            )
            .map((content, index) => {
                switch (content.facet) {
                    case "fieldset":
                        return (
                            <Grid key={index} item xs={12} md={variant === "default" ? 6 : 12}>
                                <FormFieldset
                                    key={index}
                                    title={content.options.title}
                                    fields={content.options.fields}
                                    entity={entity}
                                />
                            </Grid>
                        );
                    default:
                        return null;
                }
            });
    };

    return (
        <Form>
            <Grid container spacing={2}
                sx={{
                    '& .MuiFormControl-root': { width: { xs: '100%' } }

                }}
            >
                {processForm(formContent)}
            </Grid>
        </Form>
    )
}

export default EntityForm