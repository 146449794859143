import constants from '../../../components/entity/constants'
import { isEmpty, isObject, has, get } from 'lodash'
import { createBookingStateMapping, createCallbackField, createCurrencyField, createDataSetFacet, createFacetField, createFacetsContainer, createField, createObjectStatusField, createPaymentMethodMapping } from '../../../components/helpers/fieldHelper'

export const getHeaderContent = (t, user) => [
    createDataSetFacet(t("bookings.view.facet_information"), [
        createField("id", "Id", constants.STRING_TYPE),
        createField(
            "order.id",
            t("bookings.fields.order.id"),
            constants.STRING_TYPE
        ),
        createField(
            "order.created",
            t("bookings.fields.order.created"),
            constants.DATETIME_TYPE
        ),
        createField(
            "order.collaborator.name",
            t("bookings.view.facet_intermediary"),
            constants.STRING_TYPE,
            null,
            (entity) => entity.order.hasOwnProperty("collaborator") && 
                get(entity.order.collaborator, "id", null) !== get(user.crm_intermediary, "collaborator_id", null)
        ),
        createCallbackField(
            "order.salesman",
            t("bookings.fields.order.salesman"),
            "string",
            (value) => {
                return [value.first_name, value.last_name].join(" ").trim();
            },
            (entity) => get(entity.order, "salesman", null) !== null
        ),
        createField(
            "no_show",
            t("bookings.fields.no_show"),
            constants.DATETIME_TYPE,
            null,
            (entity) => entity.no_show
        ),
        createCallbackField(
            "managed",
            t("bookings.fields.managed"),
            "string",
            (value) => {
                return value ? t("bookings.actions.managed.managed_yes") : t("bookings.actions.managed.managed_no");
            },
            (entity) => entity.activity
        ),
        createField(
            "locked_by",
            t("bookings.fields.locked_by"),
            constants.STRING_TYPE,
            null,
            (entity) => entity.locked_by && entity.activity
        ),
    ]),
    createDataSetFacet(t("bookings.view.facet_contact"), [
        createCallbackField(
            "order.customer",
            t("bookings.fields.order.customer.first_name"),
            "string",
            (value) => {
                return [value.first_name, value.last_name].join(" ");
            }
        ),
        createField(
            "order.customer.email",
            t("bookings.fields.order.customer.email"),
            constants.STRING_TYPE
        ),
        createField(
            "order.customer.phone",
            t("bookings.fields.order.customer.phone"),
            constants.STRING_TYPE
        ),
        createField(
            "order.language",
            t("bookings.fields.order.language"),
            constants.STRING_TYPE,
            {
                deu: t("common.languages.deu"),
                eng: t("common.languages.eng"),
                fra: t("common.languages.fra"),
                ita: t("common.languages.ita"),
                nld: t("common.languages.nld"),
                pol: t("common.languages.pol"),
                rus: t("common.languages.rus"),
                spa: t("common.languages.spa"),
            }
        ),
    ]),
    createDataSetFacet(
        t("bookings.view.facet_pickup"),
        [
            createField(
                "order.pickup",
                "",
                constants.PICKUP_TYPE
            )
        ],
        (entity) => isObject(entity.order.pickup)
    ),
    createFacetsContainer([
        createFacetField("object_status", {
            size: "small",
            field: createObjectStatusField(
                createField(
                    "state",
                    t("bookings.fields.state"),
                    constants.STRING_TYPE,
                    createBookingStateMapping(t)
                ),
                null,
                (value) => {
                    switch (value) {
                        case "valid":
                            return "positive";
                        case "cancelled":
                            return "critical";
                        case "timeout":
                        case "invalid":
                            return "negative";
                        default:
                            return "neutral";
                    }
                }
            ),
        }),
        createFacetField("object_status", {
            size: "small",
            field: createObjectStatusField(
                createField(
                    "confirmed",
                    t("bookings.fields.confirmed"),
                    constants.BOOLEAN_TYPE,
                ),
                null,
                (value) => {
                    switch (value) {
                        case false:
                            return "negative";
                        case true:
                            return "positive";
                        default:
                            return "neutral";
                    }
                }
            ),
            condition: (entity) => entity.with_confirmation
        }),
    ]),
    createFacetsContainer([
        createDataSetFacet(t("bookings.view.facet_payment"), [
            createField(
                "payment_method",
                t("bookings.fields.order.payment_method"),
                constants.STRING_TYPE,
                createPaymentMethodMapping(t)
            ),
            createCallbackField(
                "order",
                t("bookings.fields.order.transaction_id"),
                constants.STRING_TYPE,
                (value) => value.tpvid === null ? (value.pp_transaction_id === null ? "Error" : value.pp_transaction_id) : value.tpvid,
                (entity) => entity.order?.tpvid || entity.order?.pp_transaction_id
            ),
        ]),
        createFacetField("object_status", {
            size: "small",
            field: createObjectStatusField(
                createCurrencyField(
                    createField(
                        "amount.total_amount_pvp",
                        t("bookings.fields.total_amount_pvp"),
                        constants.CURRENCY_TYPE
                    ),
                    "order.currency",
                    "order.main_currency",
                    "order.currency_exchange_rate"
                ),
                null,
                (value) => "info"
            ),
        }),
    ]),
    createFacetsContainer([
        createFacetField("object_status", {
            size: "small",
            field: createObjectStatusField(
                createField(
                    "booking_date",
                    t("bookings.fields.booking_date"),
                    constants.DATETIME_TYPE
                ),
                null,
                (value) => "info"
            ),
            condition: (entity) => has(entity, "booking_date")
        }),
        createFacetField("object_status", {
            size: "small",
            field: createObjectStatusField(
                createField(
                    "validation_date",
                    t("bookings.fields.validation_date"),
                    constants.DATETIME_TYPE,
                ),
                null,
                (value) => "info"
            ),
            condition: (entity) => !isEmpty(entity.validation_date)
        }),
    ]),
    createFacetsContainer([
        createFacetField("object_status", {
            size: "small",
            field: createObjectStatusField(
                createField(
                    "notes",
                    t("bookings.fields.order.customer.notes"),
                    constants.TEXT_TYPE
                ),
                null,
                (value) => "info"
            ),
            condition: (entity) => has(entity, "notes") && !user.isIntermediary()
        })
    ]),
]
