import { useTranslation } from "react-i18next";
import { getRecipientsFailedActions, getResult } from "./NotificationRecipients.functions";
import { createCallbackField, createField, createLanguagesMapping, languagesCode2 } from "../../../../../../components/helpers/fieldHelper";
import constants from "../../../../../../components/entity/constants";
import ArrayDataTable from "../../../../../../components/Datatable/ArrayDataTable";
import { Box } from "@mui/material";
import { useVolcanoApiClient } from "../../../../../../context/VolcanoApiClientProvider";
import { useNavigate } from "react-router-dom";
import { useVolcanoAuth } from "../../../../../../context/VolcanoAuthContext";
import { MailOutlineOutlined, SmsOutlined } from "@mui/icons-material";


const NotificationRecipientsFailed = ({ entityId, elements }) => {
    const { t } = useTranslation("vbms");
    const { apiClient } = useVolcanoApiClient()
    const navigate = useNavigate()
    const volcanoAuth = useVolcanoAuth()

    const actions = getRecipientsFailedActions(entityId, 'collaborator', t, apiClient, volcanoAuth.user, navigate)
 
    const columns = [
        createCallbackField(
            "type",
            t("notifications.fields.type"),
            constants.STRING_TYPE, 
            (value) => {
                return t("notification_templates.constants."+value)
            }
        ),
        createField(
            "templates.name",
            t("notifications.filter.name"),
            constants.TEXT_TYPE
        ),
        createField(
            "templates.email",
            t("notifications.fields.email"),
            constants.STRING_TYPE
        ),
        createField(
            "templates.phone",
            t("notifications.fields.phone"),
            constants.STRING_TYPE
        ),
        createCallbackField(
            "templates.language",
            t("notifications.fields.language"),
            constants.STRING_TYPE,
            (value) => createLanguagesMapping(t, languagesCode2(), true)[value]
        ),
        createCallbackField(
            "logs",
            <MailOutlineOutlined/>,
            constants.STRING_TYPE,
            (value) => {
                if (value) {
                    let elementEmail = value.find((val) => val.type === 'email');
                    if (elementEmail) {
                        return (
                            <Box>{getResult(elementEmail["state"], t)}</Box>
                        ) 
                    }
                } 
                return
            },
        ),
        createCallbackField(
            "logs",
            <SmsOutlined/>,
            constants.STRING_TYPE,
            (value) => {
                if (value) {
                    let elementEmail = value.find((val) => val.type === 'sms');
                    if (elementEmail) {
                        return (
                            <Box>{getResult(elementEmail["state"], t)}</Box>
                        ) 
                    }
                } 
                return
            },
        ),
    ]

    return (

        <ArrayDataTable
            data={elements}
            columns={columns}
            actions={actions}
            selectionMode="multiple"
        />

    )

}

export default NotificationRecipientsFailed