import { Box, CircularProgress, Typography } from "@mui/material"

const SimpleLoading = ({ loading, title }) => {
    if (!loading) {
        return null
    }

    return (
        <Box
            justifyContent="center"
            alignItems="center"
            display="flex"
            flexDirection="column"
        >
            <CircularProgress color="inherit" />
            {title && <Typography variant="body1" component="div">{title}</Typography>}
        </Box>
    )
}

export default SimpleLoading