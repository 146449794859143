import { useTranslation } from "react-i18next"
import { useVolcanoAuth } from "../../../context/VolcanoAuthContext"
import {
    createField,
    createFormFieldGroup,
    createFormFieldset,
    createFormField, createAsyncSelectFormField, createFormFieldOption
} from "../../../components/helpers/fieldHelper"
import constants from "../../../components/entity/constants"
import AsyncEntityForm from "../../../components/form/AsyncEntityForm"
import {useVolcanoApiClient} from "../../../context/VolcanoApiClientProvider"
import {buildOptions, DEFAULT_LIMIT} from "../../../lib/form-options-fetchers"
import {useMemo} from "react"
const ActivityConfigForm =  ({ selection, onAction }) => {

    const { t } = useTranslation("vbms")
    const { user } = useVolcanoAuth()
    const { apiClient } = useVolcanoApiClient()

    const fetcher = () => Promise.resolve(selection === undefined ? {
        name: "",
    } : {
            ...selection
        }
    )

    const notificationTemplatesOptions = useMemo(() => {
        const options = [createFormFieldOption("", t("table.all"))]
        const params = {
            limit: DEFAULT_LIMIT,
            sort: "name"
        }
        return apiClient.notifications.getNotificationTemplates(params).then((items) => {
            return options.concat(
                buildOptions(items.getItems(), t, "id", "name", false)
            )
        })
    }, [t, apiClient.notifications])

    const onConfirm = (data) => {

        const route = {
            ...data
        }
        route["enterprise_id"] = user.corporate_account.enterprise.id

        onAction(route)
    }

    const formContent = [
        createFormFieldset(t("activity_configs.form.fieldset_information"),
            [
                createFormFieldGroup([
                    createFormField(
                        createField(
                            "name",
                            t("activity_configs.fields.name"),
                            constants.STRING_TYPE
                        ),
                        true,
                        ""
                    )
                ]),
                createFormFieldGroup([
                    createAsyncSelectFormField(
                        "notification_templates.pickup_template.id",
                        t("activity_configs.fields.pickup_template"),
                        () => notificationTemplatesOptions,
                        false
                    ),
                    createAsyncSelectFormField(
                        "notification_templates.cancelation_template.id",
                         t("activity_configs.fields.cancel_template"),
                        () => notificationTemplatesOptions,
                        false
                    )
                ]),
            ]
        )
    ]

    return (
        <AsyncEntityForm
            fetcher={fetcher}
            onSave={onConfirm}
            formContent={formContent}
        />
    )
}

export default ActivityConfigForm
