import _isFunction from "lodash/isFunction"
import DataContainer from "../Display/DataContainer"
import ObjectStatus from "../Display/ObjectStatus"
import EntityField, { getFieldValue } from "./EntityField"

const ObjectStatusFacet = ({ size, variant, field, entity }) => {
  variant = variant || 'key_value'
  const objectStatusVariant = _isFunction(field.formatCallback) ? field.formatCallback(getFieldValue(field, entity)) : 'neutral'

  if (variant !== 'key_value') {
    return (
      <ObjectStatus size={size} variant={objectStatusVariant}>
        <EntityField field={field} entity={entity} />
      </ObjectStatus>
    )
  }

  return (
    <DataContainer
      title={field.label}
      titleVariant="label"
    >
      <ObjectStatus size={size} variant={objectStatusVariant}>
        <EntityField field={field} entity={entity} />
      </ObjectStatus>
    </DataContainer>
  )
}

export default ObjectStatusFacet
