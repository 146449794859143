import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import { Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Stack } from "@mui/material";
import { createCallbackField, createField, createFormField, createFormFieldset, createLanguagesMapping, createTextAreaFormField, languagesCode2 } from "../../../../../components/helpers/fieldHelper";
import constants from "../../../../../components/entity/constants";
import { useVolcanoApiClient } from "../../../../../context/VolcanoApiClientProvider";
import { useNavigate, useParams } from "react-router-dom";
import _set from "lodash/set"
import _has from "lodash/has"
import availableRoutes from "../../../../../routes/availableRoutes";
import useSnackBars from "../../../../../components/Snackbar/snack-bar.context";
import AsyncEntityFormPage from "../../../../../layout/templates/AsyncContentPage/AsyncEntityFormPage";



const NotificationModifyRecipientForm = ({props}) => {
    const { t } = useTranslation("vbms")
    const { apiClient } = useVolcanoApiClient()
    const navigate = useNavigate() 
    const { addAlert } = useSnackBars()

    const { entityId } = useParams()
    const { recipientType } = useParams()
    const { language } = useParams()

    const [templatesData, setTemplatesData] = useState("")

    const [legend, setLegend] = useState(false)
    const [openLegend, setOpenLegend] = useState(false)

//    const [isReady, setIsReady] = useState(false)

    const languagesMap = createLanguagesMapping(t, languagesCode2(), true)

    const getFields = (language, configuration) => {
        const result = configuration.find((config) => config.language === language)
        return result.values.reduce((acc, item) => {
            acc[item.name] = item.value;
            return acc;
        }, {});
    }

    const fetcher = () => {
//        setIsReady(false)
        return apiClient.notifications.getDetails(entityId).then((result) => {
            let fields = []
            let recipient = result.find((element) => element.type === recipientType)
            if (recipient) {
                setTemplatesData(recipient.templatesData)
                recipient.templates.forEach((template) => {
                    let f = getFields(language, template.configuration, template.type)
                    _set(fields, template.type, f)
                })
            }
            return fields
        })
    }

        
    const formContent = [
        createFormFieldset(t("notification_templates.constants.sms"), 
            [
                createTextAreaFormField(
                    'sms.body',
                    t("notification_templates.constants.body"),
                    5
                )
            ],
            (entity) => _has(entity, "sms")
        ),
        createFormFieldset(t("notification_templates.constants.email"), 
            [
                createFormField(
                    createField(
                        'email.subject',
                        t("notification_templates.constants.subject"),
                        constants.TEXT_TYPE,
                    ),
                ),
                createFormField(
                    createField(
                        'email.body',
                        t("notification_templates.constants.body"),
                        constants.HTML_TYPE
                    ),
                )
            ],
            (entity) => _has(entity, "email")
        ),

    ]

    const onConfirm = (result) => {
        let recipient = JSON.parse(templatesData);
        recipient.forEach((template) => {
            const resultTemplate = template.configuration.find((config) => config.language === language)
            if (result[template.type]) {
                resultTemplate.values.forEach((value) => {
                    if (result[template.type][value.name]) {
                        value.value = result[template.type][value.name]
                    }
                })
            }
        })

        apiClient.notifications.modifyRecipientToNotification(entityId, recipientType, JSON.stringify(recipient))
            .then((data) => {
                navigate(availableRoutes.notifications_view.path.replace(":entityId", entityId))
            })
            .catch((error) => {
                addAlert(t("notifications.actions.modify_recipient.error") + ": " +error.stack)
            })
    }

    const handleClose = () => {
        navigate(availableRoutes.notifications_view.path.replace(":entityId", entityId))
    }

    const handleClickOpenLegend = () => {
        setOpenLegend(true);
    }

    const handleCloseLegend = () => {
        setOpenLegend(false)
    }

    useEffect(() => {
        apiClient.notifications.getLegend().then((legend) => {
            setLegend(legend)
        })

    }, [setLegend, apiClient])

    return (
        <Stack spacing={1} >
            <AsyncEntityFormPage
                fetcher={fetcher}
                title={createCallbackField(
                    "name",
                    t("notifications.form.edit_title"),
                    constants.STRING_TYPE,
                    (value, entity) => {
                        return t("notification_templates.form.fieldset_recipient_texts", {name: t("notification_templates.constants."+recipientType), lang: languagesMap[language]})
                    } 
                )}
                onSave={onConfirm}
                onClose={handleClose}
                formContent={formContent}
            />
            {legend && (
            <Stack spacing={2} direction="row" justifyContent="flex-end" >
                <Dialog open={openLegend} onClose={handleCloseLegend}>
                    <DialogTitle>{t("notification_templates.actions.show_legend.modal_title")}</DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            <Box>
                                <ul>
                                    {legend[recipientType].map((elem) => (<li key={elem}>{elem}</li>))}
                                </ul>
                            </Box>
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleCloseLegend} color="primary">
                        {t("common.close")}
                        </Button>
                    </DialogActions>
                </Dialog>                
                <Box pr={10}>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={handleClickOpenLegend}
                    >
                        {t("notification_templates.actions.show_legend.button")}
                    </Button>
                </Box>
            </Stack>
            )}
        </Stack>
    )
}

export default NotificationModifyRecipientForm