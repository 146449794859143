// import availableRoutes from '../../../routes/availableRoutes'
// import userPermissions from "../../../lib/user-permissions";
import { getActions as getEntityActions } from '../SitePage/SitePage.actions';

export const getActions = (t, navigate, site) => [
    //ToDo lgonzalez
    // {
    //     title: t("sites.add"),
    //     onExecute: (user, progressHandler, onSuccess, onError) => {
    //         navigate(availableRoutes.settings_sites_add.path)
    //     },
    //     condition: () => site.hasPermission(userPermissions.PERM_CONTENT_SITE_ADMINISTER)
    // }
]

export const getTableActions = (apiClient, t, user) =>
    getEntityActions(apiClient, t, user, { scope: "row" }).filter((action) => {
        return ["clear_cache", "publish"].includes(action.id)
    })