import { useEffect } from "react"
import { useNavigate } from "react-router-dom"
import { useVolcanoAuth } from "../context/VolcanoAuthContext"

const urlToRedirect = (redirect, volcanoAuth) => {
    if (!(redirect || volcanoAuth.redirect)) {
        return null
    }

    if (redirect && redirect !== window.location.pathname) {
        return redirect
    }

    if (volcanoAuth.redirect && volcanoAuth.redirect !== window.location.pathname) {
        redirect = volcanoAuth.redirect
        volcanoAuth.clearRedirect()

        return redirect
    }

    return null
}

const AuthVerify = () => {
    const volcanoAuth = useVolcanoAuth()
    const navigate = useNavigate()
    const urlParams = new URLSearchParams(window.location.search)
    const redirect = urlParams.get("redirect")

    useEffect(() => {
        const url = urlToRedirect(redirect, volcanoAuth)

        if (volcanoAuth.tokenExists() && !volcanoAuth.isTokenValid()) {
            volcanoAuth.logout()
        } else if (url && url !== window.location.pathname) {
            navigate(url)
        }
    }, [volcanoAuth, redirect, navigate])

    return
}

export default AuthVerify