import { useTranslation } from "react-i18next"
import constants from "../../components/entity/constants"
import { useVolcanoApiClient } from "../../context/VolcanoApiClientProvider"
import { useNavigate, useParams } from "react-router-dom"
import { createCallbackField, createField, createFormFieldset, createAsyncTreeViewField } from '../../components/helpers/fieldHelper';
import AsyncEntityFormPage from "../../layout/templates/AsyncContentPage/AsyncEntityFormPage"
import { cachedFetcher } from '../../lib/form-options-fetchers';
import { useCache } from "../../context/cache.context";
import useSnackBars from "../../components/Snackbar/snack-bar.context";
import { getGroupedPermissions } from './Roles.functions';

const RoleFormPage = (props) => {
    const { t } = useTranslation()
    const { entityId } = useParams()
    const { apiClient } = useVolcanoApiClient()
    const cache = useCache()
    const navigate = useNavigate()
    const { addAlert } = useSnackBars()

    const fetcher = () => {
        if (!entityId) {
            return new Promise((res) => res({}))
        }

        return apiClient.role.getRole(entityId).then((role) => {
            return role
        })
    }

    const onSaveHandler = (role) => {
        // clone role
        const roleData = {
            ...role,
            // process role permissions
            permissions: role.permissions.map((permission) => ({ id: permission.substring(2, permission.length)}))
        }

        if (!entityId) {
            apiClient.role.createRol(roleData, 'application')
                .then((rol) => {
                    navigate('/settings/roles')
                })
                .catch((error) => {
                    addAlert("Error: " + error.message)
                })
        } else {
            apiClient.role.editRol(entityId, roleData, 'application')
                .then((rol) => {
                    navigate('/settings/roles/' + entityId)
                })
                .catch((error) => {
                    addAlert("Error: " + error.message)
                })
        }
    }


    const formContent = [
        createFormFieldset(t("settings_roles.form.fieldset_account"), [
            createField(
                "name",
                t("settings_roles.fields.name"),
                constants.STRING_TYPE
            ),
            createField(
                "slug",
                t("settings_roles.fields.slug"),
                constants.STRING_TYPE
            ),
            createField(
                "is_system",
                t("settings_roles.fields.is_system"),
                constants.BOOLEAN_TYPE,
                null
            ),
            createField(
                "corporate_account_available",
                t("settings_roles.fields.corporate_account_available"),
                constants.BOOLEAN_TYPE
            ),
        ]),
        createFormFieldset(t("settings_roles.form.fieldset_permissions"), [
            createAsyncTreeViewField(
                "permissions",
                "",
                () => cachedFetcher(cache, "permissions", apiClient.permission.getPermissions()).then((permissions) => getGroupedPermissions(permissions)),
                "expand_all",
                true,
                (values) => values.id.toString()
            ),
        ])
    ]

    return (
        <AsyncEntityFormPage
            fetcher={fetcher}
            title={createCallbackField(
                "name",
                t("settings_roles.form.edit_title"),
                constants.STRING_TYPE,
                (value, entity) => {
                    if (entity.id) {
                        return t("settings_roles.form.edit_title", {
                            name: value,
                        });
                    }
                    return t("settings_roles.form.add_title");
                }
            )}
            onSave={onSaveHandler}
            formContent={formContent}
        />
    )
}

export default RoleFormPage