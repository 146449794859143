import constants from '../../../components/entity/constants'
import { createCallbackField, createDataSetFacet, createFacetField, createField, createObjectStatusField } from '../../../components/helpers/fieldHelper'
import { createFacetsContainer, createInvoiceStateMapping, createLinkField } from '../../../components/helpers/fieldHelper';
import _isObject from 'lodash/isObject';

export const getHeaderContent = (t, user) => [
    createDataSetFacet(t("invoices.view.facet_information"), [
        createField("id", "Id", constants.STRING_TYPE),
        createField(
            "created",
            t("invoices.fields.created"),
            constants.DATETIME_TYPE
        ),
        createField(
            "enterprise.name",
            t("common.enterprise"),
            constants.STRING_TYPE
        ),
    ]),
    createDataSetFacet(
        t("invoices.view.facet_recipient"),
        [
            createField("data.collaborator.id", "Id", constants.STRING_TYPE),
            createField(
                "data.collaborator.name",
                t("invoices.fields.recipient.name"),
                constants.STRING_TYPE
            ),
            createField(
                "data.collaborator.cif",
                t("invoices.fields.recipient.vat_number"),
                constants.STRING_TYPE
            ),
        ],
        (entity) => _isObject(entity.data.collaborator)
    ),
    createDataSetFacet(
        t("invoices.view.facet_recipient"),
        [
            createLinkField(
                createField(
                    "data.client.id",
                    "Id",
                    constants.STRING_TYPE,
                    null,
                    () => user.hasPermission('view-billing-information')
                ),
                {
                    route: "billing_clients",
                    field: "data.client.id",
                }
            ),
            createField(
                "data.client.name",
                t("invoices.fields.recipient.name"),
                constants.STRING_TYPE
            ),
            createField(
                "data.client.cif",
                t("invoices.fields.recipient.vat_number"),
                constants.STRING_TYPE
            ),
        ],
        (entity) => _isObject(entity.data.client)
    ),
    createFacetsContainer([
        createFacetField("object_status", {
            size: "large",
            field: createObjectStatusField(
                createField(
                    "state",
                    t("invoices.fields.state"),
                    constants.STRING_TYPE,
                    createInvoiceStateMapping(t, "issuer")
                ),
                null,
                (value) => {
                    switch (value) {
                        case "paid":
                            return "positive";
                        case "issued":
                            return "info";
                        case "unpaid":
                            return "negative";
                        default:
                            return "neutral";
                    }
                }
            ),
        }),
        createFacetField("object_status", {
            size: "large",
            field: createObjectStatusField(
                createField(
                    "amount.total_amount",
                    t("invoices.fields.total_amount"),
                    constants.CURRENCY_TYPE
                ),
                null,
                (value) => "info"
            ),
        }),
    ]),
    createFacetsContainer([
        createFacetField("object_status", {
            size: "large",
            field: createObjectStatusField(
                createCallbackField(
                    "amount.total_amount",
                    t("invoices.fields.total_base"),
                    constants.CURRENCY_TYPE,
                    (value, invoice) => invoice.amount.components.reduce((acc, value) => acc + value.total_base, 0)
                ),
                null,
                (value) => "info"
            ),
        }),
        createFacetField("object_status", {
            size: "large",
            field: createObjectStatusField(
                createField(
                    "amount.total_tax",
                    t("invoices.fields.total_tax"),
                    constants.CURRENCY_TYPE
                ),
                null,
                (value) => "info"
            ),
        }),
    ]),
]