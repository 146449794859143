import constants from "../../../../components/entity/constants";
import { createBooleanFormField, createField } from '../../../../components/helpers/fieldHelper';
import { getIntermediariesClientsFilter } from '../../../../lib/collection-filters';

export const getTableFilter = (apiClient, t, user, cache) => ([
    createField(
        "name",
        t("intermediaries.offices.fields.name"),
        constants.TEXT_TYPE
    ),
    createBooleanFormField(
        t,
        "state",
        t("intermediaries.offices.fields.active"),
    ),
    getIntermediariesClientsFilter(apiClient, t, user, "intermediary_id", false)
])