import { useState, useContext, useEffect } from "react";
import { ToggleButtonGroup, ToggleButton as MuiToggleButton, Typography, Stack } from "@mui/material";
import { styled } from "@mui/material/styles";
import theme from "../../../config/theme";
import { RulesContext } from "./RulesSelector";

const INTERVALS = "weekdays"
export const DEFAULT_DAYS = {[INTERVALS]: "1111100"}

const DAYS = [
    {
        key: "Lunes",
        label: "L"
    },
    {
        key: "Martes",
        label: "M"
    },
    {
        key: "Miércoles",
        label: "X"
    },
    {
        key: "Jueves",
        label: "J"
    },
    {
        key: "Viernes",
        label: "V"
    },
    {
        key: "Sábado",
        label: "S"
    },
    {
        key: "Domingo",
        label: "D"
    },
];

const ToggleButton = styled(MuiToggleButton)((props) => ({
    width: "100%",
    ':hover': {
        cursor: props.readOnly && 'not-allowed',
        color: props.readOnly && theme.palette.error.main,
    },
    "&.Mui-selected": {
        color: theme.palette.common.white,
        backgroundColor: theme.palette.primary.main
    },
    "&.Mui-selected:hover": {
        color: props.readOnly && theme.palette.common.white,
        backgroundColor: props.readOnly && theme.palette.error.main
    }
}))

const formatDays = (values) => {
    return values?.split('').map(Number)
}

const parseDays = (values) => {
    return values.map(String).join('');
}


export const RulesSelectorDays = ({ label = "Label", showLabel, editable = false, index}) => {
    
    const {config, setConfig} = useContext(RulesContext)
    const {weekdays} = config[index]
    const [days, setDays] = useState(weekdays? formatDays(weekdays) : formatDays(DEFAULT_DAYS[INTERVALS]));

    const onChange = (i) => {
        if (editable) {
            let newConfig = [...config]
            let newWeekdays = [...days]
    
            newWeekdays[i] = toggleNumber(newWeekdays[i]) 
            newConfig[index] = {...newConfig[index], weekdays: parseDays(newWeekdays)}
            setConfig(newConfig)
        }
    }

    useEffect(() => {
        weekdays && setDays(formatDays(weekdays))
    },[config])

    const toggleNumber = (number) => {
        return number === 0 ? 1 : 0;
    };

    const renderLabel = () => {
        if (showLabel || (!showLabel && index === 0)) {
            return (
                <Typography 
                    variant="h5" 
                    borderBottom={!showLabel && `1px solid ${theme.palette.common.container.border}`}
                    marginBottom={!showLabel && 2}
                    paddingBottom={!showLabel && 2}
                    sx={{ fontWeight: 'bold' }} 
                >
                    {label}
                </Typography>
            )
        }
        return null
    }

    return (
        <Stack gap={2}>
            {renderLabel()}
            <ToggleButtonGroup
                size="small"
                value={days}
                onChange={(e, i) => onChange(i)}
                exclusive
            >
                {DAYS.map((day, index) =>
                    <ToggleButton 
                        key={index} 
                        value={index} 
                        selected={days[index] === 1} 
                        editable={editable.toString()}
                        readOnly={!editable}
                    >
                        {day.label}
                    </ToggleButton>
                )}
            </ToggleButtonGroup>
        </Stack>
    );
};

export default RulesSelectorDays;