
import { createContext, useContext } from "react";
import { initialVolcanoAuthState } from "./VolcanoAuthState";

const stub = () => {
    throw new Error('You forgot to wrap your component in <VolcanoAuthProvider></VolcanoAuthProvider>.')
}

const initialContext = {
    ...initialVolcanoAuthState,
    authenticate: stub,
    logout: stub,
    resetPasswordRequest: stub,
    resetPassword: stub,
    tokenExists: stub,
    tokenRenew: stub,
    isTokenValid: stub,
}

export const VolcanoAuthContext = createContext(initialContext)
export const useVolcanoAuth = () => useContext(VolcanoAuthContext)