import _isArray from "lodash/isArray"
import _isFunction from "lodash/isFunction"
import _isObject from "lodash/isObject"
import { Box, Stack } from "@mui/material"
import BookingRates from "./BookingRates"
import { useVolcanoAuth } from "../../../context/VolcanoAuthContext"
import FacetsContainer from "../../Display/FacetsContainer"
import { createCallbackField, createDataSetFacet, createField, createLinkField, createPaymentMethodMapping, createCurrencyField } from '../../helpers/fieldHelper';
import constants from "../../entity/constants"
import DatasetEntityFacet from "../../entity/DatasetEntityFacet"
import ObjectStatusFacet from "../../entity/ObjectStatusFacet"
import { useTranslation } from "react-i18next"
import DataContainer from "../../Display/DataContainer"
import CollapseContainer from "../../CollapseContent/CollapseContainer"

const BookingDetail = ({ entity }) => {
    const { t } = useTranslation()
    const volcanoAuth = useVolcanoAuth()

    const getContent = (t, user) => [
        createDataSetFacet(t("booking_detail_view.facet_information"), [
            createLinkField(
                createField(
                    "locator",
                    t("bookings.fields.locator"),
                    constants.TEXT_TYPE
                ),
                {
                    route: "bookings",
                    field: "id",
                }
            ),
            createCallbackField(
                "product",
                t("bookings.fields.product"),
                constants.STRING_TYPE,
                (value) => `${value.experience.name} / ${value.name}`
            ),
            createField(
                "order.created",
                t("bookings.fields.order.created"),
                constants.DATETIME_TYPE
            ),
            createField(
                "order.enterprise.name",
                t("bookings.fields.order.enterprise.title"),
                constants.STRING_TYPE
            ),
            createField(
                "order.collaborator.name",
                t("bookings.fields.order.collaborator.title"),
                constants.STRING_TYPE,
                null,
                (entity) => _isObject(entity.order.collaborator)
            ),
        ]),
        createDataSetFacet(t("booking_detail_view.facet_payment"), [
            createField(
                "payment_method",
                t("bookings.fields.order.payment_method"),
                constants.STRING_TYPE,
                createPaymentMethodMapping(t)
            ),
            createCallbackField(
                "order",
                t("bookings.fields.order.transaction_id"),
                constants.STRING_TYPE,
                (value) => value.tpvid === null ? (value.pp_transaction_id === null ? "Error" : value.pp_transaction_id) : value.tpvid,
                (entity) => entity.order?.tpvid || entity.order?.pp_transaction_id
            ),
            createField(
                "order.main_payment_transaction.created",
                t("bookings.fields.order.transaction_date"),
                constants.DATETIME_TYPE,
                null,
                () => entity.order.hasOwnProperty("main_payment_transaction") && !isNaN(Date.parse(entity.order.main_payment_transaction.created))
            ),
            createCurrencyField(
                createField(
                    "amount.total_amount_pvp",
                    t("bookings.fields.total_amount_pvp"),
                    constants.CURRENCY_TYPE
                ),
                "order.currency",
                "order.main_currency",
                "order.currency_exchange_rate"
            ),
        ]),
        createDataSetFacet(
            t("booking_detail_view.facet_usage"),
            [
                createField(
                    "booking_date",
                    t("bookings.fields.booking_date"),
                    constants.DATETIME_TYPE
                ),
                createField(
                    "validation_date",
                    t("bookings.fields.validation_date"),
                    constants.DATETIME_TYPE,
                    null,
                    () => entity.hasOwnProperty("validation_date")
                ),
            ],
            () => entity.hasOwnProperty("booking_date")
        ),
    ]

    const processContents = (contents) => {
        if (!_isArray(contents)) {
            return null
        }

        return contents
            .filter((content) =>
                _isFunction(content.options.condition) ? content.options.condition(entity) : true
            )
            .map((content, index) => {
                switch (content.facet) {
                    case "container":
                        return (
                            <FacetsContainer key={index}>
                                {processContents(content.options.fields)}
                            </FacetsContainer>
                        )
                    case "dataset":
                        return (
                            <DatasetEntityFacet
                                key={index}
                                title={content.options.title}
                                fields={content.options.fields}
                                entity={entity}
                            />
                        )
                    case "object_status":
                        return (
                            <ObjectStatusFacet
                                key={index}
                                size={content.options.size}
                                field={content.options.field}
                                entity={entity}
                            />
                        );
                    default:
                        return null
                }
            })
    }

    return (
        <Stack>
            <CollapseContainer disableCollapse={true} collapsed={false} onChange={() => { }}>
                {processContents(getContent(t, volcanoAuth.user))}
            </CollapseContainer>
            <DataContainer title={t("booking_detail_view.facet_rates")}>
                <BookingRates data={entity} hideRatesTotal={true} />
            </DataContainer>
        </Stack >
    )
}

export default BookingDetail