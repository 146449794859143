import { Table, TableContainer } from '@mui/material'
import DataTableHead from './DataTableHead'
import DataTableBody from './DataTableBody'

const DataTableContainer = (props) => {
    const {
        selectionMode,
        columns,
        table,
        actions,
        selected,
        highlight,
        onRowSelect,
        onSelectAllClick,
        onRequestSort
    } = props

    const withSelection = selectionMode !== "none"

    return (
        <TableContainer>
            <Table size="small">
                <DataTableHead
                    selectionMode={selectionMode}
                    columns={columns}
                    withActions={actions ? actions.length > 0 : false}
                    order={table.order}
                    orderBy={table.orderBy}
                    numSelected={selected.length}
                    onSelectAllClick={onSelectAllClick}
                    onRequestSort={onRequestSort}
                    rowCount={table.result.rows.length}
                />
                <DataTableBody
                    withSelection={withSelection}
                    columns={columns}
                    rows={table.result.rows}
                    actions={actions}
                    selected={selected}
                    highlight={highlight}
                    rowsPerPage={table.rowsPerPage}
                    onRowSelect={onRowSelect}
                />
            </Table>
        </TableContainer>
    )
}

export default DataTableContainer