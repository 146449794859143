import { Step, StepButton, StepLabel, Stepper, Typography, useMediaQuery, useTheme } from '@mui/material';

const ProcessSteps = ({ steps, activeStep, onStepSelected }) => {
    const theme = useTheme()
    const matchDownMd = useMediaQuery(theme.breakpoints.down('md'))

    return (
        <Stepper
            orientation={matchDownMd ? "horizontal" : "vertical"}
            activeStep={activeStep - 1}
            sx={{
                "& .MuiStepLabel-labelContainer": {
                    "& .MuiStepLabel-iconContainer": {
                        display: "none",
                    },
                    "& .MuiStepLabel-label": {
                        textAlign: "left",
                    },
                    "& .MuiTypography-caption": {
                        textAlign: "left",
                    },
                },
            }}
        >
            {steps.map((step, index) => (
                <Step key={"step-" + index} completed={step.id < activeStep} >
                    <StepButton onClick={() => onStepSelected(step.id)}>
                        {" "}
                        <StepLabel
                            optional={!matchDownMd && step.content.text && (<Typography variant="caption">{step.content.text}</Typography>)}
                        >
                            {(!matchDownMd || (step.id === activeStep)) && step.content.title}
                        </StepLabel>
                    </StepButton>
                </Step>
            ))
            }
        </Stepper >
    )
}

export default ProcessSteps