import CardListBody from './CardListBody'

const CardListContainer = (props) => {
    const {
        withSelection,
        columns,
        table,
        actions,
        selected,
        highlight,
        onRowSelect,
        onSelectAllClick
    } = props

    return (
        <CardListBody
            withSelection={withSelection}
            columns={columns}
            rows={table.result.rows}
            actions={actions}
            selected={selected}
            highlight={highlight}
            rowsPerPage={table.rowsPerPage}
            onRowSelect={onRowSelect}
            numSelected={selected.length}
            onSelectAllClick={onSelectAllClick}
        />
    )
}

export default CardListContainer