import { useTranslation } from "react-i18next"
import { useVolcanoApiClient } from "../../../context/VolcanoApiClientProvider"
import CollectionPage from "../../../layout/templates/CollectionPage/CollectionPage"
import { useCache } from "../../../context/cache.context"
import { getTableActions } from "./BookingsCollection.actions"
import { getTableColumns } from "./BookingsCollection.columns"
import { getTableFilter } from "./BookingsCollection.filter"
import { getTableExport } from "./BookingsCollection.export"
import constants from "../../../components/entity/constants"
import _isDate from "lodash/isDate"
import _get from "lodash/get"
import _set from "lodash/set"
import { format as formatDate } from "date-fns"
import { useVolcanoAuth } from "../../../context/VolcanoAuthContext"
import _isArray from 'lodash/isArray'


const BookingsCollectionPage = () => {
    const { apiClient } = useVolcanoApiClient()
    const { i18n, t } = useTranslation("vbms")
    const cache = useCache()
    const volcanoAuth = useVolcanoAuth()

    const actions = []

    const filterConfig = getTableFilter(apiClient, t, volcanoAuth.user, cache)

    const table = {
        selectionMode: "multiple",
        columns: getTableColumns(t, volcanoAuth.user, i18n),
        actions: getTableActions(volcanoAuth.user, apiClient, t),
        fetcher: (params) => {
            filterConfig
                .filter(field => field.type === constants.DATE_TYPE)
                .forEach(field => {
                    const date = _get(params, field.id)
                    if (_isDate(date)) {
                        _set(params, field.id, formatDate(date, "yyyy-MM-dd"))
                    }
                })

            filterConfig
                .filter(field => (field.type === constants.STRING_TYPE ||
                    field.type === constants.TEXT_TYPE))
                .forEach(field => {
                    if (_get(params, field.id) !== '') {
                        const param = _get(params, field.id)
                        _set(params, field.id, param.trim())
                    }
                })
 
            let collaboratorFilter = [];
            if (_get(params, "collaborator_id", null)) {
                if (_isArray(_get(params, "collaborator_id", null))) {
                    _get(params, "collaborator_id", null).forEach(col => {
                            collaboratorFilter.push(col.id)
                    });
                } else {
                    collaboratorFilter.push(_get(params, "collaborator_id", null));
                }
            }
            if (_get(params, "payment_method_id", null) === '') {
                _set(params, "payment_method_id", [])
            }
            if (_get(params, "discount_code_id", null) === '') {
                _set(params, "discount_code_id", [])
            }
            if (_get(params, "activity_id", null) === '') {
                _set(params, "activity_id", [])
            }
            if (_get(params, "supplier_id", null) === '') {
                _set(params, "supplier_id", [])
            }

            const queryFilter = {
                ...params,
                activity_id: _get(params, "activity_id[0].id", null),
//                collaborator_id: _get(params, "collaborator_id", null),
                collaborator_id: collaboratorFilter,
                supplier_id: _get(params, "supplier_id[0].id", null),
                discount_code_id: _get(params, "discount_code_id[0].id", null),
                required_actions: ["set_no_show", "set_exchange_date"].join("|"),
            }

            return apiClient.booking.getBookings(queryFilter)
        }
    }

    return (
        <CollectionPage
            title={t("bookings.title")}
            subtitle={t("bookings.subtitle")}
            actions={actions}
            filterConfig={filterConfig}
            table={table}
            onExport={getTableExport(apiClient, t, volcanoAuth.user, filterConfig)}
        />
    )
}

export default BookingsCollectionPage
