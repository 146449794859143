import { Box, Stack } from "@mui/material"
import constants from "../../../components/entity/constants"
import { createCallbackField, createField, createLanguagesMapping, createLinkField } from '../../../components/helpers/fieldHelper'
import { trimWithEllipsis } from "../../../lib/utils"

export const getTableColumns = (t, user, i18n) => ([
    createLinkField(
        createCallbackField(
            "locator",
            t("bookings.fields.locator"),
            constants.TEXT_TYPE,
            (value, entity) => {
                if (!entity.order.voucher_id) return value
                return (
                    <Stack gap="0.1em">
                        <Box>{value}</Box>
                        <Box>({entity.order.voucher_id})</Box>
                    </Stack>
                )
            },
            null,
            null,
            "locator"
        ),
        {
            route: "bookings",
            field: "id",
        }
    ),
    createCallbackField(
        "order.customer",
        t("bookings.fields.order.customer.title"),
        constants.STRING_TYPE,
        (value) => {
            const name = value?.full_name ? value.full_name.trim() : ""

            return (
                <Stack>
                    <Box>{trimWithEllipsis(name, 32)}</Box>
                    <Box>{trimWithEllipsis(value.phone)}</Box>
                </Stack>
            )
        }
    ),
    createCallbackField(
        "notes",
        t("bookings.fields.order.customer.notes"),
        constants.TEXT_TYPE,
        (value) => trimWithEllipsis(value, 16),
        () => !user.isIntermediary()
    ),
    createCallbackField(
        "product_rates",
        t("bookings.fields.pax_adult"),
        constants.NUMERIC_TYPE,
        (value) => (value || [])
            .filter((rate) => rate.customer_type_ids
                .split(',')
                .includes("16")
            )
            .reduce((acc, rate) => acc + rate.qty, 0)
    ),
    createCallbackField(
        "product_rates",
        t("bookings.fields.pax_children"),
        constants.NUMERIC_TYPE,
        (value) => (value || [])
            .filter((rate) => rate.customer_type_ids
                .split(',')
                .includes("14")
            )
            .reduce((acc, rate) => acc + rate.qty, 0)
    ),
    createCallbackField(
        "product_rates",
        t("bookings.fields.pax"),
        constants.NUMERIC_TYPE,
        (value) => (value || []).reduce((acc, rate) => acc + rate.qty, 0)
    ),
    createCallbackField(
        "order",
        t("bookings.fields.order.intermediary.title"),
        constants.STRING_TYPE,
        (value) => {
            if (!value?.collaborator) return null

            const name = value?.salesman ? (`${value.salesman.first_name} ${value.salesman.last_name}`).trim() : null
            return (
                <Stack>
                    <Box>{trimWithEllipsis(value.collaborator.name, 32)}</Box>
                    {name && <Box>{trimWithEllipsis(name, 32)}</Box>}
                    {value.salesman?.phone && <Box>{trimWithEllipsis(value.salesman.phone)}</Box>}
                </Stack>
            )
        }
    ),
    createField(
        "order.language",
        t("common.language"),
        constants.STRING_TYPE,
        createLanguagesMapping(t)
    ),
    createField(
        "confirmed",
        t("bookings.fields.confirmed"),
        constants.BOOLEAN_TYPE
    ),
    createField(
        "booking_date",
        t("common.fields.hour"),
        constants.TIME_TYPE
    ),
    createField(
        "order.lodgin.name",
        t("bookings.fields.pickup.lodgin"),
        constants.STRING_TYPE
    ),
    createCallbackField(
        "order.pickup",
        t("bookings.fields.pickup.title"),
        constants.STRING_TYPE,
        (value) => {
            if (!value) return null
            const limit = 16   // Limit before apply ellipsis

            return (
                <Stack>
                    <Box>{trimWithEllipsis(value.name, limit)}</Box>
                    <Box>{trimWithEllipsis(value.pickup_time)}</Box>
                </Stack>
            )
        }
    ),
])
