import { useTranslation } from "react-i18next";
import ArrayDataTable from "../../../../../components/Datatable/ArrayDataTable";
import constants from "../../../../../components/entity/constants";
import { createField } from "../../../../../components/helpers/fieldHelper";

const UserRoles = ({ entity }) => {
    const { t } = useTranslation("vbms");

    if (!entity || !entity.roles) {
        return null;
    }

    return (
        <ArrayDataTable
            data={entity.roles}
            columns={[
                createField(
                    "id",
                    "Id",
                    constants.STRING_TYPE
                ),
                createField(
                    "name",
                    t("settings_roles.fields.name"),
                    constants.STRING_TYPE
                ),
            ]}
        />
    );
}

export default UserRoles;