import { useState, useEffect, useCallback } from 'react'
import { Box, Grid, Paper, TablePagination, useMediaQuery, useTheme } from '@mui/material'
import { setOrder, setPage, setRowsPerPage } from "../entity/context/collection/collection.action"
import { useDataTableContext } from "../entity/context/collection/collection.context"
import DataTableFilter from "./components/DataTableFilter"
import _isArray from 'lodash/isArray'
import _isFunction from 'lodash/isFunction'
import DataTableToolbar from './components/DataTableToolBar'
import CardListContainer from './components/CardListContainer'
import DataTableContainer from './components/DataTableContainer'
import GroupedDataTableContainer from './components/GroupedDataTableContainer'
import AggregateResult from './AggregateResult'

const DataTable = (props) => {
    const theme = useTheme()
    const matchDownMd = useMediaQuery(theme.breakpoints.down('md'))
    const [selected, setSelected] = useState([])
    const [table, tableDispatch] = useDataTableContext()
    const [availableColumns, setAvailableColumns] = useState([])
    const {
        selectionMode = "none",
        groupBy = null,
        columns,
        highlight,
        actions,
        onExport,
        hideShare,
        hideFilter,
    } = props

    // true if table is grouped
    const grouped = groupBy !== null

    // only enable selection if required and table is not grouped
    const withSelection = selectionMode !== "none" && !grouped

    // handle initial selection
    useEffect(() => {
        if (withSelection && _isArray(props.selected)) {
            // only set selection if it is different from the initial one            
            const shouldUpdate = !(selected.every((id) => props.selected.includes(id)) && selected.length === props.selected.length)
            if (shouldUpdate) {
                setSelected(props.selected)
            }
        }
    }, [withSelection, props.selected])

    // handle selection changes
    useEffect(() => {
        if (withSelection && table.result.count > 0) {
            if (_isFunction(props.onSelectionChange)) {
                props.onSelectionChange(selected, table.result.rows.filter((row) => selected.includes(row.id)))
            }
        }
    }, [withSelection, selected, props.onSelectionChange])

    const enableSelection = withSelection && (
        (_isArray(actions) && actions.some(action => action.scope === "table")) ||
        (actions === undefined)
    )

    const handleOnRequestSort = (event, field) => {
        if (table.orderBy === field) {
            const isAsc = !(table.order === "asc")
            tableDispatch(setOrder(field, isAsc))
        } else {
            tableDispatch(setOrder(field, true))
        }
    }

    const handleOnChangePage = (event, newPage) => {
        tableDispatch(setPage(newPage))
    }

    const handleOnChangeRowsPerPage = (event) => {
        tableDispatch(setRowsPerPage(parseInt(event.target.value, 10)))
    }

    const handleOnSelectAllClick = (event) => {
        if (event.target.checked) {
            // select all rows
            const newSelection = table.result.rows.map((n) => n.id)
            setSelected(newSelection)
            return
        }
        setSelected([])
    }

    const handleOnRowSelect = (event, key) => {
        const selectedIndex = selected.indexOf(key)

        let newSelection = []

        if (selectionMode === "single") {
            if (selectedIndex === -1) {
                // not already selected, add it
                newSelection.push(key)
            }
        } else {
            if (selectedIndex === -1) {
                // not already selected, add it
                newSelection = newSelection.concat(selected, key)
            } else if (selectedIndex === 0) {
                // already selected, remove it
                newSelection = newSelection.concat(selected.slice(1))
            } else if (selectedIndex === selected.length - 1) {
                // already selected, remove it
                newSelection = newSelection.concat(selected.slice(0, -1))
            } else if (selectedIndex > 0) {
                // already selected, remove it
                newSelection = newSelection.concat(
                    selected.slice(0, selectedIndex),
                    selected.slice(selectedIndex + 1)
                )
            }
        }

        setSelected(newSelection)
    }

    const processColumns = useCallback(() => {
        if (!_isArray(columns)) {
            return null;
        }

        return columns.filter((column) =>
            _isFunction(column.condition)
                ? column.condition()
                : true
        )
    }, [columns])

    useEffect(() => {
        setAvailableColumns(processColumns())
    }, [processColumns])

    return (
        <Box>
            <Paper elevation={0}>
                {!hideFilter &&
                    <DataTableFilter
                        onExport={onExport}
                        isMobile={matchDownMd}
                        hideShare={hideShare}
                    />
                }
                <DataTableToolbar
                    selected={table.result.rows.filter((row) => selected.includes(row.id))}
                    actions={actions ? actions.filter((action) => action.scope === 'table') : []}
                />
                {matchDownMd ? (
                    <CardListContainer
                        withSelection={enableSelection}
                        columns={availableColumns}
                        table={table}
                        actions={actions ? actions.filter((action) => action.scope === 'row') : []}
                        selected={selected}
                        highlight={highlight}
                        onRowSelect={handleOnRowSelect}
                        onSelectAllClick={handleOnSelectAllClick}
                        onRequestSort={handleOnRequestSort}
                    />
                ) : !grouped ? (
                    <DataTableContainer
                        selectionMode={enableSelection ? selectionMode : "none"}
                        columns={availableColumns}
                        table={table}
                        actions={actions ? actions.filter((action) => action.scope === 'row') : []}
                        selected={selected}
                        highlight={highlight}
                        onRowSelect={handleOnRowSelect}
                        onSelectAllClick={handleOnSelectAllClick}
                        onRequestSort={handleOnRequestSort}
                    />
                ) : (
                    <GroupedDataTableContainer
                        groupBy={groupBy}
                        columns={availableColumns}
                        table={table}
                        actions={actions ? actions.filter((action) => action.scope === 'row') : []}
                        highlight={highlight}
                    />
                )}
                <Grid container>
                    {table.result.total > 0 && table.result.aggregateFields && (<Grid item xs={12} lg={7} sx={{ flexGrow: 1, paddingX: 2, paddingY: { xs: 1, lg: 2 } }}>
                        <AggregateResult items={table.result.aggregateFields} />
                    </Grid>)}
                    {!grouped && table.result.total > table.rowsPerPage && (<Grid item xs={12} lg={5}>
                        <TablePagination
                            rowsPerPageOptions={[5, 10, 20, 50]}
                            component="div"
                            count={table.result.total}
                            rowsPerPage={table.rowsPerPage}
                            page={table.page}
                            onPageChange={handleOnChangePage}
                            onRowsPerPageChange={handleOnChangeRowsPerPage}
                        />
                    </Grid>)}
                </Grid>
            </Paper>
        </Box >
    )
}

export default DataTable
