import { useTranslation } from "react-i18next"
import { useVolcanoApiClient } from "../../context/VolcanoApiClientProvider"
import { useNavigate, useParams } from "react-router-dom"
import BasePage from "../../layout/templates/BasePage"
import { useState, useEffect, useCallback } from "react"
import { filterAvailableActions } from "../../components/action/utils"
import Loading from "../../components/Display/Loading"
import availableRoutes from "../../routes/availableRoutes"
import BillingClientCreateInvoiceWidget from "../BillingClients/components/BillingClientCreateInvoiceWidget/BillingClientCreateInvoiceWidget"

const BookingCreateInvoiceFormPage = (props) => {
    const { t } = useTranslation()
    const { entityId } = useParams()
    const { apiClient } = useVolcanoApiClient()
    const navigate = useNavigate()

    const [entity, setEntity] = useState()

    const actions = [
        {
            id: "cancel",
            title: t("common.cancel"),
            onExecute: () => {
                navigate(-1)
            },
        },
    ]

    const handleOnInvoiceGenerated = useCallback((invoice) => {
        navigate(availableRoutes.billing_invoices_view.path.replace(":entityId", invoice.id))
    }, [navigate])

    useEffect(() => {
        if (!entityId) {
            navigate(availableRoutes.bookings.path)
        }

        apiClient.booking.getBooking(entityId).then((booking) => {
            setEntity(booking)
        })
    }, [apiClient, entityId, navigate])

    return (
        <>
            <Loading open={!entity} />
            {entity && <BasePage
                title={t("bookings.actions.create_invoice.form_title", { locator: entity.locator })}
                subtitle={`${entity.product.experience.name} / ${entity.product.name}`}
                actions={filterAvailableActions(actions, entity)}
            >
                <BillingClientCreateInvoiceWidget
                    defaultSelection={{ bookings: [{ id: entity.id, locator: entity.locator }] }}
                    onInvoiceGenerated={handleOnInvoiceGenerated}
                />
            </BasePage>}
        </>
    )
}

export default BookingCreateInvoiceFormPage