import { createContext, useCallback, useContext, useEffect, useMemo, useState, } from "react"
import { useTranslation } from "react-i18next"
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@mui/material"
import EntityActionsGroup from "../action/EntityActionsGroup"
import _isFunction from "lodash/isFunction"

const Context = createContext();

export function DialogProvider({ children }) {
    const { t } = useTranslation("vbms")
    const [dialog, setDialog] = useState(null)
    const [open, setOpen] = useState(false)

    useEffect(() => setOpen(dialog !== null), [dialog])

    const handleClose = useCallback(() => setDialog(null), [])

    const showDialog = useCallback(
        (title, content, actions, maxWidth = "sm") => setDialog({ title: title, content: content, actions: actions, maxWidth: maxWidth }),
        []
    )

    /**
     * Build a confirmation dialog
     */
    const showConfirmDialog = useCallback((title, message, onConfirm, onCancel) => {
        onCancel = onCancel || (() => { })

        let state = null

        const setState = (newState) => { state = newState }

        const actions = [
            {
                id: "confirm",
                title: t("common.accept"),
                onExecute: () => {
                    handleClose()
                    return onConfirm(state)
                },
            },
            {
                id: "cancel",
                title: t("common.cancel"),
                onExecute: () => {
                    handleClose()
                    return onCancel()
                },
            },
        ]

        const content = <DialogContentText>{_isFunction(message) ? message(setState) : message}</DialogContentText>

        showDialog(title, content, actions)
    }, [showDialog, t])

    const value = useMemo(() => ({ handleClose, showDialog, showConfirmDialog }), [
        handleClose,
        showDialog,
        showConfirmDialog,
    ])

    return (
        <Context.Provider value={value}>
            {children}
            {dialog && (
                <Dialog
                    open={open}
                    fullWidth={true}
                    maxWidth={dialog.maxWidth}
                >
                    {dialog.title && <DialogTitle>{_isFunction(dialog.title) ? dialog.title(dialog.content.props.selection) : dialog.title}</DialogTitle>}
                    <DialogContent sx={{ paddingBottom: 2 }}>{dialog.content}</DialogContent>
                    {dialog.actions && <DialogActions sx={{ paddingRight: 3, paddingBottom: 2 }}><EntityActionsGroup actions={dialog.actions} /></DialogActions>}
                </Dialog>
            )}
        </Context.Provider >
    )
}

export const useDialog = () => useContext(Context)