export const INITIALIZE = "INITIALIZE"
export const SET_FILTER = "SET_FILTER"
export const DELETE_FILTER = "DELETE_FILTER"
export const SET_ASYNC_FILTER_OPTIONS = "SET_ASYNC_FILTER_OPTIONS"

/**
 * Initialize table state.
 * 
 * @param {*} params
 * @returns 
 */
export const initialize = (params) => {
    return {
        type: INITIALIZE,
        ...params,
    }
}

/**
 * Set query filter fields
 *
 * @param {*} filter
 */
export const setFilter = (filter) => {
    return {
        type: SET_FILTER,
        filter: filter,
    }
}

/**
 * Reset query filter field
 *
 * @param {*} fieldName
 */
export const deleteFilter = (fieldName) => {
    return {
        type: DELETE_FILTER,
        fieldName: fieldName,
    }
}

export const setAsyncFilterOptions = (asyncFilterOptions) => {
    return {
        type: SET_ASYNC_FILTER_OPTIONS,
        asyncFilterOptions: asyncFilterOptions,
    }
}

/**
 * Reset query filter.
 *
 * @param {*} dispatch
 */
export const resetQuery = (dispatch) => {
    dispatch(setFilter({}))
}
