import { useVolcanoApiClient } from "../../../../../../context/VolcanoApiClientProvider"
import { useTranslation } from "react-i18next"
import { useNavigate } from "react-router-dom"
import { createCallbackField, createCommonStateMapping, createField } from "../../../../../../components/helpers/fieldHelper"
import { getTableActions } from "../../actions/DiscountCodeProducts/DiscountCodeProducts.actions"
import CollectionDataTable from "../../../../../../components/Datatable/CollectionDataTable"
import constants from "../../../../../../components/entity/constants"

const DiscountCodeProducts = ({ entity }) => {
    const {apiClient} = useVolcanoApiClient()
    const { t } = useTranslation("vbms")
    const navigate = useNavigate();

    const fetcher = (params) => {
        const queryFilter = {
            ...params,
            discount_code_id: entity.id
        }

        return apiClient.catalog.product.getProducts(queryFilter)
    }

    const columns = [
        createCallbackField(
        "experience",
        t("experiences.filter.title"),
        constants.STRING_TYPE,
        (value, entity) => entity.experience.name
        ),
        createField(
            "name",
            t("products.filter.title"),
            constants.STRING_TYPE
        ),
        createField(
            "state",
            t("products.fields.state"),
            constants.STRING_TYPE,
            createCommonStateMapping(t)
        ),
    ]

    if (!entity) {
        return null
    }

    return (
        <CollectionDataTable
            fetcher={fetcher}
            columns={columns}
            actions={getTableActions(apiClient, t, navigate, entity.id)}
        />
    )
}

export default DiscountCodeProducts