import { useTranslation } from "react-i18next"
import { Field } from "formik"
import { DateTimePicker, renderTimeViewClock } from "@mui/x-date-pickers"

function getDateFormatString(locale) {
  const formatObj = new Intl.DateTimeFormat(locale).formatToParts(new Date())

  return formatObj
    .map((obj) => {
      switch (obj.type) {
        case "day":
          return "dd"
        case "month":
          return "MM"
        case "year":
          return "yyyy"
        default:
          return obj.value
      }
    })
    .join("") + " HH:mm"
}

const DateTimePickerField = ({ field, form, ...props }) => {
  const { i18n } = useTranslation();
  const currentError = form.errors[field.name];

  return (
    <DateTimePicker
      openTo="day"
      views={["year", "month", "day", "hours", "minutes"]}
      format={getDateFormatString(i18n.language)}
      value={field.value || null}
      helperText={currentError}
      error={Boolean(currentError)}
      onError={(error) => {
        if (error !== currentError) {
          form.setFieldError(field.name, error);
        }
      }}
      slotProps={{
        textField: {
          size: "small",
          InputLabelProps: { shrink: true }
        }
      }}
      onChange={(date) => {
        form.setFieldValue(
          field.name,
          date
        )
      }
      }
      {...props}
    />
  )
}

const DateTimeFormField = ({ field, value }) => {

  return (
    <Field
      component={DateTimePickerField}
      name={field.id}
      label={field.label}
      required={field.required}
    />
  )
}

export default DateTimeFormField
