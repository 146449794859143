import { useTranslation } from "react-i18next"
import { useVolcanoApiClient } from "../../../context/VolcanoApiClientProvider"
import CollectionPage from "../../../layout/templates/CollectionPage/CollectionPage"
import { useCache } from "../../../context/cache.context"
import { getTableColumns } from "./BillingClientsCollection.columns"
import { getTableFilter } from "./BillingClientsCollection.filter"
import { getTableExport } from "./BillingClientsCollection.export"
import { useVolcanoAuth } from "../../../context/VolcanoAuthContext"
import { getActions } from './BillingClientsCollection.actions'
import { useNavigate } from 'react-router-dom'

const BillingClientsCollectionPage = () => {
    const { apiClient } = useVolcanoApiClient()
    const { t } = useTranslation("vbms")
    const navigate = useNavigate()
    const cache = useCache()
    const volcanoAuth = useVolcanoAuth()

    const table = {
        columns: getTableColumns(t, volcanoAuth.user),
        fetcher: (params) => apiClient.billingInformation.getBillingInformations(params)
    }

    return (
        <CollectionPage
            title={t("billing_clients.title")}
            subtitle={t("billing_clients.subtitle")}
            actions={getActions(t, navigate, volcanoAuth.user)}
            table={table}
            filterConfig={getTableFilter(t, apiClient, volcanoAuth.user, cache)}
            onExport={getTableExport(apiClient, t)}
        />
    )
}

export default BillingClientsCollectionPage
