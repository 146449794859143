import { useTranslation } from "react-i18next"
import { createFormFieldset, createEditableListFormField } from "../../../components/helpers/fieldHelper"
import AsyncEntityForm from "../../../components/form/AsyncEntityForm"
import { useVolcanoApiClient } from "../../../context/VolcanoApiClientProvider"
import ValueSelectorDialog from "../../../components/ValueSelectorDialog/ValueSelectorDialog"
import { useCache } from "../../../context/cache.context"
import {getProductsFilter} from "../../../lib/collection-filters"


const RouteProductsForm = ({ selection, onAction }) => {
    const { apiClient } = useVolcanoApiClient()
    const { t } = useTranslation("vbms")
    const cache = useCache()
    const productsFilterField = getProductsFilter(apiClient, t, true, cache)

    const fetcher = () => Promise.resolve(selection)

    const handleOnConfirm = (value) => {
        onAction({
            products: value.products.map((item) => { return { id: item.id } })
        })
    }

    const formContent = [
        createFormFieldset("", [
            createEditableListFormField(
                "products",
                "",
                "name",
                t("routes.actions.products.modal_title"),
                ({ onConfirm, onCancel }) => <ValueSelectorDialog
                    table={productsFilterField.table}
                    selected={productsFilterField.selected}
                    valueFormatter={productsFilterField.valueFormatter}
                    multipleSelection={true}
                    onConfirm={onConfirm}
                    onCancel={onCancel}
                />,
                true,
                true
            ),
        ])
    ]

    return (
        <AsyncEntityForm
            fetcher={fetcher}
            onSave={handleOnConfirm}
            formContent={formContent}
        />
    )
}

export default RouteProductsForm
