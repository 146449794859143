import { useEffect, useState } from "react"

const useScrollPosition = () => {
    const [scrollPosition, setScrollPosition] = useState(0)
    const [isInitialPosition, setIsInitialPosition] = useState(true)

    useEffect(() => {
        const updatePosition = () => {
            setScrollPosition(window.pageYOffset)
            setIsInitialPosition(window.pageYOffset === 0)
        }
        window.addEventListener("scroll", updatePosition)
        updatePosition()
        return () => window.removeEventListener("scroll", updatePosition)
    }, [])

    return {
        scrollPosition,
        isInitialPosition
    }
}

export default useScrollPosition