import { useCallback, useEffect, useState } from "react"
import { Box, Stack } from "@mui/material"
import DataWrapper from "../Display/DataWrapper"
import { useTranslation } from "react-i18next"
import EntityActionsGroup from "../action/EntityActionsGroup"
import _isFunction from "lodash/isFunction"
import ValueSelectorTable from "./ValueSelectorTable"
import ChipList from "../Display/ChipList"

const ValueSelectorDialog = ({ multipleSelection = false, table, valueFormatter, selected, onConfirm, onCancel }) => {
    const { t, i18n } = useTranslation("vbms")
    const [selection, setSelection] = useState(selected ?? [])

    const onSelectionChangeHandler = useCallback((newSelection, items) => {
        if (multipleSelection) {
            setSelection((prevSelection) => {
                // only update if the selection change
                if (newSelection.length === prevSelection.length) {
                    return prevSelection
                }
                // remove deleted elements from the selection
                const currentSelection = prevSelection.filter((item) => newSelection.includes(item.id))

                // add new selected elements to the selection
                const newItems = items
                    .filter((item) => currentSelection.every((selectedItem) => selectedItem.id !== item.id))
                    .map((item) => valueFormatter(item, i18n.language))

                return currentSelection.concat(newItems)
            })
        } else if (newSelection.length > 0) {
            onConfirm(items.map((item) => valueFormatter(item, i18n.language)))
        }
    }, [i18n.language, multipleSelection, valueFormatter, onConfirm, setSelection])

    const onValueDeleted = useCallback((id) => {
        setSelection((prevSelection) => prevSelection.filter((item) => item.id !== id))
    }, [setSelection])

    let actions = []
    if (multipleSelection) {
        actions.push({
            id: "confirm",
            title: t("common.accept"),
            disabled: selection.length === 0,
            onExecute: () => onConfirm(selection),
        })
    }

    actions.push({
        id: "cancel",
        title: t("common.cancel"),
        onExecute: () => {
            if (_isFunction(onCancel)) {
                return onCancel()
            }
        },
    })

    table.rowsPerPage = 10

    return (
        <Stack spacing={2}>
            <ValueSelectorTable
                multipleSelection={multipleSelection}
                table={table}
                selected={selection.map((value) => value.id)}
                onSelectionChange={onSelectionChangeHandler}
            />
            {multipleSelection && (
                <DataWrapper
                    title={selection.length > 0 ? t("components.value_selector_dialog.selected_values_title", { count: selection.length }) : t("components.value_selector_dialog.selected_values_empty_title")}
                >
                    <ChipList selection={selection} onValueDeleted={!multipleSelection ? onValueDeleted : null} />
                </DataWrapper>
            )}
            <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
                <EntityActionsGroup actions={actions} />
            </Box>
        </Stack>
    )
}

export default ValueSelectorDialog