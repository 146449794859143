import { useTranslation } from 'react-i18next';
import constants from '../../../../../components/entity/constants';
import { createCallbackField, createField } from '../../../../../components/helpers/fieldHelper';
import { useVolcanoApiClient } from '../../../../../context/VolcanoApiClientProvider';
import CollectionDataTable from '../../../../../components/Datatable/CollectionDataTable';
import { Box, Collapse, IconButton, Table, TableBody, TableCell, TableContainer, TableRow, Typography } from '@mui/material';
import { useState } from 'react';
import { KeyboardArrowDownOutlined, KeyboardArrowUpOutlined } from '@mui/icons-material';
import DataTableCell from '../../../../../components/Datatable/components/DataTableCell';
import Title from "../../../../../components/Display/Title";
import _isArray from "lodash/isArray"
import VisibilityIcon from '@mui/icons-material/Visibility';
import HistoricalElement from '../../../../../components/Volcano/Intermediaries/HistoricalElement';

const getIntermediaryEntityActionLogActions = (t) => ([
    {
        title: t("intermediaries.actions.view_changes.button"),
        scope: "row",
        icon: VisibilityIcon,
        reloadOnSuccess: true,
        showLoading: true,
        withDialog: {
            title: t("intermediaries.view.updated_fields"),
            content: (selection) => 
                    <HistoricalElement
                        selection={selection}
                        />
            
        },
        onExecute: (values, progressHandler, onSuccess, onError) => {

        },
    }
])


const IntermediaryEntityActionLogs = ({ entity }) => {
    const { apiClient } = useVolcanoApiClient()
    const { t } = useTranslation("vbms")

    const actions = getIntermediaryEntityActionLogActions(t)

    const columns = [
        createField(
            "created",
            t("common.fields.date"),
            constants.DATETIME_TYPE,
            null,
            true,
            true,
            null,
            "right"
        ),
        createField(
            "user.name",
            t("bookings.fields.history.username"),
            constants.STRING_TYPE
        ),
        createCallbackField(
            "data",
            t("intermediaries.view.updated_fields"),
            constants.TEXT_TYPE,
            (value) => {
                return value.map((field) => t("intermediaries.fields." + field.field)).join(', ')
            }
        ),
    ]

    if (!entity) {
        return null
    }

    return (
        <CollectionDataTable
            //filterConfig={[]}
            ignoreSearchParams={true}
            fetcher={(params) => {
                /*                params = {
                                    crm_intermediary_id: entity.id
                                    , ...params
                                }*/
                return apiClient.intermediary.getEntityActionLogs(entity.id, {})
            }}
            columns={columns}
            actions={actions}
        />
    )

}

export default IntermediaryEntityActionLogs