import { useTranslation } from "react-i18next"
import { useVolcanoApiClient } from "../../../context/VolcanoApiClientProvider"
import { useParams } from 'react-router-dom'
import constants from "../../../components/entity/constants"
import AsyncEntityPage from "../../../layout/templates/AsyncContentPage/AsyncEntityPage"
import { createField, createSuggestionTypeMapping } from "../../../components/helpers/fieldHelper"
import { getHeaderContent } from "./SuggestionPage.header.js"
import SuggestionContent from "./components/tabs/SuggestionContent"
import { getActions } from "./SuggestionPage.actions"

const SuggestionsPage = () => {
    const { t } = useTranslation()
    const { entityId } = useParams()
    const { apiClient } = useVolcanoApiClient()

    const fetcher = () => apiClient.suggestion
        .getSuggestion(entityId)
        .then((suggestion) => {
            return suggestion
        });

    return (
        <AsyncEntityPage
            fetcher={fetcher}
            title={createField(
                "suggestion_type",
                t("suggestions.fields.suggestion_type"),
                constants.STRING_TYPE,
                createSuggestionTypeMapping(t)
            )}
            headerContent={getHeaderContent(t)}
            content={SuggestionContent}
            actions={getActions(apiClient, t)}
        />
    );
}

export default SuggestionsPage;