import { Checkbox } from '@mui/material';
import { FormControlLabel } from '@mui/material';
import { TreeItem } from '@mui/x-tree-view';
import { TreeView } from '@mui/x-tree-view';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import _isArray from 'lodash/isArray';
import { useState } from 'react';
import { getSelectedNodesToExpand } from '../../lib/treeview';
import { Field } from 'formik';

const mapAddPValues = (defValues) => {
    return defValues.map((value) => `p_${value}`)
}

const TreeViewItem = ({ options, expandedItems, multiple, defaultValue, onChange }) => {
    const [expanded, setExpanded] = useState(expandedItems)
    const [selected, setSelected] = useState(mapAddPValues(defaultValue || []))

    function getOnChange(checked, item) {
        const selectedValues = checked
        ? [...selected, 'p_'+item.id]
        : selected.filter((value) => 'p_'+item.id !== value);

        onChange(selectedValues);
    }
    
    
    const buildTreeCheckboxItems = (value) => {
        return value.map((item) => {
            if (_isArray(item.value)) {
                return (
                    <TreeItem key={item.id} nodeId={item.id.toString()} label={item.name}>
                        {buildTreeCheckboxItems(item.value)}
                    </TreeItem>
                )
            }
    
            return (
                <TreeItem 
                    key={item.id} 
                    nodeId={`p_${item.id.toString()}`} 
                    label={
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={selected.some(val => val === `p_`+item.id.toString())}
                                    onChange={(event) =>
                                        getOnChange(event.currentTarget.checked, item)
                                    } 
                                />
                            }
                            label={<>{item.name}</>}
                            key={item.id}
                        />
                    }>
                </TreeItem>
            )
        })
    }

    const handleToggle = (e, nodeIds) => {
        setExpanded(nodeIds);
    };

    const handleSelect = (e, nodeIds) => {
        var nodeId = nodeIds[0];
        if (nodeId.indexOf("p_") === 0) {
            var selectedValues = selected.filter((nodeId) => nodeId === nodeIds[0]);
            if (selectedValues.length > 0) {
                selectedValues = selected.filter((value) => nodeId !== value);
            } else {
                selectedValues = [...selected, nodeId];
            }
            nodeIds = selectedValues;
            setSelected(nodeIds);
        }
    }

    if (!_isArray(options) || options.length === 0) {
        return
    }

    return (
        <TreeView
            aria-label="multi-select"
            defaultCollapseIcon={<ExpandMoreIcon />}
            defaultExpandIcon={<ChevronRightIcon />}
            defaultExpanded={expandedItems}
//            selected={selected}
            onNodeToggle={handleToggle}
            onNodeSelect={handleSelect}
            multiSelect={multiple}
        >
            {buildTreeCheckboxItems(options)}
        </TreeView>
    )
}

const TreeViewFormField = ({ field, value }) => {
    const configField = field

    return (
        <Field
            id={field.id}
            name={field.id}
        >
            {({
                field,
                form: { values, setFieldValue, setFieldTouched },
                meta,
            }) => {
                return (
                    <TreeViewItem
                        options={configField.options}
                        expandedItems={getSelectedNodesToExpand(configField.options, value)}
                        multiple={configField.multiple}
                        defaultValue={meta.initialValue}
                        onChange={(value) => {
                            setFieldValue(field.name, value, true)
                            setFieldTouched(field.name, true, false)
                        }}
                    />
                )
            }}
        </Field>
    )
}

export default TreeViewFormField;