const checkTaskState = (apiClient, t, taskId, progressHandler) => {
    return new Promise((resolve, reject) => {
        const intervalId = setInterval(() => {
            apiClient.task.getTask(taskId).then((task) => {
                progressHandler(Math.round((task.summary.processed / task.summary.total) * 100), t("tasks.actions.request.entities_track", { count: task.summary.processed, total: task.summary.total }))
                if (task.state === "completed") {
                    clearInterval(intervalId)
                    resolve(task)
                }
            })
        }, 2000)
    })
}

const createTask = (apiClient, t, progressHandler, onSuccess, onError, operation, entityType, entities, payload = null) => {
    return apiClient.task
        .createEntitiesTask(operation, entityType, entities, payload)
        .then((task) => {
            // task is created, track it
            return checkTaskState(apiClient, t, task.id, progressHandler).then((task) => {
                onSuccess(t("tasks.actions.request.entities_success"))
                return task
            })
        })
        .catch((error) => {
            onError(t("tasks.actions.request.entities_error"))
        })
}

export default createTask