import constants from "../../../components/entity/constants";
import { createBooleanFormField, createField } from '../../../components/helpers/fieldHelper';

export const getTableFilter = (t, apiClient, user, cache) => ([
    createField(
        "name",
        t("settings_roles.fields.name"),
        constants.TEXT_TYPE
    ),
    createField(
        "slug",
        t("settings_roles.fields.slug"),
        constants.TEXT_TYPE
    ),
    createBooleanFormField(
        t,
        "is_system",
        t("settings_roles.fields.is_system"),
    ),
])