import _isFunction from "lodash/isFunction"
import DataSet from "../Display/DataSet"
import EntityField from "./EntityField"

const DatasetEntityFacet = ({ title, entity, fields, variant = "inline" }) => {
  const data = fields
    .filter((field) =>
      _isFunction(field.condition) ? field.condition(entity) : true
    )
    .map((field) => {
      return {
        key: field.id,
        name: field.label,
        value: <EntityField field={field} entity={entity} />,
      };
    });

  return (
    <DataSet variant={variant} titleVariant="label" title={title} data={data} />
  )
}

export default DatasetEntityFacet
