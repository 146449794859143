import { createTheme, CssBaseline, ThemeProvider } from "@mui/material"
import { createContext, useContext, useEffect, useMemo } from "react"
import theme from '../config/theme';
import { useTranslation } from "react-i18next"
import { esES as localeEsES, enUS as localeEnUS } from "@mui/material/locale"
import { esES as datePickerEsES, enUS as datePickerEnUS } from "@mui/x-date-pickers/locales"
import { useVolcanoApiClient } from './VolcanoApiClientProvider';

const Context = createContext()

const localeMapping = {
    'en': localeEnUS,
    'es': localeEsES,
    'datePicker': {
        'en': datePickerEnUS,
        'es': datePickerEsES,
    }
}

export const VolcanoThemeProvider = ({ children }) => {
    const { siteConfig } = useVolcanoApiClient()
    const { t, i18n } = useTranslation("vbms")

    const contextValue = {
        isAdmin: siteConfig.is_admin,
        title: siteConfig.title,
        logo: siteConfig.images.logo,
        loginBackground: siteConfig.images.login_background,
    }

    useEffect(() => {
        document.title = t(contextValue.title)
    }, [contextValue.title, t])

    const localeTheme = useMemo(
        () => createTheme(
            theme,
            localeMapping[i18n.language],
            localeMapping.datePicker[i18n.language],
        ),
        [i18n.language]
    );

    return (
        <Context.Provider value={contextValue} >
            <ThemeProvider theme={localeTheme}>
                <CssBaseline />
                {children}
            </ThemeProvider>
        </Context.Provider >
    )
}

export const useVolcanoTheme = () => useContext(Context)