import { useState } from "react"
import { Stack } from "@mui/material"
import { useTranslation } from "react-i18next"
import { parseBillingClientFromApi } from "../../BillingClients.functions"
import { SearchForm } from "./SearchForm"
import { BillingClientsList } from "./BillingClientsList"

const ClientSelector = (props) => {
    const {
        user,
        countriesFetcher,
        enterprisesFetcher,
        billingClientsFetcher,
        collaboratorsFetcher,
        onSelection
    } = props

    const { t } = useTranslation()

    const [billingClients, setBillingClients] = useState([])
    const [isReady, setIsReady] = useState(false)

    const handleOnBillingClientsLoaded = (billingClients) => {
        if (billingClients.length === 1) {
            onSelection({ billingClient: parseBillingClientFromApi(billingClients[0]) })
            return
        }

        setBillingClients(billingClients)
        setIsReady(true)
    }

    const handleOnCollaboratorLoaded = (collaborator) => {
        onSelection({
            billingClient: {
                is_collaborator: true,
                collaborator_id: collaborator.id,
                enterprise: collaborator.enterprise,
                vat_number: collaborator.at.vat_number,
                name: collaborator.at.name,
                type: collaborator.at.type,
                accountancy_config: {
                    account_id: collaborator.accountancy_config?.account_id
                },
                contact_details: {
                    address_type: collaborator.at.address_type,
                    address: collaborator.at.address,
                    locality: collaborator.at.locality,
                    state: collaborator.at.state,
                    postal_code: collaborator.at.postal_code,
                    country: collaborator.at.country,
                    email: collaborator.email,
                    phone: collaborator.phone,
                }
            }
        })
        setIsReady(true)
    }

    const handleOnCreateRequest = (data) => {
        onSelection({
            billingClient: {
                enterprise: {
                    id: user?.corporate_account?.enterprise?.id
                },
                vat_number: data.vat_number,
                contact_details: {
                    country: { id: data.country_id }
                }
            }
        })
    }

    const handleOnBillingClientSelection = (billingClient) => {
        onSelection({ billingClient: parseBillingClientFromApi(billingClient) })
    }

    const setClientBusiness = (value) => {
        onSelection({
            billingClient: {
                is_collaborator: true,
                collaborator_id: value.collaborator_id,
                enterprise: { id: value?.crm_entity?.enterprise_id},
                vat_number: value?.crm_contact_billing?.crm_legal_person_contact?.vat_number,
                name: value?.crm_contact_billing?.crm_legal_person_contact?.name,
                type: value.type,
                accountancy_config: {
                    account_id: value.crm_intermediary_detail?.account_id
                },
                contact_details: {
                    address_type: value.crm_contact_billing?.crm_legal_person_contact?.crm_contact_detail?.address_road,
                    address: value.crm_contact_billing?.crm_legal_person_contact?.crm_contact_detail?.address,
                    locality: value.crm_contact_billing?.crm_legal_person_contact?.crm_contact_detail?.locality,
                    state: value.crm_contact_billing?.crm_legal_person_contact?.crm_contact_detail?.state,
                    postal_code: value.crm_contact_billing?.crm_legal_person_contact?.crm_contact_detail?.postal_code,
                    country:  { id: value.crm_contact_billing?.crm_legal_person_contact?.crm_contact_detail?.country_id },
                    email: value.crm_contact_billing?.crm_legal_person_contact?.crm_contact_detail?.email,
                    phone: value.crm_contact_billing?.crm_legal_person_contact?.crm_contact_detail?.phone,
                }
            }
        })
    }

    const setClientPerson = (value) => {
        onSelection({
            billingClient: {
                is_collaborator: false,
                collaborator_id: null,
                enterprise: value.enterprise,
                vat_number: value.vat_number,
                vat_number_type: value.vat_number_type,
                name: value.name,
                type: value.type,
                accountancy_config: {
                    account_id: value.accountancy_config?.account_id
                },
                contact_details: {
                    address_type: { id: value.address_road_id },
                    address: value.address,
                    locality: value.locality,
                    state: value.state,
                    postal_code: value.postal_code,
                    country: { id: value.country_id },
                    email: value.email,
                    phone: value.phone,
                }
            }
        })
    }
    const handleOnClientLoaded = (value) => {
        value = value[0];
        if (value.type === 'business') {
            setClientBusiness(value);
        } else {
            setClientPerson(value);
        }
    }

    return (
        <Stack spacing={2}>
            <SearchForm
                user={user}
                countriesFetcher={countriesFetcher}
                enterprisesFetcher={enterprisesFetcher}
                billingClientsFetcher={billingClientsFetcher}
                collaboratorsFetcher={collaboratorsFetcher}
                t={t}
                onBillingClientsLoaded={handleOnBillingClientsLoaded}
                onCollaboratorLoaded={handleOnCollaboratorLoaded}
                onClientLoaded={handleOnClientLoaded}
                onCreateRequest={handleOnCreateRequest}
                onSearchClick={() => setIsReady(false)}
            />
            {isReady && billingClients.length > 0 &&
                <BillingClientsList
                    billingClients={billingClients}
                    title={t("billing_client_create_invoice_widget.steps.initial.search_form_select")}
                    onSelection={handleOnBillingClientSelection}
                />}
        </Stack>
    )
}

export default ClientSelector
