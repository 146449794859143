import availableRoutes from "../../../../routes/availableRoutes"
import _has from "lodash/has"
import LodgingForm from "../LodgingForm";
import LodgingPickupPointsForm from "../LodgingPickupPointsForm";

export const getActions = (apiClient, t, user, navigate, entityId) => {


    return [
        {
            id: "edit",
            title: t("common.edit"),
            reloadOnSuccess: true,
            showLoading: true,
            withDialog: {
                title: t("lodgings.actions.edit.modal_title"),
                content: LodgingForm
            },
            onExecute: (item, progressHandler, onSuccess, onError) => {
                return apiClient.activityManager.lodging.editLodging(entityId, item)
                    .then((result) => {
                        onSuccess(
                            t("lodgings.actions.edit.confirm", {
                                name: result.name,
                            })
                        )

                        return { ...result, id: entityId }
                    })
                    .catch(() => {
                        onError(
                            t("lodgings.actions.edit.error", {
                                name: item.name,
                            })
                        )
                    })
            },
            condition: (item) => _has(item.getActions(), "edit")
        },
        {
            id: "edit_pickup_points",
            title: t("lodgings.actions.edit_pickup_points.button"),
            scope: "lodging",
            icon: null,
            showLoading: true,
            withDialog: {
                title: t("lodgings.actions.edit_pickup_points.modal_title"),
                content: LodgingPickupPointsForm,
            },
            reloadOnSuccess: true,
            onExecute: (data, progressHandler, onSuccess, onError) => {
                return apiClient.activityManager.lodging.editPickupPoints(entityId, data)
                    .then((result) => {
                        onSuccess(
                            t("lodgings.actions.edit_pickup_points.confirm", {
                                name: result.name,
                            })
                        )

                        return result
                    })
                    .catch(() => {
                        onError(
                            t("lodgings.actions.edit_pickup_points.error", {
                                name: data.name,
                            })
                        )
                    })
            },

        },
        {
            id: "delete",
            title: t("common.delete"),
            reloadOnSuccess: true,
            showLoading: true,
            confirmDialog: {
                title: t("lodgings.actions.delete.modal_title"),
                content: (item) =>
                    t("lodgings.actions.delete.modal_content", {
                        name: item.name,
                    }),
            },
            onExecute: (item, progressHandler, onSuccess, onError) => {
                return apiClient.activityManager.lodging.deleteLodging(item.id)
                    .then(() => {
                        onSuccess(
                            t("lodgings.actions.delete.confirm", {
                                name: item.name,
                            })
                        )
                        navigate(availableRoutes.lodgings.path)
                    })
                    .catch(() => {
                        onError(
                            t("lodgings.actions.delete.error", {
                                name: item.name,
                            })
                        )
                    })
            },
            condition: (item) => _has(item.getActions(), "delete")
        },
    ]
}
