import { downloadFile } from '../../../lib/utils'
import { createTabBarItem } from '../../../components/helpers/fieldHelper'
import InvoicesTable from '../../../components/Volcano/Invoices/InvoicesTable'
import BillingClientRelatedBillingClients from './components/tabs/BillingClientRelatedBillingClients';

export const getTabNavigationItems = (user, apiClient, t) => [
    createTabBarItem(
        t("bookings.navbar.invoices"),
        ({ entity, data }) => (
            <InvoicesTable
                data={data.getItems()}
                onDownload={
                    (invoice) => {
                        apiClient.invoice
                            .getInvoicePdf(invoice.id)
                            .then((pdf) =>
                                downloadFile(pdf, "invoice_" + invoice.title + ".pdf")
                            );
                    }
                }
            />
        ),
        (billingClient) => apiClient.invoice.getInvoices({ billing_client_id: billingClient.id })
    ),
    createTabBarItem(
        t("billing_clients.view.navbar.related_billing_clients"),
        ({ entity, data }) => (
            <BillingClientRelatedBillingClients data={data} />
        ),
        (billingClient) => {
            return apiClient.billingInformation.getBillingInformations({
                limit: 1000,
                vat_number: billingClient.vat_number,
                enterprise_id: billingClient.enterprise.id,
                country: billingClient.contact_details.country.id
            }).then((result) => {
                return result.getItems().filter((item) => item.id !== billingClient.id)
            })
        }
    ),
]