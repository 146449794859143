import { useTranslation } from 'react-i18next';
import DataSet from './DataSet';
import _isEmpty from 'lodash/isEmpty';
import DateTimeFieldValue from '../entity/DateTimeFieldValue';
import _get from 'lodash/get';
import _isObject from 'lodash/isObject';
import ContactDetailsFieldValue from '../entity/ContactDetailsFieldValue';
import BooleanFieldValue from '../entity/BooleanFieldValue';
import MappedFieldValue from '../entity/MappedFieldValue';

/**
 * Returns a date object with the time from the session 
 * @param {*} date 
 * @param {*} session 
 * @returns 
 */
const processDate = (date, session) => {
    if (session.session === "day_wide") {
        return date
    }

    // returns a date object with the time from the session with format HH:mm:ss
    const time = session.session.split(":")
    const result = new Date(date)
    result.setHours(time[0])
    result.setMinutes(time[1])
    result.setSeconds(time[2])

    return result
}

const processDataset = (t, data, config) => {

    if (_isEmpty(data) || _isEmpty(config)) {
        return []
    }

    let result = []

    config.forEach((item) => {
        const value = _get(data, item.field.id) ? _get(data, item.field.id) : _get(data, item.field)

        if (!value) {
            return
        }

        let content = null

        if (item.type === "date" || item.type === "datetime") {
            const { date, session } = item.value(data)
            item.type = (session && session.session === "day_wide") ? "date" : "datetime"
            content = <DateTimeFieldValue value={processDate(date, session)} format={item.type} />
        } else if (item.type === "address") {
            content = <ContactDetailsFieldValue value={value} />
        } else if (item.type === "boolean") {
            content = <BooleanFieldValue value={value} />
        } else if (_isObject(item.mapping)) {
            content = <MappedFieldValue mapping={item.mapping} value={value} />
        } else {
            content = value
        }

        result.push({
            name: t(item.label),
            value: content
        })
    })

    return result
}

const DataSetWrapper = ({ title, data, config, direction, variant }) => {
    const { t } = useTranslation("vbms")

    if (_isEmpty(data)) {
        return null
    }

    return (
        <DataSet
            title={title}
            titleLevel="body1"
            direction={direction}
            variant={variant}
            data={processDataset(t, data, config)}
        />
    )
}

export default DataSetWrapper