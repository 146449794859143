import { useState, useCallback } from "react";
import { useTranslation } from "react-i18next"
import { useVolcanoApiClient } from "../../context/VolcanoApiClientProvider"
import { useNavigate, useParams } from "react-router-dom"
import { createCallbackField, createField, createFormFieldGroup, createFormFieldset, createFormField, createSelectFormField, discountCodesTypeMapping, createDependentFormField, createFormFieldOption } from '../../components/helpers/fieldHelper'
import AsyncEntityFormPage from "../../layout/templates/AsyncContentPage/AsyncEntityFormPage"
import availableRoutes from "../../routes/availableRoutes";
import Loading from "../../components/Display/Loading"
import constants from "../../components/entity/constants"
import useSnackBars from "../../components/Snackbar/snack-bar.context"
import startOfToday from "date-fns/startOfToday"
import { useCache } from "../../context/cache.context";
import { buildOptions, sitesFetcher as extSitesFetcher } from '../../lib/form-options-fetchers';
import { useVolcanoAuth } from "../../context/VolcanoAuthContext";
import _get from "lodash/get"
import { isEmpty } from "lodash";

const DiscountCodeFormPage = () => {
    const navigate = useNavigate()
    const { t } = useTranslation("vbms")
    const { apiClient } = useVolcanoApiClient()
    const { entityId } = useParams()
    const { addAlert } = useSnackBars()
    const cache = useCache()
    const volcanoAuth = useVolcanoAuth()

    const [isReady, setIsReady] = useState(false)

    const sitesFetcher = useCallback(() => {
        return extSitesFetcher(cache, apiClient)
    }, [cache, apiClient])


    const fetcher = useCallback(() => {
        setIsReady(false)

        if (!entityId) {
            setIsReady(true)
            return new Promise((res) => res({}))
        }

        return apiClient.catalog.discountCode.getDiscountCode(entityId).then((discountCode) => {
            setIsReady(true)
            return discountCode
        })
    }, [apiClient, entityId])

    const onSaveHandler = (data) => {
        setIsReady(false)

        let discountCode = data

        // Checking if discount code has unlimited uses
        if (data?.use_unlimited === "true" || data?.use_unlimited === true) {
            discountCode = {...discountCode, use_limit: -1}
        } else {
            // 0 or negative values cannot be established in case the use is limited
            if (parseInt(data.use_limit) < 1) {
                addAlert(t("discount_codes.actions.add.error_use_limit"))
                setIsReady(true)
                return 
            } else {
                discountCode = {...discountCode, use_limit: parseInt(data.use_limit)}
            }
        }

        if (!isEmpty(data.site_id)) {
            discountCode["site_id"] = parseInt(data.site_id)
        }

        if (!entityId) {
            discountCode = {...discountCode, enterprise_id: parseInt(_get(volcanoAuth.user, "corporate_account.enterprise.id", "-1"))}
            apiClient.catalog.discountCode.addDiscountCode(discountCode)
                .then(() => {
                    navigate(availableRoutes.discount_codes.path)
                    addAlert(t("discount_codes.actions.add.confirm"))
                })
                .catch((error) => {
                    setIsReady(true)
                    addAlert("Error: " + error.message)
                })
        } else {
            apiClient.catalog.discountCode.editDiscountCode(entityId, discountCode)
                .then((discountCode) => {
                    navigate(`${availableRoutes.discount_codes.path}/${entityId}`)
                    addAlert(t("discount_codes.actions.edit.confirm"))
                })
                .catch((error) => {
                    setIsReady(true)
                    addAlert("Error: " + error.message)
                })
        }
    }

    const formContent = (entityId) => [
        createFormFieldset(t("discount_codes.form.fieldset_information"), [
            createFormFieldGroup([
                createFormField(
                    createField(
                        "name",
                        t("discount_codes.fields.name"),
                        constants.STRING_TYPE
                    ),
                    true,
                    null,
                    false,
                    true
                ),
                createFormField(
                    createField(
                        "value",
                        t("discount_codes.fields.value"),
                        constants.NUMERIC_TYPE
                    ),
                    true
                )
            ]),
            createFormField(
                createDependentFormField(
                    "site_id",
                    t("discount_codes.fields.site"),
                    constants.SELECT_TYPE,
                    (values) => {
                        const enterpriseId = parseInt(_get(volcanoAuth.user, "corporate_account.enterprise.id", "-1"))
                        return sitesFetcher(cache, apiClient)
                            .then((sites) => {
                                if (enterpriseId !== -1) sites = sites.filter((item) => item.enterprise.id === enterpriseId)

                                return [createFormFieldOption("", t("discount_codes.constants.no_site_selected")), ...buildOptions(sites, t, "id", "name", false)]
                            })
                    },
                ),
                false,
                ""
            ),
            createFormField(
                createSelectFormField(
                    "type",
                    t("discount_codes.fields.type.name"),
                    Object.entries(discountCodesTypeMapping(t)).map(([key, value]) => ({ value: key, label: value }))
                ),
                false,
                1
            ),
            createFormField(
                createField(
                    "active",
                    t("common.states.active"),
                    constants.BOOLEAN_TYPE
                ),
                null,
                true
            )
        ]),
        createFormFieldset(t("discount_codes.view.facet_information_validity"), [
            createFormFieldGroup([
                createCallbackField(
                    "valid_from",
                    t("discount_codes.fields.valid_from"),
                    constants.DATE_TYPE,
                    (value) => value ? new Date(value) : startOfToday()
                ),
                createCallbackField(
                    "valid_to",
                    t("discount_codes.fields.valid_to"),
                    constants.DATE_TYPE,
                    (value) => value ? new Date(value) : null
                ),
                createFormField(
                    createCallbackField(
                        "use_unlimited",
                        t("discount_codes.constants.unlimited"),
                        constants.BOOLEAN_TYPE,
                        (value, entity) => {
                            return entity?.use_limit ? "false" : "true"
                        }
                    ),
                    true
                ),
                createFormField(
                    createDependentFormField(
                        "use_limit",
                        t("discount_codes.fields.use_limit"),
                        constants.NUMERIC_TYPE,
                        (values) => {
                            if (values?.use_unlimited === "true" || values?.use_unlimited === true) {
                                return null
                            }
        
                            return 1
                        }
                    ),
                    true,
                    1
                )
            ]),
        ]),
        
    ]

    return (
        <>
            <Loading open={!isReady} />
            <AsyncEntityFormPage
                fetcher={fetcher}
                title={
                    createCallbackField(
                        "name",
                        t("discount_codes.form.add_title"),
                        constants.STRING_TYPE,
                        (value) => !value ? t("discount_codes.form.add_title") : t("discount_codes.form.edit_title", { name: value })
                    )
                }
                subtitle={
                    createCallbackField(
                        "usages",
                        t("discount_codes.fields.usages"),
                        constants.STRING_TYPE,
                        (value, entity) => entity.name ? t("discount_codes.constants.usage_times", { usages: value }) : null
                    )
                }
                onSave={onSaveHandler}
                formContent={formContent(entityId)}
            />
        </>

    )
}

export default DiscountCodeFormPage