import { useTranslation } from "react-i18next"
import DataSetWrapper from "../../../../../../components/Display/DataSetWrapper";
import constants from '../../../../../../components/entity/constants'
import { Grid, Box } from "@mui/material"


const billingDataFields = (t) => [
    {
        field: "name",
        label: "enterprise.fields.name",
        type: constants.STRING_TYPE,
    },
    {
        field: "cif",
        label: "common.fields.vat_type.nif",
        type: constants.STRING_TYPE
    },
    {
        field: "address",
        label: "enterprise.fields.address",
        type: constants.STRING_TYPE
    },
    {
        field: "cp",
        label: "enterprise.fields.cp",
        type: constants.STRING_TYPE
    },
    {
        field: "city",
        label: "enterprise.fields.city",
        type: constants.STRING_TYPE
    },
    {
        field: "phone",
        label: "enterprise.fields.phone",
        type: constants.STRING_TYPE
    },
    {
        field: "phone_other",
        label: "enterprise.fields.phone_other",
        type: constants.STRING_TYPE
    },
    {
        field: "fax",
        label: "enterprise.fields.fax",
        type: constants.STRING_TYPE
    },
    {
        field: "web",
        label: "enterprise.fields.web",
        type: constants.STRING_TYPE
    }
]

const BaseGridContainer = ({ children }) => {
    return (
        <Grid
            item
            xs={12}
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="flex-start"
            spacing={3}
            pt={2}
            pb={2}
        >
            {children.map((item, index) => (
                <Grid item xs={12} md="auto" key={index}>
                    {item}
                </Grid>
             ))}
        </Grid>
    )
}

const EnterpriseBilling = ({ entity }) => {
    const { t } = useTranslation()

    if (!entity) {
        return null;
    }

    return (
        <Box>
            <BaseGridContainer>
                <Box></Box>
                <DataSetWrapper
                    title={t("enterprise.view.billing")}
                    data={entity}
                    config={billingDataFields(t)}
                    variant="inline"
                />
            </BaseGridContainer>
        </Box>
    )
}

export default EnterpriseBilling