import availableRoutes from "../../../../routes/availableRoutes"
import { Edit } from "@mui/icons-material"
import _has from 'lodash/has'

export const getActions = (apiClient, t, itemProps, navigate, entityId = null) => {
    const { scope, icon, reloadOnSuccess, showLoading } = { ...itemProps }
    return [
        {
            id: "edit",
            title: t("common.edit"),
            scope: scope,
            icon: icon && Edit,
            reloadOnSuccess: reloadOnSuccess,
            showLoading: showLoading,
            onExecute: (facilityBookLine) => {
                return Promise.resolve(facilityBookLine)
                    .then((facilityBookLine) => {
                        navigate(availableRoutes.facility_book_lines_edit.path.replace(":entityId", entityId ?? facilityBookLine.id))
                    })
            },
            condition: (facilityBookLine) => _has(facilityBookLine.getActions(), "edit")
        }
    ]
}