import { ToggleButtonGroup, Stack, ToggleButton, FormLabel } from '@mui/material';
import { useState } from 'react';

const ToggleButtonGroupField = ({ field, value, onChange }) => {
    const [data, setData] = useState(value)

    const handleOnOptionClick = (value) => {
        if (value !== data) {
            setData(value)
            onChange(value)
        }
    }

    return (
        <Stack spacing={2} alignItems="center">
            <ToggleButtonGroup
                name={field.id}
                size="small"
                aria-label={field.label}
                value={data}
                onChange={(_, value) => handleOnOptionClick(value)}
                exclusive={true}
            >
                {field.options.map((option, index) => {
                    return (
                        <ToggleButton
                            key={index}
                            value={option.value}
                        >
                            {option.icon}
                            <FormLabel sx={{ marginLeft: 0.5, cursor: "pointer" }}>{option.label}</FormLabel>
                        </ToggleButton>
                    )
                })}
            </ToggleButtonGroup>
        </Stack>
    )
}

export default ToggleButtonGroupField