import constants from "../../../components/entity/constants";
import { createBooleanFormField, createField } from '../../../components/helpers/fieldHelper';

export const getTableFilter = (t, apiClient, user, cache) => ([
    createField(
        "name",
        t("sites.fields.name"),
        constants.TEXT_TYPE
    ),
    createBooleanFormField(
        t,
        "is_admin",
        t("sites.fields.is_admin"),
    ),
])