import constants from "../../../components/entity/constants"
import { createField, createSuggestionTypeMapping, createIconField, createCallbackField } from '../../../components/helpers/fieldHelper'
import VisibilityIcon from "@mui/icons-material/Visibility"

export const getTableColumns = (t) => ([
    createIconField(
        "id",
        VisibilityIcon,
        t("common.actions.view.button"),
        {
            route: "suggestions",
            field: "id",
        },
        true
    ),
    createField(
        "suggestion_type",
        t("suggestions.fields.suggestion_type"),
        constants.STRING_TYPE,
        createSuggestionTypeMapping(t)
    ),
    createCallbackField(
        "content",
        t("suggestions.fields.content"),
        constants.STRING_TYPE,
        (value) => value.length > 150 ? value.substring(0, 150) + "..." : value
    ),
    createField(
        "created",
        t("common.fields.created"),
        constants.DATETIME_TYPE,
        null,
        null,
        null,
        "created"
    ),
    createField(
        "managed",
        t("suggestions.fields.managed"),
        constants.BOOLEAN_TYPE
    )
])