import constants from "../../../../components/entity/constants";
import {
    createField,
    createLinkField
} from "../../../../components/helpers/fieldHelper";

export const getTableColumns = (t) => ([
    createLinkField(
        createField(
            "activity_configuration.name",
            t("activities.fields.name"),
            constants.STRING_TYPE
        ),
        {
            route: "activities",
            field: "id",
        }
    ),
    createField(
        "state",
        t("activities.fields.state"),
        constants.STRING_TYPE
    ),
    createField(
        "date",
        t("activities.fields.date"),
        constants.DATE_TYPE
    ),
    createField(
        "summary.qty",
        t("activities.fields.pax"),
        constants.NUMERIC_TYPE
    ),
    createField(
        "created",
        t("activities.fields.created"),
        constants.DATETIME_TYPE
    ),
    createField(
        "modified",
        t("activities.fields.modified"),
        constants.DATETIME_TYPE
    ),
])
