import { useTranslation } from "react-i18next"
import { createCallbackField } from "../../../components/helpers/fieldHelper"
import constants from "../../../components/entity/constants"
import { useVolcanoApiClient } from "../../../context/VolcanoApiClientProvider"
import { useParams } from "react-router-dom"
import AsyncEntityPage from "../../../layout/templates/AsyncContentPage/AsyncEntityPage"
import { getActions } from "./InvoicePage.actions"
import { getTabNavigationItems } from "./InvoicePage.tabs"
import { getHeaderContent } from './InvoicePage.header';
import { useVolcanoAuth } from '../../../context/VolcanoAuthContext';
import { getShareActions } from "./InvoicePage.shareActions"

const InvoicePage = (props) => {
    const { t } = useTranslation()
    const { entityId } = useParams()
    const { apiClient } = useVolcanoApiClient()
    const volcanoAuth = useVolcanoAuth()

    const fetcher = () => apiClient.invoice.getInvoice(entityId)

    return (
        <AsyncEntityPage
            fetcher={fetcher}
            title={createCallbackField(
                "title",
                t("invoices.fields.title"),
                constants.STRING_TYPE,
                (value) => {
                    return t("invoices.view.title", { title: value })
                }
            )}
            headerContent={getHeaderContent(t, volcanoAuth.user)}
            actions={getActions(apiClient, t)}
            shareActions={getShareActions(volcanoAuth.user, apiClient, entityId, t)}
            tabNavigationItems={getTabNavigationItems(t)}
        >
        </AsyncEntityPage>
    )
}

export default InvoicePage