import { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { Stack, Grid } from "@mui/material"
import { format as formatDate } from "date-fns"
import DataWrapper from "../../Display/DataWrapper"
import AvailabilityCalendar from "../AvailabilityCalendar/AvailabilityCalendar"
import SessionSelector from "../Widgets/BookingWidget/SessionSelector"
import en from "date-fns/locale/en-GB"
import es from "date-fns/locale/es"

const locales = {
    en: en,
    es: es,
};



const isDayWideSession = (sessions) => sessions.filter((session) => session.session === 'day_wide').length > 0;

const DateSelector = (props) => {
    const { product, selected, availabilityFetcher, minQuantity, onSelection } = props;
    const { t, i18n } = useTranslation("vbms");
    const [selection, setSelection] = useState({});

    useEffect(() => {
        if (selection.date && selection.session) {
            onSelection({ date: selection.date.date, session: selection.session });
        }
    }, [selection, onSelection]);

    const availabilityFetcherWrapper = (date) => availabilityFetcher(product, date);

    const onDateSelectionHandler = (selectedDate) => {

        if (!selectedDate) {
            setSelection({
                date: null,
                session: null,
            })

            return
        }

        // filter unavailable sessions
        selectedDate.sessions = selectedDate.sessions.filter((session) => session.available === -1 || session.available > 0);

        if (selectedDate.sessions.length === 1 &&
            selectedDate.sessions[0].session === 'day_wide') {
            setSelection({
                date: selectedDate,
                session: selectedDate.sessions[0],
            });
        } else {
            setSelection({
                date: selectedDate,
                session: null,
            });
        }
    };

    const onSessionSelectionHandler = (selectedSession) =>
        setSelection({
            ...selection,
            session: selectedSession,
        });

    return (
        <Stack spacing={2}>
            <DataWrapper
                title={t("booking_widget.steps.date.card_date_title")}
                subtitle={
                    selection.date &&
                    formatDate(selection.date.date, "P", {
                        locale: locales[i18n.language],
                    })
                }
                onDelete={() => onDateSelectionHandler(null)}
            >
                {!selection.date && (
                    <Grid container spacing={2} justifyContent="center">
                        <Grid item xs={12} xl={props.children ? 6 : 12}>
                            <AvailabilityCalendar
                                selected={selected}
                                availabilityFetcher={availabilityFetcherWrapper}
                                minQuantity={minQuantity || 0}
                                onSelection={onDateSelectionHandler}
                            />
                        </Grid>
                        {props.children && 
                            <Grid item xs={12} lg={6}>
                                {props.children}
                            </Grid>
                        }
                    </Grid>
                )}
            </DataWrapper>
            {selection.date && !isDayWideSession(selection.date.sessions) && (
                <DataWrapper
                    title={t("booking_widget.steps.date.card_session_title")}
                    subtitle={selection.session && selection.session.session.substring(0, selection.session.session.length - 3)}
                    onDelete={() => onSessionSelectionHandler(null)}
                >
                    {!selection.session && (
                        <SessionSelector
                            sessions={selection.date.sessions}
                            onSessionSelected={onSessionSelectionHandler}
                        />
                    )}
                </DataWrapper>
            )}
        </Stack>
    );
};

export default DateSelector;
