import constants from '../../../components/entity/constants';
import _isEmpty from 'lodash/isEmpty';
import { createCallbackField, createCurrencyField, createDataSetFacet, createFacetField, createFacetsContainer, createField, createObjectStatusField } from '../../../components/helpers/fieldHelper';
import { Box } from '@mui/material';
import DateTimeFieldValue from '../../../components/entity/DateTimeFieldValue';
import StringFieldValue from '../../../components/entity/StringFieldValue';

export const getHeaderContent = (t, user) => [
    createDataSetFacet(t("refunds.view.facet_information"), [
        createField("id", "Id", constants.STRING_TYPE),
        createField(
            "auto_refund",
            t("refunds.fields.auto_refund_available"),
            constants.BOOLEAN_TYPE
        ),
        createCallbackField(
            "created",
            t("refunds.fields.created"),
            constants.STRING_TYPE,
            (value, entity) => <Box><DateTimeFieldValue value={value} /> <StringFieldValue value={"(" + entity.created_user.name.trim() + ")"} /></Box>
        ),
        createCallbackField(
            "last_action",
            t("refunds.fields.modified"),
            constants.STRING_TYPE,
            (value) => <Box><DateTimeFieldValue value={value.modified} /> <StringFieldValue value={"(" + (value.modified_user ? (value.modified_user.name ? value.modified_user.name.trim() : value.modified_user) : " -") + ")"} /></Box>,
            (entity) => !_isEmpty(entity.last_action.modified)
        ),
        createCallbackField(
            "booking.order.collaborator",
            t("bookings.fields.order.collaborator.title"),
            constants.BOOLEAN_TYPE,
            (value, entity) => {
                return (entity.booking.order?.collaborator && entity.booking.order.billing_type === 1)
            }
        ),
    ]),
    createFacetsContainer([
        createFacetField("object_status", {
            size: "small",
            field: createField(
                "type.name",
                t("refunds.fields.type_name"),
                constants.STRING_TYPE
            ),
        }),
        createFacetField("object_status", {
            size: "small",
            field: createField(
                "state.name",
                t("refunds.fields.state_name"),
                constants.STRING_TYPE
            ),
        }),
    ]),
    createDataSetFacet(t("refunds.view.facet_transaction"), [
        createCallbackField(
            "booking.order",
            t("refunds.fields.transaction_id"),
            constants.STRING_TYPE,
            (value) => <Box>{value.tpvid === null ? (value.pp_transaction_id === null ? "Error" : value.pp_transaction_id) : value.tpvid}</Box>
        ),
        createCallbackField(
            "auto_transaction",
            t("refunds.fields.auto_transaction"),
            constants.STRING_TYPE,
            (value) => <Box>{value ? "Pago automático" : "Confirmación manual"}</Box>,
            (entity) => entity.state.id === 4
        ),
        createCallbackField(
            "data.manual_refund",
            t("refunds.fields.manual_refund_selected"),
            constants.BOOLEAN_TYPE,
            (value) => <Box>{value ? t("common.no") : t("common.yes")}</Box>,
            (entity) => entity.state.id === 4
        ),
        createCallbackField(
            "data.pg_remote_response.status",
            t("refunds.fields.pg_remote_response.status"),
            constants.STRING_TYPE,
            (value) => <Box>{value === "failed" ? "Error" : (value === "unknown" ? t("common.errors.unknown.title") : value)}</Box>,
            (entity) => [2, 3].includes(entity.state.id) && entity.data.pg_remote_response
        ),
        createField(
            "data.pg_remote_response.error",
            t("refunds.fields.pg_remote_response.error"),
            constants.STRING_TYPE,
            null,
            (entity) => [2, 3].includes(entity.state.id) && entity.data.pg_remote_response && entity.data.pg_remote_response.error
        ),
    ]),
    createFacetsContainer([
        createFacetField("object_status", {
            size: "small",
            field: createCurrencyField(
                createField(
                    "cost",
                    t("refunds.fields.cost"),
                    constants.CURRENCY_TYPE
                ),
                "currency_code",
                "main_currency_code",
                "booking.order.currency_exchange_rate"
            ),
        }),
        createFacetField("object_status", {
            size: "small",
            field: createCurrencyField(
                createField(
                    "amount",
                    t("refunds.fields.amount"),
                    constants.CURRENCY_TYPE
                ),
                "currency_code",
                "main_currency_code",
                "exchange_rate"
            ),
        }),
    ]),
    createFacetsContainer([
        createFacetField("object_status", {
            size: "large",
            field: createObjectStatusField(
                createField(
                    "auto_transaction_attempts",
                    t("refunds.fields.auto_transaction_attempts"),
                    constants.NUMERIC_TYPE
                ),
                null,
                (value) => {
                    return "critical";
                }
            ),
            condition: (entity) => [2, 3].includes(entity.state.id) && entity.auto_transaction_attempts > 0
        }),
    ]),
]