import React, { useCallback, useState } from "react"
import { DynamicFeed } from "@mui/icons-material"
import { Box, Chip, Dialog, DialogContent, DialogTitle, FormHelperText, IconButton, InputAdornment, Stack, TextField } from "@mui/material"
import { Field } from "formik"
import ValueSelectorDialog from "../ValueSelectorDialog/ValueSelectorDialog"
import _get from "lodash/get"
import { trimWithEllipsis } from "../../lib/utils"

const InputComponent = React.forwardRef((props, ref) => {

    const maxLen = props.maxLen || 100

    const handleDelete = (id) => {
        props.onDelete(id)
    }

    return (
        <Box
            className={props.className}
        >
            <Stack
                direction="row"
                spacing={1}
                overflow="hidden"
                minWidth="20ch"
            >
                {props.selected.map((item) =>
                    <Chip
                        key={item.id}
                        label={trimWithEllipsis(item.label, maxLen)}
                        onDelete={() => handleDelete(item.id)}
                        size="small"
                    />)
                }
            </Stack>
        </Box>
    )
})

const ValueSelectorInput = ({ field, label, multiple, table, valueFormatter, setFieldValue, setFieldTouched }) => {
    const [open, setOpen] = useState(false)

    const selected = field.value || []

    const updateValue = useCallback((value) => {
        if (setFieldValue) {
            setFieldValue(field.name, value, true)
        }

        if (setFieldTouched) {
            setFieldTouched(field.name, true, false)
        }
    }, [field.name, setFieldValue, setFieldTouched])

    const onConfirmHandler = useCallback((value) => {
        setOpen(false)
        updateValue(value)
    }, [setOpen, updateValue])

    const onValueDeleteHandler = (id) => {
        const value = _get(field, "value", [])
        updateValue(value.filter((item) => item.id !== id))
    }

    return (
        <Box>
            <TextField
                name={field.id}
                label={label}
                required={field.required}
                InputProps={{
                    size: "small",
                    sx: {
                        paddingRight: 0
                    },
                    inputComponent: InputComponent,
                    inputProps: {
                        maxLen: field?.labelMaxLen,
                        selected: selected,
                        onDelete: onValueDeleteHandler
                    },
                    endAdornment: (
                        <InputAdornment position="start">
                            <IconButton
                                sx={{
                                    padding: 1,
                                    marginRight: -0.5,
                                }}
                                onClick={() => setOpen(true)}
                            >
                                <DynamicFeed />
                            </IconButton>
                        </InputAdornment>
                    ),
                }}
                InputLabelProps={{
                    shrink: true,
                }}
            />
            <Dialog
                open={open}
                onClose={() => setOpen(false)}
                fullWidth={true}
                maxWidth="lg"
            >
                <DialogTitle>{label}</DialogTitle>
                <DialogContent>
                    <ValueSelectorDialog
                        table={table}
                        selected={selected}
                        valueFormatter={valueFormatter}
                        multipleSelection={multiple}
                        onConfirm={onConfirmHandler}
                        onCancel={() => setOpen(false)}
                    />
                </DialogContent>
            </Dialog>
        </Box>
    )
}

const ValueSelectorDialogFormField = ({ field, value }) => {

    const configField = field

    return (
        <Field
            id={field.id}
            name={field.id}
        >
            {({
                field,
                form: { values, setFieldValue, setFieldTouched },
                meta,
            }) => {
                return (
                    <>
                        <ValueSelectorInput
                            field={field}
                            label={configField.label}
                            multiple={configField.multiple}
                            table={configField.table}
                            valueFormatter={configField.valueFormatter}
                            setFieldValue={setFieldValue}
                            setFieldTouched={setFieldTouched}
                        />
                        {meta.error && meta.touched && <FormHelperText error={true}>{meta.error}</FormHelperText>}
                    </>
                )
            }}
        </Field>
    )
}

export default ValueSelectorDialogFormField

export {
    ValueSelectorInput
}