import availableRoutes from "../../../routes/availableRoutes"
import EnterpriseAddCurrency from "./components/actions/EnterpriseAddCurrency"

export const getActions = (apiClient, t, user, itemProps, navigate, entityId = null) => {
    const { reloadOnSuccess = true, showLoading = true } = { ...itemProps }
    return [
        {
            id: "edit",
            title: t("common.edit"),
            reloadOnSuccess: true,
            showLoading: true,
            onExecute: (enterprise) => {
                return Promise.resolve(enterprise)
                    .then((enterprise) => {
                        navigate(availableRoutes.enterprise_edit.path.replace(":entityId", entityId ?? enterprise.id))
                    })
            },
            condition: (entity) => entity.hasAction("edit")
        },
        {
            id: "add_currency",
            title: t("enterprise.actions.add_currency.button"),
            withDialog: {
                title: t("enterprise.actions.add_currency.modal_title"),
                content: EnterpriseAddCurrency
            },
            reloadOnSuccess: reloadOnSuccess,
            showLoading: showLoading,
            onExecute: (data, progressHandler, onSuccess, onError) => {
                return apiClient.enterprise.editCurrency(entityId, data)
                    .then(() => {
                        onSuccess(t("enterprise.actions.add_currency.confirm"))
                        navigate(availableRoutes.enterprise.path)
                    })
                    .catch((error) => {
                        onError(t("enterprise.actions.add_currency.error"))
                    })
            },
            condition: (entity) => entity.hasAction("add_currency")
        }
    ]
}