import { useTranslation } from "react-i18next"
import constants from "../../../../components/entity/constants"
import { useVolcanoApiClient } from "../../../../context/VolcanoApiClientProvider"
import {useNavigate, useParams} from "react-router-dom"
import { createCallbackField } from "../../../../components/helpers/fieldHelper"
import AsyncEntityPage from "../../../../layout/templates/AsyncContentPage/AsyncEntityPage"
import { getActions } from "./PickupConfigPage.actions";
import { getHeaderContent } from "./PickupConfigPage.header";
import { useVolcanoAuth } from "../../../../context/VolcanoAuthContext"
import {getTabNavigationItems} from "./PickupConfigPage.tabs";

const PickupConfigPage = (props) => {
    const { t } = useTranslation();
    const { entityId } = useParams()
    const { apiClient } = useVolcanoApiClient()
    const volcanoAuth = useVolcanoAuth()
    const fetcher = () => apiClient.activityManager.pickupConfig.getPickupConfig(entityId, [])
    const navigate = useNavigate()

    return (
        <AsyncEntityPage
            fetcher={fetcher}
            title={createCallbackField(
                "name",
                t("pickup_configs.fields.name"),
                constants.STRING_TYPE,
                (value) => {
                    return t("pickup_configs.view.title", { name: value });
                }
            )}
            headerContent={getHeaderContent(t)}
            actions={getActions(apiClient, t, volcanoAuth.user, navigate, entityId)}
            tabNavigationItems={getTabNavigationItems(volcanoAuth.user, apiClient, t)}
        />
    );
};

export default PickupConfigPage;