import { useTranslation } from "react-i18next"
import BasePage from "../../layout/templates/BasePage"
import VolcanoOneClickBookingWidget from "../../components/Volcano/Widgets/OneClickBookingWidget/VolcanoOneClickBookingWidget"

const OneClickBookingPage = () => {
    const { t } = useTranslation("vbms")

    return (
        <BasePage
            title={t("bookings.actions.new.title")}
        >
            <VolcanoOneClickBookingWidget />
        </BasePage>
    )
}

export default OneClickBookingPage