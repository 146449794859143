import _get from "lodash/get"
import constants from "../../../components/entity/constants"
import { createField, createFormField, createAsyncSelectFormField, createBooleanFormField, createFormFieldOption } from '../../../components/helpers/fieldHelper';
import { buildOptions } from "../../../lib/form-options-fetchers";
import { getLastDays } from "../../../lib/utils";
import _isEmpty from "lodash/isEmpty"

const getSchemaItemOptions = (t, apiClient, cache, item) => {
    const options = [createFormFieldOption("", t("table.all"))]

    return cache.get(
        "refund_schema",
        null,
        () => apiClient.schema.getRefundsSchema()
    ).then((schema) => {
        const schemaOptions = _get(schema, item).options

        return options.concat(Object.keys(schemaOptions).map((key) => ({ value: key, label: schemaOptions[key] })))
    })
}

export const getTableFilter = (t, apiClient, user, cache) => ([
    createField(
        "locator",
        t("refunds.filter.booking_locator"),
        constants.STRING_TYPE
    ),
    createField(
        "customer_email",
        t("refunds.filter.customer_email"),
        constants.STRING_TYPE
    ),
    createField(
        "experience_name",
        t("refunds.filter.experience_name"),
        constants.STRING_TYPE
    ),
    createFormField(
        createField(
            "created_date_from",
            t("refunds.filter.created_date_from"),
            constants.DATE_TYPE
        ),
        false,
        getLastDays(30)
    ),
    createField(
        "created_date_to",
        t("refunds.filter.created_date_to"),
        constants.DATE_TYPE
    ),
    createField(
        "booking_date_from",
        t("refunds.filter.booking_date_from"),
        constants.DATE_TYPE
    ), createField(
        "booking_date_to",
        t("refunds.filter.booking_date_to"),
        constants.DATE_TYPE
    ),
    createAsyncSelectFormField(
        "type_group",
        t("refunds.filter.type"),
        () => getSchemaItemOptions(t, apiClient, cache, "type_group"),
        false
    ),
    createFormField(
        createAsyncSelectFormField(
            "state_group",
            t("refunds.filter.state"),
            () => getSchemaItemOptions(t, apiClient, cache, "state_group"),
            false
        ),
        false,
        'not_managed'
    ),
    createAsyncSelectFormField(
        "payment_mode_id",
        t("refunds.filter.payment_mode_id"),
        () => getSchemaItemOptions(t, apiClient, cache, "payment_method_id"),
        false
    ),
    createBooleanFormField(
        t,
        "with_cost",
        t("refunds.filter.with_cost")
    ),
    createAsyncSelectFormField(
        "currency",
        t("bookings.filter.currencies"),
        () => {
            const enterpriseId = parseInt(_get(user, "corporate_account.enterprise.id", "-1"))
            let result = [
                createFormFieldOption("", t("table.all")),
                createFormFieldOption("main", t("currencies.fields.main")),
                createFormFieldOption("others", t("currencies.fields.others"))
            ]

            return cache.get("enterprises", []).then((enterprises) => {

                if (_isEmpty(enterprises)) {
                    return result
                }

                const enterprise = enterprises.find((enterprise) => enterprise.id === enterpriseId)
                if (enterprise.currencies) {
                    result = [...result, ...buildOptions(enterprise.currencies.available, t, "id", "id", false)]
                }

                return result
            })
        },
        false,
        () => user.corporate_account.enterprise.id === 1 // Filter available only for Volcano
    ),
])