import { useTranslation } from "react-i18next"

const DateTimeFieldValue = ({ value, format }) => {
    const { i18n } = useTranslation("vbms")

    let result = null

    if (!value) {
        result = ""
    } else {
        let config = null
        switch (format) {
            case 'date':
                config = {
                    year: "numeric",
                    month: "2-digit",
                    day: "2-digit",
                }
                break
            case 'time':
                config = {
                    hour: "2-digit",
                    minute: "2-digit",
                }
                break
            case 'datetime':
            default:
                config = {
                    year: "numeric",
                    month: "2-digit",
                    day: "2-digit",
                    hour: "2-digit",
                    minute: "2-digit",
                }
                break
        }

        result = Intl.DateTimeFormat(i18n.language, config).format(new Date(value))
    }

    return <>{result}</>
}

export default DateTimeFieldValue
