import constants from "../../../components/entity/constants";
import { createField, createLinkField, createCallbackField } from '../../../components/helpers/fieldHelper';
import { Link } from '@mui/material';

export const getTableColumns = (t, user) => ([
    createLinkField(
        createField(
            "name",
            t("sites.fields.name"),
            constants.TEXT_TYPE
        ),
        {
            route: "sites",
            field: "id",
        }
    ),
    createCallbackField(
        "base_url",
        t("sites.fields.base_url"),
        constants.STRING_TYPE,
        (value) => {
            const protocols = ["http://", "https://"]
            const url = value

            if (value) {
                protocols.forEach((protocol) => {
                    if (value.startsWith(protocol)) {
                        value = value.substring(protocol.length)
                        return
                    }
                });
            }

            return <Link href={"http://" + value} target="_blank">{url}</Link>
        }
    ),
    createField(
        "is_admin",
        t("sites.fields.is_admin"),
        constants.BOOLEAN_TYPE
    ),
    createField(
        "created",
        t("sites.fields.created"),
        constants.DATETIME_TYPE
    ),
    createField(
        "modified",
        t("sites.fields.modified"),
        constants.DATETIME_TYPE
    ),
])