import { Checkbox, TableCell, TableHead, TableRow, TableSortLabel } from "@mui/material"
import { useTranslation } from "react-i18next"
import DataTableCell from "./DataTableCell"

const DataTableHead = (props) => {
    const {
        selectionMode,
        onSelectAllClick,
        order,
        orderBy,
        numSelected,
        rowCount,
        onRequestSort,
        columns,
        withActions,
        grouped,
    } = props
    const { t } = useTranslation("vbms")

    const columnIds = {}

    const createSortHandler = (property) => (event) => {
        onRequestSort(event, property)
    };

    return (
        <TableHead>
            <TableRow>
                {grouped && (<TableCell />)}
                {!grouped && selectionMode !== "none" && (
                    <TableCell padding="checkbox">
                        {selectionMode === "multiple" && (
                            <Checkbox
                                indeterminate={numSelected > 0 && numSelected < rowCount}
                                checked={rowCount > 0 && numSelected === rowCount}
                                onChange={onSelectAllClick}
                                inputProps={{ "aria-label": t("table.select_all") }}
                            />
                        )}
                    </TableCell>
                )}
                {columns.map((column) => {
                    if (!(column.id in columnIds)) {
                        columnIds[column.id] = 0;
                    }
                    return (
                        <DataTableCell
                            key={column.id + "-" + columnIds[column.id]++}
                            column={column}
                            sortDirection={orderBy === column.orderBy ? order : false}
                        >
                            {column.orderBy && (<TableSortLabel
                                active={orderBy === column.orderBy}
                                direction={orderBy === column.orderBy ? order : "asc"}
                                onClick={createSortHandler(column.orderBy)}
                            >
                                {column.label}
                            </TableSortLabel>)}
                            {!column.orderBy && column.label}
                        </DataTableCell>
                    )
                })}
                {withActions && (<TableCell />)}
            </TableRow>
        </TableHead>
    )
}

export default DataTableHead