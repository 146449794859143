
export const getActions = (apiClient, t, user) => [
    {
        title: t("liquidations.actions.generate.button"),
        confirmDialog: {
            title: t("liquidations.actions.generate.modal_title"),
            content: (selection) => t("liquidations.actions.generate.modal_content"),
        },
        reloadOnSuccess: true,
        showLoading: true,
        onExecute: (user, progressHandler, onSuccess, onError) => {
            return apiClient.liquidation.generate()
                .then((result) => {
                    onSuccess(
                        t("liquidations.actions.generate.confirm")
                    )

                    return result
                })
                .catch((error) => {
                    onError(
                        t("liquidations.actions.generate.error")
                    )

                    throw error
                })
        },
        condition: () => user.hasPermission('administer-any-liquidation'),
    },
]