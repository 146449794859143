import { useTranslation } from "react-i18next";
import VolcanoBookingWidget from "../../components/Volcano/Widgets/BookingWidget/VolcanoBookingWidget";
import BasePage from "../../layout/templates/BasePage"

const BookingAddPage = () => {
    const { t } = useTranslation("vbms");
    return (
        <BasePage
            title={t("bookings.actions.new.title")}
            subtitle={t("bookings.actions.new.subtitle")}
        >
            <VolcanoBookingWidget />
        </BasePage>
    )
}

export default BookingAddPage