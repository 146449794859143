import { TextField } from "@mui/material"
import _has from "lodash/has"

const CurrencyField = (props) => {
    const fieldProps = { ...props }

    if (_has(props, "onChange") && typeof props.onChange === "function") {
        fieldProps.onChange = (e) => {
            let value = e.target.value

            value = value.replace(",", ".")

            // remove all dots except the first one
            value = value.replace(/\./, "x").replace(/\./g, "").replace(/x/, ".")

            // remove all non numeric characters
            value = value.replace(/[^0-9.]/g, "")

            e.target.value = value
            props.onChange(e)
        }
    }

    return <TextField {...fieldProps} />
}

export default CurrencyField