export const getActions = (apiClient, t, itemProps = {}) => {
    const { scope } = { ...itemProps }
    return [
        {
            title: t("suggestions.actions.set_managed.button_variant"),
            scope: scope,
            reloadOnSuccess: true,
            showLoading: true,
            onExecute: (suggestion, progressHandler, onSuccess, onError) => {
                return apiClient.suggestion
                    .setSuggestionManaged(suggestion.id, suggestion.managed)
                    .then((result) => {
                        onSuccess(
                            t(
                                "suggestions.actions.set_managed.confirm",
                                { suggestion_type: translateSuggestionType(t, suggestion.suggestion_type) }
                            )

                        )
                        return result
                    })
                    .catch((error) => {
                        onError(
                            t(
                                "suggestions.actions.set_managed.error",
                                { suggestion_type: translateSuggestionType(t, suggestion.suggestion_type) }
                            )
                        )
                        throw error
                    })
                    .finally(() => true)
            },
            condition: (suggestion) => suggestion.hasAction("set_managed") && !suggestion.managed
        },
    ]
}

const translateSuggestionType = (t, value) => {
    const suggestionType = t(`suggestions.constants.suggestion_type.${value}`)
    return suggestionType.toLowerCase()
}