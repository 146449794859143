import { Stack } from "@mui/material"

const FacetsContainer = ({ children }) => {
  return (
    <Stack spacing={1}>
      {children}
    </Stack>
  )
}

export default FacetsContainer
