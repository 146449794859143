import availableRoutes from "../../../../routes/availableRoutes";
import _has from "lodash/has";
import ActivityConfigForm from "../ActivityConfigForm";


export const getActions = (apiClient, t, user, navigate, entityId) => {
    
    return [
        {
            id: "edit",
            title: t("common.edit"),
            reloadOnSuccess: true,
            showLoading: true,
            withDialog: {
                title: t("activity_configs.actions.edit.modal_title"),
                content: ActivityConfigForm
            },
            onExecute: (item, progressHandler, onSuccess, onError) => {
                return apiClient.activityManager.activityConfig.editActivityConfig(entityId, item)
                    .then((result) => {
                        onSuccess(
                            t("activity_configs.actions.edit.confirm", {
                                name: result.name,
                            })
                        )

                        return { ...result, id: entityId }
                    })
                    .catch((error) => {
                        onError(
                            t("activity_configs.actions.edit.error", {
                                name: item.name,
                            })
                        )
                    })
            },
            condition: (item) => _has(item.getActions(), "edit")
        },
        {
            id: "activity_config_configuration",
            title: t("activity_configs.actions.configuration.button"),
            onExecute: (item) => {
                navigate(availableRoutes.activity_config_configuration.path.replace(":entityId", item.id))
            },
            condition: (item) => _has(item.getActions(), "update_configurations")
        },
        {
            id: "activity_config_products",
            title: t("activity_configs.actions.products.button"),
            onExecute: (item) => {
                navigate(availableRoutes.activity_config_products.path.replace(":entityId", item.id))
            },
            condition: (item) => _has(item.getActions(), "update_products")
        },
        /*{
            id: "activity_config_products",
            title: t("activity_configs.actions.products.button"),
            scope: "products",
            icon: null,
            showLoading: true,
            withDialog: {
                title: t("activity_configs.actions.products.modal_title"),
                content: ActivityConfigProductsForm
            },
            reloadOnSuccess: true,
            onExecute: (item, progressHandler, onSuccess, onError) => {
                return apiClient.activityManager.activityConfig.updateProducts(entityId, item)
                    .then((result) => {
                        onSuccess(
                            t("activity_configs.actions.products.confirm", {
                                name: result.name,
                            })
                        )

                        return { ...result, id: entityId }
                    })
                    .catch((error) => {
                        onError(
                            t("activity_configs.actions.products.error", {
                                name: item.name,
                            })
                        )
                    })
            },
        },*/
        {
            id: "delete",
            title: t("common.delete"),
            reloadOnSuccess: true,
            showLoading: true,
            confirmDialog: {
                title: t("activity_configs.actions.delete.modal_title"),
                content: (item) =>
                    t("activity_configs.actions.delete.modal_content", {
                        name: item.name,
                    }),
            },
            onExecute: (item, progressHandler, onSuccess, onError) => {
                return apiClient.activityManager.activityConfig.deleteActivityConfig(item.id)
                    .then(() => {
                        onSuccess(
                            t("activity_configs.actions.delete.confirm", {
                                name: item.name,
                            })
                        )
                        navigate(availableRoutes.activity_configs.path)
                    })
                    .catch((error) => {
                        onError(
                            t("activity_configs.actions.delete.error", {
                                name: item.name,
                            })
                        )
                    })
            },
            condition: (item) => _has(item.getActions(), "delete")
        },
    ]
}
