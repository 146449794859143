import { ContentCopy, Delete, Send } from '@mui/icons-material'
import availableRoutes from '../../../routes/availableRoutes'

export const getActions = (t, navigate, user, apiClient) => [
    {
        id: "copy",
        scope: "row",
        icon: ContentCopy,
        title: t("notification_templates.actions.copy.button"),
        onExecute: (template, progressHandler, onSuccess, onError) => {
            return apiClient.notifications.copyNotificationTemplate(template.id)
                .then((result) => {
                    onSuccess(t("notification_templates.actions.copy.confirm", {
                        name: result.name,
                    }))
                    navigate(availableRoutes.notification_templates_view.path.replace(":entityId", result.id))
                })
                .catch((error) => {
                    onError(
                        t("notification_templates.actions.copy.error", {
                            name: template.name,
                        })
                    )

                    throw error
                })
        },
        condition: () => user.hasPermission("administer-any-notification-template")
    },
    {
        id: "create_notification",
        title: t("notification_templates.actions.create_notification.button"),
        scope: "row", 
        icon: Send,
        reloadOnSuccess: true,
        showLoading: true,
        onExecute: (template, progressHandler, onSuccess, onError) => {
            return apiClient.notifications.createNotificationFromTemplate(template.id)
                .then((notification) => {
                    onSuccess(t("notification_templates.actions.create_notification.confirm"))
                    navigate(availableRoutes.notifications_edit.path.replace(":entityId", notification.id))
                })
                .catch((error) => {
                    onError(t("notification_templates.actions.create_notification.error"))
                    throw error
                })
        },
        condition: () => user.hasPermission("administer-any-notification")
    },
    {
        id: "delete",
        title: t("common.delete"),
        scope: "row",
        icon: Delete,
        showLoading: true,
        confirmDialog: {
            title: t("notification_templates.actions.delete.modal_title"),
            content: (selection) =>
                t("notification_templates.actions.delete.modal_content", {
                    name: selection.name,
                }),
        },
        onExecute: (template, progressHandler, onSuccess, onError) => {
            return apiClient.notifications.deleteNotificationTemplate(template.id)
                .then((result) => {
                    onSuccess(
                        t("notification_templates.actions.delete.confirm", {
                            name: template.name,
                        })
                    )
                    navigate(availableRoutes.notification_templates)
                })
                .catch((error) => {
                    onError(
                        t("notification_templates.actions.delete.error", {
                            name: template.name,
                        })
                    )

                    throw error
                })
        },
        condition: (template) => (user.hasPermission("administer-any-notification-template") && 
                            !template.system_template)
   }    
]