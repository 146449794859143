import { useTranslation } from "react-i18next"
import constants from "../../../components/entity/constants"
import { useVolcanoApiClient } from "../../../context/VolcanoApiClientProvider"
import { useNavigate, useParams } from "react-router-dom"
import { createCallbackField, createField, createFormFieldset, createSelectFormField, createFormFieldOption, createAsyncSelectFormField, createAddressFormField, createFormFieldGroup, createCommonStateMapping } from '../../../components/helpers/fieldHelper'
import AsyncEntityFormPage from "../../../layout/templates/AsyncContentPage/AsyncEntityFormPage"
import { useVolcanoAuth } from "../../../context/VolcanoAuthContext"
import { intermediariesFetcher } from '../../../lib/form-options-fetchers'
import useSnackBars from "../../../components/Snackbar/snack-bar.context"
import { useCache } from "../../../context/cache.context"

const OfficeFormPage = (props) => {
    const { t } = useTranslation()
    const { entityId } = useParams()
    const { apiClient } = useVolcanoApiClient()
    const volcanoAuth = useVolcanoAuth()
    const navigate = useNavigate()
    const { addAlert } = useSnackBars()
    const cache = useCache()

    const fetcher = () => {
        if (!entityId) {
            return new Promise((res) => res({}))
        }

        return apiClient.intermediary.getIntermediaryOffice(entityId).then((office) => {
            // prepare address information
            office.contact.contact_details.address = {
                ...office.contact.contact_details,
            }

            delete office.contact.contact_details.address.fax

            return office
        })
    }

    const onSaveHandler = (office) => {
        // merge contact details
        office.contact.contact_details = {
            ...office.contact.contact_details,
            ...office.contact.contact_details.address,
        }

        if (!entityId) {
            // clone intermediary office
            const officeData = {
                ...office,
                contact: {
                    ...office.contact,
                    name: '',
                    notify_closure: false,
                    restrict_calls: false,
                },
            }

            // intermediary
            const intermediaryId = volcanoAuth.user.isIntermediary() ?
                volcanoAuth.user.crm_intermediary.id :
                office.intermediary.id

            if (officeData.hasOwnProperty('intermediary')) {
                delete officeData.intermediary
            }

            apiClient.intermediary.createIntermediaryOffice(intermediaryId, officeData)
                .then((office) => {
                    cache.clear("offices")
                    navigate("/settings/offices")
                })
                .catch((error) => {
                    alert("Error: " + error.message)
                })
        } else {
            apiClient.intermediary.editIntermediaryOffice(entityId, office)
                .then((office) => {
                    cache.clear("offices")
                    navigate("/settings/offices/" + entityId)
                })
                .catch((error) => {
                    addAlert("Error: " + error.message)
                })
        }
    }

    const formContent = [
        createFormFieldset(t("intermediaries.offices.form.fieldset_information"), [
            createField(
                "name",
                t("intermediaries.offices.fields.name"),
                constants.STRING_TYPE
            ),
            createSelectFormField(
                "state",
                t("intermediaries.offices.fields.state"),
                Object.entries(createCommonStateMapping(t))
                    .map(([key, value]) => createFormFieldOption(key, value))
            ),
            createAsyncSelectFormField(
                "intermediary.id",
                t("settings_system_users.fields.intermediary"),
                () => intermediariesFetcher(apiClient, t, null, false),
                false,
                () => !(volcanoAuth.user.isIntermediary() || entityId)
            ),
        ]),
        createFormFieldset(t("intermediaries.offices.form.fieldset_contact"), [
            createFormFieldGroup([
                createAddressFormField(
                    "contact.contact_details.address",
                    t("common.fields.contact_details.title"),
                    true
                )
            ])
        ]),
    ]

    return (
        <AsyncEntityFormPage
            fetcher={fetcher}
            title={createCallbackField(
                "name",
                t("intermediaries.offices.form.edit_title"),
                constants.STRING_TYPE,
                (value, entity) => {
                    if (entity.id) {
                        return t("intermediaries.offices.form.edit_title", {
                            name: value,
                        });
                    }
                    return t("intermediaries.offices.form.add_title");
                }
            )}
            onSave={onSaveHandler}
            formContent={formContent}
        />
    )
}

export default OfficeFormPage