import { downloadFile } from '../../../lib/utils'
import { createTabBarItem } from '../../../components/helpers/fieldHelper'
import BookingRates from '../../../components/Volcano/Bookings/BookingRates'
import BookingHistory from './components/tabs/BookingHistory'
import CommentsTable from '../../../components/Volcano/Comments/CommentsTable'
import InvoicesTable from '../../../components/Volcano/Invoices/InvoicesTable'
import BookingPaymentTransactions from './components/tabs/BookingPaymentTransactions'
import BookingRefunds from './components/tabs/BookingRefunds'
import BookingTicketValidations from './components/tabs/BookingTicketValidations'
import _has from 'lodash/has'
import _get from 'lodash/get'
import BookingRelatedBookings from './components/tabs/BookingRelatedBookings'
import userPermissions from '../../../lib/user-permissions'

export const getTabNavigationItems = (user, apiClient, t) => [
    createTabBarItem(
        t("bookings.navbar.rates"),
        ({ entity, data }) => (
            <BookingRates 
                data={entity} 
                currency={entity.order.currency}
                mainCurrency={entity.order.main_currency}
                exchangeRate={entity.order.currency_exchange_rate}
                hideNetPrice={!user.hasPermission(userPermissions.PERM_BOOKINGS_VIEW_NET_PRICE)} 
            />
        ),
    ),
    createTabBarItem(
        t("bookings.navbar.related_bookings"),
        ({ entity, data }) => {
            return (<BookingRelatedBookings data={entity.related_bookings} />)
        },
        null,
        (booking) => booking.related_bookings && booking.related_bookings.length > 0
    ),
    createTabBarItem(
        t("bookings.navbar.comments"),
        ({ entity, data }) => {
            const comments = entity.comments ? [...entity.comments] : []
            const customerComment = _get(entity, 'order.customer.comments', '')

            // Add customer comments in first place
            if (customerComment !== null && customerComment !== '') {
                comments.unshift({
                    created: entity.order.created,
                    user: { name: _has(entity, 'order.salesman.first_name') ? entity.order.salesman.first_name + '' + entity.order.salesman.last_name : '' },
                    comments: entity.order.customer.comments
                })
            }

            return (<CommentsTable data={comments} />)
        },
        null,
        (booking) => booking.comments || (_get(booking, 'order.customer.comments', '') != null && _get(booking, 'order.customer.comments', '') !== '')
    ),
    createTabBarItem(
        t("bookings.navbar.ticket_validations"),
        ({ entity, data }) => (
            <BookingTicketValidations data={data.getItems()} />
        ),
        (booking) => apiClient.booking.getValidations(booking.id, { view_mode: "full" }),
        (booking) => _has(booking, "validation_date")  && user.hasPermission(userPermissions.PERM_BOOKING_VALIDATION_VIEW)
    ),
    createTabBarItem(
        t("bookings.navbar.history"),
        ({ entity, data }) => (
            <BookingHistory
                data={data.getItems()}
                hideUserColumn={user.isIntermediary()}
            />
        ),
        (booking) => apiClient.booking.getBookingHistory(booking.id, {limit:100})
    ),
    createTabBarItem(
        t("bookings.navbar.refunds"),
        ({ entity, data }) => (
            <BookingRefunds data={data.getItems()} />
        ),
        (booking) => apiClient.refund.getRefunds({ booking_id: booking.id, sort_by_created: 'desc' }),
        (booking) => booking.payment_method !== "free" && 
            (user.hasPermission(userPermissions.PERM_BOOKING_REFUNDS_VIEW) || 
            user.hasPermission(userPermissions.PERM_BOOKING_REFUNDS_VIEW_OWN))
    ),
    createTabBarItem(
        t("bookings.navbar.invoices"),
        ({ entity, data }) => (
            <InvoicesTable
                data={data.getItems()}
                onDownload={
                    (invoice) => {
                        apiClient.invoice
                            .getInvoicePdf(invoice.id)
                            .then((pdf) =>
                                downloadFile(pdf, "invoice_" + invoice.title + ".pdf")
                            );
                    }
                }
            />
        ),
        (booking) => apiClient.invoice.getInvoices({ order_id: booking.order.id }),
        (booking) => booking.payment_method !== "free" && (user.hasPermission(userPermissions.PERM_INVOICES_VIEW) || user.hasPermission(userPermissions.PERM_INVOICES_VIEW_OWN))
    ),
    createTabBarItem(
        t("bookings.navbar.payment_transactions"),
        ({ entity, data }) => (
            <BookingPaymentTransactions data={data.getItems()} />
        ),
        (booking) => apiClient.paymentTransaction.getTransactions({ order_id: booking.order.id }),
        (booking) => booking.payment_method !== "free" && booking.payment_method !== "credit"
    ),
]