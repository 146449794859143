import { useEffect, useState, createContext } from "react"
import { useTranslation } from "react-i18next"
import { Stack, useMediaQuery, useTheme, Divider, Button, Box, Typography } from "@mui/material"
import { RulesSelectorDates, RulesSelectorHours, RulesSelectorDays, RulesSelectorContainer, DEFAULT_HOURS, DEFAULT_DAYS} from "../RulesSelector"
import { AsyncContextProvider, useAsyncContent } from "../../../context/async.context"
import Loading from "../../Display/Loading"
import useSnackBars from "../../Snackbar/snack-bar.context"
import _isEmpty from 'lodash/isEmpty';

const styles = {
    desktopButton: {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        textTransform: 'capitalize', 
        gap: 1, 
        mt: 4
    },
    mobileButton: {
        width: "100%", 
        gap: 1, 
        textTransform: 'capitalize', 
        my: 2, 
        fontSize: "1rem"
    },
    icon: {
        cursor: "pointer",
        "&:hover": {
            filter: "brightness(1.2)"
        },
        "&:active": {
            filter: "brightness(0.9)"
        }
    }
}

const newConfigMock = {
    ...DEFAULT_HOURS,
    ...DEFAULT_DAYS
}

export const RulesContext = createContext({})

const RulesSelectorComponent = (props) => {
    const theme = useTheme()
    const { t, i18n } = useTranslation("vbms")
    const { data, error } = useAsyncContent()
    const { addAlert } = useSnackBars()
    const [config, setConfig] = useState([])

    const { datesLabel, hoursLabel, daysLabel, route, addAction, deleteAction } = props.config
    const showLabel = useMediaQuery(theme.breakpoints.down('md'))
    const showDivider = useMediaQuery(theme.breakpoints.up('sm'))
    let rest = { ...props, t, showLabel, showDivider }

    useEffect(() => {
        if (error != null) {
            addAlert(`Error [${error.type}]: ${error.message}`, "error")
        } else {
            !_isEmpty(data.config) && setConfig(data.config[route])
        }
    }, [data, error, addAlert]);

    useEffect(() => {
        props.editable && props.onChange(config)
    }, [config])

    const renderAddAction = () => {
        if (props.editable && addAction.active) {

            const onClick = () => {
                setConfig([...config, newConfigMock])
            }

            if (showLabel) {
                return (
                    <Button variant="contained" size="big" endIcon={addAction.icon} sx={{...styles.mobileButton, backgroundColor: addAction.color}} onClick={onClick}>
                        {addAction.label}
                    </Button>
                )
            }

            return (
                <Box variant="contained" sx={styles.desktopButton}>
                    <Divider orientation="horizontal" sx={{flex: 1}}/>
                    <Button variant="contained" endIcon={addAction.icon} onClick={onClick}>
                        {addAction.label}
                    </Button>
                    <Divider orientation="horizontal" sx={{flex: 1}}/>
                </Box>
            )
        }
    }

    const renderDeleteAction = (index) => {
        if (props.editable && deleteAction.active) {

            const onClick = () => {
                let newConfig = [...config]
                newConfig.splice(index,1)
                setConfig(newConfig)
            }

            if (showLabel) {
                return (
                    <Button variant="outlined" color="info" endIcon={deleteAction.icon} sx={{...styles.mobileButton, borderColor: deleteAction.color, color: deleteAction.color}} onClick={onClick}>
                        {deleteAction.label}
                    </Button>
                )
            }
            if (!showLabel && showDivider && index === 0) {
                return (
                    <Box display="flex" alignItems="flex-end" pb="0.55em" sx={{...styles.icon, color: deleteAction.color}} onClick={onClick}>       
                        {deleteAction.icon}
                    </Box>
                )
            }
            if (!showLabel && showDivider) {
                return (
                    <Box display="flex" alignItems="center" sx={{...styles.icon, color: deleteAction.color}} onClick={onClick}>
                        {deleteAction.icon}
                    </Box>
                )
            }
        }
    }

    return (
        <RulesContext.Provider value={{config, setConfig, route}}>
            <Stack divider={showLabel && <Divider light />} gap={1}>
                {_isEmpty(config) && 
                    <Stack direction={{ xs: 'column', md: 'row' }} spacing={{ xs: 3, md: 2 }} py={1} justifyContent="center">
                        <Typography variant="h5" pb={1}>
                            {t("components.rules_selector.no_data")}
                        </Typography>
                    </Stack>
                }
                {!_isEmpty(config) && config?.map((x, index) => {
                    
                    rest = { ...rest, index }
                    
                    return (
                        <Stack key={index} direction={{ xs: 'column', md: 'row' }} spacing={{ xs: 3, md: 2 }} py={1}>
                            <RulesSelectorDates label={datesLabel} language={i18n.language} {...rest} />
                            <RulesSelectorHours label={hoursLabel} {...rest} />
                            <RulesSelectorDays label={daysLabel} {...rest} />
                            {renderDeleteAction(index)}
                        </Stack>
                    )
                })}
                {renderAddAction()}
            </Stack>
        </RulesContext.Provider>

    )
}

const RulesSelectorAsync = (props) => {
    if (props.promise) {
        return (
            <AsyncContextProvider fetcher={props.fetcher} loadComponent={Loading}>
                <RulesSelectorComponent {...props} />
            </AsyncContextProvider>
        )
    }

    return <RulesSelectorComponent {...props} />
}

const RulesSelector = (props) => {
    if (props.container) {
        return (
            <RulesSelectorContainer open={props.open} config={props.config} container={props.container.toString()}>
                <RulesSelectorAsync {...props} />
            </RulesSelectorContainer>
        )
    }

    return (
        <RulesSelectorAsync {...props} />
    )
}

export default RulesSelector 