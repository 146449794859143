import { Box } from "@mui/material"
import { Field } from "formik"
import { TextField } from "formik-mui"
import { useTranslation } from "react-i18next"
import { validate } from "./utils"

const NumericFormField = ({ field, value }) => {
    const { t } = useTranslation("vbms")

    return (
        <Box sx={{ maxWidth: field.max_width || 100 }}>
            <Field
                component={TextField}
                name={field.id}
                label={field.label}
                required={field.required}
                validate={(value) => validate(field, t, value)}
                InputProps={{
                    size: "small",
                    inputMode: 'numeric',
                    pattern: '[0-9]*'
                }}
                InputLabelProps={{
                    shrink: true,
                }}
            />
        </Box>
    )
}

export default NumericFormField
