import _has from 'lodash/has';

export const getActions = (apiClient, entityId, t, navigate) => [
    {
        id: "change_state_enable",
        title: t("settings_system_users.actions.change_state_enable.button"),
        confirmDialog: {
            title: t("settings_system_users.actions.change_state_enable.modal_title"),
            content: (selection) =>
                t("settings_system_users.actions.change_state_enable.modal_content", {
                    username: selection.username,
                }),
        },
        reloadOnSuccess: true,
        onExecute: (user, progressHandler, onSuccess, onError) => {
            return apiClient.user.setUserActive(user.id, true)
                .then((user) => {
                    onSuccess(
                        t("settings_system_users.actions.change_state_enable.confirm", {
                            name: user.name,
                        })
                    )

                    return user
                })
                .catch((error) => {
                    onError(
                        t("settings_system_users.actions.change_state_enable.error", {
                            name: user.name,
                        })
                    )

                    throw error
                })
        },
        condition: (user) => _has(user.getActions(), "change_state") && !user.active
    },
    {
        id: "edit",
        title: t("common.edit"),
        onExecute: (user, progressHandler, onSuccess, onError) => {
            navigate('/settings/roles/' + entityId + '/edit')
        },
    },
]