import { useTranslation } from "react-i18next"
import { useVolcanoApiClient } from "../../../context/VolcanoApiClientProvider"
import CollectionPage from "../../../layout/templates/CollectionPage/CollectionPage"
import { useVolcanoAuth } from '../../../context/VolcanoAuthContext'
import { useNavigate } from 'react-router-dom'
import { getTableFilter } from './RolesCollection.filter'
import { getActions } from "./RolesCollection.actions"
import { getTableColumns } from './RolesCollection.columns'
import { getTableExport } from './RolesCollection.export'
import { useCache } from "../../../context/cache.context"

/*
import Collection from "@volcanoteide/volcanoteide-api-client/dist/model/entity/collection"
const dummyCollection = new Collection(null, {
    count: 0,
    execution_time: 0,
    page: 0,
    page_count: 0,
    total: 0,
    _links: {},
    _embedded: { application_user: [] }
})*/

const RolesCollectionPage = () => {
    const { apiClient } = useVolcanoApiClient()
    const { t } = useTranslation("vbms")
    const volcanoAuth = useVolcanoAuth()
    const navigate = useNavigate()
    const cache = useCache()

    const table = {
        highlight: (role) => role.is_system ? null : "background.info.main",
        columns: getTableColumns(t, volcanoAuth.user),
        fetcher: (params) => {
            const queryFilter = {
                ...params, 
                sort: 'name'
            }

            return apiClient.role.getRoles(queryFilter)
        },
        onExport: getTableExport(apiClient, t)
    };

    return (
        <CollectionPage
            title={t("settings_roles.title")}
            subtitle={t("settings_roles.subtitle")}
            actions={getActions(t, navigate, volcanoAuth.user)}
            filterConfig={getTableFilter(t, apiClient, volcanoAuth.user, cache)}
            table={table}
        />
    )
}

export default RolesCollectionPage;
