import { createExportField } from "../../../../components/helpers/fieldHelper"

export const getTableExport = (apiClient, t) => {
    return (filter, progressHandler) => {
        apiClient.content.lodging.exportLodgings(
            [
                createExportField("id", "Id"),
                createExportField("name", t("lodgings.fields.name")),
                createExportField("created", t("lodgings.fields.created")),
                createExportField("modified", t("lodgings.fields.modified")),
            ],
            filter,
            progressHandler
        )
    }
} 
