import DataWrapper from '../../../../components/Display/DataWrapper';
import constants from '../../../../components/entity/constants';
import DataSetWrapper from '../../../../components/Display/DataSetWrapper';
import { createField } from '../../../../components/helpers/fieldHelper';
import DataSet from '../../../../components/Display/DataSet';
import { Box, Link, Grid } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
import _get from "lodash/get"

const billingClientFields = [
    {
        field: "name",
        label: "billing_clients.fields.name",
        type: constants.STRING_TYPE,
    },
    {
        field: "vat_number",
        label: "billing_clients.fields.vat_number",
        type: constants.STRING_TYPE,
    },
    {
        field: "enterprise.name",
        label: "billing_clients.fields.enterprise",
        type: constants.STRING_TYPE,
    },
    {
        field: "accountancy_config.account_id",
        label: "billing_clients.fields.account_id",
        type: constants.STRING_TYPE,
    },
]

const billingClientContactFields = [
    {
        field: createField(
            "contact_details",
            null,
            constants.ADDRESS_TYPE
        ),
        type: constants.ADDRESS_TYPE,
    }
]

const CreateInvoiceSummary = (props) => {
    const { selection, t } = props

    return (
        <DataWrapper title={t("billing_client_create_invoice_widget.summary.title")}>
            <DataSetWrapper
                title={_get(selection.billingClient, "is_collaborator", false) ?
                    t("billing_client_create_invoice_widget.summary.billing_collaborator") :
                    t("billing_client_create_invoice_widget.summary.billing_client")
                }
                data={selection.billingClient}
                config={billingClientFields}
                variant="inline"
            />
            <DataSetWrapper
                title={t("billing_client_create_invoice_widget.summary.billing_contact")}
                data={selection.billingClient}
                config={billingClientContactFields}
            />
            <DataSet
                title={t("billing_client_create_invoice_widget.summary.bookings")}
                titleLevel="body1"
                direction={"row"}
                data={[{
                    name: t("billing_client_create_invoice_widget.summary.bookings_locators"),
                    value: <Grid container>
                        {selection.bookings.map((booking, index) => (
                            <Box key={index} display="flex">
                                {index !== 0 && <Box sx={{ marginRight: "5px" }}>,</Box>}
                                <Link component={RouterLink} to={"/orders/bookings/" + booking.id} target="_blank">{booking.locator}</Link>
                            </Box>
                        ))}
                    </Grid>
                }]}
            />
        </DataWrapper >
    )
}

export default CreateInvoiceSummary