import constants from '../../entity/constants';
import { createField, createArrayField, createCurrencyField } from '../../helpers/fieldHelper';
import { Box, Paper, Table, TableContainer, TableBody, TableRow, TableCell, IconButton, Collapse, TableHead, Typography, Grid } from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { Fragment, useState } from 'react';
import ArrayDataTable from '../../Datatable/ArrayDataTable';
import { useTranslation } from 'react-i18next';
import EntityField from '../../entity/EntityField';
import _get from 'lodash/get'
import _has from 'lodash/has'
import DataWrapper from '../../Display/DataWrapper';
import KeyValue from '../../Display/KeyValue';
import ObjectStatus from '../../Display/ObjectStatus';
import { formatPrice } from '../../../lib/utils';

const getColumns = (isFromCollaborator, hideNetPrice) => {
    if (hideNetPrice) {
        return [
            createArrayField(
                createField(
                    "customer_types",
                    "bookings.fields.product_rates.name",
                    constants.ARRAY_TYPE
                ),
                "name"
            ),
            createField(
                "qty",
                "bookings.fields.product_rates.quantity",
                constants.NUMERIC_TYPE
            ),
            createCurrencyField(
                createField(
                    "pvp",
                    "bookings.fields.product_rates.pvp",
                    constants.CURRENCY_TYPE
                ),
                "currency",
                "main_currency",
                "exchange_rate"
            ),
            createCurrencyField(
                createField(
                    "total_amount_pvp",
                    "bookings.fields.product_rates.total_pvp",
                    constants.CURRENCY_TYPE
                ),
                "currency",
                "main_currency",
                "exchange_rate"
            ),
        ]
    }

    return [
        createArrayField(
            createField(
                "customer_types",
                "bookings.fields.product_rates.name",
                constants.ARRAY_TYPE
            ),
            "name"
        ),
        createField(
            "qty",
            "bookings.fields.product_rates.quantity",
            constants.NUMERIC_TYPE
        ),
        createCurrencyField(
            createField(
                "pvp",
                "bookings.fields.product_rates.pvp",
                constants.CURRENCY_TYPE
            ),
            "currency",
            "main_currency",
            "exchange_rate"
        ),
        createCurrencyField(
            createField(
                "neto",
                "bookings.fields.product_rates.neto",
                constants.CURRENCY_TYPE,
                null,
                () => isFromCollaborator
            ),
            "currency",
            "main_currency",
            "exchange_rate"
        ),
        createCurrencyField(
            createField(
                "total_amount_pvp",
                "bookings.fields.product_rates.total_pvp",
                constants.CURRENCY_TYPE
            ),
            "currency",
            "main_currency",
            "exchange_rate"
        ),
        createCurrencyField(
            createField(
                "total_amount",
                "bookings.fields.product_rates.total_neto",
                constants.CURRENCY_TYPE,
                null,
                () => isFromCollaborator
            ),
            "currency",
            "main_currency",
            "exchange_rate"
        ),
    ]
}

const processAlign = (type) => {
    switch (type) {
        case "numeric":
        case "currency":
            return "right"
        default:
            return "left"
    }
}

const BookingRateTableHead = ({ isFromCollaborator, hideNetPrice, withParticipants, t }) => {
    return (
        <TableHead>
            <TableRow>
                {withParticipants && <TableCell sx={{ width: '32px', padding: 0 }} />}
                {getColumns(isFromCollaborator, hideNetPrice).map((column, index) => (
                    <TableCell
                        key={"bookingRateHeadCell" + index}
                        align={processAlign(column.type)}
                    >
                        {t(column.label)}
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    )
}

const BookingRateRow = ({ isFromCollaborator, hideNetPrice, rate, t }) => {
    const [open, setOpen] = useState(false)
    const columns = getColumns(isFromCollaborator, hideNetPrice)

    const participantColumns = [
        createField(
            "first_name",
            t("bookings.fields.product_rates.participant.first_name"),
            constants.STRING_TYPE
        ),
        createField(
            "last_name",
            t("bookings.fields.product_rates.participant.last_name"),
            constants.STRING_TYPE
        ),
        createField(
            "id_card",
            t("bookings.fields.product_rates.participant.id_card"),
            constants.STRING_TYPE
        ),
    ]

    return (
        <Fragment>
            <TableRow
                hover
                tabIndex={-1}
            >
                {rate.participants &&
                    <TableCell align='center' sx={{ borderBottom: 'unset' }}>
                        <IconButton
                            aria-label="expand row"
                            size="small"
                            onClick={() => setOpen(!open)}
                        >
                            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                        </IconButton>
                    </TableCell>}
                {columns.map((column, index) => (
                    <TableCell
                        key={"bookingRateCell" + index}
                        align={processAlign(column.type)}
                        sx={rate.participants && { borderBottom: 'unset' }}
                    >
                        <EntityField
                            field={column}
                            entity={rate}
                        />
                    </TableCell>
                ))}
            </TableRow>
            {rate.participants && <TableRow>
                <TableCell />
                <TableCell sx={{ padding: 0 }} colSpan={columns.length}>
                    <Collapse in={open} timeout="auto" unmountOnExit>
                        <Box sx={{ margin: 1 }}>
                            <Typography variant="h6" gutterBottom component="div" sx={{ paddingLeft: 1 }}>
                                {t("bookings.fields.product_rates.participants")}
                            </Typography>
                            <ArrayDataTable
                                data={rate.participants}
                                columns={participantColumns}
                            />
                        </Box>
                    </Collapse>
                </TableCell>
            </TableRow>}
        </Fragment>
    )
}

const BookingRates = (props) => {
    const { i18n, t } = useTranslation("vbms")
    const totalAmount = props.data.is_liquidated ? props.data.amount.total_amount_net : props.data.amount.total_amount
    const discount = props.data.booking_codes ? props.data.booking_codes[0] : null
    const totalCommission = (discount && !props.data.is_liquidated) ? 0 : (props.data.amount.total_amount_pvp - totalAmount)
    const hideNetPrice = props.hideNetPrice
    const totalBooking = discount ? (props.data.amount.total_amount_pvp - discount['amount']) : totalAmount
    const currency = props.currency || props.data.order.currency
    const mainCurrency = props.mainCurrency || props.currency || props.data.order.main_currency
    const exchangeRate = props.exchangeRate || props.data.order.currency_exchange_rate || 1

    return (
        <Box>
            <Paper elevation={0}>
                <TableContainer>
                    <Table size="small">
                        <BookingRateTableHead
                            isFromCollaborator={_has(props.data.order, 'collaborator')}
                            hideNetPrice={hideNetPrice}
                            withParticipants={props.data.product.with_participants}
                            t={t}
                        />
                        <TableBody>
                            {_get(props.data, "product_rates", []).map((rate, index) => {
                                return (<BookingRateRow
                                    key={"bookingRate" + index}
                                    isFromCollaborator={_has(props.data.order, 'collaborator')}
                                    hideNetPrice={hideNetPrice}
                                    rate={{
                                        ...rate,
                                        currency: currency,
                                        main_currency: mainCurrency,
                                        exchange_rate: exchangeRate
                                    }}
                                    t={t}
                                />)
                            })}
                        </TableBody>
                    </Table>
                </TableContainer>
                {!props.hideRatesTotal && <Box mt={2}>
                    <DataWrapper>
                        <Grid
                            container
                            direction="row"
                            justifyContent="space-between"
                            alignItems="center"
                            spacing={2}
                        >
                            <Grid item>
                                <KeyValue label={t("bookings.fields.product_rates.total_pvp")} labelVariant="title">
                                    <ObjectStatus size="large" variant="info">
                                        {formatPrice(i18n, currency, props.data.amount.total_amount_pvp, mainCurrency, exchangeRate)}
                                    </ObjectStatus>
                                </KeyValue>
                            </Grid>
                            {!hideNetPrice && (
                                <>
                                    <Grid item>
                                        <KeyValue label={t("bookings.fields.product_rates.total_neto")} labelVariant="title">
                                            <ObjectStatus size="large" variant="info">
                                                {formatPrice(i18n, currency, totalAmount, mainCurrency, exchangeRate)}
                                            </ObjectStatus>
                                        </KeyValue>
                                    </Grid>
                                    {(totalCommission >= 0) && <Grid item>
                                        <KeyValue label={t("bookings.fields.product_rates.total_commission")} labelVariant="title">
                                            <ObjectStatus size="large" variant="info">
                                                {formatPrice(i18n, currency, totalCommission, mainCurrency, exchangeRate)}
                                            </ObjectStatus>
                                        </KeyValue>
                                    </Grid>}
                                </>
                            )}
                        </Grid>
                    </DataWrapper>
                    {discount &&
                        <DataWrapper>
                            <Grid
                                container
                                direction="row"
                                justifyContent="space-between"
                                alignItems="center"
                                spacing={2}
                            >
                                <Grid item>
                                    <KeyValue label={t("bookings.fields.product_rates.discount_name") + ' (' + discount['name'] + ": " + discount['value'] + '%)'} labelVariant="title">
                                        <ObjectStatus size="large" variant="info">
                                            {formatPrice(i18n, currency, discount['amount'], mainCurrency, exchangeRate)}
                                        </ObjectStatus>
                                    </KeyValue>
                                </Grid>
                                <Grid item>
                                    <KeyValue label={t("bookings.fields.product_rates.total_booking")} labelVariant="title">
                                        <ObjectStatus size="large" variant="info">
                                            {formatPrice(i18n, currency, totalBooking, mainCurrency, exchangeRate)}
                                        </ObjectStatus>
                                    </KeyValue>
                                </Grid>
                            </Grid>
                        </DataWrapper>
                    }
                </Box>}
            </Paper>
        </Box >
    )
}

export default BookingRates
