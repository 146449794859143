import { useTranslation } from "react-i18next"
import { AppBar, Box, Chip, IconButton, Toolbar, useTheme, useMediaQuery } from '@mui/material';
import LogoSection from "../LogoSection"
import MenuIcon from '@mui/icons-material/Menu'
import ProfileSection from "./ProfileSection"
import { useVolcanoApiClient } from "../../../context/VolcanoApiClientProvider"
import { useVolcanoAuth } from "../../../context/VolcanoAuthContext"
import EnterprisesSelector from "../../../components/Volcano/EnterprisesSelector/EnterprisesSelector"
import { useCallback } from "react"
import availableRoutes from '../../../routes/availableRoutes';
import { useNavigate } from 'react-router-dom';
import AppSwitch from "./AppSwitch";

const getEnvironmentColor = (environment, isAdminUser) => {
    switch (environment) {
        case "prod":
        case "production":
            return isAdminUser ? "warning" : "default"
        case "dev":
        case "develop":
            return "primary"
        case "stg":
        case "staging":
            return "success"
        case "local":
            return "default"
        case "undefined":
        default:
            return "warning"

    }
}

const MainBar = ({ drawerOpened, handleDrawerToggle }) => {
    const theme = useTheme()
    const matchDownSm = useMediaQuery(theme.breakpoints.down('sm'))
    const { t } = useTranslation("vbms")
    const { siteConfig } = useVolcanoApiClient()
    const volcanoAuth = useVolcanoAuth()
    const navigate = useNavigate()

    const isProduction = siteConfig.environment === "production" || siteConfig.environment === "prod"
    const isAdminUser = volcanoAuth.user.hasRole("admin")

    const onEnterpriseSelection = useCallback((value) => {
        volcanoAuth.tokenRenew(value).then((result) => {
            if (result.success) {
                if (volcanoAuth.user.allowedRoutes.includes("bookings_add")) {
                    navigate(availableRoutes.bookings_add.path)
                } else {
                    navigate("/")
                }
            }
        })
    }, [volcanoAuth, navigate])

    return (
        <AppBar
            position="fixed"
            color="inherit"
            elevation={0}
            sx={{
                transition: drawerOpened ? theme.transitions.create('width') : 'none',
                borderBottom: 1,
                borderBottomColor: theme.palette.common.container.border,
                backgroundColor: !isProduction ? "background.warning.main" : isAdminUser ? "background.success.main" : "background.default",
                //boxShadow: theme.palette.common.container.border + " 0px -0.0625rem inset, rgb(85 107 130 / 8%) 0px 0.125rem 0.25rem 0px"
            }}
        >
            <Toolbar>
                {/* logo and toogle button */}
                <Box
                    sx={{
                        display: 'flex',
                        [theme.breakpoints.down('md')]: {
                            width: 'auto'
                        }
                    }}
                >
                    <Box component="span" sx={{ display: { xs: 'none', md: 'block' }, flexGrow: 1 }}>
                        <LogoSection />
                    </Box>
                    <IconButton onClick={handleDrawerToggle}>
                        <MenuIcon />
                    </IconButton>
                </Box>

                <Box sx={{ flexGrow: 1 }} />

                {/* enterprise */}
                {volcanoAuth.user.hasPermission("access-any-enterprise") && <Box sx={{ marginRight: 1 }}>
                    <EnterprisesSelector onSelection={onEnterpriseSelection} />
                </Box>}

                {/* profile */}
                {(!isProduction || isAdminUser) && !matchDownSm && (
                    <Box sx={{
                        marginRight: 1,
                        color: "warning.main"
                    }}
                    >
                        <Chip
                            label={t("app.environment." + siteConfig.environment)}
                            variant="filled"
                            color={getEnvironmentColor(siteConfig.environment, isAdminUser)}
                            sx={{
                                color: "common.white",
                                textTransform: "uppercase",
                            }}
                        />
                        {!isProduction && <Chip
                            label={t("app.db_environment." + siteConfig.db_environment)}
                            variant="filled"
                            color={getEnvironmentColor(siteConfig.db_environment, isAdminUser)}
                            sx={{
                                color: "common.white",
                                textTransform: "uppercase",
                                marginLeft: 1,
                            }}
                        />}
                    </Box>
                )}
                <ProfileSection />
                <AppSwitch />
            </Toolbar>
        </AppBar>
    )
}

export default MainBar