import { useTranslation } from "react-i18next";
import { format as formatDate } from "date-fns"
import _isDate from "lodash/isDate"
import _get from "lodash/get"
import _set from "lodash/set"
import constants from "../../../../../../components/entity/constants";
import CollectionDataTable from "../../../../../../components/Datatable/CollectionDataTable";
import { useVolcanoApiClient } from "../../../../../../context/VolcanoApiClientProvider";
import { getTableColumns } from "../../../../FacilityBookLines/FacilityBookLinesCollection/FacilityBookLinesCollection.columns"
import { getTableFilter } from "../../../../FacilityBookLines/FacilityBookLinesCollection/FacilityBookLinesCollection.filter"


const VenueFacilityBookLines = ({ entityId }) => {
    const { t } = useTranslation("vbms");
    const { apiClient } = useVolcanoApiClient()
    const filterConfig = getTableFilter(apiClient, t)

    return (
        <CollectionDataTable
            fetcher={(params) => {
                filterConfig
                    .filter(field => field.type === constants.DATE_TYPE)
                    .forEach(field => {
                        const value = _get(params, field.id)
                        if (_isDate(value)) {
                            _set(params, field.id, formatDate(value, "yyyy-MM-dd"))
                        }
                    })
                return apiClient.venue.facilityBookLine.getFacilityBookLines(entityId, params)
            }}

            filterConfig={filterConfig}
            columns={getTableColumns(t)}
            ignoreSearchParams={true}
        />
    );
}

export default VenueFacilityBookLines;