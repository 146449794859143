import { useTranslation } from 'react-i18next';
import LoginCard from '../components/LoginCard/LoginCard';
import { useVolcanoTheme } from '../context/VolcanoThemeProvider';
import { useParams } from 'react-router-dom';

const LoginPage = () => {
    const volcanoTheme = useVolcanoTheme()
    const { t } = useTranslation("vbms")

    const { hash } = useParams()

    return (
        <LoginCard
            resetPasswordHash={hash}
            image={volcanoTheme.logo}
            title={volcanoTheme.isAdmin ? t("login.signin.titleAdmin") : t("login.signin.titlePartners")}
        />
    )
}

export default LoginPage