import { useTranslation } from "react-i18next"
import { useVolcanoApiClient } from "../../../context/VolcanoApiClientProvider"
import { useNavigate } from "react-router-dom"
import { useVolcanoAuth } from "../../../context/VolcanoAuthContext"
import CollectionPage from "../../../layout/templates/CollectionPage/CollectionPage"
import { getTableFilter } from "./DiscountCodesCollection.filter"
import { getTableColumns } from "./DiscountCodesCollection.columns"
import { getActions, getTableActions } from "./DiscountCodesCollection.actions"
import { useCache } from "../../../context/cache.context";
import _get from "lodash/get"
import _set from "lodash/set"

const DiscountCodesCollectionPage = () => {
    const { t } = useTranslation("vbms")
    const { apiClient } = useVolcanoApiClient()
    const volcanoAuth = useVolcanoAuth()
    const navigate = useNavigate()
    const cache = useCache()
    
    const table = {
        columns: getTableColumns(t),
        actions: getTableActions(
            apiClient,
            t,
            { scope: "row", icon: true, reloadOnSuccess: true, showLoading: true },
            navigate
        ),
        fetcher: (params) => {
            if (_get(params, "site_id", null)) {
                _set(params, "site_id", parseInt(_get(params, "site_id")))
            }
            return apiClient.catalog.discountCode.getDiscountCodes(params)
        }
    }

    return (
        <CollectionPage
            title={t("discount_codes.title")}
            subtitle={t("discount_codes.subtitle")}
            table={table}
            filterConfig={getTableFilter(apiClient, t, volcanoAuth.user, cache)}
            actions={getActions(t, navigate, volcanoAuth.user)}
        />
    )
}

export default DiscountCodesCollectionPage
