import { useState } from "react"
import { useTranslation } from "react-i18next"
import { Download, IosShare } from "@mui/icons-material"
import BasePage from "../BasePage"
import { filterAvailableActions } from "../../../components/action/utils"
import { FilterProvider, useFilterContext } from "../../../components/entity/context/filter/filter.context"
import FilterBar from "../../../components/FilterBar/FilterBar"
import FilterBarSummary from "../../../components/FilterBar/FilterBarSummary"
import { CollectionProvider, useDataTableContext } from "../../../components/entity/context/collection/collection.context"
import { setDirty } from "../../../components/entity/context/collection/collection.action"
import _get from "lodash/get"
import DataTable from "../../../components/Datatable/DataTable"
import { getRouteParams } from "../../../lib/utils"


const processActions = (actions, refresh) => {
    return (actions || []).map((action) => {
        if (_get(action, "reloadOnSuccess", false)) {
            return {
                ...action,
                onExecute: (data, progressHandler, onSuccess, onError) => {
                    return action.onExecute(data, progressHandler, onSuccess, onError).then(() => refresh())
                }
            }
        }

        return action
    })
}

const Subtitle = ({ filterConfig, filter, deleteFilter, subtitle }) => {
    if (!filterConfig) {
        return subtitle
    }

    return (
        <FilterBarSummary
            filterConfig={filterConfig}
            filter={filter}
            onFilterItemDelete={deleteFilter}
        />
    )
}

const HeaderContent = ({ table, filterConfig, filter, asyncFilterOptions, updateFilter, onExport }) => {
    const { t } = useTranslation("vbms")

    if (!filterConfig) {
        return null
    }

    // add actions to share and to export the collection
    const extraActions = [
        {
            id: 'share',
            title: t("table.filter.share"),
            icon: IosShare,
            onExecute: (selection, progressHandler, onSuccess) => {
                const url = new URL(window.location.href)

                const params = {
                    page: table.page,
                    rowsPerPage: table.rowsPerPage,
                    filter: structuredClone(filter)
                }

                const q = getRouteParams(params, filterConfig, table.orderBy, table.order)

                url.searchParams.set("q", q)

                navigator.clipboard.writeText(url.toString());
                onSuccess("Enlace copiado al portapapeles")
            }
        }
    ]

    if (onExport) {
        extraActions.push({
            id: 'export',
            title: t("table.filter.export"),
            icon: Download,
            withProgress: true,
            onExecute: (selection, progressHandler) => {
                onExport(filter, progressHandler)
            }
        })
    }


    return (
        <FilterBar
            filterConfig={filterConfig}
            filter={filter}
            asyncFilterOptions={asyncFilterOptions}
            onSubmit={updateFilter}
            extraActions={extraActions}
        />
    )
}

const CollectionPageContent = (props) => {
    const [isReady, setIsReady] = useState(true)
    const [filterConfig, filter, asyncFilterOptions, updateFilter, deleteFilter] = useFilterContext()
    const [tableContext, tableDispatch] = useDataTableContext()
    const { title, subtitle } = props

    const refresh = () => {
        setIsReady(false)
        setTimeout(() => {
            setIsReady(true)
            tableDispatch(setDirty())
        }, 1000)
    }

    const actions = processActions(props.actions, refresh)

    let table = {
        ...props.table,
        actions: processActions(props.table?.actions, refresh),
    }

    return (
        <BasePage
            title={title}
            subtitle={
                <Subtitle
                    filterConfig={filterConfig}
                    filter={filter}

                    deleteFilter={deleteFilter}
                    subtitle={subtitle}
                />
            }
            actions={filterAvailableActions(actions)}
            noHeaderMemoized={true}
            headerContent={
                <HeaderContent
                    table={tableContext}
                    filterConfig={filterConfig}
                    filter={filter}
                    asyncFilterOptions={asyncFilterOptions}
                    updateFilter={updateFilter}
                    onExport={props.onExport}
                />
            }
        >
            {isReady ? <DataTable
                selectionMode={table?.selectionMode ?? "none"}
                selected={table?.selected ?? []}
                groupBy={table?.groupBy ?? null}
                columns={table?.columns}
                order={table?.order}
                orderBy={table?.orderBy}
                highlight={table?.highlight}
                actions={table?.actions}
                onExport={table?.onExport}
                onSelectionChange={table?.onSelectionChange}
                hideShare={table?.hideShare}
                hideFilter={true}
            /> : null}
        </BasePage>
    )
}

const CollectionPage = (props) => {
    return (
        <FilterProvider filterConfig={props?.filterConfig}>
            <CollectionProvider
                fetcher={props.table.fetcher}
                rowsPerPage={props.table.rowsPerPage ?? 20}
                refresh={props.table.refresh ?? 0}
            >
                <CollectionPageContent {...props} />
            </CollectionProvider>
        </FilterProvider>
    )
}

export default CollectionPage
