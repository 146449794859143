import constants from "../../../components/entity/constants";
import { createCallbackField, createField, createLinkField, notificationStatesMapping, notificationTemplateTypesMapping } from '../../../components/helpers/fieldHelper';
import SummaryTable from "../../../components/Volcano/Notifications/Summary";
 
export const getTableColumns = (t) => ([
    createLinkField(
        createField(
            "name",
            t("notifications.fields.name"),
            constants.TEXT_TYPE
        ),
        {
            route: "notifications",
            field: "id",
        }
    ),
    createField(
        "date",
        t("notifications.fields.date"),
        constants.DATE_TYPE
    ),
    createField(
        "type",
        t("notifications.fields.type"),
        constants.STRING_TYPE,
        notificationTemplateTypesMapping(t)
    ),
    createField(
        "state",
        t("notifications.fields.state"),
        constants.STRING_TYPE,
        notificationStatesMapping(t)
    ),
    createCallbackField(
        "summary",
        t("notifications.fields.summary"),
        constants.STRING_TYPE,
        (value) => 
            <SummaryTable value={value} />
    ),
])