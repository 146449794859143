import { useTranslation } from "react-i18next"
import { useVolcanoApiClient } from "../../../context/VolcanoApiClientProvider"
import CollectionPage from "../../../layout/templates/CollectionPage/CollectionPage"
import { useCache } from "../../../context/cache.context"
import { getTableColumns } from "./RefundsCollection.columns"
import { getTableFilter } from "./RefundsCollection.filter"
import { getTableExport } from "./RefundsCollection.export"
import { getTableActions } from "./RefundsCollection.actions"
import constants from "../../../components/entity/constants"
import _isDate from "lodash/isDate"
import _get from "lodash/get"
import _set from "lodash/set"
import { format as formatDate } from "date-fns"
import { useVolcanoAuth } from "../../../context/VolcanoAuthContext"

const RefundsCollectionPage = () => {
    const { apiClient } = useVolcanoApiClient()
    const { t } = useTranslation("vbms")
    const cache = useCache()
    const volcanoAuth = useVolcanoAuth()

    const actions = []

    const filterConfig = getTableFilter(t, apiClient, volcanoAuth.user, cache)

    const table = {
        columns: getTableColumns(t, volcanoAuth.user),
        fetcher: (params) => {
            filterConfig
                .filter(field => field.type === constants.DATE_TYPE)
                .forEach(field => {
                    const date = _get(params, field.id)
                    if (_isDate(date)) {
                        _set(params, field.id, formatDate(date, "yyyy-MM-dd"))
                    }
                })

            return apiClient.refund.getRefunds({
                sort: "created",
                sort_direction: "asc",
                ...params,
            })
        },
        actions: getTableActions(apiClient, t, volcanoAuth.user),
        highlight: (refund) => {
            if (refund.booking.validation_date && ![1, 2].includes(refund.type.id) && ![4, 5, 6].includes(refund.state.id)) {
                return "background.warning.main"
            }

            if ([2, 3].includes(refund.state.id) && refund.auto_transaction_attempts > 0) {
                return "background.error.main"
            }

            if (refund.auto_refund) {
                return "background.info.main"
            }

            return null
        }
    }

    return (
        <CollectionPage
            title={t("refunds.title")}
            subtitle={t("refunds.subtitle")}
            actions={actions}
            table={table}
            filterConfig={filterConfig}
            onExport={getTableExport(apiClient, t)}
        />
    )
}

export default RefundsCollectionPage
