import { Box, Grid } from '@mui/material'
import BookingRatesSimple from './BookingRatesSimple'
import BookingRatesParticipants from './BookingRatesParticipants'
import _isEmpty from 'lodash/isEmpty'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import _has from 'lodash/has'
import _get from 'lodash/get'
import DataWrapper from '../../../../components/Display/DataWrapper'
import KeyValue from '../../../../components/Display/KeyValue'
import { formatPrice, formatEmptyPrice } from '../../../../lib/utils';
import ObjectStatus from '../../../../components/Display/ObjectStatus'

const calculateCost = (bookingRates, finalRates, refundPaxCost) => {
    if (!refundPaxCost)
        return null

    let cost = 0

    finalRates.forEach((finalRate) => {
        const index = bookingRates.findIndex(
            (bookingRate) => bookingRate.id === finalRate.id
        )

        if (index >= 0) {
            const qtyDiff = bookingRates[index].qty - finalRate.qty

            cost += qtyDiff * bookingRates[index].refund_cost.value;
        } else {
            cost -= finalRate.qty * refundPaxCost[finalRate.rate_id].value;
        }
    })

    return Math.max(0, cost)
}

const calculateTotals = (rates, maxAvailableQty) => {
    maxAvailableQty = isNaN(maxAvailableQty) ? Number.MAX_SAFE_INTEGER : maxAvailableQty

    if (_isEmpty(rates)) {
        return {
            netPrice: 0,
            retailPrice: 0,
            qty: 0,
            availableQty: maxAvailableQty
        }
    }

    return {
        netPrice: rates.reduce((acc, rate) => acc + (parseFloat(rate.neto) * rate.qty), 0),
        retailPrice: rates.reduce((acc, rate) => acc + (parseFloat(rate.pvp) * rate.qty), 0),
        qty: rates.reduce((acc, rate) => acc + rate.qty, 0),
        availableQty: maxAvailableQty - rates.reduce((acc, rate) => acc + rate.qty, 0)
    }
}

const BookingRates = (props) => {
    const {
        entity,
        ratesFetcher,
        currency,
        mainCurrency,
        exchangeRate,
        costExchangeRate,
        onChange
    } = props

    const { i18n, t } = useTranslation("vbms")

    const isFromIntermediary = useCallback(() => {
        return _has(entity, 'order.collaborator')
    }, [entity])

    const isRetailPrice = useCallback(() => {
        return !isFromIntermediary() || _get(entity.order, "has_liquidation_billing_type", false)
    }, [entity.order, isFromIntermediary])

    const allowEditQty = () => {
        return entity.hasAction("rates_change_request", "rates_qty_change_request")
    }

    const allowEditAmount = () => {
        return entity.hasAction("rates_change_request", "rates_amount_change_request")
    }

    const hideProductRates = () => {
        return !(entity.hasAction("rates_change_request", "rates_new_rates_change_request") && allowEditQty())
    }

    const hideRefundCost = () => {
        return !entity.hasAction("rates_change_request", "view_refund_cost")
    }

    const getMinAvailableQty = () => {
        return entity.product.qty_restrictions.min
    }

    const getMaxAvailableQty = () => {
        if (entity.hasAction("rates_change_request", "rates_qty_increase_change_request") || entity.payment_method === 'credit' || entity.payment_method === 'free') {
            return entity.product.qty_restrictions.max === 0 ? undefined : entity.product.qty_restrictions.max
        } else {
            return entity.product_rates.reduce((acc, rate) => acc + rate.qty, 0)
        }
    }

    const maxAvailableQty = getMaxAvailableQty()
    const [rates, setRates] = useState()
    const [totals, setTotals] = useState(calculateTotals(entity.product_rates, maxAvailableQty))
    const [cost, setCost] = useState(entity.refund_pax_cost ? 0 : null)

    const initTotals = useMemo(() => totals, [])

    const handleOnRatesChange = useCallback((values) => {
        const newTotals = calculateTotals(values.booking_rates, maxAvailableQty)
        const newCost = calculateCost(entity.product_rates, values.booking_rates, entity.refund_pax_cost)

        let result = {
            price_increased:
                (isRetailPrice() && newTotals.retailPrice > initTotals.retailPrice) ||
                (!isRetailPrice() && newTotals.netPrice > initTotals.netPrice),
            qty_changed: values.qty_changed,
            amount_changed: values.amount_changed,
            booking_rates: values.booking_rates.map((rate) => {
                const rateData = {
                    ...rate
                }
                delete rateData.pvp
                delete rateData.neto

                return rateData
            })
        }

        if (newCost !== null) {
            result = {
                ...result,
                refund_cost: newCost
            }
        }

        if (_has(values, "amounts_valid")) {
            result = {
                ...result,
                amounts_valid: values.amounts_valid,
            }
        }

        if (_has(values, "participants_valid")) {
            result = {
                ...result,
                participants_valid: values.participants_valid,
            }
        }

        if (values.participants_changed) {
            result = {
                ...result,
                participants_changed: values.participants_changed
            }
        }

        setTotals(newTotals)
        setCost(newCost)

        onChange(result)
    }, [entity, onChange, maxAvailableQty, initTotals, isRetailPrice])

    useEffect(() => {
        if (!_isEmpty(entity)) {
            ratesFetcher().then((rates) => {
                setRates(rates)
            })
        }
    }, [entity, ratesFetcher])

    if (_isEmpty(entity)) {
        return null
    }

    return (
        <Box sx={{ paddingLeft: 2, paddingRight: 2 }} spacing={2}>
            <Box sx={{ marginBottom: 2 }}>
                <DataWrapper>
                    <Grid container spacing={2} justifyContent="space-between" alignItems="center">
                        <Grid item>
                            <ObjectStatus size="large">
                                <KeyValue
                                    label={t("bookings.rates_edit_form.current_qty")}
                                    variant="inline">
                                    {totals.qty}
                                </KeyValue>
                            </ObjectStatus>
                        </Grid>
                        {maxAvailableQty && <Grid item>
                            <ObjectStatus size="large">
                                <KeyValue
                                    label={t("bookings.rates_edit_form.available_qty")}
                                    variant="inline">
                                    {totals.availableQty}
                                </KeyValue>
                            </ObjectStatus>
                        </Grid>}
                    </Grid>
                </DataWrapper>
            </Box>
            {
                entity.product.with_participants ?
                    <BookingRatesParticipants
                        rates={rates}
                        selection={entity.product_rates}
                        originalSelection={entity.product_rates}
                        min={getMinAvailableQty()}
                        max={maxAvailableQty}
                        isRetailPrice={isRetailPrice()}
                        hideProductRates={hideProductRates()}
                        hideRefundCost={hideRefundCost()}
                        allowEditParticipantsData={entity.hasAction("participants_edit")}
                        allowEditQty={allowEditQty()}
                        allowEditAmount={allowEditAmount()}
                        currency={currency}
                        mainCurrency={mainCurrency}
                        exchangeRate={exchangeRate}
                        costExchangeRate={costExchangeRate}
                        onChange={handleOnRatesChange}
                    /> :
                    <BookingRatesSimple
                        rates={rates}
                        selection={entity.product_rates}
                        min={getMinAvailableQty()}
                        max={maxAvailableQty}
                        isRetailPrice={isRetailPrice()}
                        hideProductRates={hideProductRates()}
                        hideRefundCost={hideRefundCost()}
                        allowEditQty={allowEditQty()}
                        allowEditAmount={allowEditAmount()}
                        currency={currency}
                        mainCurrency={mainCurrency}
                        exchangeRate={exchangeRate}
                        costExchangeRate={costExchangeRate}
                        onChange={handleOnRatesChange}
                    />
            }
            <Box sx={{ marginTop: 2 }}>
                <DataWrapper>
                    <Grid
                        container
                        direction="row"
                        justifyContent="space-between"
                        alignItems="center"
                        spacing={2}
                    >
                        {!hideRefundCost() && cost !== null && <Grid item>
                            <KeyValue label={t("bookings.rates_edit_form.cost.title")} labelVariant="title">
                                <ObjectStatus size="large" variant="critical">
                                    {isNaN(cost) ? formatEmptyPrice(i18n, currency, mainCurrency) : formatPrice(i18n, currency, cost, mainCurrency, costExchangeRate)}
                                </ObjectStatus>
                            </KeyValue>
                        </Grid>}
                        <Grid item>
                            <KeyValue label={t("bookings.fields.product_rates.total_pvp")} labelVariant="title">
                                <ObjectStatus size="large" variant="info">
                                    {isNaN(totals.retailPrice) ? + formatEmptyPrice(i18n, currency, mainCurrency) : formatPrice(i18n, currency, totals.retailPrice, mainCurrency, exchangeRate)}
                                </ObjectStatus>
                            </KeyValue>
                        </Grid>
                        {!isRetailPrice() && (
                            <Grid item>
                                <KeyValue label={t("bookings.fields.product_rates.total_neto")} labelVariant="title">
                                    <ObjectStatus size="large" variant="info">
                                        {isNaN(totals.netPrice) ? formatEmptyPrice(i18n, currency, mainCurrency) : formatPrice(i18n, currency, totals.netPrice, mainCurrency, exchangeRate)}
                                    </ObjectStatus>
                                </KeyValue>
                            </Grid>
                        )}
                    </Grid>
                </DataWrapper>
            </Box>
        </Box >
    )
}

export default BookingRates