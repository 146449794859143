import { createField, createAsyncSelectFormField, createFormFieldOption, createFormField } from "../../../../components/helpers/fieldHelper"
import constants from "../../../../components/entity/constants"
import { getLastDays } from "../../../../lib/utils";


const getFacilityBookStateFetcher = async (apiClient, t) => {
    let options = []
    const facilityBookStates = await apiClient.venue.facilityBookLine.getFacilityStates()
    options.push(createFormFieldOption("", t("table.all")))

    facilityBookStates.forEach(item => {
        let option = createFormFieldOption(item[0], item[1])
        options.push(option)
    })

    return Promise.resolve(options)
}

export const getTableFilter = (apiClient, t, user, cache) => {
    return ([
        createFormField(
            createField(
                "date_from",
                t("facility_book_lines.filter.date_from"),
                constants.DATE_TYPE,
            ),
            false,
            getLastDays(30)
        ),
        createField(
            "date_to",
            t("facility_book_lines.filter.date_to"),
            constants.DATE_TYPE
        ),
        createField(
            "blocked",
            t("facility_book_lines.filter.blocked"),
            constants.BOOLEAN_TYPE
        ),

        createAsyncSelectFormField(
            "facility_book_state_id",
            t("facility_book_lines.filter.facility_book_state_id"),
            () => getFacilityBookStateFetcher(apiClient, t),
        ),
    ])
}
