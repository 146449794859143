import TreeViewFieldValue from "../../../../../components/entity/TreeViewFieldValue";
import { getGroupedPermissions } from "../../../Roles.functions";

const RolePermissions = ({ entity }) => {
    if (!entity || !entity.permissions) {
        return null;
    }

    return (
        <TreeViewFieldValue value={getGroupedPermissions(entity.permissions)} expandMode={'expand_all'}></TreeViewFieldValue>
    );
}

export default RolePermissions;