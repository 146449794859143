import { Checkbox, TableBody, TableCell, TableRow } from "@mui/material"
import _isFunction from "lodash/isFunction"
import _unset from "lodash/unset"
import EntityField from "../../entity/EntityField"
import ObjectStatusFacet from "../../entity/ObjectStatusFacet"
import DataTableCell from "./DataTableCell"
import BaseActionsGroup from "../../action/BaseActionsGroup"
import { filterAvailableActions } from "../../action/utils"
import { useNavigate } from 'react-router-dom'

const processContent = (field, entity) => {
    switch (field.facet) {
        case "object_status":
            _unset(field.options.field, "label")
            return (
                <ObjectStatusFacet
                    size={field.options.size}
                    field={field.options.field}
                    variant={field.options.field.variant}
                    entity={entity}
                />
            );
        default:
            return <EntityField field={field} entity={entity} />
    }
}

const DataTableBody = (props) => {
    const {
        withSelection,
        columns,
        actions,
        rows,
        selected,
        onRowSelect,
        highlight,
    } = props

    const navigate = useNavigate()

    const isSelected = (id) => selected.indexOf(id) !== -1

    return (
        <TableBody>
            {rows.map((row, index) => {
                const isItemSelected = isSelected(row.id)
                const labelId = `enhanced-table-checkbox-${row.id}`

                const columnIds = {}

                const rowBgColor = _isFunction(highlight) ? highlight(row) : null

                return (
                    <TableRow
                        hover
                        role="checkbox"
                        aria-checked={isItemSelected}
                        tabIndex={-1}
                        key={row.id + "-" + columnIds[row.id]++}
                        selected={isItemSelected}
                        onClick={(event) => withSelection ? onRowSelect(event, row.id) : null}
                        sx={{ bgcolor: rowBgColor }}
                    >
                        {withSelection && (
                            <TableCell padding="checkbox">
                                <Checkbox
                                    checked={isItemSelected}
                                    inputProps={{ "aria-labelledby": labelId }}
                                    onClick={(event) => onRowSelect(event, row.id)}
                                />
                            </TableCell>
                        )}
                        {columns.map((column, index) => {
                            if (!(column.id in columnIds)) {
                                columnIds[column.id] = 0;
                            }

                            return (
                                <DataTableCell
                                    key={column.id + "-" + columnIds[column.id]++}
                                    column={column}
                                    component={index === 0 ? "td" : "td"}
                                    scope="row"
                                >
                                    {processContent(column, row)}
                                </DataTableCell>
                            )
                        })}
                        {(actions.length > 0) && (
                            <TableCell align="center" className="row-actions-group" sx={{
                                "& .base-actions-group .MuiButton-root": {
                                    lineHeight: 1.2,
                                    padding: 0.5,
                                }
                            }}>
                                <BaseActionsGroup
                                    selection={row}
                                    variant="outlined"
                                    actions={filterAvailableActions(actions, row)}
                                    actionsNumberDesktop={2}
                                    navigate={navigate}
                                />
                            </TableCell>
                        )}
                    </TableRow>
                )
            })}
        </TableBody>
    )
}

export default DataTableBody