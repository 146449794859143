import { Apps } from "@mui/icons-material"
import { Box, Grid, IconButton, Popover, Stack, Typography } from "@mui/material"
import { useState } from "react"
import { useMenu } from "../../../context/menu.context"
import { useTranslation } from "react-i18next"


const AppSwitchItem = ({ id, icon, active, onClick }) => {
    const {t} = useTranslation("vbms")

    return (
        <Grid item xs={12} sm={6} md={4}>
            <Grid 
                container
                spacing={1}
                sx={{
                    height: "100%",
                    paddingBottom: 1,
                    border: active ? ".125rem solid" : "none",
                    borderRadius: 2,
                    borderColor: "common.container.border",
                    backgroundColor: active ? "common.table.border" : "none",
                    '&:hover': {
                        backgroundColor: active ? "common.table.border" : "common.table.headerBackground",
                        cursor: "pointer",
                    }
                }}
                onClick={() => onClick(id)}    
            >
                <Grid item
                    xs="auto" sm={12}
                    sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                    }}
                >
                    {icon}
                </Grid>
                <Grid item
                    xs="auto"
                    sm={12}
                    sx={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: {
                            xs: "flex-start",
                            sm: "center",
                        },
                        paddingBottom: 1
                    }}
                >
                    <Typography variant="h6" component="div" align="center">{t(`menu.modules.${id}.title`)}</Typography>
                    <Typography variant="body2" component="div" align="center">{t(`menu.modules.${id}.description`)}</Typography>
                </Grid>
            </Grid>
        </Grid>
    )
}

const AppSwitch = () => {
    const [anchorEl, setAnchorEl] = useState(null)
    const { modules, setActiveModule } = useMenu()

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget)
    }

    const handleClose = () => {
        setAnchorEl(null)
    }

    const onItemSelected = (module) => {
        setActiveModule(module)
        handleClose()
    }

    const open = Boolean(anchorEl)
    const id = open ? "module_selector" : undefined


    if (!modules || modules.length <= 1) {
        return null
    }

    return (
        <>
            <IconButton aria-label="module-selector" aria-describedby={id} onClick={handleClick}>
                <Apps />
            </IconButton>
            <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                sx={{
                    marginTop: 2,
                }}
            >
                <Box sx={{ padding: 2 }}>
                    <Grid
                        container
                        alignItems="stretch"
                        //justifyContent="space-evenly"
                        spacing={2}
                    >
                        {modules.map((item) => (
                            <AppSwitchItem key={item.key} id={item.key} icon={item.icon} active={item?.active} onClick={onItemSelected} />
                        ))}
                    </Grid>
                </Box>

            </Popover>
        </>
    )
}

export default AppSwitch