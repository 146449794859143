import _isArray from "lodash/isArray"
import _join from "lodash/join"

const ListFieldValue = ({ value, displayField }) => {
  let result = value || ""
  result =
    _isArray(result) &&
    _join(
      result.map((element) => (displayField ? element[displayField] : element)),
      ", "
    )

  return <>{result}</>
}

export default ListFieldValue
