import { useTranslation } from "react-i18next"
import constants from "../../components/entity/constants"
import { useVolcanoApiClient } from "../../context/VolcanoApiClientProvider"
import { useNavigate, useParams } from "react-router-dom"
import { createAsyncSelectFormField, createCallbackField, createField, createFormField, createFormFieldset, createLanguagesMapping, createSelectFormField, languagesCode2, notificationTemplateTypesMapping, recipientTypesMapping, sendMethodMapping } from '../../components/helpers/fieldHelper'
import AsyncEntityFormPage from "../../layout/templates/AsyncContentPage/AsyncEntityFormPage"
import { useVolcanoAuth } from "../../context/VolcanoAuthContext"
import { useCache } from "../../context/cache.context"
import useSnackBars from "../../components/Snackbar/snack-bar.context"
import _set from "lodash/set"
import _has from "lodash/has"
import _get from "lodash/get"
import { getActions } from "./NotificationTemplatePage/NotificationTemplatePage.actions"
import availableRoutes from '../../routes/availableRoutes';
import { notificationSchemaKeyItemsFetcher } from "../../lib/form-options-fetchers";
import { useState, useCallback } from "react"


const NotificationFormPage = (props) => {
    const { t } = useTranslation()
    const { entityId } = useParams()
    const { templateId } = useParams()
    const { apiClient } = useVolcanoApiClient()
    const volcanoAuth = useVolcanoAuth()
    const cache = useCache()
    const navigate = useNavigate()
    const { addAlert } = useSnackBars()

    const [createFromTemplate, setCreateFromTemplate] = useState(false)

    const fetcher = useCallback(() => {
        if (!entityId && templateId) {
            return apiClient.notifications.getNotificationTemplate(templateId).then((template) => {
                setCreateFromTemplate(true)
                template.type_value = template.type
                template.test = template.recipients
                return template
            }) 
        }

        return  apiClient.notifications.getNotification(entityId).then((notification) => {
            if (!notification.languages) {
                notification.languages = []
            }
            return notification
        })
    }, [apiClient, entityId, templateId])

    const onSaveHandler = (notification) => {

        const notificationData = {
            ...notification,
        }

        if (!_has(notificationData, "enterprise_id")) {
            _set(notificationData, "enterprise_id", volcanoAuth.user.corporate_account.enterprise.id)
        }

        _set(notificationData, "type", _get(notificationData, "type_value"))

        if (!entityId && templateId) {
            _set(notificationData, "template_id", templateId)
            apiClient.notifications.createNotification(notificationData, 'application')
                .then((notification) => {
                    navigate(availableRoutes.notifications_view.path.replace(":entityId", notification.id))
                })
                .catch((error) => {
                    addAlert("Error: " + error.stack)
                })
        } else if (entityId) {
            apiClient.notifications.editNotification(entityId, notificationData, 'application')
                .then((notification) => {
                    navigate(availableRoutes.notifications_view.path.replace(":entityId", notification.id))
                })
                .catch((error) => {
                    addAlert("Error: " + error.message)
                })
        }
    }

    const formContent = [
        createFormFieldset(t("notifications.form.fieldset_"+(createFromTemplate ? 'template' : 'notification')), [
            createFormField(
                createField(
                    "name",
                    t("notifications.fields.name"),
                    constants.STRING_TYPE
                ),
                true
            ),
            createAsyncSelectFormField(
                "type_value",
                t("notifications.fields.type"),
                () => notificationSchemaKeyItemsFetcher("notification_template_type", cache, apiClient, t, notificationTemplateTypesMapping, false),
                false,
                true,
                (value) => value,
                true
            ),
        ]),
        createFormFieldset(t("notifications.form.fieldset_recipients"),
        [
            createAsyncSelectFormField(
                "recipient_types_values",
                t("notifications.fields.recipients.type"),
                () => notificationSchemaKeyItemsFetcher("recipient_type", cache, apiClient, t, recipientTypesMapping, false),
                true,
                true,
                (value) => value
            ),
            createAsyncSelectFormField(
                "send_types",
                t("notifications.fields.recipients.send"),
                () => notificationSchemaKeyItemsFetcher("send_method", cache, apiClient, t, sendMethodMapping, false),
                true,
                true,
                (value) => value
            ),
            createSelectFormField(
                "languages",
                t("notifications.fields.recipients.language"),
                Object.entries(createLanguagesMapping(t, languagesCode2(), true))
                    .map(([key, value]) => (
                        {
                            value: key,
                            label: value
                        }
                    )),
                true,
                false,
                true
            ),
        ]),
    ]

    return (
        <AsyncEntityFormPage
            fetcher={fetcher}
            title={createCallbackField(
                "name",
                t("notifications.form.edit_title"),
                constants.STRING_TYPE,
                (value, entity) => {
                    if (entity.id) {
                        return t("notifications.form.edit_title", {
                            name: value,
                        });
                    }
                    return t("notifications.actions.add_title");
                }
            )}
            onSave={onSaveHandler}
            formContent={formContent}
            actions={getActions(volcanoAuth.user, apiClient, entityId, t, navigate)}
        />
    )
}

export default NotificationFormPage
