import { useTranslation } from "react-i18next"
import { createFormFieldset, createEditableListFormField } from "../../../components/helpers/fieldHelper"
import AsyncEntityForm from "../../../components/form/AsyncEntityForm"
import { useVolcanoApiClient } from "../../../context/VolcanoApiClientProvider"
import ValueSelectorDialog from "../../../components/ValueSelectorDialog/ValueSelectorDialog"
import {getPickupConfigsFilter} from "../libs/collection-filters"
import { useCache } from "../../../context/cache.context"


const RoutePickupConfigsForm = ({ selection, onAction }) => {
    const { apiClient } = useVolcanoApiClient()
    const { t } = useTranslation("vbms")
    const cache = useCache()
    const pickupConfigsFilterField = getPickupConfigsFilter(apiClient, t, true, cache)

    const fetcher = () => Promise.resolve(selection)

    const handleOnConfirm = (value) => {
        onAction(value)
    }

    const formContent = [
        createFormFieldset("", [
            createEditableListFormField(
                "pickup_configs",
                "",
                "name",
                t("routes.actions.pickup_configs.modal_title"),
                ({ onConfirm, onCancel }) => <ValueSelectorDialog
                    table={pickupConfigsFilterField.table}
                    selected={pickupConfigsFilterField.selected}
                    valueFormatter={pickupConfigsFilterField.valueFormatter}
                    multipleSelection={true}
                    onConfirm={onConfirm}
                    onCancel={onCancel}
                />,
                true,
                true
            ),
        ])
    ]

    return (
        <AsyncEntityForm
            fetcher={fetcher}
            onSave={handleOnConfirm}
            formContent={formContent}
        />
    )
}

export default RoutePickupConfigsForm
