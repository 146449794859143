import { Typography } from "@mui/material"

const ObjectStatus = ({ variant, size, children }) => {
  variant = variant || 'neutral'
  size = size || 'small'

  const getColor = (variant) => {
    switch (variant) {
      case "negative": return "error.main"
      case "critical": return "warning.main"
      case "positive": return "success.main"
      case "info": return "info.main"
      case "neutral":
      default:
        return "text.main"
    }
  }

  return (
    <Typography
      component="span"
      variant={size === 'large' ? 'h3' : 'body1'}
      sx={{ color: getColor(variant) }}
    >
      {children}
    </Typography>
  );
};

export default ObjectStatus;
