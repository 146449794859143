import { Typography } from "@mui/material"

const Title = ({ level, children }) => {
    var variant = null
    switch (level) {
        case 'subtitle':
            variant = 'subtitle1'
            break;
        default:
            variant = level
    }
    return <Typography component="div" variant={variant}>{children}</Typography>
}

export default Title