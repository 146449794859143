import { useTranslation } from "react-i18next"
import AsyncEntityForm from "../../../../../components/form/AsyncEntityForm"
import { createFormFieldset, createPasswordFormField } from "../../../../../components/helpers/fieldHelper"

const ChangePassword = ({ selection, onAction, onCancel }) => {
    const { t } = useTranslation("vbms")

    const fetcher = () => new Promise((res) => res({}))

    const formContent = [
        createFormFieldset(null, [
            createPasswordFormField(
                "password",
                t("settings_system_users.fields.password"),
                "password_repeat",
                t("settings_system_users.fields.password_repeat"),
                false,
            ),
        ])
    ]

    return (
        <AsyncEntityForm
            fetcher={fetcher}
            onSave={onAction}
            formContent={formContent}
        />
    )
}

export default ChangePassword