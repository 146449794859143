import ProductsTable from "../../../../../components/Volcano/Products/ProductsTable";

const TagProducts = ({ entity }) => {

    if (!entity) {
        return null;
    }

    return (
        <ProductsTable defaultParams={{ tag_id: entity.id }} />
    )
}

export default TagProducts