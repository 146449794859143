import { createTabBarItem } from "../../../../components/helpers/fieldHelper";
import PickupPointsConfig from "./components/tabs/PickupPointsConfig";


export const getTabNavigationItems = (user, apiClient, t) => [
    createTabBarItem(
        t("pickup_configs.view.navbar.pickup_points"),
        ({ entity, data }) => (
            <PickupPointsConfig
                data={data.getItems()}
                entity={entity}
            />
        ),
        (pickupConfig) => {
            return apiClient.activityManager.pickupConfig.getPickupConfigPickupPoints(pickupConfig.id, {})
        }
    ),
];
