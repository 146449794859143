import { Box } from "@mui/material"
import { parseISO } from "date-fns"
import { compareAsc } from "date-fns"
import { useTranslation } from "react-i18next"
import ArrayDataTable from "../../../../../components/Datatable/ArrayDataTable"
import Text from "../../../../../components/Display/Text"
import constants from "../../../../../components/entity/constants"
import EntityField from "../../../../../components/entity/EntityField"
import { createCallbackField, createField } from "../../../../../components/helpers/fieldHelper"

const InvoiceLineItems = ({ entity }) => {
    const { t } = useTranslation("vbms")

    if (!entity) {
        return null
    }

    return (
        <ArrayDataTable
            data={entity.line_items.sort((lineItem1, lineItem2) =>
                compareAsc(
                    parseISO(lineItem1.booking_date),
                    parseISO(lineItem2.booking_date)
                )
            )}
            columns={[
                createField(
                    "booking_date",
                    t("invoices.fields.line_items.booking_date"),
                    constants.DATE_TYPE
                ),
                createField(
                    "salesman.name",
                    t("invoices.fields.line_items.salesman"),
                    constants.STRING_TYPE
                ),
                createCallbackField(
                    "description",
                    t("invoices.fields.line_items.description"),
                    constants.STRING_TYPE,
                    (value, lineItem) => {
                        return (
                            <>
                                <Box>{value} ({lineItem.foreign_key_id})</Box>
                                <Box>
                                    {lineItem.simplified_invoice_line_item_entries.map(
                                        (lineItemEntry) => {
                                            return (
                                                <div key={lineItemEntry.id}>
                                                    <Text variant="body2">
                                                        {lineItemEntry.description} x{" "}
                                                        {lineItemEntry.quantity} (
                                                        <EntityField
                                                            field={createField(
                                                                "unit_price",
                                                                "unit_price",
                                                                constants.CURRENCY_TYPE
                                                            )}
                                                            entity={lineItemEntry}
                                                        />
                                                        )
                                                    </Text>
                                                </div>
                                            );
                                        }
                                    )}
                                </Box>
                            </>
                        )
                    }
                ),
                createField(
                    "total_amount",
                    t("invoices.fields.line_items.total_price"),
                    constants.CURRENCY_TYPE
                ),
                createField(
                    "total_tax",
                    t("invoices.fields.line_items.total_tax"),
                    constants.CURRENCY_TYPE
                ),
            ]}
        />
    )
}


export default InvoiceLineItems