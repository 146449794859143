import constants from "../../../components/entity/constants"
import { createField, createBooleanFormField, createDependentFormField } from "../../../components/helpers/fieldHelper"
import { buildOptions,sitesFetcher } from "../../../lib/form-options-fetchers"
import _get from "lodash/get"


export const getTableFilter = (apiClient, t, user, cache) => ([
    createField(
        "name",
        t("bookings.filter.name"),
        constants.STRING_TYPE
    ),
    createField(
        "valid_from",
        t("discount_codes.fields.valid_from"),
        constants.DATE_TYPE
    ),
    createField(
        "valid_to",
        t("discount_codes.fields.valid_to"),
        constants.DATE_TYPE
    ),
    createBooleanFormField(
        t,
        "active",
        t("common.states.active"),
        null,
        true,
        0
    ),
    createDependentFormField(
        "site_id",
        t("discount_codes.fields.site"),
        constants.SELECT_TYPE,
        () => {
            const enterpriseId = parseInt(_get(user, "corporate_account.enterprise.id", "-1"))
            return sitesFetcher(cache, apiClient)
                .then((sites) => {
                    if (enterpriseId !== -1) sites = sites.filter((item) => item.enterprise.id === enterpriseId)

                    return buildOptions(sites, t)
            })
        },
    )

])