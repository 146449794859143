import { Grid } from "@mui/material";
import ArrayDataTable from "../../../../../components/Datatable/ArrayDataTable";
import constants from "../../../../../components/entity/constants";
import { useTranslation } from "react-i18next";
import { createField, createLinkField } from "../../../../../components/helpers/fieldHelper";

const IntermediaryManagedIntermediaries = ({ entity }) => {

    const { t } = useTranslation()

    if (!entity) {
        return null;
    }

    const managedIntermediaries = [
        createLinkField(
            createField(
                "name",
                t("intermediaries.fields.name"),
                constants.TEXT_TYPE
            ),
            {
                route: "intermediaries",
                field: "id",
            }
        ),
        createField(
            "state.name",
            t("intermediaries.fields.state"),
            constants.STRING_TYPE
        ),
        createField(
            "intermediary_type.name",
            t("intermediaries.fields.intermediary_type"),
            constants.STRING_TYPE
        ),
        createField(
            "seller_type.name",
            t("intermediaries.fields.seller_type"),
            constants.STRING_TYPE
        ),
        createField(
            "agency_type.name",
            t("intermediaries.fields.agency_type"),
            constants.STRING_TYPE
        ),
        createField(
            "affiliate_type.name",
            t("intermediaries.fields.affiliate_type"),
            constants.STRING_TYPE
        ),
        createField(
            "location_type.name",
            t("intermediaries.fields.location_type"),
            constants.STRING_TYPE
        ),
        createField(
            "billing_type.name",
            t("intermediaries.fields.billing_type"),
            constants.STRING_TYPE
        ),
    ]    

    return (
        <Grid container>
            <Grid container >
                <ArrayDataTable
                    data={entity.managed_intermediaries ?? []}
                    columns={managedIntermediaries}
                />
            </Grid>
        </Grid>
    )
}

export default IntermediaryManagedIntermediaries