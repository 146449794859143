import constants from "../../../components/entity/constants"
import { createCallbackField, createField } from '../../../components/helpers/fieldHelper'

export const getTableGroupBy = (t, user, i18n) => ({
    field: "product",
    columns: [
        createField(
            "experience.name",
            t("bookings.fields.experience"),
            constants.STRING_TYPE
        ),
        createField(
            "name",
            t("bookings.fields.product"),
            constants.STRING_TYPE
        ),
        createCallbackField(
            "items",
            t("bookings.title"),
            constants.NUMBER_TYPE,
            (value) => (value || []).length
        ),
        createCallbackField(
            "items",
            t("bookings.fields.pax"),
            constants.NUMBER_TYPE,
            (value) => (value || [])
                .reduce((acc, booking) =>
                    acc + booking.product_rates.reduce((acc, rate) => acc + rate.qty, 0),
                    0)
        ),
    ]
})
