import _get from "lodash/get"
import _isObject from "lodash/isObject"

const MappedFieldValue = ({ value, mapping }) => {
  const result = value && _isObject(mapping) && _get(mapping, value)

  return <>{result}</>
}

export default MappedFieldValue
