import React from 'react';
import { useDialog } from "../../../../components/Dialog/dialog.context";
import {
  createField,
  createFormField,
  createSelectFormField, typesClientsSelectMapping
} from "../../../../components/helpers/fieldHelper";
import constants from "../../../../components/entity/constants";
import ValueSelectorDialog from "../../../../components/ValueSelectorDialog/ValueSelectorDialog";
import { Button } from "@mui/material";
import { OpenInNew } from "@mui/icons-material";

const ClientSelectorDialog = ({ apiClient, t, onClientLoaded }) => {
  const { showDialog, handleClose } = useDialog();

  const filter = [
    createFormField(
      createField(
        "name",
        t("billing_client_create_invoice_widget.popup_select_client.name"),
        constants.STRING_TYPE
      ),
      false
    ),
    createFormField(
      createField(
        "vat_number",
        t("billing_client_create_invoice_widget.popup_select_client.vat_number"),
        constants.STRING_TYPE
      ),
      false
    ),
    createSelectFormField(
      "type",
      t("billing_client_create_invoice_widget.popup_select_client.type_client"),
      [
        {
          value: null,
          label: t("billing_client_create_invoice_widget.popup_select_client.types_client.all"),
        },
        {
          value: 2,
          label: t("billing_client_create_invoice_widget.popup_select_client.types_client.business"),
        },
        {
          value: 1,
          label: t("billing_client_create_invoice_widget.popup_select_client.types_client.person"),
        }
      ]
    )
  ];

  const table = {
    filterConfig: filter,
    columns: [
      createField(
        "name",
        t("billing_client_create_invoice_widget.popup_select_client.name"),
        constants.STRING_TYPE
      ),
      createField(
        "vat_number",
        t("billing_client_create_invoice_widget.popup_select_client.vat_number"),
        constants.STRING_TYPE
      ),
      createField(
        "type",
        t("billing_client_create_invoice_widget.popup_select_client.type_client"),
        constants.STRING_TYPE,
        typesClientsSelectMapping(t)
      ),
      createField(
        "country_id",
        t("billing_client_create_invoice_widget.popup_select_client.country"),
        constants.STRING_TYPE
      )
    ],
    fetcher: (params) => {
      return apiClient.billingClient.getSalesmen({
        ...params
      })
    },
  };

  const valueFormatter = (value) => {
    return value;
  };

  const onConfirmHandler = (value) => {
    handleClose();
    onClientLoaded(value);
  };

  const selected = null;

  const DialogContent = (
    <ValueSelectorDialog
      table={table}
      selected={selected}
      valueFormatter={valueFormatter}
      multipleSelection={false}
      onConfirm={onConfirmHandler}
      onCancel={handleClose}
    />
  );

  const showClientDialog = () => {
    showDialog(t("billing_client_create_invoice_widget.popup_select_client.title"), DialogContent, null, "lg");
  };

  return (
    <Button
      variant="text"
      size="small"
      onClick={() => showClientDialog()}
    >
      <OpenInNew />
    </Button>
  );
};

export default ClientSelectorDialog;
