import constants from "../../../../components/entity/constants";
import {
    createDataSetFacet,
    createField
} from "../../../../components/helpers/fieldHelper";

export const getHeaderContent = (t) => [
    createDataSetFacet(t("activity_configs.view.facet_information"), [
        createField("id", "Id", constants.STRING_TYPE),
        createField(
            "name",
            t("activity_configs.fields.name"),
            constants.STRING_TYPE
        ),
    ]),
    createDataSetFacet(t("activity_configs.view.facet_configuration"), [
        createField(
            "notification_templates.pickup_template.name",
            t("activity_configs.fields.pickup_template"),
            constants.STRING_TYPE
        ),
        createField(
            "notification_templates.cancelation_template.name",
            t("activity_configs.fields.cancel_template"),
            constants.STRING_TYPE
        ),
    ]),

];
