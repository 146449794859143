import { format } from "date-fns"
import { useCallback } from "react"
import { useVolcanoApiClient } from "../../../../context/VolcanoApiClientProvider"
import { useVolcanoAuth } from "../../../../context/VolcanoAuthContext"
import BookingWidget from "./BookingWidget"
import { useCache } from "../../../../context/cache.context"
import { pickupDataFetcher } from "../../../../lib/booking-management"
import userPermissions from "../../../../lib/user-permissions"

// process rate name
const getRateName = (rate) =>
    rate.customer_types
        .sort((ct1, ct2) => ct1.group_id === 5 ? -1 : 1)
        .reduce((names, ct) => {
            names.push(ct.name)
            return names
        }, [])
        .join(", ")

/**
* Process payment data and redirects to the required url.
*
* @param {object} paymentData
*/
const processPaymentRedirection = (paymentData) => {
    if (paymentData.type === "GET") {
        window.location.replace(paymentData.payment_url)
    } else if (paymentData.type === "POST") {
        const form = document.createElement("form")
        form.id = "paymentForm"
        form.name = "paymentForm"
        form.method = "post"
        form.action = paymentData.payment_url

        // create form fields
        Object.entries(paymentData.data).forEach(
            ([fieldName, fieldValue]) => {
                const input = document.createElement("input")
                input.type = "hidden"
                input.name = fieldName
                input.value = fieldValue
                form.appendChild(input)
            }
        )

        document.body.appendChild(form)

        form.submit()
    }
}

const VolcanoBookingWidget = ({ className, ...props }) => {
    const { apiClient } = useVolcanoApiClient()
    const volcanoAuth = useVolcanoAuth()
    const cache = useCache()

    const intermediariesFetcher = volcanoAuth.user.isIntermediary() ? null : {
        intermediary: (params) => apiClient.collaborator.getCollaborators(params),
        office: (params) => apiClient.intermediary.getIntermediaryOffices(null, params),
        salesman: (params) => apiClient.user.getApplicationUsers(params),
    }

    const featuredIntermediariesFetcher = useCallback(() => {
        return apiClient.collaborator.getCollaborators({
            featured_collaborator_id : [35663, 92575, 93765, 35587, 92165, 92219, 35753, 35399, 393806]
        })
    });

    const productsFetcher = useCallback((collaboratorId, values) => {
        return apiClient.catalog.product
            .getProducts({
                collaborator_id: collaboratorId ?? null,
                name: values.name,
                sku: values.sku,
                state: values.state,
                experience_name: values.experience_name,
            })
    }, [apiClient])

    const categoriesFetcher = useCallback(() => {
        return cache.get("subcategories")
    }, [cache])

    const featuredProductsFetcher = useCallback((collaboratorId) => {
        if (!collaboratorId) {
            return cache.get(
                "featuredProducts",
                [],
                () => {
                    return apiClient.catalog.product
                        .getFeaturedProducts({state: "all"})
                        .then((result) => result.getItems())
                }
            )
        }

        return apiClient.catalog.product
            .getFeaturedProducts({
                collaborator_id: collaboratorId,
            })
            .then((result) => result.getItems())
    }, [apiClient, volcanoAuth])

    const availabilityFetcher = useCallback((productId, date, collaboratorId = null) => {
        return apiClient.catalog.product
            .getProductAvailability(productId, date, true, { collaborator_id: collaboratorId })
            .then((result) => {
                return result;
            });
    }, [apiClient]);

    const ratesFetcher = useCallback((productId, date, collaboratorId = null) => {
        return apiClient.catalog.product
            .getProductRatesForDate(productId, date, collaboratorId)
            .then((result) => result.map((rate) => ({
                ...rate,
                name: getRateName(rate)
            })))
    }, [apiClient])

    const intPickupDataFetcher = useCallback((productId, date) => {
        return pickupDataFetcher(apiClient, productId, date)
    }, [apiClient])


    const paymentGatewaysFetcher = useCallback(() => {
        return apiClient.cart.getPaymentGateways()
    }, [apiClient])

    const bookingConfirmHandler = useCallback((selection) => {
        apiClient.cart.resetLocalCart()
        const booking = {
            type: "booking_date",
            experience_id: selection.experienceId,
            product_id: selection.product.id,
            booking_date: format(selection.date, "yyyy-MM-dd") + " " + (selection.session.session === "day_wide" ? "00:00:00" : selection.session.session),
            rates: selection.rates
                .map((rate) => {
                    return {
                        rate_id: rate.id,
                        qty: rate.qty,
                    }
                }),
        }

        if (selection?.intermediary?.id) {
            // add intermediary data to the booking
            booking.intermediary = {
                id: selection.intermediary.crm_intermediary_id,
                office_id: selection?.office?.id || null,
                salesman_id: selection?.salesman?.id || null,
            }
        }

        return apiClient.cart
            .addBooking(booking)
            .then((cart) => {
                return cart
            })
    }, [apiClient])

    const cartConfirmHandler = (cart, values) => {
        let data = {
            id: cart.id,
            payment_gateway_id: values.payment_gateway_id,
            client: values.client,
            participants: values.participants
        }

        if (volcanoAuth.user.hasPermission("access-any-enterprise")) {
            data.payment_result_base_url = window.location.origin
        }

        return apiClient.cart
            .confirmCart(data)
            .then((cart) => {
                switch (cart.state) {
                    case "completed":
                        // load bookings related with the cart
                        return apiClient.booking.getBookings({
                            order_id: cart.order_id,
                            booking_date_from: cart.line_items[0].booking_date.split(" ",1)[0].toString()
                        }).then((result) => {
                            cart.bookings = result.getItems()
                            return cart
                        })
                    case "confirmed":
                        // process payment data and redirects to the required url
                        processPaymentRedirection(cart.payment_data)
                        break
                    default:
                        //todo: handle other states
                        break
                }

                return cart
            })
    }


    const defaultSelection = volcanoAuth.user.hasPermission(userPermissions.PERM_BOOKINGS_ADMINISTER_ANY) &&
        volcanoAuth.user.hasPermission(userPermissions.PERM_CRM_INTERMEDIARY_VIEW) &&
        !volcanoAuth.user.isIntermediary() ? { step: 0 } : null

    return (
        <BookingWidget
            defaultSelection={defaultSelection}
            intermediariesFetcher={intermediariesFetcher}
            featuredIntermediariesFetcher={featuredIntermediariesFetcher}
            categoriesFetcher={categoriesFetcher}
            // experiencesFetcher={experiencesFetcher}
            productsFetcher={volcanoAuth.user.isIntermediary() ? null : productsFetcher}
            featuredProductsFetcher={featuredProductsFetcher}
            availabilityFetcher={availabilityFetcher}
            ratesFetcher={ratesFetcher}
            pickupDataFetcher={intPickupDataFetcher}
            paymentGatewaysFetcher={paymentGatewaysFetcher}
            onBookingConfirm={bookingConfirmHandler}
            onCartConfirm={cartConfirmHandler}
        />
    )
}

export default VolcanoBookingWidget
