import { useCallback, useEffect, useState } from "react"

const makeAsyncCall = (asyncFunction, setResult, setError, setStatus, ...args) => {
    if (asyncFunction === null) {
        return null
    }

    setStatus('pending')
    setResult(null)
    setError(null)

    try {
        return asyncFunction
            .call(this, ...args)
            .then(response => {
                setResult(response)
                setStatus('success')
            })
            .catch(error => {
                setError(error)
                setStatus('error')
            });

    } catch (error) {
        setError(error)
        setStatus('error')
    }
}

const useAsync = (asyncFunction, immediate = true) => {
    const [status, setStatus] = useState('idle')
    const [value, setValue] = useState(null)
    const [error, setError] = useState(null)

    // The execute function wraps fetcher and
    // handles setting state for pending, value, and error.
    // useCallback ensures the below useEffect is not called
    // on every render, but only if asyncFunction changes.
    const execute = useCallback((...args) => {
        return makeAsyncCall(asyncFunction, setValue, setError, setStatus, ...args)
    }, [asyncFunction])

    // Call execute if we want to fire it right away.
    // Otherwise execute can be called later, such as
    // in an onClick handler.
    useEffect(() => {
        if (immediate) {
            execute()
        }
    }, [execute, immediate])

    const refresh = (asyncFunction, ...args) => {
        return makeAsyncCall(asyncFunction, setValue, setError, setStatus, ...args)
    }

    return { execute, refresh, status, value, error }
}

export default useAsync