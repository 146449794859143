import { useTranslation } from "react-i18next"
import RulesSelector from "../../../../../../components/Volcano/RulesSelector/RulesSelector"
import { RulesConfig } from "../RulesConfig"
import _isEmpty from 'lodash/isEmpty';

const VenueSchedules = ({ entity }) => {
    const {t} = useTranslation("vbms")

    if (!entity) {
        return null
    }

    return (
        <RulesSelector 
            open={!_isEmpty(entity.config)}
            fetcher={entity.config}
            promise={false}
            config={RulesConfig(t)}
        />  
    )
}

export default VenueSchedules