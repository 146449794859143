import { useTranslation } from "react-i18next"
import { useNavigate } from "react-router-dom"
import { useVolcanoApiClient } from "../../../context/VolcanoApiClientProvider"
import { useVolcanoAuth } from "../../../context/VolcanoAuthContext"
import { useCache } from "../../../context/cache.context"
import { getTableFilter } from "./TagsCollection.filter"
import { getTableColumns } from "./TagsCollection.columns"
import { getActions, getTableActions } from "./TagsCollection.action"
import CollectionPage from "../../../layout/templates/CollectionPage/CollectionPage"

const TagsCollectionPage = () => {
    const { t } = useTranslation("vbms")
    const cache = useCache()
    const navigate = useNavigate();
    const volcanoAuth = useVolcanoAuth()
    const { apiClient } = useVolcanoApiClient()

    const table = {
        filterConfig: getTableFilter(apiClient, t, volcanoAuth.user, cache),
        columns: getTableColumns(t),
        actions: getTableActions(
            apiClient,
            t,
            { scope: "row", icon: true, reloadOnSuccess: true, showLoading: true },
            navigate
        ),
        fetcher: (params) => apiClient.content.tag.getTags(params),
    }

    return (
        <CollectionPage
            title={t("tags.title")}
            subtitle={t("tags.subtitle")}
            actions={getActions(t, navigate, volcanoAuth.user)}
            table={table}
        />
    )
}

export default TagsCollectionPage
