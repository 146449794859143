import ExperiencesTable from "../../../../../components/Volcano/Experiences/ExperiencesTable";

const TagExperiences = ({ entity }) => {

    if (!entity) {
        return null;
    }

    return (
        <ExperiencesTable defaultParams={{ tag_id: entity.id }} />
    )
}

export default TagExperiences