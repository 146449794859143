import { useTranslation } from "react-i18next";
import { useVolcanoApiClient } from "../../../context/VolcanoApiClientProvider";
import CollectionDataTable from "../../Datatable/CollectionDataTable";
import { BOOKING_FIELD_BOOKING_DATE, BOOKING_FIELD_CREATION_DATE, BOOKING_FIELD_EXPERIENCE, BOOKING_FIELD_LOCATOR, BOOKING_FIELD_PRODUCT, BOOKING_FIELD_STATE, BOOKING_FIELD_TOTAL_AMOUNT, processBookingFields } from "./bookingFieldHelper";

const BookingsTable = ({ columns, defaultParams }) => {
    const { apiClient } = useVolcanoApiClient()
    const { t } = useTranslation("vbms")

    columns = columns || [
        BOOKING_FIELD_LOCATOR, BOOKING_FIELD_EXPERIENCE, BOOKING_FIELD_PRODUCT, BOOKING_FIELD_CREATION_DATE, BOOKING_FIELD_BOOKING_DATE,
        BOOKING_FIELD_STATE, BOOKING_FIELD_TOTAL_AMOUNT
    ]

    const fields = processBookingFields(t, columns)
    return (
        <CollectionDataTable
            fetcher={(params) => {
                params = {
                    ...defaultParams
                    , ...params
                }
                return apiClient.booking.getBookings(params)
            }}
            columns={fields}
        />
    )
}

export default BookingsTable