import constants from "../../../components/entity/constants";
import { createAsyncSelectFormField, createBooleanFormField, createField, notificationTemplateTypesMapping } from '../../../components/helpers/fieldHelper';
import { getProductsFilter } from "../../../lib/collection-filters"
import { notificationSchemaKeyItemsFetcher } from "../../../lib/form-options-fetchers";


export const getTableFilter = (apiClient, siteConfig, t, user, cache) => ([
    createField(
        "name",
        t("notification_templates.fields.name"),
        constants.TEXT_TYPE
    ),
    createBooleanFormField(
        t,
        "system_template",
        t("notification_templates.fields.system_template"),
        null,
        true,
    ),
    createAsyncSelectFormField(
        "type",
        t("notification_templates.fields.type"),
        () => notificationSchemaKeyItemsFetcher("notification_template_type", cache, apiClient, t, notificationTemplateTypesMapping, true),
        false
    ),
    getProductsFilter(apiClient, t, user, true, { state: "active", site_id:  siteConfig.site_id})
]) 