import constants from "../../../../components/entity/constants";
import {
    createDataSetFacet,
    createField
} from "../../../../components/helpers/fieldHelper";

export const getHeaderContent = (t) => [
    createDataSetFacet(t("activities.view.facet_information"), [
        createField("id", "Id", constants.STRING_TYPE),
        createField(
            "activity_configuration.name",
            t("activities.fields.name"),
            constants.STRING_TYPE
        ),
        createField(
            "date",
            t("activities.fields.date"),
            constants.DATE_TYPE
        ),
    ]),
    createDataSetFacet(t("activities.view.facet_summary"), [
        createField(
            "summary.total_products",
            t("activities.fields.total_products"),
            constants.STRING_TYPE
        ),
        createField(
            "summary.total_bookings",
            t("activities.fields.total_bookings"),
            constants.STRING_TYPE
        ),
        createField(
            "summary.total_pax",
            t("activities.fields.total_pax"),
            constants.STRING_TYPE
        ),
    ]),
    createDataSetFacet(" ", [
        createField(
            "summary.total_bookings_pickup",
            t("activities.fields.total_bookings_pickup"),
            constants.STRING_TYPE
        ),
        createField(
            "summary.total_pax_pickup",
            t("activities.fields.total_pax_pickup"),
            constants.STRING_TYPE
        ),
        createField(
            "summary.total_amount",
            t("activities.fields.total_amount"),
            constants.CURRENCY_TYPE
        ),
    ]),
];
