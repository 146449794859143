import { Box } from "@mui/material";
import Label from "./Label";

/**
 * 
 * @param {string} label label name
 * @param {string} variant key value facet variant:
 *                           - default
 *                           - inline
 *                           - large
 * @param {*} children key value facet content
 */
const KeyValue = ({ label, labelVariant, variant, children }) => {
  variant = variant || 'default';

  const flexDirection = variant === 'inline' ? 'row' : 'column';

  return (
    <Box
      display="flex"
      flexWrap="wrap"
      alignItems={flexDirection === 'row' ? "flex-end" : 'flex-start'}
      flexDirection={flexDirection}
    >
      <Label variant={labelVariant}>{label}:</Label>
      <Box
        marginTop={variant === 'large' ? 1 : 0}
        marginLeft={variant === 'inline' ? 1 : 0}
        width={variant === 'inline' ? 'auto' : '100%'}
      >
        {children}
      </Box>
    </Box>
  );
};

export default KeyValue;
