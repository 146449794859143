import { useTranslation } from "react-i18next"
import constants from "../../../../components/entity/constants"
import { useVolcanoApiClient } from "../../../../context/VolcanoApiClientProvider"
import {useNavigate, useParams} from "react-router-dom"
import { createCallbackField } from "../../../../components/helpers/fieldHelper"
import AsyncEntityPage from "../../../../layout/templates/AsyncContentPage/AsyncEntityPage"
import { getActions } from "./ActivityPage.actions";
import { getHeaderContent } from "./ActivityPage.header";
import { useVolcanoAuth } from "../../../../context/VolcanoAuthContext"
import { getTabNavigationItems } from "./ActivityPage.tabs";
import useSnackBars from "../../../../components/Snackbar/snack-bar.context";

const ActivityPage = () => {

    const { t } = useTranslation();
    const { entityId } = useParams()
    const { apiClient } = useVolcanoApiClient()
    const volcanoAuth = useVolcanoAuth()
    const { addAlert } = useSnackBars()
    const fetcher = () => apiClient.activityManager.activity.getActivity(entityId, [])
    const navigate = useNavigate()

    return (
        <AsyncEntityPage
            fetcher={fetcher}
            title={createCallbackField(
                "activity_configuration.name",
                t("activities.fields.name"),
                constants.STRING_TYPE,
                (value) => {
                    return t("activities.view.title", { name: value });
                }
            )}
            headerContent={getHeaderContent(t)}
            actions={getActions(apiClient, t, volcanoAuth.user, navigate)}
            tabNavigationItems={getTabNavigationItems(volcanoAuth.user, apiClient, t, addAlert)}
        />
    );
};

export default ActivityPage;
