import { useTranslation } from "react-i18next"
import { useVolcanoAuth } from "../../../../../../context/VolcanoAuthContext"
import { useVolcanoApiClient } from "../../../../../../context/VolcanoApiClientProvider"
import { createFormFieldset } from "../../../../../../components/helpers/fieldHelper"
import AsyncEntityForm from "../../../../../../components/form/AsyncEntityForm"
import { getProductsFilter } from "../../../../../../lib/collection-filters"

const DiscountCodeAddProduct = ({ selection, onAction, onCancel }) => {
    const { t } = useTranslation("vbms")
    const { apiClient } = useVolcanoApiClient()
    const { user } = useVolcanoAuth()

    const fetcher = () => Promise.resolve(selection)

    const formContent = [
        createFormFieldset(null, [getProductsFilter(apiClient, t, user, true, { state: "active", site_id:  selection.site_id})])
    ]

    const onConfirm = (result) => {
        onAction({
            products: result.product_id.map((product) => ({ id: product.id }))
        })
    }

    return (
        <AsyncEntityForm
            fetcher={fetcher}
            onSave={onConfirm}
            formContent={formContent}
        />
    )
}

export default DiscountCodeAddProduct