import {buildOptions, cachedOptionsFetcher, DEFAULT_LIMIT} from "../../../lib/form-options-fetchers"

export const areasFetcher = (cache, apiClient, t, params = {}, allOption = true) => {
  params = params || {}
  params.limit = params.limit || DEFAULT_LIMIT
  return cachedOptionsFetcher(cache, "areas", apiClient.activityManager.area.getAreas(params), t, allOption)
}

export const zonesFetcher = (cache, apiClient, t, params = {}, allOption = true) => {
  params = params || {}
  params.limit = params.limit || DEFAULT_LIMIT
  return cachedOptionsFetcher(cache, "zones", apiClient.activityManager.zone.getZones(params), t, allOption)
}

export  const lodgingsFetcher = (cache, apiClient, t, params = {}, allOption = true) => {
  params = params || {}
  params.limit = params.limit || DEFAULT_LIMIT
  return cachedOptionsFetcher(cache, "lodgings", apiClient.activityManager.lodging.getLodgings(params), t, allOption)
}

export  const pickupPointsFetcher = (cache, apiClient, t, params = {}, allOption = true) => {
  params = params || {}
  params.limit = params.limit || DEFAULT_LIMIT
  return cachedOptionsFetcher(cache, "pickup_points", apiClient.activityManager.pickupPoint.getPickupPoints(params), t, allOption)
}

export const pickupConfigsFetcher = (cache, apiClient, t, params = {}, allOption = true) => {
  params = params || {}
  params.limit = params.limit || DEFAULT_LIMIT
  return cachedOptionsFetcher(cache, "pickup_configs", apiClient.activityManager.pickupConfig.getPickupConfigs(params), t, allOption)
}

export const activityConfigsFetcher = (cache, apiClient, t, params = {}, allOption = true) => {
  params = params || {}
  params.limit = params.limit || DEFAULT_LIMIT
  return cachedOptionsFetcher(cache, "activity_configs", apiClient.activityManager.activityConfig.getActivityConfigs(params), t, allOption)
}

export const activityStatesFetcher = (cache, apiClient, t,  allOption = true) => {
  return cache.get("activity_states", [], () => apiClient.activityManager.activity.getActivityStates(true)).then((schema) => {

    let options = []
    if (schema?.states && schema.states.length > 0) {
      options = buildOptions(schema.states, t, "id", "label", allOption, false)
    }
    return options
  })
}

