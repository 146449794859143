import {
    createAsyncSelectFormField, createCallbackField,
    createDependentFormField,
    createDialogFormField,
    createField, createFormFieldOption
} from "../../../components/helpers/fieldHelper"
import constants from "../../../components/entity/constants"
import {areasFetcher} from "./form-options-fetchers"
import {buildOptions, DEFAULT_LIMIT} from "../../../lib/form-options-fetchers"
import _get from "lodash/get"

export const getPickupPointsFilter = (apiClient, t, user, cache, multiple = false) => {
    return createDialogFormField(
        "pickup_points",
        t("lodgings.filter.pickup_points"),
        multiple,
        {
            filterConfig: [
                createField(
                    "name",
                    t("pickup_points.fields.name"),
                    constants.STRING_TYPE
                ),
                createAsyncSelectFormField(
                    "area_id",
                    t("pickup_points.fields.area"),
                    () => areasFetcher(cache, apiClient, t, {
                        code: "AR",
                        limit: DEFAULT_LIMIT,
                        sort: "name"
                    },true),
                    false
                ),
                createDependentFormField(
                    "zone_id",
                    t("pickup_points.fields.zone"),
                    constants.SELECT_TYPE,
                    (values) => {

                        let options = [createFormFieldOption("" ,t("table.all"))]

                        const params = {
                            area_id:  _get(values, "area_id"),
                            limit: DEFAULT_LIMIT,
                            sort: "name"
                        }

                        return apiClient.activityManager.zone.getZones(params)
                            .then((items) =>
                                options.concat(
                                    buildOptions(items.getItems(), t, "id", "name", false)
                                )
                            )
                    },
                ),
            ],
            columns: [
                createField(
                    "name",
                    t("pickup_points.fields.name"),
                    constants.STRING_TYPE
                ),
                createCallbackField(
                    "area_components",
                    t("pickup_points.fields.area"),
                    constants.STRING_TYPE,
                    (value) => {
                        const areaComponent = value.find(component => component.cod === "AR")
                        if (areaComponent) {
                            return areaComponent.name;
                        }

                        return "";
                    }
                ),
                createField(
                    "zone.name",
                    t("pickup_points.fields.zone"),
                    constants.STRING_TYPE
                ),
            ],
            fetcher: (params) => {
                return apiClient.activityManager.pickupPoint.getPickupPoints({
                    ...params,
                    limit: DEFAULT_LIMIT,
                    offset: 0,
                    sort: "name"
                })
            }
        },
        (value) => {
            return   {
                    id: value.id,
                    label: value.name,
                    entity: value
                }
        }
    )
}

export const getPickupConfigsFilter = (apiClient, t, user, cache, multiple = false) => {
    return createDialogFormField(
        "pickup_configs",
        t("routes.fields.pickup_config"),
        multiple,
        {
            filterConfig: [
                createField(
                    "name",
                    t("pickup_configs.fields.name"),
                    constants.STRING_TYPE
                ),
            ],
            columns: [
                createField(
                    "name",
                    t("pickup_configs.fields.name"),
                    constants.STRING_TYPE
                ),
            ],
            fetcher: (params) => {
                return apiClient.activityManager.pickupConfig.getPickupConfigs({
                    ...params,
                    limit: DEFAULT_LIMIT,
                    offset: 0,
                    sort: "name"
                })
            }
        },
        (value) => {
            return   {
                id: value.id,
                label: value.name,
                entity: value
            }
        }
    )
}
