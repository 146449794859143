import { useTranslation } from "react-i18next"
import AsyncEntityForm from "../../../../../components/form/AsyncEntityForm"
import constants from "../../../../../components/entity/constants"
import { createFormFieldset, createField, createTextAreaFormField, createFormField } from '../../../../../components/helpers/fieldHelper'
import _get from "lodash/get"

const RefundAccept = ({ selection, onAction, onCancel }) => {

    const { t } = useTranslation("vbms")

    const fetcher = () => Promise.resolve(selection)

    const manualRefund = selection.auto_transaction_attempts > 0 || selection.booking.order.payment_mode.id === 8 ? "true" : "false"

    const formContent = [
        createFormFieldset("", [
            createFormField(
                createField(
                    "cost",
                    t("refunds.fields.cost"),
                    constants.CURRENCY_TYPE
                ),
                true,
                null,
                null,
                true
            ),
            createFormField(
                createField(
                    "manual_refund",
                    t("refunds.fields.manual_refund"),
                    constants.BOOLEAN_TYPE,
                    null,
                    () => (selection.state.id !== 3)
                ),
                true,
                manualRefund
            ),
            createTextAreaFormField(
                "comment",
                t("common.fields.comments.comment"),
                4
            ),
        ])
    ]

    const onConfirm = (result) => {
        if (result.cost !== '') {
            onAction({
                id: selection.id,
                booking: {
                    locator: selection.booking.locator
                },
                data: {
                    cost: parseFloat(_get(result, "cost", 0).toString().replace(",", ".")),
                    manual_refund: (selection.state.id === 3) ? null : "" + result.manual_refund === "true",
                    comment: result.comment
                }
            })
        }
    }

    return (
        <AsyncEntityForm
            fetcher={fetcher}
            onSave={onConfirm}
            formContent={formContent}
        />
    )
}

export default RefundAccept