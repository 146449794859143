import { createTabBarItem } from '../../../../components/helpers/fieldHelper';
import VenueSchedules from './components/tabs/VenueSchedules';
import VenueFacilityBookLines from './components/tabs/VenueFacilityBookLines';

export const getTabNavigationItems = (t, apiClient) => [
    
    createTabBarItem(
        t("venues.view.navbar.schedules"),
        VenueSchedules,
    ),        
    createTabBarItem(
        t("venues.view.navbar.facility_book_lines"),
        ({ entity, data }) => {
            return <VenueFacilityBookLines
                entityId={entity.id}
            />
        }
    ),     
];