import { createTabBarItem } from '../../../components/helpers/fieldHelper';
import EnterpriseCurrencies from './components/tabs/EnterpriseCurrencies/EnterpriseCurrencies';
import EnterpriseBilling from './components/tabs/EnterpriseBilling/EnterpriseBilling';

export const getTabNavigationItems = (t) => [
    createTabBarItem(
        t("enterprise.view.navbar.currencies"),
        EnterpriseCurrencies,
        null,
        (entity) => entity.hasAction("currencies")
    ),
    createTabBarItem(
        t("enterprise.view.navbar.billing"),
        EnterpriseBilling
    )
];