import availableRoutes from "../../../../../../routes/availableRoutes"
import userPermissions from "../../../../../../lib/user-permissions"
import { Delete, Edit } from "@mui/icons-material";
import EnterpriseEditCurrency from "../../actions/EnterpriseEditCurrency";

export const getTableActions = (apiClient, t, navigate, user, enterpriseId) => {
    return [
        {
            id: "set_main_currency",
            title: t("enterprise.actions.set_main_currency.button"),
            scope: "row",
            reloadOnSuccess: false,
            showLoading: true,
            confirmDialog: {
                title: t("enterprise.actions.set_main_currency.modal_title"),
                content: (currency) => t("enterprise.actions.set_main_currency.modal_content", {currency: currency.id})
            },
            onExecute: (currency, progressHandler, onSuccess, onError) => {
                return apiClient.enterprise.setMainCurrency(enterpriseId, currency.id)
                    .then((result) => {
                        onSuccess(t("enterprise.actions.set_main_currency.confirm"))
                        navigate(availableRoutes.enterprise.path)
                    })
                    .catch((error) => {
                        onError(t("enterprise.actions.set_main_currency.error"))
                        throw error
                    })
            },
            condition: (entity) => entity.hasAction("set_main_currency")
        },
        {
            id: "edit",
            title: t("common.edit"),
            scope: "row",
            icon: Edit,
            reloadOnSuccess: true,
            showLoading: true,
            withDialog: {
                title: t("enterprise.actions.edit_currency.modal_title"),
                content: EnterpriseEditCurrency
            },
            onExecute: (data, progressHandler, onSuccess, onError) => {
                return apiClient.enterprise.editCurrency(enterpriseId, data.id, data)
                    .then(() => {
                        onSuccess(t("enterprise.actions.edit_currency.confirm", {
                            currency: data.name,
                            currency_code: data.id,                        
                        }))
                        navigate(availableRoutes.enterprise.path)
                    })
                    .catch((error) => {
                        onError(t("enterprise.actions.edit_currency.error", {
                            currency: data.name,
                            currency_code: data.id,
                        
                        }))
                        throw error
                    })
            },
            condition: (entity) => entity.hasAction("edit") 
        },
        {
            id: "delete",
            title: t("common.delete"),
            scope: "row",
            icon: Delete,
            reloadOnSuccess: true,
            showLoading: true,
            confirmDialog: {
                title: t("enterprise.actions.delete_currency.modal_title"),
                content: (currency) => t("enterprise.actions.delete_currency.modal_content", {
                    currency: currency.name,
                    currency_code: currency.id,
                })
            },
            onExecute: (currency, progressHandler, onSuccess, onError) => {
                return apiClient.enterprise.deleteCurrency(enterpriseId, currency.id)
                    .then(() => {
                        onSuccess(t("enterprise.actions.delete_currency.confirm"))
                        navigate(availableRoutes.enterprise.path)
                    })
                    .catch((error) => {
                        onError(t("enterprise.actions.delete_currency.error"))
                        throw error
                    })
            },
            condition: (entity) => entity.hasAction("delete") 
        },
    ]
}