/** User roles */
const ROLE_RRPP_ID = 33
const ROLE_VENUES_ADMINISTER_ID = 35
const ROLE_VENUES_REVIEWER_ID = 36
const ROLE_VENUES_OFFICER_ID = 37
const ROLE_DRIVER_ID = 38
const ROLE_CABIN_ID = 39
const ROLE_SERVICE_PERSONEL_ID = 40

export default {
    ROLE_RRPP_ID,
    ROLE_VENUES_ADMINISTER_ID,
    ROLE_VENUES_REVIEWER_ID,
    ROLE_VENUES_OFFICER_ID,
    ROLE_DRIVER_ID,
    ROLE_CABIN_ID,
    ROLE_SERVICE_PERSONEL_ID,
}   
