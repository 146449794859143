import { Check, Close, Edit, KeyboardArrowDownOutlined, KeyboardArrowUpOutlined, MailOutlineOutlined, SmsOutlined } from "@mui/icons-material"
import availableRoutes from "../../../../../../routes/availableRoutes"
import VisibilityIcon from '@mui/icons-material/Visibility';
import PreviewElement from "../../../../../../components/Volcano/Notifications/PreviewElement";
import { Box, Button, Card, CardActionArea, CardContent, Collapse, Grid, IconButton, MenuItem, Select, Stack, Table, TableBody, TableCell, TableContainer, TableRow, Typography } from "@mui/material";
import HtmlFieldValue from "../../../../../../components/entity/HtmlFieldValue";
import DataTableCell from "../../../../../../components/Datatable/components/DataTableCell";
import { useEffect, useState } from "react";
import Title from "../../../../../../components/Display/Title";
import { useNavigate } from "react-router-dom";
import _isEmpty from "lodash/isEmpty"
import DataWrapper from "../../../../../../components/Display/DataWrapper";

export const getType = (type) => {
    if (type === 'sms') {
        return <SmsOutlined/>
    } else {
        return <MailOutlineOutlined/>
    }
}

export const getStyle = (state) => {
    switch (state) {
        case 'Ok':
            return 'success.main'
        default:
            return 'error.main'
    }
}

export const getResult = (state, t) => {
    switch (state) {
        case 'Ok':
            return <Check/>
        case 'Error':
            return <Close/>
        default: 
            return t("notifications.constants.n_a");
    }
}

export const getRecipientsPrepareActions = (entityId, details, sendType, t, apiClient, user, navigate) => {
    return [
        {
            title: t("notifications.actions.mark_no_send.button"),
            scope: "table",
            reloadOnSuccess: true,
            showLoading: true,
            onExecute: (values, progressHandler, onSuccess, onError) => {
                return apiClient.notifications.markSendRecipients(values[0].notification_id, values, 'no-send')
                .then((data) => {
                    navigate(availableRoutes.notifications_view.path.replace(":entityId", data.id))
                })
                .catch((error) => {
                    throw error
                })    
            },
            condition: () => user.hasPermission('administer-any-notification')
        },
        {
            title: t("notifications.actions.mark_send.button"),
            scope: "table",
            reloadOnSuccess: true,
            showLoading: true,
            onExecute: (values, progressHandler, onSuccess, onError) => {
                return apiClient.notifications.markSendRecipients(values[0].notification_id, values)
                .then((data) => {
                    navigate(availableRoutes.notifications_view.path.replace(":entityId", data.id))
                })
                .catch((error) => {
                    throw error
                })    
            },
            condition: () => user.hasPermission('administer-any-notification')
        },
        {
            title: t("notifications.actions.preview.button"),
            scope: "row",
            icon: VisibilityIcon,
            reloadOnSuccess: true,
            showLoading: true,
            withDialog: {
                title: t("notifications.view.preview_title") + " " + t("notification_templates.constants." + sendType),
                content: (selection) => 
                    <PreviewElement
                        selection={selection.selection}
                    />
            },
            onExecute: (values, progressHandler, onSuccess, onError) => {
                
            },
        }
    ]
}

export const getRecipientsFinishedActions = (entityId, sendType, t) => {
    return [
        {
            title: t("notifications.actions.preview.button"),
            scope: "row",
            icon: VisibilityIcon,
            reloadOnSuccess: true,
            showLoading: true,
            withDialog: {
                title: t("notifications.view.preview_title") + " " + t("notification_templates.constants." + sendType),
                content: (selection) => 
                    <PreviewElement
                        selection={selection.selection}
                    />
            },
            onExecute: (values, progressHandler, onSuccess, onError) => {
                
            },
        }
    ]
}

export const getRecipientsFailedActions = (entityId, sendType, t, apiClient, user, navigate) => {
    return [
        {
            title: t("notifications.actions.preview.button"),
            scope: "row",
            icon: VisibilityIcon,
            reloadOnSuccess: true,
            showLoading: true,
            withDialog: {
                title: t("notifications.view.preview_title"),
                content: (selection) => 
                    <PreviewElement
                        selection={selection.selection}
                    />
            },
            onExecute: (values, progressHandler, onSuccess, onError) => {
                
            },
        }
    ]
}

export const TemplateData = ({parentKey, element, t}) => {
    return (<Card
                key={'card_' + parentKey}
                variant="outlined"
                sx={{
                    marginBottom: 2,
                }}
            >
            {element.values.sort((a, b) =>b.name.localeCompare(a.name)).map((data, index) => {
                const id = parentKey+'_'+index;
                return (<CardContent key={'cc_'+id}>
                            <Typography variant="h6" component="div">{t("notification_templates.constants."+data.name)}</Typography>
                            <HtmlFieldValue key={'html_'+id} value={data.value} />
                        </CardContent>
                )
            })}
        </Card>
    )
}

export const CollapseLanguage = ({url, template, parentKey, languages, entityId, recipientType, t, edit}) => {

    const [open, setOpen] = useState(false)
    const navigate = useNavigate()

    const navigateRecipientsEdit = (url, entityId, recipientType, language) => {
        const path = '/settings/'+url+'/'+entityId+'/recipients_edit/'+recipientType+'/'+language
        navigate(path)
    }

    return (
        <TableContainer>
            <Table size="small">
                <TableBody>
                    <TableRow
                        role="checkbox"
                        aria-checked={false}
                        tabIndex={-1}
                        key={'tablerow_'+parentKey}
                        selected={false}
                    >
                        <TableCell padding="checkbox">
                            <IconButton
                                aria-label="expand row"
                                size="small"
                                onClick={() => setOpen(!open)}
                            >
                                {open ? <KeyboardArrowUpOutlined /> : <KeyboardArrowDownOutlined />}
                            </IconButton>
                        </TableCell>
                        <DataTableCell
                            key={'dtc_'+parentKey+'_'+template.language}
                            column={template.language}
                            component={"td"}
                            scope="row"
                        >
                            <Title level="h5">{languages[template.language]}</Title>
                        </DataTableCell>
                        {edit && (
                        <TableCell padding="checkbox">
                            <IconButton
                                aria-label="expand row"
                                size="small"
                                onClick={() => navigateRecipientsEdit(url, entityId, recipientType, template.language, t, navigate)}
                            >
                                <Edit />
                            </IconButton>
                        </TableCell>
                        )}
                    </TableRow>
                    {template.data.map((element, elementIndex) => {
                        const id=parentKey+'_'+template.language+'_'+element.sendType+'_'+elementIndex
                        return (
                            <TableRow key={'tablerow_'+id}>
                                <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={3}>
                                    <Collapse in={open} timeout="auto" unmountOnExit>
                                        <Typography variant="h6" component="div">{t("notification_templates.constants."+element.sendType)}</Typography>
                                        <TemplateData
                                            parentKey={id}
                                            element={element}
                                            key={'templatedata_'+id}
                                            t={t}
                                            />
                                    </Collapse>
                                </TableCell>
                            </TableRow>    
                        )
                    })}
                </TableBody>
            </Table>
        </TableContainer>
    )
}

export const getRecipientDetails = (recipientTemplates) => {

    let details = []
    recipientTemplates.forEach((template) => {
        template.configuration.forEach((configuration) => {
            let data = {
                sendType: template.type,
                values: configuration.values
            }
            let element = details.find((element) => element.language === configuration.language)
            if (element) {
                element.data.push(data)
            } else {
                details.push({
                    language: configuration.language,
                    data: [data]
                })
            }
        })
    })
    return details

}

export const SessionSelector = ({ allSessions, selectedSessions, product, onSessionSelected, t }) => {

  const handleSessionClick = (session) => {
    onSessionSelected(session)
  }
  return (
      <Grid container spacing={2}>
        {allSessions.map((session) => {
            const itemSelected = selectedSessions.indexOf(session) > -1
            const color = itemSelected ? 'primary.main' : 'common.white'
            const textColor = itemSelected ? 'common.white' : 'common.black'
            return (
                <Grid key={session} item>
                    <Card variant="outlined">
                        <CardActionArea onClick={() => handleSessionClick(session)}>
                            <CardContent sx={{backgroundColor: color}}>
                                <Typography sx={{color: textColor}}>
                                    {session !== 'day_wide' ? session : t('notifications.constants.day_wide')}
                                </Typography>
                            </CardContent>
                        </CardActionArea>
                    </Card>
                </Grid>
            );
        })}
      </Grid>
    )
  }

  export const ConfigureNotificationSessions = ({productsAllSessions, productsSelectedSessions, t, onSessionSelect, onSelectAll, onSessionRangeSelect}) => {

    return (
        (!_isEmpty(productsAllSessions) && productsAllSessions.map((productAllSession) => {
            const productSelectedSessions = productsSelectedSessions.find((elem) => elem.product.id === productAllSession.product.id).sessions

            return (
                <ConfigureSessionsProduct
                    key={'csp_'+productAllSession.product.id}
                    productAllSessions={productAllSession}
                    productSelectedSessions={productSelectedSessions}
                    t={t}
                    onSessionSelect={onSessionSelect}
                    onSelectAll={onSelectAll}
                    onSessionRangeSelect={onSessionRangeSelect}
                />
            )
        }))
    )
    
  }

  export const ConfigureSessionsProduct = ({productAllSessions, productSelectedSessions, t, onSessionSelect, onSelectAll, onSessionRangeSelect}) => {

    const [loadProduct, setLoadProduct] = useState(true)
    const [selectedSessions, setSelectedSessions] = useState(productSelectedSessions)
    const [markAllButton, setMarkAllButton] = useState(true);
    const [unmarkAllButton, setUnmarkAllButton] = useState(true);
    const [fromRange, setFromRange] = useState('')
    const [toRange, setToRange] = useState('')

    const selectAll = () => {
        setLoadProduct(false)
        setSelectedSessions([...productAllSessions.allsessions])

        onSelectAll(productAllSessions.product.id, true)
        setLoadProduct(true)
        setMarkAllButton(false)
        setUnmarkAllButton(true)
    }

    const deselectAll = () => {
        setLoadProduct(false)
        setSelectedSessions([])

        onSelectAll(productAllSessions.product.id, false)
        setLoadProduct(true)
        setUnmarkAllButton(false)
        setMarkAllButton(true)
    }

    const selectRange = (mark) => {
        setLoadProduct(false)
        if (fromRange === '' || toRange === '') {
            return
        }
        const ordererSelected = [...selectedSessions].sort()
        productAllSessions.allsessions.forEach((session) => {
            if (session >= fromRange && session <= toRange) {
                if (mark) {
                    let notSelected = ordererSelected.indexOf(session) === -1
                    if (notSelected) {
                        ordererSelected.push(session)
                    }
                } else {
                    let pos = ordererSelected.indexOf(session)
                    if (pos > -1) {
                        ordererSelected.splice(pos, 1)
                    }
                }
            }
        })

        onSessionRangeSelect(productAllSessions.product.id, fromRange, toRange, mark)
        setSelectedSessions(ordererSelected)
        setMarkAllButton(ordererSelected.length < productAllSessions.allsessions.length)
        setUnmarkAllButton(ordererSelected.length > 0)

        setLoadProduct(true)
    }

    const sessionSelect = (session) => {
        setLoadProduct(false)
        let selected = [...selectedSessions]
        let productSession = selected.indexOf(session)
        let mark = true
        if (productSession > -1) {
            mark = false
            selected.splice(productSession, 1)
        } else {
            selected.push(session)
        }

        onSessionSelect(productAllSessions.product.id, session, mark)
        setLoadProduct(true)
        setSelectedSessions(selected)
    }

    useEffect(() => {
        if (productAllSessions.allsessions.length === 0) {
            setMarkAllButton(false);
            setUnmarkAllButton(false);
        } else {
            if (!_isEmpty(selectedSessions) &&
                selectedSessions.length === productAllSessions.allsessions.length) {
                setMarkAllButton(false);
                setUnmarkAllButton(true);
            } else if (_isEmpty(selectedSessions)) {
                setUnmarkAllButton(false);
                setMarkAllButton(true);
            } else {
                setMarkAllButton(true)
                setUnmarkAllButton(true)
            }
            if (productAllSessions.allsessions.length > 1) {
                let ordererSessions = productAllSessions.allsessions.sort()
                setFromRange(ordererSessions[0] !== 'day_wide' ? ordererSessions[0] : ordererSessions[1])
                setToRange(ordererSessions[ordererSessions.length -1] !== 'day_wide' ? ordererSessions[ordererSessions.length -1] : ordererSessions[ordererSessions.length -2])
            }
        }
    }, [selectedSessions, loadProduct, productAllSessions])

    return (<>
        {loadProduct && (
            <Stack spacing={2}>
                <DataWrapper
                    title={productAllSessions.product.name}
                >
                    <Stack spacing={1} direction="row" useFlexGap flexWrap="wrap" display="grid">
                        <Box className="base-actions-group" alignItems="center">
                                <Stack direction="row" spacing={2} display="box">
                                    {markAllButton && (
                                        <Button
                                            size="small"
                                            color="primary"
                                            variant="contained"
                                            onClick={selectAll}
                                        >
                                            {t("notifications.actions.mark_all.button")}
                                        </Button>
                                    )}
                                    {unmarkAllButton && (
                                        <Button
                                            size="small"
                                            color="primary"
                                            variant="contained"
                                            onClick={deselectAll}
                                        >
                                            {t("notifications.actions.unmark_all.button")}
                                        </Button>
                                    )}
                                    {productAllSessions.allsessions.length > 1 && (
                                        <>
                                        <Select
                                            id="sessionFrom"
                                            sx={{width:110}}
                                            onChange={(event) => setFromRange(event.target.value)}
                                            value={fromRange}
                                        >
                                            {productAllSessions.allsessions.filter((item) => item !== 'day_wide').map((session) => {
                                                return <MenuItem key={'sessionFrom_'+session} value={session}>{session}</MenuItem>
                                            })}
                                        </Select>
                                        <Select
                                            id="sessionTo"
                                            sx={{width:110}}
                                            onChange={(event) => setToRange(event.target.value)}
                                            value={toRange}
                                            >
                                            {productAllSessions.allsessions.filter((item) => item !== 'day_wide').map((session) => {
                                                return <MenuItem  key={'sessionTo_'+session} value={session}>{session}</MenuItem>
                                            })}
                                        </Select>
                                        <Button
                                            size="small"
                                            color="primary"
                                            variant="contained"
                                            onClick={(event) => selectRange(true)}
                                        >
                                            {t("notifications.actions.mark_range.button")}
                                        </Button>
                                        <Button
                                            size="small"
                                            color="primary"
                                            variant="contained"
                                            onClick={(event) => selectRange(false)}
                                        >
                                            {t("notifications.actions.unmark_range.button")}
                                        </Button>

                                    </>
                                    )}
                                </Stack>
                            </Box>
                        {(productAllSessions.allsessions.length > 0) && (productAllSessions.allsessions[0].length > 1) && (
                        <Box>
                            <DataWrapper
                                title={t("notifications.actions.configure_sessions.select")}
                            >
                                <SessionSelector
                                    allSessions={productAllSessions.allsessions}
                                    selectedSessions={selectedSessions}
                                    product={productAllSessions.product.id}
                                    onSessionSelected={sessionSelect}
                                    t={t}
                                />
                            </DataWrapper>
                            </Box>
                        )}
                        {_isEmpty(productAllSessions.allsessions) && (
                            <Title level='h6'>{t("notifications.constants.no_sessions")}</Title>
                        )}
                    </Stack>
                </DataWrapper>
            </Stack>                        
        )}
    </>)
}

export const SelectedSessionsProduct = ({product, t}) => {

    return (
        <DataWrapper title={product.name}>
            <Stack spacing={1} direction="row" useFlexGap flexWrap="wrap">
                {!_isEmpty(product.sessions) && product.sessions.map((session)=> {
                    return (
                        <Box key={product.id+'_'+session} sx={{borderRadius:1, bgcolor:'background.default', padding:1}}>
                            <Typography>
                                {(session !== 'day_wide'&& session !== '00:00') ? session : t('notifications.constants.day_wide')}
                            </Typography>
                        </Box>
                    )
                })}
                {product.sessions.length === 0 && (
                    <Title level='h6'>{t("notifications.constants.no_sessions")}</Title>
                )}
            </Stack>
        </DataWrapper>
    )
}


