import { Backdrop } from "@mui/material"
import LoadingInternal from "./LoadingInternal"

const Loading = ({ open, title, progress }) => {
  return (
    <Backdrop open={open} sx={{ zIndex: 10000, color: "background.default" }}>
      <LoadingInternal title={title} progress={progress} />
    </Backdrop>
  )
}

export default Loading
