import { createTabBarItem } from "../../../../components/helpers/fieldHelper"
import ZonePickupPoints from "./components/tabs/ZonePickupPoints";
import {DEFAULT_LIMIT} from "../../../../lib/form-options-fetchers";


export const getTabNavigationItems = (user, apiClient, t) => [
    createTabBarItem(
        t("zones.view.related_collection_points"),
        ({ entity, data }) => (
            <ZonePickupPoints
                data={data.getItems()}
                entity={entity}
            />
        ),
        (zone) => {
            const params = {
                zone_id: zone.id,
                limit: DEFAULT_LIMIT,
                offset: 0,
                sort: "name"
            }
            return apiClient.activityManager.pickupPoint.getPickupPoints(params)
        },
    ),
];
