import AsyncEntityForm from "../../../../../components/form/AsyncEntityForm"
import { createFormFieldset, createFormField, createPickupFormField } from '../../../../../components/helpers/fieldHelper';
import { parseISO } from 'date-fns';
import { useVolcanoApiClient } from '../../../../../context/VolcanoApiClientProvider';
import { pickupDataFetcher } from "../../../../../lib/booking-management";
import { useEffect, useState } from "react";
import _isEmpty from "lodash/isEmpty";
import SimpleLoading from "../../../../../components/Display/SimpleLoading";

const BookingActivityPickupChange = ({ selection, onAction, onCancel }) => {

    const { apiClient } = useVolcanoApiClient()
    const [pickupData, setPickupData] = useState([])

    const fetcher = () => Promise.resolve(selection)

    const formContent = [
        createFormFieldset(null, [
            createFormField(
                createPickupFormField(
                    "order.pickup",
                    "",
                    pickupData
                ),
                true
            )
        ])
    ]

    const onConfirm = (result) => {
        if (result.order.pickup.lodgin_id === 'UNK') {
            result.order.pickup.lodgin_id = null
        }

        if (result.order.pickup.pickup_point_id === 'UNK') {
            result.order.pickup.pickup_point_id = null
        }

        onAction({
            id: selection.id,
            locator: selection.locator,
            pickup: result.order.pickup
        })
    }

    useEffect(() => {
        pickupDataFetcher(apiClient, selection.product.id, parseISO(selection.booking_date))
            .then((results) => {
                setPickupData(results)
            })
    }, [apiClient, selection.product.id, selection.booking_date])

    return (
        <>
            <SimpleLoading loading={_isEmpty(pickupData)} />
            {!_isEmpty(pickupData) && (<AsyncEntityForm
                fetcher={fetcher}
                onSave={onConfirm}
                formContent={formContent}
            />)}
        </>
    )
}

export default BookingActivityPickupChange