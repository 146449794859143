import { TableCell } from "@mui/material"

const DataTableCell = ({ column, scope, component, sortDirection, children }) => {
    const processAlign = (col) => {
        if (col.align) {
            return col.align;
        }
        switch (col.type) {
            case "numeric":
            case "percentage":
            case "currency":
            case "datetime":
            case "date":
            case "weekdays":
                return "right";
            case "download":
                return "center";
            default:
                return "left";
        }
    };
    return (
        <TableCell
            align={processAlign(column)}
            sortDirection={sortDirection}
            scope={scope || "column"}
            component={component || "td"}
        >
            {children}
        </TableCell>
    )
}

export default DataTableCell
