import UsersTable from "../../../../../components/Volcano/Users/UsersTable";

const IntermediaryUsers = ({ entity }) => {

    if (!entity) {
        return null;
    }

    return (
        <UsersTable defaultParams={{ crm_intermediary_id: entity.id }} />
    )
}

export default IntermediaryUsers