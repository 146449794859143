import constants from "../../../../components/entity/constants";
import {
    createAsyncSelectFormField,
    createField,
} from "../../../../components/helpers/fieldHelper";
import {DEFAULT_LIMIT} from "../../../../lib/form-options-fetchers";
import {pickupPointsFetcher} from "../../libs/form-options-fetchers";


export const getTableFilter = (t, apiClient, user, cache) => ([
    createField(
        "name",
        t("pickup_configs.fields.name"),
        constants.TEXT_TYPE
    ),
    createAsyncSelectFormField(
        "pickup_point_id",
        t("pickup_configs.fields.pickup_point"),
        () => pickupPointsFetcher(cache, apiClient, t, {
            limit: DEFAULT_LIMIT
        },true),
        false
    ),
])
