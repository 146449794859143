import { useTranslation } from 'react-i18next'
import { Field, Form, Formik } from 'formik';
import { Box, Button, Grid, Stack, useMediaQuery, useTheme, DialogTitle } from '@mui/material';
import { FilterAltOff, OpenInNew } from '@mui/icons-material'
import EntityFormField from '../form/EntityFormField'
import EntityActionsGroup from '../action/EntityActionsGroup'
import { useDialog } from '../Dialog/dialog.context'
import _get from 'lodash/get'
import _has from 'lodash/has'
import _isArray from 'lodash/isArray'
import _isFunction from 'lodash/isFunction'
import _isEmpty from 'lodash/isEmpty'
import constants from '../entity/constants';
import { CheckboxWithLabel } from 'formik-mui';
import { useCallback } from 'react';

const processFilter = (filterConfig, asyncFilterOptions) => {
    if (!_isArray(filterConfig)) {
        return []
    }

    const result = []
    const availableFilter = filterConfig
        .map((filterField, index) => ({ ...filterField, order: index }))
        // filter fields by condition
        .filter((filterField) =>
            _isFunction(filterField.condition)
                ? filterField.condition()
                : true
        )

    // Sort important fields before not important ones
    result.push(...availableFilter.filter((filterField) => filterField.important).sort((a, b) => a.order - b.order))
    result.push(...availableFilter.filter((filterField) => !filterField.important).sort((a, b) => a.order - b.order))

    return result
        // process async fields options
        .map((filterField) => {
            if (filterField.type === constants.ASYNC_SELECT_TYPE && _has(asyncFilterOptions, filterField.id)) {
                filterField.options = asyncFilterOptions[filterField.id]
            }

            return filterField
        })
}

const FilterDialog = ({ filterConfig, filter, t, onConfirm, onCancel }) => {
    const { handleClose } = useDialog()

    const initialValues = { ...filter }

    const onConfirmHandler = (values) => {
        onConfirm({ ...filter, ...values })
    }

    const filterFields = filterConfig.filter((field) => field.type !== "aggregate")
    const aggFields = filterConfig.filter((field) => field.type === "aggregate")

    const processAggregateOptions = useCallback((options) => {
        if (!_isArray(options)) {
            return [];
        }

        return options.filter((option) =>
            _isFunction(option.condition)
                ? option.condition()
                : true
        )
    }, [])

    return (
        <Formik initialValues={initialValues}>
            {({ submitForm, isSubmitting, resetForm, setSubmitting, values }) => {
                const actions = [
                    {
                        id: "confirm",
                        title: t("common.accept"),
                        variant: "contained",
                        onExecute: () => {
                            handleClose()
                            return onConfirmHandler(values)
                        },
                    },
                    {
                        id: "cancel",
                        title: t("common.cancel"),
                        onExecute: () => {
                            handleClose()
                            if (_isFunction(onCancel)) {
                                return onCancel()
                            }
                        },
                    },
                ]

                return (
                    <Form>
                        <Box p={1}>
                            <Grid item container spacing={2}>
                                {filterFields.map((field) =>
                                    <Grid item xs={12} sm={6} key={field.id} sx={{ "& .MuiFormControl-root": { width: "100%" } }}>
                                        <EntityFormField field={field} isFilter={true} />
                                    </Grid>
                                )}
                            </Grid>
                            {aggFields.length > 0 && aggFields.map((aggField) =>
                                <Stack>
                                    <Box>
                                        <DialogTitle sx={{ marginLeft: -4 }}>
                                            {_isEmpty(aggField.label) ? t("table.filter.dialog.aggregate") : t(aggField.label)}
                                        </DialogTitle>
                                    </Box>
                                    <Box sx={{ marginTop: -1, marginBottom: 1 }}>
                                        <Grid item container>
                                            {processAggregateOptions(aggField.options).map((option) =>
                                                <Grid item xs={12} sm={4} key={option.id} sx={{ "& .MuiFormControl-root": { width: "100%" } }}>
                                                    <Field
                                                        component={CheckboxWithLabel}
                                                        type="checkbox"
                                                        name="agg_fields"
                                                        Label={{ label: t(option.label) }}
                                                        value={option.id}
                                                    />
                                                </Grid>)}
                                        </Grid>
                                    </Box>
                                </Stack>)}
                            <Box display="flex" justifyContent="right">
                                <Stack direction="row" spacing={1}>
                                    <EntityActionsGroup
                                        actions={actions}
                                        variant="outlined"
                                        actionsNumberMobile={4}
                                    />
                                </Stack>
                            </Box>
                        </Box>
                    </Form>
                )
            }}
        </Formik>
    )
}

const FilterBar = ({ filterConfig, filter, asyncFilterOptions, onSubmit, extraActions = [] }) => {
    const { t } = useTranslation("vbms")
    const theme = useTheme()
    const matchDownMd = useMediaQuery(theme.breakpoints.down('md'))

    const mainFilterItems = matchDownMd ? 0 : 6

    const availableFilter = processFilter(filterConfig, asyncFilterOptions)
    const mainFilter = availableFilter.slice(0, mainFilterItems)
    const extendedFilter = availableFilter.slice(mainFilterItems, availableFilter.length)

    const onFilterDialogConfirmHandler = (values) => {
        onSubmit({ ...filter, ...values })
    }

    const onSubmitHandler = (values, { setSubmitting }) => {
        onSubmit({ ...filter, ...values })
        setSubmitting(false)
    }

    const onResetHandler = (handleReset, { setSubmitting }) => {
        const values = {}
        availableFilter.forEach((field) => (values[field.id] = _get(field, "defaultValue", "")))

        handleReset({ values: values })
        onSubmitHandler(values, { setSubmitting })
    }

    return (
        <>
            {<Formik initialValues={filter} enableReinitialize={true} onSubmit={onSubmitHandler}>
                {({ submitForm, isSubmitting, resetForm, setSubmitting }) => {

                    const actions = [
                        {
                            id: 'reset',
                            title: t("table.filter.reset"),
                            icon: FilterAltOff,
                            withProgress: false,
                            onExecute: (selection, progressHandler) => {
                                onResetHandler(resetForm, { setSubmitting })
                            }
                        }
                    ]

                    const showDialogFilter = extendedFilter.length > 0

                    if (showDialogFilter) {
                        actions.unshift({
                            id: 'showExtendedFilters',
                            title: t("table.filter.show_extended"),
                            icon: OpenInNew,
                            withProgress: false,
                            withDialog: {
                                title: t("table.filter.dialog.title"),
                                content: () => <FilterDialog filterConfig={extendedFilter} filter={filter} t={t} onConfirm={onFilterDialogConfirmHandler} />
                            },
                            onExecute: (selection, progressHandler) => {
                            }
                        })
                    }

                    // add extra actions to the filter bar
                    actions.push(...extraActions)

                    return (
                        <Form>
                            <Box
                                display="flex"
                                p={1}
                            >
                                <Box>
                                    {<Box display="flex" flexDirection="row" flexWrap="wrap">
                                        {mainFilter.map((field) =>
                                            <Box
                                                key={field.id}
                                                sx={{
                                                    marginRight: 1,
                                                    marginBottom: 1,
                                                    '& .selectField': { minWidth: 140, maxWidth: 140 },
                                                    '& .textField': { minWidth: 140, maxWidth: 140 },
                                                    '& .dateField': { minWidth: 140, maxWidth: 140 }
                                                }}
                                            >
                                                <EntityFormField field={field} isFilter={true} />
                                            </Box>
                                        )}
                                    </Box>}
                                </Box>
                                <Box>
                                    <Stack direction="row" spacing={1}>
                                        {mainFilterItems > 0 && <Button
                                            size="small"
                                            color="primary"
                                            variant="contained"
                                            type="submit"
                                        >
                                            {t("table.filter.search")}
                                        </Button>}
                                        <EntityActionsGroup
                                            actions={actions}
                                            variant="outlined"
                                            actionsNumberMobile={4}
                                            actionsNumberDesktop={5}
                                        />
                                    </Stack>
                                </Box>
                            </Box>
                        </Form>
                    )
                }}
            </Formik>}
        </>
    )
}

export default FilterBar