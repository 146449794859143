import { useTranslation } from "react-i18next"
import AsyncEntityForm from "../../../../../components/form/AsyncEntityForm"
import { DialogContentText } from "@mui/material"

const BookingRevertNoShow = ({ selection, onAction, onCancel }) => {
    const { t } = useTranslation("vbms")

    const fetcher = () => Promise.resolve(selection)

    const onConfirm = (result) => {
        onAction({
            ...result,
            locator: selection.locator
        })

    }

    return (
        <>
            <DialogContentText pb={2}>{t("bookings.actions.revert_no_show.modal_content", { "locator": selection.locator, "id": selection.id })}</DialogContentText>
            <AsyncEntityForm
                fetcher={fetcher}
                onSave={onConfirm}
                formContent={[]}
            />
        </>
    )
}

export default BookingRevertNoShow