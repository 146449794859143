import { useTranslation } from 'react-i18next'
import constants from '../../../../../components/entity/constants'
import { createField } from '../../../../../components/helpers/fieldHelper';
import ArrayDataTable from '../../../../../components/Datatable/ArrayDataTable'
import { createIconField } from '../../../../../components/helpers/fieldHelper';
import VisibilityIcon from '@mui/icons-material/Visibility';

const BillingClientRelatedBillingClients = (props) => {
    const { t } = useTranslation("vbms")
    const columns = [
        createIconField(
            "id",
            VisibilityIcon,
            t("common.actions.view.button"),
            {
                route: "billing_clients",
                field: "id",
            },
            true
        ),
        createField(
            "vat_number",
            t("billing_clients.fields.vat_number"),
            constants.STRING_TYPE
        ),
        createField(
            "name",
            t("billing_clients.fields.name"),
            constants.STRING_TYPE
        ),
        createField(
            "enterprise.name",
            t("billing_clients.fields.enterprise"),
            constants.STRING_TYPE
        )
    ]

    return (
        <ArrayDataTable
            data={props.data}
            columns={columns}
        />
    )
}

export default BillingClientRelatedBillingClients