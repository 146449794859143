import { Box, styled, useTheme } from "@mui/material";
import { useState } from "react";
import { Outlet } from "react-router-dom";
import MainBar from "./Header/MainBar";
import Sidebar from "./Sidebar";

const drawerWidth = 200

// styles
const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })(({ theme, open }) => ({
    ...theme.typography.mainContent,
    [theme.breakpoints.up('sm')]: {
        marginTop: '65px',
    },
    padding: 0,
    ...(!open && {
        borderBottomLeftRadius: 0,
        borderBottomRightRadius: 0,
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen
        }),
        marginLeft: -(drawerWidth),
        width: '100%',
        [theme.breakpoints.down('md')]: {
            marginLeft: 0,
            width: '100%',
        },
        [theme.breakpoints.down('sm')]: {
            marginLeft: 0,
            marginTop: '57px',
            width: '100%',
        }
    }),
    ...(open && {
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen
        }),
        marginLeft: 0,
        borderBottomLeftRadius: 0,
        borderBottomRightRadius: 0,
        width: `calc(100% - ${drawerWidth}px)`,
        [theme.breakpoints.down('md')]: {
            marginLeft: 0,
            width: '100%',
        },
        [theme.breakpoints.down('sm')]: {
            marginLeft: 0,
            marginTop: '57px',
            width: '100%',
        }
    })
}))

const MainLayout = () => {
    const theme = useTheme()
    const [leftDrawerOpened, setLeftDrawerOpened] = useState(true)

    const handleLeftDrawerToggle = () => {
        setLeftDrawerOpened(!leftDrawerOpened)
    }
    return (
        <Box sx={{ display: "flex" }}>
            {/* header */}
            <MainBar drawerOpened={leftDrawerOpened} handleDrawerToggle={handleLeftDrawerToggle} />

            {/* drawer */}
            <Sidebar drawerOpen={leftDrawerOpened} drawerToogle={handleLeftDrawerToggle}></Sidebar>

            {/* main content */}
            <Main theme={theme} open={leftDrawerOpened}><Outlet /></Main>
        </Box >
    )
}

export default MainLayout