import constants from "../../../../components/entity/constants"
import {
    createField,
    createLinkField
} from "../../../../components/helpers/fieldHelper"

export const getTableColumns = (t) => ([
    createLinkField(
        createField(
            "name",
            t("routes.fields.name"),
            constants.STRING_TYPE
        ),
        {
            route: "routes",
            field: "id",
        }
    ),
    createField(
        "created",
        t("routes.fields.created"),
        constants.DATETIME_TYPE
    ),
    createField(
        "modified",
        t("routes.fields.modified"),
        constants.DATETIME_TYPE
    ),
])
