import { useTranslation } from 'react-i18next';
import constants from '../../../../../components/entity/constants';
import { createField, createCallbackField } from '../../../../../components/helpers/fieldHelper';
import ArrayDataTable from '../../../../../components/Datatable/ArrayDataTable';
import _get from 'lodash/get'

const BookingTicketValidations = (props) => {
    const { t } = useTranslation('vbms')

    const columns = [
        createField(
            'type',
            t('bookings.fields.ticket_validations.type'),
            constants.STRING_TYPE,
            {
                single: 'Individual',
                full: 'Grupo'
            }
        ),
        createField(
            'booking_ticket.locator',
            t('bookings.fields.ticket_validations.booking_ticket_locator'),
            constants.STRING_TYPE,
            null,
            () => props.data.some(item => _get(item, 'type') === 'single')
        ),
        createCallbackField(
            'booking_ticket.participant',
            t('bookings.fields.ticket_validations.booking_ticket_participant'),
            constants.STRING_TYPE,
            (value) => {
                return value && value.first_name + ' ' + value.last_name
            },
            () => props.data.some(item => _get(item, 'type') === 'single')
        ),
        createField(
            'access_control_point.name',
            t('bookings.fields.ticket_validations.access_control_point_name'),
            constants.STRING_TYPE
        ),
        createField(
            'date',
            t('bookings.fields.ticket_validations.date'),
            constants.DATETIME_TYPE
        ),
    ]

    return (
        <ArrayDataTable
            data={props.data}
            columns={columns}
        />
    )
}

export default BookingTicketValidations