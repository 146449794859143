import { useTranslation } from "react-i18next"
import { useVolcanoApiClient } from "../../../../context/VolcanoApiClientProvider"
import { useNavigate } from "react-router-dom"
import { useVolcanoAuth } from "../../../../context/VolcanoAuthContext"
import { useCache } from "../../../../context/cache.context"
import constants from "../../../../components/entity/constants"
import {getTableFilter} from "../components/Activities.filter"
import {createField} from "../../../../components/helpers/fieldHelper"
import availableRoutes from "../../../../routes/availableRoutes";
import CollectionPage from "../../../../layout/templates/CollectionPage/CollectionPage";
import {format as formatDate} from "date-fns";
import useSnackBars from "../../../../components/Snackbar/snack-bar.context";

const ActivityAddPage = () => {
    const { apiClient } = useVolcanoApiClient()
    const { t } = useTranslation("vbms")
    const volcanoAuth = useVolcanoAuth()
    const navigate = useNavigate()
    const cache = useCache()
    const { addAlert } = useSnackBars()

    let filterConfig = getTableFilter(t, apiClient, volcanoAuth.user, cache)
    // remove the state filter by key
    filterConfig = filterConfig.filter((field) => field.id !== "state");

    const addActivities = (values) => {
        const promises = values.map((value) => {
            return {
                activity_config_id: value.activity_config_id,
                date: value.date
            }
        })

        return Promise.all(promises.map((promise) => {
            return apiClient.activityManager.activity.addActivity(promise)
        }))
            .then(() => {
                navigate(availableRoutes.activities.path)
            })
            .catch((error) => {
                addAlert(error.data.message, "error")
            })
    }

    const getActions = (apiClient, t, user, navigate) => [
        {
            id: "add_activities",
            scope: "table",
            title: t("activities.actions.add.create"),
            confirmDialog: {
                title: t("activities.actions.add.confirm_title"),
                content: (selections) => t("activities.actions.add.confirm_content", { count: selections.length }),
            },
            reloadOnSuccess: true,
            showLoading: true,
            onExecute: (selections) => {
                return addActivities(selections)
            },
         },
        {
            id: "cancel",
            title: t("activities.actions.add.cancel"),
            onExecute: () => {
                navigate(availableRoutes.activities.path)
            }
        }
    ]

    const table = {
        selectionMode: "multiple",
        columns: [
            createField(
                "activity_configuration.name",
                t("activities.fields.activity_config"),
                constants.TEXT_TYPE,
                null,
                null,
                null,
                null
            ),
            createField(
                "summary.qty",
                t("activities.fields.pax"),
                constants.NUMERIC_TYPE,
                null,
                null,
                null,
                null
            ),
            createField(
                "date",
                t("activities.fields.date"),
                constants.DATE_TYPE,
                null,
                null,
                null,
                null
            ),
        ],
        actions: getActions(apiClient, t, volcanoAuth.user, navigate),
        fetcher: (params) => {
            // Añadimos el filtro para actividades con state = empty
            params.date_from = formatDate(params.date_from, "yyyy-MM-dd")
            params.date_to = formatDate(params.date_to, "yyyy-MM-dd")
            params.state = "empty"

            return apiClient.activityManager.activity.getActivities(params).then((result) => {
                let index = 0
                result.items = result.getItems().map((item) => {
                    index++
                    return {
                        ...item, id : index
                    }
                })

                return result
            })
        },
        filterConfig,
    }

    return (
        <CollectionPage
            title={t("activities.actions.add.title")}
            subtitle={t("activities.actions.add.subtitle")}
            actions={[]}
            filterConfig={filterConfig}
            table={table}
            />
    )
}

export default ActivityAddPage
