import {
    INITIALIZE,
    SET_FILTER,
    DELETE_FILTER,
    SET_ASYNC_FILTER_OPTIONS,
} from "./filter.action"

const filterReducer = (state, action) => {
    switch (action.type) {
        case INITIALIZE:
            return {
                ...state,
                ...action.filter,
            }
        case SET_FILTER:
            return {
                ...state,
                ...action.filter,
            }
        case DELETE_FILTER:
            return {
                ...state,
                [action.fieldName]: ""
            }
        case SET_ASYNC_FILTER_OPTIONS:
            return {
                ...state,
                asyncFilterOptions: action.asyncFilterOptions,
            }
        default:
            return state
    }
}

export default filterReducer