import { CardContent, Stack, Typography, Alert, Box, IconButton, Divider } from '@mui/material';
import KeyValue from '../../components/Display/KeyValue';
import Text from '../../components/Display/Text';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from "react-router-dom"
import { useCallback, useEffect, useState } from 'react';
import { useVolcanoApiClient } from '../../context/VolcanoApiClientProvider';
import { Download } from '@mui/icons-material';
import Label from '../../components/Display/Label';
import _get from 'lodash/get';
import _isEmpty from 'lodash/isEmpty';
import { downloadFile } from '../../lib/utils';
import LoadingInternal from '../../components/Display/LoadingInternal';

const PAYMENT_TRANSACTION_CUSTOMER_RESULT_STATE_NO_DATA = 0
const PAYMENT_TRANSACTION_CUSTOMER_RESULT_STATE_HAS_DATA = 1
const PAYMENT_TRANSACTION_CUSTOMER_RESULT_STATE_LOADING = 2
const PAYMENT_TRANSACTION_CUSTOMER_RESULT_STATE_PAYMENT_ACCEPTED = 3
const PAYMENT_TRANSACTION_CUSTOMER_RESULT_STATE_PAYMENT_DENIED = 4

const renderTitle = (state, t) => {
    switch (state) {
        case PAYMENT_TRANSACTION_CUSTOMER_RESULT_STATE_LOADING:
            return null
        case PAYMENT_TRANSACTION_CUSTOMER_RESULT_STATE_PAYMENT_ACCEPTED:
            return t("payment_transactions.client_result.payment_accepted.title")
        case PAYMENT_TRANSACTION_CUSTOMER_RESULT_STATE_PAYMENT_DENIED:
            return t("payment_transactions.client_result.payment_denied.title")
        case PAYMENT_TRANSACTION_CUSTOMER_RESULT_STATE_NO_DATA:
        default:
            return t("payment_transactions.client_result.payment_denied.title")
    }
}

const StatusMessage = ({ message, severity, children }) => {
    return (
        <Alert severity={severity}>
            <Box>{message}</Box>
            {children}
        </Alert>
    )
}

const PaymentTransactionCustomerResultPage = () => {
    const { t } = useTranslation("vbms")
    const [searchParams,] = useSearchParams()
    const { apiClient, siteConfig } = useVolcanoApiClient()

    const transactionId = searchParams.get("vte_transaction_id")
    const sessionId = searchParams.get("vte_sid")
    const [state, setState] = useState(transactionId && sessionId ? PAYMENT_TRANSACTION_CUSTOMER_RESULT_STATE_HAS_DATA : PAYMENT_TRANSACTION_CUSTOMER_RESULT_STATE_NO_DATA)
    const [booking, setBooking] = useState(null)

    const downloadVoucher = useCallback(() => {
        apiClient.booking
            .getBookingPdf(booking.id, booking.order.customer.sid)
            .then((pdf) =>
                downloadFile(pdf, "booking_" + booking.locator + ".pdf")
            )
    }, [apiClient.booking, booking])

    useEffect(() => {
        if (state === PAYMENT_TRANSACTION_CUSTOMER_RESULT_STATE_HAS_DATA) {
            setState(PAYMENT_TRANSACTION_CUSTOMER_RESULT_STATE_LOADING)
            apiClient.paymentTransaction.processTransactionResult({ "vte_transaction_id": transactionId }, sessionId)
                .then((bookings) => {
                    if (bookings.length > 0) {
                        setBooking(bookings[0])
                        setState(PAYMENT_TRANSACTION_CUSTOMER_RESULT_STATE_PAYMENT_ACCEPTED)
                    } else {
                        setState(PAYMENT_TRANSACTION_CUSTOMER_RESULT_STATE_PAYMENT_DENIED)
                    }
                })
                .catch((error) => {
                    setState(PAYMENT_TRANSACTION_CUSTOMER_RESULT_STATE_PAYMENT_DENIED)
                })
        }
    }, [transactionId, sessionId, state, apiClient.paymentTransaction])

    const renderContent = useCallback(() => {
        switch (state) {
            case PAYMENT_TRANSACTION_CUSTOMER_RESULT_STATE_LOADING:
                return (
                    <LoadingInternal title={t("payment_transactions.client_result.loading")} />
                )
            case PAYMENT_TRANSACTION_CUSTOMER_RESULT_STATE_PAYMENT_ACCEPTED:
                return (
                    <>
                        {booking && <Stack spacing={2}>
                            <StatusMessage
                                message={t("payment_transactions.client_result.payment_accepted.message")}
                                severity="success"
                            />
                            {!_isEmpty(siteConfig.payment_accepted_result_info) && <StatusMessage
                                message={siteConfig.payment_accepted_result_info}
                                severity="info"
                            />}
                            {!_isEmpty(siteConfig.payment_accepted_contact_info) && <StatusMessage
                                message={siteConfig.payment_accepted_contact_info}
                                severity="info"
                            />}
                            <KeyValue label={t("bookings.fields.product")} variant="inline">
                                <Text>{booking.product.name}</Text>
                            </KeyValue>
                            <KeyValue label={t("bookings.fields.locator")} variant="inline">
                                <Text>{booking.locator}</Text>
                            </KeyValue>
                            {_get(booking, "remote_sync_pending", true) === false && <Stack>
                                <Divider sx={{ marginBottom: 2 }} />
                                <Stack alignItems="center">
                                    <Label>{t("payment_transactions.client_result.payment_accepted.download_pdf")}</Label>
                                    <IconButton
                                        size="small"
                                        color="primary"
                                        variant="contained"
                                        onClick={() => downloadVoucher()}>
                                        <Download />
                                    </IconButton>
                                </Stack>
                            </Stack>}
                        </Stack>}
                    </>
                )
            case PAYMENT_TRANSACTION_CUSTOMER_RESULT_STATE_NO_DATA:
            case PAYMENT_TRANSACTION_CUSTOMER_RESULT_STATE_PAYMENT_DENIED:
            default:
                return (
                    <StatusMessage
                        message={t("payment_transactions.client_result.payment_denied.message")}
                        severity="error"
                    />
                )
        }
    }, [state, booking, t, downloadVoucher])

    return (
        <Stack>
            <Typography variant="h4" component="h1" align="center">
                {renderTitle(state, t)}
            </Typography>
            <CardContent>
                <Stack spacing={2}>
                    {renderContent()}
                </Stack>
            </CardContent>
        </Stack>
    )
}

export default PaymentTransactionCustomerResultPage