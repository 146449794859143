import _has from "lodash/has"
import { useTranslation } from "react-i18next";
import constants from "../../../../../../components/entity/constants";
import { createArrayField, createDataSetFacet, createField } from '../../../../../../components/helpers/fieldHelper';
import DatasetEntityFacet from "../../../../../../components/entity/DatasetEntityFacet"
import { Stack } from "@mui/material"
import _isArray from "lodash/isArray"

const FacilityBookLinePersonnel = ({ entity }) => {
    const { t } = useTranslation("vbms");

    const getContent = (t, user) => [
        createDataSetFacet("", [
            createArrayField(
                createField(
                    "driver_users",
                    t("facility_book_lines.fields.drivers"),
                    constants.ARRAY_TYPE,
                    null,
                    () => _has(entity, "driver_users")
                ),



                "name"

            ),
            createArrayField(
                createField(
                    "line1_users",
                    t("facility_book_lines.fields.line_responsables") + " 1",
                    constants.ARRAY_TYPE,
                    null,
                    () => _has(entity, "line1_users")
                ),
                "name"
            ),
            createArrayField(
                createField(
                    "line2_users",
                    t("facility_book_lines.fields.line_responsables") + " 2",
                    constants.ARRAY_TYPE,
                    null,
                    () => _has(entity, "line2_users")
                ),
                "name"
            ),
            createArrayField(
                createField(
                    "cabin_users",
                    t("facility_book_lines.fields.cabins"),
                    constants.ARRAY_TYPE,
                    null,
                    () => _has(entity, "cabin_users")
                ),
                "name"
            ),
            createArrayField(
                createField(
                    "officers2_users",
                    t("facility_book_lines.fields.officers_2"),
                    constants.ARRAY_TYPE,
                    null,
                    () => _has(entity, "officers2_users")
                ),
                "name"
            ),
            createArrayField(
                createField(
                    "service_personnel_users",
                    t("facility_book_lines.fields.service_personnel"),
                    constants.ARRAY_TYPE,
                    null,
                    () => _has(entity, "service_personnel_users")
                ),
                "name"
            ),
            createArrayField(
                createField(
                    "public_relation_users",
                    t("facility_book_lines.fields.public_relation"),
                    constants.ARRAY_TYPE,
                    null,
                    () => _has(entity, "public_relation_users")
                ),
                "name"
            ),
            createArrayField(
                createField(
                    "information_point_users",
                    t("facility_book_lines.fields.information_point"),
                    constants.ARRAY_TYPE,
                    null,
                    () => _has(entity, "information_point_users")
                ),
                "name"
            ),
        ]),
    ]

    const processContents = (contents) => {
        if (!_isArray(contents)) {
            return null
        }

        return contents
            .map((content, index) => {
                return (
                    <DatasetEntityFacet
                        key={index}
                        title={content.options.title}
                        fields={content.options.fields}
                        entity={entity}
                    />
                )

            })
    }
    return (
        <Stack>
            {processContents(getContent(t))}
        </Stack >
    )
}
export default FacilityBookLinePersonnel;