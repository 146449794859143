import constants from "../../../../components/entity/constants"
import {
    createAsyncSelectFormField,
    createDependentFormField,
    createField, createFormFieldOption
} from "../../../../components/helpers/fieldHelper"
import {buildOptions, DEFAULT_LIMIT} from "../../../../lib/form-options-fetchers"
import { areasFetcher } from "../../libs/form-options-fetchers"
import _get from "lodash/get"

export const getTableFilter = (t, apiClient, user, cache) => ([
    createField(
        "name",
        t("pickup_points.fields.name"),
        constants.TEXT_TYPE
    ),
    createAsyncSelectFormField(
        "area_id",
        t("pickup_points.fields.area"),
        () => areasFetcher(cache, apiClient, t, {
            code: "AR",
            limit: DEFAULT_LIMIT,
            sort: "name"
        },true),
        false
    ),
    createDependentFormField(
        "zone_id",
        t("pickup_points.fields.zone"),
        constants.SELECT_TYPE,
        (values) => {

            let options = [createFormFieldOption("" ,t("table.all"))]

            const params = {
                area_id:  _get(values, "area_id"),
                limit: DEFAULT_LIMIT,
                sort: "name"
            }

            return apiClient.activityManager.zone.getZones(params)
                .then((items) =>
                    options.concat(
                        buildOptions(items.getItems(), t, "id", "name", false)
                    )
                )
        },
    ),
])
