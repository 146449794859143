import constants from "../../../components/entity/constants"
import { createField, createFormField } from "../../../components/helpers/fieldHelper"
import _get from "lodash/get"
import _isDate from "lodash/isDate"
import { getActivitiesFilter, getExperiencesFilter } from "../../../lib/collection-filters"

export const getTableFilter = (apiClient, t, user, cache) => ([
    createFormField(
        createField(
            "booking_date",
            t("bookings.filter.booking_date"),
            constants.DATE_TYPE,
            null,
            null,
            true
        ),
        true,
        new Date(),
        (values, setFieldValue) => {
            const activity = _get(values, "activity_id[0].entity", null)

            // update the field value if the activity is set and the field value is not set to the activity date
            if (activity && (
                !_isDate(_get(values, "booking_date", null)) ||
                _get(values, "booking_date", null).getTime() !== new Date(activity.date).getTime()
            )) {
                setFieldValue("booking_date", new Date(activity.date))
            }
        }
    ),
    getActivitiesFilter(apiClient, t, user),
    getExperiencesFilter(apiClient, t, user),
])