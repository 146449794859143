import { useTranslation } from "react-i18next"
import constants from "../../components/entity/constants"
import { useVolcanoApiClient } from "../../context/VolcanoApiClientProvider"
import { useNavigate, useParams } from "react-router-dom"
import { createAsyncSelectFormField, createCallbackField, createField, createFormField, createFormFieldset, createSelectFormField, recipientTypesMapping, notificationTemplateTypesMapping, sendMethodMapping, createLanguagesMapping, languagesCode2 } from '../../components/helpers/fieldHelper'
import AsyncEntityFormPage from "../../layout/templates/AsyncContentPage/AsyncEntityFormPage"
import { useVolcanoAuth } from "../../context/VolcanoAuthContext"
import { useCache } from "../../context/cache.context"
import useSnackBars from "../../components/Snackbar/snack-bar.context"
import _set from "lodash/set"
import _has from "lodash/has"
import { getActions } from "./NotificationTemplatePage/NotificationTemplatePage.actions"
import availableRoutes from '../../routes/availableRoutes';
import { notificationSchemaKeyItemsFetcher } from "../../lib/form-options-fetchers";


const NotificationTemplatesFormPage = (props) => {
    const { t } = useTranslation()
    const { entityId } = useParams()
    const { apiClient } = useVolcanoApiClient()
    const volcanoAuth = useVolcanoAuth()
    const cache = useCache()
    const navigate = useNavigate()
    const { addAlert } = useSnackBars()


    const fetcher = () => {
        if (!entityId) {
            return new Promise((res) => res({
                name: "",
                system_template: false,
                languages: []
            }))
        }

        return apiClient.notifications.getNotificationTemplate(entityId).then((template) => {
            if (!template.languages) {
                template.languages = []
            }
            return template
        })
    }

    const onSaveHandler = (template) => {
        
        const templateData = {
            ...template,
        }

        if (!_has(templateData, "enterprise_id")) {
            _set(templateData, "enterprise_id", volcanoAuth.user.corporate_account.enterprise.id)
        }

        if (!entityId) {
            apiClient.notifications.createNotificationTemplate(templateData, 'application')
                .then((template) => {
                    navigate(availableRoutes.notification_templates_view.path.replace(":entityId", template.id))
                })
                .catch((error) => {
                    addAlert("Error: " + error.stack)
                })
        } else {
            apiClient.notifications.editNotificationTemplate(entityId, templateData, 'application')
                .then((template) => {
                    navigate(availableRoutes.notification_templates_view.path.replace(":entityId", template.id))
                })
                .catch((error) => {
                    addAlert("Error: " + error.message)
                })
        }
    }

    const formContent = [
        createFormFieldset(null, 
        [
            createFormField(
                createField(
                    "name",
                    t("notification_templates.fields.name"),
                    constants.STRING_TYPE
                ),
                true
            ),
            createFormField(
                createField(
                    "system_template",
                    t("notification_templates.fields.system_template"),
                    constants.BOOLEAN_TYPE,
                ),
                true,
                false
            ),
            createAsyncSelectFormField(
                "type",
                t("notification_templates.fields.type"),
                () => notificationSchemaKeyItemsFetcher("notification_template_type", cache, apiClient, t, notificationTemplateTypesMapping, false),
                false,
                true,
                null,
                true
            ),
        ]),
        createFormFieldset(null,
        [
            createAsyncSelectFormField(
                "recipient_types_values",
                t("notification_templates.fields.recipients.type"),
                () => notificationSchemaKeyItemsFetcher("recipient_type", cache, apiClient, t, recipientTypesMapping, false),
                true,
                true,
                (value) => value
            ),
            createAsyncSelectFormField(
                "send_types",
                t("notification_templates.fields.recipients.send"),
                () => notificationSchemaKeyItemsFetcher("send_method", cache, apiClient, t, sendMethodMapping, false),
                true,
                true,
                (value) => value
            ),
            createSelectFormField(
                "languages",
                t("notification_templates.fields.recipients.language"),
                Object.entries(createLanguagesMapping(t, languagesCode2(), true))
                    .map(([key, value]) => (
                        {
                            value: key,
                            label: value
                        }
                    )),
                true,
                false,
                true
            ),
        ]),
    ]

    return (
        <AsyncEntityFormPage
            fetcher={fetcher}
            title={createCallbackField(
                "name",
                t("notification_templates.form.edit_title"),
                constants.STRING_TYPE,
                (value, entity) => {
                    if (entity.id) {
                        return t("notification_templates.form.edit_title", {
                            name: value,
                        });
                    }
                    return t("notification_templates.actions.add_title");
                }
            )}
            onSave={onSaveHandler}
            formContent={formContent}
            actions={getActions(volcanoAuth.user, apiClient, entityId, t, navigate)}
        />
    )
}

export default NotificationTemplatesFormPage
