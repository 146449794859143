import { useTranslation } from "react-i18next"
import AsyncEntityForm from "../../form/AsyncEntityForm"
import { createFormFieldset, createTextAreaFormField } from '../../helpers/fieldHelper';

const CommentForm = ({ selection, onAction, onCancel }) => {
    const { t } = useTranslation("vbms")

    const fetcher = () => new Promise((res) => res({}))

    const handleOnSave = (data) => {
        onAction({
            id: selection.id,
            ...data
        })
    }

    const formContent = [
        createFormFieldset(null, [
            createTextAreaFormField(
                "comment",
                t("common.fields.comments.comment"),
                4
            ),
        ])
    ]

    return (
        <AsyncEntityForm
            fetcher={fetcher}
            onSave={handleOnSave}
            formContent={formContent}
        />
    )
}

export default CommentForm