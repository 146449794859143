import { useTranslation } from "react-i18next"
import constants from "../../../components/entity/constants"
import { createAsyncSelectFormField, createField, createFormFieldset, createPersonTypeMapping, vatNumberTypeMapping, createSelectFormField, createBooleanFormField } from '../../../components/helpers/fieldHelper';
import FormikEntityForm from "../../../components/form/FormikEntityForm";

const BillingClientForm = (props) => {

    const { entity, enterprisesFetcher, onChange } = props

    const { t } = useTranslation()

    const formContent = [
        createFormFieldset(t("billing_clients.form.fieldset_information"), [
            createAsyncSelectFormField(
                "enterprise.id",
                t("billing_clients.fields.enterprise"),
                enterprisesFetcher,
                false
            ),
            createSelectFormField(
                "type",
                t("billing_clients.fields.type"),
                Object.entries(createPersonTypeMapping(t)).map(([key, value]) => ({ value: key, label: value }))
            ),
            createField(
                "name",
                t("billing_clients.fields.name"),
                constants.STRING_TYPE
            ),
            createSelectFormField(
                "vat_number_type",
                t("billing_clients.fields.vat_number_type"),
                Object.entries(vatNumberTypeMapping(t)).map(([key, value]) => ({ value: key, label: value }))
            ),
            createField(
                "vat_number",
                t("billing_clients.fields.vat_number"),
                constants.STRING_TYPE
            ),
            createField(
                "accountancy_config.account_id",
                t("billing_clients.fields.account_id"),
                constants.STRING_TYPE
            ),
            createBooleanFormField(
                t,
                "allow_repeated",
                t("billing_clients.fields.allow_repeated"),
                null,
                false,
                "0"
            ),
        ]),
        createFormFieldset(t("billing_clients.view.facet_content"), [
            createField(
                "contact_details",
                null,
                constants.ADDRESS_TYPE
            ),
        ]),
    ]

    return (
        <FormikEntityForm
            entity={entity}
            onChange={onChange}
            formContent={formContent}
            variant="default"
        />
    )
}

export default BillingClientForm