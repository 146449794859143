import { downloadFile, getSuggestedForCollaboratorFilename, openInNewTab } from "../../../lib/utils"

export const getShareActions = (user, apiClient, entityId, t) => [
    {
        id: "link",
        title: t("common.actions.share.options.link"),
        onExecute: (invoice, progressHandler, onSuccess, onError) => {
            navigator.clipboard.writeText(window.location.href)
            onSuccess(t("common.actions.share.confirm"))
        },
    },
    {
        id: "link_pdf",
        title: t("common.actions.share.options.open_pdf"),
        onExecute: (invoice, progressHandler, onSuccess, onError) => {
            const data = {
                resource: "invoices",
                operation: "invoice_pdf",
                id: invoice.id,
                params: {
                    title: getSuggestedForCollaboratorFilename(invoice.title, invoice.collaborator)
                }
            }
            return apiClient.download.createLink(data)
                .then((link) => {
                    openInNewTab(link.url);
                })
                .catch((error) => {
                    onError(t("common.actions.share.error"))
                })
        },
    },
    {   
        id: "download_pdf",
        title: t("common.download"),
        onExecute: (invoice, progressHandler, onSuccess, onError) => {
            apiClient.invoice.getInvoicePdf(invoice.id).then(pdf => {
                downloadFile(pdf, getSuggestedForCollaboratorFilename(invoice.title, invoice.data.collaborator) + ".pdf")
            })
        },
    }
]