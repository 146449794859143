import { format as formatDate } from 'date-fns';
import constants from "../../../../components/entity/constants"
import { createField, createLinkField, createCallbackField } from '../../../../components/helpers/fieldHelper'


export const getTableColumns = (t) => ([
    createLinkField(
        createCallbackField(
            "date",
            t("facility_book_lines.fields.date"),
            constants.DATE_TYPE,
            (value) => formatDate(new Date(value), "dd/MM/yyyy")
        ),
        {
            route: "facility_book_lines",
            field: "id",
        }
    ),

    createField(
        "blocked",
        t("facility_book_lines.fields.blocked"),
        constants.BOOLEAN_TYPE
    ),
    createField(
        "facility_book_state.name",
        t("facility_book_lines.fields.facility_book_state"),
        constants.STRING_TYPE
    ),
    createField(
        "open_time",
        t("facility_book_lines.fields.open_time"),
        constants.STRING_TYPE
    ),
    createField(
        "close_time",
        t("facility_book_lines.fields.close_time"),
        constants.STRING_TYPE
    ),
])