import { Container } from '@mui/material'
import { LocalizationProvider } from '@mui/x-date-pickers'
import { SnackBarProvider } from './components/Snackbar/snack-bar.context'
import { VolcanoAPIClientProvider } from './context/VolcanoApiClientProvider'
import VolcanoAuthProvider from './context/VolcanoAuthProvider'
import { VolcanoThemeProvider } from './context/VolcanoThemeProvider'
import AppRoutes from './routes/AppRoutes'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import enGB from 'date-fns/locale/en-GB'
import esES from 'date-fns/locale/es'
import { useTranslation } from 'react-i18next'
import { CacheProvider } from './context/cache.context'
import { MenuProvider } from './context/menu.context'
import { modulesMenu } from './routes/mainMenu'

const dateLocales = {
    en: enGB,
    es: esES,
}

function App() {
    const { i18n } = useTranslation('vbms')

    return (
        <VolcanoAPIClientProvider>
            <VolcanoAuthProvider>
                <MenuProvider menu={modulesMenu}>
                    <VolcanoThemeProvider>
                        <CacheProvider>
                            <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={dateLocales[i18n.language]}>
                                <SnackBarProvider>
                                    <Container disableGutters maxWidth={false}>
                                        <AppRoutes />
                                    </Container>
                                </SnackBarProvider>
                            </LocalizationProvider>
                        </CacheProvider>
                    </VolcanoThemeProvider>
                </MenuProvider>
            </VolcanoAuthProvider>
        </VolcanoAPIClientProvider>
    );
}

export default App;
