import { useTranslation } from "react-i18next"
import AsyncEntityForm from "../../../../../components/form/AsyncEntityForm"
import constants from "../../../../../components/entity/constants"
import { createFormFieldset, createField, createFormField } from '../../../../../components/helpers/fieldHelper'

const RefundReject = ({ selection, onAction, onCancel }) => {

    const { t } = useTranslation("vbms")

    const fetcher = () => Promise.resolve(selection)

    const formContent = [
        createFormFieldset("", [
            createFormField(
                createField(
                    "notify_client",
                    t("refunds.fields.notify_client"),
                    constants.BOOLEAN_TYPE
                ),
                true,
                "true"
            ),
        ])
    ]

    const onConfirm = (result) => {
        onAction({
            id: selection.id,
            booking: {
                locator: selection.booking.locator
            },
            data: {
                notify_client: "" + result.notify_client === "true",
            }
        })
    }

    return (
        <AsyncEntityForm
            fetcher={fetcher}
            onSave={onConfirm}
            formContent={formContent}
        />
    )
}

export default RefundReject