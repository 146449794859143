import constants from "../../../../components/entity/constants"
import {
    createCallbackField,
    createDataSetFacet,
    createField
} from "../../../../components/helpers/fieldHelper";
import {Link} from "@mui/material";

export const getHeaderContent = (t) => [
    createDataSetFacet(t("lodgings.view.facet_information"), [
        createField("id", "Id", constants.STRING_TYPE),
        createField(
            "name",
            t("lodgings.fields.name"),
            constants.STRING_TYPE
        ),
        createField(
            "address",
            t("lodgings.fields.address"),
            constants.STRING_TYPE
        ),
        createField(
            "phone",
            t("lodgings.fields.phone"),
            constants.STRING_TYPE
        ),
        createField(
            "email",
            t("lodgings.fields.email"),
            constants.STRING_TYPE
        ),
        createField(
            "web",
            t("lodgings.fields.web"),
            constants.STRING_TYPE
        )
    ]),
    createDataSetFacet(t("lodgings.view.facet_location"), [
        createField(
            "zone.name",
            t("lodgings.fields.zone"),
            constants.STRING_TYPE
        ),
        createCallbackField(
            "area_components",
            t("lodgings.fields.locality"),
            constants.STRING_TYPE,
            (value) => {
                const areaComponent = value.find(component => component.cod === "LO");
                if (areaComponent) {
                    return areaComponent.name;
                }

                return "";
            }
        ),
        createField(
            "coordinates.lat",
            t("lodgings.fields.latitude"),
            constants.STRING_TYPE
        ),
        createField(
            "coordinates.lng",
            t("lodgings.fields.longitude"),
            constants.STRING_TYPE
        ),
        createCallbackField(
            "coordinates",
            t("lodgings.fields.gmap"),
            constants.TEXT_TYPE,
            (value) =>
                <Link href={`https://www.google.es/maps?z=10&q=loc:${value.lat},${value.lng}`}
                      target="_blank">
                    {t("common.actions.view.map_link")}
                </Link>,
        ),
    ])
];
