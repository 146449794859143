import { Box } from "@mui/material"
import { Field } from "formik"
import { TextField } from "formik-mui"
import { useTranslation } from "react-i18next"
import { validate } from "./utils"

const StringFormField = ({ field, value }) => {
    const { t } = useTranslation("vbms")

    const inputProps = {
        size: "small",
        multiline: field.rows !== undefined,
        rows: field.rows ? field.rows : 1,
    }

    if (field?.max_length) {
        inputProps.maxLength = field.max_length
    }
    
    return (
        <Box>
            <Field
                className="textField"
                component={TextField}
                name={field.id}
                label={field.label}
                required={field.required}
                validate={(value) => validate(field, t, value)}
                InputProps={inputProps}
                InputLabelProps={{
                    shrink: true,
                }}
                autoFocus={field.autofocus}
            />
        </Box>
    )
}

export default StringFormField
