import { useTranslation } from "react-i18next"
import constants from "../../../components/entity/constants"
import { useVolcanoApiClient } from "../../../context/VolcanoApiClientProvider"
import { useNavigate, useParams } from "react-router-dom"
import { createCallbackField } from "../../../components/helpers/fieldHelper"
import AsyncEntityPage from "../../../layout/templates/AsyncContentPage/AsyncEntityPage"
import { getActions } from './NotificationPage.actions';
import { getHeaderContent } from './NotificationPage.header';
import { getTabNavigationItems } from "./NotificationPage.tabs"
import { useVolcanoAuth } from "../../../context/VolcanoAuthContext"
import useSnackBars from "../../../components/Snackbar/snack-bar.context"

const NotificationPage = (props) => {
    const { t } = useTranslation();
    const { entityId } = useParams()
    const { apiClient } = useVolcanoApiClient()
    const volcanoAuth = useVolcanoAuth()
    const navigate = useNavigate()
    const { addAlert } = useSnackBars()

    const fetcher = () => apiClient.notifications.getNotification(entityId)

    return (
        <AsyncEntityPage
            fetcher={fetcher}
            title={createCallbackField(
                "name",
                t("notification.view.title"),
                constants.STRING_TYPE,
                (value) => {
                    return t("notifications.view.title", { name: value });
                }
            )}
            headerContent={getHeaderContent(t, volcanoAuth.user)}
            actions={getActions(volcanoAuth.user, apiClient, entityId, t, navigate, addAlert)}
            tabNavigationItems={getTabNavigationItems(t, apiClient, volcanoAuth, navigate)}
        />
    ); 
};

export default NotificationPage;