import { Box, TableCell, FormHelperText } from '@mui/material';
import Text from "../../../../components/Display/Text"
import { getRateName } from "./BookingRates.functions"
import { formatPrice, formatCurrencyCode } from '../../../../lib/utils';
import { useState } from "react"
import _get from 'lodash/get'
import CurrencyField from '../../../../components/form/CurrencyField';

const parseRateAmount = (amount) => {
    let nAmount = amount.endsWith(",") ? amount.replace(",", ".") : parseFloat(amount.replace(",", ".")) // parse to float

    nAmount = isNaN(nAmount) && amount.length > 0 && !amount.endsWith(".") ? "" : nAmount // remove other chars than decimal separator

    return isNaN(nAmount) || amount.endsWith(".") ? amount : nAmount
}

const AmountTextField = ({ id, value, onChange, i18n, currency, mainCurrency }) => {
    return (
        <Box display="inline-flex">
            <CurrencyField
                id={id.toString()}
                name={id.toString()}
                value={value}
                InputProps={{ size: "small", sx: { marginTop: "-2px", paddingY: "0px" } }}
                onChange={(e) => onChange(e.target.value)}
                InputLabelProps={{ shrink: true }}
                sx={{ paddingTop: 0 }}
            />
            <Box sx={{ marginLeft: 1, marginY: "6px" }}>{formatCurrencyCode(i18n, currency, mainCurrency)}</Box>
        </Box>
    )
}

const BookingRatesBasicData = (props) => {
    const {
        rate,
        allowEditAmount,
        hideBasePrice,
        hideNetPrice,
        hideRefundCost,
        i18n,
        unitPrice,
        t,
        currency,
        mainCurrency,
        costExchangeRate,
        exchangeRate,
        onChange
    } = props

    const { pvp, neto, collaborator_pvd: pvd } = rate
    const refundCost = _get(rate, "refund_cost.value", null)
    const [value, setValue] = useState(unitPrice)

    const handleOnChange = (newAmount) => {
        if (newAmount !== value) {
            newAmount = parseRateAmount(newAmount)

            setValue(newAmount)
            onChange(newAmount)
        }
    }

    return (
        <>
            <TableCell align="left">
                <Text>{getRateName(rate)}</Text>
                {!hideRefundCost && refundCost !== null && <FormHelperText>
                    {t("bookings.rates_edit_form.cost.message", { refund_cost: formatPrice(i18n, currency, refundCost, mainCurrency, costExchangeRate) })}
                </FormHelperText>}
            </TableCell>
            <TableCell align="right">
                {(allowEditAmount && hideNetPrice) && <AmountTextField
                    id={rate.id}
                    value={unitPrice}
                    i18n={i18n}
                    currency={currency}
                    mainCurrency={mainCurrency}
                    onChange={handleOnChange}
                />}
                {!(allowEditAmount && hideNetPrice) && <Text>{formatPrice(i18n, currency, pvp, mainCurrency, exchangeRate)}</Text>}
            </TableCell>
            {!hideNetPrice && <TableCell align="right">
                {allowEditAmount && <AmountTextField
                    id={rate.id}
                    value={unitPrice}
                    i18n={i18n}
                    currency={currency}
                    mainCurrency={mainCurrency}
                    onChange={handleOnChange}
                />}
                {!allowEditAmount && <Text>{formatPrice(i18n, currency, neto, mainCurrency, exchangeRate)}</Text>}
            </TableCell>
            }
            {!hideBasePrice && <TableCell align="right"><Text>{formatPrice(i18n, currency, pvd, mainCurrency, exchangeRate)}</Text></TableCell>}
        </>
    )
}

export default BookingRatesBasicData