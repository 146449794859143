import {Box, IconButton, Stack} from "@mui/material"
import constants from "../../../../components/entity/constants"
import {
    createCallbackField,
    createField,
    createLanguagesMapping, createLinkField
} from '../../../../components/helpers/fieldHelper'

import {trimWithEllipsis} from "../../../../lib/utils"


export const getTableColumns = (t, user, actions) => {


    return [
        createField(
            "managed",
            t("inbox.fields.managed"),
            constants.BOOLEAN_TYPE,
            null
        ),
        createField(
            "order.pickup.pickup_time",
            t("inbox.fields.pickup_time"),
            constants.STRING_TYPE,
            null
        ),
        createField(
            "order.pickup.name",
            t("inbox.fields.pickup_point"),
            constants.STRING_TYPE,
            null
        ),
        createCallbackField(
            "product_rates",
            t("bookings.fields.pax"),
            constants.NUMERIC_TYPE,
            (value) => (value || []).reduce((acc, rate) => acc + rate.qty, 0)
        ),
        createField(
            "order.language",
            t("common.language"),
            constants.STRING_TYPE,
            createLanguagesMapping(t)
        ),
        createLinkField(
            createCallbackField(
                "locator",
                t("bookings.fields.locator"),
                constants.TEXT_TYPE,
                (value, entity) => {
                    if (!entity.order.voucher_id) return value
                    return (
                        <Stack gap="0.1em">
                            <Box>{value}</Box>
                            <Box>({entity.order.voucher_id})</Box>
                        </Stack>
                    )
                },
                null,
                null,
                "locator"
            ),
            {
                route: "bookings",
                field: "id",
            }
        ),
        createField(
            "order.lodgin.name",
            t("inbox.fields.lodging"),
            constants.STRING_TYPE,
            null
        ),
        createField(
            "booking_date",
            t("inbox.fields.booking_date"),
            constants.DATETIME_TYPE,
            null,
            null,
            null,
            "booking_date"
        ),
        createCallbackField(
            "order.customer",
            t("inbox.fields.customer"),
            constants.STRING_TYPE,
            (value) => {
                const limit = 16  // 16 characters
                const name = `${value.first_name} ${value.last_name}`

                return (
                    <Stack>
                        <Box>{trimWithEllipsis(name, limit)}</Box>
                    </Stack>
                )
            }
        ),
        createCallbackField(
            "order.customer",
            t("inbox.fields.contact"),
            constants.STRING_TYPE,
            (value) => {
                const limit = 30

                return (
                    <Stack>
                        <Box>{value.phone}</Box>
                        <Box>{trimWithEllipsis(value.email, limit)}</Box>
                    </Stack>
                )
            }
        ),
        createField(
            "product.sku",
            t("inbox.fields.alias"),
            constants.STRING_TYPE,
            null
        ),
        createCallbackField(
            "id",
            t("inbox.fields.block_action"),
            constants.STRING_TYPE,
            (value, entity) => {
                const availableActions = actions.filter(action => action.condition(entity) && action.scope === 'block_action')

                return (
                    <Stack gap="0.1em">
                        <Box>
                            {availableActions.map((action) => (
                                <IconButton
                                    key={action.id}
                                    size="small"
                                    onClick={() => action.onExecute(value, entity)}
                                    aria-label={action.title}
                                >
                                    {action.icon}
                                </IconButton>
                            ))}
                        </Box>
                        {entity.locked_by !== false && (
                            <Box>{entity.locked_by}</Box>
                        )}
                    </Stack>
                )
            }
        ),
        createCallbackField(
            "id",
            t("inbox.fields.managed_action"),
            constants.STRING_TYPE,
            (value, entity) => {
                const availableActions = actions.filter(action => action.condition(entity) && action.scope === 'managed_action')

                return (
                    <Stack direction="row" spacing={1}>
                        {availableActions.map((action) => (
                            <IconButton
                                key={action.id}
                                size="small"
                                onClick={() => action.onExecute(value, entity)}
                                aria-label={action.title}
                            >
                                {action.icon}
                            </IconButton>
                        ))}
                    </Stack>
                )
            }
        )
    ]
}

