import availableRoutes from "../../../routes/availableRoutes"
import { Edit, Delete } from "@mui/icons-material"
import _has from 'lodash/has'

export const getActions = (apiClient, t, itemProps, navigate, entityId = null) => {
    const { scope, icon, reloadOnSuccess, showLoading } = { ...itemProps }
    return [
        {
            id: "edit",
            title: t("common.edit"),
            scope: scope,
            icon: icon && Edit,
            reloadOnSuccess: reloadOnSuccess,
            showLoading: showLoading,
            onExecute: (tag) => {
                return Promise.resolve(tag)
                    .then((tag) => {
                        navigate(availableRoutes.tags_edit.path.replace(":entityId", entityId ?? tag.id))
                    })
            },
            condition: (tag) => _has(tag.getActions(), "edit")
        },
        {
            id: "delete",
            title: t("common.delete"),
            scope: scope,
            icon: icon && Delete,
            reloadOnSuccess: reloadOnSuccess,
            showLoading: showLoading,
            confirmDialog: {
                title: t("tags.actions.delete.modal_title"),
                content: (tag) =>
                    t("tags.actions.delete.modal_content", {
                        name: tag.slug,
                    }),
            },
            onExecute: (tag, progressHandler, onSuccess, onError) => {
                return apiClient.content.tag.deleteTag(tag.id)
                    .then((result) => {
                        onSuccess(
                            t("tags.actions.delete.confirm", {
                                name: tag.slug,
                            })
                        )
                        navigate(availableRoutes.tags.path)
                    })
                    .catch((error) => {
                        onError(
                            t("tags.actions.delete.error", {
                                name: tag.slug,
                            })
                        )

                        throw error
                    })
            },
            condition: (tag) => _has(tag.getActions(), "delete")
        }
    ]
}