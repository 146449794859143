import { useTranslation } from "react-i18next"
import constants from "../../../components/entity/constants"
import { useVolcanoApiClient } from "../../../context/VolcanoApiClientProvider"
import { useParams } from 'react-router-dom'
import AsyncEntityPage from "../../../layout/templates/AsyncContentPage/AsyncEntityPage"
import { createCallbackField } from "../../../components/helpers/fieldHelper"
import { getHeaderContent } from "./LiquidationPage.header.js"
import _padStart from "lodash/padStart"
import LiquidationLines from "./components/tabs/LiquidationLines"
//import { downloadFile } from "../../../lib/utils"
import { getShareActions } from "./LiquidationPage.shareActions"
import { useVolcanoAuth } from '../../../context/VolcanoAuthContext';

const LiquidationPage = (props) => {
    const { t } = useTranslation()
    const { entityId } = useParams()
    const { apiClient } = useVolcanoApiClient()
    const volcanoAuth = useVolcanoAuth()

    const fetcher = () => apiClient.liquidation
        .getLiquidation(entityId)
        .then((liquidation) => {
            liquidation.title = `${liquidation.year}${_padStart(liquidation.number, 5, 0)}`
            return liquidation
        })

/*    const actions = [
        {
            title: t("common.download"),
            showLoading: true,
            onExecute: (liquidation, progressHandler, onSuccess, onError) => {
                return apiClient.liquidation
                    .getLiquidationPdf(liquidation.id)
                    .then(pdf => {
                        downloadFile(pdf, "liquidation_" + liquidation.id + ".pdf")
                    })
                    .finally(() => true)
            },
        }
        ,
        {
            id: "link_pdf",
            title: t("common.actions.share.options.link_pdf"),
            onExecute: (liquidation, progressHandler, onSuccess, onError) => {
                const data = {
                    resource: "liquidations",
                    operation: "liquidation_pdf",
                    id: liquidation.id,
                }
                console.log(data)
                return apiClient.download.createLink(data)
                    .then((link) => {
                        navigator.clipboard.writeText(link.url);
                        onSuccess(t("common.actions.share.confirm"))
                    })
                    .catch((error) => {
                        onError(t("common.actions.share.error"))
                    })
            },
            //condition: (entity) => entity.state === 'valid' || entity.state === 'refund_requested'
        },
    ]*/

    return (
        <AsyncEntityPage
            fetcher={fetcher}
            title={createCallbackField(
                "title",
                t("liquidations.fields.title"),
                constants.STRING_TYPE,
                (value) => t("liquidations.view.title", { id: value })
            )}
            headerContent={getHeaderContent(t)}
            content={LiquidationLines}
            //actions={actions}
            shareActions={getShareActions(volcanoAuth.user, apiClient, entityId, t)}
        />
    );
};

export default LiquidationPage;