import OfficesTable from "../../../../../components/Volcano/Offices/OfficesTable";

const IntermediaryOffices = ({ entity }) => {

    if (!entity) {
        return null;
    }

    return (
        <OfficesTable defaultParams={{ crm_intermediary_id: entity.id }} />
    )
}

export default IntermediaryOffices