import { createExportField } from "../../../components/helpers/fieldHelper";

export const getTableExport = (apiClient, t) => {
    return (filter, progressHandler) => {
        apiClient.content.sites.exportSites(
            [
                createExportField("id", "Id"),
                createExportField("name", t("sites.fields.name")),
                createExportField("base_url", t("sites.fields.base_url")),
                createExportField(
                    "is_admin",
                    t("sites.fields.is_admin"),
                    null,
                    (site) => site.is_admin ? t("common.yes") : t("common.no")
                ),
                createExportField("created", t("sites.fields.created")),
                createExportField("modified", t("sites.fields.modified")),
            ],
            filter,
            progressHandler
        )
    }
} 