import { useState, useCallback } from "react";
import { useTranslation } from "react-i18next"
import { useVolcanoApiClient } from "../../context/VolcanoApiClientProvider"
import { useNavigate } from "react-router-dom"
import { useVolcanoAuth } from "../../context/VolcanoAuthContext";
import { createCallbackField, createField, createFormFieldGroup, createFormFieldset, createFormField } from '../../components/helpers/fieldHelper'
import AsyncEntityFormPage from "../../layout/templates/AsyncContentPage/AsyncEntityFormPage"
import availableRoutes from "../../routes/availableRoutes";
import Loading from "../../components/Display/Loading"
import constants from "../../components/entity/constants"
import useSnackBars from "../../components/Snackbar/snack-bar.context"

const EnterpriseFormPage = () => {
    const navigate = useNavigate()
    const { t } = useTranslation("vbms")
    const { apiClient } = useVolcanoApiClient()
    const { addAlert } = useSnackBars()
    const volcanoAuth = useVolcanoAuth()
    const entityId = volcanoAuth.user.corporate_account.enterprise.id

    const [isReady, setIsReady] = useState(false)

    const fetcher = useCallback(() => {
        setIsReady(false)

        return apiClient.enterprise.getEnterprise(entityId).then((enterprise) => {
            setIsReady(true)
            return enterprise
        })
    }, [apiClient, entityId])

    const onSaveHandler = (data) => {
        setIsReady(false)
        let enterprise = data

        if (entityId) {
            apiClient.enterprise.editEnterprise(entityId, enterprise)
                .then(() => {
                    navigate(availableRoutes.enterprise.path)
                    addAlert(t("enterprise.actions.edit.confirm"))
                })
                .catch((error) => {
                    setIsReady(true)
                    addAlert("Error: " + error.message)
                })
        }
    }

    const formContent = () => [
        createFormFieldset(t("enterprise.form.fieldset_information"), [
            createFormField(
                createField(
                    "name",
                    t("enterprise.fields.name"),
                    constants.STRING_TYPE
                ),
                true,
                null,
                false,
                true
            ),
            createFormField(
                createField(
                    "cif",
                    t("enterprise.fields.cif"),
                    constants.STRING_TYPE
                ),
                false,
                null,
                false,
                true
            ),
            createFormField(
                createField(
                    "address",
                    t("enterprise.fields.address"),
                    constants.STRING_TYPE
                ),
                false,
                null,
                false,
                true
            ),
            createFormFieldGroup([
                createFormField(
                    createField(
                        "cp",
                        t("enterprise.fields.cp"),
                        constants.STRING_TYPE
                    ),
                    false,
                    null,
                    false,
                    true
                ),
                createFormField(
                    createField(
                        "city",
                        t("enterprise.fields.city"),
                        constants.STRING_TYPE
                    ),
                    false,
                    null,
                    false,
                    true
                ),
            ]),
            createFormFieldGroup([
                createFormField(
                    createField(
                        "phone",
                        t("enterprise.fields.phone"),
                        constants.STRING_TYPE
                    ),
                    false,
                    null,
                    false,
                    true
                ),
                createFormField(
                    createField(
                        "phone_other",
                        t("enterprise.fields.phone_other"),
                        constants.STRING_TYPE
                    ),
                    false,
                    null,
                    false,
                    true
                ),
                createFormField(
                    createField(
                        "fax",
                        t("enterprise.fields.fax"),
                        constants.STRING_TYPE
                    ),
                    false,
                    null,
                    false,
                    true
                ),
                createFormField(
                    createField(
                        "web",
                        t("enterprise.fields.web"),
                        constants.STRING_TYPE
                    ),
                    false,
                    null,
                    false,
                    true
                )
            ])
        ])
    ]

    return (
        <>
            <Loading open={!isReady} />
            <AsyncEntityFormPage
                fetcher={fetcher}
                title={
                    createCallbackField(
                        "name",
                        t("enterprise.form.title"),
                        constants.STRING_TYPE,
                        (value) => t("enterprise.form.title", {name: value})
                    )
                }
                subtitle={
                    createCallbackField(
                        "",
                        t("enterprise.form.edit_title"),
                        constants.STRING_TYPE,
                    )
                }
                onSave={onSaveHandler}
                formContent={formContent()}
            />
        </>

    )
}

export default EnterpriseFormPage