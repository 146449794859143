import { useRef, useState } from "react"
import { useTranslation } from "react-i18next"
import { Typography, Button, CardActions, CardContent } from "@mui/material"
import { createFormFieldset, createSelectFormField, createSuggestionTypeMapping, createTextAreaFormField, createFormField } from "../helpers/fieldHelper"
import useSnackBars from "../Snackbar/snack-bar.context"
import FormikEntityForm from "../form/FormikEntityForm"
import LoadingButton from "../form/LoadingButton"
import GoogleRecaptcha from "../Volcano/Captcha/GoogleRecaptcha"

const SuggestionForm = ({ title, loading, onSubmit }) => {

    const { t } = useTranslation("vbms")
    const [values, setValues] = useState({})
    const reCaptchaRef = useRef(null)
    const { addAlert } = useSnackBars()

    const onChange = (values) => {
        setValues(values)
    }

    const formContent = [
        createFormFieldset("", [
            createFormField(
                createSelectFormField(
                    "suggestion_type",
                    t("suggestions.fields.suggestion_type"),
                    Object.entries(createSuggestionTypeMapping(t)).map(([key, value]) => ({ value: key, label: value }))
                ),
                true
            ),
            createFormField(
                createTextAreaFormField(
                    "content",
                    t("suggestions.fields.content"),
                    10
                ),
                true
            )
        ]),
    ]

    const onSubmitCaptcha = (values) => {
        reCaptchaRef.current.executeAsync()
            .then(() => onSubmit(values))
            .catch((error) => {
                addAlert(`Error [${error?.type}]: ${error?.message}`, "error")
            })
    }


    return (<>
        <CardContent>
            <Typography variant="h4" component="h1" align="center">{title}</Typography>
            <FormikEntityForm
                entity={values}
                onChange={onChange}
                formContent={formContent}
                variant="dialog"
            />
            <GoogleRecaptcha
                ref={reCaptchaRef}
            />
        </CardContent>
        <CardActions sx={{ justifyContent: "space-between", px: '1em' }}>
            <Button
                href="/login"
                variant="text"
                size="small">
                {t("common.back")}
            </Button>
            <LoadingButton
                type="submit"
                variant="contained"
                color="primary"
                loading={loading}
                onClick={() => onSubmitCaptcha(values)}
            >
                {t("common.submit")}
            </LoadingButton>
        </CardActions>
    </>
    )
}

export default SuggestionForm