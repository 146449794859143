import { Box, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';

const BookingRatesTable = (props) => {
    const {
        rates,
        withParticipants,
        maxQtyAttempted,
        allowEditParticipantsData,
        allowEditQty,
        allowEditAmount,
        hideNetPrice,
        hideBasePrice,
        hideRefundCost,
        disableExpandIcon,
        RateRowComponent,
        t,
        currency,
        mainCurrency,
        exchangeRate,
        costExchangeRate,
        onChange
    } = props

    return (
        <TableContainer component={Box} sx={{ paddingLeft: 2 }}>
            <Table
                size="small"
                sx={{
                    '& td, & th': { border: 0 },
                    '& td:last-child, & th:last-child': { pr: 0 },
                    '& td:first-of-type, & th:first-of-type': { pl: 0 },
                }}
            >
                <TableHead>
                    <TableRow>
                        {withParticipants && <TableCell sx={{ width: '32px', padding: 0 }} />}
                        <TableCell align="left">{t("bookings.fields.product_rates.name")}</TableCell>
                        <TableCell align="right">{t("bookings.fields.product_rates.pvp")}</TableCell>
                        {!hideNetPrice && <TableCell align="right">{t("bookings.fields.product_rates.neto")}</TableCell>}
                        {!hideBasePrice && <TableCell align="right">{t("bookings.fields.product_rates.pvd")}</TableCell>}
                        <TableCell align="center">{t("bookings.fields.product_rates.quantity")}</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {rates.map((rate, index) => (
                        <RateRowComponent
                            index={index}
                            key={rate.id}
                            rate={rate}
                            maxQtyAttempted={maxQtyAttempted}
                            allowEditParticipantsData={allowEditParticipantsData}
                            allowEditQty={allowEditQty}
                            allowEditAmount={allowEditAmount}
                            hideNetPrice={hideNetPrice}
                            hideBasePrice={hideBasePrice}
                            hideRefundCost={hideRefundCost}
                            enableExpandColumn={disableExpandIcon}
                            currency={currency}
                            mainCurrency={mainCurrency}
                            exchangeRate={exchangeRate}
                            costExchangeRate={costExchangeRate}
                            onChange={onChange}
                        />)
                    )}
                </TableBody>
            </Table>
        </TableContainer >
    )
}

export default BookingRatesTable