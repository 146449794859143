import { Box, Button, Grid, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material"
import { useCallback, useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import DataWrapper from "../../../Display/DataWrapper"
import Text from "../../../Display/Text"
import _isEmpty from "lodash/isEmpty"
import _get from "lodash/get"
import _isFunction from "lodash/isFunction"
import Counter from "../../../Counter/Counter"
import { getCurrencyLocale } from '../../../../lib/utils';
import { CUSTOMER_TYPE_IDS, TELEFERICO_PRODUCTS_AGENCIAS } from '../../../../pages/Bookings/BookingsCollection/utils';
import { useVolcanoAuth } from "../../../../context/VolcanoAuthContext"
import userPermissions from "../../../../lib/user-permissions"


const getRateAgeRestriction = (rate) => {
    const ct = rate.customer_types.find((ct) => ct.group_id === 5)
    return ct.age_restriction || {}
}

const getRateQtyRestriction = (rate, minNotRestricted) => {
    const min = minNotRestricted ? 0 : rate.qty_restrictions.min
    const max = rate.qty_restrictions.max

    return {
        min: isNaN(min) || min === null ? 0 : min,
        max: isNaN(max) || max === null ? Number.MAX_SAFE_INTEGER : max,
    }
}

const isLastRate = (rates, index) => rates.length - 1 === index;

/**
 * Formats the quantity to be displayed in the UI
 *  
 * @param {number} qty 
 * @returns {string}
 */
const formatQty = (qty) => {
    if (qty <= 0) {
        return "0"
    }

    if (qty > 99) {
        return "99+"
    }

    return qty.toString()
}

// convert rates object to array
const ratesToArray = (rates) => Object.entries(rates).map(([id, rate]) => rate)

const getMaxAvailableQty = (maxQty, selection) => {
    maxQty = (isNaN(maxQty) || maxQty === -1) ? Number.MAX_SAFE_INTEGER : maxQty
    return maxQty - ratesToArray(selection).reduce((acc, rate) => acc + rate.qty, 0)
}

const isMaxQtyLimit = (rate, totalMaxQty, selection) => {
    if (getMaxAvailableQty(totalMaxQty, selection) <= 0) {
        return true
    }

    if (rate.qty_restrictions.max && rate.qty_restrictions.max <= selection[rate.id].qty) {
        return true
    }

    return false
}

/**
 * Checks if the rates selection is ready to continue with the booking process
 * @param {object} selection 
 * @param {array} rates 
 * @param {number} maxQty 
 * @returns 
 */
const checkIsReady = (product, selection, rates, minQty, maxQty, minNotRestricted) => {
    if (_isEmpty(selection) || _isEmpty(rates)) {
        return false
    }
    
    // only check rates that do not belongs to a guide (customer type id 10) 
    // todo: remove this logic from here and move it to the API
    const validRates = (product === TELEFERICO_PRODUCTS_AGENCIAS.SUDIBA_BAJADA_AGENCIAS ? rates.filter((rate) => rate.customer_types.every((ct) => ct.id !== CUSTOMER_TYPE_IDS.GUIDES)) : rates)
    
    // cheack if at least one rate is selected
    if (validRates.every((rate) => selection[rate.id].qty === 0)) {
        return false
    }

    // check if min qty is respected (sum of all rates)
    if (validRates.reduce((acc, rate) => acc + selection[rate.id].qty, 0) < minQty) {
        return false
    }

    // check if max qty is respected
    if (getMaxAvailableQty(maxQty, selection) < 0) {
        return false
    }

    // check if all rates restrictions are respected
    return rates.every((rate) => {
        if (!minNotRestricted && rate.qty_restrictions.min > selection[rate.id].qty) {
            return false
        }

        if (rate.qty_restrictions.max && rate.qty_restrictions.max < selection[rate.id].qty) {
            return false
        }

        return true
    })
}

const RateRow = ({
    rate,
    defaultValue,
    isLastRate,
    disabledMin,
    disabledMax,
    onChange,
}) => {
    const [value, setValue] = useState(null)
    const { t, i18n } = useTranslation("vbms")
    const { id, pvp: unitPrice, currency } = rate
    const ageRestriction = getRateAgeRestriction(rate)
    const qtyRestriction = getRateQtyRestriction(rate, disabledMin)

    useEffect(() => {
        if (value === null) {
            return
        }

        onChange(id, value)
    }, [id, defaultValue, value, onChange])

    const priceFormatter = new Intl.NumberFormat(getCurrencyLocale(i18n.language), {
        style: "currency",
        currency: currency || "eur",
    })

    const onRateChangeHandler = (newValue) => {
        setValue(newValue)
    }

    return (
        <TableRow
            sx={{
                "&:nth-of-type(odd)": {
                    backgroundColor: "rgba(0, 0, 0, 0.04)",
                },
            }}
        >
            <TableCell align="left"><Text>{rate.name}</Text></TableCell>
            <TableCell align="right"><Text>{priceFormatter.format(unitPrice)}</Text></TableCell>
            <TableCell align="center">
                <Stack
                    direction="column"
                    justifyContent="center"
                    alignItems="center"
                >
                    <Box>
                        <Counter
                            defaultValue={defaultValue}
                            disabledMin={disabledMin}
                            disabledMax={disabledMax}
                            min={qtyRestriction.min}
                            max={qtyRestriction.max}
                            onChange={onRateChangeHandler}
                            allowEdit={true}
                        />
                    </Box>
                    <Box>
                        {_get(ageRestriction, "min", -1) >= 0 && (
                            <Text variant="body2">
                                {t("booking_widget.steps.rates.age_restriction", {
                                    from: ageRestriction.min,
                                    to: ageRestriction.max,
                                })}
                            </Text>
                        )}
                        {isLastRate && _get(ageRestriction, "min", -1) > 0 && (
                            <Text variant="body2">
                                {t("booking_widget.steps.rates.age_minimum", {
                                    age: ageRestriction.min,
                                })}
                            </Text>
                        )}
                    </Box>
                </Stack>
            </TableCell>
        </TableRow>
    )
}

const RatesTable = ({ rates, selection, max, groupId, visibleGroupValue, disabled, onChange, minNotRestricted }) => {
    const { t } = useTranslation("vbms")
    const [groupedRates, setGroupedRates] = useState({})

    useEffect(() => {
        if (_isEmpty(rates)) {
            return
        }

        let processedRates = rates.reduce((acc, rate) => {
            const ct = rate.customer_types.find((ct) => ct.group_id === groupId)
            if (!ct) {
                return acc
            }

            if (!(ct.id in acc)) {
                acc[ct.id] = []
            }

            acc[ct.id].push(rate)

            return acc
        }, {})

        if (_isEmpty(processedRates)) {
            // if no rates are found for the group, return the rates as they are
            processedRates = { [groupId]: rates }
        }

        let orderedGroupedRates = []
        Object.keys(processedRates)
            .sort((a, b) => {
                return parseInt(a) === visibleGroupValue ? -1 : 0
            })
            .forEach((ctId) => {
                orderedGroupedRates.push(processedRates[ctId])
            })

        setGroupedRates(orderedGroupedRates)
    }, [rates, groupId, visibleGroupValue])

    if (_isEmpty(selection)) {
        return null
    }

    return (
        <TableContainer component={Box}>
            <Table
                size="small"
                sx={{
                    '& td, & th': { border: 0, paddingX: { xs: "5px", md: "inherit" } },
                    '& td:last-child, & th:last-child': { pr: 0 },
                    '& td:first-of-type, & th:first-of-type': { pl: 0 },
                }}
            >
                <TableHead>
                    <TableRow>
                        <TableCell align="left">{t("booking_widget.steps.rates.rate_name")}</TableCell>
                        <TableCell align="right">{t("booking_widget.steps.rates.rate_price")}</TableCell>
                        <TableCell align="center">{t("booking_widget.steps.rates.rate_qty")}</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {groupedRates
                        .map((rates) => {
                            return rates.map((rate, index) => (
                                <RateRow
                                    key={rate.id}
                                    rate={rate}
                                    isLastRate={isLastRate(rates, index)}
                                    defaultValue={selection[rate.id].qty}
                                    disabledMin={disabled || minNotRestricted}
                                    disabledMax={disabled || isMaxQtyLimit(rate, max, selection)}
                                    onChange={onChange}
                                />)
                            )
                        })
                        .flat()}
                </TableBody>
            </Table>
        </TableContainer >
    )
}

const RatesSelector = (props) => {
    const { t } = useTranslation()
    const {
        product,
        date,
        collaborator,
        ratesFetcher,
        max,
        min,
        disabled,
        onChange,
        onConfirm,
    } = props

    const [selection, setSelection] = useState({})
    const [rates, setRates] = useState([])
    const volcanoAuth = useVolcanoAuth()
    const [maxAvailableQty, setMaxAvailableQty] = useState(0)
    const [isReady, setIsReady] = useState(false)
    const minNotRestricted = volcanoAuth.user.hasPermission(userPermissions.PERM_PROD_AVAILABILITY_IGNORE_RESTRICTIONS)

    useEffect(() => {
        ratesFetcher(product, date, collaborator).then((rates) => setRates(rates))
    }, [product, date, collaborator, ratesFetcher])

    useEffect(() => {
        setSelection(
            rates.reduce((obj, rate, index) => {
                return {
                    ...obj,
                    [rate.id]: {
                        id: rate.id,
                        qty: Math.max(index === 0 ? min : 0, getRateQtyRestriction(rate, minNotRestricted).min),
                    }
                }
            }, {})
        )
    }, [rates, min])

    useEffect(() => {
        if (_isEmpty(selection)) {
            return
        }

        setMaxAvailableQty(getMaxAvailableQty(max, selection))
        const ratesSelectionValid = checkIsReady(product, selection, rates, min, max)
        setIsReady(ratesSelectionValid)

        const selectedRates = ratesToArray(selection)
            .filter((rate) => rate.qty > 0)
            .map((rate) => {
                // find related rate
                const relatedRate = rates.find((r) => r.id === rate.id)
                return {
                    ...relatedRate,
                    qty: rate.qty,
                }
            })
        onChange(selectedRates, ratesSelectionValid)
    }, [rates, selection, max, onChange])

    const onRateChangeHandler = useCallback((rateId, qty) => {
        if (_isEmpty(selection) || selection[rateId].qty === qty) {
            return
        }

        setSelection((selection) => ({
            ...selection,
            [rateId]: {
                id: rateId,
                qty: qty,
            }
        }))
    }, [selection])

    const subtitle = []

    if (!_isEmpty(selection)) {
        subtitle.push(t("booking_widget.steps.rates.tickets_available", { qty: formatQty(maxAvailableQty) }))
    }

    if (min > 1) {
        subtitle.push(t("booking_widget.steps.rates.tickets_min", { qty: min }))
    }

    return (
        <Box
            display="flex"
            flexDirection="column"
            sx={{
                "& .MuiChip-root": {
                    mb: 2,
                },
                "& .RatesHeader-root .MuiTypography-root": {
                    fontWeight: 700,
                }
            }}
        >
            <DataWrapper
                title={t("booking_widget.steps.rates.card_rates_title")}
                subtitle={subtitle}
            >
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <RatesTable
                            rates={rates}
                            selection={selection}
                            groupId={6}
                            visibleGroupValue={19}
                            max={max}
                            disabled={disabled}
                            onChange={onRateChangeHandler}
                            minNotRestricted={minNotRestricted}
                        />
                    </Grid>
                    {isReady && _isFunction(onConfirm) && (<Grid item xs={12}>
                        <Box display="flex" justifyContent="center">
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={() => onConfirm()}
                            >
                                {t("booking_widget.summary.continue")}
                            </Button>
                        </Box>
                    </Grid>
                    )}
                </Grid>
            </DataWrapper>
        </Box >
    )
}

export default RatesSelector
