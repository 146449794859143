import { useTranslation } from "react-i18next"
import AsyncEntityForm from "../../../../../components/form/AsyncEntityForm"
import { createFormFieldset, createSelectFormField } from '../../../../../components/helpers/fieldHelper'

const BookingManaged = ({ selection, onAction, onCancel }) => {

    const { t } = useTranslation("vbms")

    const fetcher = () => Promise.resolve(selection)

    const formContent = [
        createFormFieldset(null, [
            createSelectFormField(
                "managed",
                t("bookings.fields.managed"),
                [
                    {
                        value: true,
                        label: t("bookings.actions.managed.managed_yes"),
                    },
                    {
                        value: false,
                        label: t("bookings.actions.managed.managed_no"),
                    }
                ]
            ),
        ])
    ]

    const onConfirm = (result) => {

        const managed = result.managed === "true"

        onAction({
            id: selection.id,
            locator: selection.locator,
            managed: managed
        })
    }

    return (
        <AsyncEntityForm
            fetcher={fetcher}
            onSave={onConfirm}
            formContent={formContent}
        />
    )
}

export default BookingManaged
