import {createTabBarItem} from "../../../../components/helpers/fieldHelper";
import ActivityBookings from "./components/tabs/ActivityBookings";

export const getTabNavigationItems = (user, apiClient, t) => [
    createTabBarItem(
        t("activities.view.navbar.activity_bookings"),
        ({ entity }) => (
            <ActivityBookings
                activity={entity}
            />
        ),
         null,
        (entity) => entity
    ),
];
