import { Outlet } from 'react-router-dom';
import { Box, Grid, Card, CardMedia } from '@mui/material';
import { useVolcanoTheme } from '../../context/VolcanoThemeProvider';

const styles = {
    page: (backgroundImage) => {
        return {
            position: 'absolute',
            width: '100%',
            height: '100%',
            top: 0,
            bottom: 0,
            left: 0,
            zIndex: 0,
            background: 'url(' + backgroundImage + ') #1D2024 no-repeat 50% 50%',
            backgroundAttachment: 'fixed',
            backgroundSize: 'cover',
        }
    },
    page_header: {
        paddingTop: {
            xs: 4,
            sm: 16
        }
    },
    page_content: {
        position: 'relative',
        marginLeft: 2,
        marginRight: 2
    },
    card_container: (backgroundImage) => {
        return {
            maxWidth: 468,
            '&::before': {
                content: '""',
                position: 'absolute',
                top: '-10px',
                left: '-10px',
                right: '-10px',
                bottom: '-10px',
                background: 'url(' + backgroundImage + ') #1D2024 no-repeat 50% 50%',
                backgroundAttachment: 'fixed',
                backgroundSize: 'cover',
                filter: 'blur(12px)',
                zIndex: 0
            }
        }
    },
    container: {
        p: 2,
        position: "relative",
        overflow: "hidden",
        background: "rgba(255, 255, 255, 0.6);",
        borderRadius: 4,
        boxShadow: 0,
        "& form": {
            paddingTop: 2,
            "& input:-internal-autofill-selected": {
                backgroundColor: "none !important"
            }
        }
    },
}

const PublicLayout = ({ image }) => {
    const volcanoTheme = useVolcanoTheme()

    return (
        <Box sx={styles.page(volcanoTheme.loginBackground)}>
            <Grid
                container
                spacing={0}
                direction="column" alignItems="center">
                <Grid item xs={12} sx={styles.page_header}></Grid>
                <Grid item xs={12} sx={styles.page_content}>
                    <Box sx={styles.card_container(volcanoTheme.loginBackground)}>
                        <Card sx={styles.container}>
                            <CardMedia
                                component="img"
                                height="auto"
                                image={image}
                                sx={{
                                    width: "auto",
                                    margin: "0 auto",
                                    padding: "0.5em",
                                    maxHeight: "7em"
                                }}
                            />
                            <Outlet />
                        </Card>
                    </Box>
                </Grid>
            </Grid>
        </Box>
    )
}

export default PublicLayout