import { createContext, useCallback, useContext, useEffect, useReducer, useState } from 'react'
import _get from 'lodash/get'
import _isFunction from 'lodash/isFunction'
import _set from 'lodash/set'
import filterReducer from './filter.reducer'
import constants from '../../constants'
import { setFilter, deleteFilter, setAsyncFilterOptions } from './filter.action'
import { useSearchParams } from 'react-router-dom'

const Context = createContext();

const processInitialFilterState = (filterConfig, defaultFilter, searchParams, ignoreSearchParams) => {

    const defaultState = defaultFilter || { asyncFilterOptions: {} }

    if (!filterConfig) {
        return defaultState
    }
    if (searchParams.has("q") && !ignoreSearchParams ) {
        const params = JSON.parse(atob(searchParams.get("q")))

        // initilize date filter params
        filterConfig
            .filter(field => field.type === constants.DATE_TYPE)
            .forEach(field => {
                const date = _get(params.filter, field.id)
                if (date) {
                    _set(params.filter, field.id, new Date(date))
                }
            })

        filterConfig
            .forEach(field => {
                _set(defaultState, field.id, _get(params.filter, field.id, ""))
            })
    } else {
        filterConfig
            .forEach(field => {
                _set(defaultState, field.id, field.defaultValue || "")
            })
    }

    return defaultState
}

const FilterProvider = ({ filterConfig, children, ignoreSearchParams }) => {
    const [searchParams, setSearchParams] = useSearchParams()
    const [ready, setReady] = useState(false)
    const [filter, filterDispatch] = useReducer(
        filterReducer,
        processInitialFilterState(filterConfig, {}, searchParams, ignoreSearchParams)
    )

    const updateFilter = useCallback((values) => filterDispatch(setFilter(values)), [filterDispatch])

    const removeFilter = useCallback((fieldName) => filterDispatch(deleteFilter(fieldName)), [filterDispatch])

    // process async filter params
    useEffect(() => {
        if (ready) {
            return
        }

        const asyncFilters = filterConfig ?
            filterConfig
                // filter out non async filters
                .filter(field => field.type === constants.ASYNC_SELECT_TYPE)
                // process filter conditions if any
                .filter(field => _isFunction(field.condition) ? field.condition() : true)
            : []

        if (asyncFilters.length === 0) {
            setReady(true)
            return
        }

        const fetchers = asyncFilters.map(field => ({
            key: field.id,
            fetcher: field.optionsFetcher
        }))

        Promise.all(fetchers.map(fetcher => fetcher.fetcher()))
            .then(results => {
                const params = {}
                results.forEach((result, index) => {
                    params[fetchers[index].key] = result
                })

                filterDispatch(setAsyncFilterOptions(params))

                setReady(true)
            })
    }, [ready, filterConfig, filterDispatch, setReady])

    if (!ready) {
        return null
    }

    // process filter params without async options
    const processedFilter = {
        ...filter
    }

    delete processedFilter.asyncFilterOptions

    return (
        <Context.Provider value={[filterConfig, processedFilter, filter.asyncFilterOptions, updateFilter, removeFilter]}>
            {children}
        </Context.Provider>
    )
}

const useFilterContext = () => useContext(Context)

export {
    FilterProvider,
    useFilterContext
}