import { createExportField } from "../../../components/helpers/fieldHelper";

export const getTableExport = (apiClient, t) => {
    return (filter, progressHandler) => {
        apiClient.user.exportApplicationUsers(
            [
                createExportField("id", "Id"),
                createExportField(
                    "username",
                    t("settings_system_users.fields.username")
                ),
                createExportField("name", t("settings_system_users.fields.name")),
                createExportField("email", t("settings_system_users.fields.email")),
                createExportField("phone", t("settings_system_users.fields.phone")),
                createExportField(
                    "created",
                    t("settings_system_users.fields.created")
                ),
                createExportField(
                    "last_login",
                    t("settings_system_users.fields.last_login")
                ),
            ],
            filter,
            progressHandler
        )
    }
} 