import { useVolcanoApiClient } from "../../../../context/VolcanoApiClientProvider"
import { useVolcanoAuth } from "../../../../context/VolcanoAuthContext"
import ValidationWidget from "./ValidationWidget"
import { format as formatDate } from "date-fns"
import _get from "lodash/get"
import _has from "lodash/has"

const DEFAULT_USER_CONFIG = {
    activity_id: null,
    activity_config_id: null,
    access_control_venue_id: null,
    access_control_point_id: null,
    access_control_point_name: "",
}

const VALIDATION_ERROR_VOLCANO_ENTITY_NOT_FOUND = "VOLCANO_ENTITY_NOT_FOUND"
const VALIDATION_ERROR_BOOKING_VALIDATED = "VOLCANO_BOOKING_VALIDATED"
const VALIDATION_ERROR_BOOKING_VALIDATION_TYPE = "VOLCANO_VALIDATION_TYPE"
const VALIDATION_ERROR_ACTIVITY_REQUIRED = "VOLCANO_ACTIVITY_REQUIRED"
const VALIDATION_ERROR_ACTIVITY_VALIDATION_STATE = "VOLCANO_ACTIVITY_VALIDATION_STATE"
const VALIDATION_ERROR_ACCESS_CONTROL_POINT_REQUIRED = "VOLCANO_VALIDATION_ACCESS_CONTROL_POINT_REQUIRED"
const VALIDATION_ERROR_ACCESS_CONTROL_POINT = "VOLCANO_VALIDATION_ACCESS_CONTROL_POINT_INVALID"
const VALIDATION_ERROR_ACCESS_CONTROL_POINT_ORDER = "VOLCANO_VALIDATION_ACCESS_CONTROL_POINT_ORDER"
const VALIDATION_ERROR_PRODUCT_CONFIGURATION = "VOLCANO_VALIDATION_PRODUCT_CONFIGURATION"

const VALIDATION_ERROR_MESSAGES_MAPPING = {
    [VALIDATION_ERROR_VOLCANO_ENTITY_NOT_FOUND]: "validation_widget.access_control.result.error.type.volcano_entity_not_found",
    [VALIDATION_ERROR_BOOKING_VALIDATED]: "validation_widget.access_control.result.error.type.volcano_booking_validated",
    [VALIDATION_ERROR_BOOKING_VALIDATION_TYPE]: "validation_widget.access_control.result.error.type.volcano_validation_type",
    [VALIDATION_ERROR_ACTIVITY_REQUIRED]: "validation_widget.access_control.result.error.type.volcano_activity_required",
    [VALIDATION_ERROR_ACTIVITY_VALIDATION_STATE]: "validation_widget.access_control.result.error.type.volcano_activity_validation_state",
    [VALIDATION_ERROR_ACCESS_CONTROL_POINT_REQUIRED]: "validation_widget.access_control.result.error.type.volcano_validation_access_control_point_required",
    [VALIDATION_ERROR_ACCESS_CONTROL_POINT]: "validation_widget.access_control.result.error.type.volcano_validation_access_control_point",
    [VALIDATION_ERROR_ACCESS_CONTROL_POINT_ORDER]: "validation_widget.access_control.result.error.type.volcano_validation_access_control_point_order",
    [VALIDATION_ERROR_PRODUCT_CONFIGURATION]: "validation_widget.access_control.result.error.type.volcano_validation_product_configuration",
    "default": "validation_widget.access_control.result.error.type.default",
}

const VALIDATION_SUCCESS_ENTITY_TYPE_BOOKING = "booking"
const VALIDATION_SUCCESS_ENTITY_TYPE_BOOKING_TICKET = "booking_ticket"

const VALIDATION_SUCCESS_MESSAGES_MAPPING = {
    [VALIDATION_SUCCESS_ENTITY_TYPE_BOOKING]: "validation_widget.access_control.result.success.type.booking",
    [VALIDATION_SUCCESS_ENTITY_TYPE_BOOKING_TICKET]: "validation_widget.access_control.result.success.type.booking_ticket",
}

const formatValidationError = (locator, error) => ({
    sucess: false,
    message_key: _get(VALIDATION_ERROR_MESSAGES_MAPPING, error.type, VALIDATION_ERROR_MESSAGES_MAPPING["default"]),
    data: { locator: locator, ...error.data }
})

const formatValidationResult = (validation) => {
    let entity = null

    switch (validation.type) {
        case "full":
            entity = {
                entity_type: "booking",
                entity_id: validation.booking.id,
                locator: validation.booking.locator,
            }
            break
        case "single":
            entity = {
                entity_type: "booking_ticket",
                entity_id: validation.booking_ticket.id,
                locator: validation.booking_ticket.locator,
            }
            break
    }

    return {
        success: true,
        id: validation.id,
        type: validation.type,
        message_key: VALIDATION_SUCCESS_MESSAGES_MAPPING[entity.entity_type],
        data: {
            date: validation.date,
            created: validation.created,
            ...entity,
        }
    }
}

const VolcanoValidationWidget = () => {
    const { apiClient } = useVolcanoApiClient()
    const volcanoAuth = useVolcanoAuth()

    const activitiesFetcher = (activityConfigId) => {
        const date = formatDate(new Date(), "yyyy-MM-dd")

        return apiClient.activityManager.activity.getActivities({
            activity_config_id: activityConfigId,
            date_from: date,
            date_to: date,
        })
    }

    const onValidateHandler = (locator, settings) => {
        const data = {
            locator: locator,
            activity_id: settings.activity_id,
            access_control_point_id: settings.access_control_point_id,
            date: formatDate(new Date(), "yyyy-MM-dd H:mm:ss"),
        }

        const t1 = {
            "id": "bcd3058d-89d9-4f4a-95f1-f80e97b8d717",
            "type": "single",
            "date": "2023-08-25 07:03:44",
            "booking_ticket": {
                "id": 98943,
                "locator": "1RTBQ-D0DE-37115",
                "qr_code": "1RTBQ-D0DE-37115",
                "number": 1
            },
            "activity": {
                "id": "dbd0c857-d990-465c-8301-2e7691658dca",
                "activity_configuration": {
                    "id": "96c2a143-29a5-4ebd-8e69-448e967f64bb",
                    "name": "Expo",
                    "created": "2023-08-02 11:13:44",
                    "href": "https://api.staging.volcanoteide.com/admin/am/activity_configs/96c2a143-29a5-4ebd-8e69-448e967f64bb"
                },
                "date": "2023-09-20 00:00:00",
                "created": "2023-08-02 12:39:02",
                "href": "https://api.staging.volcanoteide.com/admin/am/activities/dbd0c857-d990-465c-8301-2e7691658dca",
                "state": "ready"
            },
            "access_control_point": {
                "id": "82d2ee1e-87fc-479e-a49c-c8bbda875f2c",
                "name": "Expo",
                "_links": {
                    "self": {
                        "href": "https://api.staging.volcanoteide.com/admin/access-control/venues/da0485e7-b90c-11ed-88b9-020000ee9ac7/points/82d2ee1e-87fc-479e-a49c-c8bbda875f2c"
                    }
                },
                "summary": {
                    "total_bookings": 2,
                    "total_pax": 6,
                    "total_amount": 12,
                    "total_bookings_redeemed": 2,
                    "total_pax_redeemed": 4
                }
            },
            "coordinates": {
                "status": "available",
                "lat": null,
                "lon": null
            },
            "created": "2023-08-25 06:04:24",
            "user": {
                "id": "b37832fd-28e0-11eb-80ba-0cc47ac3a6b8",
                "user_type": "application",
                "name": "Roberto Muñoz"
            },
            "_links": {
                "self": {
                    "href": "https://api.staging.volcanoteide.com/admin/bookings/1963386/validations/bcd3058d-89d9-4f4a-95f1-f80e97b8d717"
                },
                "delete": {
                    "href": "https://api.staging.volcanoteide.com/admin/bookings/1963386/validations/bcd3058d-89d9-4f4a-95f1-f80e97b8d717",
                    "method": "DELETE"
                }
            },
            "actions": []
        }

        const t2 = {
            "error": true,
            "type": "VOLCANO_BOOKING_VALIDATED",
            "message": "Booking 1RTBQ-D0DE is already validated [2023-08-25 07:03:44]",
            "url": "/admin/bookings/validations",
            "code": 403,
            "data": {
                "id": "bcd3058d-89d9-4f4a-95f1-f80e97b8d717",
                "booking_id": 1963386,
                "locator": "1RTBQ-D0DE",
                "validation_date": "2023-08-25 07:03:44"
            }
        }

        //return Promise.resolve(formatValidationResult(t1))
        //return Promise.resolve(formatValidationError(locator, t2))

        return apiClient.booking
            .addValidationFromLocator(data)
            .then((validation) => {
                return formatValidationResult(validation)
            })
            .catch((error) => {
                return formatValidationError(locator, error)
            })
    }

    return <ValidationWidget
        defaultConfig={{
            ...DEFAULT_USER_CONFIG,
            ..._get(volcanoAuth.user.settings, "validation_widget", {})
        }}
        activitiesFetcher={activitiesFetcher}
        onValidate={onValidateHandler}
    />
}

export default VolcanoValidationWidget