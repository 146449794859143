import { useCallback, useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { Box, Checkbox, Stack } from "@mui/material"
import _get from "lodash/get"
import _has from "lodash/has"
import _isEmpty from "lodash/isEmpty"
import _isFunction from "lodash/isFunction"
import "/node_modules/flag-icons/css/flag-icons.min.css"
import ItemSelector from "../../ItemsSelector/ItemsSelector"
import { orderItemsByKey } from "../../../lib/utils"

const BASE_CATALOG = {
    categories: null,
    experiences: [],
    products: [],
    featuredProducts: [],
}

const BASE_SELECTION = {
    category: null,
    experience: null,
    product: null,
    state: "active"
}

const ProductSelector = ({
    categoriesFetcher,
    experiencesFetcher,
    productsFetcher,
    featuredProductsFetcher,
    selected,
    onSelection,
}) => {
    const { t } = useTranslation("vbms")
    const [catalog, setCatalog] = useState(BASE_CATALOG)
    const [selection, setSelection] = useState(selected || BASE_SELECTION)
    const [status, setStatus] = useState({ empty_experiences: false, empty_experience_products: false })

    const loadCategories = useCallback(() => {
        return categoriesFetcher().then((result) => {
            result = orderItemsByKey(result, "name")
            setCatalog((prev) => ({
                ...prev,
                categories: result,
                experiences: [],
                products: [],
            }))

            if (_has(selection, "category.id")) {
                const category = result.find((item) => item.id === selection.category.id)

                setSelection((prev) => ({ ...prev, category: category }))
                return
            }

            if (_has(selection, "experience.id")) {
                const expCategories = result
                    .filter((item) => _get(item, "experiences", []).find((experience) => experience.id === selection.experience.id))
                    .sort((a, b) => { return a.id < b.id })

                setSelection((prev) => ({ ...prev, category: expCategories.length === 0 ? null : expCategories[0] }))
                return
            }

            if (result.length === 1) {
                setSelection({ ...BASE_SELECTION, category: result[0] })
            }
        })
    }, [categoriesFetcher, selection])

    const loadExperiences = useCallback(async () => {
        let result

        // order result by name
        if (_isFunction(experiencesFetcher)) {
            result = await experiencesFetcher(selection.category.id).then((result) => {
                return orderItemsByKey(result, "name") 
            })
        } else {
            result = orderItemsByKey(selection.category.experiences, "name")
        }

        result = ((_has(selection, "state") && _get(selection, "state.id") === "active") ?
            result.filter((item) => _get(selection, "state.id") === item.state) :
            result 
        )

        setStatus((prev) => ({
            ...prev,
            empty_experiences: _isEmpty(result) || result.length === 0
        }))

        setCatalog((prev) => ({
            ...prev,
            experiences: result,
            products: [],
        }))

        if (_has(selection, "experience.id")) {
            const experience = result.find((item) => item.id === selection.experience.id)

            setSelection((prev) => ({ ...prev, experience: experience }))
            return
        }

        if (result.length === 1) {
            setSelection((prev) => ({ ...prev, experience: result[0] }))
        }
    }, [experiencesFetcher, selection, setStatus])

    const loadProducts = useCallback(async () => {
        let result

        // order result by name
        if (_isFunction(productsFetcher)) {
            result = await productsFetcher(selection.experience.id).then((result) => {
                return orderItemsByKey(result.map((product) => ({ ...product, experience_id: selection.experience.id })), "name")
            })
        } else {
            result = orderItemsByKey(selection.experience.products, "name")
        }

        result = ((_has(selection, "state") && (_get(selection, "state.id") === "active")) ?
            result.filter((item) => _get(selection, "state.id") === item.state) :
            result
        )

        setStatus((prev) => ({
            ...prev,
            empty_experience_products: _isEmpty(result) || result.length === 0
        }))

        setCatalog((prev) => ({
            ...prev,
            products: result,
        }))

        if (result.length === 1 && !_has(selected, "product.id")) {
            setSelection((prev) => ({ ...prev, product: result[0] }))
        }
    }, [productsFetcher, selection.experience, selection.state, selected, setStatus])

    useEffect(() => {

        if (!_has(selection, "category.name") && catalog.categories === null) {
            loadCategories()
            return
        }

        if (_has(selection, "category.id")
            && !_has(selection, "experience.name")
            && _has(selection, "category.experiences")
            && _isEmpty(catalog.experiences)
            && !status.empty_experiences) {

            loadExperiences()
            return
        }

        if (_has(selection, "experience.id")
            && !_has(selection, "product.name")
            //&& _has(selection, "experience.products")
            && _isEmpty(catalog.products)
            && !status.empty_experience_products) {

            loadProducts()
        }

        onSelection(selection)

    }, [selection, catalog, loadCategories, loadExperiences, loadProducts, onSelection, status])

    const handleStateSelected = (event) => {
        let state = null
        if (event.target.checked) {
            state = {id: "active"}
        } else {
            state = {id: "all"}
        }

        if (_has(selection, "experience.id")) {
            setStatus((prev) => ({
                ...prev,
                empty_experience: false,
                empty_experience_products: false
            }))
            setSelection({
                ...selection,
                state: state,
                product: null,
            })
            setCatalog((prev) => ({
                ...prev,
                products: [],
            }))
        } else {
            setStatus((prev) => ({
                ...prev,
                empty_experiences: false,
                empty_experience_products: true
            }))
            setSelection({
                ...selection,
                state: state,
                experience: null,
                product: null,
            })
            setCatalog((prev) => ({
                ...prev,
                experiences: [],
                products: [],
            }))
        }
    }

    const handleCategorySelected = (category) => {
        setStatus((prev) => ({
            ...prev,
            empty_experiences: false
        }))

        setSelection({
            category: category,
            experience: null,
            product: null
        })

        if (!category) {
            setCatalog((prev) => ({
                ...prev,
                experiences: [],
                products: [],
            }))
        }
    }

    const handleExperienceSelected = (experience) => {
        setStatus((prev) => ({
            ...prev,
            empty_experience_products: false
        }))

        setSelection({
            ...selection,
            experience: experience,
            product: null,
        })

        if (!experience) {
            setCatalog((prev) => ({
                ...prev,
                experiences: [],
                products: [],
            }))
        }
    }

    const handleProductSelected = (product) => {
        if (!product) {
            setSelection({
                ...selection,
                product: null,
            })
            return
        }

        setSelection({
            ...selection,
            //experience: catalog.experiences.find((exp) => exp.id === product.experience_id),
            product: product,
        })
    }

    return (
        <Stack spacing={2}>
            {/*!selection.experience && !_isEmpty(catalog.featuredProducts) && (
                <ItemSelector
                    title={t("booking_widget.steps.product.card_feature_products_title")}
                    items={catalog.featuredProducts.map((product) => ({
                        title: product.experience.name,
                        subtitle: product.name,
                        value: product
                    }))}
                    selected={_get(selection, "product.id")}
                    onItemSelected={(product) => setSelection({
                        category: null,
                        experience: catalog.experiences.find((exp) => exp.id === product.experience.id),
                        product: product,
                    })}
                />
            )*/}
            {catalog.categories !== null && catalog.categories.length > 1 &&
                <ItemSelector
                    title={t("booking_widget.steps.product.card_category_title")}
                    items={catalog.categories.map((category) => ({
                        title: category.name,
                        image: _get(category, "list_image.small.responsive", ""),
                        value: category
                    }))}
                    selected={_get(selection, "category.id")}
                    onItemSelected={handleCategorySelected}
                />
            }
            {/*
                <Box
                    display="flex"
                    justifyContent="flex-start"
                    alignItems="center"
                    sx={{ paddingLeft: 2 }}
                >
                    <Box>
                        <Checkbox 
                            onChange={handleStateSelected}
                            title={t("booking_widget.steps.product.card_state_title") }
                            inputProps={{ "aria-label": t("booking_widget.steps.product.card_state_title") }} 
                            checked={_get(selection, "state") && _get(selection, "state.id") === "active"}
                            />
                    </Box>
                    <Box>{t("booking_widget.steps.product.card_state_title")}</Box>
                </Box>
        */}
            {selection.category && catalog.experiences.length > 1 && (
                <ItemSelector
                    title={t("booking_widget.steps.product.card_experience_title")}
                    items={catalog.experiences.map((experience) => ({
                        title: experience.name,
                        image: _get(experience, "image.cached_images.small.responsive", ""),
                        value: experience,
                    }))}
                    selected={_get(selection, "experience.id")}
                    onItemSelected={handleExperienceSelected}
                />
            )}
            {selection.experience && catalog.products.length > 0 && (
                <ItemSelector
                    title={t("booking_widget.steps.product.card_product_title")}
                    items={catalog.products.map((product) => ({
                        title: product.name,
                        value: product
                    }))}
                    selected={_get(selection, "product.id")}
                    onItemSelected={handleProductSelected}
                />
            )}
        </Stack>
    )
}

export default ProductSelector
