import { useTranslation } from "react-i18next"
import { useNavigate, useSearchParams } from "react-router-dom"
import { useVolcanoApiClient } from "../../../context/VolcanoApiClientProvider"
import availableRoutes from "../../../routes/availableRoutes"
import useSnackBars from "../../../components/Snackbar/snack-bar.context"
import PaymentTransactionResultBasePage from "./PaymentTransactionBaseResultPage"
import { useVolcanoAuth } from "../../../context/VolcanoAuthContext"

const PaymentTransactionResultTokenPage = () => {
    const { t } = useTranslation("vbms")
    const [searchParams, setSearchParams] = useSearchParams()
    const { apiClient } = useVolcanoApiClient()
    const volcanoAuth = useVolcanoAuth()
    const { addAlert } = useSnackBars()
    const navigate = useNavigate()

    const transactionResultFetcher = () => {
        return apiClient.paymentTransaction.getTransaction(searchParams.get("vte_transaction_id"))
            .then(result => {
                // check if the transaction was successful
                if (result.status === "success") {
                    addAlert(t("payment_transactions.token_result.success.message"), "success")
                } else if (result.status === "failed") {
                    addAlert(t("payment_transactions.token_result.fail.message"), "error")
                }
                navigate(
                    availableRoutes.intermediaries_intermediary_view.path.replace(":entityId", volcanoAuth.user.crm_intermediary.id),
                    { replace: true }
                )
            })
    }

    return (
        <PaymentTransactionResultBasePage
            transationType="payment_token"
            transactionResultFetcher={transactionResultFetcher}            
        />
    )
}

export default PaymentTransactionResultTokenPage