import { useContext } from "react";
import { Stack, Typography, useTheme } from "@mui/material"
import { TimePicker } from "@mui/x-date-pickers";
import { format } from "date-fns";
import { RulesDivider } from "../RulesSelector";
import { RulesContext } from "./RulesSelector";
import { t } from "i18next";

const INTERVALS = {
    FROM: 'hour_from',
    TO: 'hour_to'
}

export const DEFAULT_HOURS = {
    [INTERVALS.FROM]: "00:00:00",
    [INTERVALS.TO]: "23:59:59"
}

function hoursToDate(timeString) {
    if (!timeString) return null

    const date = new Date(0);
    const [hours, minutes, seconds] = timeString.split(':').map(Number);
    
    return date.setHours(hours, minutes, seconds, 0);
  }

const formatHour = (time) => {
    return format(time, "HH:mm:ss")
}


export const RulesSelectorHours = ({ label = "Label", showLabel, showDivider, editable = false, index}) => {
    const theme = useTheme()

    const {config, setConfig} = useContext(RulesContext)
    const {hour_from: hourFrom, hour_to: hourTo} = config[index]

    const onChange = (value, interval) => {
        let newConfig = [...config]
        newConfig[index] = {...newConfig[index], 
                [interval]: formatHour(value)
        }
        setConfig(newConfig)
    }

    const renderLabel = () => {
        if (showLabel || (!showLabel && index === 0)) {
            return (
                <Typography 
                    variant="h5" 
                    borderBottom={!showLabel && `1px solid ${theme.palette.common.container.border}`}
                    marginBottom={!showLabel && 2}
                    paddingBottom={!showLabel && 2}
                    sx={{ fontWeight: 'bold' }} 
                >
                    {label}
                </Typography>
            )
        }
        return null
    }

    return (
        <Stack gap={2}>
            {renderLabel()}
            <Stack direction={{ xs: 'column', sm: 'row'}} spacing={{ xs: 2, sm: 1 }} divider={showDivider && <RulesDivider/>}>
                <TimePicker
                    format="HH:mm"
                    label={t("components.rules_selector.range_from")} 
                    value={hourFrom ? hoursToDate(hourFrom) : hoursToDate(DEFAULT_HOURS[INTERVALS.FROM])}
                    slotProps={{ textField: { size: 'small', InputLabelProps: { shrink: true }, fullWidth: true } }}
                    ampm={false}
                    readOnly={!editable}
                    disableOpenPicker={!editable}
                    onChange={(value) => onChange(value, INTERVALS.FROM)}
                />
                <TimePicker
                    format="HH:mm"
                    label={t("components.rules_selector.range_to")} 
                    value={hourTo ? hoursToDate(hourTo) : hoursToDate(DEFAULT_HOURS[INTERVALS.TO])}
                    slotProps={{ textField: { size: 'small', InputLabelProps: { shrink: true }, fullWidth: true } }}
                    ampm={false}
                    readOnly={!editable}
                    disableOpenPicker={!editable}
                    onChange={(value) => onChange(value, INTERVALS.TO)}
                />
            </Stack>
        </Stack>
    )
}

export default RulesSelectorHours