import constants from "../../../components/entity/constants";
import { createField, createIconField } from '../../../components/helpers/fieldHelper';
import VisibilityIcon from '@mui/icons-material/Visibility';

export const getTableColumns = (t, user) => ([
    createIconField(
        "id",
        VisibilityIcon,
        t("common.actions.view.button"),
        {
            route: "billing_clients",
            field: "id",
        },
        true
    ),
    createField(
        "vat_number",
        t("billing_clients.fields.vat_number"),
        constants.STRING_TYPE
    ),
    createField(
        "name",
        t("billing_clients.fields.name"),
        constants.STRING_TYPE
    )
])