import constants from '../../../components/entity/constants';
import { createDataSetFacet, createFacetField, createField, createLinkField, createObjectStatusField } from '../../../components/helpers/fieldHelper';
import userPermissions from '../../../lib/user-permissions';

const processOfficeField = (t, user) => {
    const field = createField(
        "crm_intermediary_office.name",
        t("settings_system_users.fields.office"),
        constants.TEXT_TYPE,
        null,
        (user) => user.hasOwnProperty('crm_intermediary_office')
    )

    if (user.hasPermission(userPermissions.PERM_CRM_INTERMEDIARY_OFFICE_VIEW)) {
        return createLinkField(
            field,
            {
                route: "intermediary_offices",
                field: "crm_intermediary_office.id",
            }
        )
    }

    return field
}

export const getHeaderContent = (t, user) => [
    createDataSetFacet(t("settings_system_users.view.facet_information"), [
        createField("id", "Id", constants.STRING_TYPE),
        createField(
            "username",
            t("settings_system_users.fields.username"),
            constants.STRING_TYPE
        ),
        createField(
            "created",
            t("settings_system_users.fields.created"),
            constants.DATETIME_TYPE
        ),
        createField(
            "last_login",
            t("settings_system_users.fields.last_login"),
            constants.DATETIME_TYPE
        ),
        createField(
            "corporate_account.enterprise.name",
            t("common.enterprise"),
            constants.STRING_TYPE
        ),
        createField(
            "crm_intermediary.name",
            t("settings_system_users.fields.intermediary"),
            constants.STRING_TYPE,
            null,
            (user) => user.isIntermediary()
        ),
        processOfficeField(t, user),
    ]),
    createDataSetFacet(
        t("settings_system_users.view.facet_contact"),
        [
            createField("first_name", t("settings_system_users.fields.name"), constants.STRING_TYPE),
            createField("last_name", t("settings_system_users.fields.last_name"), constants.STRING_TYPE),
            createField("email", t("settings_system_users.fields.email"), constants.EMAIL_TYPE),
            createField("phone", t("settings_system_users.fields.phone"), constants.PHONE_TYPE),
            createField(
                "salesman.id_card",
                t("settings_system_users.fields.id_card"),
                constants.STRING_TYPE,
                null,
                (user) => user.hasOwnProperty('salesman')
            ),
        ],
    ),
    createFacetField("object_status", {
        size: "large",
        field: createObjectStatusField(
            createField(
                "active",
                t("settings_system_users.fields.active"),
                constants.BOOLEAN_TYPE
            ),
            null,
            (value) => {
                switch (value) {
                    case false:
                        return "negative";
                    case true:
                        return "positive";
                    default:
                        return "neutral";
                }
            }
        ),
    }),
];