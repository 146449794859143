import { useState, useCallback } from "react";
import { useTranslation } from "react-i18next"
import { useVolcanoApiClient } from "../../context/VolcanoApiClientProvider"
import { useNavigate, useParams } from "react-router-dom"
import { useVolcanoAuth } from "../../context/VolcanoAuthContext";
import { useCache } from "../../context/cache.context";
import { createAsyncSelectFormField, createCallbackField, createField, createFormFieldGroup, createFormFieldset, createFormFieldOption, createFormField, createDependentFormField } from '../../components/helpers/fieldHelper';
import { enterprisesFetcher, sitesFetcher, buildOptions } from '../../lib/form-options-fetchers';
import availableRoutes from "../../routes/availableRoutes";
import AsyncEntityFormPage from "../../layout/templates/AsyncContentPage/AsyncEntityFormPage"
import Loading from "../../components/Display/Loading";
import constants from "../../components/entity/constants"
import useSnackBars from "../../components/Snackbar/snack-bar.context";
import _get from "lodash/get";
import isEmpty from "lodash/isEmpty";

const TagFormPage = () => {
    const navigate = useNavigate()
    const cache = useCache()
    const { t } = useTranslation("vbms")
    const { apiClient } = useVolcanoApiClient()
    const { entityId } = useParams()
    const { user } = useVolcanoAuth()
    const { addAlert } = useSnackBars()

    const [isReady, setIsReady] = useState(false)

    const fetcher = useCallback(() => {
        setIsReady(false)

        if (!entityId) {
            setIsReady(true)
            return new Promise((res) => res({}))
        }

        return apiClient.content.tag.getTag(entityId).then((tag) => {
            setIsReady(true)
            return tag
        })
    }, [apiClient, entityId])

    const onSaveHandler = (data) => {
        setIsReady(false)

        const regIntegers = /^\d+$/
        let errors = []

        if (!isEmpty(data.sort_order) && !regIntegers.exec(data.sort_order)) {
            errors = [...errors, t("tags.form.sort_order_error")]
        }

        if (!isEmpty(errors)) {
            addAlert(errors[0])
            setIsReady(true)
            return
        }

        const tag = {
            ...data,
            site_id: data.site.id,
            group_id: data.group.id,
            sort_order: data.sort_order || 0
        }

        delete tag.site
        delete tag.group

        if (user.hasPermission("access-any-enterprise")) {
            tag['enterprise_id'] = data.enterprise.id
            delete tag.enterprise
        }

        if (!entityId) {
            apiClient.content.tag.addTag(tag)
                .then(() => {
                    navigate(availableRoutes.tags.path)
                })
                .catch((error) => {
                    setIsReady(true)
                    addAlert("Error: " + error.message)
                })
        } else {
            apiClient.content.tag.editTag(entityId, tag)
                .then((tag) => {
                    navigate(`${availableRoutes.tags.path}/${entityId}`)
                })
                .catch((error) => {
                    setIsReady(true)
                    addAlert("Error: " + error.message)
                })
        }
    }

    const formContent = [
        createFormFieldset(t("tags.form.fieldset_information"),
            [
                createFormFieldGroup([
                    createFormField(
                        createField(
                            "slug",
                            t("tags.fields.slug"),
                            constants.STRING_TYPE
                        ),
                        true
                    ),
                    createFormField(
                        createField(
                            "name",
                            t("tags.fields.name"),
                            constants.STRING_TYPE
                        ),
                        true
                    )
                ]),
                createFormField(
                    createAsyncSelectFormField(
                        "enterprise.id",
                        t("tags.fields.enterprise"),
                        () => enterprisesFetcher(cache, apiClient, t, false),
                        false,
                        () => user.hasPermission('access-any-enterprise')
                    ),
                    true,
                    user.corporate_account.enterprise.id
                ),
                // Sites dependent filter of enterprise (For admin users)
                createFormField(
                    createDependentFormField(
                        "site.id",
                        t("tags.filter.site"),
                        constants.SELECT_TYPE,
                        (values) => {
                            const enterpriseId = parseInt(_get(values, "enterprise.id", "-1"))
                            return sitesFetcher(cache, apiClient)
                                .then((sites) => {
                                    if (enterpriseId !== -1) sites = sites.filter((item) => item.enterprise.id === enterpriseId)

                                    return [createFormFieldOption("", t("tags.constants.no_site_selected")), ...buildOptions(sites, t, "id", "name", false)]
                                })
                        },
                        () => user.hasPermission("access-any-enterprise"),
                    ),
                    true,
                    user.site.id ?? ""
                ),
                // Sites non-dependent filter (For sites users)
                createFormField(
                    createAsyncSelectFormField(
                        "site.id",
                        t("tags.filter.site"),
                        (params) => sitesFetcher(cache, apiClient, params).then((sites) => buildOptions(sites, t)),
                        false,
                        () => !user.hasPermission("access-any-enterprise")
                    ),
                    false,
                    user.site.id
                ),
                createFormFieldGroup([
                    createFormField(
                        createDependentFormField(
                            "group.id",
                            t("tags.filter.group"),
                            constants.SELECT_TYPE,
                            (values) => {
                                const enterpriseId = parseInt(_get(values, "enterprise.id", "-1"))
                                const siteId = parseInt(_get(values, "site.id", "-1"))
                                return cache.get("tag_groups").then((tagGroups) => {
                                    if (user.hasPermission("access-any-enterprise")) {
                                        if (enterpriseId !== -1 && siteId !== -1) tagGroups = tagGroups.filter((item) => item.site.id === siteId)
                                    } else {
                                        if (siteId !== -1) tagGroups = tagGroups.filter((item) => item.site.id === siteId)
                                    }

                                    return [createFormFieldOption("", t("tags.constants.no_group_selected")), ...buildOptions(tagGroups, t, "id", "name", false)]
                                })
                            }
                        ),
                        false
                    ),
                    createFormField(
                        createCallbackField(
                            "sort_order",
                            t("tags.fields.sort_order"),
                            constants.STRING_TYPE,
                            (entity, values) => values.sort_order || "0"
                        ),
                        false,
                        "0"
                    )
                ]),
                createFormFieldGroup([
                    createCallbackField(
                        "protected",
                        t("tags.fields.protected"),
                        constants.BOOLEAN_TYPE,
                        (entity, values) => values.protected || true,
                        () => user.hasAnyPermission([
                            "access-any-enterprise",
                            "administer-tags-system"
                        ])
                    ),
                    createCallbackField(
                        "visible",
                        t("tags.fields.visible"),
                        constants.BOOLEAN_TYPE,
                        (entity, values) => values.visible || true
                    ),
                ])
            ])
    ]

    return (
        <>
            <Loading open={!isReady} />
            <AsyncEntityFormPage
                fetcher={fetcher}
                title={
                    createCallbackField(
                        "name",
                        t("tags.form.add_title"),
                        constants.STRING_TYPE,
                        (entity, values) => !entity ? t("tags.form.add_title") : t("tags.form.edit_title", { name: values.slug })
                    )
                }
                onSave={onSaveHandler}
                formContent={formContent}
            />
        </>

    )
}

export default TagFormPage