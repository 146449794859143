import { useTranslation } from "react-i18next"
import ErrorPage from "../layout/templates/ErrorPage/ErrorPage"

const NotFound = () => {
    const { t } = useTranslation("vbms")

    return (
        <ErrorPage
            code={404}
            message={t("common.errors.not_found.title")}
            description={t("common.errors.not_found.subtitle")}
        />
    )
}

export default NotFound