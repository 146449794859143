import { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { Box, Button, Grid, Stack } from "@mui/material"
import DataWrapper from "../../../Display/DataWrapper"
import { ValueSelectorInput } from "../../../form/ValuseSelectorDialogFormField"
import { getIntermediariesClientsFilter, getIntermediaryOfficesFilter, getIntermediarySalesmanFilter } from "../../../../lib/collection-filters"
import _get from "lodash/get"
import _has from "lodash/has"
import _isEmpty from "lodash/isEmpty"
import ItemSelector from "../../../ItemsSelector/ItemsSelector"
import { orderItemsByKey } from "../../../../lib/utils"

const BASE_SELECTION = {
    intermediary: null,
    office: null,
    salesman: null,
}

const initSelection = (selection) => {
    const result = {
        ...BASE_SELECTION,
    }

    if (_has(selection, "intermediary.id")) {
        result.intermediary = [{
            id: selection.intermediary.id,
            label: selection.intermediary.name,
            entity: selection.intermediary
        }]
    }

    if (_has(selection, "office.id")) {
        result.office = [{
            id: selection.office.id,
            label: selection.office.name,
            entity: selection.office
        }]
    }

    if (_has(selection, "salesman.id")) {
        result.salesman = [{
            id: selection.salesman.id,
            label: selection.salesman.name,
            entity: selection.salesman
        }]
    }

    return result
}

const IntermediarySelector = ({
    intermediariesFetcher,
    featuredIntermediariesFetcher,
    selected,
    onSelection,
}) => {
    const { t } = useTranslation("vbms")
    const [selection, setSelection] = useState(initSelection(selected))
    const [fieldsConfig, setFieldsConfig] = useState([])
    const [featuredIntermediaries, setFeaturedIntermediaries] = useState(null)

    useEffect(() => {
        if (featuredIntermediariesFetcher && !featuredIntermediaries) {
            featuredIntermediariesFetcher().then((result) => {
                setFeaturedIntermediaries(orderItemsByKey([...new Map(result.items.map(intermediary => [intermediary.id, intermediary])).values()], "name"))
            })
        }
    }, [featuredIntermediaries, featuredIntermediariesFetcher])

    // initialize fields config based on selection and fetchers
    useEffect(() => {
        const fetchers = intermediariesFetcher

        const fieldsConfig = [
            // always show intermediary selector
            getIntermediariesClientsFilter(null, t, null, 'intermediary', false, fetchers.intermediary),
        ]
/*
        if (_has(selection, "featuredIntermediary.0.entity.crm_intermediary_id")) {
            const intermediaryId = selection.featuredIntermediary[0].entity.crm_intermediary_id

            // if intermediary is selected, show office selector and salesman selector
            fieldsConfig.push(getIntermediaryOfficesFilter(null, t, null, 'office', false, (params) => {
                const queryFilter = {
                    ...params,
                    crm_intermediary_id: intermediaryId
                }

                return fetchers.office(queryFilter)
            }))

            fieldsConfig.push(getIntermediarySalesmanFilter(null, t, null, "salesman", false, (params) => {
                const queryFilter = {
                    ...params,
                    crm_intermediary_id: intermediaryId,
                    crm_intermediary_office_id: _get(selection, "office[0].id", null)
                }

                return fetchers.salesman(queryFilter)
            }))
        }
*/
        if (_has(selection, "intermediary.0.entity.crm_intermediary_id")) {
            const intermediaryId = selection.intermediary[0].entity.crm_intermediary_id

            // if intermediary is selected, show office selector and salesman selector
            fieldsConfig.push(getIntermediaryOfficesFilter(null, t, null, 'office', false, (params) => {
                const queryFilter = {
                    ...params,
                    crm_intermediary_id: intermediaryId
                }

                return fetchers.office(queryFilter)
            }))

            fieldsConfig.push(getIntermediarySalesmanFilter(null, t, null, "salesman", false, (params) => {
                const queryFilter = {
                    ...params,
                    crm_intermediary_id: intermediaryId,
                    crm_intermediary_office_id: _get(selection, "office[0].id", null)
                }

                return fetchers.salesman(queryFilter)
            }))
        }

        setFieldsConfig(fieldsConfig)
    }, [t, selection, intermediariesFetcher, featuredIntermediariesFetcher])

    const selectedFeaturedIntermediary = (value) => {
        setSelection(() => ({
            ['intermediary']: [
                {
                    id: value.id,
                    label: value.name,
                    entity: value
                }],
        }))
    }

    const handleSelectionUpdated = (fieldName, value) => {
        setSelection((prev) => ({
            ...prev,
            [fieldName]: value,
        }))
    }

    if (fieldsConfig.length === 0) {
        return null
    }

    return (
        <Stack spacing={2}>
            {!_isEmpty(featuredIntermediaries) && (
                <ItemSelector
                    title={t("booking_widget.steps.intermediary.card_featured_intermediaries_title")}
                    items={featuredIntermediaries.map((intermediary) => ({
                        title: intermediary.name,
                        value: intermediary
                    }))}
                    onItemSelected={selectedFeaturedIntermediary}
                />
            )}
            <DataWrapper title={t("booking_widget.steps.intermediary.title")}>
                <Grid container spacing={2} m={0}>
                    <Grid item container xs={12}>
                        {fieldsConfig.map((configField) => {
                            const field = {
                                id: configField.id,
                                name: configField.id,
                                labelMaxLen: 20,
                                value: selection[configField.id],
                                required: false
                            }

                            return (
                                <Grid item xs={12} sm={4} key={configField.id}>
                                    <ValueSelectorInput
                                        field={field}
                                        label={configField.label}
                                        multiple={false}
                                        table={configField.table}
                                        valueFormatter={configField.valueFormatter}
                                        setFieldValue={handleSelectionUpdated}
                                    />
                                </Grid>
                            )
                        })}
                    </Grid>
                    <Grid item xs={12}>
                        <Box display="flex" justifyContent="center">
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={() => onSelection({
                                    intermediary: _get(selection, "intermediary[0].entity", {}),
                                    office: _get(selection, "office[0].entity", null),
                                    salesman: _get(selection, "salesman[0].entity", null),
                                })}
                            >
                                {t("booking_widget.summary.continue")}
                            </Button>
                        </Box>
                    </Grid>
                </Grid>
            </DataWrapper>
        </Stack>
    )
}

export default IntermediarySelector
