import FilterBar from "../../FilterBar/FilterBar"
import { useFilterContext } from "../../entity/context/filter/filter.context"

const DataTableFilter = () => {
    // filterContext = [filterConfig, filter, updateFilter,]
    const filterContext = useFilterContext()

    // check if filter is available and if filterConfig is available
    if (!filterContext || !filterContext[0]) {
        return null
    }

    const [filterConfig, filter, asyncFilterOptions, updateFilter,] = filterContext

    return <FilterBar
        filterConfig={filterConfig}
        asyncFilterOptions={asyncFilterOptions}
        filter={filter}
        onSubmit={updateFilter}
    />
}

export default DataTableFilter