import get from "lodash/get"
import isFunction from "lodash/isFunction"
import constants from "../entity/constants"

export const createField = (id, label, type, mapping, condition, important, orderBy, align = null) => {
    return {
        id: id,
        label: label,
        type: type,
        mapping: mapping,
        condition: condition,
        important: important,
        orderBy: orderBy,
        align: align
    }
}

export const createAggField = (id, label, options, condition) => {
    return {
        id: id,
        label: label,
        type: constants.AGG_TYPE,
        options: options,
        condition: condition,
    }
}

export const createFormField = (field, required, defaultValue, onValuesUpdate, autofocus) => {
    return {
        ...field,
        required: required,
        defaultValue: defaultValue || null,
        onValuesUpdate: onValuesUpdate || null,
        autofocus: autofocus
    }
}

export const createArrayField = (field, displayField) => {
    return {
        ...field,
        displayField: displayField,
    }
}

export const createCallbackField = (id, label, type, callback, condition, important, orderBy) => {
    return {
        ...createField(id, label, type, null, condition, important, orderBy),
        callback: callback,
    }
}

export const createCurrencyField = (field, currencyField, mainCurrencyField, exchangeRateField) => {
    return {
        ...field,
        callback: (value, entity) => {
            const result = {
                value: value,
                currency: get(entity, currencyField),
            }

            if (mainCurrencyField) {
                result.main_currency = get(entity, mainCurrencyField)
                result.exchange_rate = get(entity, exchangeRateField)
            }

            return result
        }
    }
}

export const createLinkField = (field, link, isDisfrutare = false) => {
    return {
        ...field,
        link: link,
    }
}

export const createIconField = (id, Icon, title, link, isAction, condition, important, orderBy) => {
    return createLinkField(
        {
            ...createCallbackField(
                id,
                "",
                constants.STRING_TYPE,
                (value) => <Icon />
            ),
            icon: Icon,
            title,
            isAction: isAction ? isAction : false,
        },
        link,
        condition,
        important,
        orderBy
    )
}

export const createObjectStatusField = (field, variant, formatCallback) => {
    return {
        ...field,
        variant: variant,
        formatCallback: formatCallback,
    }
}

export const createFacetField = (facet, options) => {
    return {
        facet: facet,
        options: options,
    };
};

export const createDataSetFacet = (title, fields, condition) => {
    return createFacetField("dataset", {
        title: title,
        fields: fields,
        condition: condition,
    })
}

export const createFacetsContainer = (fields, condition) => {
    return createFacetField("container", {
        fields: fields,
        condition: condition,
    })
}

export const createFormFieldOption = (value, label) => {
    return { value: value, label: label };
}

export const createFormFieldOptions = (field, options) => {
    return {
        ...field,
        options: options.map((option) =>
            createFormFieldOption(option[0], option[1])
        ),
    }
}

export const createSelectFormField = (id, label, options, condition, required, multiple = false) => {
    return {
        ...createField(id, label, constants.SELECT_TYPE, null, condition),
        options: options,
        required: required,
        multiple: multiple
    }
}

export const createRangeDatesField = (id, label, referenceField, isMinField, condition) => {
    return {
        ...createField(id, label, constants.RANGE_DATES_TYPE, null, condition),
        referenceField: referenceField,
        isMinField: isMinField
    }
}

export const createTreeViewField = (id, label, options, expandMode, multiple, valueMapper, condition) => {
    return {
        ...createField(id, label, "treeview", null, condition),
        options: options,
        expandMode: expandMode ?? "expand_all",
        multiple: multiple,
        valueMapper: valueMapper
    }
}

export const createAsyncTreeViewField = (id, label, optionsFetcher, expandMode, multiple, valueMapper, condition) => {
    return {
        ...createField(id, label, "async_treeview", null, condition),
        optionsFetcher: optionsFetcher,
        expandMode: expandMode ?? "expand_all",
        multiple: multiple,
        valueMapper: valueMapper
    }
}

export const createAsyncSelectFormField = (id, label, optionsFetcher, multiple, condition, valueMapper, required, important) => {
    return {
        ...createField(id, label, "async_select", null, condition, important),
        optionsFetcher: optionsFetcher,
        multiple: multiple,
        valueMapper: valueMapper,
        required: required
    }
}

export const createEditableListFormField = (id, label, itemLabel, addContentTitle, addContentHandler, allowSort, allowDelete, condition, valueMapper, fields) => {
    return {
        ...createField(id, label, "editable_list", null, condition),
        facet: constants.FACET_TYPE_ARRAY,
        itemLabel: itemLabel,
        valueMapper: valueMapper,
        addContentTitle: addContentTitle,
        addContent: addContentHandler,
        allowSort: allowSort,
        allowDelete: allowDelete,
        fields: fields
    }
}

export const createAutocompleteFormField = (id, label, optionsFetcher) => {
    return {
        ...createField(id, label, "autocomplete"),
        optionsFetcher: optionsFetcher,
    }
}

export const createBooleanFormField = (t, id, label, condition, allOption = true, defaultValue) => {
    const options = allOption ? [createFormFieldOption("", t("table.all"))] : []

    return {
        ...createField(id, label, constants.SELECT_TYPE, null, condition),
        options: [
            ...options,
            createFormFieldOption(1, t("common.yes")),
            createFormFieldOption(0, t("common.no")),
        ],
        defaultValue: defaultValue || null
    }
}

export const createDependentFormField = (
    id,
    label,
    component,
    valuesFetcher,
    condition
) => {
    return {
        ...createField(id, label, constants.DEPENDENT_TYPE, null, condition),
        component: component,
        valuesFetcher: valuesFetcher,
        optionsFetcher: valuesFetcher
    }
}

export const createPasswordFormField = (id, label, repeatId, repeatLabel, useStrength, condition) => {
    return {
        ...createField(id, label, constants.PASSWORD_TYPE, null, condition),
        repeatId: repeatId,
        repeatLabel: repeatLabel,
        useStrength: useStrength
    }
}

export const createTextAreaFormField = (id, label, rows, condition) => {
    return {
        ...createField(id, label, constants.TEXT_TYPE, null, condition),
        rows: rows
    }
}

export const createPickupFormField = (id, label, pickupData, condition) => {
    return {
        ...createField(id, label, constants.PICKUP_TYPE, null, condition),
        pickupData: pickupData,
    }
}

export const createHtmlFormField = (id, label, condition, onValuesUpdate) => {
    return {
        ...createField(id, label, constants.HTML_TYPE, null, condition),
        onValuesUpdate: onValuesUpdate
    }
}

export const createAddressFormField = (id, label, required, condition) => {
    return {
        ...createField(id, label, constants.ADDRESS_TYPE, null, condition),
        required: required,
        defaultValue: {
            address_type: { id: "" },
            address: "",
            postal_code: "",
            locality: "",
            state: "",
            country: { id: "" },
            phone: "",
            email: "",
            phone_alternative: "",
            fax: "",
        },
    }
}

export const createContactFormField = (id, label, type, showFields, copyAddresFrom, required, condition, onCopyAddressChange) => {
    return {
        ...createField(id, label, "contact", null, condition),
        required: required,
        contact_type: type,
        showFields: showFields,
        copyAddresFrom: copyAddresFrom,
        onCopyAddressChange: onCopyAddressChange,
        defaultValue: {
            legal_person_type: "",
            contact_method: "",
            name: "",
            surname: "",
            notify_closure: "",
            restrict_calls: "",
            title: "",
            department: "",
            contact_details: {
                address_type: { id: "" },
                address: "",
                postal_code: "",
                locality: "",
                state: "",
                country: { id: "" },
            },
        },
    }
}

export const createDialogFormField = (id, label, multiple, table, valueFormatter, condition, important) => {
    return {
        ...createField(id, label, constants.VALUE_SELECTOR_DIALOG_TYPE, null, condition, important),
        multiple: multiple,
        table: table,
        valueFormatter: valueFormatter,
    }
}

export const createFormFieldGroup = (fields, condition) => {
    return {
        facet: "fieldgroup",
        options: {
            fields: fields,
            condition: condition
        },
    }
}

export const createTabBarItem = (name, content, fetcher, condition) => {
    return {
        name: name,
        content: content,
        fetcher: fetcher,
        condition: condition,
    }
}

export const createExportField = (id, name, mapping, callback) => {
    let result = {
        key: id,
        name: name,
    }

    if (mapping) {
        callback = (entity) => {
            const value = get(entity, id)
            return !value ? null : get(mapping, value)
        }
    }

    if (isFunction(callback)) {
        result["callback"] = callback
    }

    return result
};

export const createFormFieldset = (title, fields, condition) => {
    return {
        facet: "fieldset",
        options: {
            title: title,
            fields: fields,
            condition: condition
        },
    }
}

export const languageList = () => { return ["deu", "eng", "fra", "ita", "nld", "pol", "rus", "spa"] }

export const languagesCode2 = () => { return ["de", "en", "fr", "it", "nl", "pl", "ru", "es"] }

/**
 * 
 * @param {*} t 
 * @param {array} languages 
 * @param {string} code2 true if the mapping should be code2 => label
 * @returns 
 */
export const createLanguagesMapping = (t, languages, code2) => {
    return (languages || languageList()).reduce((acc, lang) => {
        if (code2) {
            var code3Lang = null
            switch (lang) {
                case "en":
                case "eng":
                    code3Lang = "eng"
                    break
                case "fr":
                case "fra":
                    code3Lang = "fra"
                    break
                case "nl":
                case "nld":
                    code3Lang = "nld"
                    break
                case "de":
                case "deu":
                    code3Lang = "deu"
                    break
                case "es":
                case "spa":
                    code3Lang = "spa"
                    break
                case "it":
                case "ita":
                    code3Lang = "ita"
                    break
                case "pl":
                case "pol":
                    code3Lang = "pol"
                    break
                case "ru":
                case "rus":
                    code3Lang = "rus"
                    break
                default:
            }

            acc[lang] = t(`common.languages.${code3Lang}`)
        } else {
            acc[lang] = t(`common.languages.${lang}`)
        }
        return acc
    }, {})
}

export const createCommonStateMapping = (t) => {
    return {
        active: t("common.states.active"),
        inactive: t("common.states.inactive"),
    }
}

export const createPaymentMethodMapping = (t) => {
    return {
        credit: t("bookings.constants.payment_method.credit"),
        cash: t("bookings.constants.payment_method.cash"),
        redsys: t("bookings.constants.payment_method.credit_card"),
        credit_card: t("bookings.constants.payment_method.credit_card"),
        paypal: t("bookings.constants.payment_method.paypal"),
        ticket_office: t("bookings.constants.payment_method.ticket_office"),
        deferred: t("bookings.constants.payment_method.deferred"),
        prepaid: t("bookings.constants.payment_method.deferred"),
        pos_terminal: t("bookings.constants.payment_method.pos_terminal"),
        ingenico: t("bookings.constants.payment_method.ingenico"),
        universalpay: t("bookings.constants.payment_method.universalpay"),
        free: t("bookings.constants.payment_method.free"),
        paygold: t("bookings.constants.payment_method.paygold"),
        oneclick: t("bookings.constants.payment_method.oneclick"),
    }
}

export const createBookingStateMapping = (t) => {
    return {
        valid: t("bookings.constants.state.valid"),
        invalid: t("bookings.constants.state.invalid"),
        confirmation_pending: t("bookings.constants.state.confirmation_pending"),
        timeout: t("bookings.constants.state.timeout"),
        refund_requested: t("bookings.constants.state.refund_requested"),
        cancelled: t("bookings.constants.state.cancelled"),
    }
}

export const createLiquidationStateMapping = (t) => {
    return {
        created: t("liquidations.constants.state.created"),
        invoice_pending: t("liquidations.constants.state.invoice_pending"),
        invoice_paid: t("liquidations.constants.state.invoice_paid"),
    }
}

export const createInvoiceStateMapping = (t, type) => {
    const key = `invoices.constants.state.${type}`
    return {
        issued: t(key + ".issued"),
        paid: t(key + ".paid"),
        payment_pending: t(key + ".payment_pending"),
        refunded: t(key + ".refunded"),
    }
}

export const createPersonTypeMapping = (t) => {
    return {
        person: t("common.fields.contact_type.person"),
        business: t("common.fields.contact_type.business"),
    }
}

export const createLegalPersonTypeMapping = (t) => {
    return {
        "self_employed": t("common.fields.legal_person_type.self_employed"),
        "company": t("common.fields.legal_person_type.company"),
        "other": t("common.fields.legal_person_type.other")
    }
}

export const vatNumberTypeMapping = (t) => {
    return {
        nif: t("common.fields.vat_type.nif"),
        passport: t("common.fields.vat_type.passport"),
        document_country: t("common.fields.vat_type.document_country"),
        document_residence: t("common.fields.vat_type.document_residence"),
        document_other: t("common.fields.vat_type.document_other"),
    }
}

export const createSuggestionTypeMapping = (t) => {
    return {
        suggestion: t("suggestions.constants.suggestion_type.suggestion"),
        complaint: t("suggestions.constants.suggestion_type.complaint"),
    }
}

export const billingTypeMapping = (t) => {
    return {
        1: t("bookings.fields.order.billing_type.billing"),
        2: t("bookings.fields.order.billing_type.liquidation")
    }
}

export const discountCodesTypeMapping = (t) => {
    return {
        "percentage": t("discount_codes.fields.type.percentage"),
        "fixed": t("discount_codes.fields.type.value")
    }
}

export const typesClientsSelectMapping = (t) => {
    return {
        "person": t("billing_client_create_invoice_widget.popup_select_client.types_client.person"),
        "business": t("billing_client_create_invoice_widget.popup_select_client.types_client.business")
    }
}


export const notificationTemplateTypesMapping = (t) => {
    return {
        "alert": t("notification_templates.constants.alert"),
        "close": t("notification_templates.constants.close"),
        "open": t("notification_templates.constants.open")
    }
}

export const recipientTypesMapping = (t) => {
    return {
        "booking": t("notification_templates.constants.booking"),
        "collaborator": t("notification_templates.constants.collaborator"),
        "guide": t("notification_templates.constants.guide")
    }
}

export const notificationStatesMapping = (t) => {
    return {
        "edition": t("notifications.constants.edition"),
        "ready": t("notifications.constants.ready"),
        "pending": t("notifications.constants.pending"),
        "cancelled": t("notifications.constants.cancelled"),
        "finished": t("notifications.constants.finished"),
    }
}

export const sendMethodMapping = (t) => {
    return {
        "sms": "sms",
        "email": "email",
        "recipient": "recipient"
    }
}

export const createIntermediaryStateMapping = (t) => {
    return {
        "pending_validate": t("intermediaries.constants.state.pending_validate"),
        "validated": t("intermediaries.constants.state.validated"),
        "active": t("common.states.active"),
        "inactive": t("common.states.inactive"),
        "locked": t("intermediaries.constants.state.locked")
    }
}

export const createIntermediaryInvoicesStateMapping = (t) => {
    return {
        active: t("common.states.active"),
        inactive: t("common.states.inactive"),
        locked: t("intermediaries.constants.state.locked")
    }
}

