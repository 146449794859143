import { useTranslation } from 'react-i18next';
import constants from '../../../../../components/entity/constants';
import { createCurrencyField, createField, createPaymentMethodMapping } from '../../../../../components/helpers/fieldHelper';
import ArrayDataTable from '../../../../../components/Datatable/ArrayDataTable';

const BookingPaymentTransactions = (props) => {
    const { t } = useTranslation("vbms")

    const columns = [
        createField(
            "id",
            t("bookings.fields.payment_transactions.id"),
            constants.STRING_TYPE
        ),
        createField(
            "tipo",
            t("bookings.fields.payment_transactions.type"),
            constants.STRING_TYPE
        ),
        createField(
            "estado",
            t("bookings.fields.payment_transactions.status"),
            constants.STRING_TYPE
        ),
        createCurrencyField(
            createField(
                "amount",
                t("bookings.fields.payment_transactions.amount"),
                constants.CURRENCY_TYPE
            ),
            "currency_code",
            "main_currency.currency_code",
            "main_currency.exchange_rate"
        ),
        createField(
            "payment_method",
            t("bookings.fields.payment_transactions.payment_method"),
            constants.STRING_TYPE,
            createPaymentMethodMapping(t)
        ),
        createField(
            "created",
            t("bookings.fields.payment_transactions.created"),
            constants.DATETIME_TYPE
        ),
        createField(
            "modified",
            t("bookings.fields.payment_transactions.modified"),
            constants.DATETIME_TYPE
        ),
        createField(
            "message",
            t("bookings.fields.payment_transactions.message"),
            constants.STRING_TYPE
        ),
    ]

    return (
        <ArrayDataTable
            data={props.data}
            columns={columns}
        />
    )
}

export default BookingPaymentTransactions