import { useTranslation } from "react-i18next"
import { useVolcanoApiClient } from "../../../context/VolcanoApiClientProvider"
import CollectionPage from "../../../layout/templates/CollectionPage/CollectionPage"
import { getTableFilter } from "./SuggestionsCollection.filter"
import { getTableColumns } from "./SuggestionsCollection.columns"
import { getTableActions } from "./SuggestionsCollection.actions"
import { useVolcanoAuth } from "../../../context/VolcanoAuthContext"
import { useCache } from "../../../context/cache.context"
import constants from "../../../components/entity/constants"
import _set from "lodash/set"
import _get from "lodash/get"
import _isDate from "lodash/isDate"
import { format as formatDate } from "date-fns"

const SuggestionsCollectionPage = () => {
    const { t } = useTranslation("vbms")
    const { apiClient } = useVolcanoApiClient()
    const volcanoAuth = useVolcanoAuth()
    const cache = useCache()

    const filterConfig = getTableFilter(t, apiClient, volcanoAuth.user, cache)

    const table = {
        columns: getTableColumns(t),
        actions: getTableActions(apiClient, t),
        highlight: (suggestion) => suggestion.suggestion_type === "complaint" ? "background.error.main" : null,
        fetcher: (params) => {
            filterConfig
                .filter(field => field.type === constants.DATE_TYPE)
                .forEach(field => {
                    const date = _get(params, field.id)
                    if (_isDate(date)) {
                        _set(params, field.id, formatDate(date, "yyyy-MM-dd"))
                    }
                })

            return apiClient.suggestion.getSuggestions({
                sort: "created",
                sort_direction: "desc",
                ...params
            })
        }
    }

    return (
        <CollectionPage
            title={t("suggestions.title")}
            subtitle={t("suggestions.subtitle")}
            table={table}
            filterConfig={filterConfig}
        />
    )
}

export default SuggestionsCollectionPage
