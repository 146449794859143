import { createTabBarItem } from '../../../components/helpers/fieldHelper';
import UserRoles from './components/tabs/UserRoles';
import UserSales from './components/tabs/UserSales';

export const getTabNavigationItems = (t) => [
    createTabBarItem(
        t("settings_system_users.view.navbar.roles"),
        UserRoles
    ),
    createTabBarItem(
        t("settings_system_users.view.navbar.sales"),
        UserSales,
        null,
        (user) => user.hasOwnProperty('salesman')
    ),
];