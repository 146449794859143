
import { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { Box, Button, Stack } from "@mui/material"
import _isEmpty from "lodash/isEmpty"
import _get from "lodash/get"
import _isFunction from "lodash/isFunction"
import DataContainer from '../../../Display/DataContainer';
import PaymentGatewaysSelector from "../../PaymentGatewaysSelector/PaymentGatewaysSelector"
import BookingSummary from "./BookingSummary"
import { getCurrencyLocale } from '../../../../lib/utils';


/**
 * Check if cart is ready to be confirmed.
 * 
 * @param {*} selection 
 * @param {*} paymentGateway 
 * @returns 
 */
const checkIsCartReady = (selection, paymentGateway) => {
    return selection.step === 5 && !_isEmpty(_get(selection, "cart.line_items")) && !_isEmpty(_get(selection, "client"), !_isEmpty(paymentGateway))
}

/**
 * Returns the confirm button message key. 
 * @param {*} paymentGateway 
 * @param {*} total 
 * @returns 
 */
const getConfirmButtonMessage = (paymentGateway, total) => {
    if (!paymentGateway ||
        paymentGateway.payment_method === "credit" ||
        total === 0) {
        return "booking_widget.summary.confirm"
    }

    return "booking_widget.summary.pay"
}

const BookingWidgetSummary = ({ selection, cartOptions, paymentGateways, pickupData, onConfirm }) => {
    const { t, i18n } = useTranslation("vbms");
    const [paymentGateway, setPaymentGateway] = useState(null)
    const { rates } = selection || {}

    useEffect(() => {
        if (selection.payment_gateway) {
            setPaymentGateway(selection.payment_gateway)
        }
    }, [selection.payment_gateway])

    const priceFormatter = new Intl.NumberFormat(getCurrencyLocale(i18n.language), {
        style: "currency",
        currency: _get(selection, "cart.currency", "eur"),
    })

    const getTotalAmount = (key) => {
        let total = 0
        rates.forEach((selRate) => {
            total += selRate[key] * selRate.qty
        })

        return total
    }

    return (
        <>
            <Stack spacing={2}>
                <BookingSummary
                    selection={selection}
                    pickupData={!_get(cartOptions, "pickupData.ignore", false) ? cartOptions.pickupData : null}
                />
                {checkIsCartReady(selection, paymentGateway) && (
                    <Box sx={{ '& .MuiTypography-body1': { textAlign: "center" } }}>
                        {cartOptions.paymentGateways.length > 1 && (
                            <DataContainer
                                title={t("booking_widget.summary.payment_gateways")}
                                titleVariant="default"
                                titleLevel="body1"
                            >
                                <PaymentGatewaysSelector
                                    paymentGateways={cartOptions.paymentGateways}
                                    selection={paymentGateway}
                                    onChange={(values) => setPaymentGateway(values)}
                                />
                            </DataContainer>)
                        }
                        <Box display="flex" justifyContent="center">
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={() => onConfirm(paymentGateway)}
                                disabled={cartOptions.paymentGateways.length > 1 && paymentGateway == null}
                                sx={{ marginTop: 1 }}
                            >
                                {t(getConfirmButtonMessage(paymentGateway, getTotalAmount("pvp")))}
                            </Button>
                        </Box>
                    </Box>
                )}
            </Stack>
        </>
    )
}

export default BookingWidgetSummary
