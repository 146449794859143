import { useCache } from '../../../context/cache.context';
import { enterprisesFetcher } from '../../../lib/form-options-fetchers';
import { useCallback, useEffect, useState } from 'react';
import { useVolcanoApiClient } from '../../../context/VolcanoApiClientProvider';
import { useTranslation } from 'react-i18next';
import { useVolcanoAuth } from '../../../context/VolcanoAuthContext';
import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';

const EnterprisesSelector = ({ onSelection }) => {
    const { t } = useTranslation()
    const { apiClient } = useVolcanoApiClient()
    const volcanoAuth = useVolcanoAuth()
    const cache = useCache()
    const [pending, setPending] = useState(true)
    const [selection, setSelection] = useState(volcanoAuth.user.corporate_account.enterprise.id)

    const [field, setField] = useState({
        id: 'enterprise_id',
        label: t("billing_clients.fields.enterprise"),
        required: false,
        options: []
    })

    const handleOnChange = useCallback((value) => {
        setSelection(value)
        onSelection(value)
    }, [onSelection])

    useEffect(() => {
        if (pending && !volcanoAuth.hasOwnProperty('crm_intermediary')) {
            enterprisesFetcher(cache, apiClient, t, false).then((options) => {
                setField((prev) => ({
                    ...prev,
                    options: options
                }))
                setPending(false)
            })
        }
    }, [volcanoAuth, cache, apiClient, t, field, pending])

    return (
        <>
            {!pending && field.options.length > 0 && <FormControl required={field.required} fullWidth>
                <InputLabel id={field.id} htmlFor={field.id} shrink>{t(field.label)}</InputLabel>
                <Select
                    labelId={field.id}
                    id={field.id}
                    value={selection}
                    label={t(field.label)}
                    onChange={(e) => handleOnChange(e.target.value)}
                    displayEmpty
                    size="small"
                    notched={true}
                >
                    {field.options.map((option, index) => {
                        return <MenuItem key={index} value={option.value}>{option.label}</MenuItem>
                    })}
                </Select>
            </FormControl>}
        </>
    )
}

export default EnterprisesSelector