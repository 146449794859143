import { useTranslation } from "react-i18next"
import { useVolcanoApiClient } from "../../../context/VolcanoApiClientProvider"
import { useParams, useNavigate } from 'react-router-dom'
import AsyncEntityPage from "../../../layout/templates/AsyncContentPage/AsyncEntityPage"
import { useVolcanoAuth } from '../../../context/VolcanoAuthContext'
import constants from '../../../components/entity/constants';
import { createCallbackField } from '../../../components/helpers/fieldHelper';
import { getActions } from "./BillingClientPage.actions"
import { getHeaderContent } from "./BillingClientPage.header"
import { getTabNavigationItems } from './BillingClientPage.tabs'
import { billingClientFetcher } from '../BillingClients.functions';

const BillingClientPage = (props) => {
    const { t } = useTranslation()
    const { entityId } = useParams()
    const { apiClient } = useVolcanoApiClient()
    const volcanoAuth = useVolcanoAuth()
    const navigate = useNavigate()

    return (
        <AsyncEntityPage
            fetcher={() => billingClientFetcher(apiClient, entityId)}
            title={createCallbackField(
                "vat_number",
                t("billing_clients.view.title"),
                constants.STRING_TYPE,
                (value) => t("billing_clients.view.title", { vat_number: value })
            )}
            headerContent={getHeaderContent(t, volcanoAuth.user)}
            actions={getActions(apiClient, entityId, t, navigate, volcanoAuth.user)}
            tabNavigationItems={getTabNavigationItems(volcanoAuth.user, apiClient, t)}
        />
    )
}

export default BillingClientPage