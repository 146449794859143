import { useCallback } from "react"
import { Form, Formik } from "formik"
import { useTranslation } from "react-i18next"
import DataWrapper from "../../../Display/DataWrapper"
import _get from "lodash/get"
import _isArray from "lodash/isArray"
import { createField, createFormField, createLanguagesMapping, createSelectFormField, createTextAreaFormField, createPickupFormField, createBooleanFormField } from "../../../helpers/fieldHelper"
import FormFieldset from "../../../form/FormFieldset"
import { Box, Button, Grid, Stack } from "@mui/material"
import { validateParticipants } from "../../../../lib/booking-management"
import { useEffect } from "react"

const availableFormFields = [
    "first_name",
    "last_name",
    "email",
    "phone",
    "nif",
    "notes",
    "observations",
    "language",
    "voucher_id",
    "send_notification"
];

const defaultValues = {
    clientValue: {
        first_name: "",
        last_name: "",
        email: "",
        phone: "",
        language: "spa",
        voucher_id: "",
        notes: "",
        send_notification: true,
    },
    participantValue: {
        rate: "",
        first_name: "",
        last_name: "",
        nif: "",
    },
    pickupValue: {
        lodgin_id: null,
        pickup_point_id: null,
        observations: null,
    },
}

const processPickupField = (pickupData) => {
    const result = []

    result.push(
        createFormField(
            createPickupFormField(
                "pickup",
                "",
                pickupData
            ),
            true
        )
    )

    return result
}

const processFormFields = (t, prefix, fields) => {
    let result = []

    availableFormFields.forEach((field, index) => {
        if (fields[field]) {
            let formField = null

            if (field === "language") {
                formField = createFormField(
                    createSelectFormField(
                        `${prefix}.${field}`,
                        t("bookings.fields.order.language"),
                        Object.entries(createLanguagesMapping(t, fields[field].options)).map(([key, value]) => (
                            {
                                value: key,
                                label: value
                            }
                        ))
                    ),
                    fields[field].required
                )
            } else if (field === "observations") {
                formField = createFormField(
                    createTextAreaFormField(
                        `${prefix}.${field}`,
                        t(`bookings.fields.order.customer.${field}`),
                        5
                    ),
                    fields[field].required
                )
            } else {
                const label = field === "voucher_id" ? t("bookings.fields.order.voucher") : t(`bookings.fields.order.customer.${field}`)
                formField = createFormField(
                    createField(
                        `${prefix}.${field}`,
                        label,
                        fields[field].type
                    ),
                    fields[field].required
                )

                // check field restrictions (max_length)
                if (fields[field]?.max_length) {
                    formField.max_length = fields[field]?.max_length
                }
            }

            result.push(formField)
        }
    })

    return result
}

const initParticipantsByLineitem = (lineItems, participants, defaultParticipants) => {
    if (_isArray(lineItems)) {
        return participants.map((participant) => {
            const lineItem = lineItems.find(
                (lineItem) => lineItem.id === participant.line_item_id
            )

            const defaultLineItemParticipants = defaultParticipants && defaultParticipants.find(
                (participant) => participant.line_item_id === lineItem.id
            )

            let subindex = 0;
            return {
                line_item_id: lineItem.id,
                participants: lineItem.rates
                    .map((rate) => {
                        let rateParticipants = []
                        for (let i = 0; i < rate.qty; i++, subindex++) {
                            const participantKey = rate.rate.id + "-" + subindex
                            let participantData = {
                                ...Object.fromEntries(
                                    Object.keys(participant.form).map((field) => [field, ""])
                                ),
                                rate: participantKey,
                            }

                            if (defaultLineItemParticipants) {
                                const defaultParticipant = defaultLineItemParticipants.participants.find(
                                    (participant) => participant.rate === participantKey
                                );
                                if (defaultParticipant) {
                                    participantData = {
                                        ...participantData,
                                        ...defaultParticipant,
                                    }
                                }
                            }
                            rateParticipants.push(participantData)
                        }

                        return rateParticipants
                    })
                    .flat(),
            }
        })
    }

    return []
}

const initParticipants = (cart, defaultParticipants) => {
    const participants = _get(cart, "client.participants");
    const lineItems = _get(cart, "line_items");

    if (_isArray(participants)) {
        return initParticipantsByLineitem(lineItems, participants, defaultParticipants);
    }

    return [];
};

const createInitialValues = (cart, client, participants, pickup) => {
    return {
        client: {
            ...defaultValues.clientValue,
            ...client || {},
        },
        participants: initParticipants(cart, participants),
        pickup: {
            ...defaultValues.pickupValue,
            ...pickup || {},
        }
    }
}

const ClientForm = ({ cart, defaultClient, defaultParticipants, defaultPickup, pickupData, onConfirm }) => {
    const { t } = useTranslation("vbms")

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    // validate participants 
    const formValidation = useCallback((values) => {
        return validateParticipants(_get(values, 'participants', []), 'participants', 'nif', false, t)
    }, [t])

    if (!cart) {
        return null;
    }

    const initialValues = createInitialValues(cart, defaultClient, defaultParticipants, defaultPickup)

    let subindex = 0;
    const participants =
        initialValues.participants.length > 0 &&
        cart.line_items[0].rates
            .map((rate) => {
                let rateParticipants = [];

                for (let i = 0; i < rate.qty; i++, subindex++) {
                    rateParticipants.push({
                        index: subindex + 1,
                        fields: processFormFields(
                            t,
                            `participants[0].participants[${subindex}]`,
                            cart.client.participants[0].form
                        ),
                        rateName: rate.rate.rate_elements
                            .sort((ct1, ct2) => (ct1.type === 5 ? -1 : 1))
                            .reduce((names, ct) => {
                                names.push(ct.name);
                                return names;
                            }, [])
                            .join(", "),
                    });
                }

                return rateParticipants;
            })
            .flat()

    return (
        <Formik
            enableReinitialize
            initialValues={initialValues}
            validate={formValidation}
            validateOnChange={true}
            validateOnBlur={false}
            validateOnMount={true}
            onSubmit={onConfirm}
        >
            {({ isValid, submitForm, isSubmitting, }) => {
                return (
                    <Form                    >
                        <Stack spacing={2} sx={{
                            '& .MuiFormControl-root': { width: { xs: '100%', md: '100%' } },
                            '& .FormFieldset-root > h3': { fontSize: "0.875rem" },
                        }}>
                            <DataWrapper title={t("booking_widget.steps.client.card_client_title")}>
                                <FormFieldset
                                    key="clientForm"
                                    fields={processFormFields(t, "client", cart.client.form)}
                                />
                            </DataWrapper>
                            {participants.length > 0 &&
                                <DataWrapper title={t("booking_widget.steps.client.card_participants_title")}>
                                    <Grid container spacing={2}>
                                        {participants.length > 0 &&
                                            participants.map((participant, index) => {
                                                return (
                                                    <Grid key={index} item xs={12} md={6}>
                                                        <FormFieldset
                                                            title={t("booking_widget.steps.client.participant", {
                                                                count: participant.index,
                                                                rate_name: participant.rateName,
                                                            })}
                                                            fields={participant.fields}
                                                        />
                                                    </Grid>
                                                );
                                            })}
                                    </Grid>
                                </DataWrapper>}

                            {pickupData &&
                                <DataWrapper title={t("booking_widget.steps.client.pickup.card_title")}>
                                    <FormFieldset
                                        key="pickupForm"
                                        fields={processPickupField(pickupData)}
                                    />
                                </DataWrapper>
                            }
                            <Box display="flex" justifyContent="center">
                                <Button
                                    variant="contained"
                                    color="primary"
                                    type="submit"
                                    disabled={isSubmitting || !isValid}
                                >
                                    {t("booking_widget.summary.continue")}
                                </Button>
                            </Box>
                        </Stack>
                    </Form>
                )
            }}
        </Formik>
    )
}

export default ClientForm