import { useEffect, useState, useCallback } from "react"
import { useTranslation } from "react-i18next"
import { Box, Button, ButtonGroup, CardActions, CardContent, IconButton, InputAdornment, Stack, TextField, Typography } from "@mui/material"
import WarningAmberOutlinedIcon from '@mui/icons-material/WarningAmberOutlined'
import { useVolcanoAuth } from "../../context/VolcanoAuthContext"
import { useVolcanoApiClient } from "../../context/VolcanoApiClientProvider"
import { useNavigate, useParams } from "react-router-dom"
import LoadingButton from "../form/LoadingButton"
import availableRoutes from "../../routes/availableRoutes"
import { Visibility, VisibilityOff } from "@mui/icons-material"

const LOGIN_CARD_STATE_LOGIN_INITIAL = 0
const LOGIN_CARD_STATE_LOGIN_INVALID_CREDENTIALS = 1
const LOGIN_CARD_STATE_RESET_PASSWORD_INITIAL = 2
const LOGIN_CARD_STATE_RESET_PASSWORD_ERROR = 3
const LOGIN_CARD_STATE_RESET_PASSWORD_SENT = 4
const LOGIN_CARD_STATE_RESET_PASSWORD_CHANGE = 5
const LOGIN_CARD_STATE_RESET_PASSWORD_CHANGE_ERROR = 6
const LOGIN_CARD_STATE_RESET_PASSWORD_CHANGED = 7
const LOGIN_CARD_STATE_LOGIN_ACCESS_FORBIDDEN = 8

const LOGIN_FORM_MSG_TYPE_INVALID_CREDENTIALS = 'invalidCredentials'
const LOGIN_FORM_MSG_TYPE_ACCESS_FORBIDDEN = 'accessForbidden'
const LOGIN_FORM_MSG_TYPE_RESET_SENT = 'resetSent'
const LOGIN_FORM_MSG_TYPE_PWD_CHANGED = 'pwdChanged'

const styles = {
    container: {
        p: 2,
        position: "relative",
        overflow: "hidden",
        background: "rgba(255, 255, 255, 0.6);",
        borderRadius: 4,
        boxShadow: 0,
        "& form": {
            paddingTop: 2,
            "& input:-internal-autofill-selected": {
                backgroundColor: "none !important"
            }
        }
    },
    formInput: {
        marginBottom: "1em"
    }
}

const LanguageSelector = () => {
    const { i18n } = useTranslation('vbms')

    const changeLanguage = (lng) => {
        i18n.changeLanguage(lng)
    }

    return (
        <Box
            sx={{ justifyContent: "center", display: "flex", mt: 1 }}
        >
            <ButtonGroup variant="text" size="small" aria-label="outlined primary button group">
                <Button onClick={() => changeLanguage("es")}>Español</Button>
                <Button onClick={() => changeLanguage("en")}>English</Button>
            </ButtonGroup>
        </Box>
    )
}

const LoginForm = ({ title, msgType, onSubmit, onForgotPassword, onSetSuggestions }) => {
    const { t } = useTranslation("vbms")
    const { siteConfig } = useVolcanoApiClient()
    const [showPassword, setShowPassword] = useState(false)
    const [loading, setLoading] = useState(false)
    const [credentials, setCredentials] = useState({ username: '', password: '' })

    const handleInputChange = (event) => {
        setCredentials({
            ...credentials,
            [event.target.name]: event.target.value
        })
    }

    const handleSubmit = (event) => {
        event.preventDefault()
        setLoading(true)
        onSubmit(credentials)
    }

    const handleGoSuggestions = () => {
        onSetSuggestions()
    }

    useEffect(() => {
        if (msgType === LOGIN_FORM_MSG_TYPE_INVALID_CREDENTIALS || msgType === LOGIN_FORM_MSG_TYPE_ACCESS_FORBIDDEN) {
            setLoading(false)
            setCredentials(prevCredentials => {
                return {
                    ...prevCredentials,
                    password: ''
                }
            })
        }
    }, [msgType])

    return (
        <form autoComplete="off" onSubmit={handleSubmit}>
            <Typography variant="h5" component="h1" align="center">{title}</Typography>
            {(msgType === LOGIN_FORM_MSG_TYPE_RESET_SENT) && <Typography variant="subtitle1" component="h2" align="center" mt={1}>{t("login.signin.resetPasswordSent")}</Typography>}
            {(msgType === LOGIN_FORM_MSG_TYPE_PWD_CHANGED) && <Typography variant="subtitle1" component="h2" align="center" mt={1}>{t("login.signin.resetPasswordChanged")}</Typography>}
            <CardContent>
                <TextField
                    fullWidth
                    required
                    variant="outlined"
                    name="username"
                    label={t("login.signin.username")}
                    sx={styles.formInput}
                    value={credentials.username}
                    error={msgType === LOGIN_FORM_MSG_TYPE_INVALID_CREDENTIALS}
                    onChange={handleInputChange}
                />
                <TextField
                    fullWidth
                    required
                    type={showPassword ? "text" : "password"}
                    variant="outlined"
                    name="password"
                    label={t("login.signin.password")}
                    sx={styles.formInput}
                    value={credentials.password}
                    error={msgType === LOGIN_FORM_MSG_TYPE_INVALID_CREDENTIALS}
                    InputProps={{
                        endAdornment:
                            <InputAdornment position="end">

                                <IconButton
                                    onClick={() => setShowPassword((show) => !show)}
                                >
                                    {showPassword ? <Visibility /> : <VisibilityOff />}
                                </IconButton>

                            </InputAdornment>
                    }}
                    onChange={handleInputChange}
                />
                {(msgType === LOGIN_FORM_MSG_TYPE_INVALID_CREDENTIALS || msgType === LOGIN_FORM_MSG_TYPE_ACCESS_FORBIDDEN) && (
                    <Stack direction="row" justifyContent="center" alignItems="center" gap={1} mt={1}>
                        <WarningAmberOutlinedIcon color="error" />
                        <Typography
                            variant="subtitle1"
                            component="h2"
                            align="center"
                            color="error"
                        >
                            {msgType === LOGIN_FORM_MSG_TYPE_INVALID_CREDENTIALS ? t("login.signin.invalidCredentials") : t("login.signin.accessForbidden")}
                        </Typography>
                    </Stack>
                )}
            </CardContent>
            <CardActions sx={{ justifyContent: "space-between" }}>
                <Stack direction="column" spacing={0}>
                    <Button
                        variant="text"
                        size="small"
                        sx={{ justifyContent: 'start' }}
                        onClick={onForgotPassword}
                    >
                        {t("login.signin.forgotPassword")}
                    </Button>
                    {siteConfig.show_suggestion_link &&
                        <Button
                            variant="text"
                            size="small"
                            onClick={handleGoSuggestions}
                        >
                            {t("suggestions.actions.send.button")}
                        </Button>
                    }
                </Stack>
                <LoadingButton
                    type="submit"
                    variant="contained"
                    color="primary"
                    loading={loading}
                >
                    {t("login.signin.logIn")}
                </LoadingButton>
            </CardActions>
            <LanguageSelector />
        </form >
    )
}

const ResetPassword = ({ error, onSubmit }) => {
    const { t } = useTranslation("vbms")
    const [data, setData] = useState({ email: '' })
    const [err, setErr] = useState('')

    const handleInputChange = (event) => {
        setData({
            ...data,
            [event.target.name]: event.target.value.trim()
        })
    }

    const handleSubmit = (event) => {
        event.preventDefault()

        if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.\w+$/i.test(data.email)) {
            setErr(t("login.resetPassword.emailError"))
        } else {
            setErr('')
            onSubmit(data)
        }
    }

    return (
        <form autoComplete="off" onSubmit={handleSubmit}>
            <Typography variant="h5" component="h1" align="center">{t("login.resetPassword.title")}</Typography>
            <Typography variant="subtitle1" component="h2" align="center" mt={1}>{t("login.resetPassword.subtitle")}</Typography>
            {error && (
                <Stack direction="row" justifyContent="center" alignItems="center" gap={1} mt={1}>
                    <WarningAmberOutlinedIcon />
                    <Typography variant="subtitle1" component="h2" align="center">
                        {t("login.resetPassword.error")}
                    </Typography>
                </Stack>
            )}
            <CardContent>
                <TextField
                    fullWidth
                    required
                    variant="outlined"
                    name="email"
                    label={t("login.resetPassword.email")}
                    helperText={err ? err : t("login.resetPassword.emailHelper")}
                    error={err}
                    sx={styles.formInput}
                    value={data.email}
                    onChange={handleInputChange}
                />
            </CardContent>
            <CardActions sx={{ justifyContent: "space-between" }}>
                <Button
                    href="/login"
                    variant="text"
                    size="small">
                    {t("common.cancel")}
                </Button>
                <Button type="submit" variant="contained" color="primary">
                    {t("common.submit")}
                </Button>
            </CardActions>
        </form>
    )
}

const ResetPasswordChange = ({ error, onSubmit }) => {
    const { t } = useTranslation("vbms")
    const [data, setData] = useState({ password: '', password_repeat: '' })
    const [err, setErr] = useState('')

    const handleInputChange = (event) => {
        setData({
            ...data,
            [event.target.name]: event.target.value
        })
    }

    const handleSubmit = (event) => {
        event.preventDefault()
        if (data.password !== data.password_repeat) {
            setErr(t("login.resetPasswordChange.passwordRepeatError"))
        } else {
            setErr('')
            onSubmit(data)
        }
    }

    return (
        <form autoComplete="off" onSubmit={handleSubmit}>
            <Typography variant="h5" component="h1" align="center">{t("login.resetPasswordChange.title")}</Typography>
            <Typography variant="subtitle1" component="h2" align="center" mt={1}>{t("login.resetPasswordChange.subtitle")}</Typography>
            {error && (
                <Stack direction="row" justifyContent="center" alignItems="center" gap={1} mt={1}>
                    <WarningAmberOutlinedIcon />
                    <Typography variant="subtitle1" component="h2" align="center">
                        {t("login.resetPasswordChange.error")}
                    </Typography>
                </Stack>
            )}
            <CardContent>
                <TextField
                    fullWidth
                    required
                    type="password"
                    variant="outlined"
                    name="password"
                    label={t("login.resetPasswordChange.password")}
                    helperText={t("login.resetPasswordChange.passwordHelper")}
                    sx={styles.formInput}
                    value={data.password}
                    onChange={handleInputChange}
                />
                <TextField
                    fullWidth
                    required
                    type="password"
                    variant="outlined"
                    name="password_repeat"
                    label={t("login.resetPasswordChange.passwordRepeat")}
                    helperText={err ? err : t("login.resetPasswordChange.passwordRepeatHelper")}
                    error={err}
                    sx={styles.formInput}
                    onChange={handleInputChange}
                />
            </CardContent>
            <CardActions sx={{ justifyContent: "space-between" }}>
                <Button
                    href="/login"
                    variant="text"
                    size="small">
                    {t("common.cancel")}
                </Button>
                <Button type="submit" variant="contained" color="primary">
                    {t("common.submit")}
                </Button>
            </CardActions>
        </form>
    )
}

const LoginCard = ({ title, image, resetPasswordHash, navigationHandler }) => {
    const [state, setState] = useState(resetPasswordHash ? LOGIN_CARD_STATE_RESET_PASSWORD_CHANGE : LOGIN_CARD_STATE_LOGIN_INITIAL)
    const volcanoAuth = useVolcanoAuth()
    const navigate = useNavigate()
    const { redirect } = useParams()

    const handleError = useCallback(() => {
        const invalidCredentialsError = 'Invalid credentials'
        const userNotFoundError = 'Invalid resource User not found'
        const accessForbiddenError = 'Forbidden access'

        switch (state) {
            case LOGIN_CARD_STATE_LOGIN_INITIAL:
            case LOGIN_CARD_STATE_RESET_PASSWORD_CHANGED:
                if (volcanoAuth.error === invalidCredentialsError) {
                    setState(LOGIN_CARD_STATE_LOGIN_INVALID_CREDENTIALS)
                } else if (volcanoAuth.error === accessForbiddenError) {
                    setState(LOGIN_CARD_STATE_LOGIN_ACCESS_FORBIDDEN)
                }
                break
            case LOGIN_CARD_STATE_RESET_PASSWORD_INITIAL:
                if (volcanoAuth.error === userNotFoundError) {
                    setState(LOGIN_CARD_STATE_RESET_PASSWORD_ERROR)
                }
                break
            case LOGIN_CARD_STATE_RESET_PASSWORD_CHANGE:
                if (volcanoAuth.error === userNotFoundError) {
                    setState(LOGIN_CARD_STATE_RESET_PASSWORD_CHANGE_ERROR)
                }
                break
            default:
                break
        }
    }, [state, volcanoAuth.error])

    useEffect(() => {
        if (volcanoAuth.isAuthenticated) {
            if (redirect) {
                navigate(redirect)
            } else if (volcanoAuth.user.isIntermediary() && volcanoAuth.user.allowedRoutes.includes("bookings_add")) {
                navigate(availableRoutes.bookings_add.path)
            } else if (volcanoAuth.user.allowedRoutes.includes("bookings")) {
                navigate(availableRoutes.bookings.path)
            } else {
                navigate("/")
            }
        } else if (volcanoAuth.error !== null) {
            handleError()
        }
    }, [navigate, handleError, redirect, volcanoAuth.isAuthenticated, volcanoAuth.user, volcanoAuth.error])

    const getLoginFormMessageType = useCallback(() => {
        switch (state) {
            case LOGIN_CARD_STATE_LOGIN_INVALID_CREDENTIALS:
                return LOGIN_FORM_MSG_TYPE_INVALID_CREDENTIALS
            case LOGIN_CARD_STATE_LOGIN_ACCESS_FORBIDDEN:
                return LOGIN_FORM_MSG_TYPE_ACCESS_FORBIDDEN
            case LOGIN_CARD_STATE_RESET_PASSWORD_SENT:
                return LOGIN_FORM_MSG_TYPE_RESET_SENT
            case LOGIN_CARD_STATE_RESET_PASSWORD_CHANGED:
                return LOGIN_FORM_MSG_TYPE_PWD_CHANGED
            default:
                return null
        }
    }, [state])

    const loginSubmitHandler = (credentials) => {
        setState(LOGIN_CARD_STATE_LOGIN_INITIAL)
        volcanoAuth.authenticate(credentials)
    }

    const loginForgotPasswordHandler = (event) => {
        setState(LOGIN_CARD_STATE_RESET_PASSWORD_INITIAL)
        event.preventDefault()
    }

    const resetPasswordSubmitHandler = (data) => {
        volcanoAuth.resetPasswordRequest(data.email)
            .then((success) => {
                if (success) {
                    setState(LOGIN_CARD_STATE_RESET_PASSWORD_SENT)
                }
            })
    }

    const setSuggestionsHandler = () => {
        navigate(availableRoutes.suggestions_add.path)
    }

    const resetPasswordChangeSubmitHandler = (data) => {
        volcanoAuth.resetPassword({
            ...data,
            reset_hash: resetPasswordHash
        })
            .then((success) => {
                if (success) {
                    setState(LOGIN_CARD_STATE_RESET_PASSWORD_CHANGED)
                }
            })
    }

    const renderState = (state) => {
        switch (state) {
            case LOGIN_CARD_STATE_LOGIN_INITIAL:
            case LOGIN_CARD_STATE_LOGIN_INVALID_CREDENTIALS:
            case LOGIN_CARD_STATE_LOGIN_ACCESS_FORBIDDEN:
            case LOGIN_CARD_STATE_RESET_PASSWORD_SENT:
            case LOGIN_CARD_STATE_RESET_PASSWORD_CHANGED:
                return <LoginForm
                    title={title}
                    msgType={getLoginFormMessageType()}
                    onSubmit={loginSubmitHandler}
                    onForgotPassword={loginForgotPasswordHandler}
                    onSetSuggestions={setSuggestionsHandler}
                />
            case LOGIN_CARD_STATE_RESET_PASSWORD_INITIAL:
            case LOGIN_CARD_STATE_RESET_PASSWORD_ERROR:
                return <ResetPassword
                    error={state === LOGIN_CARD_STATE_RESET_PASSWORD_ERROR}
                    onSubmit={resetPasswordSubmitHandler}
                />
            case LOGIN_CARD_STATE_RESET_PASSWORD_CHANGE:
            case LOGIN_CARD_STATE_RESET_PASSWORD_CHANGE_ERROR:
                return <ResetPasswordChange
                    error={state === LOGIN_CARD_STATE_RESET_PASSWORD_CHANGE_ERROR}
                    onSubmit={resetPasswordChangeSubmitHandler}
                />
            default:
                return null
        }
    }

    return (
        <>
            {renderState(state)}
        </>
    )
}

export default LoginCard