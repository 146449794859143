import { createExportField } from "../../../../components/helpers/fieldHelper";

export const getTableExport = (apiClient, t) => {
    return (filter, progressHandler) => {
        apiClient.activityManager.pickupConfig.exportPickupConfig(
            [
                createExportField("id", "Id"),
                createExportField("name", t("pickup_configs.fields.name")),
                createExportField("created", t("pickup_configs.fields.created")),
                createExportField("modified", t("pickup_configs.fields.modified")),
            ],
            filter,
            progressHandler
        )
    }
} 