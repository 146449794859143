import { FastField } from "formik"
import ActivityPickupSelector from "./ActivityPickupSelector"
import _isEmpty from 'lodash/isEmpty'
import _get from 'lodash/get'
import { useTranslation } from "react-i18next"
import { FormHelperText } from "@mui/material"

export const validatePickup = (t, required, value) => {

    if (!value || (
        _isEmpty(value.lodgin_id) &&
        _isEmpty(value.pickup_point_id) &&
        _isEmpty(value.observations)
    )) {
        return t("booking_widget.steps.client.pickup.validation")
    }

    if (required &&
        (_isEmpty(value.lodgin_id) || _get(value, "lodgin_id", "UNK") === "UNK") &&
        (_isEmpty(value.pickup_point_id) || _get(value, "pickup_point_id", "UNK") === "UNK")) {
        return t("booking_widget.steps.client.pickup.validation_required")
    }

    if (!_isEmpty(value.observations) && value.observations.length < 20) {
        return t("booking_widget.steps.client.pickup.validation_address")
    }

    return null
}

const ActivityPickupFormField = ({ field, value }) => {
    const { t } = useTranslation("vbms")
    const configField = field

    return (
        <>
            <FastField
                id={field.id}
                name={field.id}
                validate={(value) => validatePickup(t, field.required, value)}

            >
                {({
                    field,
                    form: { touched, errors, setFieldValue, setFieldTouched },
                    meta,
                }) => {
                    return (
                        <>
                            <ActivityPickupSelector
                                pickupData={configField.pickupData}
                                defaultValue={meta.initialValue}
                                error={meta.error && meta.touched}
                                onChange={(value) => {
                                    setFieldValue(field.name, value)
                                    setFieldTouched(field.name, true)
                                }}
                            />
                            {meta.error && meta.touched && <FormHelperText error={true}>{meta.error}</FormHelperText>}
                        </>
                    )
                }}
            </FastField>
        </>
    )
}

export default ActivityPickupFormField