import { useEffect, useState } from "react"
import SelectFormField from "./SelectFormField"
import _has from "lodash/has"

const AsyncSelectFormField = ({ field, value }) => {
    const [isFetching, setIsFetching] = useState(false)
    const [customField, setCustomField] = useState({
        ...field,
        options: []
    })

    useEffect(() => {
        if (_has(field, "options")) {
            setCustomField(
                (prevState) => ({
                    ...prevState,
                    options: field.options
                })
            )

            return
        }

        setIsFetching(true)
        field.optionsFetcher().then((options) => {
            setIsFetching(false)
            setCustomField(
                (prevState) => ({
                    ...prevState,
                    options: options
                })
            )
        })
    }, [])

    return !isFetching && <SelectFormField field={customField} value={value} />
}

export default AsyncSelectFormField