import { Download, Edit, Visibility } from "@mui/icons-material"
import { downloadFile, openInNewTab, getSuggestedForCollaboratorFilename } from "../../../lib/utils"
import ChangeState from "./components/actions/ChangeState"

export const getActions = (apiClient, t, scope, collaborator = false) => [
    {
        title: t("common.open"),
        scope: scope,
        icon: scope === "row" ? Visibility : null,
        onExecute: (invoice, progressHandler, onSuccess, onError) => {
            const data = {
                resource: "invoices",
                operation: "invoice_pdf",
                id: invoice.id,
                params: {
                    title: getSuggestedForCollaboratorFilename(invoice.title, invoice.collaborator, true)
                }
            }
            return apiClient.download.createLink(data)
                .then((link) => {
                    openInNewTab(link.url);
                })
                .catch((error) => {
                    onError(t("common.actions.share.error_open"))
                })
        },
        condition: () => scope === "row",
    },
    {
        title: t("common.download"),
        scope: scope,
        icon: scope === "row" ? Download : null,
        onExecute: (invoice, progressHandler, onSuccess, onError) => {
            return apiClient.invoice.getInvoicePdf(invoice.id)
                .then((pdf) => {
                    downloadFile(pdf, getSuggestedForCollaboratorFilename(invoice.title, invoice.collaborator, true) + ".pdf")
                })
                .catch((error) => {
                    onError(t("common.actions.share.error_download"))
                })
        },
        condition: () => scope === "row",
    },
    {
        title: t("invoices.actions.edit.button"),
        scope: scope,
        icon: scope === "row" ? Edit : null,
        withDialog: {
            title: t("invoices.actions.edit.modal_title"),
            content: ChangeState,
        },
        reloadOnSuccess: true,
        showLoading: true,
        onExecute: (invoice, progressHandler, onSuccess, onError) => {
            return apiClient.invoice.editInvoice(invoice.id, invoice.data)
                .then((result) => {
                    onSuccess(
                        t("invoices.actions.edit.confirm", {
                            title: invoice.title,
                        })
                    )

                    return result
                })
                .catch((error) => {
                    onError(
                        t("invoices.actions.edit.error", {
                            title: invoice.title,
                        })
                    )

                    throw error
                })
        },
        condition: (invoice) => invoice.hasAction("change_state"),
    },
    {
        title: t("invoices.actions.amend.button"),
        scope: scope,
        confirmDialog: {
            title: t("invoices.actions.amend.modal_title"),
            content: (invoice) =>
                t("invoices.actions.amend.modal_content", {
                    title: invoice.title,
                })
        },
        reloadOnSuccess: true,
        showLoading: true,
        onExecute: (invoice, progressHandler, onSuccess, onError) => {
            return apiClient.invoice.amendInvoice(invoice.id, "partial")
                .then((result) => {
                    onSuccess(
                        t("invoices.actions.amend.confirm", {
                            title: invoice.title,
                        })
                    )

                    return result
                })
                .catch((error) => {
                    onError(
                        t("invoices.actions.amend.error", {
                            title: invoice.title,
                        })
                    )

                    throw error
                })
        },
        condition: (invoice) => invoice.hasAction("amend"),
    },
]