import availableRoutes from '../../../../routes/availableRoutes'

export const getActions = (t, navigate, user) => [
    {
        title: t("intermediaries.offices.add"),
        onExecute: (user, progressHandler, onSuccess, onError) => {
            navigate(availableRoutes.intermediary_offices_add.path)
        },
        condition: () => user.hasPermission('administer-any-intermediary-office')
    },
]