import { useEffect, useState } from "react"
import { useFormikContext } from "formik"
import EntityFormField from "./EntityFormField"
import { createAutocompleteFormField, createField, createFormField, createSelectFormField } from "../helpers/fieldHelper"
import _get from "lodash/get"

const buildComponentField = (id, label, type, condition, options, required, defaultValue) => {
    switch (type) {
        case "autocomplete":
            return {
                ...createAutocompleteFormField(
                    id,
                    label,
                    () => new Promise((r) => r(options))
                ),
                disabled: options.length === 0,
                condition: condition
            }
        case "select":
            return createSelectFormField(id, label, options, condition)
        default:
            return createFormField(createField(id, label, type, null, condition), required, defaultValue)
    }
}

/**
 *
 * @param {object} props object with:
 *   - field: dependent fields definition
 *     {
 *       id: product_id,
 *       label: Product,
 *       type: dependent
 *       component: autocomplete | select
 *       valuesFetcher: function
 *       condition: function
 *     }
 *   - value: current value for all dependent fields
 */
const DependentFormField = ({ field: { id, label, component, valuesFetcher, condition, required, defaultValue }, value }) => {
    const [selectField, setSelectField] = useState(null)
    const { values, setFieldValue } = useFormikContext()

    useEffect(() => {
        if (component === "autocomplete" || component === "select") {
            // process options 
            let optionsPromise = valuesFetcher(values)
            if (!(optionsPromise instanceof Promise)) {
                optionsPromise = Promise.resolve(optionsPromise)
            }

            optionsPromise.then((result) => {
                const options = result || []

                if (options === null || options.length === 0) {
                    // if there are no options, reset the field value
                    if (_get(values, id) !== "") {
                        setFieldValue(id, "")
                        setSelectField(null)
                    }
                } else {
                    // check if the current value is in the options
                    const value = _get(values, id)
                    if (value) {
                        const option = options.find((option) => option.value.toString() === value.toString())
                        if (!option) {
                            // if not, reset the field value
                            setFieldValue(id, "")
                        }
                    }

                    // check if the field should be rebuilt
                    if (!selectField ||
                        selectField.options.length !== options.length ||
                        selectField.options.some((option) => !options.some((o) => o.value.toString() === option.value.toString()))
                    ) {
                        setSelectField(buildComponentField(id, label, component, condition, options))
                    }
                }
            })
        } else {
            const fieldValidValues = valuesFetcher(values)
            if (fieldValidValues !== null) {
                setSelectField(buildComponentField(id, label, component, condition, [], required, defaultValue))
            } else {
                setSelectField(null)
            }
        }
    }, [id, label, component, condition, valuesFetcher, values])

    if (!selectField) {
        return null
    }

    return <EntityFormField field={selectField} />
}

export default DependentFormField
