import { useTranslation } from "react-i18next"
import AsyncEntityForm from "../../../../../components/form/AsyncEntityForm"
import { createFormFieldset, createField } from '../../../../../components/helpers/fieldHelper'
import constants from '../../../../../components/entity/constants'
import { DialogContentText } from "@mui/material"

const BookingSetNoShow = ({ selection, onAction, onCancel }) => {
    const { t } = useTranslation("vbms")

    const fetcher = () => Promise.resolve(selection)

    const formContent = [
        createFormFieldset(null, [
            createField(
                "no_show_cost",
                t("bookings.actions.set_no_show.fields.cost"),
                constants.CURRENCY_TYPE
            ),
        ])
    ]

    const onConfirm = (result) => {
        onAction({
            ...result,
            locator: selection.locator,
            id: selection.id
        })

    }

    return (
        <>
            <DialogContentText>{t("bookings.actions.set_no_show.modal_content", { "locator": selection.locator, "id": selection.id })}</DialogContentText>
            <AsyncEntityForm
                fetcher={fetcher}
                onSave={onConfirm}
                formContent={formContent}
            />
        </>
    )
}

export default BookingSetNoShow