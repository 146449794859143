import { BorderAll, IosShare, Link, PictureAsPdfOutlined } from "@mui/icons-material"
import { IconButton, ListItemIcon, ListItemText, Menu, MenuItem } from "@mui/material"
import { useState } from "react"
import useSnackBars from "../Snackbar/snack-bar.context"
import { useEntityContext } from "../entity/context/entity/entity.context"

const ACTION_ICONS = {
    link: Link,
    link_pdf: PictureAsPdfOutlined,
    download_pdf: PictureAsPdfOutlined,
    download_excel: BorderAll,
    link_gift_pdf:PictureAsPdfOutlined
}

const ShareOption = ({id, title, onClick}) => {
    const Icon = ACTION_ICONS[id]

    return (
        <MenuItem onClick={onClick}>
            <ListItemIcon> 
                <Icon sx={{color: "primary.main"}} />
            </ListItemIcon>
            <ListItemText sx={{color: "primary.main"}}>{title}</ListItemText>
        </MenuItem>
    )
}
const ShareActionsGroup = ({actions}) => {
    const [anchorEl, setAnchorEl] = useState(null)
    const { addAlert } = useSnackBars()
    const selection = useEntityContext()

    const open = Boolean(anchorEl)
    
    const handleClick = (event) => setAnchorEl(event.currentTarget)

    const handleClose = () => setAnchorEl(null)

    const onSuccessHandler = (message) => addAlert(message, "success")

    const onErrorHandler = (message) => addAlert(message, "error")

    const progressHandler = (value, message) => true

    const handleAction = (action) => {
        handleClose()

        action.onExecute(selection, progressHandler, onSuccessHandler, onErrorHandler)
    }

    if (!actions || actions.length === 0) {
        return null
    }

    return (
        <div>
            <IconButton
                aria-label="share"
                id="share-button"
                aria-controls={open ? 'share-button' : undefined}
                aria-expanded={open ? 'true' : undefined}
                aria-haspopup="true"
                onClick={handleClick}
                color="primary"
            >
                <IosShare />
            </IconButton>
            <Menu
                id="share-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                    'aria-labelledby': 'basic-button',
                }}
            >
                {actions.map((action, index) => (
                    <ShareOption key={index} id={action.id} icon={action.icon} title={action.title} onClick={() => handleAction(action)} />
                ))}
            </Menu>
        </div>
    )
}

export default ShareActionsGroup
