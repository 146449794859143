import { useTranslation } from 'react-i18next'
import constants from '../../../../../components/entity/constants'
import { createField } from '../../../../../components/helpers/fieldHelper'
import ArrayDataTable from '../../../../../components/Datatable/ArrayDataTable'


const processLiquidationLines = (liquidationLines) =>
    liquidationLines
        .map((liquidationLine) => {
            const result = [{
                description: liquidationLine.description,
                exchange_date: liquidationLine.exchange_date,
                quantity: null,
                total_amount: null,
                total_tax: null
            }]

            liquidationLine.liquidation_line_entries.forEach((liquidationLineEntry) => {
                result.push({
                    description: `<span style="padding-left: 32px">${liquidationLineEntry.description}</span>`,
                    exchange_date: null,
                    quantity: liquidationLineEntry.quantity,
                    total_amount: liquidationLineEntry.total_commission_price,
                    total_tax: liquidationLineEntry.total_commission_tax
                })
            })

            return result
        })
        .flat()

const LiquidationLines = ({ entity }) => {
    const { t } = useTranslation("vbms")

    if (!entity) {
        return null
    }

    const columns = [
        createField(
            "description",
            t("liquidations.fields.liquidation_lines.description"),
            constants.HTML_TYPE
        ),
        createField(
            "exchange_date",
            t("liquidations.fields.liquidation_lines.exchange_date"),
            constants.DATE_TYPE
        ),
        createField(
            "quantity",
            t("liquidations.fields.liquidation_lines.quantity"),
            constants.NUMERIC_TYPE
        ),
        createField(
            "total_amount",
            t("liquidations.fields.total_amount"),
            constants.CURRENCY_TYPE
        ),
        createField(
            "total_tax",
            t("liquidations.fields.total_tax"),
            constants.CURRENCY_TYPE
        )
    ]

    return (
        <ArrayDataTable
            data={processLiquidationLines(entity.liquidation_lines)}
            columns={columns}
        />
    )
}

export default LiquidationLines