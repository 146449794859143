import { useTranslation } from "react-i18next"
import { useVolcanoAuth } from "../../../context/VolcanoAuthContext"
import {
    createField,
    createFormFieldGroup,
    createFormFieldset,
    createFormField,
    createAsyncSelectFormField, createDependentFormField, createFormFieldOption
} from "../../../components/helpers/fieldHelper"
import constants from "../../../components/entity/constants"
import AsyncEntityForm from "../../../components/form/AsyncEntityForm"
import {buildOptions, DEFAULT_LIMIT} from "../../../lib/form-options-fetchers"
import _get from "lodash/get"
import {useVolcanoApiClient} from "../../../context/VolcanoApiClientProvider"
const PickupPointForm =  ({ selection, onAction }) => {

    const { t } = useTranslation("vbms")
    const { apiClient } = useVolcanoApiClient()
    const { user } = useVolcanoAuth()

    const formatArea = (areas) => {
        if (!areas) {
            return {}
        }
        const areaAR = areas.filter(area => area.cod === "AR");
        return areaAR.length > 0 ? areaAR[0] : {};
    }

    const fetcher = () => Promise.resolve(selection === undefined ? {
        name: "",
        address: "",
        coordinates: {
            lat: "",
            lng: ""
        }
    } : {
            ...selection,
            area: formatArea(selection.area_components)
        }
    )

    const onConfirm = (data) => {

        const pickupPoint = {
            ...data
        }
        pickupPoint["enterprise_id"] = user.corporate_account.enterprise.id

        onAction(pickupPoint)
    }

    const formContent = [
        createFormFieldset(t("pickup_points.form.fieldset_information"),
            [
                createFormFieldGroup([
                    createFormField(
                        createField(
                            "name",
                            t("pickup_points.fields.name"),
                            constants.STRING_TYPE
                        ),
                        true,
                        ""
                    ),
                    createFormField(
                        createField(
                            "address",
                            t("pickup_points.fields.address"),
                            constants.STRING_TYPE
                        ),
                        false,
                        ""
                    ),
                    createAsyncSelectFormField(
                        "area.id",
                        t("pickup_points.fields.area"),
                        () => {
                            let options = [createFormFieldOption("" ,t("table.all"))]
                            const params = {
                                limit: DEFAULT_LIMIT,
                                code: "AR",
                                sort: "name"
                            }
                            return apiClient.activityManager.area.getAreas(params)
                                .then((items) =>
                                    options.concat(
                                        buildOptions(items.getItems(), t, "id", "name", false)
                                    )
                                )
                        },
                        false
                    ),
                    createDependentFormField(
                        "zone.id",
                        t("pickup_points.fields.zone"),
                        constants.SELECT_TYPE,
                        (values) => {

                            let options = [createFormFieldOption("" ,t("table.all"))]

                            const params = {
                                area_id:   _get(values, "area.id"),
                                limit: DEFAULT_LIMIT
                            }

                            return apiClient.activityManager.zone.getZones(params)
                                .then((items) =>
                                    options.concat(
                                        buildOptions(items.getItems(), t, "id", "name", false)
                                    )
                                )
                        },
                        true
                    ),
                    createFormField(
                        createField(
                            "coordinates.lat",
                            t("pickup_points.fields.latitude"),
                            constants.STRING_TYPE
                        ),
                        true,
                        ""
                    ),
                    createFormField(
                        createField(
                            "coordinates.lng",
                            t("pickup_points.fields.longitude"),
                            constants.STRING_TYPE
                        ),
                        true,
                        ""
                    ),
                ])
            ])
    ]

    return (
        <AsyncEntityForm
            fetcher={fetcher}
            onSave={onConfirm}
            formContent={formContent}
        />
    )
}

export default PickupPointForm
