import { useTranslation } from 'react-i18next'
import constants from '../../../../../components/entity/constants'
import { createField, createLinkField, createBookingStateMapping, createCallbackField } from '../../../../../components/helpers/fieldHelper';
import ArrayDataTable from '../../../../../components/Datatable/ArrayDataTable'
import { ListItem, Stack } from '@mui/material';
import { getRateName } from '../../../BookingRatesFormPage/components/BookingRates.functions';

const BookingRelatedBookings = (props) => {
    const { t } = useTranslation("vbms")
    const columns = [
        createLinkField(
            createField(
                "locator",
                t("bookings.fields.locator"),
                constants.STRING_TYPE
            ),
            {
                route: "bookings",
                field: "id",
            }
        ),
        createField(
            "order.collaborator.name",
            t("bookings.fields.order.collaborator.title"),
            constants.STRING_TYPE
        ),
        createField(
            "state",
            t("bookings.fields.state"),
            constants.STRING_TYPE,
            createBookingStateMapping(t)
        ),
        createCallbackField(
            "product_rates",
            t("bookings.fields.product_rates.title"),
            constants.TEXT_TYPE,
            (value) => {
                return (
                    <Stack>
                        {value.map((bookingRate) => <ListItem sx={{ padding: 0 }}>{getRateName(bookingRate)}: {bookingRate.qty}</ListItem>)}
                    </Stack>
                )
            }
        ),
    ]

    return (
        <ArrayDataTable
            data={props.data}
            columns={columns}
        />
    )
}

export default BookingRelatedBookings