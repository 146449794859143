import availableRoutes from "../../../routes/availableRoutes"

export const getActions = (t, navigate, user) => [
    {
        title: t("billing_clients.add"),
        onExecute: (user, progressHandler, onSuccess, onError) => {
            navigate(availableRoutes.billing_clients_add.path)
        },
        condition: () => user.hasPermission("add-billing-information")
    },
    {
        title: t("billing_clients.actions.create_invoice.button"),
        onExecute: (user, progressHandler, onSuccess, onError) => {
            navigate(availableRoutes.billing_clients_create_invoice.path)
        },
        condition: () => user.hasPermission("create-invoice")
    },
]