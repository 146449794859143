import { createTabBarItem } from '../../../components/helpers/fieldHelper'
import CommentsTable from '../../../components/Volcano/Comments/CommentsTable'
import BookingDetail from '../../../components/Volcano/Bookings/BookingDetail'
import { Stack } from '@mui/material'
import RefundDetail from './components/tabs/RefundDetail'

export const getTabNavigationItems = (user, apiClient, t) => [
    createTabBarItem(
        t("refunds.navbar.detail"),
        ({ entity }) => (<Stack>
            <BookingDetail entity={entity.booking} />
            {
                //Excluded in cancellation or closure
                ![0, 4].includes(entity.type.id) && <RefundDetail refundData={entity.data} currency={entity.currency_code} mainCurrency={entity.main_currency_code} exchangeRate={entity.booking.order.currency_exchange_rate} />
            }
        </Stack>),
    ),
    createTabBarItem(
        t("refunds.navbar.comments"),
        ({ entity }) => (<CommentsTable data={entity.comments} />),
        null,
        (entity) => entity.comments
    ),
]