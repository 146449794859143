import constants from "../../components/entity/constants";
import { createField } from "../../components/helpers/fieldHelper";
import { useVolcanoApiClient } from "../../context/VolcanoApiClientProvider";
import { useCache } from "../../context/cache.context";
import AsyncEntityPage from "../../layout/templates/AsyncContentPage/AsyncEntityPage";

const ArticleContent = ({ entity }) => {
    return (
        <div
            dangerouslySetInnerHTML={{ __html: entity.full_text }}
        />
    )
}

const ArticleContentPage = ({ siteId, sectionId, articleId }) => {
    const { apiClient } = useVolcanoApiClient()
    const cache = useCache()

    const fetcher = () => cache.get("terms_conditions", null, () => apiClient.content.article.getArticle(siteId, sectionId, articleId))

    return (
        <AsyncEntityPage
            fetcher={fetcher}
            title={createField(
                "name",
                null,
                constants.STRING_TYPE
            )}
            content={ArticleContent}
        />
    )
}

export default ArticleContentPage