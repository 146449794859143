import { Button, ButtonGroup, TextField } from '@mui/material';
import { useEffect, useState } from "react"

const Counter = ({ defaultValue, min, max, disabledMin, disabledMax, allowEdit, onChange }) => {
    const maxQty = isNaN(max) ? Number.MAX_SAFE_INTEGER : max
    const minQty = isNaN(min) ? 0 : min
    const [value, setValue] = useState(defaultValue || minQty)

    const increment = () => {
        if (!disabledMax && value < maxQty) {
            setValue(value + 1)
        }
    }

    const decrement = () => {
        if (!disabledMin && value > minQty) {
            setValue(value - 1)
        }
    }

    const handleTextFieldOnBlur = (value) => {
        if (value === "") {
            setValue(minQty)
        }
    }

    const handleTextFieldOnChange = (value) => {
        if (value !== "" && (isNaN(value) || value.indexOf(".") > 0 || value.indexOf(",") >= 0)) {
            return
        }

        let newValue = value

        if (newValue !== "") {
            if (parseInt(newValue) < minQty) {
                newValue = minQty
            } else if (parseInt(newValue) > maxQty) {
                newValue = maxQty
            } else {
                newValue = parseInt(newValue)
            }
        }

        setValue(newValue)
        onChange(newValue === "" ? minQty : newValue)
    }

    useEffect(() => onChange(value), [value, onChange])

    return (
        <ButtonGroup
            size="small"
            aria-label="counter"
            sx={{
                "& .MuiButton-root.Mui-disabled": {
                    color: "common.text",
                    fontWeight: 400,
                },
                "& .MuiButton-outlined.Mui-disabled": {
                    borderColor: "rgba(0, 0, 0, 0.23)",
                },
                "& .MuiTextField-root .MuiInputBase-root": {
                    borderRadius: 0,
                    width: 40
                },
                "& .MuiTextField-root input": {
                    paddingX: 1,
                    textAlign: "center"
                },
            }}
        >
            <Button disabled={disabledMin} onClick={decrement}>-</Button>
            {!allowEdit && <Button disabled={true}>{value}</Button>}
            {allowEdit && <TextField
                id={"counter"}
                value={value}
                required={true}
                onChange={(e) => handleTextFieldOnChange(e.target.value)}
                onBlur={(e) => handleTextFieldOnBlur(e.target.value)}
                variant="outlined"
                size="small"
                InputLabelProps={{
                    shrink: true
                }}
            />
            }
            <Button disabled={disabledMax} onClick={increment}>+</Button>
        </ButtonGroup >
    )
}

export default Counter