import { Field } from "formik"
import { TimePicker } from "@mui/x-date-pickers"
import { FormHelperText } from '@mui/material';
import { isDate } from 'lodash';
import { useTranslation } from 'react-i18next';

const TimePickerField = ({ field, form, required, ...props }) => {

    const handleChange = (value) => {
        form.setFieldValue(field.name, value ? value: null)
        form.setFieldTouched(field.name, true, false)
    }

    const getTimeValue = () => {
        if (typeof field.value === "object") {
            return field.value
        }

        return null
    }

    return (
        <TimePicker
            views={['hours', 'minutes']}
            format="HH:mm"
            label={props.label}
            value={getTimeValue()}
            slotProps={{
                textField: {
                    size: "small",
                    className: 'dateField',
                    InputLabelProps: { shrink: true },
                    fullWidth: true,
                    required: required
                }
            }}
            ampm={false}
            readOnly={false}
            disableOpenPicker={false}
            onChange={handleChange}
        />
    )
}

const validate = (t, value, required) => {
    if (required && !value) {
        return t("common.form.validation.required")
    }

    if (value && (!isDate(value) || isNaN(value))) {
        return t("common.form.validation.invalid_date")
    }
    return null
}

const TimeFormField = ({ field }) => {

    const { t } = useTranslation("vbms")
    const mainField = field

    return (
        <Field
            name={field.name}
            label={field.label}
            required={field.required}
            validate={(value) => validate(t, value, field.required)}
        >
            {({ field, form, meta }) => (
                <>
                    <TimePickerField
                        field={field}
                        form={form}
                        name={mainField.id}
                        label={mainField.label}
                        required={mainField.required}
                    />

                    {meta.error && <FormHelperText error={true}>{meta.error}</FormHelperText>}
                </>
            )}
        </Field>
    )
}

export default TimeFormField
