import {getActions as getRowActions} from "../../../Tags/TagPage/TagPage.actions";

export const getActions = (apiClient, t, navigate, user,  confirmDialog) => {
    return [
        {
            id: "unMarkInUseAll",
            title: t("inbox.actions.un_mark_uses_all.button"),
            onExecute: () => {
                confirmDialog(
                    t("inbox.actions.un_mark_uses_all.title"),
                    t("inbox.actions.un_mark_uses_all.message"),
                    () => {
                        return apiClient.booking.unlockAllBookings()
                            .then(() => {
                            })
                            .catch(() => {
                            })
                    }
                )

            },
            condition: () => true
        },
    ]
}

export const getTableActions = (...props) => getRowActions(...props)
