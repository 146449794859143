import constants from "../../../../components/entity/constants";
import {
    createCallbackField,
    createDataSetFacet,
    createField
} from "../../../../components/helpers/fieldHelper";
import {Link} from "@mui/material";

export const getHeaderContent = (t) => [
    createDataSetFacet(t("pickup_points.view.facet_information"), [
        createField("id", "Id", constants.STRING_TYPE),
        createField(
            "name",
            t("pickup_points.fields.name"),
            constants.STRING_TYPE
        ),
        createField(
            "address",
            t("pickup_points.fields.address"),
            constants.STRING_TYPE
        ),
        createField(
            "zone.name",
            t("pickup_points.fields.area"),
            constants.STRING_TYPE
        ),
        createCallbackField(
            "area_components",
            t("pickup_points.fields.zone"),
            constants.STRING_TYPE,
            (value) => {
                const areaComponent = value.find(component => component.cod === "AR");
                if (areaComponent) {
                    return areaComponent.name;
                }

                return "";
            }
        ),
    ]),
    createDataSetFacet(t("pickup_points.view.facet_location"), [
        createField(
            "coordinates.lat",
            t("pickup_points.fields.latitude"),
            constants.STRING_TYPE
        ),
        createField(
            "coordinates.lng",
            t("pickup_points.fields.longitude"),
            constants.STRING_TYPE
        ),
        createCallbackField(
            "coordinates",
            t("pickup_points.fields.gmap"),
            constants.TEXT_TYPE,
            (value) =>
                <Link href={`https://www.google.es/maps?z=10&q=loc:${value.lat},${value.lng}`}
                      target="_blank">
                    {t("common.actions.view.map_link")}
                </Link>,
        ),
    ]),

];