import constants from "../../../../components/entity/constants";
import { createField, createLinkField } from "../../../../components/helpers/fieldHelper";

export const getTableColumns = (t) => ([
    createLinkField(
        createField(
            "name",
            t("zones.fields.name"),
            constants.TEXT_TYPE
        ),
        {
            route: "zones",
            field: "id",
        }
    ),
    createField(
        "area.name",
        t("zones.fields.area"),
        constants.TEXT_TYPE
    ),
    createField(
        "created",
        t("zones.fields.created"),
        constants.DATETIME_TYPE
    ),
    createField(
        "modified",
        t("zones.fields.modified"),
        constants.DATETIME_TYPE
    ),
])