import { Field } from "formik"
import { useTranslation } from "react-i18next"
import { validate } from "./utils"
import { Autocomplete } from "formik-mui"
import { TextField } from "@mui/material"
import _get from "lodash/get"
import _has from "lodash/has"

const AutocompleteFormField = ({ field, value }) => {
    const { t } = useTranslation("vbms")

    return (
        <Field
            component={Autocomplete}
            name={field.id}
            label={field.label}
            required={field.required}
            validate={(value) => validate(field, t, value)}
            options={field.optionsFetcher()}
            getOptionLabel={(option) => {
                return option ? option.label : ""
            }}
            renderInput={(params) => (
                <TextField
                    {...params}
                    name={field.id}
                    error={_has(params.touched, field.id) && _has(params.errors, field.id)}
                    helperText={_get(params.errors, field.id)}
                    label={field.label}
                    variant="outlined"
                    InputProps={{
                        size: "small"
                    }}
                />
            )}
        />
    )
}

export default AutocompleteFormField