import constants from '../../../components/entity/constants';
import { createDataSetFacet, createField, createPersonTypeMapping, vatNumberTypeMapping } from '../../../components/helpers/fieldHelper';

export const getHeaderContent = (t, user) => [
    createDataSetFacet(t("billing_clients.view.facet_information"), [
        createField("id", "Id", constants.STRING_TYPE),
        createField(
            "name",
            t("billing_clients.fields.name"),
            constants.STRING_TYPE
        ),
        createField(
            "enterprise.name",
            t("billing_clients.fields.enterprise"),
            constants.STRING_TYPE
        ),
        createField(
            "counter",
            t("billing_clients.fields.counter"),
            constants.NUMERIC_TYPE
        ),
        createField(
            "created",
            t("billing_clients.fields.created"),
            constants.DATETIME_TYPE
        ),
    ]),
    createDataSetFacet(t("billing_clients.view.facet_at"), [
        createField(
            "type",
            t("billing_clients.fields.type"),
            constants.STRING_TYPE,
            createPersonTypeMapping(t)
        ),
        createField(
            "vat_number_type",
            t("billing_clients.fields.vat_number_type"),
            constants.STRING_TYPE,
            vatNumberTypeMapping(t)
        ),
        createField(
            "vat_number",
            t("billing_clients.fields.vat_number"),
            constants.STRING_TYPE
        ),
        createField(
            "accountancy_config.account_id",
            t("billing_clients.fields.account_id"),
            constants.STRING_TYPE
        ),
    ]),
    createDataSetFacet(t("billing_clients.view.facet_content"), [
        createField(
            "contact_details",
            null,
            constants.ADDRESS_TYPE
        ),
    ]),
]