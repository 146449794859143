import { createTabBarItem } from "../../../../components/helpers/fieldHelper"
import LodgingPickupPoints from "./components/tabs/LodgingPickupPoints"
import {DEFAULT_LIMIT} from "../../../../lib/form-options-fetchers"



export const getTabNavigationItems = (user, apiClient, t) => [
    createTabBarItem(
        t("lodgings.view.navbar.pickup_points"),
        ({ entity, data }) => (
            <LodgingPickupPoints
                data={data.getItems()}
                entity={entity}
            />
        ),
        (lodging) => {
            const params = {
                limit: DEFAULT_LIMIT,
                offset: 0,
                sort: "name"
            }
            return apiClient.activityManager.lodging.getPickupPoints(lodging.id, params)
        },
    ),
];
