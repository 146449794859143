import constants from "../../../components/entity/constants";
import { createField, createLinkField } from '../../../components/helpers/fieldHelper';

export const getTableColumns = (t, user) => ([
    createLinkField(
        createField(
            "name",
            t("settings_roles.fields.name"),
            constants.TEXT_TYPE
        ),
        {
            route: "settings_roles",
            field: "id",
        }
    ),
    createField(
        "slug",
        t("settings_roles.fields.slug"),
        constants.TEXT_TYPE
    ),
    createField(
        "is_system",
        t("settings_roles.fields.is_system"),
        constants.BOOLEAN_TYPE
    ),
])