import { createTabBarItem } from '../../../components/helpers/fieldHelper'
import _get from 'lodash/get'
import InvoiceLineItems from './components/tabs/InvoiceLineItems'
import InvoiceHistory from './components/tabs/InvoiceHistory'

export const getTabNavigationItems = (t) => [
    createTabBarItem(
        t("invoices.view.navbar.detail"),
        InvoiceLineItems,
    ),
    createTabBarItem(
        t("invoices.view.navbar.history"),
        ({ entity, data }) => (
            <InvoiceHistory data={entity.log ?? []} />
        ),
        null,
        (invoice) => _get(invoice, 'log', []).length > 0
    ),
]