import { useTranslation } from "react-i18next"
import { Box, Icon, Stack } from "@mui/material"
import { MailOutlineOutlined, SmsOutlined } from '@mui/icons-material'
import _has from "lodash/has"
import Text from "../../Display/Text"

const getType = (type) => {
    if (type === 'sms') {
        return (<Icon><SmsOutlined /></Icon>)
    } else {
        return (<Icon><MailOutlineOutlined/></Icon>)
    }
}

const summaryElement = (element) => {
    return (
        <Stack direction="row" display="flex" spacing={1}>
            <Box>{getType(element.type)}</Box>
            <Box sx={{color: "info.main"}}>{element.total}</Box>
            <Box sx={{color: "success.main"}}>{element.total_successful}</Box>
            <Box sx={{color: "error.main"}}>{element.total_unsuccessful}</Box>
        </Stack>
    )
}

const summaryRecipient = (value, recipientType, t) => {
    return (
        <Stack direction="row" spacing={1}>
            <Box>
                <Text>{t("notification_templates.constants."+recipientType)}</Text>
                {_has(value, "email") && _has(value.email, "type") && (
                    summaryElement(value.email)
                )}
                {_has(value, "sms") && _has(value.sms, "type") && (
                    summaryElement(value.sms)
                )}
            </Box>
        </Stack>
    )
}


const Summary = ({ value }) => {
    const { t } = useTranslation("vbms")

    return (
        <Stack spacing={2} direction="row">
        {_has(value, "booking") && _has(value.booking, "type") && (
            <Stack>{summaryRecipient(value.booking, value.booking.type, t)}</Stack>
        )}
        {_has(value, "collaborator") && _has(value.collaborator, "type") && (
            <Stack>{summaryRecipient(value.collaborator, value.collaborator.type, t)}</Stack>
        )}
        {_has(value, "guide") && _has(value.guide, "type") && (
            <Stack>{summaryRecipient(value.guide, value.guide.type, t)}</Stack>
        )}
        </Stack>
    )
}

export default Summary