import { Card, CardActionArea, CardContent, Grid, Typography } from "@mui/material";

const SessionSelector = ({ sessions, onSessionSelected }) => {

  return (
    <Grid container spacing={2}>
      {sessions.map((session) => {
        return (
          <Grid key={session.session} item>
            <Card variant="outlined">
              <CardActionArea onClick={() => onSessionSelected(session)}>
                <CardContent>
                  <Typography>
                    {session.session.substring(0, session.session.length - 3)} (
                    {session.available})
                  </Typography>
                </CardContent>
              </CardActionArea>
            </Card>
          </Grid>
        )
      })}
    </Grid>
  )
}

export default SessionSelector;