import { useTranslation } from "react-i18next";
import ArrayDataTable from "../../../../../../components/Datatable/ArrayDataTable";
import {createField, createLinkField} from "../../../../../../components/helpers/fieldHelper";
import constants from "../../../../../../components/entity/constants";

const ZonePickupPoints = (props) => {
    const { t } = useTranslation("vbms")
    const columns = [
        createLinkField(
            createField(
                "name",
                t("lodgings.fields.name"),
                constants.STRING_TYPE
            ),
            {
                route: "pickup_points",
                field: "id",
            }
        ),
        createField(
            "created",
            t("lodgings.fields.created"),
            constants.DATETIME_TYPE
        ),
        createField(
            "modified",
            t("lodgings.fields.modified"),
            constants.DATETIME_TYPE
        ),
    ]
    return (
        <ArrayDataTable
            data={props.data}
            columns={columns}
        />
    )
}

export default ZonePickupPoints
