import { useTheme } from "@mui/material"
import { Delete, AddCircleOutline} from "@mui/icons-material"

export const RulesConfig = (t) => {
    const theme = useTheme()

    const config = {
        "route": "opening_hours",
        "title": t("venues.form.fieldset_rules"),
        "datesLabel": t("components.rules_selector.range_title_dates"),
        "hoursLabel": t("components.rules_selector.range_title_hours"),
        "daysLabel": t("components.rules_selector.range_title_days"),
        "addAction": {
            "active": true,
            "label": t("components.rules_selector.add_configuration"),
            "icon": <AddCircleOutline />,
            "color": theme.palette.primary.main,
        },
        "deleteAction": {
            "active": true,
            "label": t("components.rules_selector.delete_configuration"),
            "icon": <Delete />,
            "color": theme.palette.error.main,
        }
    }

    return config
}