import { useTranslation } from "react-i18next"
import { useVolcanoAuth } from "../../../context/VolcanoAuthContext"
import {
    createField,
    createFormFieldGroup,
    createFormFieldset,
    createFormField
} from "../../../components/helpers/fieldHelper"
import constants from "../../../components/entity/constants"
import AsyncEntityForm from "../../../components/form/AsyncEntityForm"
const RouteForm =  ({ selection, onAction }) => {

    const { t } = useTranslation("vbms")
    const { user } = useVolcanoAuth()

    const fetcher = () => Promise.resolve(selection === undefined ? {
        name: "",
    } : {
            ...selection
        }
    )

    const onConfirm = (data) => {

        const route = {
            ...data
        }
        route["enterprise_id"] = user.corporate_account.enterprise.id

        onAction(route)
    }

    const formContent = [
        createFormFieldset(t("routes.form.fieldset_information"),
            [
                createFormFieldGroup([
                    createFormField(
                        createField(
                            "name",
                            t("routes.fields.name"),
                            constants.STRING_TYPE
                        ),
                        true,
                        ""
                    ),
                ]),
            ]
        )
    ]

    return (
        <AsyncEntityForm
            fetcher={fetcher}
            onSave={onConfirm}
            formContent={formContent}
        />
    )
}

export default RouteForm
