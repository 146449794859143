import { useParams } from 'react-router-dom'
import { useTranslation } from "react-i18next"
import { useVolcanoApiClient } from "../../../context/VolcanoApiClientProvider"
import { useNavigate } from 'react-router-dom'
import { useVolcanoAuth } from '../../../context/VolcanoAuthContext'
import { createCallbackField } from '../../../components/helpers/fieldHelper'
import { getTabNavigationItems } from './TagPage.tabs'
import { getHeaderContent } from './TagPage.header'
import { getActions } from './TagPage.actions'
import constants from '../../../components/entity/constants'
import AsyncEntityPage from "../../../layout/templates/AsyncContentPage/AsyncEntityPage"


const TagPage = () => {
    const { t } = useTranslation()
    const { entityId } = useParams()
    const { apiClient } = useVolcanoApiClient()
    const { user } = useVolcanoAuth()
    const navigate = useNavigate()

    const fetcher = () => apiClient.content.tag.getTag(entityId)

    return (
        <AsyncEntityPage
            fetcher={fetcher}
            title={createCallbackField(
                "name",
                t("tags.form.title"),
                constants.STRING_TYPE,
                (entity, values) => t("tags.form.title", { name: values.slug })
            )}
            headerContent={getHeaderContent(t, user)}
            tabNavigationItems={getTabNavigationItems(t)}
            actions={getActions(apiClient, t, {}, navigate, entityId)}
        />
    );
}

export default TagPage;