import { useTranslation } from "react-i18next"
import AsyncEntityForm from "../../../../../components/form/AsyncEntityForm"
import { createFormFieldset, createTextAreaFormField, createSelectFormField, createFormFieldOption } from '../../../../../components/helpers/fieldHelper'

const ChangeState = ({ selection, onAction }) => {

    const { t } = useTranslation("vbms")

    const fetcher = () => Promise.resolve(selection)

    const formContent = [
        createFormFieldset("", [
            createSelectFormField(
                "state",
                t("invoices.fields.state"),
                [
                    createFormFieldOption("issued", t(`invoices.constants.state.issuer.issued`)),
                    createFormFieldOption("payment_pending", t(`invoices.constants.state.issuer.payment_pending`)),
                    createFormFieldOption("paid", t(`invoices.constants.state.issuer.paid`)),
                    createFormFieldOption("refunded", t(`invoices.constants.state.issuer.refunded`)),
                ]
            ),
            createTextAreaFormField(
                "comment",
                t("common.fields.comments.comment"),
                4
            ),
        ])
    ]

    const onConfirm = (result) => {
        onAction({
            id: selection.id,
            title: selection.title,
            data: {
                state: result.state,
                comment: result.comment
            }
        })
    }

    return (
        <AsyncEntityForm
            fetcher={fetcher}
            onSave={onConfirm}
            formContent={formContent}
        />
    )
}

export default ChangeState