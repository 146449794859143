import { useTranslation } from "react-i18next"
import { useVolcanoAuth } from "../../../../../context/VolcanoAuthContext"
import { useVolcanoApiClient } from "../../../../../context/VolcanoApiClientProvider"
import { createField, createFormField, createFormFieldset } from "../../../../../components/helpers/fieldHelper"
import AsyncEntityForm from "../../../../../components/form/AsyncEntityForm"
import { getCurrenciesFilter } from "../../../../../lib/collection-filters"
import constants from "../../../../../components/entity/constants"
import { Box } from "@mui/material"

const EnterpriseAddCurrency = ({ selection, onAction, onCancel }) => {
    const { t } = useTranslation("vbms")
    const { apiClient } = useVolcanoApiClient()
    const { user } = useVolcanoAuth()

    const fetcher = () => Promise.resolve(selection)

    const formContent = [
        createFormFieldset(null, [
            getCurrenciesFilter(apiClient, t, user, true, selection.id),
            {
                ...createFormField(
                    createField(
                        "correction_factor",
                        t("enterprise.fields.currencies.exchange_rate.correction_factor"),
                        constants.NUMERIC_TYPE
                    ),
                    true,
                    0.0
                ),
                max_width: "100%"
            }
        ])
    ]

    const onConfirm = (result) => {
        onAction(result.currency_id.map((currency) => (
            {
                id: currency.id,
                exchange_rate: {
                    correction_factor: parseFloat(result.correction_factor) / 100,
                }
            }
        )))
    }

    return (
        <Box>
            <Box sx={{ mb: 2 }}>
                {t("enterprise.actions.add_currency.modal_content")}
            </Box>
            <AsyncEntityForm
                fetcher={fetcher}
                onSave={onConfirm}
                formContent={formContent}
            />
        </Box>
    )
}

export default EnterpriseAddCurrency