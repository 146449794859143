import { useTranslation } from "react-i18next"
import AsyncEntityForm from "../../../../../components/form/AsyncEntityForm"
import { createFormFieldset, createEditableListFormField } from '../../../../../components/helpers/fieldHelper'
import { useVolcanoApiClient } from '../../../../../context/VolcanoApiClientProvider'
import ValueSelectorDialog from '../../../../../components/ValueSelectorDialog/ValueSelectorDialog'
import { getProductsFilter } from '../../../../../lib/collection-filters'
import { useVolcanoAuth } from '../../../../../context/VolcanoAuthContext'

const SiteFeaturedProductsForm = ({ selection, onAction, onCancel }) => {
    const { apiClient } = useVolcanoApiClient()
    const { user } = useVolcanoAuth()
    const { t } = useTranslation("vbms")
    const productsFilterField = getProductsFilter(apiClient, t, user, true, { state: "active", site_id: selection.id })

    const fetcher = () => apiClient.content.site.getFeaturedProducts(selection.id)
        .then((response) => {
            selection.config.content.featured_products = response.getItems()
            return selection
        })


    const handleOnConfirm = (value) => {
        onAction({
            site: {
                id: selection.id,
                name: selection.name,
            },
            featured_products: value.config.content.featured_products.map((item) => { return { id: parseInt(item.id), name: item.label } })
        })
    }

    const formContent = [
        createFormFieldset("", [
            createEditableListFormField(
                "config.content.featured_products",
                "",
                "name",
                t("sites.actions.set_featured_products.actions.add.modal_title"),
                ({ onConfirm, onCancel }) => <ValueSelectorDialog
                    table={productsFilterField.table}
                    selected={productsFilterField.selected}
                    valueFormatter={productsFilterField.valueFormatter}
                    multipleSelection={true}
                    onConfirm={onConfirm}
                    onCancel={onCancel}
                />,
                true,
                true
            ),
        ])
    ]

    return (
        <AsyncEntityForm
            fetcher={fetcher}
            onSave={handleOnConfirm}
            formContent={formContent}
        />
    )
}

export default SiteFeaturedProductsForm