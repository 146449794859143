import { useTranslation } from "react-i18next"
import AsyncEntityForm from "../../../../../components/form/AsyncEntityForm"
import { createFormFieldset, createCallbackField } from '../../../../../components/helpers/fieldHelper';
import constants from '../../../../../components/entity/constants';
import { DialogContentText } from "@mui/material";

const BookingSetValidationDate = ({ selection, onAction, onCancel }) => {
    const { t } = useTranslation("vbms")

    const fetcher = () => Promise.resolve(selection)

    const formContent = [
        createFormFieldset(null, [
            createCallbackField(
                "booking_date",
                t("bookings.fields.validation_date"),
                constants.DATETIME_TYPE,
                (value) => value ? new Date(value) : new Date()
            ),
        ])
    ]

    const onConfirm = (result) => {
        onAction({
            ...result,
            locator: selection.locator,
            id: selection.id
        })

    }

    return (
        <>
            <DialogContentText>{t("bookings.actions.validate.modal_content", { "locator": selection.locator, "id": selection.id })}</DialogContentText>
            <AsyncEntityForm
                fetcher={fetcher}
                onSave={onConfirm}
                formContent={formContent}
            />
        </>
    )
}

export default BookingSetValidationDate