import constants from "../../../../components/entity/constants"
import {
    createAsyncSelectFormField, createDependentFormField, createField, createFormFieldOption
} from "../../../../components/helpers/fieldHelper"

import {buildOptions, DEFAULT_LIMIT, experiencesFetcher} from "../../../../lib/form-options-fetchers"
import _get from "lodash/get"
import {pickupConfigsFetcher} from "../../libs/form-options-fetchers"

export const getTableFilter = (t, apiClient, user, cache) => ([
    createField(
        "name",
        t("routes.fields.name"),
        constants.TEXT_TYPE
    ),
    createAsyncSelectFormField(
        "experience_id",
        t("routes.fields.experience"),
        () => experiencesFetcher(apiClient, t, {
            limit: DEFAULT_LIMIT
        },true),
        false
    ),
    createDependentFormField(
        "product_id",
        t("routes.fields.product"),
        constants.SELECT_TYPE,
        (values) => {

            let options = [createFormFieldOption("" ,t("table.all"))]

            const params = {
                experience_id:  _get(values, "experience_id"),
                limit: DEFAULT_LIMIT
            }

            return apiClient.catalog.product.getProducts(params)
                .then((items) =>
                    options.concat(
                        buildOptions(items.getItems(), t, "id", "name", false)
                    )
                )
        },
    ),
    createAsyncSelectFormField(
        "pickup_config_id",
        t("routes.fields.pickup_config"),
        () => pickupConfigsFetcher(cache, apiClient, t, {
            limit: DEFAULT_LIMIT
        },true),
        false
    ),
])
