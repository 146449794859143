import BookingsTable from "../../../../../../components/Volcano/Bookings/BookingsTable";

const OfficeSales = ({ entity }) => {

    if (!entity) {
        return null;
    }

    return (
        <BookingsTable defaultParams={{ office_id: entity.collaborator_office_id }} />
    )
}

export default OfficeSales