import { Link } from "react-router-dom"
import { useVolcanoTheme } from "../../../context/VolcanoThemeProvider"
import { Box } from "@mui/material"

/**
 * Main logo
 */
const LogoSection = () => {
    const volcanoTheme = useVolcanoTheme()

    return (
        <Box
            sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                paddingTop: 1,
                "& img": {
                    maxHeight: "50px",
                    minWidth: "50px",
                    height: "50px",
                    width: "auto",
                    maxWidth: "145px",
                },
            }}

        >
            <Link to="/">
                <img src={volcanoTheme.logo} alt="Logo" width="auto" />
            </Link>
        </Box>
    )

}

export default LogoSection