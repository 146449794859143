import { useTranslation } from "react-i18next";
import constants from "../../../../../../components/entity/constants";
import {
    createCallbackField,
    createField, createLinkField
} from "../../../../../../components/helpers/fieldHelper";
import ArrayDataTable from "../../../../../../components/Datatable/ArrayDataTable";

const LodgingPickupPoints = (props) => {

    const { t } = useTranslation("vbms")

    const columns = [
        createLinkField(
            createField(
                "name",
                t("pickup_points.fields.name"),
                constants.STRING_TYPE
            ),
            {
                route: "pickup_points",
                field: "id",
            }
        ),
        createCallbackField(
            "area_components",
            t("pickup_points.fields.area"),
            constants.STRING_TYPE,
            (value) => {
                return (
                    <>
                        {value.find((item) => item.type === "Area")?.name}
                    </>
                )
            }
        ),
        createField(
            "zone.name",
            t("pickup_points.fields.zone"),
            constants.STRING_TYPE
        ),
        createField(
            "created",
            t("pickup_points.fields.created"),
            constants.DATETIME_TYPE
        ),
        createField(
            "modified",
            t("pickup_points.fields.modified"),
            constants.DATETIME_TYPE
        ),
    ]

    return (
        <ArrayDataTable
            data={props.data ?? []}
            columns={columns}
        />
    )

}

export default LodgingPickupPoints
