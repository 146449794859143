import { format as formatDate } from "date-fns"
import { dateObject } from "../../../lib/utils"

export const CUSTOMER_TYPE_IDS = {
    ADULTS: 16,
    CHILDREN: 14,
    RESIDENTS: 18,
    NOT_RESIDENTS: 19,
    GUIDES: 10
}

export const TELEFERICO_PRODUCTS_AGENCIAS = {
    SUDIBA_BAJADA_AGENCIAS : 1863
}

export const processDate = (value, format) => {
    const date = dateObject(value)

    if (!date) {
        return null
    }

    return formatDate(date, format)
}

export const getPax = (productRates, customerTypeId = null) => {
    if (!productRates || !productRates.length) {
        return 0
    }

    if (!customerTypeId) {
        return productRates.reduce((total, productRate) => total + productRate.qty, 0)
    }

    return productRates
        .filter(product => product.customer_type_ids.split(',').map(Number).includes(customerTypeId))
        .reduce((total, productRate) => total + productRate.qty, 0)
}