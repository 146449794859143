//import MultipleBookingsProductChange from "../BookingPage/components/actions/BookingProductChange/MultipleBookingsProductChange"
import BookingSetValidationDate from "../BookingPage/components/actions/BookingSetValidationDate"
import BookingSetNoShow from "../BookingPage/components/actions/BookingSetNoShow"
import _isArray from "lodash/isArray"
import { format as formatDate } from "date-fns"
import { ConfirmationNumberOutlined, VisibilityOffOutlined, CheckOutlined } from "@mui/icons-material"
import createTask from "../../../lib/tasks"

const checkBaseConditions = (availableActions, bookings, requiredAction) =>
    _isArray(availableActions) &&
    availableActions.hasOwnProperty(requiredAction) &&
    bookings.every((booking) => booking.state === "valid")

export const getTableActions = (user, apiClient, t) => [
    /*{
        id: "request_cancel",
        scope: "table",
        title: t("bookings.actions.request_cancel.button"),
        confirmDialog: {
            title: t("bookings.actions.request_cancel.modal_title"),
            content: (selection) => t("bookings.actions.request_cancel.confirm_text_not_allowed"),
        },
        reloadOnSuccess: false,
        showLoading: true,
        onExecute: (bookings, progressHandler, onSuccess, onError) => {
            return createTask(apiClient, t, progressHandler, onSuccess, onError, "cancellation_request", "booking", bookings.map((booking) => ({ id: booking.id })))
        },
        condition: (availableActions, bookings) => checkBaseConditions(availableActions, bookings, "cancellation_request")
    },*/
    {
        id: "collection_set_exchange_date",
        scope: "table",
        title: t("bookings.actions.validate.button"),
        confirmDialog: {
            title: t("bookings.actions.validate.modal_title"),
            content: (selection) => t("bookings.actions.validate.modal_multiple_content"),
        },
        reloadOnSuccess: true,
        showLoading: true,
        onExecute: (bookings, progressHandler, onSuccess, onError) => {
            return createTask(
                apiClient,
                t,
                progressHandler,
                onSuccess,
                onError,
                "exchange_date",
                "booking",
                bookings.map((booking) => ({
                    id: booking.id,
                    payload: {
                        exchange_date: booking.booking_date
                    }
                }))
            )
        },
        condition: (availableActions, bookings) => bookings.every((booking) => booking.hasAction("set_exchange_date"))
    },
    {
        id: "collection_set_no_show",
        scope: "table",
        title: t("bookings.actions.set_no_show.button"),
        confirmDialog: {
            title: t("bookings.actions.set_no_show.modal_title"),
            content: (selection) => t("bookings.actions.set_no_show.modal_multiple_content"),
        },
        reloadOnSuccess: true,
        showLoading: true,
        onExecute: (bookings, progressHandler, onSuccess, onError) => {
            return createTask(
                apiClient,
                t,
                progressHandler,
                onSuccess,
                onError,
                "no_show",
                "booking",
                bookings.map((booking) => ({
                    id: booking.id,
                    payload: {
                        cost: booking.no_show_cost
                    }
                }))
            )
        },
        condition: (availableActions, bookings) => bookings.every((booking) => booking.hasAction("set_no_show"))
    },
    {
        id: "set_exchange_date",
        scope: "row",
        icon: ConfirmationNumberOutlined,
        title: t("bookings.actions.validate.button"),
        withDialog: {
            title: t("bookings.actions.validate.modal_title"),
            content: BookingSetValidationDate
        },
        reloadOnSuccess: true,
        showLoading: true,
        onExecute: (result, progressHandler, onSuccess, onError) => {
            const validationDate = formatDate(result.booking_date, "yyyy-MM-dd HH:mm:ss")
            return apiClient.booking.setValidationDate(result.id, { exchange_date: validationDate })
                .then((booking) => {
                    onSuccess(
                        t("bookings.actions.validate.confirm", { locator: result.locator })
                    )

                    return { ...booking, id: result.id }
                })
                .catch((error) => {
                    onError(
                        t("bookings.actions.validate.error", { locator: result.locator })
                    )

                    throw error
                })
        },
        condition: (booking) => booking.hasAction("set_exchange_date")
    },
    {
        id: "set_no_show",
        scope: "row",
        icon: VisibilityOffOutlined,
        title: t("bookings.actions.set_no_show.button"),
        withDialog: {
            title: t("bookings.actions.set_no_show.modal_title"),
            content: BookingSetNoShow
        },
        reloadOnSuccess: true,
        showLoading: true,
        onExecute: (result, progressHandler, onSuccess, onError) => {
            return apiClient.booking.setNoShow(result.id, { cost: result.cost })
                .then((booking) => {
                    onSuccess(
                        t("bookings.actions.set_no_show.confirm", { locator: result.locator })
                    )

                    return { ...booking, id: result.id }
                })
                .catch((error) => {
                    onError(
                        t("bookings.actions.set_no_show.error", { locator: result.locator })
                    )

                    throw error
                })
        },
        condition: (booking) => booking.hasAction("set_no_show")
    },
    {
        id: "confirm",
        scope: "row",
        icon: CheckOutlined,
        title: t("bookings.actions.booking_confirmation.button"),
        confirmDialog: {
            title: t("bookings.actions.booking_confirmation.modal_title"),
            content: (selection) =>
                t("bookings.actions.booking_confirmation.modal_content", {
                    locator: selection.locator,
                })
        },
        reloadOnSuccess: true,
        showLoading: true,
        onExecute: (booking, progressHandler, onSuccess, onError) => {
            return apiClient.booking
                .confirm(booking.id)
                .then((result) => {
                    onSuccess(
                        t("bookings.actions.booking_confirmation.confirm", {
                            locator: booking.locator,
                            id: booking.id
                        })
                    )
                    return result
                })
                .catch((error) => {
                    t("bookings.actions.booking_confirmation.error", {
                        locator: booking.locator
                    })
                })
        },
        condition: (booking) => booking.hasAction("confirm")
    },
    /*{
        id: "send_voucher",
        scope: "table",
        title: t("bookings.actions.send_voucher.button"),
        confirmDialog: {
            title: t("bookings.actions.send_voucher.modal_title"),
            content: (selection) => t("bookings.actions.send_voucher.modal_content"),
        },
        reloadOnSuccess: false,
        showLoading: true,
        onExecute: (bookings, progressHandler, onSuccess, onError) => {
            return apiClient.task
                .createEntitiesTask("send_email", "booking", bookings.map((booking) => ({ id: booking.id })))
                .then((task) => {
                    onSuccess(t("tasks.actions.request.entities_success"))
                    return task
                })
                .catch((error) => {
                    onError(t("tasks.actions.request.entities_error"))
                })
 
        },
        condition: (availableActions, bookings) => bookings.every((booking) => booking.state === "valid" || booking.state === 'refund_requested')
    },
    {
        id: "product_change",
        scope: "table",
        title: t("bookings.actions.product_change.button"),
        withDialog: {
            title: t("bookings.actions.product_change.modal_title"),
            content: MultipleBookingsProductChange,
        },
        reloadOnSuccess: false,
        showLoading: true,
        onExecute: (result, progressHandler, onSuccess, onError) => {
            return apiClient.task
                .createEntitiesTask(
                    "product_change_request",
                    "booking",
                    result.ids.map((bookingId) => ({ id: bookingId })),
                    result.data
                )
                .then((task) => {
                    onSuccess(t("tasks.actions.request.entities_success"))
                    return task
                })
                .catch((error) => {
                    onError(t("tasks.actions.request.entities_error"))
                })
        },
        condition: (availableActions, bookings) =>
            checkBaseConditions(availableActions, bookings, "product_change_request") &&
            // all bookings should have the same product id
            bookings.every((booking) => booking.product.id === bookings[0].product.id) &&
            // all bookings should have the same booking date
            bookings.every((booking) => booking.booking_date === bookings[0].booking_date)
    },*/
]