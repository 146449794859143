export const INITIALIZE = "INITIALIZE"
export const SET_DIRTY = "SET_DIRTY"
export const SET_ROWS_PER_PAGE = "SET_ROWS_PER_PAGE"
export const SET_PAGE = "SET_PAGE"
export const SET_ORDER = "SET_ORDER"
export const SET_RESULT = "SET_RESULT"

/**
 * Initialize table state.
 * 
 * @param {*} params
 * @returns 
 */
export const initialize = (params) => {
    return {
        type: INITIALIZE,
        ...params,
    }
}

/**
 * Set table as dirty.
 */
export const setDirty = () => {
    return {
        type: SET_DIRTY,
        dirty: true,
    }
}

/**
 * Set rows per page value.
 *
 * @param {number} rowsPerPage rows per page
 */
export const setRowsPerPage = (rowsPerPage) => {
    return {
        type: SET_ROWS_PER_PAGE,
        rowsPerPage: rowsPerPage,
    }
}

/**
 * Set current page.
 *
 * @param {number} page current page
 */
export const setPage = (page) => {
    return {
        type: SET_PAGE,
        page: page,
    }
}

/**
 * Set order field.
 *
 * @param {string} orderBy order field
 * @param {boolean} orderAsc ascending order
 */
export const setOrder = (orderBy, orderAsc) => {
    return {
        type: SET_ORDER,
        orderBy: orderBy,
        order: orderAsc ? "asc" : "desc",
    }
}

export const setResult = (result) => {
    return {
        type: SET_RESULT,
        result: result,
    }
}

