import { FormControl, InputLabel, MenuItem } from "@mui/material"
import { Field } from "formik"
import { Select } from "formik-mui"
import { validate } from "./utils"
import { useTranslation } from "react-i18next"

const SelectFormField = ({ field, value }) => {
    const { t } = useTranslation("vbms")

    return (
        <FormControl className="selectField" sx={{ minWidth: 120 }}>
            <InputLabel id={field.id} htmlFor={field.id} shrink>{field.label}</InputLabel>
            <Field
                component={Select}
                name={field.id}
                id={field.id}
                labelId={field.id}
                label={field.required ? field.label + ' *' : field.label}
                required={field.required}
                displayEmpty
                size="small"
                notched={true}
                multiple={field.multiple}
                validate={(value) => validate(field, t, value)}
                inputLabel={{
                    shrink: true
                }}
                inputProps={{
                    id: field.id,
                    disabled: field.options.length === 0,
                }}

            >
                {field.options.map((option, index) => {
                    return <MenuItem key={index} value={option.value}>{option.label}</MenuItem>
                })}
            </Field>
        </FormControl>
    )
}

export default SelectFormField