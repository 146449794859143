import constants from "../../../components/entity/constants";
import { createField, createLinkField, createArrayField } from '../../../components/helpers/fieldHelper';
import userPermissions from '../../../lib/user-permissions';

const processOfficeField = (t, user) => {

    const field = createField(
        "crm_intermediary_office.name",
        t("settings_system_users.fields.office"),
        constants.TEXT_TYPE
    )

    if (user.hasPermission(userPermissions.PERM_CRM_INTERMEDIARY_OFFICE_VIEW)) {
        return createLinkField(
            field,
            {
                route: "intermediary_offices",
                field: "crm_intermediary_office.id",
            }
        )
    }

    return field
}

export const getTableColumns = (t, user) => ([
    createLinkField(
        createField(
            "username",
            t("settings_system_users.fields.username"),
            constants.TEXT_TYPE
        ),
        {
            route: "settings_users",
            field: "id",
        }
    ),
    createField(
        "name",
        t("settings_system_users.fields.name"),
        constants.TEXT_TYPE
    ),
    processOfficeField(t, user),
    createField(
        "active",
        t("settings_system_users.fields.active"),
        constants.BOOLEAN_TYPE
    ),
    createArrayField(
        createField(
            "roles",
            t("settings_system_users.fields.roles"),
            constants.ARRAY_TYPE
        ),
        "name"
    ),
    createField(
        "email",
        t("settings_system_users.fields.email"),
        constants.EMAIL_TYPE
    ),
    createField(
        "phone",
        t("settings_system_users.fields.phone"),
        constants.PHONE_TYPE
    ),
    createField(
        "created",
        t("settings_system_users.fields.created"),
        constants.DATETIME_TYPE
    ),
    createField(
        "last_login",
        t("settings_system_users.fields.last_login"),
        constants.DATETIME_TYPE
    ),
])